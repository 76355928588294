import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../../atoms/customButton";
import CustomTextField from "../../atoms/customTextField";
import CustomDropDown from "../../atoms/customDropDown";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchReferenceData,
  updateProfile,
  updateProfileSuccess,
  setCurrentDealer,
  startProductFlow,
  fetchProdCategoryResponse,
  setSonyMenuItems,
  setLoading,
} from "../../../state/actions";
import {
  NAME_REGEX,
  TELEPHONE_NUM_PATTERN,
} from "../../../constants/commonConstants";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import styles from "./updateProfile.module.scss";
import { createMenuItems, getComparator, stableSort } from "../../helper/util";
var _ = require("lodash");

export const UpdateProfile = () => {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [languageOptions, setLanguageOptions] = useState([]);
  const dispatch = useDispatch();

  const loginDetails = useSelector(
    (state: any) => state?.commonReducer?.loginResponse?.data?.userDetails
  );
  const userDetails = loginDetails?.profile;
  const updateProfileRes = useSelector(
    (state: any) => state?.commonReducer?.updateProfileRes
  );
  const referenceData = useSelector(
    (state: any) => state?.signUpReducer?.referenceData
  );
  const dealerDetails = useSelector(
    (state: any) => state?.commonReducer?.dealerDetails
  );
  let [formData, setFormData]: any = useState({
    salutation: userDetails?.salutation,
    firstName: userDetails?.firstName,
    lastName: userDetails?.lastName,
    officePhone: userDetails?.officePhone === null ? "" : userDetails?.officePhone,
    mobilePhone: userDetails?.mobilePhone === null ? "" : userDetails?.mobilePhone,
    email: userDetails?.email,
    userCountry: "",
    language: "",
    job: userDetails?.jobDescription,
    subscribedToNewsletters: userDetails?.subscribedToNewsletters === "true",
  });

  const validateRegisterFormData = () => {
    let errorObj: any = {};
    let requiredErrorMsg = t("dnet.field_required.txt");
    if (formData["firstName"] === "") {
      errorObj.firstName = requiredErrorMsg;
    } else if (!NAME_REGEX.test(formData["firstName"])) {
      errorObj.firstName = t("dnet.first_name_not_valid.txt");
    }
    if (formData["lastName"] === "") {
      errorObj.lastName = requiredErrorMsg;
    } else if (!NAME_REGEX.test(formData["lastName"])) {
      errorObj.lastName = t("dnet.first_name_not_valid.txt");
    }
    if (formData["officePhone"] === "") {
      errorObj.officePhone = requiredErrorMsg;
    } else if (!TELEPHONE_NUM_PATTERN.test(formData["officePhone"])) {
      errorObj.officePhone = t("dnet.invalid_excel_format.text");
    }
    if (
      formData["mobilePhone"] !== "" && 
      formData["mobilePhone"] !== null &&
      !TELEPHONE_NUM_PATTERN.test(formData["mobilePhone"])
    ) {
      errorObj.mobilePhone = t("dnet.invalid_excel_format.text");
    }
    if (formData["userCountry"] === "") {
      errorObj.userCountry = requiredErrorMsg;
    }
    if (formData["language"] === "") {
      errorObj.language = requiredErrorMsg;
    }
    if (formData["job"] === "") {
      errorObj.job = requiredErrorMsg;
    }
    setErrorMsg(errorObj);
    if (_.isEmpty(errorObj)) {
      dispatch(setLoading(true));
      dispatch(updateProfile(formData));
    }
  };

  //sets form data based on the fieldName
  const populateForm = (data: any, fieldName: any) => {
    setFormData({ ...formData, [fieldName]: data });
  };
  let [errorMsg, setErrorMsg]: any = useState({});

  const handleCountryChange = (d: any, languageCode: any = "") => {
    let lang: any = [];
    let selectedCountryArr = referenceData?.countries.filter((c: any) => {
      return c?.key === d
    });
    if(selectedCountryArr[0]?.value.includes(",")) {
      selectedCountryArr[0]?.value?.split(",").forEach((elem: any) => {
        let translatedLanguage = t(`dnet.language_${elem}.text`)
        lang.push({ key: elem, label: translatedLanguage })
      })
    } else {
      let translatedLanguage = t(`dnet.language_${selectedCountryArr[0]?.value}.text`)
      lang = [{ key: selectedCountryArr[0]?.value, label: translatedLanguage }]
    }
    setFormData({ 
      ...formData, 
      language: languageCode === "" ? lang[0]?.key : languageCode, 
      userCountry: d 
    });
    setLanguageOptions(lang);
    // let langObj: any = referenceData?.langMapping[d];
    // if (langObj) {
    //   Object.keys(langObj).forEach(function (key) {
    //     lang.push({ value: key, label: langObj[key] });
    //   });
    //   setFormData({ ...formData, language: lang[0]?.value, userCountry: d });
    //   setLanguageOptions(lang);
    // }
  };
  useEffect(() => {
    dispatch(updateProfileSuccess({}));
    if (_.isEmpty(referenceData)) {
      dispatch(setLoading(true));
      dispatch(fetchReferenceData({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setProductResponse = useSelector(
    (state: any) => state?.commonReducer?.productFlow
  );

  const dispatchFetchProdCategory = () => {
    dispatch(fetchProdCategoryResponse({}));
  };

  useEffect(() => {
    if (setProductResponse?.success && updateButtonClicked) {
      dispatchFetchProdCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setProductResponse]);

  const dispatchSetProduct = () => {
    dispatch(
      startProductFlow({
        siteLocale: updateProfileRes?.data?.userProfile?.profile?.locale,
      })
    );
  };

  const prodCategoryResponse = useSelector(
    (state: any) => state?.commonReducer?.prodCategoryResponse
  );

  const siteSectionDetails = useSelector(
    (state: any) => state?.commonReducer?.siteSectionDetails
  );

  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );

  useEffect(() => {
    if (
      !_.isEmpty(prodCategoryResponse) && 
      prodCategoryResponse?.success && 
      updateButtonClicked
    ) {
      let currentRoles = loginDetails?.businessGroups.find(
        (bg: any) => bg.name === currentDealer?.bgName
      )?.roles;
      let bgName = currentDealer?.bgName;
      if (currentRoles?.length > 0) {
        dispatch(
          setSonyMenuItems(
            createMenuItems(
              siteSectionDetails,
              currentRoles,
              bgName,
              updateProfileRes?.data?.userProfile?.profile?.locale,
              loginDetails?.data?.userDetails,
              prodCategoryResponse?.data?.catalogList[0],
              currentDealer?.ecot3,
            )
          )
        );
      }
      dispatch(setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prodCategoryResponse]);

  const [updateButtonClicked, setUpdateButtonClicked] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(updateProfileRes) && updateProfileRes?.success) {
      let successMsg = t(updateProfileRes?.message);
      setMessage(successMsg);
      if (dealerDetails && dealerDetails?.dealers?.length > 0) {
        let details = dealerDetails?.dealers?.[0]?.[loginDetails?.businessGroups[0]?.accounts[0]];
        let accountID = Object.keys(dealerDetails?.dealers?.[0])
        dispatch(
          setCurrentDealer({
            bgName: details?.bgGroup,
            accNum: accountID?.[0],
            accountName: details?.name,
            street: details?.street,
            country: details?.country,
            townCity: details?.townCity,
            loggedInUser: loginDetails,
            singleAccount: true,
            accountFlag: details?.currentAccountFlag,
          })
        );
      }
      setUpdateButtonClicked(true);
      dispatchSetProduct();
    } else {
      dispatch(setLoading(false));
      setMessage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProfileRes]);

  useEffect(() => {
    if (!_.isEmpty(referenceData)) {
      dispatch(setLoading(false));
      // let salutationIndex = referenceData?.salutations?.indexOf(
      //   userDetails?.salutation
      // );
      if (referenceData?.salutations?.length > 0) {
        referenceData?.salutations.forEach((c: any) => {
          c.label = t(c.value);
        });
      }
      let salutationArr = referenceData?.salutations?.filter((elem: any)=>
        elem.label === userDetails?.salutation
      )
      if (referenceData?.salutations?.length > 0) {
        populateForm(
          salutationArr[0]?.key,//referenceData?.salutations?.[salutationIndex],
          "salutation"
        );
      }
      if (
        referenceData?.countries?.length > 0 &&
        Object.keys(userDetails)?.length > 0
      ) {
        let localeArr = userDetails?.locale?.split("_");
        referenceData?.countries.forEach((c: any) => {
          let label = `dnet.country_${c.key}.text`
          c.label = t(label);
        });
        referenceData.countries = stableSort(referenceData?.countries, getComparator("asc", "label"))
        // Use below part once the selected country issue need to be fix
        // let findSelectedCountry: any = referenceData?.countries?.filter(
        //   (elem: any) => elem?.label === loginDetails?.profile?.organisation?.countryISO
        // )?.[0]
        let userCountryObject = referenceData?.countries.filter((c: any) => {
          return c["key"] === localeArr[1];//findSelectedCountry?.key
        });
        let languageObject = userCountryObject//referenceData?.langMapping?.[localeArr[1]];
        setFormData({
          ...formData,
          userCountry: localeArr[1],//findSelectedCountry?.key,
          language: t(languageObject[0]?.value),
          salutation: formData?.salutation,
        });
        handleCountryChange(localeArr[1], localeArr[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceData]);

  return (
    <div>
      <Box className={styles.boxStyle}>
        <Typography
          variant="subtitle2"
          color="primary"
          sx={{
            marginBottom: "10px",
          }}
        >
          {t("dnet.registration_sub_title.text")}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {message && (
              <Typography
                sx={{
                  color: "#155724",
                  backgroundColor: "#d4edda",
                  padding: "8px 14px",
                  borderRadius: "5px",
                  marginBottom: "24px",
                }}
              >
                {message}
              </Typography>
            )}
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <div className={styles.salDropdown}>
              {/* <CustomDropDown
                id="salutation"
                value={formData["salutation"]}
                handleChange={(e: any) =>
                  populateForm(e.target.value, "salutation")
                }
                menuItems={referenceData?.salutations}
                label={t("dnet.registration_salutation.text")}
              /> */}
              <CustomDropDown
                  id="salutation"
                  value={formData["salutation"]}
                  handleChange={(e: any) => {
                    populateForm(e.target.value, "salutation");
                  }}
                  menuOptions={referenceData?.salutations}
                  label={t("dnet.registration_salutation.text")}
                  module="department"
                />
            </div>
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <CustomTextField
              id="firstName"
              label={t("dnet.registration_first_name.text")}
              value={formData["firstName"]}
              changeHandler={(e: any) => {
                populateForm(e.target.value, "firstName");
              }}
              type="text"
              helperText={errorMsg["firstName"]}
              error={errorMsg["firstName"]?.length > 0}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <CustomTextField
              id="lastName"
              label={t("dnet.registration_last_name.text")}
              value={formData["lastName"]}
              changeHandler={(e: any) => {
                populateForm(e.target.value, "lastName");
              }}
              type="text"
              helperText={errorMsg["lastName"]}
              error={errorMsg["lastName"]?.length > 0}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={0}>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <CustomTextField
              id="officePhone"
              label={`${t("registration.officePhone.text")} *`}
              value={formData["officePhone"]}
              changeHandler={(e: any) => {
                populateForm(e.target.value, "officePhone");
              }}
              type="text"
              helperText={errorMsg["officePhone"]}
              error={errorMsg["officePhone"]?.length > 0}
            />
            <Tooltip
              title={t("dnet.phone_info.text")}
              arrow
              placement="right"
              enterTouchDelay={0}
            >
              <IconButton className={styles.infoIcon}>
                <InfoOutlinedIcon color="action" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <CustomTextField
              id="mobilePhone"
              label={t("registration.mobilePhone.text")}
              value={formData["mobilePhone"]}
              changeHandler={(e: any) => {
                populateForm(e.target.value, "mobilePhone");
              }}
              type="text"
              helperText={errorMsg["mobilePhone"]}
              error={errorMsg["mobilePhone"]?.length > 0}
            />
            <Tooltip
              title={t("dnet.phone_info.text")}
              arrow
              placement="right"
              enterTouchDelay={0}
            >
              <IconButton className={styles.infoIcon}>
                <InfoOutlinedIcon color="action" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={0}>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <div className={styles.salDropdown}>
              <CustomDropDown
                id="userCountry"
                value={formData["userCountry"]}
                handleChange={(e: any) => {
                  handleCountryChange(e.target.value);
                }}
                menuOptions={referenceData?.countries}
                label={t("dnet.registration_country.text")}
                module="department"
                helperText={errorMsg["userCountry"]}
                error={errorMsg["userCountry"]?.length > 0}
              />
            </div>
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <div className={styles.salDropdown}>
              <CustomDropDown
                id="language"
                value={formData["language"]}
                handleChange={(e: any) => {
                  populateForm(e.target.value, "language");
                }}
                menuOptions={languageOptions}
                label={t("dnet.registration_language.text")}
                module="department"
                helperText={errorMsg["language"]}
                error={errorMsg["language"]?.length > 0}
              />
            </div>
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <div className={styles.salDropdown}>
              <CustomTextField
                id="job"
                value={formData["job"]}
                changeHandler={(e: any) => {
                  populateForm(e.target.value, "job");
                }}
                label={t("updateProfile.jobDescription.text")}
                helperText={errorMsg["job"]}
                error={errorMsg["job"]?.length > 0}
              />
            </div>
          </Grid>
        </Grid>
        <Box mt={2}>
          <div className={styles.checkboxStyle}>
            <Checkbox
              checked={formData["subscribedToNewsletters"]}
              onChange={(e: any) => {
                populateForm(e.target.checked, "subscribedToNewsletters");
              }}
              size="small"
              sx={{ paddingLeft: "0" }}
            />
            <Typography
              component="div"
              variant="body2"
              color="text.secondary"
              sx={{
                paddingTop: "2px",
              }}
            >
              {t("dnet.registration_agreement2.text")}
            </Typography>
          </div>
        </Box>
        <Grid container spacing={3} mt={0}>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <CustomButton
              id="registerButton"
              label={t("dnet.update_profile.section")}
              onClick={validateRegisterFormData}
              color="primary"
              margin="0"
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
