import { Box, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getPaidItemsData, getPaidItemsDataSuccess } from "../../../state/actions";
import CustomButton from "../../atoms/customButton";
import CustomDropDown from "../../atoms/customDropDown";
import CustomTextField from "../../atoms/customTextField";
import logger from "../../helper/logger";
import styles from "./paidItems.module.scss";
import PaidItemTable from "./paidItemTable";
var _ = require("lodash");

export const PaidItems = (props: any) => {
  const { t } = useTranslation();
  const { period, refValue, from } = props;
  const [value, setValue] = useState("");
  const [referenceValue, setReferenceValue] = useState<string>("");
  const [refresh, setRefresh] = useState<Boolean>(false);
  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.userDetails
  );
  const paidItemsData = useSelector(
    (state: any) => state?.gazelleReducer?.paidItemsData
  );
  const paidItemSelectedMonth = useSelector(
    (state: any) => state?.gazelleReducer?.paidItemSelectedMonth
  );
  const lastRetrievalDate = paidItemsData?.lastRetrievalDate;
  const paidItemsTableData = paidItemsData?.invoices;
  const [menuItems, setMenuItems] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>([]);
  const [endDate, setEndDate] = useState<any>([]);
  const [csvData, setCsvData] = useState([]);
  const [readyToDownload, setReadyToDownload] = useState(false);

  const previosMonthNumberArray = [
    t("gzl.last_two_month_key.text"),
    t("gzl.last_three_month_key.text"),
    t("gzl.last_six_month_key.text"),
  ];

  const downloadTableLabel = t("gzl.csv_paiditems.text");

  const downloadCSV = () => {
    // CSV format creation for downloading
    logger.info("Download Paid Items CSV");
    const csvData: any = [
      [
        t("gzl.invoice_credit_note.text"),
        t("gzl.doc_type.text"),
        t("gzl.date.text"),
        t("gzl.your_ref.text"),
        t("gzl.org_inv_value.text"),
        t("gzl.amount_collected.text"),
        t("gzl.currency.text"),
      ],
      ...paidItemsData?.invoices?.map((item: any) => [
        item?.creditNote,
        item?.documentType,
        item?.invoiceDate,
        item?.yourReference,
        item?.amount,
        item?.openAmount,
        item?.currency,
      ]),
    ];
    setCsvData(csvData);
    setReadyToDownload(true);
  };

  let csvLink: any = React.createRef();
  useEffect(() => {
    if (!_.isEmpty(csvData) && readyToDownload) {
      csvLink.current.link.click();
      setReadyToDownload(false);
    }
  }, [readyToDownload]);

  useEffect(() => {
    if (from === "refSearch" && refValue !== "") {
      setReferenceValue(refValue);
      setValue(period);
    }
  }, [refValue, period]);

  useEffect(() => {
    if (from === "refSearch" && (refValue !== "")){
      getAPIData()
      referenceHandler();
    }
  }, [value]);

  const handleChange = (event: any) => {
    // on change of month from the dropdown
    logger.info("Selected value ",event.target.value);
    setValue(event.target.value);
    if (from !== "refSearch") {
      getAPIData();
      setReferenceValue("");
    }
  };

  const referenceSetter = (event: any) => {
    if (event.target.value === "") {
      getAPIData();
    }
    setReferenceValue(event.target.value);
  };

  const referenceHandler = () => {
    // Filtering out data on the basis of reference value
    if (referenceValue?.length > 0) {
      const arr = paidItemsTableData?.filter((elem: any) => {
        return (
          elem.yourReference === referenceValue ||
          elem.yourReference.includes(referenceValue)
        );
      });
      if(paidItemsData) paidItemsData.invoices = arr;
      dispatch(getPaidItemsDataSuccess(paidItemsData));
    } else {
      getAPIData();
    }
  };

  const onReferesh = () => {
    // On click of refresh fetching data again
    logger.info("Refreshing Paid items data");
    setRefresh(!refresh);
    getAPIData();
    setReferenceValue("");
  }

  const getMonths = () => {
    // Functionality to create a list of months 
    // and respective start & end date from past 1 year
    let startArr = [];
    let endArr = [];

    let previousMonthsArr = [];
    for (let i = 0; i <= 11; i++) {
      let monthName = moment(new Date())
        .subtract(i, "month")
        .startOf("month")
        .format("MM YYYY");
      let yearName = monthName.split(" ");
      let current: any = new Date(
        parseInt(yearName[1]),
        parseInt(yearName[0]),
        0
      );
      previousMonthsArr.push(moment(current).format("MMMM YYYY"));
      startArr.push(moment("01 " + previousMonthsArr[i]).format("YYYYMMDD"));
      i === 0
        ? endArr.push(moment(new Date()).format("YYYYMMDD"))
        : endArr.push(moment(current).format("YYYYMMDD"));
    }
    previousMonthsArr = [...previousMonthsArr, ...previosMonthNumberArray];
    setStartDate(startArr);
    setEndDate(endArr);
    setMenuItems(previousMonthsArr);
    setValue(paidItemSelectedMonth ? paidItemSelectedMonth : previousMonthsArr[0]);
  };

  const saveAccountDetails = useSelector(
    (state: any) => state?.commonReducer?.saveAccountDetails
  )

  const getAPIData = () => {
    // hitting API on the basis of selected month
    let currentDate = "";
    let lastDate = "";
    if (
      value === previosMonthNumberArray[0] ||
      value === previosMonthNumberArray[1] ||
      value === previosMonthNumberArray[2]
    ) {
      let date = value.split(" ");
      let n = parseInt(date[1]);
      lastDate = endDate[0];
      currentDate = startDate[n - 1];
    } else {
      currentDate = startDate[menuItems.indexOf(value)];
      lastDate = endDate[menuItems.indexOf(value)];
    }
    dispatch(
      getPaidItemsData({
        language: userDetails?.language,
        username: userDetails?.userName,
        organizationKey: "GB00",
        partnerKey: saveAccountDetails?.payerToAcc?.number,
        startDate: currentDate,
        endDate: lastDate,
        itemType: 1,
        selectedMonth: value,
      })
    );
  };

  useEffect(()=>{
    if(from === "refSearch" && paidItemsTableData){
      const arr = paidItemsTableData?.filter((elem: any) => {
        return (
          elem.yourReference === referenceValue ||
          elem.yourReference.includes(referenceValue)
        );
      });
      if(paidItemsData) paidItemsData.invoices = arr;
      dispatch(getPaidItemsDataSuccess(paidItemsData));
    }
  },[paidItemsTableData])

  useEffect(() => {
    if (from !== "refSearch" && value!=="") {
      getAPIData();
    }
    if (value === "")
      getMonths();
  }, [value]);

  return (
    <>
      <Typography 
        variant={from !== "refSearch" ? "subtitle1" : "subtitle1"} 
        className={styles.header} 
        pb={3}
        color="primary"
      >
        {t("hacksite.function.paid_items")}
      </Typography>
      <Box>
        <Box className={styles.paidBoxContainer}>
          <CustomTextField
            label={t("gzl.last_retrieval_date.text")}
            value={
              moment(lastRetrievalDate).utc().format(
                "DD/MM/YYYY HH:mm"
              ) + " UTC"
            }
            disabled = {true}
          />
          <CustomDropDown
            value={value}
            handleChange={handleChange}
            menuItems={menuItems}
            label={t("gzl.period.text")}
            id="previous12-months-dropdown"
          />
          <div className={styles.yourReferenceStyle}>
            <CustomTextField
              id="RefrenceSearchInput"
              label={t("gzl.your_ref.text")}
              changeHandler={referenceSetter}
              value={referenceValue}
            />
            <CustomButton
              id="refrenceSearchFindButton"
              style={{ marginLeft: "0" }}
              label={t("gzl.find_button.text")}
              onClick={() => referenceHandler()}
            />
          </div>
        </Box>
        <Box className={styles.tableContainer}>
          <div className={styles.tableHeader}>
            <Typography variant="body2" id="paid-item-table-headline">
              {t("gzl.paid_items_headline.text")}
            </Typography>
            <div>
              <CustomButton
                id="Refresh"
                label={t("gzl.refresh.text")}
                onClick={onReferesh}
              />
              <CSVLink
                data={csvData}
                separator={`;`}
                enclosingCharacter={``}
                filename={downloadTableLabel}
                className={styles.hidden}
                ref={csvLink}
              ></CSVLink>
              <CustomButton
                id="downloadCSV"
                label={t("gzl.download_csv.text")}
                onClick={downloadCSV}
                color="primary"
              />
            </div>
          </div>
          <PaidItemTable />
        </Box>
      </Box>
    </>
  );
};
