import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import styles from "./awardsAndReviewsTab.module.scss";

export const AwardsAndReviewTab = () => {

  const productDetailsData = useSelector(
    (state: any) => state?.productReducer?.productDetailsData
  );

  return (
    <Box className={styles.mainContainer}>
      {productDetailsData?.awards?.map((award: any, index: any) => (
        award?.includes(".com") && <img key={index} src={award} alt="Award" title="Award" />
      ))}
      {productDetailsData?.expertReviews?.map((review: any, index: any) => (
        review?.includes(".com") && <img key={index} src={review} alt="Review" title="Review" />
      ))}
    </Box>
  );
};
