import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CONTEXT_ORDER, TOAST_SEVERITY } from "../../../constants/commonConstants";
import { ROUTES_LABEL } from "../../../constants/routesLabels";
import { fetchProductContentData, getCategoryProductSuccess, getContextDetails, setOpenToast, setProductContentData } from "../../../state/actions";
import { DisplayCategories } from "../displayCategories";
import styles from "./productCategory.module.scss";
import { categoryReplace } from "../../helper/util";
var _ = require("lodash");

export const ProductCategory = () => {
  const navigate = useNavigate();
  const categoryNames = useParams()["*"];
  const dispatch = useDispatch();
  const { t } = useTranslation();


  useEffect(() => {
    dispatch(getCategoryProductSuccess({}));
    dispatch(
      getContextDetails({
        key: CONTEXT_ORDER?.PRODUCT_MODULE_PAGE,
      })
    );
  }, []);

  const contextDetails = useSelector(
    (state: any) => state?.commonReducer?.contextDetails
  );

  useEffect(() => {
    if (!_.isEmpty(contextDetails)) {
      setIsReseller(contextDetails?.isReseller);
    }
  }, [contextDetails]);
  const [isReseller, setIsReseller] = useState<any>(false);

  useEffect(()=>{
    const categoryPath= categoryNames? categoryNames?.split("/"):[];
    const currentCategory= getCategoryByPath(prodCategoryResponse?.data?.catalogList[0]?.children,categoryPath);
    if(currentCategory)
    {
      setSelectedCategory(currentCategory);
    }
  },[useParams()]);

  const getCategoryByPath=(categories:any,path:any)=>{
    return path.reduce((currentCategory:any,categoryName:any)=>{
      return currentCategory?.children?.find((subCategory:any)=> categoryReplace(subCategory.displayName) === categoryReplace(categoryName));
    },{children: categories});
    };

  const prodCategoryResponse = useSelector(
    (state: any) => state?.commonReducer?.prodCategoryResponse
  );

  const [selectedCategory, setSelectedCategory] = useState<any>({});


  const [selectedItems, setSelectedItems] = useState<any>({});

  const displaySelected = (selectedCategory: any) => {
    if (Object.keys(selectedItems).includes(selectedCategory?.id)) {
      let displayItem: any = [];
      selectedItems[selectedCategory?.id].forEach((ele: any) => {
        displayItem.push(ele?.id);
      });
      dispatch(
        fetchProductContentData({
          categoryName: displayItem,
        })
      );
      isReseller
        ? navigate(`/${ROUTES_LABEL.RESELLER_PROD_CONTENT_DETAILS}`)
        : navigate(`/${ROUTES_LABEL.PROD_CONTENT_DETAIL}`);
      //navigate(`/${ROUTES_LABEL.PROD_CONTENT_DETAIL}`);
    } else {
      dispatch(
        setOpenToast({
          openToast: true,
          severity: TOAST_SEVERITY.ERROR,
          message: t("dnet.product_multiple_series_error.text"),
        })
      );
    }
  };

  const checkHandler = (e: any, category: any, parent: any) => {
    let items = JSON.parse(JSON.stringify(selectedItems));
    if (Object.keys(items).includes(parent?.id)) {
      let exisitingIndex = items[parent?.id]?.findIndex(
        (ele: any) => ele?.id === category?.id
      );
      if (exisitingIndex > -1) {
        items[parent?.id].splice(exisitingIndex, 1);
        if (items[parent?.id]?.length === 0) {
          delete items[parent?.id];
        }
      } else {
        items[parent?.id].push(category);
      }
    } else {
      items[parent?.id] = [category];
    }
    setSelectedItems(items);
  };

  const findCategory=(categoryList:any,name:any,path:any)=>{
    let paths:any=[];
    for(const category of categoryList)
    {
      const currentPath=[...path,categoryReplace(category?.displayName)];
      if(categoryReplace(category.displayName)===name){
        paths.push(`/${currentPath.join('/')}`);
      }
      if(category?.children?.length>0)
      {
        const subPaths:any= findCategory(category?.children,name,currentPath);
        paths=[...paths,...subPaths];
      }
    }
    return paths;
  }

  const getByPath=(categories:any,categoryName:any)=>{
    for(const category of categories){
      const result= findCategory([category],categoryName,[]);
      if(!_.isEmpty(result)){
        return result;
      }
    }
    return null;
  }

  const chooseBestPath=(paths:any,mainCategory:any,subCategory:any,sub:any)=>{
    let bestMatch= paths?.[0];
    let maxMatches=0;

    if(paths?.length === 1) return paths?.[0];

    for(const path of paths){
      const pathSegments = path.split('/').filter((segment:any)=>segment !== '');
      const categorySegments=[mainCategory,subCategory,sub].filter((segment:any)=>segment);
      let matches=0;
      for(const categorySegment of categorySegments)
      {
        if(pathSegments.includes(categoryReplace(categorySegment?.displayName))){
          matches++;
        }
      }
      if(matches>maxMatches){
        maxMatches=matches;
        bestMatch=path;
      }
    }
    return bestMatch;
  }
  
  const productClickHandler = (  
    mainCategory:any,
    subCategory: any,
    sub:any
  ) => {
    let navigatePath:any="";
    let temp:any = (mainCategory && subCategory && sub)? getByPath(prodCategoryResponse?.data?.catalogList[0]?.children,categoryReplace(sub?.displayName)): getByPath(prodCategoryResponse?.data?.catalogList[0]?.children,categoryReplace(subCategory?.displayName));
    navigatePath=chooseBestPath(temp,mainCategory,subCategory,sub);
    let selectedSubCategory:any=  (mainCategory && subCategory && sub)? sub: subCategory;
    if ((sub && sub?.children?.length >0) || ( sub === undefined && subCategory?.children?.length > 0) ){
      setSelectedCategory(selectedSubCategory);
    } else {
      dispatch(
        fetchProductContentData({
          categoryName: [selectedSubCategory?.id],
        })
      );
      isReseller
      ? navigate(
        `/${ROUTES_LABEL.RESELLER_PROD_CONTENT_DETAILS}${navigatePath}`
      )
      : navigate(
        `/${ROUTES_LABEL.PROD_CONTENT_DETAIL}${navigatePath}`)
    }
  };

  return (
    <>
      <Typography variant="h5" className={styles.header} pb={2} color="primary">
        {selectedCategory?.displayName}
      </Typography>

      <Box className={styles.boxContainer}>
        <DisplayCategories
          selectedCategory={selectedCategory}
          checkHandler={checkHandler}
          productClickHandler={productClickHandler}
          displaySelected={displaySelected}
        />
      </Box>
    </>
  );
};
