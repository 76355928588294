import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../atoms/customButton";
import CustomTextField from "../../../atoms/customTextField";
import styles from "./orderPageModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomTextArea from "../../../atoms/customTextArea";
import { useSelector, useDispatch } from "react-redux";
import { getCalendarData, myQuotationsSFDCPlaceOrder, myQuotationsSFDCPlaceOrderSuccess } from "../../../../state/actions";
import { useNavigate } from "react-router-dom";
import { shouldDisableDateFunc } from "../../../helper/util";
const tripleBarGif = require("../../../../assets/images/ajax-loader-triplebar.gif");
var _ = require("lodash");

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
  paddingBottom: "45px",
  textAlign: "left",
  overflowY: "scroll",
  height: "70%",
};

export const OrderPageModal = ({ openModal, closeModal, selectedQuotation }: any) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [orderEntryObj, setOrderEntryObj] = useState({
    shipToAccounts: "0",
    referenceNo: "",
    requestedDeliveryDate: null,
    deliveryOption: "partial",
    specialInstruction: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contextDetails = useSelector(
    (state: any) => state?.commonReducer?.contextDetails
  );
  const calendarDetails = useSelector(
    (state: any) => state?.commonReducer?.calendarDetails
  );
  const placeOrderResponse = useSelector(
    (state: any) => state?.myQuotationsReducer?.placeOrderResponse
  );
  const [errorObj, setErrorObj] = useState<any>({});
  const [basicCalender, setBasicCalender] = useState<any>(true);
  const [startingMinDate, setStartingMinDate] = useState<any>(null);
  const [calenderId, setCalenderId] = useState("");
  const [selectedYear, setSelectedYear] = useState<any>("");
  const [showLoading, setShowLoading] = useState<any>(false);

  const handleReportChange = (fieldname: any, data: any) => {
    let tempObj = orderEntryObj;
    if (fieldname === "requestedDeliveryDate") {
      tempObj = {
        ...tempObj,
        [fieldname]: new Date(data?.$d),
      };
    } else {
      if (fieldname === "shipToAccounts") {
        let selectedShipAccount = contextDetails?.shipToAccounts?.filter(
          (acc: any) => acc?.number === data
        );
        setCalenderId(selectedShipAccount[0]?.calenderId);
        tempObj = {
          ...tempObj,
          requestedDeliveryDate: null,
        };
      }
      tempObj = {
        ...tempObj,
        [fieldname]: data,
      };
    }
    setErrorObj({});
    setOrderEntryObj(tempObj);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const submitHandler = () => {
    let report: any = orderEntryObj;
    let errors: any = errorObj;
    if (report?.shipToAccounts === "0" || report?.shipToAccounts === "") {
      errors = {
        ...errors,
        shipToAccounts: t("dnet.please_select_shipto.text").toString(),
      };
    } else if (report?.referenceNo === "") {
      errors = {
        ...errors,
        referenceNo: t("dnet.enter_reference.text").toString(),
      };
    } else if (
      report?.requestedDeliveryDate === null ||
      report?.requestedDeliveryDate === ""
    ) {
      errors = {
        ...errors,
        requestedDeliveryDate: t("dnet.specify_rdd.text").toString(),
      };
    }
    // } else if (report?.specialInstruction === "") {
    //   errors = {
    //     ...errors,
    //     specialInstruction: t(
    //       "dnet.salesforce_quotation_specialinstructions_placeholder.text"
    //     ).toString(),
    //   };
    // }
    setErrorObj(errors);
    if (Object.keys(errors)?.length === 0) {
      let items: any = {};
      selectedQuotation?.items?.forEach((elem: any) => {
        elem?.warranties?.length > 0 && elem?.warranties?.forEach((subElem: any) => {
          if ((selectedQuotation?.type === 1 && parseInt(subElem?.orderQuantity) > 0 && subElem?.orderQuantity !== "" && subElem?.orderQuantity !== undefined) || selectedQuotation?.type === 0) {
            items={
              ...items,
              [subElem?.itemRef]: selectedQuotation?.type === 0 ? subElem?.quantity : subElem?.orderQuantity
            }
          }  
        })
        if ((selectedQuotation?.type === 1 && parseInt(elem?.orderQuantity) > 0 && elem?.orderQuantity !== "" && elem?.orderQuantity !== undefined) || selectedQuotation?.type === 0) {
          items={
            ...items,
            [elem?.itemRef]: selectedQuotation?.type === 0 ? elem?.quantity : elem?.orderQuantity
          }
        }
      })
      setShowLoading(true)
      dispatch(myQuotationsSFDCPlaceOrder({
        q: selectedQuotation?.ref,
        items,
        specialInstructions: report?.specialInstruction,
        yourReference: report?.referenceNo,
        shipTo: report?.shipToAccounts,
        Qtype: report?.deliveryOption === "partial" ? "p" : "c",
        requestedDeliveryDate: moment(report?.requestedDeliveryDate).format("DD/MM/YYYY"),
      }))
    }
  };

  useEffect(()=>{
    if(placeOrderResponse?.success && placeOrderResponse?.data?.success) {
      navigate("/My_Quotations");
    }
    setShowLoading(false);
  },[placeOrderResponse])

  const basicCalenderLogic = () => {
    if (
      contextDetails?.isCustomCalendarEnabled &&
      !(
        orderEntryObj?.shipToAccounts === "0" ||
        orderEntryObj?.shipToAccounts === "multiShip"
      )
    ) {
      let tempCalenderId =
          calenderId === "" || calenderId === undefined || calenderId === null ? 
          contextDetails?.defaultCalendarId : calenderId;
      if(tempCalenderId !== "") {
        setBasicCalender(false);
        let cdate = new Date();
        setSelectedYear(cdate.getFullYear());
        dispatch(
          getCalendarData({
            calendarId: tempCalenderId,
            year: cdate.getFullYear(),
          })
        );
      } else {
        setBasicCalender(true);
      }
    } else {
      setBasicCalender(true);
    }
  }
  useEffect(() => {
    basicCalenderLogic()
  }, [calenderId]);

  useEffect(() => {
    let currentDate = new Date();
    let twoDaysLater = new Date(currentDate);
    twoDaysLater.setDate(currentDate.getDate() + 2);
    setStartingMinDate(twoDaysLater.toDateString());
    //To ensure previous data is removed
    dispatch(myQuotationsSFDCPlaceOrderSuccess({}))
  }, []);

  const handleMonthChange = (newMonth: any) => {
    // if (newMonth.$y !== selectedYear && !basicCalender) {
    //   setSelectedYear(newMonth.$y)
    //   dispatch(
    //     getCalendarData({
    //       calendarId: calenderId,
    //       year: newMonth.$y,
    //     })
    //   );
    // }
  }
  const shouldDisableDate = (date: any) => {
    return shouldDisableDateFunc(date, basicCalender, calendarDetails)
  }
  return (
    <div>
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div className={styles.modalHeader}>
            <Typography variant="subtitle2" color="primary">
              {t("dnet.salesforce_quotation_confirm_order.text")}
            </Typography>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </div>
          <div>
            <Grid container className={styles.boxDesign} spacing={2}>
              <Grid item xs={12}>
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                  {t("dnet.deliver_to.text")?.toString()}
                </Typography>
                <FormControl fullWidth>
                  <Select
                    id="select1"
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    value={orderEntryObj?.shipToAccounts}
                    error={
                      Object.keys(errorObj)?.length > 0 &&
                      errorObj?.shipToAccounts?.length > 0
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      handleReportChange("shipToAccounts", e.target.value)
                    }
                    sx={{
                      borderRadius: "4px",
                      textAlign: "initial",
                      fontSize: "14px",
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: 200,
                        },
                      },
                    }}
                    size="small"
                  >
                    <MenuItem key={0} value={0} disabled={true}>
                      {t("dnet.please_select_shipto.text")}
                    </MenuItem>
                    {contextDetails?.shipToAccounts?.map((option: any) => {
                      return (
                        <MenuItem key={option?.number} value={option?.number}>
                          {`${option?.number} - ${option?.name}, ${option?.address?.street}, ${option?.address?.townCity} (${option?.address?.country})`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText style={{ color: "#d32f2f" }}>
                    {errorObj?.shipToAccounts}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                  {t("dnet.your_reference.text")?.toString()}
                </Typography>
                <div>
                  <CustomTextField
                    id="referenceNo"
                    label=""
                    value={orderEntryObj?.referenceNo}
                    changeHandler={(e: any) => {
                      handleReportChange("referenceNo", e.target.value);
                    }}
                    helperText={errorObj?.referenceNo}
                    error={
                      Object.keys(errorObj)?.length > 0 &&
                      errorObj?.referenceNo?.length > 0
                        ? true
                        : false
                    }
                  />
                  <Tooltip
                    title={t("dnet.mandatory.text")}
                    arrow
                    placement="right"
                    enterTouchDelay={0}
                  >
                    <IconButton className={styles.infoIcon}>
                      <InfoOutlinedIcon color="action" />
                    </IconButton>
                  </Tooltip>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                  {t("dnet.requested_delivery_date.text")?.toString()}
                </Typography>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      openTo="day"
                      views={["year", "month", "day"]}
                      value={orderEntryObj?.requestedDeliveryDate}
                      onChange={(newValue: any) => {
                        handleReportChange("requestedDeliveryDate", newValue);
                      }}
                      renderInput={(params) => (
                        <TextField 
                          {...params}
                          inputProps={{...params.inputProps, readOnly: true}}
                          size="small"
                          error={Object.keys(errorObj)?.length > 0 &&
                            errorObj?.requestedDeliveryDate?.length > 0
                              ? errorObj?.requestedDeliveryDate
                              : false
                          }
                        />
                      )}
                      inputFormat="DD/MM/YYYY"
                      minDate={startingMinDate}//basicCalender ? startingMinDate : null}
                      shouldDisableDate={shouldDisableDate}
                      onMonthChange={handleMonthChange}
                    />
                  </LocalizationProvider>
                </div>
                {Object.keys(errorObj)?.length > 0 &&
                  errorObj?.requestedDeliveryDate?.length > 0 && (
                    <Typography variant="caption" color="error" ml={2}>
                      {errorObj?.requestedDeliveryDate}
                    </Typography>
                  )}
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel
                    id="radio-buttons-group-label"
                    className={styles.formLabelStyle}
                  >
                    {t(
                      "dnet.salesforce_quotation.preferredDeliveryOptionTypes.text"
                    )?.toString()}
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="radio-buttons-group-label"
                    defaultValue="partial"
                    name="radio-buttons-group"
                    onChange={(e: any) => {
                      handleReportChange("deliveryOption", e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="partial"
                      control={<Radio size="small" />}
                      label={t("dnet.partial.text")}
                      sx={styles.formLabelStyle}
                    />
                    <FormControlLabel
                      value="complete"
                      control={<Radio size="small" />}
                      label={t("dnet.complete.text")}
                      sx={styles.formLabelStyle}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                  {t("dnet.special_instructions_colon.text")?.toString()}
                </Typography>
                <div>
                  <CustomTextArea
                    label=""
                    minRows={4}
                    placeholder={t(
                      "dnet.salesforce_quotation_specialinstructions_placeholder.text"
                    )}
                    maxRows={10}
                    changeHandler={(e: any) => {
                      handleReportChange("specialInstruction", e.target.value);
                    }}
                    error={
                      Object.keys(errorObj)?.length > 0 &&
                      errorObj?.specialInstruction?.length > 0
                        ? true
                        : false
                    }
                    helperText={errorObj?.specialInstruction}
                  />
                </div>
              </Grid>
            </Grid>
            <div className={styles.processMsgStyle}>
              <CustomButton
                id="submit"
                label={t("dnet.submit.text")}
                onClick={() => submitHandler()}
                margin="20px 0"
              />
            </div>
            {showLoading && ( 
              <div className={styles.processMsgStyle}>
                <Typography variant="caption" mr={1}>
                  {t("dnet.please_wait_while_creating.text")?.toString()}
                </Typography>
                <img src={tripleBarGif} />
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
};
