import { Box, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { EMAIL_REGEX } from "../../../../constants/commonConstants";
import { createDebitNote, createDebitNoteSuccess, createInvoiceDebitNote } from "../../../../state/actions";
import CustomButton from "../../../atoms/customButton";
import CustomDropDown from "../../../atoms/customDropDown";
import CustomModal from "../../../atoms/customModal";
import CustomTextField from "../../../atoms/customTextField";
import logger from "../../../helper/logger";
import styles from "./debit-note-form.module.scss";
var _ = require("lodash");

export const DebitNoteForm = (props: any) => {
  const { t } = useTranslation();
  const { reason, setReason, enquiryCheck, fromPaymentPage, invoiceId, selectedMetadata } = props;
  const dispatch = useDispatch();

  const userCurrencies = useSelector(
    (state: any) => state?.gazelleReducer?.userCurrencies
  );

  const saveAccountDetails = useSelector(
    (state: any) => state?.commonReducer?.saveAccountDetails
  )

  //prepopulate the currency field
  useEffect(()=>{
    if(userCurrencies?.length > 0) {
      formData['Currency'] = userCurrencies[0];
    }
  },[userCurrencies]);

  let [formData, setFormData] : any = useState({});
  let [errorMsg, setErrorMsg] : any = useState({});

  //sets form data based on the fieldName taken from xml
  const populateForm = (data:any ,fieldName: any) => {
    setFormData({ ...formData, [fieldName]: data });
  }

  //return the type of input based on validation field of xml
  const getInputType = (validation: any) => {
    if(validation) {
      switch(validation) {
        case "type:double" : 
        case "type:numeric" : 
          return "number";
        default : return "text";
      }
    }
  }

  //computes length property
  const getLength = (typeProperties : any) => {
    if(typeProperties){
      let criteria = typeProperties?.split(",");
      if(criteria[0]?.includes("Maxlength")){
        let dataLength = criteria[0]?.split(":")[1];
        return dataLength;
      }
    }
    return 100;
  }
 
  // return either <input> or <select> or <textarea> based on xml data
  const getInputField = (data: any) => {
    switch (data?.type) {
      case "input":
        return (
          <CustomTextField
            id={data?.name}
            label={t(data?.messageKey)}
            value={formData[data?.name]}
            changeHandler={(e: any) => {
              e.target.value = e.target.value.toString().slice(0,getLength(data?.typeProperties))
              populateForm(
                e.target.value,
                data?.name
              )}
            }
            type={getInputType(data?.validation)}
            helperText={errorMsg[data?.name]}
            error={errorMsg[data?.name]?.length > 0}
          />
        );
      case "select_box":
        return (
          <div className={styles.currencyDropdown}>
            <CustomDropDown
              id={data?.name}
              value={formData[data?.name]}
              handleChange={(e: any) =>
              populateForm(
                e.target.value,
                data?.name
              )
            }
              menuItems={userCurrencies}
              label={t(data?.messageKey)}
            />
          </div>
        );
      case "text_area":
        return (
          <TextField
            sx={{ width: "223px" }}
            id={data?.name}
            placeholder={t("gzl.max_character.text")?.toString()}
            multiline
            rows={2}
            label={t(data?.messageKey)}
            onChange={(e: any) =>{
              e.target.value = e.target.value.toString().slice(0,500)
              populateForm(
                e.target.value,
                data?.name
              )}
            }
            value={formData[data?.name]}
          />
        );
      default:
        break;
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setReason("");
    setOpenModal(false);
    dispatch(createDebitNoteSuccess(false));
  };

  const saveHandler = () => {
    let errorList : any= {};
    let validForm = true;
    //validation logic for required fields and email format
    selectedMetadata?.fields?.field.forEach((field:any) => {
        if(field?.required === "true") {
          if(formData[field.name] === undefined || !formData[field.name]) {
            errorList[field.name] = t("gzl.alertBlank.text");
            validForm = false;
          } else if(field?.validation === "format:mail"){
            if (!EMAIL_REGEX.test(formData[field.name] )) {
              errorList[field.name] = t("gzl.alertEmail.text");
              validForm = false;
            }
          }
        }
    });
    setErrorMsg(errorList);
    if(validForm) {
      if(enquiryCheck || fromPaymentPage){
        dispatch(
          createInvoiceDebitNote(createPayloadforAddReply())
        );
      }
      else
        dispatch(
          createDebitNote(createPayload())
        );
    }
  }

  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.userDetails
  );

  const createPayload = () => {
    let dynamicBapiFields : any = [];
    let reasonTextList : any = [];
    selectedMetadata?.fields?.field?.forEach((ele:any) => {
      if(ele?.bapiInputName) { // adds in to dynamicBapiFields if there is an entry for bapi in xml
        dynamicBapiFields.push({
          [ele?.bapiInputName] : formData[ele?.name]
        })
      } else { // if no bapi field in xml, user input content is added in reasonTextList array
        if(formData[ele?.name])
        reasonTextList.push(formData[ele?.name]);
      }
    });
    return {
      companyCode: "GB00",
      partnerKey: saveAccountDetails?.payerToAcc?.number,
      username: userDetails?.userName,
      reasonCode: selectedMetadata?.sapCode,
      dynamicBapiFields: dynamicBapiFields,
      reasonTextList: reasonTextList,
      language: userDetails?.language,
    }
  }

  //creates payload for payment or create enquiry modules
  const createPayloadforAddReply = () => {
    let dynamicBapiField : any = [];
    let reasonTextList : any = [selectedMetadata?.sapCode];
    selectedMetadata?.fields?.field?.forEach((ele:any) => {
      if(ele?.bapiInputName) {
        dynamicBapiField.push({
          [ele?.bapiInputName] : formData[ele?.name]
        })
      } else {
        if(formData[ele?.name])
        reasonTextList.push(formData[ele?.name]);
      }
    });
    return {
      invoiceId,
      language: userDetails?.language,
      accountId: saveAccountDetails?.payerToAcc?.number,
      username: userDetails?.userName,
      dynamicBapiField: dynamicBapiField,
      reasonTextList: reasonTextList
    }
  }

  //clears form data
  const clearFormData = () => {
    let temp : any = {};
    logger.info("Cleared form data");
    Object.keys(formData).forEach(key => {
      temp[key] = "";
    });
    if(userCurrencies?.length > 0) {
      temp['Currency'] = userCurrencies[0];
    }
    setFormData(temp);
    setErrorMsg(temp);
  }

  const createDebitNoteStatus = useSelector(
    (state: any) => state?.gazelleReducer?.createDebitNote
  );

  useEffect(() => {
    if (createDebitNoteStatus) {
      logger.info("Succesfully created debit note");
      setOpenModal(true);
      clearFormData();
    }
  }, [createDebitNoteStatus]);

  return (
    <>
      <Box className={styles.formContainer}>
        <Grid container spacing={3}>
          {selectedMetadata?.fields?.field?.map((field: any) => (
            <Grid item lg={3} md={6} sm={6} xs={12}>
              {getInputField(field)}
            </Grid>
          ))}
           <Grid
            item
            lg={3} md={6} sm={6} xs={12}
          >
            <CustomButton
              id="saveButton"
              label={t("gzl.send_button.text")}
              onClick={saveHandler}
              color="primary"
            />
            <CustomButton
              id="cleatButton"
              label={t("gzl.clear_button.text")}
              onClick={clearFormData}
            />
          </Grid>
        </Grid>
      </Box>
      <CustomModal
        id="confirmationModal"
        open={openModal}
        closeModal={closeModal}
        heading={
          enquiryCheck
            ? t("gzl.conf_enquiry_title.text")?.toString()
            : fromPaymentPage
            ? t("gzl.conf_enquiry_title.text")?.toString()
            : t("gzl.conf_debit_note.text")?.toString()
        }
        content={t("gzl.conf_enquiry_headline.text")}
      />
    </>
  );
};
