import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./noResultFound.module.scss";

export const NoResultFound = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box className={styles.errorStyle}>
        <Typography component="div" variant="caption">
          {t("dnet.no_matching_result.text")}
        </Typography>
      </Box>
      <Box mt={2} className={styles.infoStyle}>
        <Typography component="div" variant="subtitle2">
          {t("dnet.search_hints.text")}
        </Typography>
        <ul>
          <Typography
            component="div"
            variant="caption"
            dangerouslySetInnerHTML={{
              __html: t("dnet.no_search_result_first.text").toString(),
            }}
          />
          <Typography
            component="div"
            variant="caption"
            dangerouslySetInnerHTML={{
              __html: t("dnet.no_search_result_second.text").toString(),
            }}
          />
          <Typography
            component="div"
            variant="caption"
            dangerouslySetInnerHTML={{
              __html: t("dnet.no_search_result_third.text").toString(),
            }}
          />
        </ul>
      </Box>
    </>
  );
};
