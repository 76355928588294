import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./termsAndCondition.module.scss";
import { getTermsAndCondition } from "../../state/actions";
const arrowImage = require("../../assets/images/arrow_006_004.gif");
const spacerImage = require("../../assets/images/spacer.gif");

export const TermsAndCondition = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const termsAndConditionString = useSelector(
    (state: any) => state?.commonReducer?.termsAndCondition
  );
  useEffect(() => {
    dispatch(getTermsAndCondition({ locale: i18n?.language }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [htmlString, setHtmlString] = useState<any>([]);
  const [checkForString, setCheckForString] = useState(false);
  useEffect(() => {
    if (termsAndConditionString?.length > 0) {
      let changeContent: any = termsAndConditionString;
      changeContent = changeContent.replace(
        /src="(.*?)"/g,
        (match: any, p1: any) => {
          return `src="${arrowImage}"`;
        }
      );
      changeContent = changeContent.replace(
        "spacerGIF",
        `src="${spacerImage}"`
      );
      changeContent = changeContent.replace("arrowImage", `${arrowImage}`);
      changeContent = changeContent.replace("${new Date().getFullYear()}", `${new Date().getFullYear()}`);
      let checkContentString = changeContent?.includes("{0}");
      changeContent = checkContentString ? changeContent?.split("{0}") : changeContent;
      setHtmlString(changeContent);
      setCheckForString(checkContentString);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termsAndConditionString]);
  return (
    <div>
      {checkForString ? (
        <div>
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{ __html: htmlString?.[0].toString() }}
          />
          <Typography variant="body2">
            {htmlString?.[1].toString()}
            <Link to="/Contact" className={styles.contactLink}>{htmlString?.[2]?.toString()}</Link>
          </Typography>
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{ __html: htmlString?.[3]?.toString() }}
          />
        </div>
      ) : (
        <div>
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{ __html: htmlString?.toString() }}
          />
        </div>
      )}
    </div>
  );
};
