import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./reporting.module.scss";
import { useTranslation } from "react-i18next";
import { SelectionCriteria } from "./selectionCriteria";
import CustomDropDown from "../../atoms/customDropDown";
import CustomButton from "../../atoms/customButton";
import { ReportsTable } from "./reportsTable";
import { useDispatch } from "react-redux";
import {
  getReportingfilteredData,
  getReportingfilteredDataSuccess,
  getOrderChangeReport,
  getOrderChangeReportSuccess,
  getContextDetails,
} from "../../../state/actions";
import {
  INITIAL_NUMBER_OF_PLANNEDDISPATCHED_UNTIL_DATES,
  INITIAL_NUMBER_OF_DISPATHED_SINCE_DATES,
  CONTEXT_ORDER,
  REPORT_TYPE,
} from "../../../constants/commonConstants";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSelector } from "react-redux";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { OrderChangeTable } from "../orderChangeReport/orderChangeTable";
var _ = require("lodash");

export const Reporting = ({ parent }: any) => {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reportedFilteredDetails = useSelector(
    (state: any) => state?.reportingReducer?.reportedFilteredDetails
  );
  const orderChangeReportData = useSelector(
    (state: any) => state?.orderChangeReportReducer?.orderChangeReportData
  );
  const currentLocale = useSelector(
    (state: any) => state?.commonReducer?.locale
  );
  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );
  const contextDetails = useSelector(
    (state: any) => state?.commonReducer?.contextDetails
  );
  const sortByReportingArray =[
    {value: "productSku_name", label: t("dnet.model.text")?.toString()},
    {value: "customerMaterialNumber", label: t("customerMaterialNumber.text")?.toString()},
    {value: "firstDeliveryDate", label: t("dnet.req_date.text")?.toString()},
    {value: "numericCombinedIndex", label: t("dnet.sony_ref_no_colon.text")?.toString()},
    {value: "customerReference", label: t("dnet.your_ref.text")?.toString()},
    {value: "shipToAccount_number", label: t("dnet.deliver_to_no_colon.text")?.toString()},
    {value: "reportScheduleLines_plannedIssueDate", label: t("dnet.planned_date.text")?.toString()},
    {value: "reportScheduleLines_goodsIssueDate", label: t("dnet.issue_date.text")?.toString()},
    {value: "reportScheduleLines_deliveryNumber", label: t("dnet.delivery_no.text")?.toString()},
    {value: "reportScheduleLines_carrierTrackingNumber", label: t("dnet.carrier_reference_no.text")?.toString()},
    {value: "reportScheduleLines_minShipmentValue", label: t("dnet.blocked_orders.text")?.toString()},
    {value: "reportScheduleLines_invoiceNumber", label: t("dnet.invoice_no.text")?.toString()},
    {value: "rejectionCode", label: t("dnet.rejected_orders.text")?.toString()},
  ];

  const sortByOrderChangeReportArray = [
    { value: "model", label: t("dnet.model.text")?.toString() },
    {
      value: "customerMaterialNumber",
      label: t("customerMaterialNumber.text")?.toString(),
    },
    {
      value: "sonyOrderNumber",
      label: t("dnet.sony_ref_no_colon.text")?.toString(),
    },
    { value: "refNo", label: t("dnet.your_ref.text")?.toString() },
  ];

  const [formData, setFormData] = useState({
    itemsChecked: 0,
    productSkuId: location?.state?.selectedModel !== "" && 
      location?.state?.selectedModel !== undefined ? 
      location?.state?.selectedModel : "",
    openItems: true,
    customerReference: "",
    chkDispachedDate: "",
    elapsedDays: INITIAL_NUMBER_OF_DISPATHED_SINCE_DATES,
    dispatchedSinceDate: INITIAL_NUMBER_OF_DISPATHED_SINCE_DATES,
    estimatedDays: "",
    plannedDispatchedUntilDate: INITIAL_NUMBER_OF_PLANNEDDISPATCHED_UNTIL_DATES,
    blockedOrders: false,
    orderNumbers: "",
    orderType: "salesOrders",
    shipToAccountId: contextDetails?.shipToAccounts?.length <= 1 ? "" : "All",
    orderDateFrom: "",
    orderDateTo: "",
    requestedDeliveryDateFrom: "",
    requestedDeliveryDateTo: "",
    orderReportSortingAttribute: "productSku_name",
    orderChangeReportSortingAttribute: "model",
    rejectedOrdersEnabled: false,
    rejectedOrderCode: "99",
    rejectedOrders: "",
    rejectionCode: "",
    materialName: "",
    orderNumber: "",
    hotFlag: false,
    hotOrders: false,
    deliverToAddress: "",
    shipTo: "",
    sortingFieldName: "",
    onlyRequestedDeliveryDateChanges: false,
    orderItemNumber: "",
    sortingType: "",
    tolerance: "",
    customerMaterialNumber: "",
  });

  useEffect(() => {
    setFormData({
      ...formData,
      shipToAccountId: contextDetails?.shipToAccounts?.length <= 1 ? "" : "All",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextDetails]);

  const [minOrderDate, setMinOrderDate] = useState<any>(null);
  const [minDeliveryDate, setMinDeliveryDate] = useState<any>(null);
  const [orderDateFrom, setOrderDateFrom] = useState<any>(null);
  const [deliveryDateFrom, setDeliveryDateFrom] = useState<any>(null);
  const [orderDateTo, setOrderDateTo] = useState<any>(null);
  const [deliveryDateTo, setDeliveryDateTo] = useState<any>(null);
  const selectedModel = location?.state?.selectedModel;

  const handleCheckBox = (e: any) => {
    let checkBoxIDsObj: any = checkBoxIDs;
    let targetId = e.target.id;
    let targetValue = e.target.checked;
    if (targetId === "openItems" && targetValue === true) {
      checkBoxIDsObj = {
        ...checkBoxIDsObj,
        [targetId]: targetValue,
        rejectedOrdersEnabled: false,
      };
      setFormData({
        ...formData,
        [targetId]: targetValue,
        rejectedOrdersEnabled: false,
      });
    }
    if (targetId === "openItems" && targetValue === false) {
      checkBoxIDsObj = {
        ...checkBoxIDsObj,
        [targetId]: targetValue,
        elapsedDays: true,
      };
      setFormData({
        ...formData,
        [targetId]: targetValue,
        elapsedDays: formData?.dispatchedSinceDate,
      });
    }
    if (targetId === "rejectedOrdersEnabled" && targetValue === true) {
      checkBoxIDsObj = {
        ...checkBoxIDsObj,
        [targetId]: targetValue,
        openItems: false,
        estimatedDays: false,
        elapsedDays: false,
      };
      setFormData({
        ...formData,
        [targetId]: targetValue,
        openItems: false,
        elapsedDays: "",
        estimatedDays: "",
      });
    } else if (targetId === "rejectedOrdersEnabled" && targetValue === false) {
      checkBoxIDsObj = {
        ...checkBoxIDsObj,
        [targetId]: targetValue,
        openItems: true,
      };
      setFormData({
        ...formData,
        [targetId]: targetValue,
        openItems: true,
      });
    } else if (targetId === "estimatedDays" && targetValue === true) {
      checkBoxIDsObj = {
        ...checkBoxIDsObj,
        [targetId]: targetValue,
        elapsedDays: false,
        rejectedOrdersEnabled: false,
      };
      setFormData({
        ...formData,
        elapsedDays: "",
        estimatedDays: formData?.plannedDispatchedUntilDate,
        rejectedOrdersEnabled: false,
      });
    } else if (targetId === "elapsedDays" && targetValue === true) {
      checkBoxIDsObj = {
        ...checkBoxIDsObj,
        [targetId]: targetValue,
        estimatedDays: false,
        rejectedOrdersEnabled: false,
      };
      setFormData({
        ...formData,
        elapsedDays: formData?.dispatchedSinceDate,
        estimatedDays: "",
        rejectedOrdersEnabled: false,
      });
    } else if (targetId === "estimatedDays" && targetValue === false) {
      if (checkBoxIDsObj?.openItems) {
        checkBoxIDsObj = {
          ...checkBoxIDsObj,
          [targetId]: targetValue,
        };
        setFormData({
          ...formData,
          [targetId]: targetValue,
        });
      } else {
        checkBoxIDsObj = {
          ...checkBoxIDsObj,
          [targetId]: targetValue,
          openItems: true,
          elapsedDays: true,
        };
        setFormData({
          ...formData,
          elapsedDays: formData?.dispatchedSinceDate,
          openItems: true,
        });
      }
    } else if (targetId === "elapsedDays" && targetValue === false) {
      if (checkBoxIDsObj?.openItems) {
        checkBoxIDsObj = {
          ...checkBoxIDsObj,
          [targetId]: targetValue,
        };
        setFormData({
          ...formData,
          [targetId]: targetValue,
        });
      } else {
        checkBoxIDsObj = {
          ...checkBoxIDsObj,
          [targetId]: targetValue,
          openItems: true,
          estimatedDays: true,
        };
        setFormData({
          ...formData,
          estimatedDays: formData?.plannedDispatchedUntilDate,
          openItems: true,
        });
      }
    } else {
      checkBoxIDsObj = {
        ...checkBoxIDsObj,
        [targetId]: targetValue,
      };
      setFormData({
        ...formData,
        [targetId]: targetValue,
      });
    }
    setCheckBoxIDs(checkBoxIDsObj);
  };

  const handleFormData = (data: any, fieldName: any) => {
    if (fieldName === "dispatchedSinceDate") {
      setFormData({
        ...formData,
        [fieldName]: data,
        elapsedDays: data,
      });
    } else if (fieldName === "plannedDispatchedUntilDate") {
      setFormData({
        ...formData,
        [fieldName]: data,
        estimatedDays: data,
      });
    } else if (fieldName === "orderDateFrom") {
      // let newDateValue = new Date(data?.$d);
      setMinOrderDate(data);
      setOrderDateFrom(data);
    } else if (fieldName === "requestedDeliveryDateFrom") {
      // let newDateValue = new Date(data?.$d);
      setMinDeliveryDate(data);
      setDeliveryDateFrom(data);
    } else if (fieldName === "orderDateTo") {
      setOrderDateTo(data);
    } else if (fieldName === "requestedDeliveryDateTo") {
      setDeliveryDateTo(data);
    } else {
      setFormData({ ...formData, [fieldName]: data });
    }
  };

  const [checkBoxIDs, setCheckBoxIDs] = useState({
    openItems: true,
    rejectedOrdersEnabled: false,
    estimatedDays: false,
    elapsedDays: true,
    blockedOrders: false,
    onlyRequestedDeliveryDateChanges: false,
  });

  const createProductSkuIDArray = (prodSkuArr: any) => {
    let tempProdArr: any = [];
    tempProdArr = prodSkuArr?.map((elem: any) => {return {name: elem}})
    return tempProdArr;
  }

  const [currentSelectedAddress, setCurrentSelectedAddress] = useState("");

  const handleSearch = (type: any) => {
    dispatch(getOrderChangeReportSuccess([]));
    dispatch(getReportingfilteredDataSuccess([]));
    let dispatchedData = formData;
    if (orderDateFrom !== null) {
      let newDate = moment(new Date(orderDateFrom?.$d)).format("YYYY-MM-DD");
      dispatchedData.orderDateFrom = newDate;
    }
    if (deliveryDateFrom !== null) {
      dispatchedData.requestedDeliveryDateFrom = moment(
        new Date(deliveryDateFrom?.$d)
      ).format("YYYY-MM-DD");
    }
    if (orderDateTo !== null) {
      dispatchedData.orderDateTo = moment(new Date(orderDateTo?.$d)).format(
        "YYYY-MM-DD"
      );
    }
    if (deliveryDateTo !== null) {
      dispatchedData.requestedDeliveryDateTo = moment(
        new Date(deliveryDateTo?.$d)
      ).format("YYYY-MM-DD");
    }
    if (type === "search") {
      if (dispatchedData?.shipToAccountId === "All") {
        setCurrentSelectedAddress(t("dnet.all_addresses.text")?.toString())
      } else {
        contextDetails?.shipToAccounts?.forEach((acc: any) => {
          acc?.number === dispatchedData?.shipToAccountId && 
            setCurrentSelectedAddress(
              `${acc?.number} - ${acc?.name}, ${acc?.address?.street}, ${acc?.address?.townCity}, (${acc?.address?.country})`
            )
        });
      }
      let lineItemReportQuery = {
        "orderNumbers": dispatchedData?.orderNumbers==="" ? [] : dispatchedData?.orderNumbers?.split(","),
        "shipToAccountId": {
            "number": dispatchedData?.shipToAccountId === "All" ? "" : dispatchedData?.shipToAccountId,
        },
        "productSkuId": dispatchedData?.productSkuId===""? [] : 
          createProductSkuIDArray(dispatchedData?.productSkuId?.split(",")),
        "customerMaterialNumber": dispatchedData?.customerMaterialNumber===""?[]:dispatchedData?.customerMaterialNumber?.split(","),
        "isRejectedOrdersEnabled": checkBoxIDs?.rejectedOrdersEnabled, 
        "rejectedOrderCode": checkBoxIDs?.rejectedOrdersEnabled ? dispatchedData?.rejectedOrderCode : "",
        "dispatchedSinceDate": checkBoxIDs?.elapsedDays ? dispatchedData?.dispatchedSinceDate: "",
        "plannedDispatchedUntilDate": checkBoxIDs?.estimatedDays ? dispatchedData?.plannedDispatchedUntilDate: "",
        "requestedDeliveryDateFrom": dispatchedData?.requestedDeliveryDateFrom === ""
        ? ""
        : dispatchedData?.requestedDeliveryDateFrom,
        "requestedDeliveryDateTo": dispatchedData?.requestedDeliveryDateTo === ""
        ? ""
        : dispatchedData?.requestedDeliveryDateTo,
        "orderDateFrom": dispatchedData?.orderDateFrom === ""
        ? ""
        : dispatchedData?.orderDateFrom,
        "orderDateTo": dispatchedData?.orderDateTo === ""
        ? ""
        : dispatchedData?.orderDateTo,
        "contactPerson": "",
        "customerReference": dispatchedData?.customerReference,
        "isOpenItems": dispatchedData?.openItems,
        "isBlockedOrders": dispatchedData?.blockedOrders,
        "orderType": dispatchedData?.orderType === "both" ? "" : dispatchedData?.orderType,
        "sortBy":dispatchedData?.orderReportSortingAttribute,
      }
      if (parent !== "OrderChangeReport")
        dispatch(getReportingfilteredData({ lineItemReportQuery }));
      else {
        let criteria = {
          customerReference: dispatchedData?.customerReference,
          shipToNumber:
            dispatchedData?.shipToAccountId === "All"
              ? ""
              : dispatchedData?.shipToAccountId,
          orderStart:
            dispatchedData?.orderDateFrom === ""
              ? ""
              : dispatchedData?.orderDateFrom,
          orderEnd:
            dispatchedData?.orderDateTo === ""
              ? ""
              : dispatchedData?.orderDateTo,
          descriptionLanguage: currentLocale,
          rejectedOrderCode: dispatchedData?.rejectedOrdersEnabled ? dispatchedData?.rejectedOrderCode : "",
          materialName:
            dispatchedData?.productSkuId === ""
              ? []
              : dispatchedData?.productSkuId?.split(","),
          customerMaterialNumber:
            dispatchedData?.customerMaterialNumber === ""
              ? []
              : dispatchedData?.customerMaterialNumber?.split(","),
          sonyReference:
            dispatchedData?.orderNumbers === ""
              ? []
              : dispatchedData?.orderNumbers?.split(","),
          deliveryStart:
            dispatchedData?.requestedDeliveryDateFrom === ""
              ? ""
              : dispatchedData?.requestedDeliveryDateFrom,
          deliveryEnd:
            dispatchedData?.requestedDeliveryDateTo === ""
              ? ""
              : dispatchedData?.requestedDeliveryDateTo,
          onlyDeliveryRequestChanges:
            dispatchedData?.onlyRequestedDeliveryDateChanges,
          deliveryBlocked: dispatchedData?.blockedOrders,
        };
        dispatch(getOrderChangeReport({ criteria }));
      }
    } else {
      navigate(
        parent !== "OrderChangeReport"
          ? "/Reporting/Manage_Reports"
          : "/Order_Change_Report/Manage_Reports",
        {
          state: {
            dispatchedData,
            autoEmailReport: true,
            reportType: parent !== "OrderChangeReport" ? REPORT_TYPE?.ORDER_REPORT : REPORT_TYPE?.ORDER_CHANGE_HISTORY,
            checkBoxIDs:checkBoxIDs,
          },
        }
      );
    }
  };

  useEffect(()=>{
    if (selectedModel !== "" && selectedModel !== undefined) {
      handleSearch("search")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedModel])

  useEffect(() => {
    if(location?.state?.reportingSearch !== "true") {
      dispatch(getReportingfilteredDataSuccess([]));
    }
    if (location?.state?.orderChangeSearch !== "true") {
      dispatch(getOrderChangeReportSuccess([]));
    }
    dispatch(
      getContextDetails({
        key: parent!=="OrderChangeReport" ? CONTEXT_ORDER?.RPEORTING_PAGE : CONTEXT_ORDER?.ORDER_CHANGE_HISTORY_PAGE,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Typography
        variant="subtitle1"
        className={styles.header}
        pb={3}
        color="primary"
      >
        {parent === "OrderChangeReport"
          ? t("dnet.order_change_report.text")
          : t("dnet.open_orders_deliveries.text")}
      </Typography>
      <Box className={styles.boxContainer}>
        <SelectionCriteria
          formData={formData}
          handleFormData={handleFormData}
          handleCheckBox={handleCheckBox}
          checkBoxIDs={checkBoxIDs}
          parent={parent}
        />
        <div className={styles.topBox}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={t("dnet.order_date_colon.text")}
              openTo="day"
              views={["year", "month", "day"]}
              value={orderDateFrom}
              onChange={(newValue: any) => {
                handleFormData(newValue, "orderDateFrom");
              }}
              renderInput={(params) => (
                <TextField 
                  {...params}
                  inputProps={{...params.inputProps, readOnly: true}}
                  size="small"
                />
              )}
              inputFormat="DD-MM-YYYY"
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={t("dnet.to.text")}
              openTo="day"
              views={["year", "month", "day"]}
              value={orderDateTo}
              onChange={(newValue: any) => {
                handleFormData(newValue, "orderDateTo");
              }}
              renderInput={(params) => (
                <TextField 
                  {...params}
                  inputProps={{...params.inputProps, readOnly: true}}
                  size="small"
                />
              )}
              inputFormat="DD-MM-YYYY"
              minDate={minOrderDate}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={t("dnet.requested_delivery_date.text")}
              openTo="day"
              views={["year", "month", "day"]}
              value={deliveryDateFrom}
              onChange={(newValue: any) => {
                handleFormData(newValue, "requestedDeliveryDateFrom");
              }}
              renderInput={(params) => (
                <TextField 
                  {...params}
                  inputProps={{...params.inputProps, readOnly: true}}
                  size="small"
                />
              )}
              inputFormat="DD-MM-YYYY"
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={t("dnet.to.text")}
              openTo="day"
              views={["year", "month", "day"]}
              value={deliveryDateTo}
              onChange={(newValue: any) => {
                handleFormData(newValue, "requestedDeliveryDateTo");
              }}
              renderInput={(params) => (
                <TextField 
                  {...params}
                  inputProps={{...params.inputProps, readOnly: true}}
                  size="small"
                />
              )}
              inputFormat="DD-MM-YYYY"
              minDate={minDeliveryDate}
            />
          </LocalizationProvider>
        </div>
        <div className={styles.bottomButtons}>
          {parent !== "OrderChangeReport" ? (
            <CustomDropDown
              id="sortByDropdown"
              value={formData?.orderReportSortingAttribute}
              handleChange={(e: any) =>
                handleFormData(e.target.value, "orderReportSortingAttribute")
              }
              menuOptions={sortByReportingArray}
              module="debitNoteCreation"
              label={t("dnet.sort_by_colon.text")?.toString()}
            />
          ) : (
            <CustomDropDown
              id="sortByOrderDropdown"
              value={formData?.orderChangeReportSortingAttribute}
              handleChange={(e: any) =>
                handleFormData(
                  e.target.value,
                  "orderChangeReportSortingAttribute"
                )
              }
              label={t("dnet.sort_by_colon.text")?.toString()}
              menuOptions={sortByOrderChangeReportArray}
              module="debitNoteCreation"
            />
          )}
          <div>
            <CustomButton
              id="searchButton"
              label={t("dnet.search.text")}
              onClick={() => handleSearch("search")}
              color="primary"
              margin="0 20px"
            />
            <CustomButton
              id="autoEmail"
              label={t("dnet.auto_email.text")}
              onClick={() => handleSearch("report")}
              margin="0"
            />
          </div>
        </div>
        {((reportedFilteredDetails?.reportLineItems?.length > 0 &&
          parent !== "OrderChangeReport") ||
          (orderChangeReportData?.data?.length > 0 &&
            parent === "OrderChangeReport")) && (
              <Box className={styles.tableContainer} style={{ width: "100%" }}>
                {parent === "OrderChangeReport" ? (
                  <OrderChangeTable
                    initialSort={formData?.orderChangeReportSortingAttribute}
                  />
                ) : (
                  <ReportsTable 
                    initialSort={formData?.orderReportSortingAttribute} 
                    currentSelectedAddress={currentSelectedAddress}
                  />
                )}
              </Box>
            )}
        {((!_.isEmpty(reportedFilteredDetails?.error) &&
          parent !== "OrderChangeReport") ||
          (!_.isEmpty(orderChangeReportData?.data?.error) &&
            parent === "OrderChangeReport")) && (
              <div className={styles.errorMsg}>
                <Typography variant="body2" color={"error"}>
                  {
                    parent !== "OrderChangeReport" ? 
                    t(reportedFilteredDetails?.error?.message)?.toString() :
                    t(orderChangeReportData?.data?.error?.message)?.toString()
                  }
                </Typography>
              </div>
            )}
        <div className={styles.endMsg}>
          <Typography variant="body2">
            {t(
              `dnet.quotationt.c_${currentDealer?.bgName?.slice(0, 4)}.text`
            ).toString()}
          </Typography>
        </div>
      </Box>
    </div>
  );
};

Reporting.defaultProps = {
  parent: "",
};
