import React from "react";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";
import "./totalAmountTable.scss";
import { useTranslation } from "react-i18next";

const TotalAmountTable = () => {
  const { t } = useTranslation();
  const [rows, setRows] = useState<any>([]);
  const totalAmountTableData = useSelector(
    (state: any) => state?.gazelleReducer?.paymentDetailsData
  );

  interface Column {
    id: "textMessage" | "totalAmount";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
  }

  const columns: readonly Column[] = [
    { id: "textMessage", label: "" },
    { id: "totalAmount", label: "" },
  ];

  interface Data {
    textMessage: any;
    totalAmount: any;
  }

  function createData(textMessage: any, totalAmount: any): Data {
    return { textMessage, totalAmount };
  }

  const createRowData = () => {
    // Rows data creation from API response
    let data = totalAmountTableData;
    let rowsdata = [];
    rowsdata.push(createData(data?.textMessage, data?.totalAmount));
    setRows([...rowsdata]);
  };

  useEffect(() => {
    createRowData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalAmountTableData]);

  return (
    <>
      <TableContainer component={Paper} className="tableContainerHeader">
        <Table sx={{ minWidth: 650 }} aria-label="Payments Table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  colSpan={2}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length > 0 ? (
              rows.map((row: any, key: any) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return column.id === "textMessage" ? (
                        <TableCell key={column.id} align={column.align}>
                          {t("gzl.payment_detail_explanation.text")}
                        </TableCell>
                      ) : (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  align={"center"}
                  className="noRecFound"
                >
                  {t("gzl.no_data_available.text")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TotalAmountTable;
