import React, { useEffect } from "react";
import styles from "./primeSupportRegistration.module.scss";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getPrimeSupportArticles } from "../../../state/actions";
import { ArticleDetailsPage } from "../../cms/articleDetailsPage";

export const PrimeSupportRegistration = () => {
  //TODO: logic might differ,
  //current logic is based on the discussion and CMS API call visibility,
  //as the respective screen is blank in all environments
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  
  const articles = useSelector(
    (state: any) => state?.userProfileOptionsReducer?.primeSupportArticles
  );
  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );

  useEffect(() => {
    dispatch(
      getPrimeSupportArticles({
        module: "PrimeSupportRegistration",
        params: {
          [`filter_locale[${i18n.language}]`]: i18n.language,
          [`filter_bg[${currentDealer?.bgName}]`]: currentDealer?.bgName,
          [`filter_bg[ResellerEU]`]: "ResellerEU", //TODO: This should be based on selected dealer but as UI is not visible, this is not clear
          [`filter_ecot[4_OLD]`]: "4_OLD",
          [`acf_like[main_content_category]`]: "OrderManagement",
          [`acf_like[content_category]`]:
            "OrderManagement>PrimeSupportRegistration",
          [`acf_like[rh_related_category]`]: "RHBottomBanner",
          max_result: "3",
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Typography
        variant="subtitle1"
        className={styles.header}
        pb={3}
        color="primary"
      >
        {t("dnet.primesupport_registration.section")}
      </Typography>
      <ArticleDetailsPage selectedArticle={articles?.[0]} />
    </div>
  );
};
