import { commonConstant } from "../constants/common-constants";

export const initialState = {
  productVisibilityData: [],
  checkSalesRepData: {},
  checkContactEmailData: {},
  checkSalesRepBySalesOrgData: {},
  checkSalesRepDealerData: {},
  checkSalesOrgAccountsData: {},
};

const SystemAdminReducer = (state = initialState, action: any) => {
  switch (action?.type) {
    case commonConstant.PRODUCT_VISIBILITY_DATA_SUCCESS:
      return {
        ...state,
        productVisibilityData: action?.payload,
      };
    case commonConstant.CHECK_SALES_REP_SUCCESS:
      let checkSalesRepData: any = {};
      if (action?.payload?.success) {
        checkSalesRepData = {
          errorMessageSalesRep: "",
          salesRep: action?.payload?.data,
        };
      } else {
        checkSalesRepData = {
          errorMessageSalesRep: action?.payload?.message,
          salesRep: "",
        };
      }
      return {
        ...state,
        checkSalesRepData,
      };
    case commonConstant.CHECK_CONTACT_EMAIL_SUCCESS:
      let checkContactEmailData: any = {};
      if (action?.payload?.success) {
        checkContactEmailData = {
          infoMessageContactEmails: action?.payload?.data?.error
            ? action?.payload?.data?.error
            : action?.payload?.data,
          errorMessageContactEmails: "",
          queryContactEmailsInfo: action?.payload?.data?.queryContactEmailsInfo,
          contactEmails: action?.payload?.data?.contactEmails
            ? action?.payload?.data?.contactEmails[0]?.contactEmailList?.toString()
            : "",
        };
      } else {
        checkContactEmailData = {
          infoMessageContactEmails: "",
          errorMessageContactEmails: action?.payload?.message,
          queryContactEmailsInfo: "",
          contactEmails: "",
        };
      }
      return {
        ...state,
        checkContactEmailData,
      };
    case commonConstant.CHECK_SALES_REP_BY_SALES_ORG_SUCCESS:
      let checkSalesRepBySalesOrgData: any = {};
      if (action?.payload?.success) {
        checkSalesRepBySalesOrgData = {
          infoMessageSalesRepsBySalesOrg: action?.payload?.data?.error,
          errorMessageSalesRepsBySalesOrg: "",
          salesRepresentativeList: action?.payload?.data,
        };
      } else {
        checkSalesRepBySalesOrgData = {
          infoMessageSalesRepsBySalesOrg: "",
          errorMessageSalesRepsBySalesOrg: action?.payload?.message,
          salesRepresentativeList: [],
        };
      }
      return {
        ...state,
        checkSalesRepBySalesOrgData,
      };
    case commonConstant.CHECK_SALES_REP_DEALER_SUCCESS:
      let checkSalesRepDealerData: any = {};
      if (action?.payload?.success) {
        checkSalesRepDealerData = {
          infoMessageSalesRepsDealers: action?.payload?.data?.error,
          errorMessageSalesRepsDealers: "",
          salesRepresentativesDealersList: action?.payload?.data,
        };
      } else {
        checkSalesRepDealerData = {
          infoMessageSalesRepsDealers: "",
          errorMessageSalesRepsDealers: action?.payload?.message,
          salesRepresentativesDealersList: [],
        };
      }
      return {
        ...state,
        checkSalesRepDealerData,
      };
    case commonConstant.CHECK_SALES_ORG_ACCOUNTS_SUCCESS:
      let checkSalesOrgAccountsData: any = {};
      if (action?.payload?.success) {
        checkSalesOrgAccountsData = {
          infoMessageAccountsBySalesOrg: action?.payload?.data?.error,
          errorMessageAccountsBySalesOrg: "",
          accountsList: action?.payload?.data,
        };
      } else {
        checkSalesOrgAccountsData = {
          infoMessageAccountsBySalesOrg: "",
          errorMessageAccountsBySalesOrg: action?.payload?.message,
          accountsList: [],
        };
      }
      return {
        ...state,
        checkSalesOrgAccountsData,
      };
    default:
      return state;
  }
};

export default SystemAdminReducer;
