import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useSelector, useDispatch } from "react-redux";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import Box from "@mui/material/Box";
import "./invoiceDetailsTable.scss";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { downloadInvoicePdf } from "../../../../state/actions";
import { useTranslation } from "react-i18next";
import { getComparator, stableSort } from '../../../helper/util';
import moment from "moment";
var currencyFormatter = require('currency-formatter');


const InvoiceDetailsTable = () => {
  const { t } = useTranslation();
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("label");
  const [rows, setRows] = useState<any>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const downloadPdfText = t("gzl.display_pdf.text");

  const invoiceDetailsTableData = useSelector(
    (state: any) => state?.gazelleReducer?.paymentDetailsData?.invoices
  );
  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.userDetails
  );
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    // Sorting table data on click of header
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  interface Column {
    id: "label" | "dueDate" | "amount" | "openAmount" | "discount";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
  }

  const columns: readonly Column[] = [
    { id: "label", label: t("gzl.invoice.text") },
    { id: "dueDate", label: t("gzl.due_on.text") },
    { id: "amount", label: t("gzl.org_inv_value.text") },
    { id: "openAmount", label: t("gzl.paid_with_this_payment.text") },
    { id: "discount", label: t("gzl.discount_granted.text") },
  ];

  interface Data {
    label: any;
    dueDate: any;
    amount: any;
    openAmount: any;
    discount: any;
  }

  function createData(
    label: any,
    dueDate: any,
    amount: any,
    openAmount: any,
    discount: any
  ): Data {
    return { label, dueDate, amount, openAmount, discount };
  }

  const createRowData = () => {
    // Rows data creation from API response
    let data = invoiceDetailsTableData;
    let rowsdata = [];
    for (let i = 0; i < data?.length; i++) {
      rowsdata.push(
        createData(
          data[i].label,
          data[i].dueDate,
          data[i].amount,
          data[i].openAmount,
          data[i].discount
        )
      );
    }
    setRows([...rowsdata]);
  };

  useEffect(() => {
    createRowData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceDetailsTableData]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // Functionality to change number of rows per page
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const saveAccountDetails = useSelector(
    (state: any) => state?.commonReducer?.saveAccountDetails
  )

  const downloadPdf = (columnData: any) => {
    // PDF download
    dispatch(
      downloadInvoicePdf({
        language: userDetails?.language,
        username: userDetails?.userName,
        partnerKey: saveAccountDetails?.payerToAcc?.number,
        invoiceId: "SD_"+columnData?.invoiceId,
      })
    );
  };

  return (
    <>
      <TableContainer component={Paper} className="tableContainerHeader">
        <Table sx={{ minWidth: 650 }} aria-label="Payments Table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={createSortHandler(column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {invoiceDetailsTableData?.length > 0 && rows?.length > 0 ? (
              stableSort(invoiceDetailsTableData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, key: any) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return column?.id === "amount" ? (
                          <TableCell key={column.id} align={column.align}>
                            <Link
                              className="colorBlue"
                              to="/Paid_Item_Details"
                              state={{
                                invoiceId:
                                row?.invoiceId,
                              }}
                            >
                              {value}&nbsp;
                              {currencyFormatter.findCurrency(
                                row?.currency
                                )?.symbol}
                            </Link>
                          </TableCell>
                        ) : column?.id === "label" ? (
                          <TableCell key={column.id} align={column.align}>
                            <span className="pdfIcon"
                              title={downloadPdfText}
                              onClick={(e: any) => {downloadPdf(row);}}>
                                {value}{" "}
                            </span>
                            <IconButton 
                              className="pdfIcon"
                              onClick={(e: any) => {
                                downloadPdf(row);
                              }}
                            >
                              <PictureAsPdfOutlinedIcon
                                titleAccess={downloadPdfText}
                              />
                            </IconButton>
                          </TableCell>
                        ) : column.id === "dueDate" ? (
                          <TableCell key={column.id} align={column.align}>
                            {
                              value && moment(value).format("DD.MM.YYYY") 
                            }
                          </TableCell>
                        ) : column?.id === "discount" ? (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ color: value > 0 ? "" : "red" }}
                          >
                            {value}&nbsp;
                            {currencyFormatter.findCurrency(
                              row?.currency
                              )?.symbol}
                          </TableCell>
                        ) : column?.id === "openAmount" ? (
                          <TableCell key={column.id} align={column.align}>
                            {value}&nbsp;
                            {currencyFormatter.findCurrency(
                              row?.currency
                              )?.symbol}
                          </TableCell>
                        ) : (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  align={"center"}
                  className="noRecFound"
                >
                  {t("gzl.no_data_available.text")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="tableFooter">
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

InvoiceDetailsTable.defaultProps = {
  from: "Payments",
};

export default InvoiceDetailsTable;
