import { commonConstant } from "../constants/common-constants";

export const initialState = {
  cmsArticles: [],
  pricingInfo: [],
  relatedItem: [],
  loginCMSData: [],
  relatedTools: [],
  primeSupportArticles: [],
};

const UserProfileOptionsReducer = (state = initialState, action: any) => {
  switch (action?.type) {
    case commonConstant.GET_CMS_ARTICLES_SUCCESS:
      return {
        ...state,
        cmsArticles: action?.payload,
      };
    case commonConstant.GET_RELATED_ITEMS_ARTICLES_SUCCESS:
      return {
        ...state,
        relatedItem: action?.payload,
      };
    case commonConstant.GET_PRICING_INFO_ARTICLES_SUCCESS:
      return {
        ...state,
        pricingInfo: action?.payload,
      };
    case commonConstant.GET_LOGIN_CMS_DATA_SUCCESS:
      return {
        ...state,
        loginCMSData: action?.payload,
      };
    case commonConstant.GET_RELATED_TOOLS_ARICLES_SUCCESS:
      return {
        ...state,
        relatedTools: action?.payload,
      };
    case commonConstant.GET_PRIME_SUPPORT_REGISTRATION_SUCCESS:
      return {
        ...state,
        primeSupportArticles: action?.payload,
      };
    default:
      return state;
  }
};

export default UserProfileOptionsReducer;
