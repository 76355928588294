import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOpenToast } from "../../../state/actions";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ToastMessage = () => {
  const dispatch = useDispatch();

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(
      setOpenToast({
        openToast: false,
        severity: toastContent?.severity,
        message: "",
      })
    );
  };

  const openToast = useSelector(
    (state: any) => state?.commonReducer?.openToast
  );

  const toastContent = useSelector(
    (state: any) => state?.commonReducer?.toastContent
  );

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar open={openToast} autoHideDuration={4000} onClose={handleClose} 
      anchorOrigin={{  vertical: "bottom", horizontal: "right"  }}>
        <Alert
          onClose={handleClose}
          severity={toastContent?.severity}
          sx={{ width: "100%" }}
        >
          {toastContent?.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
