import React from 'react';
import { Button } from '@mui/material';

const CustomButton = ({variant, color, onClick, size, margin, additionalSxProperties, label,disabled,fontSize,padding}: any) => {
    return(
    <Button 
        variant={variant}
        onClick={onClick}
        size={size}
        sx={{
            borderRadius:"4px",
            margin: margin,
            backgroundColor: color,
            fontSize: fontSize,
            padding: padding,
            ...additionalSxProperties
        }}
        disabled={disabled}
    >
        {label}
    </Button>
    )
};

CustomButton.defaultProps = {
    label:"",
    margin: "0 6px",
    additionalSxProperties: {},
    size: "medium",
    color: "#000000",
    variant: "contained",
    disabled:false,
    padding:"2px 10px",
    fontSize:"11px",
};

export default CustomButton;