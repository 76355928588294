import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { CONTEXT_ORDER, PUBLICATION } from "../../../../constants/commonConstants";
import { getContextDetails, getProductsByMaterialName, getProductsByMaterialNameSuccess, setProductContentData } from "../../../../state/actions";
import { ProdContentDetail } from "../../prodContentDetail";
import { AwardsAndReviewTab } from "./awardsAndReviewsTab";
import { BenefitsTab } from "./benefitsTab";
import { EnergyTab } from "./energyTab";
import { FeaturesTab } from "./featuresTab";
import { ManualTab } from "./manualTab";
import { OverviewTab } from "./overviewTab";
import styles from "./prodTabs.module.scss";
import { SpecificationsTab } from "./specificationsTab";
import { TechnicalSummaryTab } from "./technicalSummaryTab";
import { ResellerProdContent } from "../../resellerProdContent";
const _ = require("lodash");

export const ProdTabs = () => {
    const { t } = useTranslation();
  const dispatch = useDispatch();

  const productDetailsData = useSelector(
    (state: any) => state?.productReducer?.productDetailsData
  );

  const productByMaterialName = useSelector(
    (state: any) => state?.productReducer?.productsByMaterialName
  );

  const currentProductSku = useSelector(
    (state: any) => state?.productReducer?.currentProductSku
  );

  const [isConsumer, setIsConsumer] = useState<any>(false);

  useEffect(() => {
    dispatch(
      getContextDetails({
        key: CONTEXT_ORDER?.PRODUCT_MODULE_PAGE,
      })
    );
    dispatch(getProductsByMaterialNameSuccess({}));
  }, []);

  const contextDetails = useSelector(
    (state: any) => state?.commonReducer?.contextDetails
  );

  useEffect(() => {
    if (!_.isEmpty(contextDetails)) {
      setIsReseller(contextDetails?.isReseller);
    }
  }, [contextDetails]);
  const [isReseller, setIsReseller] = useState<any>(false);
 
  useEffect(() => {
      let  updatedAccesories:any = [];
      if(!_.isEmpty(productByMaterialName))
      {
        updatedAccesories = productByMaterialName?.map(
          (product: any) => Object.values(product)[0]
        );
      }
      const filteredProducts = updatedAccesories?.filter((product:any)=> product?.product?.index && product?.product?.index?.uri);
      setAccessories(filteredProducts);
  }, [productByMaterialName]);

  const [accessories,setAccessories]= useState([]);

  useEffect(() => {
    setValue(0);
    if (!_.isEmpty(productDetailsData)) {
      let tabList: any = [];
      if (productDetailsData?.proOverview?.length > 0) {
        tabList.push("dnet.overview.text");
      }
      if (productDetailsData?.featureBenefits?.length > 0) {
        tabList.push("dnet.features.text");
      }
      if (productDetailsData?.proBenefit?.length > 0) {
        tabList.push("dnet.benefits.text");
      }
      if (productDetailsData?.techSpecs?.length > 0) {
        tabList.push("dnet.specifications.text");
      }
      if (
        productDetailsData?.iconDescription?.length > 0 ||
        productDetailsData?.inTheBox?.length > 0 ||
        productDetailsData?.keyTechSpecs?.length > 0
      ) {
        tabList.push("dnet.technical_summary.text");
      }

      if (productDetailsData?.accessories?.length > 0) {
        dispatch(
          getProductsByMaterialName({
            materialName: productDetailsData?.accessories,
          })
        );
        tabList.push("dnet.accessories.text");
      }
      // Feature is deprecated
      if (false) {
        tabList.push("dnet.manuals.text");
      }

      if (
        productDetailsData?.awards?.length > 0 ||
        productDetailsData?.expertReviews?.length > 0
      ) {
        tabList.push("dataDownload.dataType.titleAwardsAndReviews.text");
      }

      if (!_.isEmpty(productDetailsData?.ecoEnergy)) {
        tabList.push("dataDownload.dataType.titleEcoEnergy.text");
      }
      if (currentProductSku?.product?.warranties) {
        tabList.push("dnet.warranties.text");
      }
      setProductDetailTabList(tabList);

      setIsConsumer(
        currentProductSku?.product?.index?.publication !== PUBLICATION.PSE
      );
    }
  }, [productDetailsData]);

  const [productDetailTabList, setProductDetailTabList] = useState<any>([]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const supportInfoHandler = () => {
    window.open(
      `http://support.pro.sony.eu/?lang=E&CpSurT=22b10fd0-6aab-42b2-8fdb-032d284d23be&model=${productDetailsData?.materialName}`,
      "_blank"
    );
  };
  return (
    <>
      <Box sx={{ width: "100%", marginTop: "16px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          className={styles.tabsStyle}
          variant="scrollable"
          allowScrollButtonsMobile
          TabIndicatorProps={{
            sx: {
              bottom: "4px",
            },
          }}
        >
          {productDetailTabList?.map((tab: any, index: any) => (
            <Tab key={index} label={t(tab)} />
          ))}
          {!isConsumer && (
            <Tab
              label={t("dnet.support_information.text")}
              onClick={supportInfoHandler}
            />
          )}
        </Tabs>
        <Box className={styles.tabContainer}>
          {productDetailTabList[value] === "dnet.overview.text" && (
            <Box m={2}>
              <OverviewTab />
            </Box>
          )}
          {productDetailTabList[value] === "dnet.features.text" && (
            <Box m={2}>
              <FeaturesTab />
            </Box>
          )}
          {productDetailTabList[value] === "dnet.benefits.text" && (
            <Box m={2}>
              <BenefitsTab />
            </Box>
          )}
          {productDetailTabList[value] === "dnet.specifications.text" && (
            <Box m={2}>
              <SpecificationsTab />
            </Box>
          )}
          {productDetailTabList[value] === "dnet.technical_summary.text" && (
            <Box m={2}>
              <TechnicalSummaryTab />
            </Box>
          )}

          {/* feature is deprecated */}
          {/* {productDetailTabList[value] === "dnet.manuals.text" && (
            <Box m={2}>
              <ManualTab />
            </Box>
          )} */}

          {productDetailTabList[value] === "dnet.accessories.text" && (
            <Box m={2}>
                <ProdContentDetail parent="accessories" accessories={accessories} />
              {/* Display's prod content detail table */}
            </Box>
          )}
          {productDetailTabList[value] ===
            "dataDownload.dataType.titleAwardsAndReviews.text" && (
            <Box m={2}>
              <AwardsAndReviewTab />
            </Box>
          )}
          {productDetailTabList[value] ===
            "dataDownload.dataType.titleEcoEnergy.text" && (
            <Box m={2}>
              <EnergyTab />
            </Box>
          )}
          {productDetailTabList[value] === "dnet.warranties.text" && (
            <Box m={2}>
                <ProdContentDetail parent="productTabs" />
              {/* Display's prod content detail table */}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
