import { commonConstant } from "../constants/common-constants";

export const initialState = {
  reportedFilteredDetails: [],
  reportedFilteredPayload: [],
  orderDetails: [],
  newReportSuccessData: {},
  allReportsData: [],
  getSelectedReportData: {},
  updateReportSuccessData: {},
  editReportSuccessResponse: {},
};

const reportingReducer = (state = initialState, action: any) => {
  switch (action?.type) {
    case commonConstant.GET_REPORTING_FILTERED_DATA_SUCCESS:
      return {
        ...state,
        reportedFilteredDetails: action?.payload,
      };
    case commonConstant.GET_REPORTING_FILTERED_DATA:
      return {
        ...state,
        reportedFilteredPayload: action?.payload,
      };
    case commonConstant.GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderDetails: action?.payload,
      };
    case commonConstant.CREATE_NEW_REPORT_SUCCESS:
      return {
        ...state,
        newReportSuccessData: action?.payload,
      };
    case commonConstant.GET_ALL_REPORTS_SUCCESS:
      return {
        ...state,
        allReportsData: action?.payload,
      };
    case commonConstant.GET_SELECTED_REPORT_DATA_SUCCESS:
      return {
        ...state,
        getSelectedReportData: action?.payload,
      };
    case commonConstant.UPDATE_REPORT_SUCCESS:
      return {
        ...state,
        updateReportSuccessData: action?.payload,
      };
    case commonConstant.EDIT_ORDER_REPORT_SUCCESS:
      return {
        ...state,
        editReportSuccessResponse: action?.payload,
      };
    default:
      return state;
  }
};

export default reportingReducer;
