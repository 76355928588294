import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES_LABEL } from "./constants/routesLabels";
import styles from "./App.module.scss";
import { Header } from "./components/header";
import IdleTimerContainer from "./components/idleTimerContainer";
import { useLocation } from "react-router-dom";
import { Footer } from "./components/atoms/footer";
import { CustomBreadcrumbs } from "./components/atoms/cutomBreadcrumbs";
import {
  getCurrentDealerSuccess,
  getDealerDetailsSuccess,
  loginUserSuccess,
  setCurrentAddressSet,
  setCurrentDealer,
  setDealers,
  setIsUserLoggedin,
  setSonyMenuItems,
  startProductFlowSuccess,
  fetchProdCategoryResponseSuccess,
  logoutAction,
  
} from "./state/actions";

const ProtectedRoutes = ({ Component }) => {
  const { pathname } = useLocation();
  const isLoggedin = useSelector((state) => state?.commonReducer?.isLoggedin);
  const isUserAuthorized = useSelector(
    (state) => state?.commonReducer?.isUserAuthorized
  );
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    // Navigating user to login page if not logged in
    if (!isLoggedin) {
      navigate(`/${ROUTES_LABEL.LOGIN}`);
    }
  });

  useEffect(() => {
    if (!isUserAuthorized) {
      dispatch(setIsUserLoggedin(false));
      dispatch(loginUserSuccess({}));
      dispatch(setCurrentDealer({}));
      dispatch(getDealerDetailsSuccess([]));
      dispatch(startProductFlowSuccess({}));
      dispatch(fetchProdCategoryResponseSuccess({}));
      dispatch(setSonyMenuItems([]));
      dispatch(setDealers([]));
      dispatch(setCurrentAddressSet(-1));
      dispatch(getCurrentDealerSuccess({}))
      dispatch(logoutAction({}));
      navigate(`/${ROUTES_LABEL.LOGIN}`);
    }
  }, [isUserAuthorized]);

  const sapEnvDetails = useSelector(
    (state) => state?.commonReducer?.sapEnvDetails
  );

  return (
    <div>
      <Header
        fromLogin={
          pathname === `/${ROUTES_LABEL.DEALER_SELECTION}` ? true : false
        }
      />
      <IdleTimerContainer />
      <div className={styles.innerContainer} style={{ minHeight: "80vh" }}>
        {!pathname.includes("Dealer_Selection") &&
          !pathname.includes("Homepage") && <CustomBreadcrumbs />}
        <Component />
        {!pathname.includes("Homepage") && (
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              marginTop: "16px",
            }}
          >
            {process.env.REACT_APP_ENV !== "prod" && `SAP Env : ${sapEnvDetails?.sapENV}`}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ProtectedRoutes;
