import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { visuallyHidden } from "@mui/utils";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import styles from "./paidItemTable.module.scss";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { downloadInvoicePdf } from "../../../../state/actions";
import { Link } from "react-router-dom";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import MoreTimeOutlinedIcon from "@mui/icons-material/MoreTimeOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import { useTranslation } from "react-i18next";
import { getComparator, stableSort } from '../../../helper/util';
import moment from "moment";


const PaidItemTable = () => {
  const { t } = useTranslation();
  const [order, setOrder] = useState<'asc' | 'desc'>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("creditNote");
  const [rows, setRows] = useState<any>([]);
  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.userDetails
  );
  const paidItemsData = useSelector(
    (state: any) => state?.gazelleReducer?.paidItemsData?.invoices
  );
  const salesAreaMapping = useSelector(
    (state: any) => state?.commonReducer?.salesAreaMapping
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const addReplyEnquiryText = t("gzl.add_reply_enquiry.text");
  const displayPdfText = t("gzl.display_pdf.text");
  const createEnquiryText = t("gzl.create_enquiry.text");

  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    // Sorting table data on header click
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  interface Column {
    id:
      | "creditNote"
      | "documentType"
      | "invoiceDate"
      | "yourReference"
      | "amount"
      | "openAmount"
      | "currency"
      | "queries";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
  }

  const columns: readonly Column[] = [
    { id: "creditNote", label: t("gzl.invoice_credit_note.text") },
    { id: "documentType", label: t("gzl.doc_type.text") },
    { id: "invoiceDate", label: t("gzl.date.text"), minWidth: 80 },
    { id: "yourReference", label: t("gzl.your_ref.text") },
    { id: "amount", label: t("gzl.org_inv_value.text") },
    { id: "openAmount", label: t("gzl.amount_collected.text") },
    { id: "currency", label: t("gzl.currency.text") },
    { id: "queries", label: t("gzl.queries.text") },
  ];

  interface Data {
    creditNote: any;
    documentType: any;
    invoiceDate: any;
    yourReference: any;
    amount: any;
    openAmount: any;
    currency: any;
    queries: any;
  }

  function createData(
    creditNote: any,
    documentType: any,
    invoiceDate: any,
    yourReference: any,
    amount: any,
    openAmount: any,
    currency: any,
    queries: any
  ): Data {
    return {
      creditNote,
      documentType,
      invoiceDate,
      yourReference,
      amount,
      openAmount,
      currency,
      queries,
    };
  }

  const createRowData = () => {
    // Rows creation from API response
    let data = paidItemsData;
    let rowsdata = [];
    for (let i = 0; i < data?.length; i++) {
      rowsdata.push(
        createData(
          data[i]?.creditNote,
          data[i]?.documentType,
          data[i]?.invoiceDate,
          data[i]?.yourReference,
          data[i]?.amount,
          data[i]?.openAmount,
          data[i]?.currency,
          data[i]?.queries
        )
      );
    }
    setRows([...rowsdata]);
  };

  useEffect(() => {
    if(paidItemsData?.length>0)
    createRowData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paidItemsData]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // functionality to change number of rows in table
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const saveAccountDetails = useSelector(
    (state: any) => state?.commonReducer?.saveAccountDetails
  )

  const downloadPdf = (columnData: any) => {
    // PDF download
    dispatch(
      downloadInvoicePdf({
        language: userDetails?.language,
        username: userDetails?.userName,
        partnerKey: saveAccountDetails?.payerToAcc?.number,
        invoiceId: "SD_"+columnData?.invoiceId,
      })
    );
  };

  return (
    <div className={styles.tableContainer}>
      <TableContainer component={Paper} className={styles.paidTableContainerHeader}>
        <Table sx={{ minWidth: 650 }} aria-label="Paid Item Table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={createSortHandler(column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paidItemsData !== "null" &&
            paidItemsData?.length > 0 &&
            rows?.length > 0 ? (
              stableSort(paidItemsData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, key: any) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return column.id === "creditNote" ? (
                          <TableCell key={column.id} align={column.align}>
                            <span className={styles.pdfIcon}
                              title={displayPdfText}
                              onClick={(e: any) => {downloadPdf(row);}}>
                                {value}{" "}
                            </span>
                            <IconButton 
                              className={styles.pdfIcon}
                              onClick={(e: any) => {
                                downloadPdf(row);
                              }}
                            >
                              <PictureAsPdfOutlinedIcon
                                titleAccess={displayPdfText}
                              />
                            </IconButton>
                          </TableCell>
                        ) : column.id === "invoiceDate" ? (
                          <TableCell key={column.id} align={column.align}>
                            {
                              value && 
                              moment(value).format("DD.MM.YYYY")
                            }
                          </TableCell>
                        ) : column.id === "amount" ? (
                          <TableCell
                            sx={{ color: value > 0 ? "" : "red" }}
                            key={column.id}
                            align={column.align}
                          >
                            {value}
                          </TableCell>
                        ) : column.id === "openAmount" ? (
                          <TableCell key={column.id} align={column.align}>
                            <Link
                              className={`${
                                value > 0 ? styles.colorBlue : styles.colorRed
                              }`}
                              to="/Paid_Item_Details"
                              state={{
                                invoiceId: row?.invoiceId,
                              }}
                            >
                              {value}
                            </Link>
                          </TableCell>
                        ) : column.id === "queries" &&
                          row?.disputed &&
                          salesAreaMapping?.history_icon?.paidItems === "true" ? (
                          <TableCell key={column.id} align={column.align}>
                            <Link
                              to="/Reply_Enquiry"
                              state={{
                                invoiceId: row?.invoiceId,
                              }}
                            >
                              <IconButton>
                                {row?.newContact ? (
                                  <MoreTimeOutlinedIcon
                                    titleAccess={addReplyEnquiryText}
                                  />
                                ) : (
                                  <QueryBuilderIcon
                                    titleAccess={addReplyEnquiryText}
                                  />
                                )}
                              </IconButton>
                            </Link>
                          </TableCell>
                        ) : column.id === "queries" &&
                          !row?.disputed &&
                          salesAreaMapping?.create_enquiry?.paidItems === "true" ? (
                          <TableCell key={column.id} align={column.align}>
                            <Link
                              to="/Create_Enquiry"
                              state={{
                                invoiceId: row?.invoiceId,
                              }}
                            >
                              <IconButton>
                                <NoteAddOutlinedIcon
                                  titleAccess={createEnquiryText}
                                />
                              </IconButton>
                            </Link>
                          </TableCell>
                        ) : (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  align={"center"}
                  className={styles.noRecFound}
                >
                  {t("gzl.no_data_available.text")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.tableFooter}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default PaidItemTable;
