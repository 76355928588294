import { Typography, Box, IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import styles from './paymentDetails.module.scss';
import PaymentDetailsTable from './paymentDetailsTable';
import CreditDetailsTable from './creditDetailsTable';
import InvoiceDetailsTable from './invoiceDetailsTable';
import { useDispatch, useSelector } from "react-redux";
import { getPaymentDetailsData } from "../../../state/actions";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';    
import {useLocation, useNavigate} from 'react-router-dom';
import FinalBalanceTable from './finalBalanceTable';
import TotalAmountTable from './totalAmountTable';
import { useTranslation } from 'react-i18next';


export const PaymentDetails = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const userDetails = useSelector(
      (state: any) => state?.commonReducer?.userDetails
    );
    let referenceId = location?.state?.referenceId
    const paymentDetailsData = useSelector(
        (state: any) => state?.gazelleReducer?.paymentDetailsData
    );
    const backButtonText = t("gzl.back.text");

    const saveAccountDetails = useSelector(
      (state: any) => state?.commonReducer?.saveAccountDetails
    )
    const getAPIData = () => {
      // fetching API data
        dispatch(getPaymentDetailsData({
            referenceId,
            partnerKey: saveAccountDetails?.payerToAcc?.number,
        }));
    };

    useEffect(() => {
        if(!referenceId){
          // Navigating user back if no refrenceId present
            navigate(-1)
        }
        getAPIData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <div id="paymentDetailsBox">
        <Box className={styles.headerBox}>
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIosIcon titleAccess={backButtonText} />
          </IconButton>
          <Typography
            sx={{ paddingBottom: "0px" }}
            variant="subtitle1"
            className={styles.header}
            pb={3}
            color="primary"
          >
            {t("gzl.payment_detail_header.text")?.toString()}
          </Typography>
        </Box>
        <Box>
          <Box className={styles.tableContainer}>
            <div className={styles.tableHeader}></div>
            <PaymentDetailsTable />
          </Box>
          <Box className={styles.tableContainer}>
            <div className={styles.tableHeader}>
              <Typography variant="body2">
                {t("gzl.payment_detail_headline.text")}
              </Typography>
            </div>
            <CreditDetailsTable />
          </Box>
          <Box className={styles.tableContainer}>
            <div className={styles.tableHeader}>
              <Typography variant="body2">
                {t("gzl.payment_detail_headline2.text")}
              </Typography>
            </div>
            <InvoiceDetailsTable />
          </Box>
          {paymentDetailsData?.finalBalance && (
            <Box className={styles.tableContainer}>
              <div className={styles.tableHeader}>
                <Typography variant="body2">
                  {paymentDetailsData?.finalBalance > 0
                    ? t("gzl.payment_detail_headline3.text")
                    : t("gzl.payment_detail_headline4.text")}
                </Typography>
              </div>
              <FinalBalanceTable />
            </Box>
          )}
          {paymentDetailsData?.totalAmount && (
            <Box className={styles.tableContainer}>
              <div className={styles.tableHeader}></div>
              <TotalAmountTable />
            </Box>
          )}
        </Box>
      </div>
    );
}