import {
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import styles from "./add-comments.module.scss";
import moment from "moment";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { REPLY_ENQUIRY } from "../../../../constants/labels";
import CustomButton from "../../../atoms/customButton";
import { useDispatch } from "react-redux";
import { saveComment, saveCommentSuccess } from "../../../../state/actions";
import CustomModal from "../../../atoms/customModal";
import { useTranslation } from "react-i18next";
import logger from "../../../helper/logger";

export const AddComments = (props: any) => {
  const { t } = useTranslation();
  const { invoiceId } = props;
  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.userDetails
  );
  const replyEnquiryComments = useSelector(
    (state: any) => state?.gazelleReducer?.replyEnquiryComments
  );

  const [showAddComments, setShowAddComments] = React.useState(false);

  const saveAccountDetails = useSelector(
    (state: any) => state?.commonReducer?.saveAccountDetails
  )
  
  const saveCommentsHandler = () => {
    // Hitting API with the comments newly added
    logger.info("Saving comment ",additionalComments);
    dispatch(
      saveComment({
        username: userDetails?.userName,
        invoiceId: invoiceId,
        reasonTextList: [additionalComments],
        accountId: saveAccountDetails?.payerToAcc?.number,
        language: userDetails?.language,
        dynamicBapiField: [{}],
      })
    );
  };

  const saveCommentResponse = useSelector(
    (state: any) => state?.gazelleReducer?.saveComment
  );

  const [additionalComments, setAdditionalComments] = React.useState("");
  const [additionalCommentsErr, setAdditionalCommentsErr] = React.useState("");

  const setComments = (value: string) => {
    // Functionality to check on comment length & set error message if any
    setAdditionalComments(value);
    if (value?.length > 500) {
      setAdditionalCommentsErr(REPLY_ENQUIRY.MAX_500_CHARS);
    } else {
      setAdditionalCommentsErr("");
    }
  };

  const [openModal, setOpenModal] = React.useState(false);

  React.useEffect(() => {
    if (saveCommentResponse) {
      setOpenModal(true);
    }
  }, [saveCommentResponse]);

  const closeModal = () => {
    // Close Modal functionality on click of Ok or close icon
    setOpenModal(false);
    setShowAddComments(false);
    setAdditionalComments("");
    dispatch(saveCommentSuccess(false));
  };

  return (
    <>
      <Grid container>
        <Grid item lg={5} md={6} sm={12} xs={12}>
          {replyEnquiryComments?.map((comment: any) => (
            <Card key={comment?.date} className={styles.cardStyle}>
              <CardContent className={styles.cardContent}>
                <div className={styles.commentContainer}>
                  <div>
                    <CommentOutlinedIcon color="action" />
                  </div>
                  <div className={styles.contentDiv}>
                    <div className={styles.commentHeader}>
                      <Typography
                        component="div"
                        variant="body2"
                        color="primary"
                      >
                        {comment?.commentedBy}
                      </Typography>
                      <Typography
                        component="div"
                        variant="caption"
                        color="text.secondary"
                        className={styles.dateContainer}
                      >
                        <span>
                          <AccessTimeOutlinedIcon
                            className={styles.timerIcon}
                          />
                        </span>
                        <span>
                          {/* {moment(comment?.date).format("DD.MM.YYYY HH:mm:SS")} */}
                          {comment?.date}
                        </span>
                      </Typography>
                    </div>
                    <Typography
                      component="div"
                      variant="body2"
                      color="text.secondary"
                      mt={0.5}
                    >
                      {comment?.comment?.map((ele: any) => (
                        <span key={ele}>{ele}<br /></span>
                      ))}
                    </Typography>
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}
        </Grid>
        {showAddComments && (
          <Grid item lg={5} md={6} sm={12} xs={12} className={styles.newComments}>
            <TextField
              id="commentBox"
              fullWidth
              placeholder={t("gzl.max_character.text")?.toString()}
              multiline
              rows={2}
              label={t("gzl.additional_comment.text")}
              onChange={(e: any) => setComments(e.target.value)}
              value={additionalComments}
              helperText={additionalCommentsErr}
              error={additionalCommentsErr?.length > 0}
              style={{ background: "white" }}
            />
            <Box mt={2}>
              <CustomButton
                id="cancelButton"
                label={t("dnet.cancel.text")}
                onClick={() => {
                  setShowAddComments(false);
                  setAdditionalComments("");
                }}
                margin="0"
              />
              <CustomButton
                label={t("gzl.send_button.text")}
                onClick={saveCommentsHandler}
                color="primary"
                margin="0 0 0 20px"
                disabled={
                  additionalCommentsErr?.length > 0 ||
                  additionalComments?.length <= 0
                }
              />
            </Box>
          </Grid>
        )}
      </Grid>
      <CustomButton
        id="addCommentButton"
        label={t("gzl.add_reply_enquiry.text")}
        onClick={() => setShowAddComments(true)}
        color="primary"
        margin="0"
      />
      <CustomModal
        open={openModal}
        closeModal={closeModal}
        heading={t("gzl.conf_enquiry_title.text")?.toString()}
        content={t("gzl.conf_enquiry_headline.text")}
      />
    </>
  );
};
