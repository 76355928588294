import { commonConstant } from "../constants/common-constants";

export const initialState = {
  priceChangeData: []
};

const priceChangeReducer = (state = initialState, action: any) => {
  switch (action?.type) {
    case commonConstant.PRICE_CHANGE_SUCCESS:
      return {
        ...state,
        priceChangeData: action?.payload,
      };
    default:
      return state;
  }
};

export default priceChangeReducer;
