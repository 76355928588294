import React from "react";
import Carousel from "react-material-ui-carousel";
import CustomCard from "./customCard";

const NewProductCard = ({ products }: any) => {
  const arrProduct = products?.filter((elem: any, i: any) => {
    return i < 5 && elem?.product?.index?.uri != null;
  });

  return (
    <>
      <Carousel
        autoPlay={true}
        animation="slide"
        indicators={true}
        navButtonsAlwaysInvisible={true}
      >
        {arrProduct.map((prod: any, key: any) => (
          <CustomCard key={key} product={prod?.product}></CustomCard>
        ))}
      </Carousel>
    </>
  );
};

export default NewProductCard;
