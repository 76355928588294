import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { applyMiddleware, createStore } from "redux";
import reducers from "./state/reducers";
import { Provider } from "react-redux";
import saga from "./state/sagas/index";
import createSagaMiddleware from "redux-saga";
import {ThemeProvider, createTheme} from '@mui/material/styles';
import './i18n';
import { interceptor } from "./state/interceptors";
import { getTitle } from "./components/helper/util";

const theme = createTheme({
  palette:{
    primary: {
      main: "#f25529",
    },
    secondary: {
      main: "#000000",
    },
    warning: {
      main: "#dc3545"
    },
    info: {
      main: "#5887f5"
    }
  },
  typography:{
    subtitle1: {
      fontFamily: "Roboto,Helvetica,Arial,sans-serif",
      fontWeight: 400,
      fontSize: "25px",
      lineHeight: "1.235",
      letterSpacing: "0.00735em",
    },
    subtitle2: {
      fontFamily: "Roboto,Helvetica,Arial,sans-serif",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "1.235",
      letterSpacing: "0.00735em",
    }
  }
})
// if('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('/service-worker.js');
//   });
// }

const sagaMiddleware = createSagaMiddleware();

document.title = getTitle();

const store = createStore(reducers, applyMiddleware(sagaMiddleware));
interceptor(store);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

sagaMiddleware.run(saga);

