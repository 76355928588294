import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import styles from "./featuresTab.module.scss";

export const FeaturesTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const productDetailsData = useSelector(
    (state: any) => state?.productReducer?.productDetailsData
  );

  return (
    <>
      {productDetailsData?.featureBenefits?.map((overview: any, index: any) => (
        <Grid key={index} container className={styles.featuresCard} mb={3}>
          {overview?.shot && (
            <Grid item lg={4} md={4} sm={3} xs={12} p={2}>
              <img src={overview?.shot} className={styles.featuresImage} />
            </Grid>
          )}
          <Grid
            item
            lg={overview?.shot ? 8 : 12}
            md={overview?.shot ? 8 : 12}
            sm={overview?.shot ? 9 : 12}
            xs={12}
            p={2}
          >
            <Typography variant="body1" color="primary" pb={1}>
              {overview?.headline}
            </Typography>
            <Typography
              component="div"
              variant="caption"
              color="text.secondary"
            >
              {overview?.bodyCopy}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </>
  );
};
