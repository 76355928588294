import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Box, Checkbox, Typography } from "@mui/material";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CONTEXT_ORDER,
  // TOAST_MESSAGES,
  TOAST_SEVERITY,
} from "../../../constants/commonConstants";
import { LABELS } from "../../../constants/labels";
import { ROUTES_LABEL } from "../../../constants/routesLabels";
import {
  fetchProdCategoryResponse,
  fetchSiteSectionDetails,
  // getDealerDetails,
  getSalesAreaMapping,
  getAddressListSuccess,
  // getSAPEnvDetails,
  // getTranslationAction,
  loginUser,
  resetPasswordRequest,
  setCurrentDealer,
  setCurrentDealerInContext,
  setIsUserLoggedin,
  setOpenToast,
  setSonyMenuItems,
  getLoginCaptchaValidationSuccess,
  getCaptchaValidation,
  getArticlesFromCMS,
  getLoginCMSDataSuccess,
  startProductFlow,
  // getNewProducts,
  setUnauthorizedUser,
  setLoading,
  saveLoginError,
  getContextDetails,
} from "../../../state/actions";
import CustomButton from "../../atoms/customButton";
import CustomModal from "../../atoms/customModal";
import CustomTextField from "../../atoms/customTextField";
// import { Header } from "../../header";
import logger from "../../helper/logger";
import {
  // base64Decryption,
  // base64Encryption,
  createMenuItems,
} from "../../helper/util";
import styles from "./loginForm.module.scss";
var _ = require("lodash");

export const LoginForm = ({ checkArticleClick, articleClicked }: any) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginPageError = useSelector(
    (state: any) => state?.commonReducer?.loginPageError
  );

  // let usernameCookie = "";
  // let passwordCookie = "";
  // if (Cookies.get("SonyUsername") && Cookies.get("SonyPassword")) {
  //   usernameCookie = base64Decryption(Cookies.get("SonyUsername"));
  //   passwordCookie = base64Decryption(Cookies.get("SonyPassword"));
  // }

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(
    t("dnet.login_or_password_empty.text")
  );
  const [rememberMe, setRememberMe] = useState(false);

  // const sapEnvDetails = useSelector(
  //   (state: any) => state?.commonReducer?.sapEnvDetails
  // );

  const siteSectionDetails = useSelector(
    (state: any) => state?.commonReducer?.siteSectionDetails
  );

  const setCurrentDealerSuccessRes = useSelector(
    (state: any) => state?.commonReducer?.setCurrentDealerSuccessRes
  );

  const isUserAuthorized = useSelector(
    (state: any) => state?.commonReducer?.isUserAuthorized
  );

  const prodCategoryResponse = useSelector(
    (state: any) => state?.commonReducer?.prodCategoryResponse
  );

  useEffect(() => {
    if (_.isEmpty(siteSectionDetails)) {
      dispatch(fetchSiteSectionDetails({}));
    }
  }, []);

  const isFormValid = () => {
    if (userName?.trim() && password) return true;
    else return false;
  };
  const onKeyDown = (event: any) => {
    if (event.key === "Enter") loginHandler();
  };
  let CAPTCHA_ENABLED = process.env?.REACT_APP_CAPTCHA_ENABLED
    ? process.env?.REACT_APP_CAPTCHA_ENABLED
    : false;
  const captchaRef = useRef<any>();
  const handleCaptchaChange = (value: any) => {
    if (value) {
      dispatch(setLoading(true));
      dispatch(getCaptchaValidation({ googleRecaptcha: value, from: "login" }));
    }
  };
  useEffect(() => {
    dispatch(getLoginCaptchaValidationSuccess({ success: false }));
  }, []);
  const validateCaptchaRes = useSelector(
    (state: any) => state?.commonReducer?.loginValidateCaptchaRes
  );
  useEffect(() => {
    if (validateCaptchaRes?.success) {
      loginClickHandler();
    }
  }, [validateCaptchaRes]);
  const loginClickHandler = () => {
    setError(false);
    dispatch(
      saveLoginError({
        error: "",
      })
    );
    // const encryptedPassword = base64Encryption(password);
    // const encryptedUsername = base64Encryption(userName);
    if (!isFormValid()) {
      dispatch(setLoading(false));
      setErrorMsg(t("dnet.login_or_password_empty.text"));
      setError(true);
    } else {
      setError(false);
      dispatch(setLoading(true));
      dispatch(getLoginCaptchaValidationSuccess({ success: false }));
      dispatch(loginUser({ username: userName, password }));
    }
    // Below code is for setting up username and password in cookies
    // const oneWeek = 7;
    // const now = new Date();
    // const expires = new Date(now.getTime() + oneWeek * 24 * 60 * 60 * 1000);
    // rememberMe &&
    //   Cookies.set("SonyUsername", encryptedUsername, {
    //     httpsOnly: true,
    //     secure: true,
    //     expires,
    //   });
    // rememberMe &&
    //   Cookies.set("SonyPassword", encryptedPassword, {
    //     httpsOnly: true,
    //     secure: true,
    //     expires,
    //   });
  };
  const loginHandler = () => {
    dispatch(
      setUnauthorizedUser({
        isUserAuthorized: true,
      })
    );
    if (CAPTCHA_ENABLED) {
      captchaRef.current.execute();
    } else {
      loginClickHandler();
    }
  };

  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.loginResponse?.data?.userDetails
  );

  const loginResponse = useSelector(
    (state: any) => state?.commonReducer?.loginResponse
  );

  const [openModal, setOpenModal] = React.useState(false);

  const closeModal = () => {
    setOpenModal(false);
  };

  const [openForgotPassModal, setOpenForgotPassModal] = useState(false);
  const [userOrEmail, setUserOrEmail] = useState("");
  const [showForgotPassConfirmation, setShowForgotPassConfirmation] =
    useState(false);
  const closeForgotPassModal = () => {
    setOpenForgotPassModal(false);
    setUserOrEmail("");
    setShowForgotPassConfirmation(false);
  };
  const searchUserOrEmail = () => {
    // forgot password functionality
    if (userOrEmail && userOrEmail?.length >= 1) {
      setShowForgotPassConfirmation(true);
      dispatch(
        resetPasswordRequest({
          userNameOrEmail: userOrEmail,
          locale: currentLocale,
          // domainName: window.location.hostname,
        })
      );
    } else setShowForgotPassConfirmation(false);
  };

  // const currentTranslation = useSelector(
  //   (state: any) => state?.commonReducer?.translation
  // );

  const currentLocale = useSelector(
    (state: any) => state?.commonReducer?.locale
  );

  const setProductResponse = useSelector(
    (state: any) => state?.commonReducer?.productFlow
  );

  // useEffect(() => {
  //   i18n.addResourceBundle(
  //     currentLocale,
  //     "translation",
  //     currentTranslation,
  //     true
  //   );
  //   i18n.changeLanguage(currentLocale);
  // }, [currentTranslation]);

  // useEffect(() => {
  //   //onload fetch translation
  //   dispatch(getTranslationAction({ locale: currentLocale }));
  // }, []);

  const dispatchSetProduct = () => {
    dispatch(
      startProductFlow({
        siteLocale: currentLocale,
      })
    );
  };

  const dispatchFetchProdCategory = () => {
    if (_.isEmpty(prodCategoryResponse)) {
      dispatch(fetchProdCategoryResponse({}));
    }
  };

  const dispatchSonyMenu = () => {
    if (
      loginResponse?.data?.userDetails?.businessGroups?.length === 1 &&
      loginResponse?.data?.userDetails?.businessGroups[0]?.type === "R" &&
      !_.isEmpty(
        loginResponse?.data?.dealerNetContext?.dealerNetContext?.currentAccount
      ) &&
      !_.isEmpty(
        loginResponse?.data?.dealerNetContext?.dealerNetContext?.currentAccount
          ?.internalAdmin
      )
    ) {
      let currentRoles = ["priceChangeViewer"];
      dispatch(
        setSonyMenuItems(
          createMenuItems(
            siteSectionDetails,
            currentRoles,
            loginResponse?.data?.dealerNetContext?.dealerNetContext
              ?.currentAccount?.internalAdmin?.name,
            loginResponse?.data?.userDetails?.profile?.locale,
            loginResponse?.data,
            prodCategoryResponse?.data?.catalogList[0]
          )
        )
      );
    } else {
      let currentRoles = userDetails?.businessGroups.find(
        (bg: any) =>
          bg.name === loginResponse?.data?.userDetails?.businessGroups[0]?.name
      )?.roles;
      if (currentRoles?.length > 0) {
        dispatch(
          setSonyMenuItems(
            createMenuItems(
              siteSectionDetails,
              currentRoles,
              loginResponse?.data?.userDetails?.businessGroups[0]?.name,
              loginResponse?.data?.userDetails?.profile?.locale,
              loginResponse?.data,
              prodCategoryResponse?.data?.catalogList[0]
            )
          )
        );
      }
    }
  };

  useEffect(() => {
    if (!_.isEmpty(loginResponse)) {
      if (
        loginResponse?.success === true &&
        !_.isEmpty(loginResponse?.data?.userDetails?.profile?.organisation) &&
        !Array?.isArray(
          loginResponse?.data?.userDetails?.profile?.organisation
        ) &&
        loginResponse?.data?.userDetails?.profile?.organisation !== null
      ) {
        logger.info(userName, "Successfully logged in");
        dispatch(setIsUserLoggedin(true));
        //setting up userToken in session storage
        window.sessionStorage.setItem("st", loginResponse?.data?.token);
        //if only 1 business group and 1 account is present in login response then we should
        //directly land the user to home screen and also fetch site section and sales area mapping
        if (
          loginResponse?.data?.userDetails?.businessGroups?.length === 1 &&
          loginResponse?.data?.userDetails?.businessGroups[0]?.type === "R" &&
          !_.isEmpty(
            loginResponse?.data?.dealerNetContext?.dealerNetContext
              ?.currentAccount
          ) &&
          !_.isEmpty(
            loginResponse?.data?.dealerNetContext?.dealerNetContext
              ?.currentAccount?.internalAdmin
          )
        ) {
          dispatch(
            setCurrentDealer({
              bgName:
                loginResponse?.data?.dealerNetContext?.dealerNetContext
                  ?.currentAccount?.internalAdmin?.masterSalesArea,
              accNum:
                loginResponse?.data?.dealerNetContext?.dealerNetContext
                  ?.currentAccount?.internalAdmin?.name,
              accountName:
                loginResponse?.data?.dealerNetContext?.dealerNetContext
                  ?.currentAccount?.internalAdmin?.name,
              // street: details?.street,
              // country: details?.country,
              // townCity: details?.townCity,
              loggedInUser: loginResponse?.data?.userDetails,
              singleAccount: true,
              accountFlag: 0,
            })
          );
          dispatch(
            setCurrentDealerInContext({
              accountFlag: 0,
              accountId:
                loginResponse?.data?.dealerNetContext?.dealerNetContext
                  ?.currentAccount?.internalAdmin?.name,
              msa: loginResponse?.data?.dealerNetContext?.dealerNetContext
                ?.currentAccount?.internalAdmin?.masterSalesArea,
            })
          );
          dispatch(
            getSalesAreaMapping({
              selectedDealership:
                loginResponse?.data?.userDetails?.businessGroups[0]
                  ?.accounts?.[0],
              localeID:
                loginResponse?.data?.userDetails?.businessGroups[0]?.name,
            })
          );
        } else if (
          loginResponse?.data?.userDetails?.businessGroups?.length === 1 &&
          loginResponse?.data?.userDetails?.businessGroups[0]?.type === "R"
        ) {
          dispatch(
            setCurrentDealer({
              bgName: loginResponse?.data?.userDetails?.businessGroups[0]?.name,
              accNum: loginResponse?.data?.userDetails?.businessGroups[0]?.name,
              accountName:
                loginResponse?.data?.userDetails?.businessGroups[0]?.name,
              // street: details?.street,
              // country: details?.country,
              // townCity: details?.townCity,
              loggedInUser: loginResponse?.data,
              singleAccount: true,
              accountFlag: 2,
            })
          );
          dispatch(
            setCurrentDealerInContext({
              accountFlag: 2,
              accountId:
                loginResponse?.data?.userDetails?.businessGroups[0]?.name,
              msa: loginResponse?.data?.userDetails?.businessGroups[0]?.name,
            })
          );
          let currentRoles =
            loginResponse?.data?.userDetails?.businessGroups[0]?.roles;
          if (currentRoles?.length > 0) {
            dispatch(
              getSalesAreaMapping({
                selectedDealership:
                  loginResponse?.data?.userDetails?.businessGroups[0]
                    ?.accounts?.[0],
                localeID:
                  loginResponse?.data?.userDetails?.businessGroups[0]?.name,
              })
            );
          }
        }
        // else if (
        //   loginResponse?.data?.userDetails?.businessGroups?.length === 1 &&
        //   loginResponse?.data?.userDetails?.businessGroups[0].accounts?.length === 1
        // ) {
        //   dispatch(
        //     getDealerDetails({
        //       accountDetails: [
        //         {
        //           bgGroup: loginResponse?.data?.userDetails?.businessGroups[0]?.name,
        //           accountId:
        //             loginResponse?.data?.userDetails?.businessGroups[0]?.accounts[0],
        //         },
        //       ],
        //     })
        //   );
        // }
        else {
          dispatch(getAddressListSuccess({}));
          dispatch(setLoading(false));
          navigate(`/${ROUTES_LABEL.DEALER_SELECTION}`);
        }
      } else {
        dispatch(setLoading(false));
        setErrorMsg(t(`${loginResponse?.data?.error}`));
        setError(true);
        captchaRef.current.reset();
      }
    } else {
      dispatch(setLoading(false));
    }
  }, [loginResponse]);

  const dealerDetails = useSelector(
    (state: any) => state?.commonReducer?.dealerDetails
  );

  useEffect(() => {
    if (dealerDetails && dealerDetails?.dealers?.length > 0) {
      let details =
        dealerDetails?.dealers?.[0]?.[
          loginResponse?.data?.userDetails?.businessGroups[0]?.accounts[0]
        ];
      dispatch(
        setCurrentDealer({
          bgName: loginResponse?.data?.userDetails?.businessGroups[0]?.name,
          accNum:
            loginResponse?.data?.userDetails?.businessGroups[0]?.accounts[0],
          accountName: details?.name,
          street: details?.street,
          country: details?.country,
          townCity: details?.townCity,
          loggedInUser: loginResponse?.data,
          singleAccount: true,
          accountFlag: 1,
        })
      );
      dispatch(
        setCurrentDealerInContext({
          accountFlag: 1,
          accountId:
            loginResponse?.data?.userDetails?.businessGroups[0]?.accounts[0],
          msa: loginResponse?.data?.userDetails?.businessGroups[0]?.name,
        })
      );
      let currentRoles = userDetails?.businessGroups.find(
        (bg: any) =>
          bg.name === loginResponse?.data?.userDetails?.businessGroups[0]?.name
      )?.roles;
      if (currentRoles?.length <= 0) {
        dispatch(
          setOpenToast({
            openToast: true,
            severity: TOAST_SEVERITY.ERROR,
            message: t("dnet.access_error.text")?.toString(),
          })
        );
      }
      dispatch(
        getSalesAreaMapping({
          selectedDealership:
            loginResponse?.data?.userDetails?.businessGroups[0]?.accounts[0],
          localeID: loginResponse?.data?.userDetails?.businessGroups[0]?.name,
        })
      );
    }
  }, [dealerDetails]);

  const sonyMenuItems = useSelector(
    (state: any) => state?.commonReducer?.sonyMenuItems
  );

  useEffect(() => {
    if (setCurrentDealerSuccessRes?.success) {
      dispatchSetProduct();
    }
  }, [setCurrentDealerSuccessRes]);

  useEffect(() => {
    if (setProductResponse?.success) {
      dispatchFetchProdCategory();
    }
  }, [setProductResponse]);

  useEffect(() => {
    if (!_.isEmpty(prodCategoryResponse) && prodCategoryResponse?.success) {
      dispatchSonyMenu();
    }
  }, [prodCategoryResponse]);

  useEffect(() => {
    if (
      sonyMenuItems?.length > 0 &&
      setCurrentDealerSuccessRes?.success &&
      setProductResponse?.success
    ) {
      dispatch(setLoading(false));
      dispatch(
        getContextDetails({
          key: CONTEXT_ORDER?.RPEORTING_PAGE,
        })
      );
      navigate(`/${ROUTES_LABEL.SONY_HOMEPAGE}`);
    }
  }, [sonyMenuItems]);

  const related_items = useSelector(
    (state: any) => state?.userProfileOptionsReducer?.cmsArticles
  );

  useEffect(() => {
    dispatch(
      getArticlesFromCMS({
        module: "login",
        params: {
          [`filter_locale[${i18n.language}]`]: i18n.language,
          [`filter_bg[S1_0]`]: "S1_0",
          [`acf_like[main_content_category]`]: "Home",
          [`acf_like[content_category]`]: "Home>Landing",
          [`acf_like[rh_related_category]`]: "Login",
          max_result: "3",
        },
      })
    );
  }, []);

  return (
    <>
      {" "}
      {/*  <Box className={styles.loginContainer}>
           <Header fromLogin={true} /> */}
      <div>
        <Box className={styles.boxStyle}>
          <Box className={styles.loginCard}>
            <div className={styles.loginIcon}>
              <AccountCircleOutlinedIcon
                color="action"
                className={styles.svg_icons}
              />
            </div>
            <CustomTextField
              additionalSxProperties={{ margin: "12px 16px" }}
              id="userName"
              label={t("dnet.mail_address.text")}
              value={userName}
              changeHandler={(e: any) => setUserName(e.target.value)}
            />
            <CustomTextField
              additionalSxProperties={{ margin: "12px 16px" }}
              id="password"
              label={t("dnet.password.text")}
              value={password}
              changeHandler={(e: any) => setPassword(e.target.value)}
              onKeyDown={onKeyDown}
              type="password"
            />
            <div className={styles.buttonStyle}>
              <div
                className={styles.rememberBlock}
                onClick={() => setRememberMe(!rememberMe)}
              >
                <Checkbox
                  sx={{ padding: "5px" }}
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                <Typography
                  sx={{
                    fontFamily: "Roboto,Helvetica,Arial,sans-serif",
                    fontWeight: "500",
                    fontSize: "0.875rem",
                    lineHeight: "1.75",
                    letterSpacing: "0.02857em",
                  }}
                >
                  {t("dnet.remember_me.text")}
                </Typography>
              </div>
              <div
                className={styles.forgotPassBlock}
                onClick={() => setOpenForgotPassModal(!openForgotPassModal)}
              >
                <Typography
                  sx={{
                    fontFamily: "Roboto,Helvetica,Arial,sans-serif",
                    fontWeight: "500",
                    fontSize: "0.875rem",
                    lineHeight: "1.75",
                    letterSpacing: "0.02857em",
                  }}
                  className={styles.hyperlinkText}
                >
                  {t("dnet.forgotten_password.text") + " ?"}
                </Typography>
              </div>
              {/* <CustomButton
                id="loginButton"
                label={t("dnet.login.text")}
                onClick={loginHandler}
                color="primary"
                margin="12px 16px"
                additionalSxProperties={{ width: "30%" }}
              /> */}
            </div>
            {CAPTCHA_ENABLED && (
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={
                  process.env?.REACT_APP_CAPTCHA_SITEKEY
                    ? process.env?.REACT_APP_CAPTCHA_SITEKEY
                    : ""
                }
                onChange={handleCaptchaChange}
                size="invisible"
              />
            )}
            <CustomButton
              id="loginButton"
              label={t("dnet.login.text")}
              onClick={loginHandler}
              color="primary"
              margin="12px 16px"
              additionalSxProperties={{ width: "92%" }}
              fontSize="12px"
              padding="4px 10px"
            />
            {(error || !isUserAuthorized || loginPageError) && (
              <div className={styles.errorMessage}>
                <CancelOutlinedIcon />
                <Typography variant="body2" ml={1}>
                  {!isUserAuthorized
                    ? t("dnet.landing_page_session_expired.text")?.toString()
                    : loginPageError
                    ? loginPageError
                    : errorMsg}
                </Typography>
              </div>
            )}
            {related_items?.length > 0 &&
              !articleClicked &&
              related_items?.map((item: any, key: any) => {
                return (
                  item?.related_items?.length > 0 && (
                    <div className={styles.cmsContentLinks} key={key}>
                      {item?.related_items?.map((article: any, index: any) => {
                        return (
                          article?.acf_fc_layout === "add_article" &&
                          article?.article_title !== "" && (
                            <Typography
                              variant="caption"
                              onClick={() => {
                                dispatch(getLoginCMSDataSuccess(related_items));
                                checkArticleClick();
                              }}
                              key={index}
                            >
                              {article?.article_title}
                            </Typography>
                          )
                        );
                      })}
                    </div>
                  )
                );
              })}
          </Box>
        </Box>
        {/* <div
            className={styles.registration}
            onClick={() => navigate(`/${ROUTES_LABEL.REGISTRATION}`)}
          >
            {t("dnet.apply_registration.text")}
          </div> */}
        {/* <div style={{ position: "fixed", bottom: "32px", left: "32px" }}>
            SAP : {sapEnvDetails?.sapENV}
          </div> */}
      </div>
      {/* </Box> */}
      <CustomModal
        id="loginAccessDeniedModal"
        open={openModal}
        closeModal={closeModal}
        heading={LABELS.ACCESS_DENIED}
        content={t("gzl.unauthorized_access.text")}
      />
      <CustomModal
        id="forgottenPasswordModal"
        open={openForgotPassModal}
        closeModal={closeForgotPassModal}
        heading={t("dnet.forgotten_password.text")?.toString()}
        content={t("dnet.enter_email_or_userid.text")}
        forgotPassModal={openForgotPassModal}
        userOrEmail={userOrEmail}
        getUserOrEmail={(e: any) => setUserOrEmail(e.target.value)}
        searchUserOrEmail={() => searchUserOrEmail()}
        showForgotPassConfirmation={showForgotPassConfirmation}
      />
    </>
  );
};

LoginForm.defaultProps = {
  checkArticleClick: () => {},
  articleClicked: false,
};
