import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { LABELS } from "../../../constants/labels";
import CustomButton from "../customButton";
import CustomTextField from "../customTextField";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import {useTranslation} from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { ROUTES_LABEL } from "../../../constants/routesLabels";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
  textAlign: "center",
};
const invoiceStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
  paddingBottom: "45px",
  textAlign: "left",
};
const modalHeader = {
  display: "flex", 
  justifyContent: "space-between", 
  alignItems: "center",
}
const confirmationStyle = {
  marginBottom: "10px",
  color: "#155724",
  backgroundColor: "#d4edda",
  borderColor: "#c3e6cb",
  padding: "0.75rem 1.25rem",
  border: "1px solid transparent",
  borderRadius: "1px",
}

export default function CustomModal(props: any) {
  const { 
    open, 
    closeModal,
    heading, 
    content, 
    invoiceSearchModal, 
    invoiceSearchFunc, 
    getInvoiceNumber, 
    invoiceNumber,
    forgotPassModal,
    searchUserOrEmail,
    userOrEmail,
    getUserOrEmail,
    showForgotPassConfirmation,
    createReport,
    cartTotal,
    clearOrderHandler,
    placeOrder,
    navigateHandler,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      {!invoiceSearchModal && !forgotPassModal ? 
        <Modal
          open={open}
          onClose={createReport? ()=>{} : closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {cartTotal ? 
            <div style={modalHeader}>
              <Typography variant="h6" color="primary">
                {heading}
              </Typography>
              <IconButton onClick={closeModal}>
                <CloseIcon />
              </IconButton>
            </div> : 
            <Typography variant="h6" color="primary">
              {heading}
            </Typography>
            }
            <Typography sx={{ mt: 2, mb:2 }} dangerouslySetInnerHTML={{__html: content}}>
            </Typography>
            {
              !createReport && !placeOrder ?
              <CustomButton label={LABELS.OK} color="primary" onClick={closeModal}/> : cartTotal ?
              <>
                <CustomButton 
                  label={t("dnet.display_order.text")?.toString()} 
                  color="primary" 
                  onClick={()=>{
                    closeModal();
                    navigate(`/${ROUTES_LABEL.ORDER_ENTRY}`)
                  }}
                />
                <CustomButton 
                  label={t("dnet.yes.text")?.toString()} 
                  onClick={()=>{
                    closeModal();
                  }}
                />
                <CustomButton 
                  label={t("dnet.no.text")?.toString()} 
                  onClick={()=>{
                    clearOrderHandler();
                  }}
                />
              </> : placeOrder ?
              <>
                <CustomButton 
                  label={t("dnet.modify.text")?.toString()} 
                  onClick={()=>closeModal()}
                />
                <CustomButton 
                  label={t("dnet.continue.text")?.toString()} 
                  color="primary" 
                  onClick={()=>{
                    closeModal();
                    navigateHandler();
                  }}
                />
              </> :
              <>
                <CustomButton 
                  label={t("dnet.home.text")?.toString()} 
                  color="primary" 
                  onClick={()=>{
                    closeModal();
                    navigate("/Homepage")
                  }}
                />
                <CustomButton 
                  label={t("dnet.report_management.text")?.toString()} 
                  onClick={()=>{
                    closeModal();
                    navigate("/Manage_Reports")
                  }}
                />
              </>
            }
          </Box>
        </Modal>
        : !forgotPassModal ?
        <Modal
          open={open}
          onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={invoiceStyle}>
            <div style={modalHeader}>
              <Typography variant="h6" color="primary">
                {heading}
              </Typography>
              <IconButton onClick={closeModal}>
                <CloseIcon />
              </IconButton>
            </div>
            <Typography sx={{ mt: 2, mb:2 }}>
              {content}
            </Typography>
            <div 
              style={{
                display:"flex", 
                flexWrap:"wrap", 
                gap:"10px", 
                alignItems: "end"
              }}
            >
              <CustomTextField 
                label={t("gzl.invoice_no.text")} 
                changeHandler={getInvoiceNumber}
                value={invoiceNumber}
              />
              <CustomButton label={t("gzl.find_button.text")} color="primary" onClick={invoiceSearchFunc}/>
            </div>
          </Box>
        </Modal>
        :
        <Modal
          open={open}
          onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={invoiceStyle}>
            <div style={modalHeader}>
              <Typography variant="h6" color="primary">
                {heading}
              </Typography>
              <IconButton onClick={closeModal}>
                <CloseIcon />
              </IconButton>
            </div>
            <Typography sx={{ mt: 4, mb:2 }}>
               {showForgotPassConfirmation ? 
                <div style={confirmationStyle}>
                  {t("dnet.email_sent_confirmation.text")}
                </div>
                : null
               }
               {content}
            </Typography>
            <div style={{display:"flex", flexDirection:"column", width:"70%", gap:"15px"}}>
              <CustomTextField 
                label={t("dnet.mail_address.text")} 
                changeHandler={getUserOrEmail}
                value={userOrEmail}
              />
              <CustomButton 
                label={t("dnet.continue.text")} 
                color="primary" 
                onClick={searchUserOrEmail}
                margin={0}
              />
            </div>
          </Box>
        </Modal>
      }
    </div>
  );
}

CustomModal.defaultProps = {
  invoiceSearchModal: false,
  forgotPassModal: false,
  createReport: false,
  cartTotal: false,
  placeOrder: false,
}
