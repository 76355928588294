import moment from "moment";

const logger = (() => {

  //to check if the environment is dev
  const isDev: boolean = process.env.REACT_APP_ENV !== "prod";

  //if we have set 'debug' key as 'true' in session storage then we can see logs irrespective of environments
  const isLogginEnabled = (): boolean => {
    const debugSession = sessionStorage?.getItem("debug");
    if (debugSession === "true") {
      return true;
    } else {
      return false;
    }
  };

  const print = (level: string, message: string, ...data: any) => {
    if (isDev || isLogginEnabled()) {
      const timestamp = moment().format("YYY-MM-DD @ hh:mm:ss A");
      const messageStr = data.length > 0 ? "%c %s ==>" : "%c %s";

      const styles: any = {
        info: "font-weight: bold; background: blue; color: white; text-transform: uppercase; padding: 1px",
        warn: "font-weight: bold; background: orange; color: white; text-transform: uppercase; padding: 1px",
        error:
          "font-weight: bold; background: red; color: white; text-transform: uppercase; padding: 1px",
        trace:
          "font-weight: bold; background: yellow; color: white; text-transform: uppercase; padding: 1px",
        debug:
          "font-weight: bold; background: grey; color: white; text-transform: uppercase; padding: 1px",
        log: "font-weight: bold; background: green; color: white; text-transform: uppercase; padding: 1px",
        message: "font-weight: bold;",
      };

      switch (level) {
        case "info":
          console.info(
            "[%s] :: %c %s :" + messageStr,
            timestamp,
            styles[level],
            level,
            styles["message"],
            message,
            ...data
          );
          break;
        case "warn":
          console.warn(
            "[%s] :: %c %s :" + messageStr,
            timestamp,
            styles[level],
            level,
            styles["message"],
            message,
            ...data
          );
          break;
        case "error":
          console.error(
            "[%s] :: %c %s :" + messageStr,
            timestamp,
            styles[level],
            level,
            styles["message"],
            message,
            ...data
          );
          break;
        case "trace":
          console.trace(
            "[%s] :: %c %s :" + messageStr,
            timestamp,
            styles[level],
            level,
            styles["message"],
            message,
            ...data
          );
          break;
        case "debug":
          console.debug(
            "[%s] :: %c %s :" + messageStr,
            timestamp,
            styles[level],
            level,
            styles["message"],
            message,
            ...data
          );
          break;
        default:
          console.log(
            "[%s] :: %c %s :" + messageStr,
            timestamp,
            styles[level],
            level,
            styles["message"],
            message,
            ...data
          );
          break;
      }
    }
  };
  return {
    debug: print.bind(null, "debug"),
    info: print.bind(null, "info"),
    warn: print.bind(null, "warn"),
    error: print.bind(null, "error"),
    trace: print.bind(null, "trace"),
    log: print.bind(null, "log"),
  };
})();

export default logger;
