import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../../atoms/customButton";
import CustomTextField from "../../atoms/customTextField";
import styles from "./changePassword.module.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  changePasswordAction,
  changePasswordActionSuccess,
} from "../../../state/actions";
import { PASSWORD_PATTERN } from "../../../constants/commonConstants";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const ChangePassword = () => {
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [errMessage, setErrMessage] = useState({
    oldPass: "",
    newPass: "",
    confirmPass: "",
  });
  const dispatch = useDispatch();

  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.loginResponse?.data?.userDetails?.profile
  );

  const changePasswordResponse = useSelector(
    (state: any) =>
      state?.changePasswordReducer?.changePasswordResponse
  );

  const changePasswordHandler = () => {
    let pattern_msg = t("dnet.invalid_excel_format.text");
    let diff_password = t("dnet.passwords_are_different.text");
    let easy_guess = t("dnet.password_easily_guessed.text");
    let blank_msg = t("dnet.field_required.txt");
    let firstName = userDetails?.firstName?.toLowerCase();
    let lastName = userDetails?.lastName?.toLowerCase();
    let email = userDetails?.email?.toLowerCase();
    let lowerNewPass = newPassword.toLowerCase();
    let errObj: any = {};
    if (oldPassword === "") {
      errObj.oldPass = blank_msg;
    }
    if (newPassword === "") {
      errObj.newPass = blank_msg;
    } else if (!PASSWORD_PATTERN.test(newPassword)) {
      errObj.newPass = pattern_msg;
    }
    if (confirmNewPassword === "") {
      errObj.confirmPass = blank_msg;
    }
    if (confirmNewPassword !== "" && newPassword !== "") {
      if (newPassword !== confirmNewPassword) {
        errObj.newPass = diff_password;
      } else if (
        lowerNewPass.includes(firstName) ||
        lowerNewPass.includes(lastName) ||
        lowerNewPass.includes(email)
      ) {
        errObj.newPass = easy_guess;
      }
    }
    if (Object.keys(errObj).length === 0) {
      setErrMessage({
        oldPass: "",
        newPass: "",
        confirmPass: "",
      });
      dispatch(
        changePasswordAction({
          email: userDetails?.email,
          oldPassword,
          newPassword,
          translation: userDetails?.locale
        })
      );
    } else {
      setErrMessage(errObj);
    }
  };

  useEffect(() => {
    if (changePasswordResponse?.success) {
      let successMessage = t("updatePassword.successful.text");
      setMessage(successMessage);
    }
    else{
        if(changePasswordResponse?.data?.error){
            let errObj: any = {}
            errObj.oldPass = t(changePasswordResponse?.data?.error)
            // let successMessage = t(changePasswordResponse?.data?.error);
            // setMessage(successMessage);
            setErrMessage(errObj);
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePasswordResponse]);

  useEffect(() => {
    dispatch(changePasswordActionSuccess({ success: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Box className={styles.boxStyle}>
        <Typography variant="subtitle2" color="primary">
          {t("updateUser.changeYourCurrentPassword.text")}
        </Typography>
        <div className={styles.changePasswordBox}>
          {message && (
            <Typography sx={{
                color: "#155724",
                backgroundColor: "#d4edda",
                padding: "8px 14px",
                borderRadius: "5px",
                marginBottom: "24px",
            }}>
              {message}
            </Typography>
          )}
          <CustomTextField
            additionalSxProperties={{
              marginBottom: "24px",
            }}
            id="confirmPassword"
            label={`${t("updateUser.currentPassword.text")} *`}
            value={oldPassword}
            changeHandler={(e: any) => setOldPassword(e.target.value)}
            type="password"
            helperText={errMessage["oldPass"]}
            error={errMessage["oldPass"]?.length > 0}
          />
          <div className={styles.newPassBox}>
            <div style={{ display: "flex" }}>
              <CustomTextField
                id="password"
                label={`${t("dnet.new_password.text")} *`}
                value={newPassword}
                changeHandler={(e: any) => setNewPassword(e.target.value)}
                type="password"
                helperText={errMessage["newPass"]}
                error={errMessage["newPass"]?.length > 0}
              />
              <Tooltip
                title={t("dnet.password_info.text")}
                arrow
                placement="right"
                enterTouchDelay={0}
              >
                <IconButton>
                  <InfoOutlinedIcon color="action" />
                </IconButton>
              </Tooltip>
            </div>
            <CustomTextField
              id="confirmPassword"
              label={`${t("dnet.new_password_again.text")} *`}
              value={confirmNewPassword}
              changeHandler={(e: any) => setConfirmNewPassword(e.target.value)}
              type="password"
              helperText={errMessage["confirmPass"]}
              error={errMessage["confirmPass"]?.length > 0}
            />
          </div>
          <CustomButton
            id="submitButton"
            label={t("dnet.change_password.text")}
            onClick={changePasswordHandler}
            color="primary"
            margin="0px"
          />
        </div>
      </Box>
    </div>
  );
};
