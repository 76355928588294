import React, { useEffect, useState } from "react";
import styles from "./createReport.module.scss";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CustomTextField from "../../../atoms/customTextField";
import CustomDropDown from "../../../atoms/customDropDown";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomButton from "../../../atoms/customButton";
import {
  createNewReportSuccess,
} from "../../../../state/actions";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../atoms/customModal";
import { useNavigate } from "react-router-dom";

export const CreateReport = ({
  emailReport,
  formEditable,
  reportCreationData,
  handleReportCreationData,
  reportCreationSaveHandler,
  StartingMinDate,
}: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orderDatePeriod = [
    {
      value: "p",
      label: t("dnet.please_select.text"),
      disabled: true
    },
    {
      value: "LW",
      label: t("dnet.last_week.text"),
    },
    {
      value: "L2W",
      label: t("dnet.last2_Weeks.text"),
    },
    {
      value: "L3W",
      label: t("dnet.last3_weeks.text"),
    },
    {
      value: "LM",
      label: t("dnet.last_month.text"),
    },
    {
      value: "L2M",
      label: t("dnet.last2_months.text"),
    },
    {
      value: "L3M",
      label: t("dnet.last3_months.text"),
    },
    {
      value: "L6M",
      label: t("dnet.last6_months.text"),
    },
  ];
  const newPeriod = [
    {
      value: "NW",
      label: t("dnet.next_week.text"),
    },
    {
      value: "N2W",
      label: t("dnet.next2_weeks.text"),
    },
    {
      value: "N3W",
      label: t("dnet.next3_weeks.text"),
    },
    {
      value: "NM",
      label: t("dnet.next_month.text"),
    },
    {
      value: "N2M",
      label: t("dnet.next2_months.text"),
    },
    {
      value: "N3M",
      label: t("dnet.next3_months.text"),
    },
    {
      value: "N6M",
      label: t("dnet.next6_months.text"),
    },
  ];
  const rddPeriod = [...orderDatePeriod, ...newPeriod];
  const emailFrequency = [
    {
      value: "p",
      label: t("dnet.please_select.text"),
      disabled: true
    },
    {
      value: "W",
      label: t("dnet.weekly.text"),
    },
    {
      value: "M",
      label: t("dnet.monthly.text"),
    },
  ];

  const contextDetails = useSelector(
    (state: any) => state?.commonReducer?.contextDetails
  );

  const newReportSuccessData = useSelector(
    (state: any) => state?.reportingReducer?.newReportSuccessData?.success
  );
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setOpenModal(false);
    dispatch(createNewReportSuccess({}));
    navigate("/Reporting");
  };
  useEffect(() => {
    dispatch(createNewReportSuccess({}));
  }, []);
  useEffect(() => {
    newReportSuccessData && setOpenModal(true);
  }, [newReportSuccessData]);

  const isWeekend = (date: any) => {
    const day = new Date(date).getDay();
    return day === 0 || day === 6; //check for Saturday and Sunday
  };
  const filterWeekends = (date: any) => {
    return isWeekend(date);
  };
  return (
    <div>
      <Typography variant="body2" mb={2}>
        {t("dnet.report_definition_info.text")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item lg={3} md={4} xs={12}>
          <CustomTextField
            label={t("dnet.report_name.text")}
            changeHandler={(e: any) => {
              handleReportCreationData("name", e.target.value);
            }}
            value={reportCreationData?.name}
            disabled={formEditable}
          />
        </Grid>
        <Grid item lg={3} md={4} xs={12}>
          <CustomTextField
            label={t("dnet.email_address.text")}
            changeHandler={(e: any) => {
              handleReportCreationData("email", e.target.value);
            }}
            value={reportCreationData?.email}
            disabled={true}
          />
        </Grid>
        <Grid item lg={3} md={4} xs={12}>
          <div className={styles.dateBox}>
            <CustomDropDown
              label={t("dnet.order_date_period.text")}
              id="orderDatePeriod"
              value={reportCreationData?.orderDatePeriod}
              handleChange={(e: any) => {
                handleReportCreationData("orderDatePeriod", e.target.value);
              }}
              menuOptions={orderDatePeriod}
              module="debitNoteCreation"
              disabled={formEditable}
            />
            <Tooltip
              title={t("dnet.orderdate_period_info.text")}
              arrow
              placement="right"
              enterTouchDelay={0}
            >
              <IconButton className={styles.infoIcon}>
                <InfoOutlinedIcon color="action" />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
        <Grid item lg={3} md={4} xs={12}>
          <div className={styles.dateBox}>
            <CustomDropDown
              label={t("dnet.rdd_period.text")}
              id="requestedDeliveryDatePeriod"
              value={reportCreationData?.requestedDeliveryDatePeriod}
              handleChange={(e: any) => {
                handleReportCreationData("requestedDeliveryDatePeriod", e.target.value);
              }}
              menuOptions={rddPeriod}
              module="debitNoteCreation"
              disabled={formEditable}
            />
            <Tooltip
              title={t("dnet.rdd_info.text")}
              arrow
              placement="right"
              enterTouchDelay={0}
            >
              <IconButton className={styles.infoIcon}>
                <InfoOutlinedIcon color="action" />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
        <Grid item lg={3} md={4} xs={12}>
          <div className={styles.dateBox}>
            <CustomDropDown
              label={t("dnet.email_frequency.text")}
              id="frequency"
              value={reportCreationData?.frequency}
              handleChange={(e: any) => {
                handleReportCreationData("frequency", e.target.value);
              }}
              menuOptions={emailFrequency}
              module="debitNoteCreation"
              disabled={formEditable}
            />
            <Tooltip
              title={t("dnet.email_frequency_info.text")}
              arrow
              placement="right"
              enterTouchDelay={0}
            >
              <IconButton className={styles.infoIcon}>
                <InfoOutlinedIcon color="action" />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
        <Grid item lg={3} md={4} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disablePast
              label={t("dnet.start_with.text")}
              openTo="day"
              views={["year", "month", "day"]}
              value={reportCreationData?.startingDate}
              onChange={(newValue: any) => {
                handleReportCreationData("startingDate", newValue);
              }}
              renderInput={(params) => (
                <TextField 
                  sx={{ width: "210px" }}
                  {...params}
                  inputProps={{...params.inputProps, readOnly: true}}
                  size="small"
                />
              )}
              inputFormat="DD-MM-YYYY"
              minDate={StartingMinDate}
              shouldDisableDate={filterWeekends}
              disabled={formEditable}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      {contextDetails?.isSalesRepresentative && (
        <Grid container className={styles.sendEmailContainer}>
          <Grid item xs={12} className={styles.sendEmailtoAllContacts}>
            <Checkbox
              id="sendEmailtoSalesRepresentativeContacts"
              checked={reportCreationData?.sendEmailtoSalesRepresentativeContacts}
              onChange={(e: any) => {
                handleReportCreationData(
                  "sendEmailtoSalesRepresentativeContacts",
                  e.target.checked
                );
              }}
              size="small"
              sx={{ padding: 0 }}
              disabled={formEditable}
            />
            <Typography variant="body2" sx={{ marginLeft: "10px" }}>
              {t("dnet.send_order_details_mail_to_all_contacts.text")}
            </Typography>
          </Grid>
        </Grid>
      )}
      {emailReport && (
        <Grid container>
          <Grid item xs={12} className={styles.buttonBox}>
            <CustomButton
              id="saveButton"
              label={t("dnet.save.text")}
              onClick={reportCreationSaveHandler}
              color="primary"
              margin="0 30px"
            />
          </Grid>
        </Grid>
      )}
      {newReportSuccessData && (
        <CustomModal
          id="confirmationModal"
          open={openModal}
          closeModal={closeModal}
          heading={"1"}
          createReport={true}
          content={t("dnet.redirection_info.text")}
        />
      )}
    </div>
  );
};

CreateReport.defaultProps = {
  emailReport: true,
  formEditable: false,
  StartingMinDate: null
};
