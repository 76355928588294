import React, { useEffect } from "react";
import styles from "./SERPGeneralInformation.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getArticlesFromCMS } from "../../state/actions";
import { ArticleDetailsPage } from "../cms/articleDetailsPage";
var _ = require("lodash");

export const SERPGeneralInformation = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const articles = useSelector(
    (state: any) => state?.userProfileOptionsReducer?.cmsArticles
  );
  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );
  useEffect(() => {
    dispatch(
      getArticlesFromCMS({
        module: "SERP",
        params: {
          [`filter_locale[${i18n.language}]`]: i18n.language,
          [`filter_bg[${currentDealer?.bgName}]`]: currentDealer?.bgName,
          [`filter_ecot[4_GB_CONVE]`]: "4_GB_CONVE",
          [`acf_like[main_content_category]`]: "SERPP Overview",
          [`acf_like[content_category]`]: "SERPP Overview>Generalinformation",
          max_result: "1",
        }, //?filter_locale[en_GB]=en_GB&filter_bg[GB200201]=GB200201&filter_ecot[4_GB_CONVE]=4_GB_CONVE&acf_like[main_content_category]=SERPP Overview&acf_like[content_category]=SERPP Overview>Generalinformation&max_result=1
      })
    );
  }, []);
  return (
    <div>
      <ArticleDetailsPage selectedArticle={articles?.[0]} />
    </div>
  );
};
