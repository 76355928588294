import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./imageDetails.module.scss";
import { Carousel } from "react-responsive-carousel";

import CustomButton from "../../../../atoms/customButton";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getProductImages} from "../../../../../state/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
var _ = require("lodash");

export const ImageDetails = (props: any) => {
  const { modalDetails } = props;
  const { t } = useTranslation();
  const dispatch= useDispatch();
  const [selectedImageIndex, setSelectedImageIndex] = useState<any>(0);
  const openFullSizeImage = () => {
    window.open(
      modalDetails?.largeImages[selectedImageIndex]?.includes(
        "sp.sony-europe.com"
      )
        ? `${modalDetails?.largeImages[selectedImageIndex]}`
        : `${modalDetails?.largeImages[selectedImageIndex]}&wid=1000`
    );
  };
  const [isDownloadTrigged,setIsDownloadTriggered]=useState<any>(false);

  const productImages = useSelector(
    (state: any) => state?.productReducer?.productImages
  );
  useEffect(()=>{

    if(!_.isEmpty(productImages) && isDownloadTrigged)
    {
      const binaryData= atob(productImages?.data);
      const arrayBuffer = new ArrayBuffer(binaryData?.length);
      const uint8Array= new Uint8Array(arrayBuffer);
      for(let i=0;i<binaryData.length;i++){
        uint8Array[i]=binaryData.charCodeAt(i);
      }

      const blob= new Blob([uint8Array],{type:'application/zip'});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${modalDetails?.productDisplayName}.zip`);
      document.body.appendChild(link);
      link.click();
      setIsDownloadTriggered(false);
    }     

  },[productImages]);
  const downloadAllImages = () => {

    const productImageURL :any = [];

    modalDetails?.largeImages.map( (url: any, index: any) => {

      const alteredURL = url?.includes("sp.sony-europe.com") ? url : `${url}&wid=1000`
      const parts= alteredURL.split('/');
      const relevantPart=parts.slice(3).join('/');
      const flag = url?.includes("sp.sony-europe.com") ? 2 : (url?.includes("sony.scene7.com") ? 3 : 1);

      productImageURL.push({imageURL: relevantPart,
      flag: flag} );
    });
      dispatch(getProductImages(
       { payload: productImageURL
       }
      ))
      setIsDownloadTriggered(true);
};
  return (
    <>
      <Box className={styles.imageContainer}>
        <Box className={styles.infoContainer}>
          <Typography
            component="div"
            variant="body2"
            color="text.secondary"
            dangerouslySetInnerHTML={{
              __html: modalDetails?.isVisibleBaseLink
                ? t("dataDownload.baseInformation.text")
                : t("dataDownload.baseInformationDisabled.text"),
            }}
          ></Typography>
        </Box>
        <Box mt={2} mb={3}>
          <CustomButton
            label={t("dnet.download_image.text")}
            color="primary"
            onClick={() => openFullSizeImage()}
          />
          <CustomButton
            label={t("dnet.download_all_images.text")}
            color="primary"
            onClick={() => downloadAllImages()}
          />
          <Carousel
            className={styles.slider}
            onChange={(index: any) => setSelectedImageIndex(index)}
            autoPlay={false}
            showArrows
            dynamicHeight
            thumbWidth={40}
            infiniteLoop
          >
            {modalDetails?.largeImages?.map((item: any) => (
              <img src={item} className={styles.imageStyle} />
            ))}
          </Carousel>
        </Box>
      </Box>
    </>
  );
};
