import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styles from "./reply-enquiry-table.module.scss";
import moment from "moment";

export const ReplyEnquiryTable = () => {
  const { t } = useTranslation();
  const replyEnquiryTableData = useSelector(
    (state: any) => state?.gazelleReducer?.enquiryDetailsData
  );

  return (
    <>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("gzl.inv_credit.text")}</TableCell>
              <TableCell>{ t("gzl.date.text")}</TableCell>
              <TableCell>{t("gzl.amount.txt")}</TableCell>
              {replyEnquiryTableData?.amount !==
                replyEnquiryTableData?.openAmount && (
                <TableCell>{t("gzl.outstd_blnc_less_disc.text")}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{replyEnquiryTableData?.name}</TableCell>
              <TableCell>{
                  moment(replyEnquiryTableData?.dueDate).format(
                    "DD.MM.YYYY"
                  )
                }
              </TableCell>
              <TableCell
                className={
                  replyEnquiryTableData?.amount < 0 ? styles.errorText : ""
                }
              >
                {replyEnquiryTableData?.amount}
              </TableCell>
              {replyEnquiryTableData?.amount !==
                replyEnquiryTableData?.openAmount && (
                <TableCell
                  className={
                    replyEnquiryTableData?.openAmount < 0
                      ? styles.errorText
                      : ""
                  }
                >
                  {replyEnquiryTableData?.openAmount}
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
