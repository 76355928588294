import { Box, Typography } from '@mui/material';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { getPaymentHistory } from "../../../state/actions";
import CustomButton from '../../atoms/customButton';
import CustomDropDown from '../../atoms/customDropDown';
import CustomTextField from '../../atoms/customTextField';
import logger from '../../helper/logger';
import styles from './payments.module.scss';
import PaymentTable from './paymentTable';
var _ = require("lodash");

export const Payments = () => {
    const { t } = useTranslation();
    const [value, setValue] = useState<string | number>("");
    const [refresh, setRefresh] = useState<Boolean>(false)
    const dispatch = useDispatch();
    const paymentHistoryTableData = useSelector(
        (state: any) => state?.gazelleReducer?.paymentHistoryData
    );
    const paymentSelectedMonth = useSelector(
        (state: any) => state?.gazelleReducer?.paymentSelectedMonth
    );
    const lastRetrievalDate = paymentHistoryTableData?.lastRetrievalDate;
    const userDetails = useSelector(
        (state: any) => state?.commonReducer?.userDetails
      );
    const [menuItems, setMenuItems] = useState<any>([])
    const [startDate, setStartDate] = useState<any>([])
    const [endDate, setEndDate] = useState<any>([])
    const [csvData, setCsvData] = useState([]);
    const [readyToDownload, setReadyToDownload] = useState(false);
    const downloadTableLabel = t("gzl.csv_payments.text");

    const previosMonthNumberArray = [
        t("gzl.last_two_month_key.text"),
        t("gzl.last_three_month_key.text")
    ]
  
    const downloadCSV = () => {
      // CSV format creation for download
      logger.info("Download Payments CSV");
      const csvData: any = [
        [
          t("gzl.payment_date.text"),
          t("gzl.actual_paid_value.text"),
          t("gzl.currency.text"),
        ],
        ...paymentHistoryTableData?.payments?.map((item: any) => [
          item?.valueDate,
          item?.amount,
          item?.currency,
        ]),
      ];
      setCsvData(csvData);
      setReadyToDownload(true);
    };

  let csvLink: any = React.createRef();
  useEffect(() => {
    if (!_.isEmpty(csvData) && readyToDownload) {
      csvLink.current.link.click();
      setReadyToDownload(false);
    }
  }, [readyToDownload]);

    const getMonths=()=>{
        // Functionality to create a list of months 
        // and respective start & end date from past 1 year
        let startArr = []
        let endArr = []

        let previousMonthsArr=[]
        for(let i=0;i<=11;i++)
        {
            let monthName = moment(new Date()).subtract(i,"month").startOf("month").format('MM YYYY')
            let yearName = monthName.split(" ")
            let current:any = new Date(parseInt(yearName[1]), parseInt(yearName[0]),0)
            previousMonthsArr.push(moment(current).format("MMMM YYYY"))
            startArr.push(moment("01 " + previousMonthsArr[i]).format("YYYYMMDD"))
            i===0 ? endArr.push(moment(new Date()).format("YYYYMMDD")) : endArr.push(moment(current).format("YYYYMMDD"))
        }
        previousMonthsArr=[...previousMonthsArr, ...previosMonthNumberArray]
        setStartDate(startArr)
        setEndDate(endArr)
        setMenuItems(previousMonthsArr)
        setValue(paymentSelectedMonth ? paymentSelectedMonth : previousMonthsArr[0])
    }

    const handleChange = (event: any) => {
        // on change of month from the dropdown
        logger.info("Selected value ",event.target.value);
        setValue(event.target.value);
    };

    const onRefresh = () => {
        // On click of refresh fetching data again
        logger.info("Refreshing Payments data");
        setRefresh(!refresh)
        getAPIData()
    }

    const saveAccountDetails = useSelector(
        (state: any) => state?.commonReducer?.saveAccountDetails
    )

    const getAPIData = () => {
        // hitting API on the basis of selected month
        let currentDate = ""
        let lastDate = ""
        if(value === previosMonthNumberArray[0] || value === previosMonthNumberArray[1] ){
            let date = value.split(" ")
            let n = parseInt(date[1])
            lastDate = endDate[0]
            currentDate = startDate[n-1]
        }
        else{
            currentDate = startDate[menuItems.indexOf(value)]
            lastDate = endDate[menuItems.indexOf(value)]
        }
        dispatch(
          getPaymentHistory({
            language: userDetails?.language,
            username: userDetails?.userName,
            organizationKey: "GB00",
            partnerKey: saveAccountDetails?.payerToAcc?.number,
            startDate: currentDate,
            endDate: lastDate,
            itemType: 1,
            selectedMonth: value,
          })
        );
    };

    useEffect(() => {
        if(value!=="")
            getAPIData()
        else
            getMonths()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    return (
        <>
            <Typography 
                variant="subtitle1" 
                className={styles.header}
                pb={3}
                color="primary"
            >
                {t("gzl.payment_history.text")}
            </Typography>
            <Box>
                <Box className={styles.paymentBoxContainer}>
                    <CustomTextField
                        label={t("gzl.last_retrieval_date.text")}
                        value={moment(lastRetrievalDate).utc().format(
                            "DD/MM/YYYY HH:mm"
                        ) + " UTC"}
                        disabled = {true}
                    />
                    <CustomDropDown 
                        id="previous12-months-dropdown" 
                        value={value} 
                        handleChange={handleChange} 
                        menuItems={menuItems} 
                        label={t("gzl.period.text")} 
                    />
                </Box>
                <Box className={styles.tableContainer}>
                    <div className={styles.tableHeader}>
                        <Typography id="payment-table-headline" variant="body2">
                            {t("gzl.payment_history_headline.text")}
                        </Typography>
                        <div>
                            <CustomButton
                                id="Refresh"
                                label={t("gzl.refresh.text")}
                                onClick={onRefresh}
                            />
                            <CSVLink
                                data={csvData}
                                separator={`;`}
                                enclosingCharacter={``}
                                filename={downloadTableLabel}
                                className={styles.hidden}
                                ref={csvLink}
                            ></CSVLink>
                            <CustomButton
                                id="downloadCSV"
                                label={t("gzl.download_csv.text")}
                                onClick={downloadCSV}
                                color="primary"
                            />
                        </div>
                    </div>
                    <PaymentTable />
                </Box>
            </Box>
        </>
    )
}