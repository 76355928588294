import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES_LABEL } from './constants/routesLabels';

export default function NotFoundPage () {
    const navigate = useNavigate();

    useEffect(()=>{
        // navigate back to login
        navigate(`/${ROUTES_LABEL.LOGIN}`)
    }, [])
    
    return null;
}