import {
  Box,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ACCOUNT_NUM_10_DIGIT_PATTERN,
  BUSINESS_GROUP_PATTERN,
} from "../../../constants/commonConstants";
import { SYSTEM_ADMIN } from "../../../constants/labels";
import {
  checkContactEmail,
  checkContactEmailSuccess,
  checkSalesOrgAccounts,
  checkSalesOrgAccountsSuccess,
  checkSalesRep,
  checkSalesRepBySalesOrg,
  checkSalesRepBySalesOrgSuccess,
  checkSalesRepDealer,
  checkSalesRepDealerSuccess,
  checkSalesRepSuccess,
} from "../../../state/actions";
import CustomButton from "../../atoms/customButton";
import CustomTextField from "../../atoms/customTextField";
import styles from "./salesRepVisibility.module.scss";

export const SalesRepVisibility = () => {
  const dispatch = useDispatch();

  const [userName, setUserName] = useState<any>("");
  const [salesRepMsg, setSalesRepMsg] = useState<any>("");

  const [sapAccount, setSapAccount] = useState<any>("");
  const [businessGroup, setBusinessGroup] = useState<any>("");
  const [contactEmailMsg, setContactEmailMsg] = useState<any>("");

  const [salesOrganization, setSalesOrganization] = useState<any>("");
  const [salesOrgMsg, setsalesOrgMsg] = useState<any>("");

  const [salesRepEmail, setSalesRepEmail] = useState<any>("");
  const [salesOrg, setsalesOrg] = useState<any>("");
  const [salesRepDealerMsg, setSalesRepDealerMsg] = useState<any>("");

  const [salesOrgAcc, setSalesOrgAcc] = useState<any>("");
  const [salesOrgAccMsg, setsalesOrgAccMsg] = useState<any>("");

  const checkSalesRepData = useSelector(
    (state: any) => state?.systemAdminReducer?.checkSalesRepData
  );

  const clearState =(attribute:any) => { 
    dispatch(checkSalesRepSuccess({}));
    dispatch(checkContactEmailSuccess({}));
    dispatch(checkSalesRepBySalesOrgSuccess({}));
    dispatch(checkSalesRepDealerSuccess({}));
    dispatch(checkSalesOrgAccountsSuccess({}));
    attribute?.forEach((exceptState:any)=>{
      setUserName(exceptState === "userName" ? userName : "");
      setSalesRepMsg(exceptState === "salesRepMsg" ? salesRepMsg : "");
      setSapAccount(exceptState === "sapAccount" ? sapAccount : "");
      setBusinessGroup(exceptState === "sapAccount" ? businessGroup : "");
      setContactEmailMsg(exceptState === "contactEmailMsg" ? contactEmailMsg : "");
      setSalesOrganization(exceptState === "salesOrganization" ? salesOrganization : "");
      setsalesOrgMsg(exceptState === "salesOrgMsg" ? salesOrgMsg : "");
      setSalesRepEmail(exceptState === "salesRepEmail" ? salesRepEmail : "");
      setsalesOrg(exceptState === "salesRepEmail" ? salesOrg : "");
      setSalesRepDealerMsg(exceptState === "salesRepDealerMsg" ? salesRepDealerMsg : "");
      setSalesOrgAcc(exceptState === "salesOrgAcc" ? salesOrgAcc : "");
      setsalesOrgAccMsg(exceptState === "salesOrgAccMsg" ? salesOrgAccMsg : "");
    });
  }
  

  const checkContactEmailData = useSelector(
    (state: any) => state?.systemAdminReducer?.checkContactEmailData
  );

  const checkSalesRepBySalesOrgData = useSelector(
    (state: any) => state?.systemAdminReducer?.checkSalesRepBySalesOrgData
  );

  const checkSalesRepDealerData = useSelector(
    (state: any) => state?.systemAdminReducer?.checkSalesRepDealerData
  );

  const checkSalesOrgAccountsData = useSelector(
    (state: any) => state?.systemAdminReducer?.checkSalesOrgAccountsData
  );

  useEffect(() => {
    clearState([""]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      clearState([""]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const checkSalesRepHandler = () => {
    clearState(["userName"]);
    if (!userName) {
      setSalesRepMsg(SYSTEM_ADMIN.ENTER_VALID_SONY1_USER);
    } else {
      dispatch(checkSalesRep({ usernameOrEmail: userName }));
    }
  };

  const checkContactEmailHandler = () => {
    clearState(["sapAccount"]);
    if (!sapAccount || !businessGroup) {
      setContactEmailMsg(SYSTEM_ADMIN.ENTER_SAP_ACC_AND_BG);
    } else if (!ACCOUNT_NUM_10_DIGIT_PATTERN.test(sapAccount)) {
      setContactEmailMsg(SYSTEM_ADMIN.ENTER_VALID_SAP_ACC_NUM);
    } else if (!BUSINESS_GROUP_PATTERN.test(businessGroup)) {
      setContactEmailMsg(SYSTEM_ADMIN.ENTER_VALID_BG);
    } else {
      dispatch(
        checkContactEmail({ accountReference: sapAccount, businessGroup })
      );
    }
  };

  const checkSalesRepBySalesOrgHandler = () => {
    clearState(["salesOrganization"]);
    if (
      !salesOrganization ||
      (salesOrganization && salesOrganization?.length > 50)
    ) {
      setsalesOrgMsg(SYSTEM_ADMIN.ENTER_VALID_SALES_ORG);
    } else {
      dispatch(
        checkSalesRepBySalesOrg({ salesOrganization, salesRepEmail: "all" })
      );
    }
  };

  const checkSalesRepDealerHandler = () => {
    clearState(["salesRepEmail"]);
    if (
      !salesRepEmail ||
      (salesRepEmail && salesRepEmail?.length > 50) ||
      !salesOrg ||
      (salesOrg && salesOrg?.length > 50)
    ) {
      setSalesRepDealerMsg(SYSTEM_ADMIN.ENTER_VALID_SALES_REP_EMAIL);
    } else {
      dispatch(
        checkSalesRepDealer({ salesRepEmail, salesOrganization: salesOrg })
      );
    }
  };

  const checkSalesOrgAccountsHandler = () => {
    clearState(["salesOrgAcc"]);
    if (!salesOrgAcc || (salesOrgAcc && salesOrgAcc?.length > 50)) {
      setsalesOrgAccMsg(SYSTEM_ADMIN.ENTER_VALID_SALES_ORG);
    } else {
      dispatch(checkSalesOrgAccounts({ salesOrganization: salesOrgAcc }));
    }
  };

  return (
    <>
      <Box mt={2} mb={4}>
        <Typography variant="subtitle2" color="primary" mb={1}>
          {SYSTEM_ADMIN.CHECK_SALES_REP_USER}
        </Typography>
        <Grid
          container
          spacing={2}
          justifyItems={"center"}
          alignItems={"center"}
        >
          <Grid item lg={8} md={10} sm={12} xs={12}>
            <CustomTextField
              additionalSxProperties={{ width: "100%" }}
              label={userName ? "" : SYSTEM_ADMIN.USER_MAIL_PLACEHOLDER}
              changeHandler={(e: any) => setUserName(e?.target?.value)}
              value={userName}
            />
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={1}>
            <CustomButton
              label={SYSTEM_ADMIN.CHECK}
              color="primary"
              onClick={() => checkSalesRepHandler()}
              margin={0}
            />
          </Grid>
        </Grid>
        {(checkSalesRepData?.errorMessageSalesRep || salesRepMsg) && (
          <Box mt={2} className={styles.warningStyle}>
            <Typography component="div" variant="caption">
              {salesRepMsg
                ? salesRepMsg
                : checkSalesRepData?.errorMessageSalesRep}
            </Typography>
          </Box>
        )}
        {checkSalesRepData?.salesRep && (
          <Box mt={2} className={styles.infoStyle}>
            <Typography component="div" variant="caption">
              {checkSalesRepData?.salesRep}
            </Typography>
          </Box>
        )}
      </Box>
      <Divider />
      <Box mt={3} mb={4}>
        <Typography variant="subtitle2" color="primary" mb={1}>
          {SYSTEM_ADMIN.RECEIVE_CONTACT_EMAIL}
        </Typography>
        <Grid
          container
          spacing={2}
          justifyItems={"center"}
          alignItems={"center"}
        >
          <Grid item lg={4} md={5} sm={12} xs={12}>
            <CustomTextField
              additionalSxProperties={{ width: "100%" }}
              label={sapAccount ? "" : SYSTEM_ADMIN.ENTER_SAP_ACC_ID}
              changeHandler={(e: any) => setSapAccount(e?.target?.value)}
              value={sapAccount}
            />
          </Grid>
          <Grid item lg={4} md={5} sm={12} xs={12}>
            <CustomTextField
              additionalSxProperties={{ width: "100%" }}
              label={businessGroup ? "" : SYSTEM_ADMIN.ENTER_BUSINESS_GROUP}
              changeHandler={(e: any) => setBusinessGroup(e?.target?.value)}
              value={businessGroup}
            />
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={1}>
            <CustomButton
              label={SYSTEM_ADMIN.CHECK}
              color="primary"
              onClick={() => checkContactEmailHandler()}
              margin={0}
            />
          </Grid>
        </Grid>
        {(checkContactEmailData?.errorMessageContactEmails ||
          contactEmailMsg) && (
          <Box mt={2} className={styles.warningStyle}>
            <Typography component="div" variant="caption">
              {contactEmailMsg
                ? contactEmailMsg
                : checkContactEmailData?.errorMessageContactEmails}
            </Typography>
          </Box>
        )}
        {/* {checkContactEmailData?.infoMessageContactEmails && (
          <Box mt={2} className={styles.infoStyle}>
            <Typography component="div" variant="caption">
              {checkContactEmailData?.infoMessageContactEmails}
            </Typography>
          </Box>
        )} */}
        {checkContactEmailData?.contactEmails && (
          <Box mt={2}>
            <Typography variant="body2" pb={1}>
              {checkContactEmailData?.queryContactEmailsInfo}
            </Typography>
            <Typography
              component="div"
              variant="caption"
              color="text.secondary"
            >
              {checkContactEmailData?.contactEmails}
            </Typography>
          </Box>
        )}
      </Box>
      <Divider />
      <Box mt={3} mb={4}>
        <Typography variant="subtitle2" color="primary" mb={1}>
          {SYSTEM_ADMIN.RECEIVE_SALES_REP_BY_SALES_ORG}
        </Typography>
        <Grid
          container
          spacing={2}
          justifyItems={"center"}
          alignItems={"center"}
        >
          <Grid item lg={8} md={10} sm={12} xs={12}>
            <CustomTextField
              additionalSxProperties={{ width: "100%" }}
              label={salesOrganization ? "" : SYSTEM_ADMIN.ENTER_SALES_ORG}
              changeHandler={(e: any) => setSalesOrganization(e?.target?.value)}
              value={salesOrganization}
            />
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={1}>
            <CustomButton
              label={SYSTEM_ADMIN.CHECK}
              color="primary"
              onClick={() => checkSalesRepBySalesOrgHandler()}
              margin={0}
            />
          </Grid>
        </Grid>
        {(checkSalesRepBySalesOrgData?.errorMessageSalesRepsBySalesOrg ||
          salesOrgMsg) && (
          <Box mt={2} className={styles.warningStyle}>
            <Typography component="div" variant="caption">
              {salesOrgMsg
                ? salesOrgMsg
                : checkSalesRepBySalesOrgData?.errorMessageSalesRepsBySalesOrg}
            </Typography>
          </Box>
        )}
        {checkSalesRepBySalesOrgData?.infoMessageSalesRepsBySalesOrg && (
          <Box mt={2} className={styles.infoStyle}>
            <Typography component="div" variant="caption">
              {checkSalesRepBySalesOrgData?.infoMessageSalesRepsBySalesOrg}
            </Typography>
          </Box>
        )}
        {checkSalesRepBySalesOrgData?.salesRepresentativeList?.length > 0 && (
          <Grid container mt={3}>
            <Grid item lg={9} md={11} sm={12} xs={12}>
              <Box className={styles.mainTableContainer}>
                <div className={styles.tableHeader}>
                  <Typography variant="body2">
                    {salesOrganization}&nbsp;-&nbsp;
                    {SYSTEM_ADMIN.SALES_REP_LIST}
                  </Typography>
                </div>
                <TableContainer
                  component={Paper}
                  className={styles.tableContainer}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{SYSTEM_ADMIN.USERNAME}</TableCell>
                        <TableCell>{SYSTEM_ADMIN.PERSON_ID}</TableCell>
                        <TableCell>{SYSTEM_ADMIN.EMAIL}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {checkSalesRepBySalesOrgData?.salesRepresentativeList?.map(
                        (salesRep: any, index: number) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>{salesRep?.username}</TableCell>
                            <TableCell>{salesRep?.personID}</TableCell>
                            <TableCell>{salesRep?.email}</TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
      <Divider />
      <Box mt={3} mb={4}>
        <Typography variant="subtitle2" color="primary" mb={1}>
          {SYSTEM_ADMIN.RECEIVE_ACC_OF_SALES_REP}
        </Typography>
        <Grid
          container
          spacing={2}
          justifyItems={"center"}
          alignItems={"center"}
        >
          <Grid item lg={4} md={5} sm={12} xs={12}>
            <CustomTextField
              additionalSxProperties={{ width: "100%" }}
              label={salesRepEmail ? "" : SYSTEM_ADMIN.ENTER_SALES_REP_EMAIL}
              changeHandler={(e: any) => setSalesRepEmail(e?.target?.value)}
              value={salesRepEmail}
            />
          </Grid>
          <Grid item lg={4} md={5} sm={12} xs={12}>
            <CustomTextField
              additionalSxProperties={{ width: "100%" }}
              label={salesOrg ? "" : SYSTEM_ADMIN.ENTER_SALES_ORGANIZATION}
              changeHandler={(e: any) => setsalesOrg(e?.target?.value)}
              value={salesOrg}
            />
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={1}>
            <CustomButton
              label={SYSTEM_ADMIN.CHECK}
              color="primary"
              onClick={() => checkSalesRepDealerHandler()}
              margin={0}
            />
          </Grid>
        </Grid>
        {(checkSalesRepDealerData?.errorMessageSalesRepsDealers ||
          salesRepDealerMsg) && (
          <Box mt={2} className={styles.warningStyle}>
            <Typography component="div" variant="caption">
              {salesRepDealerMsg
                ? salesRepDealerMsg
                : checkSalesRepDealerData?.errorMessageSalesRepsDealers}
            </Typography>
          </Box>
        )}
        {checkSalesRepDealerData?.infoMessageSalesRepsDealers && (
          <Box mt={2} className={styles.infoStyle}>
            <Typography component="div" variant="caption">
              {checkSalesRepDealerData?.infoMessageSalesRepsDealers}
            </Typography>
          </Box>
        )}
        {checkSalesRepDealerData?.salesRepresentativesDealersList?.length >
          0 && (
          <Grid container>
            <Grid item lg={9} md={11} sm={12} xs={12}>
              <Box className={styles.mainTableContainer}>
                <div className={styles.tableHeader}>
                  <Typography variant="body2">
                    {SYSTEM_ADMIN.SALES_REP}
                    {salesRepEmail}&nbsp;-&nbsp;{salesOrg + " "}
                    {SYSTEM_ADMIN.DEALERS_LIST}
                  </Typography>
                </div>
                <TableContainer
                  component={Paper}
                  className={styles.tableContainer}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{SYSTEM_ADMIN.ACCOUNT_REF}</TableCell>
                        <TableCell>{SYSTEM_ADMIN.BUSINESS_GROUP}</TableCell>
                        <TableCell>{SYSTEM_ADMIN.CONTACT_EMAIL_LIST}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {checkSalesRepDealerData?.salesRepresentativesDealersList?.map(
                        (salesRepDealer: any, index: number) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>
                              {salesRepDealer?.accountReference}
                            </TableCell>
                            <TableCell>
                              {salesRepDealer?.businessGroup}
                            </TableCell>
                            <TableCell>
                              {salesRepDealer?.contactEmailList?.join(", ")}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
      <Divider />
      <Box mt={3} mb={4}>
        <Typography variant="subtitle2" color="primary" mb={1}>
          {SYSTEM_ADMIN.RECEIVE_CONTACT_ACC_BY_SALES_ORG}
        </Typography>
        <Grid
          container
          spacing={2}
          justifyItems={"center"}
          alignItems={"center"}
        >
          <Grid item lg={8} md={10} sm={12} xs={12}>
            <CustomTextField
              additionalSxProperties={{ width: "100%" }}
              label={salesOrgAcc ? "" : SYSTEM_ADMIN.ENTER_SALES_ORG}
              changeHandler={(e: any) => setSalesOrgAcc(e?.target?.value)}
              value={salesOrgAcc}
            />
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={1}>
            <CustomButton
              label={SYSTEM_ADMIN.CHECK}
              color="primary"
              onClick={() => checkSalesOrgAccountsHandler()}
              margin={0}
            />
          </Grid>
        </Grid>
        {(checkSalesOrgAccountsData?.errorMessageAccountsBySalesOrg ||
          salesOrgAccMsg) && (
          <Box mt={2} className={styles.warningStyle}>
            <Typography component="div" variant="caption">
              {salesOrgAccMsg
                ? salesOrgAccMsg
                : checkSalesOrgAccountsData?.errorMessageAccountsBySalesOrg}
            </Typography>
          </Box>
        )}
        {checkSalesOrgAccountsData?.infoMessageAccountsBySalesOrg && (
          <Box mt={2} className={styles.infoStyle}>
            <Typography component="div" variant="caption">
              {checkSalesOrgAccountsData?.infoMessageAccountsBySalesOrg}
            </Typography>
          </Box>
        )}
        {checkSalesOrgAccountsData?.accountsList?.length > 0 && (
          <Grid container mt={3}>
            <Grid item lg={9} md={11} sm={12} xs={12}>
              <Box className={styles.mainTableContainer}>
                <div className={styles.tableHeader}>
                  <Typography variant="body2">
                    {SYSTEM_ADMIN.SALES_ORG}
                    {salesOrgAcc}&nbsp;-&nbsp;
                    {SYSTEM_ADMIN.CONTACT_ACCOUNTS_LIST}
                  </Typography>
                </div>
                <TableContainer
                  component={Paper}
                  className={styles.tableContainer}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{SYSTEM_ADMIN.ACCOUNT_REF}</TableCell>
                        <TableCell>{SYSTEM_ADMIN.BUSINESS_GROUP}</TableCell>
                        <TableCell>{SYSTEM_ADMIN.CONTACT_EMAIL_LIST}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {checkSalesOrgAccountsData?.accountsList?.map(
                        (account: any, index: number) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>{account?.accountReference}</TableCell>
                            <TableCell>{account?.businessGroup}</TableCell>
                            <TableCell>
                              {account?.contactEmailList?.join(", ")}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};
