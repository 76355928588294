import { commonConstant } from "../constants/common-constants";

export const initialState = {
  productList: {},
  productGroup: [],
  deleteAPIresponse: {},
  updateAPIresponse: {},
  saveAPIresponse: {},
};

const productListReducer = (state = initialState, action: any) => {
  switch (action?.type) {
    case commonConstant.GET_ALL_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        productList: action?.payload,
      };
    case commonConstant.GET_PRODUCT_GROUP_SUCCESS:
      return {
        ...state,
        productGroup: action?.payload,
      };

    case commonConstant.DELETE_PRODUCT_GROUP_SUCCESS:
      return {
        ...state,
        deleteAPIresponse: action?.payload,
      };

    case commonConstant.UPDATE_PRODUCT_GROUP_SUCCESS:
      return {
        ...state,
        updateAPIresponse: action?.payload,
      };

    case commonConstant.SAVE_PRODUCT_GROUP_SUCCESS:
      return {
        ...state,
        saveAPIresponse: action?.payload,
      };

    default:
      return state;
  }
};

export default productListReducer;
