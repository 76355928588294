import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styles from "./quotationTable.module.scss";
import moment from "moment";

// var currencyFormatter = require("currency-formatter");
var _ = require("lodash");
const alertstar = require("../../../../assets/images/alertstar_002.gif");
const hotIconFlag = require("../../../../assets/images/icon_flag.gif");

export const QuotationTable = ({
  isMultiShip,
  multiRdd,
  showPrice,
  setShowPrice,
  placeOrderPage,
}: any) => {
  const { t } = useTranslation();

  const tableHeaderData = [
    { id: "line", label: t("dnet.line.text") },
    { id: "model", label: t("dnet.model.text") },
    { id: "orderedQuantity", label: t("dnet.ordered_quantity.text") },
    { id: "reqDate", label: t("dnet.req_date.text") },
    { id: "plannedQuantity", label: t("dnet.planned_quantity.text") },
    { id: "plannedDate", label: t("dnet.planned_date.text") },
    { id: "unitPrice", label: t("dnet.unit_price.text"), minWidth: "120px" },
    { id: "netPrice", label: t("dnet.netprice.text"), minWidth: "120px" },
    { id: "description", label: t("dnet.description.text") },
    { id: "specialPrice", label: t("dnet.special_price.text") },
  ];

  const singleQuoteLoad = useSelector(
    (state: any) => state?.cartReducer?.singleQuoteLoad
  );
  const multiQuoteLoad = useSelector(
    (state: any) => state?.cartReducer?.multiQuoteLoad?.data
  );
  const placeOrderRes = useSelector(
    (state: any) => state?.cartReducer?.placeOrderRes?.data
  );

  const [totalNetValue, setTotalNetValue] = useState<any>(0);
  const [contextKeys, setContextKeys] = useState<any>({});

  useEffect(() => {
    if (!_.isEmpty(singleQuoteLoad)) {
      if (!_.isEmpty(placeOrderRes) && placeOrderPage) {
        setProdContentData(placeOrderRes?.output);
        setContextKeys(placeOrderRes?.payload);
      } else setProdContentData([singleQuoteLoad]);
      setContextKeys(singleQuoteLoad);
      setTotalNetValue(singleQuoteLoad?.totalNetValue?.amount);
    }
    if (!_.isEmpty(multiQuoteLoad)) {
      if (!_.isEmpty(placeOrderRes) && placeOrderPage) {
        setProdContentData(placeOrderRes?.output);
        setContextKeys(placeOrderRes?.payload?.products?.[0]);
      } else setProdContentData(multiQuoteLoad?.response);
      setContextKeys(multiQuoteLoad?.response?.[0]);
      let total = 0;
      multiQuoteLoad?.response?.forEach((item: any) => {
        total += item?.totalNetValue?.amount;
      });
      setTotalNetValue(total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleQuoteLoad, multiQuoteLoad, placeOrderRes]);

  const [prodContentData, setProdContentData] = useState<any>([]);

  const priceVisible = contextKeys?.priceVisible;

  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const handleChangePage = (
  //   event: React.MouseEvent<HTMLButtonElement> | null,
  //   newPage: number
  // ) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  return (
    <>
      <Box className={styles.tableBox}>
        <TableContainer component={Paper} className={styles.tableContainer}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableHeaderData.map((headCell: any, index: any) => (
                  <React.Fragment key={index}>
                    {headCell.id === "unitPrice" ? (
                      priceVisible && (
                        <TableCell
                          key={headCell.id}
                          style={{
                            minWidth: headCell?.minWidth,
                            color: "#5887f5",
                          }}
                          className={styles.colorBlue}
                          onClick={() => {
                            setShowPrice(!showPrice);
                          }}
                        >
                          {headCell.label}
                          <img src={alertstar} alt="alertStar" />
                        </TableCell>
                      )
                    ) : headCell.id === "netPrice" ? (
                      <TableCell
                        key={headCell.id}
                        style={{
                          minWidth: headCell?.minWidth,
                          color: "#5887f5",
                        }}
                        className={styles.colorBlue}
                        onClick={() => {
                          setShowPrice(!showPrice);
                        }}
                      >
                        {headCell.label}
                        <img src={alertstar} alt="alertstar" />
                      </TableCell>
                    ) : headCell.id === "specialPrice" ? (
                      contextKeys?.isSpecialPriceEnabled && (
                        <TableCell key={headCell.id}>
                          {headCell.label}
                        </TableCell>
                      )
                    ) : headCell.id === "reqDate" ? (
                      multiRdd && (
                        <TableCell key={headCell.id}>
                          {headCell.label}
                        </TableCell>
                      )
                    ) : (
                      <TableCell key={headCell.id}>{headCell.label}</TableCell>
                    )}
                  </React.Fragment>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {prodContentData?.length > 0 ? (
                prodContentData?.map((order: any, index: any) => {
                  return (
                    <>
                      {isMultiShip && (
                        <TableRow
                          tabIndex={-1}
                          //   key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell colSpan={tableHeaderData?.length - 1}>
                            <div>
                              {`${t("dnet.deliver_to.text")} ${
                                order?.shipToAccount?.number
                              } - ${order?.shipToAccount?.name}, ${
                                order?.shipToAccount?.address?.street
                              }, ${order?.shipToAccount?.address?.townCity} (${
                                order?.shipToAccount?.address?.country
                              })`}
                            </div>
                            {order?.orderNumber !== "" &&
                              !_.isEmpty(placeOrderRes) && (
                                <div>
                                  {`${t("dnet.sony_ref.text")} ${
                                    order?.orderNumber
                                  }`}
                                </div>
                              )}
                          </TableCell>
                        </TableRow>
                      )}
                      {order?.lineItems?.map(
                        (product: any, productIndex: any) => {
                          return (
                            <TableRow
                              tabIndex={-1}
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>
                                {parseInt(product?.index, 10).toString()}
                                {product?.hotflagged && (
                                  <img src={hotIconFlag} alt="hoticonflag" />
                                )}
                              </TableCell>
                              <TableCell>{product?.productSku?.name}</TableCell>
                              <TableCell>{product?.quantity}</TableCell>
                              {multiRdd && (
                                <TableCell>
                                  {moment(
                                    product?.requestedDeliveryDate
                                  )?.format("DD-MMM-YYYY")}
                                </TableCell>
                              )}
                              {product?.scheduleLines?.length > 0 ? (
                                <TableCell>
                                  {product?.scheduleLines?.map((item: any) => (
                                    <div>{item?.quantity}</div>
                                  ))}
                                </TableCell>
                              ) : (
                                <TableCell></TableCell>
                              )}
                              {product?.scheduleLines?.length > 0 && (!_.isEmpty(placeOrderRes) && placeOrderPage) ? (
                                <TableCell>
                                  {product?.scheduleLines?.map((item: any) => (
                                    <div>
                                      {item?.confirmed && item?.plannedIssueDate
                                        ? moment(item?.plannedIssueDate)?.format(
                                            "DD-MMM-YYYY"
                                          )
                                        : product?.centralStockStatus
                                        ? t("dnet.centralstock_overnight.text")
                                        : t("dnet.unconfirmed.text")}
                                    </div>
                                  ))}
                                </TableCell>
                              ) : product?.scheduleLines?.length > 0 ? (
                                <TableCell>
                                  {product?.scheduleLines?.map((item: any) => (
                                    <div>
                                      {item?.confirmed && item?.goodsIssueDate
                                        ? moment(item?.goodsIssueDate)?.format(
                                            "DD-MMM-YYYY"
                                          )
                                        : product?.centralStockStatus
                                        ? t("dnet.centralstock_overnight.text")
                                        : t("dnet.unconfirmed.text")}
                                    </div>
                                  ))}
                                </TableCell>
                              ) : (
                                <TableCell></TableCell>
                              )}
                              {priceVisible && (
                                <TableCell>
                                  {showPrice &&
                                    `${product?.unitPrice?.currency} ${(product?.unitPrice?.amount)?.toFixed(2)}`}
                                </TableCell>
                              )}
                              <TableCell>
                                {showPrice &&
                                  `${product?.netValuePrice?.currency} ${(product?.netValuePrice?.amount)?.toFixed(2)}`}
                              </TableCell>
                              <TableCell>
                                {!_.isEmpty(placeOrderRes)
                                  ? product?.description
                                  : product?.productSku?.descriptions
                                      ?.description}
                              </TableCell>
                              {contextKeys?.isSpecialPriceEnabled && (
                                <TableCell>
                                  {product?.specialPrice &&
                                    product?.specialPrice?.amount &&
                                    `${product?.specialPrice?.currency} ${product?.specialPrice?.amount}`}
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        }
                      )}
                    </>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={8}
                    align="center"
                    className={styles.noRecFound}
                  >
                    {t("gzl.no_data_available.text")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={styles.tableFooterDesign}>
          {contextKeys?.belowQuantityPrice?.amount > 0 && (
            <div className={styles.totalAmount}>
              <Typography variant="body2">
                {t("dnet.total_fee.text")}
              </Typography>
              {showPrice && (
                <Typography variant="body2">
                  {`${contextKeys?.belowQuantityPrice?.currency} ${contextKeys?.belowQuantityPrice?.amount}`}
                </Typography>
              )}
            </div>
          )}
          <div className={styles.totalAmount}>
            <Typography variant="body2">
              {`${t("dnet.total.text")} ${t(
                "dnet.order_report.net_price.text"
              )}`}
            </Typography>
            {showPrice && (
              <Typography variant="body2">
                {`${
                  prodContentData[0]?.totalNetValue?.currency
                } ${totalNetValue?.toFixed(2)} `}
              </Typography>
            )}
          </div>
          {/* <TablePagination
              className={styles.pagination}
              component="div"
              count={prodContentData?.length ? prodContentData.length : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 20]}
            /> */}
        </div>
      </Box>
    </>
  );
};
