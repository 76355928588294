import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styles from "./specificationsTab.module.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomButton from "../../../../atoms/customButton";
import { useSelector } from "react-redux";

export const SpecificationsTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const productDetailsData = useSelector(
    (state: any) => state?.productReducer?.productDetailsData
  );

  const [expandedAccordions, setExpandedAccordions] = useState<any>([0]);

  const accordionClicked = (index: any) => {
    if (expandedAccordions.includes(index))
      setExpandedAccordions(
        expandedAccordions.filter((number: any) => number !== index)
      );
    else setExpandedAccordions([...expandedAccordions, index]);
  };

  const collapseAll = () => {
    setExpandedAccordions([]);
  };

  const expandAll = () => {
    const newArray: any = [];
    productDetailsData?.techSpecs.forEach((spec:any, index:any) => newArray.push(index));
    setExpandedAccordions(newArray);
  };

  const [showButton, setShowButton] = useState("expand");

  useEffect(() => {
    if (expandedAccordions?.length === productDetailsData?.techSpecs?.length) {
      setShowButton("collapse");
    } else {
      setShowButton("expand");
    }
  }, [expandedAccordions]);

  return (
    <>
      <Box mb={2}>
        {showButton === "expand" ? (
          <CustomButton
            label={t("dnet.expand_all.text")}
            onClick={expandAll}
            margin={0}
          />
        ) : (
          <CustomButton
            label={t("dnet.collaps_all.text")}
            onClick={collapseAll}
            margin={0}
          />
        )}
      </Box>

      {productDetailsData?.techSpecs?.map((featureGroup: any, index: any) => (
        <Accordion
          key={index}
          disableGutters
          sx={{ marginBottom: "20px" }}
          onChange={() => accordionClicked(index)}
          expanded={expandedAccordions.includes(index)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1" color="primary">
              {featureGroup?.displayName}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {featureGroup?.specs?.map((feature: any, index: any) => (
              <Grid container mb={1} key={index}>
                <Grid item lg={5} md={5} sm={6} xs={12}>
                  <Typography
                    component="span"
                    variant="caption"
                    color="black"
                    sx={{ fontWeight: "600" }}
                  >
                    {feature?.displayName || feature?.name}
                  </Typography>
                </Grid>
                <Grid item lg={7} md={7} sm={6} xs={12} style={{overflowX: "auto"}}>
                  {/* To Do Cross Verify with Actual Data */}
                  {Array.isArray(feature?.value) ? (
                    feature?.value?.map((spec: any, index: any) => (
                      <React.Fragment key={index}>
                        <Typography
                          component="span"
                          variant="caption"
                          color="text.secondary"
                          dangerouslySetInnerHTML={{ __html: spec }}
                        ></Typography>
                        <br />
                      </React.Fragment>
                    ))
                  ) : (
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="caption"
                        color="text.secondary"
                        dangerouslySetInnerHTML={{ __html: feature?.value }}
                      ></Typography>
                      <br />
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};
