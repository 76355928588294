import React, { useEffect, useState } from "react";
import { COUNTRY_SELECT } from "../../constants/labels";
import styles from "./countrySelect.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES_LABEL } from "../../constants/routesLabels";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getTranslationAction, setLoading } from "../../state/actions";
import { Header } from "../header";
import { SubLocaleModal } from "./subLocaleModal";

const CountrySelect = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const currentTranslation = useSelector(
    (state: any) => state?.commonReducer?.translation
  );

  const [subLocaleModal, setSubLocaleModal] = useState(false);
  const [countryObject, setCountryObject] = useState({});
  const closeModal: any = () => {
    setSubLocaleModal(false);
  };

  const currentLocale = useSelector(
    (state: any) => state?.commonReducer?.locale
  );

  const changeLanguage = (locale: any, country: any = "") => {
    // Changing locale on country selection
    if(typeof locale === "string"){
      if (locale === "en_GB") {
        dispatch(setLoading(false)); 
        navigate(`/${ROUTES_LABEL.LOGIN}`);
      }
      else {
        dispatch(setLoading(true)); 
        dispatch(getTranslationAction({ locale }));
      }
    }
    else{
      let tempCountryObject = countryObject
      tempCountryObject = {
        locale,
        country
      }
      setCountryObject(tempCountryObject);
      setSubLocaleModal(true);
    }
  };

  useEffect(() => {
    if (Object.keys(currentTranslation).length >= 1) {
      i18n.addResourceBundle(
        currentLocale,
        "translation",
        currentTranslation,
        true
      );
      i18n.changeLanguage(currentLocale);
      dispatch(setLoading(false)); 
      if (currentLocale !== "en_GB") navigate(`/${ROUTES_LABEL.LOGIN}`);
    } else {
      dispatch(setLoading(true));  
      dispatch(getTranslationAction({ locale: "en_GB" }));
    }
  }, [currentTranslation]);

  return (
    <div className={styles.countryContainer}>
      <Header fromLogin={true} />
      <div className={styles.boxStyle}>
        <Typography variant="subtitle1" color="primary">
          Welcome to 1
        </Typography>
        <Typography className={styles.header}>Please select country</Typography>
      </div>
      <div className={styles.countryBox}>
        {COUNTRY_SELECT?.map((elem, index) => {
          return (
            <div
              className={styles.countryCard}
              key={index}
              onClick={() => changeLanguage(elem?.locale, elem?.country)}
            >
              {elem?.country}
            </div>
          );
        })}
      </div>
      {subLocaleModal && (
        <SubLocaleModal
          closeModal={closeModal}
          openModal={subLocaleModal}
          countryObject = {countryObject}
          changeLanguage= {changeLanguage}
        />
      )}
    </div>
  );
};

export default CountrySelect;
