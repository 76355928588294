import { AccountCircle } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Badge,
  Box,
  Grid,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { ROUTES_LABEL } from "../../constants/routesLabels";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import {
  downloadInvoicePdf,
  fetchGlobalSearchSuggestions,
  fetchGlobalSearchSuggestionsSuccess,
  fetchProdCategoryResponseSuccess,
  getCurrentDealerSuccess,
  getDealerDetailsSuccess,
  getProductsByMaterialName,
  getProductsByMaterialNameSuccess,
  loginUserSuccess,
  logoutAction,
  setCurrentAddressSet,
  setCurrentDealer,
  setDealers,
  setIsUserLoggedin,
  setSonyMenuItems,
  startProductFlow,
  startProductFlowSuccess,
  updateProfileSuccess,
} from "../../state/actions";
import CustomAutoComplete from "../atoms/customAutoComplete";
import { SonyMenu } from "../sonyMenu";
import styles from "./header.module.scss";
//@ts-ignore
import SonyLogo from "./so-sony-logo.svg";

var _ = require("lodash");

export const Header = (props: any) => {
  const { fromLogin } = props;
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [clickedMenuName, setClickedMenuName] = React.useState("");
  const [invoiceNumber, setInvoiceNumber] = React.useState("");
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.userDetails
  );
  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );
  const sonyMenuItems = useSelector(
    (state: any) => state?.commonReducer?.sonyMenuItems
  );

  const logout = () => {
    dispatch(setIsUserLoggedin(false));
    dispatch(loginUserSuccess({}));
    dispatch(setCurrentDealer({}));
    dispatch(getDealerDetailsSuccess([]));
    dispatch(setSonyMenuItems([]));
    dispatch(setDealers([]));
    dispatch(setCurrentAddressSet(-1));
    dispatch(startProductFlowSuccess({}));
    dispatch(fetchProdCategoryResponseSuccess({}));
    dispatch(getCurrentDealerSuccess({}));
    dispatch(logoutAction({}));
    navigate(`/${ROUTES_LABEL.LOGIN}`);
  };
  const selectDealer = () => {
    dispatch(setCurrentDealer({}));
    dispatch(getDealerDetailsSuccess([]));
    dispatch(setSonyMenuItems([]));
    setClickedMenuName("");
    dispatch(getCurrentDealerSuccess({}));
    dispatch(startProductFlowSuccess({}));
    dispatch(fetchProdCategoryResponseSuccess({}));
    navigate(`/${ROUTES_LABEL.DEALER_SELECTION}`);
  };
  const displayMenuItems = (itemName: any) => {
    const menuItem = sonyMenuItems?.filter((item: any) => {
      return item?.type === itemName
    })
    return menuItem?.length === 1;
  }
  const showHeaderIcons = (itemName: any) => {
    const menuOptions = sonyMenuItems?.filter((item: any) => {
      return item?.sectionName === "DNET Order Management"
    })
    const menuItem = menuOptions[0]?.subMenu?.filter((item: any) => {
      return item?.sectionName === itemName
    })
    return menuItem?.length === 1;
  }
  const profileMenuItems = [
    {
      label: t("dnet.contact.section"),
      navLink: `/${ROUTES_LABEL.CONTACT}`,
      funcName: () => {
        navigate(`/${ROUTES_LABEL.CONTACT}`)
      },
      display: displayMenuItems("Contact"),
    },
    {
      label: t("dnet.link.section"),
      navLink: `/${ROUTES_LABEL.LINKS}`,
      funcName: () => {
        navigate(`/${ROUTES_LABEL.LINKS}`)
      },
      display: displayMenuItems("Link"),
    },
    {
      label: t("dnet.help.section"),
      navLink: `/${ROUTES_LABEL.HELP}`,
      funcName: () => {
        navigate(`/${ROUTES_LABEL.HELP}`)
      },
      display: displayMenuItems("Help"),
    },
    {
      label: t("dnet.update_profile.section"),
      navLink: `/${ROUTES_LABEL.UPDATE_PROFILE}`,
      funcName: () => {
        dispatch(updateProfileSuccess({}));
      },
      display: displayMenuItems("UpdateProfile"),
    },
    {
      label: t("dnet.select_account.text"),
      funcName: selectDealer,
      display: !currentDealer?.singleAccount,
    },
    {
      label: t("dnet.logout.section"),
      funcName: logout,
      display: displayMenuItems("Logout"),
    },
  ];

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpenMenu(open);
    };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setClickedMenuName(event.currentTarget.id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [showSearchField, setShowSearchField] = useState<any>(false);
  const handleSearchIconClick = () => {
    setShowSearchField(!showSearchField);
  };

  //hiding or displaying header chucnks based on module which we are in
  const displayHeader = () => {
    if (fromLogin) {
      return false;
    } else {
      return true;
    }
  };
  const closeModal = () => {
    setOpenModal(false);
    setInvoiceNumber("");
  };
  const downloadPdf = () => {
    dispatch(
      downloadInvoicePdf({
        language: userDetails?.language,
        username: userDetails?.userName,
        partnerKey: userDetails?.account,
        invoiceId: "SD_" + invoiceNumber,
      })
    );
  };

  const isLoggedin = useSelector(
    (state: any) => state?.commonReducer?.isLoggedin
  );

  const redirectToMainPage = () => {
    if (isLoggedin && !_.isEmpty(currentDealer)) {
      navigate(`/${ROUTES_LABEL.SONY_HOMEPAGE}`);
    }
    else if(!isLoggedin) {
      navigate(`/${ROUTES_LABEL.LOGIN}`);
    }
  };

  const [searchValue, setSearchValue] = React.useState<any>("");
  const [isKeyPressed,setIsKeyPressed]= useState(false);

  //If the length of search text is greater than 2 chars, then trigger api call to fetch search result
  useEffect(() => {
    if (searchValue?.length > 2) {
      dispatch(
        fetchGlobalSearchSuggestions({
          searchString: searchValue,
        })
      );
      setIsKeyPressed(false);
    }
  }, [searchValue]);

  const globalSearchSuggestions = useSelector(
    (state: any) => state?.commonReducer?.globalSearchSuggestions
  );

  const inputRef: any = React.useRef(null);
  useEffect(()=>{
    if(isKeyPressed)
    {
      if(!_.isEmpty(globalSearchSuggestions)){
        let productArr:any= globalSearchSuggestions.map((product:any)=>product?.item);
        dispatch(
          getProductsByMaterialName({
            materialName: productArr,
          }));     
      }else{
        dispatch(getProductsByMaterialNameSuccess([]));
      }   
      navigate(`/${ROUTES_LABEL.GLOBAL_SEARCH}`);
    }
    
  },[globalSearchSuggestions]);

  const selectedProductHandler = (value: any) => {
    dispatch(
      getProductsByMaterialName({
        materialName: [value],
      }));
    inputRef.current.click();   
    dispatch(fetchGlobalSearchSuggestionsSuccess([]));
    navigate(`/${ROUTES_LABEL.GLOBAL_SEARCH}`);
  };

  const handleKeyPress =(event:any)=>{
    if(event?.key==='Enter' && searchValue?.length>1){
      setIsKeyPressed(true);
      dispatch(
        fetchGlobalSearchSuggestions({
          searchString: searchValue,
        })
      );
      inputRef.current.click();
    }
  }

  const cartProductsTotal = useSelector(
    (state: any) => state?.cartReducer?.cartProductsTotal
  )

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar className={styles.HeaderBgColor}>
          <Toolbar className={styles.toolbar}>
            <Grid container>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box className={styles.toolbarContent}>
                  {displayHeader() && (
                    <>
                      <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ ml: 1, mr: 2 }}
                        onClick={toggleDrawer(true)}
                      >
                        <MenuIcon />
                      </IconButton>
                      <SonyMenu
                        openMenu={openMenu}
                        toggleDrawer={toggleDrawer}
                      />
                    </>
                  )}

                  <div
                    className={styles.logoStyle}
                    onClick={redirectToMainPage}
                  >
                    <img src={SonyLogo} alt="Sony" title="Sony" />
                  </div>
                  <Typography
                    variant="h5"
                    component="span"
                    color="primary"
                    pr={2}
                    className={styles.headerNumText}
                  >
                    1
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    className={styles.headerText}
                    sx={{ flexGrow: 1 }}
                  >
                    {t("dnet.headertext.text").toString()}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                {displayHeader() && (
                  <Grid container alignItems={"center"} textAlign={"center"}>
                    <Grid item lg={1} md={1} sm={1} xs={0}></Grid>
                    {showSearchField && (
                      <Grid item lg={9} md={8} sm={9} xs={8} textAlign={"end"}>
                        <CustomAutoComplete
                          setShowSearchField={setShowSearchField}
                          options={globalSearchSuggestions}
                          value={searchValue}
                          setValue={setSearchValue}
                          placeHolder={t(
                            "dataDownload.placeHolder.search.text"
                          )}
                          selectedItems={(value: any) =>
                            selectedProductHandler(value)
                          }
                          forwardedKeyDown={handleKeyPress}
                          textFieldStyle={{ backgroundColor: "white" }}
                        />
                      </Grid>
                    )}
                    <Grid 
                      item 
                      lg={showSearchField ? 2 : 11} 
                      md={showSearchField ? 3: 11} 
                      sm={showSearchField ? 2: 11} 
                      xs={showSearchField ? 4: 12}
                      className={styles.headerMenuIconsTray}
                    >
                      {showSearchField ? (
                        <IconButton
                          ref={inputRef}
                          size="large"
                          color="inherit"
                          onClick={(e: any) => setShowSearchField(false)}
                        >
                          <CloseIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          size="large"
                          aria-label="Search Invoice/ Reference"
                          color="inherit"
                          onClick={handleSearchIconClick}
                          id="search-button"
                        >
                          <ManageSearchIcon />
                        </IconButton>
                      )}
                      {showHeaderIcons("DNET Order Entry") &&
                        !showSearchField &&
                        <IconButton
                          size="large"
                          aria-label="cart"
                          color="inherit"
                          onClick={()=>navigate(`/${ROUTES_LABEL?.ORDER_ENTRY}`)}
                          id="user-cart"
                          title={t("dnet.orderentry.section")?.toString()}
                        >
                          {cartProductsTotal?.totalProductCount !== "" || 
                          cartProductsTotal?.totalProductCount !== 0 || 
                          cartProductsTotal?.totalProductCount !== "0" ? 
                            <Badge 
                              badgeContent={cartProductsTotal?.totalProductCount} 
                              color="primary"
                            >
                              <ShoppingCartOutlinedIcon />
                            </Badge> :
                            <ShoppingCartOutlinedIcon />
                          }
                        </IconButton>
                      }
                      {showHeaderIcons("DNET Product List") && 
                        !showSearchField &&
                        <IconButton
                          size="large"
                          aria-label="wishlist"
                          color="inherit"
                          onClick={()=>navigate(`/${ROUTES_LABEL?.PRODUCT_LISTS}`)}
                          id="wishList"
                          title={t("dnet.product_list.text")?.toString()}
                        >
                          <FavoriteBorderOutlinedIcon />
                        </IconButton>
                      }
                      <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls={open ? "menu-appbar" : undefined}
                        aria-haspopup="true"
                        color="inherit"
                        onClick={handleClick}
                        aria-expanded={open ? "true" : undefined}
                        id="profile-button"
                      >
                        <AccountCircle />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
                {clickedMenuName && (
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby":
                        clickedMenuName === "profile-button"
                          ? "profile-button"
                          : "search-button",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    {
                      clickedMenuName === "profile-button" &&
                        profileMenuItems.map(
                          (item) =>
                            item?.display && (
                              <MenuItem
                                key={item.label}
                                onClick={item.funcName}
                              >
                                {item.navLink ? (
                                  <Link
                                    to={item.navLink}
                                    className={styles.profileMenuItems}
                                    onClick={handleClose}
                                  >
                                    {item.label}
                                  </Link>
                                ) : (
                                  item.label
                                )}
                              </MenuItem>
                            )
                        )
                      // : searchMenuItems.map(
                      //     (item) =>
                      //       item?.display && (
                      //         <MenuItem key={item.label} onClick={item.funcName}>
                      //           {item.label}
                      //         </MenuItem>
                      //       )
                      // )
                    }
                  </Menu>
                )}
                {/* <CustomModal
                  open={openModal}
                  closeModal={closeModal}
                  heading={t("dnet.invoice_search.text")}
                  content={t("gzl.account_information_home_infofield2.text")}
                  invoiceSearchModal={true}
                  invoiceSearchFunc={downloadPdf}
                  getInvoiceNumber={(e: any) =>
                    setInvoiceNumber(e.target.value)
                  }
                  invoiceNumber={invoiceNumber}
                /> */}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};
