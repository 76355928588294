import {
  Box,
  Checkbox,
  IconButton,
  TableCell,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { OrderDetailsTable } from "./orderDetailsTable";
import styles from "./orderStatus.module.scss";
import { CONTEXT_ORDER, LINE_ITEM_ORDER_STATUS, NEW_EMAIL_REGEX } from "../../../../constants/commonConstants";
import CustomButton from "../../../atoms/customButton";
// @ts-ignore  
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { editOrderReport, editOrderReportSuccess, getContextDetails, getOrderDetails, getOrderDetailsSuccess } from "../../../../state/actions";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import CustomTextField from "../../../atoms/customTextField";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
//@ts-ignore
import spacer from "../../../../assets/images/spacer.gif";
//@ts-ignore
import blockedOrder from "../../../../assets/images/blocked_order.jpg";
//@ts-ignore
import LockIcon from "@mui/icons-material/Lock";
import CustomDropDown from "../../../atoms/customDropDown";
import { ROUTES_LABEL } from "../../../../constants/routesLabels";

export const OrderStatus = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [showPrice, setShowPrice] = useState(true);

  const handleShowPrice = () => {
    setShowPrice(!showPrice);
  };

  let internetOrderId = location?.state?.internetOrderId;
  let [orderStatusErrorText, setOrderStatusErrorText] = useState("");
  let [updateComponent, setUpdateComponent] = useState(false);
  let [newUpdatedOrder, setNewUpdatedOrder]: any = useState({});

  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );
  const loginDetails = useSelector(
    (state: any) => state?.commonReducer?.loginResponse?.data?.userDetails
  );

  const contextDetails = useSelector(
    (state: any) => state?.commonReducer?.contextDetails
  );

  const orderDetailsResponse = useSelector(
    (state: any) => state?.reportingReducer?.orderDetails
  );

  const [formEditable, setFormEditable] = useState(false);

  const createMenuListItems = (agentlist: any, filterType: any) => {
    if (filterType === "list") {
      let listItems: any = [];
      agentlist?.length > 0 && agentlist.forEach((item: any) => {
        listItems.push({
          value: item?.number,
          label: `${item?.number} - ${item?.name}, ${item?.address?.street}, ${item?.address?.townCity} (${item?.address?.country})`,
        });
      });
      return listItems;
    } else {
      let shipingAgentText: any = "";
      if(agentlist?.length > 0){
        let item = agentlist?.filter((item: any) => {
          return (
            item?.number ===
            orderDetailsResponse?.dealership?.shippingAgentAccount[0]?.number
          );
        });
        shipingAgentText = `${item?.[0]?.number} - ${item?.[0]?.name}, ${item?.[0]?.address?.street}, ${item?.[0]?.address?.townCity} (${item?.[0]?.address?.country})`;
        return shipingAgentText;
      }
      return shipingAgentText;
    }
  };

  const handlePayerToAccounts = (value: any) => {
    let paymentTermEnabledTemp: any = [...paymentTermEnabled];
    paymentTermEnabledTemp?.map((list: any) => {
      return list?.id==="payer" ? list.item = value : null
    })
    setPaymentTermEnabled(paymentTermEnabledTemp);
  }

  const [paymentTermEnabled, setPaymentTermEnabled]:any = useState([])


  const [orderDetails, setOrderDetails]: any = useState([]);

  useEffect(() => {
    let orderDetailsTemp: any = orderDetails;
    let paymentTermEnabledTemp: any = paymentTermEnabled;
    orderDetailsTemp = [
      {
        id: "creationDate",
        name: t("dnet.order_report.created_on.text"),
        value: moment(orderDetailsResponse?.orders?.creationDate).format("DD-MMM-YYYY"),
        edit: true,
      },
      {
        id: "orderNumber",
        name: t("dnet.order_report.sony_ref.text"),
        value: orderDetailsResponse?.orders?.orderNumber,
        edit: true,
      },
      {
        id: "customerReference",
        name: t("dnet.order_report.your_ref.text"),
        value: orderDetailsResponse?.orders?.customerReference,
        edit: true,
      },
      {
        id: "soldToAccount",
        name: t("dnet.account_no.text"),
        value: `${orderDetailsResponse?.orders?.soldToAccount?.number} - ${orderDetailsResponse?.orders?.soldToAccount?.name}`,
        edit: true,
      },
      {
        id: "shipToAccount",
        name: t("dnet.deliver_to.text"),
        value: `${orderDetailsResponse?.orders?.shipToAccount?.number} - ${orderDetailsResponse?.orders?.shipToAccount?.name}`,
        edit: true,
      },
      {
        id: "deliver_to_address",
        name: t("dnet.order_report.deliver_to_address.text"),
        value: `${orderDetailsResponse?.orders?.shipToAccount?.address?.street} ${orderDetailsResponse?.orders?.shipToAccount?.address?.city}`,
        edit: false,
      },
      {
        id: "shipingAgent",
        name: t("dnet.shipping_agent.text"),
        value: createMenuListItems(
          orderDetailsResponse?.dealership?.shippingAgentAccount,
          "item"
        ),
        listItems: createMenuListItems(
          orderDetailsResponse?.dealership?.shippingAgentAccount,
          "list"
        ),
        item: orderDetailsResponse?.dealership?.shippingAgentAccount[0]?.number,
        edit: true,
      },
      {
        id: "sendEmail",
        name: t("dnet.send_order_details_mail.text"),
        emailList: [loginDetails?.profile?.email],
        checked: false,
        edit: true,
        error: false,
      },
      {
        id: "sendEmailToSalesRepresntative",
        name: t("dnet.send_order_details_mail_to_all_contacts.text"),
        emailList: contextDetails?.salesRepContacts,
        emptyText: t("dnet.sales_rep_contact_email_list_empty.text")?.toString(),
        checked: false,
        edit: true,
        emailCheck: true,
      },
      {
        id: "paymentTermEnabled",
      },
    ];
    paymentTermEnabledTemp = [
      {
        id: "payer",
        name: t("dnet.payer.text"),
        value: `${orderDetailsResponse?.orders?.payerToAccount?.number} - ${orderDetailsResponse?.orders?.payerToAccount?.name}, ${orderDetailsResponse?.orders?.payerToAccount?.address?.street}, ${orderDetailsResponse?.orders?.payerToAccount?.address?.townCity} (${orderDetailsResponse?.orders?.payerToAccount?.address?.country})`,
        edit: true,
        listItems: createMenuListItems(
          contextDetails?.payerToAccounts,
          "list"
        ),
        item: orderDetailsResponse?.orders?.payerToAccount?.number,
      },
      {
        id: "paymentTerm",
        name: t("dnet.payment_term.text"),
        value: createPaymentTermText(orderDetailsResponse?.orders?.paymentTerm),
        edit: false,
      }
    ];
    setOrderDetails(orderDetailsTemp);
    setPaymentTermEnabled(paymentTermEnabledTemp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetailsResponse]);

  const createPaymentTermText = (paymentTermObject: any) => {
    if (paymentTermObject?.description?.length > 0)
      return `${paymentTermObject?.name}-${paymentTermObject?.description}`
    else
      return `${paymentTermObject?.name}`
  }

  const endMsg = t(
    `dnet.quotationt.c_${currentDealer?.bgName?.slice(0, 4)}.text`
  ).toString();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const createTable = () => {
    const tableHeader = [
      { id: "itemNo", label: t("dnet.item_number.text") },
      { id: "productCategory", label: t("dnet.order_report.category.text") },
      { id: "model", label: t("dnet.model.text") },
      { id: "customerMaterialNumber", label: t("customerMaterialNumber.text") },
      { id: "eanCode", label: t("dnet.order_report.ean_code.text") },
      // { id: "editQuantity", label: t("dnet.quantity.text") },
      { id: "reqDate", label: t("dnet.order_report.requested_date.text") },
      {
        id: "reqTotalQty",
        label: t("dnet.order_report.requested_total_quantity.text"),
      },
      {
        id: "unitPrice",
        label: t("dnet.order_report.unit_price.text"),
      },
      {
        id: "netPrice",
        label: t("dnet.order_report.net_price.text"),
      },
      {
        id: "specialPrice",
        label: t("dnet.special_price.text"),
      },
      {
        id: "confirmedQty",
        label: t("dnet.order_report.confirmed_quantity.text"),
      },

      { id: "openQty", label: t("dnet.order_report.open_quantity.text") },
      { id: "rejectCode", label: t("dnet.order_report.rejection_code.text") },
      {
        id: "plannedDispatchDate",
        label: t("dnet.order_report.planned_dispatch_date.text"),
      },
      {
        id: "actualDispatchDate",
        label: t("dnet.order_report.actual_dispatch_date.text"),
      },
      {
        id: "plannedDeliveryDate",
        label: t("dnet.order_report.planned_delivery_date.text"),
      },
      {
        id: "readyToDeliverQty",
        label: t("dnet.order_report.ready_to_deliver_quantity.text"),
      },
      // { id: "quantity", label: t("dnet.quantity.text") },
      // { id: "despatched", label: t("dnet.despatched.text") },
      { id: "deliveryNo", label: t("dnet.delivery_no.text") },
      { id: "carierRefNo", label: t("dnet.carrier_reference_no.text") },
      { id: "invoiceNo", label: t("dnet.invoice_no.text") },
      { id: "lockStatus", label: t("dnet.padlock_alttext.text") },
      { id: "blockStatus", label: "" },
      // { id: "deleteCol", label: t("dnet.delete.text") },
    ];
    let cellStyle={
      border:"1px solid #c8c8c8"
    }
    return (
      <div>
        <Typography
          variant="subtitle1"
          className={styles.header}
          pb={3}
          color="primary"
        >
          {t("dnet.order_status.text")}
        </Typography>
        <Box className={styles.boxContainer}>
          <div>
            {orderDetails?.length > 0 &&
              orderDetails?.map((item: any) =>
                item?.id !== "paymentTermEnabled"
                  ? formEditable
                    ? item?.edit &&
                      ((item?.id === "sendEmail" && updateComponent) ||
                        item?.id !== "sendEmail") &&
                      ((item?.id === "sendEmailToSalesRepresntative" && updateComponent 
                        && item?.checked) ||
                        item?.id !== "sendEmailToSalesRepresntative") &&
                      ((item?.id === "shipingAgent" &&
                        item?.listItems?.length > 0) ||
                        item?.id !== "shipingAgent") &&
                      ((item?.id === "shipToAccount" &&
                        Object.keys(orderDetailsResponse?.orders?.shipToAccount)
                          ?.length > 0 &&
                        orderDetailsResponse?.orders?.shipToAccount !== null) ||
                        item?.id !== "shipToAccount") && (
                        <div className={styles.orderBox}>
                          <Typography
                            variant="body2"
                            className={styles.textWidth}
                          >
                            {item?.id === "creationDate"
                              ? t("dnet.order_date.text")
                              : item?.name}
                          </Typography>
                          <div>:</div>
                          {item?.id === "customerReference" &&
                          !updateComponent ? (
                            <CustomTextField
                              label=""
                              changeHandler={(e: any) =>
                                handleFormField(e, item?.id)
                              }
                              value={item.value}
                              additionalSxProperties={{ width: "50%" }}
                            />
                          ) : item?.id === "creationDate" ? (
                            <Typography variant="body2">
                              {moment(item.value).format("MMM DD,YYYY")}
                            </Typography>
                          ) : item?.id === "sendEmail" && updateComponent ? (
                            <div className={styles.sendEmailBox}>
                              <Checkbox
                                id="emailCheckbox"
                                checked={item?.checked}
                                onChange={(e: any) => {
                                  handleFormField(e, "emailCheckbox");
                                }}
                                size="small"
                                sx={{ padding: 0, marginRight: "10px" }}
                              />
                              {item?.checked && (
                                <div>
                                  <CustomTextField
                                    id="emailList"
                                    label={t("dnet.to.text")}
                                    value={item?.emailList?.join(",")}
                                    changeHandler={(e: any) => {
                                      handleFormField(e, item?.id);
                                    }}
                                    //   helperText={refError && t("dnet.mandatory.text")}
                                    //   error={refError}
                                  />
                                  <Tooltip
                                    title={t(
                                      "dnet.separate_multiple_addresses.text"
                                    )}
                                    arrow
                                    placement="right"
                                    enterTouchDelay={0}
                                  >
                                    <IconButton className={styles.infoIcon}>
                                      <InfoOutlinedIcon color="action" />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              )}
                            </div>
                          ) : (
                            <Typography variant="body2">
                              {item.value}
                            </Typography>
                          )}
                        </div>
                      )
                    : ((item?.id === "sendEmail" && updateComponent) ||
                    (item?.id !== "sendEmail")) &&
                    ((item?.id !== "sendEmailToSalesRepresntative")) &&
                    ((item?.id === "shipingAgent" &&
                      item?.listItems?.length > 0
                      ) ||
                      item?.id !== "shipingAgent") && (
                      <div className={styles.orderBox}>
                        <Typography
                          variant="body2"
                          className={styles.textWidth}
                        >
                          {item.name}
                        </Typography>
                        <div>:</div>
                        {
                          item?.id === "shipingAgent" && item?.listItems?.length > 1 ?
                          <CustomDropDown
                            id="shippingAgent"
                            value={item?.item}
                            menuOptions={item?.listItems}
                            module="debitNoteCreation"
                            label=""
                            // width="90%"
                            handleChange={(e: any) => {
                              handleChangeShippingAgent(e.target.value);
                            }}
                          /> :
                          <Typography variant="body2">{item.value}</Typography>
                        }
                      </div>
                    )
                  : contextDetails?.isPaymentTermEnabled
                  ? paymentTermEnabled?.length > 0 &&
                    paymentTermEnabled?.map((subItem: any) => (
                      <div className={styles.orderBox}>
                        <Typography
                          variant="body2"
                          className={styles.textWidth}
                        >
                          {subItem.name}
                        </Typography>
                        <div>:</div>
                        {formEditable &&
                        subItem?.edit &&
                        subItem?.id === "paymentTerm" &&
                        !updateComponent ? ( 
                          <CustomTextField
                            label=""
                            changeHandler={(e: any) =>
                              handleFormField(e, subItem?.id)
                            }
                            value={subItem?.value}
                            additionalSxProperties={{ width: "50%" }}
                          />
                        ) : (
                          <Typography variant="body2">
                            {subItem.value}
                          </Typography>
                        )}
                      </div>
                    ))
                  : null
              )}
          </div>
          {/* <TableContainer style={{ maxWidth: "100%" }}> */}
            <table style={{borderCollapse: 'collapse'}}>
              <thead>
                <tr>
                  <td
                    key="1"
                    align="center"
                    colSpan={formEditable ? 5 : firstColspan}
                    style={{
                      borderRight:"1px solid #c8c8c8",
                      ...cellStyle
                    }}
                  >
                    {t("dnet.order.text")}
                  </td>
                  <td
                    key="2"
                    align="center"
                    colSpan={formEditable ? 4 : 9}
                    style={cellStyle}
                  >
                    {t("dnet.delivery.text")}
                  </td>
                </tr>
                <tr>
                  {tableHeader.map((column: any) => {
                    return column?.id === "model" ? (
                      <td
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, ...cellStyle }}
                      >
                        {column.label}
                      </td>
                    ) : column?.id === "productCategory" ||
                      column?.id === "eanCode" ||
                      column?.id === "reqTotalQty" ||
                      column?.id === "confirmedQty" ||
                      column?.id === "openQty" ||
                      column?.id === "plannedDispatchDate" ||
                      column?.id === "actualDispatchDate" ||
                      column?.id === "plannedDeliveryDate" ||
                      column?.id === "readyToDeliverQty" ||
                      column?.id === "carierRefNo" ||
                      column?.id === "invoiceNo" ? (
                      <td
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, ...cellStyle }}
                      >
                        {column.label}
                      </td>
                    ) : column?.id === "customerMaterialNumber" ? (
                      contextDetails?.customerMaterialNumberEnabled && (
                        <td
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, ...cellStyle }}
                        >
                          {column.label}
                        </td>
                      )
                    ) : column?.id === "unitPrice" ? (
                      contextDetails?.priceVisible && (
                        <td
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            ...cellStyle
                          }}
                        >
                          {column.label}
                        </td>
                      )
                    ) : column?.id === "netPrice" ? (
                      <td
                        key={column.id}
                        align={column.align}
                        style={{ 
                          minWidth: column.minWidth, 
                          ...cellStyle 
                        }}
                      >
                        {column.label}
                      </td>
                    ) : column?.id === "specialPrice" ? (
                      contextDetails?.isSpecialPriceEnabled && (
                        <td
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, ...cellStyle }}
                        >
                          {column.label}
                        </td>
                      )
                    ) : column?.id === "rejectCode" ? (
                      contextDetails?.viewRejectedOrdersEnabled && (
                        <td
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, ...cellStyle }}
                        >
                          {column.label}
                        </td>
                      )
                    ) : column?.id === "lockStatus" ? (
                      <td
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, ...cellStyle }}
                      >
                        <Tooltip
                          title={column?.label}
                          arrow
                          placement="left"
                          enterTouchDelay={0}
                        >
                          <IconButton>
                            <LockIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      </td>
                    ) : column?.id === "blockStatus" ? (
                      <td
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, ...cellStyle }}
                      >
                        <img src={blockedOrder} alt="blockedOrder"/>
                      </td>
                    ) : (
                      <td
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, ...cellStyle }}
                      >
                        {column.label}
                      </td>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {orderDetailsResponse?.orders?.lineItems?.length > 0 ? (
                  orderDetailsResponse?.orders?.lineItems.map(
                    (order: any, key: any) => {
                      return (
                        <>
                          {order?.scheduleLines?.length > 0 ? (
                            order?.scheduleLines?.map(
                              (subElem: any, index: any) => {
                                return (
                                  ((!formEditable && !updateComponent) || 
                                  ((formEditable || updateComponent) &&
                                  subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED))
                                  &&
                                  <tr key={`row-${key}-cell-${index}`}>
                                    {index === 0 && (
                                      <td
                                        rowSpan={
                                          order?.scheduleLines &&
                                          order?.scheduleLines?.length > 1
                                            ? order?.scheduleLines?.length
                                            : 1
                                        }
                                        style={cellStyle}
                                      >
                                        {parseInt(order?.index, 10).toString()}
                                      </td>
                                    )}
                                    {index === 0 && (
                                      <td
                                        rowSpan={
                                          order?.scheduleLines &&
                                          order?.scheduleLines?.length > 1
                                            ? order?.scheduleLines?.length
                                            : 1
                                        }
                                        style={cellStyle}
                                      >
                                        {order?.productCategoryRootPathList
                                          ?.length >= 2
                                          ? order?.productCategoryRootPathList[
                                              order?.productCategoryRootPathList
                                                ?.length - 2
                                            ]
                                          : order?.productSku?.productHierarchy}
                                      </td>
                                    )}
                                    {index === 0 && (
                                      <td
                                        rowSpan={
                                          order?.scheduleLines &&
                                          order?.scheduleLines?.length > 1
                                            ? order?.scheduleLines?.length
                                            : 1
                                        }
                                        style={cellStyle}
                                      >
                                        {order?.productSku?.name}
                                      </td>
                                    )}
                                    {index === 0 &&
                                      contextDetails?.customerMaterialNumberEnabled && (
                                        <td
                                          rowSpan={
                                            order?.scheduleLines &&
                                            order?.scheduleLines?.length > 1
                                              ? order?.scheduleLines?.length
                                              : 1
                                          }
                                          style={cellStyle}
                                        >
                                          {order?.customerMaterialNumber}
                                        </td>
                                      )}
                                    {index === 0 && (
                                      <td
                                        rowSpan={
                                          order?.scheduleLines &&
                                          order?.scheduleLines?.length > 1
                                            ? order?.scheduleLines?.length
                                            : 1
                                        }
                                        style={cellStyle}
                                      >
                                        {order?.productSku?.EANCode}
                                      </td>
                                    )}
                                    {index === 0 && (
                                      <td
                                        rowSpan={
                                          order?.scheduleLines &&
                                          order?.scheduleLines?.length > 1
                                            ? order?.scheduleLines?.length
                                            : 1
                                        }
                                        style={cellStyle}
                                      >
                                        {order?.requestedDeliveryDate}
                                      </td>
                                    )}
                                    {index === 0 && (
                                      <td
                                        rowSpan={
                                          order?.scheduleLines &&
                                          order?.scheduleLines?.length > 1
                                            ? order?.scheduleLines?.length
                                            : 1
                                        }
                                        style={cellStyle}
                                      >
                                        {order?.quantity}
                                      </td>
                                    )}
                                    {index === 0 &&
                                      contextDetails?.priceVisible && (
                                        <td
                                          rowSpan={
                                            order?.scheduleLines &&
                                            order?.scheduleLines?.length > 1
                                              ? order?.scheduleLines?.length
                                              : 1
                                          }
                                          style={cellStyle}
                                        >
                                          {showPrice &&
                                            // currencyFormatter.format(
                                            //   order?.unitPrice?.amount,
                                            //   {
                                            //     code: order?.unitPrice?.currency,
                                            //   }
                                            // )
                                            `${order?.unitPrice?.currency} ${order?.unitPrice?.amount}`}
                                        </td>
                                      )}
                                    {index === 0 && (
                                      <td
                                        rowSpan={
                                          order?.scheduleLines &&
                                          order?.scheduleLines?.length > 1
                                            ? order?.scheduleLines?.length
                                            : 1
                                        }
                                        style={cellStyle}
                                      >
                                        {contextDetails?.priceVisible &&
                                          showPrice &&
                                          // currencyFormatter.format(
                                          //   order?.netValuePrice?.amount,
                                          //   {
                                          //     code: order?.netValuePrice?.currency,
                                          //   }
                                          // )
                                          `${order?.netValuePrice?.currency} ${order?.netValuePrice?.amount}`}
                                      </td>
                                    )}
                                    {index === 0 &&
                                      contextDetails?.isSpecialPriceEnabled && (
                                        <td
                                          rowSpan={
                                            order?.scheduleLines &&
                                            order?.scheduleLines?.length > 1
                                              ? order?.scheduleLines?.length
                                              : 1
                                          }
                                          style={cellStyle}
                                        >
                                          {
                                            order?.specialPrice?.amount
                                              ? order?.specialPrice?.amount
                                              : ""
                                          }
                                        </td>
                                      )}
                                    {index === 0 && (
                                      <td
                                        rowSpan={
                                          order?.scheduleLines &&
                                          order?.scheduleLines?.length > 1
                                            ? order?.scheduleLines?.length
                                            : 1
                                        }
                                        style={cellStyle}
                                      >
                                        {order?.confirmedQuantity}
                                      </td>
                                    )}
                                    {index === 0 && (
                                      <td
                                        rowSpan={
                                          order?.scheduleLines &&
                                          order?.scheduleLines?.length > 1
                                            ? order?.scheduleLines?.length
                                            : 1
                                        }
                                        style={cellStyle}
                                      >
                                        {order?.openQuantity}
                                      </td>
                                    )}
                                    {index === 0 &&
                                      contextDetails?.viewRejectedOrdersEnabled && (
                                        <td
                                          rowSpan={
                                            order?.scheduleLines &&
                                            order?.scheduleLines?.length > 1
                                              ? order?.scheduleLines?.length
                                              : 1
                                          }
                                          style={cellStyle}
                                        >
                                          {order?.rejectionCode !== null
                                            ? ""
                                            : order?.rejectionCode}
                                        </td>
                                      )}
                                    <td style={cellStyle}>
                                      {subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED
                                        ? subElem?.confirmed
                                          ? subElem?.plannedIssueDate === "" ? "" : 
                                            moment(
                                              subElem?.plannedIssueDate
                                            ).format("DD-MMM-YYYY")
                                          : t("dnet.unconfirmed.text")
                                        : ""}
                                    </td>
                                    <td style={cellStyle}>
                                      {subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED &&
                                      subElem?.goodsIssueDate !== ""
                                        ? moment(
                                            subElem?.goodsIssueDate
                                          ).format("DD-MMM-YYYY")
                                        : ""}
                                    </td>
                                    <td style={cellStyle}>
                                      {subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED &&
                                      subElem?.plannedDeliveryDate !== ""
                                        ? moment(
                                            subElem?.plannedDeliveryDate
                                          ).format("DD-MMM-YYYY")
                                        : ""}
                                    </td>
                                    <td style={cellStyle}>
                                      {subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED
                                        ? subElem?.quantity
                                        : ""}
                                    </td>
                                    <td style={cellStyle}>
                                      {subElem?.deliveryNumber}
                                    </td>
                                    <td style={cellStyle}>
                                      {
                                        subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED &&
                                          (subElem?.billOfLadingNumber!=="" &&
                                          subElem?.billOfLadingNumber!==null &&
                                          subElem?.deliveryStatus!=="" &&
                                          subElem?.deliveryStatus!==null
                                            ? contextDetails?.tntUrl !== "" ||
                                              contextDetails?.dpdUrl !== "" ||
                                              contextDetails?.schenkerUrl !== ""
                                              ? subElem?.carrierPartnerName?.toLowerCase() ===
                                                "tnt"
                                                ? subElem?.billOfLadingNumber
                                                : subElem?.carrierPartnerName?.toLowerCase() ===
                                                  "direct"
                                                ? subElem?.billOfLadingNumber
                                                : subElem?.carrierPartnerName?.toLowerCase() ===
                                                    "schenker" &&
                                                  subElem?.deliveryNumber
                                                ? subElem?.deliveryNumber
                                                : subElem?.billOfLadingNumber
                                              : subElem?.billOfLadingNumber
                                            : !subElem?.billOfLadingNumber &&
                                              subElem?.deliveryNumber &&
                                              subElem?.deliveryStatus &&
                                              contextDetails?.schenkerUrl!=="" &&
                                              subElem?.carrierPartnerName ===
                                                "schenker" &&
                                              subElem?.deliveryNumber)
                                      }
                                    </td>
                                    <td style={cellStyle}>
                                      {subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED
                                        ? subElem?.invoiceNumber
                                        : ""}
                                    </td>
                                    <td style={cellStyle}>
                                      {subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED &&
                                      subElem?.deliveryStatus!=="" &&
                                      subElem?.deliveryStatus!==null ? (
                                        <IconButton>
                                          <LockIcon color="primary" />
                                        </IconButton>
                                      ) : (
                                        <img src={spacer} alt="spacer"/>
                                      )}
                                    </td>
                                    <td style={cellStyle}>
                                      {subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED &&
                                      subElem?.documentHeader !== "" &&
                                      subElem?.documentHeader !== null ? (
                                        <img
                                          src={blockedOrder}
                                          alt={
                                            t(
                                              "dnet.blocked_delivery_alttext.text"
                                            )?.toString()
                                          }
                                        />
                                      ) : subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED && (
                                        <img src={spacer} alt="spacer"/>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          ) : (
                            <tr
                              tabIndex={-1}
                              key={`row-${key}`}
                            >
                              {
                                <td
                                  rowSpan={
                                    order?.scheduleLines &&
                                    order?.scheduleLines?.length > 1
                                      ? order?.scheduleLines?.length
                                      : 1
                                  }
                                  style={cellStyle}
                                >
                                  {parseInt(order?.index, 10).toString()}
                                </td>
                              }
                              <td
                                rowSpan={
                                  order?.scheduleLines &&
                                  order?.scheduleLines?.length > 1
                                    ? order?.scheduleLines?.length
                                    : 1
                                }
                                style={cellStyle}
                              >
                                {order?.productCategoryRootPathList?.length >= 2
                                  ? order?.productCategoryRootPathList[
                                      order?.productCategoryRootPathList
                                        ?.length - 2
                                    ]
                                  : order?.productSku?.productHierarchy}
                              </td>
                              {
                                <td
                                  rowSpan={
                                    order?.scheduleLines &&
                                    order?.scheduleLines?.length > 1
                                      ? order?.scheduleLines?.length
                                      : 1
                                  }
                                  style={cellStyle}
                                >
                                  {order?.productSku?.name}
                                </td>
                              }
                              {contextDetails?.customerMaterialNumberEnabled && (
                                <td
                                  rowSpan={
                                    order?.scheduleLines &&
                                    order?.scheduleLines?.length > 1
                                      ? order?.scheduleLines?.length
                                      : 1
                                  }
                                  style={cellStyle}
                                >
                                  {order?.customerMaterialNumber}
                                </td>
                              )}
                              <td
                                rowSpan={
                                  order?.scheduleLines &&
                                  order?.scheduleLines?.length > 1
                                    ? order?.scheduleLines?.length
                                    : 1
                                }
                                style={cellStyle}
                              >
                                {order?.productSku?.EANCode}
                              </td>
                              <td
                                rowSpan={
                                  order?.scheduleLines &&
                                  order?.scheduleLines?.length > 1
                                    ? order?.scheduleLines?.length
                                    : 1
                                }
                              >
                                {order?.requestedDeliveryDate}
                              </td>
                              <td
                                rowSpan={
                                  order?.scheduleLines &&
                                  order?.scheduleLines?.length > 1
                                    ? order?.scheduleLines?.length
                                    : 1
                                }
                                style={cellStyle}
                              >
                                {order?.quantity}
                              </td>
                              {contextDetails?.priceVisible && (
                                <td
                                  rowSpan={
                                    order?.scheduleLines &&
                                    order?.scheduleLines?.length > 1
                                      ? order?.scheduleLines?.length
                                      : 1
                                  }
                                  style={cellStyle}
                                >
                                  {showPrice &&
                                    `${order?.unitPrice?.currency} ${order?.unitPrice?.amount}`}
                                </td>
                              )}
                              <td
                                rowSpan={
                                  order?.scheduleLines &&
                                  order?.scheduleLines?.length > 1
                                    ? order?.scheduleLines?.length
                                    : 1
                                }
                                style={cellStyle}
                              >
                                {contextDetails?.priceVisible &&
                                  showPrice &&
                                  `${order?.netValuePrice?.currency} ${order?.netValuePrice?.amount}`}
                              </td>
                              {contextDetails?.isSpecialPriceEnabled && (
                                <td
                                  rowSpan={
                                    order?.scheduleLines &&
                                    order?.scheduleLines?.length > 1
                                      ? order?.scheduleLines?.length
                                      : 1
                                  }
                                  style={cellStyle}
                                >
                                  {
                                    order?.specialPrice?.amount
                                      ? order?.specialPrice?.amount
                                      : ""
                                  }
                                </td>
                              )}
                              <td
                                rowSpan={
                                  order?.scheduleLines &&
                                  order?.scheduleLines?.length > 1
                                    ? order?.scheduleLines?.length
                                    : 1
                                }
                                style={cellStyle}
                              >
                                {order?.confirmedQuantity}
                              </td>
                              <td
                                rowSpan={
                                  order?.scheduleLines &&
                                  order?.scheduleLines?.length > 1
                                    ? order?.scheduleLines?.length
                                    : 1
                                }
                                style={cellStyle}
                              >
                                {order?.openQuantity}
                              </td>
                              {contextDetails?.viewRejectedOrdersEnabled && (
                                <td
                                  rowSpan={
                                    order?.scheduleLines &&
                                    order?.scheduleLines?.length > 1
                                      ? order?.scheduleLines?.length
                                      : 1
                                  }
                                  style={cellStyle}
                                >
                                  {order?.rejectionCode !== null
                                    ? ""
                                    : order?.rejectionCode}
                                </td>
                              )}
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </tr>
                          )}
                        </>
                      );
                    }
                  )
                ) : (
                  <tr>
                    <td
                      colSpan={tableHeader.length}
                      align={"center"}
                      className={styles.noRecFound}
                      style={cellStyle}
                    >
                      {t("gzl.no_data_available.text")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          {/* </TableContainer> */}
          <div className={styles.endMsg}>
            <Typography variant="body2">{endMsg}</Typography>
          </div>
        </Box>
      </div>
    );
  };

  const [firstColspan, setFirstColspan] = useState(9);

  useEffect(() => {
    dispatch(editOrderReportSuccess({}))
    dispatch(getContextDetails({key: CONTEXT_ORDER?.ORDER_STATUS_PAGE}))
    let colspanValue = 9;
    if (contextDetails?.customerMaterialNumberEnabled) colspanValue++;
    if (contextDetails?.priceVisible) colspanValue++;
    if (contextDetails?.viewRejectedOrdersEnabled) colspanValue++;
    if (contextDetails?.isSpecialPriceEnabled) colspanValue++;
    setFirstColspan(colspanValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contextDetails?.key === CONTEXT_ORDER?.ORDER_STATUS_PAGE)
      dispatch(
        getOrderDetails({ 
          internetOrder: internetOrderId 
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextDetails])

  const handleFormField = (e: any, id: any) => {
    const orderArray = [...orderDetails];
    if (id === "emailCheckbox") {
      for (let i = 0; i < orderArray?.length; i++) {
        if (
          orderArray[i]?.id === "sendEmail" || 
          orderArray[i]?.id === "sendEmailToSalesRepresntative"
        ) {
          orderArray[i].checked = e.target.checked;
        }
      }
    } else if (id === "sendEmailToSalesRepresntative") {
      for (let i = 0; i < orderArray?.length; i++) {
        if (
          orderArray[i]?.id === "sendEmailToSalesRepresntative"
        ) {
          orderArray[i].emailCheck = e.target.checked;
        }
      }
    } else if (id === "sendEmail") {
      for (let i = 0; i < orderArray?.length; i++) {
        if (orderArray[i]?.id === "sendEmail") {
          orderArray[i].emailList = e.target.value.split(",");
          orderArray[i].error = false
        }
      }
    } else {
      for (let i = 0; i < orderArray?.length; i++) {
        if (orderArray[i]?.id === id) {
          orderArray[i].value = e.target.value;
        }
      }
    }
    setOrderStatusErrorText("");
    setOrderDetails(orderArray);
  };

  const handleUpdate = (
    orderReport: any,
    orderStatusError: any,
    qtyError: any
  ) => {
    let referenceBlock = orderDetails?.filter((item: any) => {
      return item?.id === "customerReference";
    });

    if (referenceBlock[0]?.value === "") {
      setOrderStatusErrorText(t("dnet.enter_reference.text").toString());
    } else if (
      orderStatusError !== "" &&
      referenceBlock[0]?.value === orderDetailsResponse?.orders?.customerReference
    ) {
      setOrderStatusErrorText(orderStatusError);
    } else if (Object.keys(qtyError)?.length === 0) {
      setNewUpdatedOrder(orderReport)
      setUpdateComponent(true);
      // setFormEditable(!formEditable);
    }
  };

  const confirmHandler = () => {
    let newCustomerReferenceObject = orderDetails?.filter((list: any) => list?.id === "customerReference");
    let salesRepObj = orderDetails?.filter((list: any) => list?.id === "sendEmailToSalesRepresntative");
    let sendEmailObject = orderDetails?.filter((list: any) => list?.id === "sendEmail");
    let newPayerTo = paymentTermEnabled?.filter((list: any) => list?.id === "payer")
    let lineItems: any = [];
    let errorMsg = false;
    if (sendEmailObject[0]?.emailList?.length > 0 ) {
      for(let i=0; i<sendEmailObject[0]?.emailList?.length; i++){
        if (!NEW_EMAIL_REGEX.test(sendEmailObject[0]?.emailList?.[i])) {
          errorMsg = true
          break;
        }
      }
    }
    if (errorMsg) {
      const orderArray = [...orderDetails];
      for (let i = 0; i < orderArray?.length; i++) {
        if (orderArray[i]?.id === "sendEmail") {
          orderArray[i].emailList = [loginDetails?.profile?.email];
          orderArray[i].error = true;
        }
      }
      setOrderDetails(orderArray);
    } else {
      newUpdatedOrder?.lineItems?.forEach((lineItem: any, key: any) => {
        if(lineItem?.isDelete || moment(lineItem?.requestedDeliveryDate).format(
          "DD-MM-YYYY"
        ) !==
          moment(orderDetailsResponse?.orders?.lineItems?.[key]?.requestedDeliveryDate).format(
            "DD-MM-YYYY"
          ) || lineItem?.quantity !== orderDetailsResponse?.orders?.lineItems?.[key]?.quantity) 
        {
            let orderRddChanged: any = moment(lineItem?.requestedDeliveryDate).format(
              "DD-MM-YYYY"
            ) !==
              moment(orderDetailsResponse?.orders?.lineItems?.[key]?.requestedDeliveryDate).format(
                "DD-MM-YYYY"
              )
            lineItems?.push({
              "isEditable": true,
              "index": lineItem?.index,
              "isRequestedDeliveryDateChanged": orderRddChanged,
              "newRequestedDeliveryDate": moment(lineItem?.requestedDeliveryDate).format(
                "YYYY-MM-DD"
              ),
              "requestedDeliveryDate": moment(
                orderDetailsResponse?.orders?.lineItems?.[key]?.requestedDeliveryDate
              ).format(
                "YYYY-MM-DD"
              ),
              "productSku": {
                  "name": lineItem?.productSku?.name
              },
              "isQuantityChanged": lineItem?.quantity !== orderDetailsResponse?.orders?.lineItems?.[key]?.quantity,
              "oldQuantity": orderDetailsResponse?.orders?.lineItems?.[key]?.quantity,
              "newQuantity": lineItem?.quantity,
              "isDelete": lineItem?.isDelete,
            })
          }
      })
      let shoppingCart= {
          "orderNumber": orderDetailsResponse?.orders?.orderNumber,
          "oldPayerToAccountId": {
              "number": orderDetailsResponse?.orders?.payerToAccount?.number
          },
          "payerToAccountId": {
              "number": newPayerTo[0]?.item
          },
          "newCustomerReference": newCustomerReferenceObject[0]?.value,
          "customerReference": orderDetailsResponse?.orders?.customerReference,
          "paymentTermId": orderDetailsResponse?.orders?.paymentTerm?.name,
          "maxIndex": orderDetailsResponse?.orders?.maxIndex,
          "lineItems": lineItems,
      }
      let isSendEmail= sendEmailObject[0]?.checked;
      let emailAddress= sendEmailObject[0]?.emailList;
      if (
        contextDetails?.salesRepContacts?.length > 0 && 
        contextDetails?.isSalesRepresentative &&
        salesRepObj[0]?.emailCheck &&
        sendEmailObject[0]?.checked
        ) {
          emailAddress = [...emailAddress, ...contextDetails?.salesRepContacts]
      }
      // API call to update data
      dispatch(
        editOrderReport({
          shoppingCart,
          isSendEmail,
          emailAddress
        })
      )
      setUpdateComponent(false);
      setFormEditable(!formEditable);
    }
  };

  const editReportSuccessResponse = useSelector(
    (state: any) => state?.reportingReducer?.editReportSuccessResponse
  )

  useEffect(() => {
    if(editReportSuccessResponse?.success && editReportSuccessResponse?.data?.success) {
      dispatch(getOrderDetailsSuccess({}))
      dispatch(getOrderDetails({ internetOrder: internetOrderId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editReportSuccessResponse])

  const modifyHandler = () => {
    setUpdateComponent(false);
  };

  const handleChangeShippingAgent = (value: any) => {
    let orderDetailsTemp: any = [...orderDetails];
    orderDetailsTemp?.map((list: any) => {
      return list?.id==="shipingAgent" ? list.item = value : null
    })
    setOrderDetails(orderDetailsTemp);
  }

  return (
    <div>
      <Typography
        variant="subtitle1"
        className={styles.header}
        pb={3}
        color="primary"
      >
        {!formEditable
          ? t("dnet.order_status.text")
          : updateComponent
          ? t("dnet.order_amend_confirmation.text")
          : t("dnet.edit_order_title.text")}
      </Typography>
      {orderStatusErrorText && (
        <Typography variant="body2" mb={1} className={styles.errorMsg}>
          {orderStatusErrorText}
        </Typography>
      )}
      {Object.keys(orderDetailsResponse)?.length > 0 && 
        <Box className={styles.boxContainer}>
          <div>
            {orderDetails?.length > 0 &&
              orderDetails?.map((item: any) =>
                item?.id !== "paymentTermEnabled"
                  ? formEditable
                    ? item?.edit &&
                      ((item?.id === "sendEmail" && updateComponent) ||
                        item?.id !== "sendEmail") &&
                      ((item?.id === "sendEmailToSalesRepresntative" && 
                        updateComponent && item?.checked) ||
                        item?.id !== "sendEmailToSalesRepresntative") &&
                      ((item?.id === "shipingAgent" &&
                        item?.listItems?.length > 0
                        ) ||
                        item?.id !== "shipingAgent") &&
                      ((item?.id === "shipToAccount" &&
                        Object.keys(orderDetailsResponse?.orders?.shipToAccount)?.length >
                          0 &&
                        orderDetailsResponse?.orders?.shipToAccount !== null) ||
                        item?.id !== "shipToAccount") && (
                        <div className={styles.orderBox}>
                          <Typography
                            variant="body2"
                            className={styles.textWidth}
                          >
                            {item?.id === "creationDate"
                              ? t("dnet.order_date.text")
                              : item?.name}
                          </Typography>
                          <div>:</div>
                          {item?.id === "customerReference" &&
                          !updateComponent ? (
                            <CustomTextField
                              label=""
                              changeHandler={(e: any) =>
                                handleFormField(e, item?.id)
                              }
                              value={item.value}
                              // additionalSxProperties={{ width: "50%" }}
                            />
                          ) : item?.id === "creationDate" ? (
                            <Typography variant="body2">
                              {moment(item.value).format("MMM DD,YYYY")}
                            </Typography>
                          ) : item?.id === "sendEmailToSalesRepresntative" && item?.checked && updateComponent ? (
                            <div className={styles.sendEmailBox}>
                              <Checkbox
                                id="sendEmailToSalesRepresntativeCheckbox"
                                checked={item?.emailCheck}
                                onChange={(e: any) => {
                                  handleFormField(e, "sendEmailToSalesRepresntative");
                                }}
                                size="small"
                                sx={{ padding: 0, marginRight: "10px" }}
                              />
                              <div>
                                { item?.emailList?.length > 0 ? 
                                  item?.emailList?.map((listItem: any) => 
                                    <Typography variant="body2">{listItem}</Typography>
                                  ) :
                                <Typography variant="body2">{item?.emptyText}</Typography>
                                }
                              </div>
                            </div>
                          ) : item?.id === "sendEmail" && updateComponent ? (
                            <div className={styles.sendEmailBox}>
                              <Checkbox
                                id="emailCheckbox"
                                checked={item?.checked}
                                onChange={(e: any) => {
                                  handleFormField(e, "emailCheckbox");
                                }}
                                size="small"
                                sx={{ padding: 0, marginRight: "10px" }}
                              />
                              {item?.checked && (
                                <div>
                                  <CustomTextField
                                    id="emailList"
                                    label={t("dnet.to.text")}
                                    value={item?.emailList?.join(",")}
                                    changeHandler={(e: any) => {
                                      handleFormField(e, item?.id);
                                    }}
                                    helperText={item?.error && t("dnet.invalid_email_address.text")}
                                    error={item?.error}
                                  />
                                  <Tooltip
                                    title={t(
                                      "dnet.separate_multiple_addresses.text"
                                    )}
                                    arrow
                                    placement="right"
                                    enterTouchDelay={0}
                                  >
                                    <IconButton className={styles.infoIcon}>
                                      <InfoOutlinedIcon color="action" />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              )}
                            </div>
                          ) : (
                            <Typography variant="body2">{item.value}</Typography>
                          )}
                        </div>
                      )
                    : ((item?.id === "sendEmail" && updateComponent) ||
                      (item?.id !== "sendEmail")) &&
                      ((item?.id !== "sendEmailToSalesRepresntative")) &&
                      ((item?.id === "shipingAgent" &&
                        item?.listItems?.length > 0
                        ) ||
                        item?.id !== "shipingAgent") && (
                        <div className={styles.orderBox}>
                          <Typography
                            variant="body2"
                            className={styles.textWidth}
                          >
                            {item.name}
                          </Typography>
                          <div>:</div>
                          {
                            item?.id === "shipingAgent" && item?.listItems?.length > 1 ?
                            <CustomDropDown
                              id="shippingAgent"
                              value={item?.item}
                              menuOptions={item?.listItems}
                              module="debitNoteCreation"
                              label=""
                              // width="90%"
                              handleChange={(e: any) => {
                                handleChangeShippingAgent(e.target.value);
                              }}
                            /> :
                            <Typography variant="body2">{item.value}</Typography>
                          }
                        </div>
                      )
                  : contextDetails?.isPaymentTermEnabled
                  ? paymentTermEnabled?.length > 0 &&
                    paymentTermEnabled?.map((subItem: any) => (
                      <div className={styles.orderBox}>
                        <Typography variant="body2" className={styles.textWidth}>
                          {subItem.name}
                        </Typography>
                        <div>:</div>
                        {formEditable &&
                        subItem?.edit &&
                        subItem?.id === "paymentTerm" &&
                        !updateComponent ? ( 
                          <CustomTextField
                            label=""
                            changeHandler={(e: any) =>
                              handleFormField(e, subItem?.id)
                            }
                            value={subItem?.value}
                            additionalSxProperties={{ width: "50%" }}
                          />
                        ) : subItem?.id === "payer" && subItem?.listItems?.length > 1 ? (
                            <CustomDropDown
                              id="payerToAccounts"
                              value={item?.item}
                              menuOptions={item?.listItems}
                              module="debitNoteCreation"
                              label=""
                              // width="90%"
                              handleChange={(e: any) => {
                                handlePayerToAccounts(e.target.value);
                              }}
                            />
                        ) : (
                          <Typography variant="body2">{subItem.value}</Typography>
                        )}
                      </div>
                    ))
                  : null
              )}
          </div>
          {!formEditable && (
            <div className={styles.printDesign}>
              <CustomButton
                id="back"
                label={t("dnet.back_to_search_results.text")}
                onClick={() => {
                  navigate(`/${ROUTES_LABEL?.REPORTING}`, {
                    state: {
                      reportingSearch: "true",
                    }
                  })
                }}
              />
              {contextDetails?.isAdvancedTransactor &&
                contextDetails?.isAmendOrderEnabled &&
                orderDetailsResponse?.orders?.orderType === "TA" && (//Means editable
                  <CustomButton
                    id="edit"
                    label={t("dnet.edit_order_title.text")}
                    onClick={() => {
                      setFormEditable(!formEditable);
                      setUpdateComponent(false);
                    }}
                    color="primary"
                  />
                )}
              <CustomButton
                id="printButton"
                label={t("dnet.printer_friendly.text")}
                onClick={handlePrint}
                color="primary"
              />
              <div style={{ display: "none" }}>
                <div ref={componentRef} style={{ padding: "22px" }}>
                  {createTable()}
                </div>
              </div>
            </div>
          )}
          <Box className={styles.tableContainer} style={{ width: "100%" }}>
            <OrderDetailsTable
              formEditable={formEditable}
              handleUpdate={handleUpdate}
              updateComponent={updateComponent}
              modifyHandler={modifyHandler}
              confirmHandler={confirmHandler}
              handleShowPrice={handleShowPrice}
              showPrice={showPrice}
            />
          </Box>
          {formEditable && !updateComponent && (
            <div style={{ display: "block" }}>
              <Typography variant="body2" mb={1}>
                {t("dnet.rdd_explanation.text")}
              </Typography>
              <Typography variant="body2">
                {t("dnet.price_explanation.text")}
              </Typography>
            </div>
          )}
          <div className={styles.endMsg}>
            <Typography variant="body2">{endMsg}</Typography>
          </div>
        </Box>
      }
    </div>
  );
};
