import React from 'react';
import {useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSelector } from "react-redux";
import styles from './paidItemDetailTable.module.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from "moment";
var currencyFormatter = require('currency-formatter');



const PaidItemDetailTable = () => {
  const { t } = useTranslation();
  const [rows, setRows] = useState<any>([])
  const paidItemDetailTableData = useSelector(
    (state: any) => state?.gazelleReducer?.paidItemDetailsData
  );

    interface Column {
      id: 'paymentDoct1' | 'paymentDate' | 'paymentAmount' | 'allocatedAmount';
      label: string;
      minWidth?: number;
      align?: 'right';
      format?: (value: number) => string;
    }
    
    const columns: readonly Column[] = [
      {id: 'paymentDoct1', label: t("gzl.payments.text")},
      {id: 'paymentDate', label: t("gzl.paid_on.text")},
      {id: 'paymentAmount', label: t("gzl.payment_amount.text")},
      {id: 'allocatedAmount', label: t("gzl.used.text")},
    ];
    
    interface Data {
      paymentDoct1: any,
      paymentDate: any,
      paymentAmount: any,
      allocatedAmount: any,
    }

  function createData(
    paymentDoct1: any,
    paymentDate: any,
    paymentAmount: any,
    allocatedAmount: any,
  ): Data {
    return { paymentDoct1, paymentDate, paymentAmount, allocatedAmount};
  }

  const createRowData=()=>{
    // Rows creation from API response
    let data = paidItemDetailTableData
    let rowsdata = []
    rowsdata.push(
      createData(
        data?.paymentDoct1, 
        moment(data?.paymentDate).format("DD.MM.YYYY"), 
        data?.paymentAmount, 
        data?.allocatedAmount
      )
    )
    setRows([...rowsdata])
  }

  useEffect(()=>{
    createRowData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[paidItemDetailTableData])

    return (
        <>
          <TableContainer component={Paper} className={styles.tableContainerHeader}>
            <Table sx={{ minWidth: 650 }} aria-label="Paid Item Detail Table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                        {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  paidItemDetailTableData?.constructor === Object && 
                  Object.keys(paidItemDetailTableData)?.length > 0 && 
                  rows?.length > 0 ?rows.map((row:any, key:any) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return column?.id === "paymentAmount" ? (
                            <TableCell key={column.id} align={column.align} >
                                <Link 
                                    className={styles.colorBlue}
                                    to="/Payment_Details" 
                                    state={{referenceId: paidItemDetailTableData.paymentReferenceId}}
                                >
                                    {value}&nbsp;
                                    {currencyFormatter.findCurrency(paidItemDetailTableData?.currency)?.symbol}
                                </Link>
                            </TableCell>
                          ):column?.id === "allocatedAmount" ? (
                            <TableCell key={column.id} align={column.align} >
                                    {value}&nbsp;
                                    {currencyFormatter.findCurrency(paidItemDetailTableData?.currency)?.symbol}
                            </TableCell>
                          ): (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  }):<TableRow>
                          <TableCell colSpan={columns.length} align={"center"} className={styles.noRecFound}>
                            {t("gzl.no_data_available.text")}
                          </TableCell>
                    </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );
}

export default PaidItemDetailTable;