import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getPriceChangeData } from "../../state/actions";
import CustomButton from "../atoms/customButton";
import styles from "./priceChangeReport.module.scss";
import moment from "moment";

export const PriceChangeReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const priceChangeData = useSelector(
    (state: any) => state?.priceChangeReducer?.priceChangeData
  );

  const submitHandler = () => {
    dispatch(
      getPriceChangeData({
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(toDate).format("YYYY-MM-DD"),
      })
    );
  };
  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  )
  const isInternalAdmin = currentDealer?.accountFlag === 0 ? true: false;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  let previous1Months = new Date();
  previous1Months.setMonth(previous1Months.getMonth() - 1);
  const [fromDate, setFromDate] = React.useState(previous1Months);
  const [toDate, setToDate] = React.useState(new Date());
  const [maxToDate, setMaxToDate] = useState(new Date());
  const [minToDate, setMinToDate] = useState<any>(undefined);
  const handleFromDateChange = (newDateVal: any) => {
    if (!alteredToDate) setAlteredToDate(true);
    setFromDate(new Date(newDateVal?.$d.getTime()));
    setMinToDate(newDateVal);
    const after3Months = new Date(newDateVal?.$d.getTime());
    after3Months.setMonth(after3Months.getMonth() + 3);

    if (after3Months > new Date()) {
      setMaxToDate(new Date());
      setToDate(new Date());
    } else {
      setMaxToDate(after3Months);
      setToDate(after3Months);
    }
  };

  const [alteredToDate, setAlteredToDate] = useState(false);
  const handleToDateChange = (newDateVal: any) => {
    setToDate(new Date(newDateVal?.$d.getTime()));
    if (!alteredToDate) {
      const before3Months = new Date(newDateVal?.$d.getTime());
      before3Months.setMonth(before3Months.getMonth() - 3);
      setFromDate(before3Months);
      setMinToDate(before3Months);
      setMaxToDate(newDateVal);
      setAlteredToDate(true);
    }
  };

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  //on load dispatches an event to fetch the details of price changes
  useEffect(() => {
    dispatch(
      getPriceChangeData({
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(toDate).format("YYYY-MM-DD"),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography
        variant="subtitle1"
        className={styles.header}
        pb={3}
        color="primary"
      >
        {t("priceChanges.priceChangeReport.title")}
      </Typography>
      <Box className={styles.boxContainer}>
        <div className={styles.datePickerDiv}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disableFuture
              label={t("priceChange.from.text")}
              openTo="day"
              views={["year", "month", "day"]}
              value={fromDate}
              onChange={(newValue: any) => {
                handleFromDateChange(newValue);
              }}
              renderInput={(params) => (
                <TextField size="small" onKeyDown={onKeyDown} {...params} />
              )}
              className={styles.datePicker}
              inputFormat="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </div>
        <div className={styles.datePickerDiv}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disableFuture
              label={t("priceChange.to.text")}
              openTo="day"
              views={["year", "month", "day"]}
              value={toDate}
              onChange={(newValue: any) => {
                handleToDateChange(newValue);
              }}
              renderInput={(params) => (
                <TextField size="small" onKeyDown={onKeyDown} {...params} />
              )}
              className={styles.datePicker}
              inputFormat="DD/MM/YYYY"
              maxDate={maxToDate}
              minDate={minToDate}
            />
          </LocalizationProvider>
        </div>
        <Box>
          <CustomButton
            id="submitButton"
            label={t("dnet.submit.text")}
            onClick={submitHandler}
            color="primary"
            margin="0"
          />
        </Box>
      </Box>
      <Box className={styles.tableBox}>
        <TableContainer component={Paper} className={styles.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                {isInternalAdmin && (
                  <>
                    <TableCell>
                      {t("priceChange.sales_organization.text")}
                    </TableCell>
                    <TableCell>
                      {t("dataDownload.dataType.priceListType.text")}
                    </TableCell>
                    <TableCell>
                      {t("dataDownload.dataType.extendedPriceListType.text")}
                    </TableCell>
                    <TableCell>
                      {t("dnet.product_listing_codes.text")}
                    </TableCell>
                  </>
                )}
                <TableCell
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {t("dataDownload.dataType.materialName.text")}
                </TableCell>
                <TableCell>{t("priceChange.changeDate.text")}</TableCell>
                <TableCell>{t("priceChange.price.old.text")}</TableCell>
                <TableCell>{t("priceChange.price.new.text")}</TableCell>
                <TableCell>{t("dnet.currency.text")}</TableCell>
                <TableCell>{t("priceChange.change.text")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {priceChangeData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((product: any, index: number) => (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    key={index}
                  >
                    {isInternalAdmin && (
                      <>
                        <TableCell>{product?.sales_area}</TableCell>
                        <TableCell>{product?.price_list_type}</TableCell>
                        <TableCell>
                          {product?.extended_price_list_type}
                        </TableCell>
                        <TableCell>X00</TableCell>
                      </>
                    )}
                    <TableCell>{product?.material_name}</TableCell>
                    <TableCell>{product?.price_change_date}</TableCell>
                    <TableCell>{product?.old_price}</TableCell>
                    <TableCell>{product?.new_price}</TableCell>
                    <TableCell>{product?.currency}</TableCell>
                    <TableCell>
                      {product?.new_price < product?.old_price ? (
                        <ArrowCircleDownOutlinedIcon
                          className={styles.svg_icons}
                          style={{ color: "green" }}
                        />
                      ) : (
                        <ArrowCircleUpOutlinedIcon
                          className={styles.svg_icons}
                          style={{ color: "red" }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className={styles.pagination}
          component="div"
          count={priceChangeData?.length ? priceChangeData?.length : 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 20]}
        />
      </Box>
    </>
  );
};
