import {
  Checkbox,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTextField from "../../../atoms/customTextField";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CustomDropDown from "../../../atoms/customDropDown";
import styles from "./selectionCriteria.module.scss";
import { useSelector } from "react-redux";

export const SelectionCriteria = ({
  formData,
  handleFormData,
  handleCheckBox,
  checkBoxIDs,
  formEditable,
  parent,
}: any) => {
  const { t } = useTranslation();
  const orderType = [
    {value: "salesOrders", label: t("dnet.sales_orders_only.text")?.toString()},
    {value: "returnOrders", label: t("dnet.returns_only.text")?.toString()},
    {value: "both", label: t("dnet.sales_returns_orders.text")?.toString()},
  ];
  const contextDetails = useSelector(
    (state: any) => state?.commonReducer?.contextDetails
  );

  const [shipToAccounts, setShipToAccounts] = useState([]);
  const [rejectedOrderCodes, setRejectedOrderCodes] = useState([]);
  useEffect(() => {
    let shipToAccountsArr: any = [];
    if (contextDetails?.shipToAccounts?.length > 0) {
      if (contextDetails?.shipToAccounts?.length > 1) {
        shipToAccountsArr?.push({
          value: "All",
          label: t("dnet.all_addresses.text")?.toString(),
        });
      }
      contextDetails?.shipToAccounts?.forEach((acc: any) => {
        shipToAccountsArr?.push({
          value: acc?.number,
          label: `${acc?.number} - ${acc?.name}, ${acc?.address?.street}, ${acc?.address?.townCity}, (${acc?.address?.country})`,
        });
      });
    }
    setShipToAccounts(shipToAccountsArr);
    if (shipToAccountsArr?.length === 1) {
      handleFormData(shipToAccountsArr[0]?.["value"], "shipToAccountId");
    }

    if (contextDetails?.rejectionCodes) {
      let rejectCodes: any = contextDetails?.rejectionCodes?.split(",");
      let rejectCodeStringArray: any = [
        {
          value: "99",
          label: t("dnet.all.text")?.toString(),
        },
      ];
      rejectCodes?.forEach((elem: any) => {
        rejectCodeStringArray?.push({
          value: elem,
          label: elem + "-" + t(`dnet.rejection_description_${elem}.text`),
        });
      });
      setRejectedOrderCodes(rejectCodeStringArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextDetails]);

  const estimatedDays = [
    {
      value: 1,
      label: t("dnet.1day.text"),
    },
    {
      value: 2,
      label: t("dnet.2days.text"),
    },
    {
      value: 3,
      label: t("dnet.3days.text"),
    },
    {
      value: 4,
      label: t("dnet.4days.text"),
    },
    {
      value: 5,
      label: t("dnet.5days.text"),
    },
    {
      value: 6,
      label: t("dnet.6days.text"),
    },
    {
      value: 7,
      label: t("dnet.1week.text"),
    },
    {
      value: 14,
      label: t("dnet.2weeks.text"),
    },
    {
      value: 21,
      label: t("dnet.3weeks.text"),
    },
    {
      value: 30,
      label: t("dnet.1month.text"),
    },
    {
      value: 60,
      label: t("dnet.2months.text"),
    },
    {
      value: 180,
      label: t("dnet.6months.text"),
    },
  ];

  const elapsedDays = [
    {
      value: -1,
      label: t("dnet.1day.text"),
    },
    {
      value: -2,
      label: t("dnet.2days.text"),
    },
    {
      value: -3,
      label: t("dnet.3days.text"),
    },
    {
      value: -4,
      label: t("dnet.4days.text"),
    },
    {
      value: -5,
      label: t("dnet.5days.text"),
    },
    {
      value: -6,
      label: t("dnet.6days.text"),
    },
    {
      value: -7,
      label: t("dnet.1week.text"),
    },
    {
      value: -14,
      label: t("dnet.2weeks.text"),
    },
    {
      value: -21,
      label: t("dnet.3weeks.text"),
    },
    {
      value: -30,
      label: t("dnet.1month.text"),
    },
    {
      value: -60,
      label: t("dnet.2months.text"),
    },
    {
      value: -180,
      label: t("dnet.6months.text"),
    },
    {
      value: 0,
      label: t("dnet.all.text"),
    },
  ];
  return (
    <div className={styles.mainContainer}>
      <Typography variant="subtitle2" mb={2}>
        {t("dnet.selection_criteria.text")}
      </Typography>
      <Grid container className={styles.containerMargin}>
        <Grid item md={6} xs={12}>
          <CustomTextField
            id="productSkuId"
            label={t("dnet.model_colon.text")}
            changeHandler={(e: any) => {
              handleFormData(e.target.value, "productSkuId");
            }}
            value={formData?.productSkuId}
            additionalSxProperties={{ width: "90%" }}
            disabled={formEditable}
          />
          <Tooltip
            title={t("dnet.multiple_entry_info.text")}
            arrow
            placement="right"
            enterTouchDelay={0}
          >
            <IconButton className={styles.infoIcon}>
              <InfoOutlinedIcon color="action" />
            </IconButton>
          </Tooltip>
        </Grid>
        {parent !== "OrderChangeReport" ? (
          <Grid item md={6} xs={12} className={styles.checkboxesDesign}>
            <Checkbox
              id="openItems"
              checked={checkBoxIDs.openItems}
              onChange={(e) => handleCheckBox(e)}
              size="small"
              disabled={formEditable}
            />
            <Typography variant="body2">{t("dnet.open_items.text")}</Typography>
          </Grid>
        ) : (
          <Grid item md={6} xs={12} className={styles.checkboxesDesign}>
            <Checkbox
              id="blockedOrders"
              checked={checkBoxIDs.blockedOrders}
              // onChange={(e) => handleCheckBox(e)}
              onChange={(e: any) => {
                handleCheckBox(e);
              }}
              size="small"
              disabled={formEditable}
            />
            <Typography variant="body2">
              {t("dnet.blocked_orders.text")}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        style={
          contextDetails?.customerMaterialNumberEnabled
            ? { marginTop: "25px" }
            : {}
        }
      >
        {contextDetails?.customerMaterialNumberEnabled && (
          <Grid item md={6} xs={12}>
            <CustomTextField
              id="customerMaterialNumber"
              label={t("customerMaterialNumber.text")}
              changeHandler={(e: any) => {
                handleFormData(e.target.value, "customerMaterialNumber");
              }}
              value={formData?.customerMaterialNumber}
              additionalSxProperties={{ width: "90%" }}
              disabled={formEditable}
            />
            <Tooltip
              title={t("dnet.multiple_entry_info.text")}
              arrow
              placement="right"
              enterTouchDelay={0}
            >
              <IconButton className={styles.infoIcon}>
                <InfoOutlinedIcon color="action" />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <Grid container className={styles.containerMargin}>
        <Grid item md={6} xs={12}>
          <CustomTextField
            id="customerReference"
            label={t("dnet.your_ref_colon.text")}
            value={formData?.customerReference}
            additionalSxProperties={{ width: "90%" }}
            changeHandler={(e: any) => {
              handleFormData(e.target.value, "customerReference");
            }}
            disabled={formEditable}
          />
        </Grid>
        {parent !== "OrderChangeReport" && (
          <Grid item md={6} xs={12} className={styles.checkboxesDesign}>
            <Checkbox
              id="elapsedDays"
              checked={checkBoxIDs?.elapsedDays}
              onChange={(e) => handleCheckBox(e)}
              size="small"
              disabled={formEditable}
            />
            <Typography variant="body2" sx={{ marginRight: "12px" }}>
              {t("dnet.despatched_items.text")}
            </Typography>
            <CustomDropDown
              id="dispatchedSinceDate"
              value={formData?.dispatchedSinceDate}
              handleChange={(e: any) => {
                handleFormData(e.target.value, "dispatchedSinceDate");
              }}
              menuOptions={elapsedDays}
              module="debitNoteCreation"
              disabled={formEditable || !checkBoxIDs.elapsedDays}
            />
          </Grid>
        )}
      </Grid>
      <Grid container className={styles.containerMargin}>
        <Grid item md={6} xs={12}>
          <CustomTextField
            id="orderNumbers"
            label={t("dnet.sony_ref.text")}
            value={formData?.orderNumbers}
            additionalSxProperties={{ width: "90%" }}
            changeHandler={(e: any) => {
              handleFormData(e.target.value, "orderNumbers");
            }}
            disabled={formEditable}
          />
          {parent === "OrderChangeReport" && 
            <Tooltip
              title={t("dnet.multiple_entry_info.text")}
              arrow
              placement="right"
              enterTouchDelay={0}
            >
              <IconButton className={styles.infoIcon}>
                <InfoOutlinedIcon color="action" />
              </IconButton>
            </Tooltip>
          }
        </Grid>
        {parent !== "OrderChangeReport" ? (
          <Grid item md={6} xs={12} className={styles.checkboxesDesign}>
            <Checkbox
              id="estimatedDays"
              checked={checkBoxIDs?.estimatedDays}
              onChange={(e: any) => {
                handleCheckBox(e);
              }}
              size="small"
              disabled={formEditable}
            />
            <Typography variant="body2" sx={{ marginRight: "12px" }}>
              {t("dnet.to_be_dispatch_items.text")}
            </Typography>
            <CustomDropDown
              id="plannedDispatchedUntilDate"
              value={formData?.plannedDispatchedUntilDate}
              handleChange={(e: any) => {
                handleFormData(e.target.value, "plannedDispatchedUntilDate");
              }}
              menuOptions={estimatedDays}
              module="debitNoteCreation"
              disabled={formEditable || !checkBoxIDs.estimatedDays}
            />
          </Grid>
        ) : (
          <Grid item md={6} xs={12} className={styles.checkboxesDesign}>
            <Checkbox
              id="onlyRequestedDeliveryDateChanges"
              checked={checkBoxIDs.onlyRequestedDeliveryDateChanges}
              onChange={(e) => handleCheckBox(e)}
              size="small"
              disabled={formEditable}
            />
            <Typography variant="body2">
              {t("dnet.only_requested_delivery_date_changes.text")}
            </Typography>
          </Grid>
        )}
      </Grid>
      {parent !== "OrderChangeReport" && (
        <Grid container className={styles.containerMargin}>
          <Grid item md={6} xs={12}>
            <CustomDropDown
              id="orderType"
              value={formData?.orderType}
              menuOptions={orderType}
              module="debitNoteCreation"
              label={t("dnet.order_type.text")?.toString()}
              width="90%"
              handleChange={(e: any) => {
                handleFormData(e.target.value, "orderType");
              }}
              disabled={formEditable}
            />
          </Grid>
          <Grid item md={6} xs={12} className={styles.checkboxesDesign}>
            <Checkbox
              id="blockedOrders"
              checked={checkBoxIDs.blockedOrders}
              // onChange={(e) => handleCheckBox(e)}
              onChange={(e: any) => {
                handleCheckBox(e);
              }}
              size="small"
              disabled={formEditable}
            />
            <Typography variant="body2">
              {t("dnet.blocked_orders.text")}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid container className={styles.containerMargin}>
        {shipToAccounts?.length > 1 ? (
          <Grid item md={6} xs={12}>
            <CustomDropDown
              id="shipToAccountId"
              value={formData?.shipToAccountId}
              menuOptions={shipToAccounts}
              module="debitNoteCreation"
              // disabled={!checkBoxIDs.estimatedDays}
              label={t("dnet.deliver_to.text")?.toString()}
              width="90%"
              handleChange={(e: any) => {
                handleFormData(e.target.value, "shipToAccountId");
              }}
              disabled={formEditable}
            />
          </Grid>
        ) : (
          <Grid item md={6} xs={12}>
            <Typography variant="body2" sx={{ width: "90%" }}>
              {shipToAccounts[0]?.["label"]}
            </Typography>
          </Grid>
        )}
        {contextDetails?.viewRejectedOrdersEnabled && rejectedOrderCodes?.length > 0 && (
          <Grid item md={6} xs={12} className={styles.checkboxesDesign}>
            <Checkbox
              id="rejectedOrdersEnabled"
              checked={checkBoxIDs?.rejectedOrdersEnabled}
              onChange={(e) => handleCheckBox(e)}
              size="small"
              // onChange={(e: any) => {
              //   handleFormData(e.target.value, "rejectedOrdersEnabled");
              // }}
              disabled={formEditable}
            />
            <Typography variant="body2" sx={{ marginRight: "12px" }}>
              {t("dnet.rejected_orders.text")}
            </Typography>
            <CustomDropDown
              id="rejectedOrderCode"
              sx={{ paddingLeft: "0px" }}
              value={formData?.rejectedOrderCode}
              menuOptions={rejectedOrderCodes}
              disabled={formEditable || !checkBoxIDs.rejectedOrdersEnabled}
              module="debitNoteCreation"
              handleChange={(e: any) => {
                handleFormData(e.target.value, "rejectedOrderCode");
              }}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

SelectionCriteria.defaultProps = {
  formEditable: false,
  parent: "",
};
