import React, { useState, useRef } from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import styles from "./reportsTable.module.scss";
import { useTranslation } from "react-i18next";
import moment from "moment";
//@ts-ignore
import order_both from "../../../../assets/images/order_both.gif";
//@ts-ignore
import order_return from "../../../../assets/images/order_return.gif";
//@ts-ignore
import order_sales from "../../../../assets/images/order_sales.gif";
import LockIcon from "@mui/icons-material/Lock";
import { Link } from "react-router-dom";
import { sortNestedData } from "../../../helper/util";
import { visuallyHidden } from "@mui/utils";
import CustomButton from "../../../atoms/customButton";
//@ts-ignore
import blockedOrder from "../../../../assets/images/blocked_order.jpg";
// @ts-ignore  
import { useReactToPrint } from "react-to-print";
import { downloadExcel } from "../../../helper/util";
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";

export const ReportsTable = ({ 
  initialSort, 
  currentSelectedAddress 
}: any) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<any>(initialSort);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    if(property==="combinedIndex") property= "numericCombinedIndex";
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const reportedFilteredDetails = useSelector(
    (state: any) =>
      state?.reportingReducer?.reportedFilteredDetails?.reportLineItems
  );
  const reportedFilteredPayload = useSelector(
    (state: any) => state?.reportingReducer?.reportedFilteredPayload
  );
  const { t } = useTranslation();

  const contextDetails = useSelector(
    (state: any) => state?.commonReducer?.contextDetails
  );

  interface Column {
    id:
      | "productSku_name"
      | "customerMaterialNumber"
      | "reqTotalQty"
      | "openQty"
      | "firstDeliveryDate"
      | "combinedIndex"
      | "orderType"
      | "customerReference"
      | "rejectionCode"
      | "shipToAccount_number"
      | "readyToDeliverQty"
      | "reportScheduleLines_plannedIssueDate"
      | "reportScheduleLines_goodsIssueDate"
      | "reportScheduleLines_deliveryNumber"
      | "reportScheduleLines_carrierTrackingNumber"
      | "reportScheduleLines_invoiceNumber"
      | "lockStatus"
      | "reportScheduleLines_minShipmentValue";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
  }

  const columns: readonly Column[] = [
    { id: "productSku_name", label: t("dnet.model.text") },
    { id: "customerMaterialNumber", label: t("customerMaterialNumber.text") },
    {
      id: "reqTotalQty",
      label: t("dnet.order_report.requested_total_quantity.text"),
    },
    { id: "openQty", label: t("dnet.order_report.open_quantity.text") },
    {
      id: "firstDeliveryDate",
      label: t("dnet.order_report.requested_date.text"),
    },
    { id: "combinedIndex", label: t("dnet.order_report.sony_ref.text") },
    { id: "orderType", label: t("dnet.order_type.text") },
    { id: "customerReference", label: t("dnet.order_report.your_ref.text") },
    { id: "rejectionCode", label: t("dnet.order_report.rejection_code.text") },
    {
      id: "shipToAccount_number",
      label: t("dnet.order_report.deliver_to.text"),
    },
    {
      id: "readyToDeliverQty",
      label: t("dnet.order_report.ready_to_deliver_quantity.text"),
    },
    {
      id: "reportScheduleLines_plannedIssueDate",
      label: t("dnet.order_report.planned_dispatch_date.text"),
    },
    {
      id: "reportScheduleLines_goodsIssueDate",
      label: t("dnet.order_report.actual_dispatch_date.text"),
    },
    {
      id: "reportScheduleLines_deliveryNumber",
      label: t("dnet.delivery_no.text"),
    },
    {
      id: "reportScheduleLines_carrierTrackingNumber",
      label: t("dnet.carrier_reference_no.text"),
    },
    {
      id: "reportScheduleLines_invoiceNumber",
      label: t("dnet.invoice_no.text"),
    },
    { id: "lockStatus", label: t("dnet.padlock_alttext.text") },
    {
      id: "reportScheduleLines_minShipmentValue",
      label: t("dnet.blocked_delivery_alttext.text"),
    },
  ];
  const sortedData =
    order === "desc"
      ? reportedFilteredDetails?.sort(
          (a: any, b: any) => -sortNestedData(a, b, orderBy)
        )
      : reportedFilteredDetails?.sort((a: any, b: any) =>
          sortNestedData(a, b, orderBy)
        );

  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const createTable = () => {
    const tableHeader = [
      { id: "model", label: t("dnet.model.text") },
      { id: "customerMaterialNumber", label: t("customerMaterialNumber.text") },
      {
        id: "reqTotalQty",
        label: t("dnet.order_report.requested_total_quantity.text"),
      },
      { id: "openQty", label: t("dnet.order_report.open_quantity.text") },
      { id: "reqDate", label: t("dnet.order_report.requested_date.text") },
      { id: "orderNo", label: t("dnet.order_report.sony_ref.text") },
      { id: "orderType", label: t("dnet.order_type.text") },
      { id: "refNo", label: t("dnet.order_report.your_ref.text") },
      { id: "rejectCode", label: t("dnet.order_report.rejection_code.text") },
      { id: "deliverTo", label: t("dnet.order_report.deliver_to.text") },
      {
        id: "readyToDeliverQty",
        label: t("dnet.order_report.ready_to_deliver_quantity.text"),
      },
      {
        id: "plannedDispatchDate",
        label: t("dnet.order_report.planned_dispatch_date.text"),
      },
      {
        id: "actualDispatchDate",
        label: t("dnet.order_report.actual_dispatch_date.text"),
      },
      { id: "deliveryNo", label: t("dnet.delivery_no.text") },
      { id: "carierRefNo", label: t("dnet.carrier_reference_no.text") },
      { id: "invoiceNo", label: t("dnet.invoice_no.text") },
      { id: "lockStatus", label: t("dnet.padlock_alttext.text") },
      { id: "blockedStatus", label: t("dnet.blocked_delivery_alttext.text") },
    ];
    let cellStyle={
      border:"1px solid #c8c8c8"
    }
    return (
      <div>
        <Typography variant="subtitle2">
          {t("dnet.open_orders_deliveries.text")}
        </Typography>
        <Grid container>
          <Grid item xs={2}>
            <Typography variant="body2">
              {t("dnet.to_be_delivered_to.text")}
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="body2">
              {currentSelectedAddress}
            </Typography>
          </Grid>
        </Grid>
        {/* <TableContainer style={{ maxWidth: "100%" }}> */}
          <table style={{borderCollapse: 'collapse'}}>
            <thead>
              <tr>
                <td
                  key="1"
                  align="center"
                  colSpan={
                    contextDetails?.customerMaterialNumberEnabled ? 9 : 8
                  }
                  style={cellStyle}
                >
                  {t("dnet.order.text")}
                </td>
                <td key="2" align="center" colSpan={9} style={cellStyle}>
                  {t("dnet.delivery.text")}
                </td>
              </tr>
              <tr>
                {tableHeader.map((headCell: any) =>
                  headCell?.id === "orderType" ? (
                    <td
                      key={headCell.id}
                      align={headCell.align}
                      style={{ minWidth: headCell.minWidth, ...cellStyle }}
                    >
                      <img src={order_both} alt={headCell.label} />
                    </td>
                  ) : headCell?.id === "lockStatus" ? (
                    <td key={headCell.id} style={cellStyle}>
                      <LockIcon color="primary" />
                    </td>
                  ) : headCell?.id === "blockedStatus" ? (
                    <td key={headCell.id} style={cellStyle}>
                      <img src={blockedOrder} alt={headCell.label} />
                    </td>
                  ) : headCell?.id === "customerMaterialNumber" ? (
                    contextDetails?.customerMaterialNumberEnabled && (
                      <td key={headCell.id} style={cellStyle}>{headCell.label}</td>
                    )
                  ) : headCell?.id === "rejectCode" ? (
                    contextDetails?.viewRejectedOrdersEnabled && (
                      <td key={headCell.id} style={cellStyle}>{headCell.label}</td>
                    )
                  ): (
                    <td key={headCell.id} style={cellStyle}>{headCell.label}</td>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {reportedFilteredDetails?.length > 0 ? (
                reportedFilteredDetails.map((order: any, key: any) => {
                  return (
                    <>
                      {order?.reportScheduleLines ? (
                        order?.reportScheduleLines?.map(
                          (lineItem: any, index: any) => {
                            return (
                              <tr
                                tabIndex={-1}
                                key={`row-${key}-cell-${index}`}
                              >
                                {index === 0 && (
                                  <td
                                    rowSpan={
                                      order?.reportScheduleLines &&
                                      order?.reportScheduleLines?.length > 1
                                        ? order?.reportScheduleLines?.length
                                        : 1
                                    }
                                    style={cellStyle}
                                  >
                                    {order?.productSku?.name}
                                  </td>
                                )}
                                {contextDetails?.customerMaterialNumberEnabled &&
                                  index === 0 && (
                                    <td
                                      rowSpan={
                                        order?.reportScheduleLines &&
                                        order?.reportScheduleLines?.length > 1
                                          ? order?.reportScheduleLines?.length
                                          : 1
                                      }
                                      style={cellStyle}
                                    >
                                      {order?.customerMaterialNumber}
                                    </td>
                                  )}
                                {index === 0 && (
                                  <td
                                    rowSpan={
                                      order?.reportScheduleLines &&
                                      order?.reportScheduleLines?.length > 1
                                        ? order?.reportScheduleLines?.length
                                        : 1
                                    }
                                    style={cellStyle}
                                  >
                                    {order?.quantity}
                                  </td>
                                )}
                                {index === 0 && (
                                  <td
                                    rowSpan={
                                      order?.reportScheduleLines &&
                                      order?.reportScheduleLines?.length > 1
                                        ? order?.reportScheduleLines?.length
                                        : 1
                                    }
                                    style={cellStyle}
                                  >
                                    {order?.openQuantity}
                                  </td>
                                )}
                                {index === 0 && (
                                  <td
                                    rowSpan={
                                      order?.reportScheduleLines &&
                                      order?.reportScheduleLines?.length > 1
                                        ? order?.reportScheduleLines?.length
                                        : 1
                                    }
                                    style={cellStyle}
                                  >
                                    {moment(order?.firstDeliveryDate).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  </td>
                                )}
                                {index === 0 && (
                                  <td
                                    rowSpan={
                                      order?.reportScheduleLines &&
                                      order?.reportScheduleLines?.length > 1
                                        ? order?.reportScheduleLines?.length
                                        : 1
                                    }
                                    style={cellStyle}
                                  >
                                    {`${order?.orderNumber} / ${parseInt(
                                      order?.orderItemNumber,
                                      10
                                    ).toString()}`}
                                  </td>
                                )}
                                {index === 0 && (
                                  <td
                                    rowSpan={
                                      order?.reportScheduleLines &&
                                      order?.reportScheduleLines?.length > 1
                                        ? order?.reportScheduleLines?.length
                                        : 1
                                    }
                                    style={cellStyle}
                                  >
                                    {
                                      <img
                                        src={
                                          reportedFilteredPayload
                                            ?.lineItemReportQuery?.orderType ===
                                          "salesOrders"
                                            ? order_sales
                                            : reportedFilteredPayload
                                                ?.lineItemReportQuery
                                                ?.orderType === "returnOrders"
                                            ? order_return
                                            : order_both
                                        }
                                        alt={
                                          reportedFilteredPayload
                                            ?.lineItemReportQuery?.orderType
                                        }
                                        title={
                                          reportedFilteredPayload
                                            ?.lineItemReportQuery?.orderType
                                        }
                                      />
                                    }
                                  </td>
                                )}
                                {index === 0 && (
                                  <td
                                    rowSpan={
                                      order?.reportScheduleLines &&
                                      order?.reportScheduleLines?.length > 1
                                        ? order?.reportScheduleLines?.length
                                        : 1
                                    }
                                    style={cellStyle}
                                  >
                                    {order?.customerReference}
                                  </td>
                                )}
                                {contextDetails?.viewRejectedOrdersEnabled &&
                                  index === 0 && (
                                    <td
                                      rowSpan={
                                        order?.reportScheduleLines &&
                                        order?.reportScheduleLines?.length > 1
                                          ? order?.reportScheduleLines?.length
                                          : 1
                                      }
                                      style={cellStyle}
                                    >
                                      {order?.rejectionCode &&
                                      order?.rejectionCode !== ""
                                        ? 
                                          order?.rejectionCode
                                        : ""}
                                    </td>
                                  )}
                                {index === 0 && (
                                  <td
                                    rowSpan={
                                      order?.reportScheduleLines &&
                                      order?.reportScheduleLines?.length > 1
                                        ? order?.reportScheduleLines?.length
                                        : 1
                                    }
                                    style={cellStyle}
                                  >
                                    {order?.shipToAccount?.number}
                                  </td>
                                )}
                                <td style={cellStyle}>
                                  {lineItem?.deliveredQuantity}
                                </td>
                                <td style={cellStyle}>
                                  {lineItem?.confirmed
                                    ? lineItem?.plannedIssueDate !== "" &&
                                      lineItem?.plannedIssueDate &&
                                      moment(lineItem?.plannedIssueDate).format(
                                        "DD-MMM-YYYY"
                                      )
                                    : t("dnet.unconfirmed.text")}
                                </td>
                                <td style={cellStyle}>
                                  {lineItem?.goodsIssueDate &&
                                  lineItem?.goodsIssueDate !== ""
                                    ? moment(lineItem?.goodsIssueDate).format(
                                        "DD-MMM-YYYY"
                                      )
                                    : ""}
                                </td>
                                <td style={cellStyle}>
                                  {lineItem?.deliveryNumber}
                                </td>
                                <td style={cellStyle}>
                                  {lineItem?.billOfLadingNumber !== "" ? (
                                    contextDetails?.tntUrl !== "" ||
                                    contextDetails?.dpdUrl !== "" ||
                                    contextDetails?.schenkerUrl !== "" ? (
                                      lineItem?.carrierPartnerName?.toLowerCase() ===
                                        "schenker" &&
                                      lineItem?.deliveryNumber &&
                                      lineItem?.deliveryNumber !== "" ? (
                                        <div>{lineItem?.deliveryNumber}</div>
                                      ) : (
                                        <div>
                                          {lineItem?.billOfLadingNumber}
                                        </div>
                                      )
                                    ) : (
                                      lineItem?.billOfLadingNumber
                                    )
                                  ) : (
                                    lineItem?.billOfLadingNumber === "" &&
                                    lineItem?.deliveryNumber !== "" &&
                                    lineItem?.deliveryStatus !== "" &&
                                    contextDetails?.schenkerUrl !== "" &&
                                    lineItem?.carrierPartnerName?.toLowerCase() ===
                                      "schenker" && (
                                      <div>{lineItem?.deliveryNumber}</div>
                                    )
                                  )}
                                </td>
                                <td style={cellStyle}>{lineItem?.invoiceNumber}</td>
                                <td style={cellStyle}>
                                  {lineItem?.deliveryStatus !== "" && (
                                    <LockIcon color="primary" />
                                  )}
                                </td>
                                <td style={cellStyle}>
                                  {lineItem?.documentHeader !== "" && (
                                    <img
                                      src={blockedOrder}
                                      alt={
                                        lineItem?.documentHeader !== ""
                                          ? t(
                                              "dnet.blocked_delivery_alttext.text"
                                            )?.toString()
                                          : ""
                                      }
                                    />
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <tr tabIndex={-1} key={key}>
                          <td style={cellStyle}>{order?.productSku?.name}</td>
                          {contextDetails?.customerMaterialNumberEnabled && (
                            <td style={cellStyle}>
                              {order?.customerMaterialNumber}
                            </td>
                          )}
                          <td style={cellStyle}>{order?.quantity}</td>
                          <td style={cellStyle}>{order?.openQuantity}</td>
                          <td style={cellStyle}>
                            {moment(order?.firstDeliveryDate).format(
                              "DD-MMM-YYYY"
                            )}
                          </td>
                          <td style={cellStyle}>
                            {`${order?.orderNumber} / ${parseInt(
                              order?.orderItemNumber,
                              10
                            ).toString()}`}
                          </td>
                          <td style={cellStyle}>
                            {
                              <img
                                src={
                                  reportedFilteredPayload?.lineItemReportQuery
                                    ?.orderType === "salesOrders"
                                    ? order_sales
                                    : reportedFilteredPayload
                                        ?.lineItemReportQuery?.orderType ===
                                      "returnOrders"
                                    ? order_return
                                    : order_both
                                }
                                alt={
                                  reportedFilteredPayload?.lineItemReportQuery
                                    ?.orderType
                                }
                                title={
                                  reportedFilteredPayload?.lineItemReportQuery
                                    ?.orderType
                                }
                              />
                            }
                          </td>
                          <td style={cellStyle}>{order?.customerReference}</td>
                          {contextDetails?.viewRejectedOrdersEnabled && (
                            <td style={cellStyle}>
                              {order?.rejectionCode &&
                              order?.rejectionCode !== ""
                                ? 
                                order?.rejectionCode
                                : ""}
                            </td>
                          )}
                          <td style={cellStyle}>
                            <div>{order?.shipToAccount?.number}</div>
                          </td>
                          <td style={cellStyle}></td>
                          <td style={cellStyle}></td>
                          <td style={cellStyle}></td>
                          <td style={cellStyle}></td>
                          <td style={cellStyle}></td>
                          <td style={cellStyle}></td>
                          <td style={cellStyle}></td>
                          <td style={cellStyle}></td>
                        </tr>
                      )}
                    </>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={columns.length}
                    align={"center"}
                    className={styles.noRecFound}
                    style={cellStyle}
                  >
                    {t("gzl.no_data_available.text")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        {/* </TableContainer> */}
        <div>
          <Typography variant="body2">
            {t(
              `dnet.quotationt.c_${currentDealer?.bgName?.slice(0, 4)}.text`
            ).toString()}
          </Typography>
        </div>
      </div>
    );
  };

  const handleDownload = () => {
    const excelHeader = [
      { header: t("dnet.model.text"), key: "model", width: 20 },
      {
        header: t("dnet.order_report.requested_total_quantity.text"),
        key: "reqTotalQty",
        width: 20,
      },
      {
        header: t("dnet.order_report.open_quantity.text"),
        key: "openQty",
        width: 20,
      },
      {
        header: t("dnet.order_report.created_on.text"),
        key: "createdOn",
        width: 20,
      },
      {
        header: t("dnet.order_report.requested_date.text"),
        key: "reqDate",
        width: 20,
      },
      {
        header: t("dnet.order_report.sony_ref.text"),
        key: "orderNo",
        width: 20,
      },
      { header: t("dnet.order_type.text"), key: "orderType", width: 20 },
      { header: t("dnet.order_report.your_ref.text"), key: "refNo", width: 20 },
      {
        header: t("dnet.order_report.rejection_code.text"),
        key: "rejectCode",
        width: 20,
      },
      {
        header: t("dnet.order_report.deliver_to.text"),
        key: "deliverTo",
        width: 20,
      },
      {
        header: t("dnet.order_report.ready_to_deliver_quantity.text"),
        key: "readyToDeliverQty",
        width: 20,
      },
      {
        header: t("dnet.order_report.planned_dispatch_date.text"),
        key: "plannedDispatchDate",
        width: 20,
      },
      {
        header: t("dnet.order_report.actual_dispatch_date.text"),
        key: "actualDispatchDate",
        width: 20,
      },
      { header: t("dnet.delivery_no.text"), key: "deliveryNo", width: 20 },
      {
        header: t("dnet.carrier_reference_no.text"),
        key: "carierRefNo",
        width: 20,
      },
      { header: t("dnet.invoice_no.text"), key: "invoiceNo", width: 20 },
      { header: t("dnet.locked.text"), key: "lockStatus", width: 20 },
      { header: t("dnet.blocked_order.text"), key: "blockedStatus", width: 20 },
    ];
    let excelData: any = [];
    // for (let i = 0; i < reportedFilteredDetails?.length; i++) {
    reportedFilteredDetails?.forEach((order: any) => {
      order?.reportScheduleLines
        ? order?.reportScheduleLines?.forEach((lineItem: any, index: any) => {
            excelData.push({
              model: order?.productSku?.name,
              reqTotalQty: order?.quantity,
              openQty: order?.openQuantity,
              createdOn: moment(order?.createdOnDate).format("DD-MMM-YYYY"),
              reqDate: moment(order?.firstDeliveryDate).format("DD-MMM-YYYY"),
              orderNo: `${order?.orderNumber} / ${order?.orderItemNumber}`,
              orderType:
                reportedFilteredPayload?.lineItemReportQuery?.orderType,
              refNo: order?.customerReference,
              rejectCode: order?.rejectionCode,
              deliverTo: order?.shipToAccount?.number,
              readyToDeliverQty: lineItem?.deliveredQuantity,
              plannedDispatchDate: lineItem?.confirmed
                ? lineItem?.plannedIssueDate !== "" &&
                  lineItem?.plannedIssueDate &&
                  moment(lineItem?.plannedIssueDate).format("DD-MMM-YYYY")
                : t("dnet.unconfirmed.text"),
              actualDispatchDate:
                lineItem?.goodsIssueDate !== "" && lineItem?.goodsIssueDate
                  ? moment(lineItem?.goodsIssueDate).format("DD-MMM-YYYY")
                  : "",
              deliveryNo: lineItem?.deliveryNumber,
              carierRefNo: lineItem?.billOfLadingNumber,
              invoiceNo: lineItem?.invoiceNumber,
              lockStatus: lineItem?.deliveryStatus !== "" ? "Yes" : "No",
              blockedStatus: lineItem?.documentHeader !== "" ? "Yes" : "No",
            });
          })
        : excelData.push({
            model: order?.productSku?.name,
            reqTotalQty: order?.quantity,
            openQty: order?.openQuantity,
            createdOn: moment(order?.createdOnDate).format("DD-MMM-YYYY"),
            reqDate: moment(order?.firstDeliveryDate).format("DD-MMM-YYYY"),
            orderNo: `${order?.orderNumber} / ${order?.orderItemNumber}`,
            orderType: reportedFilteredPayload?.lineItemReportQuery?.orderType,
            refNo: order?.customerReference,
            rejectCode: order?.rejectionCode,
            deliverTo: order?.shipToAccount?.number,
            readyToDeliverQty: "",
            plannedDispatchDate: "",
            actualDispatchDate: "",
            deliveryNo: "",
            carierRefNo: "",
            invoiceNo: "",
            lockStatus: "",
            blockedStatus: "",
          });
    });
    downloadExcel(excelHeader, excelData, "SONYOrderReport.xlsx");
  };

  const generateAccountDetailString = (report: any) => {
    let country = report?.shipToAccount?.address?.country
      ? report?.shipToAccount?.address?.country
      : "";
    let accDetailString =
      report?.shipToAccount?.number +
      " - " +
      report?.shipToAccount?.name +
      " - " +
      report?.shipToAccount?.address?.street +
      ", " +
      report?.shipToAccount?.address?.city +
      ", (" +
      country +
      ")";
    return accDetailString;
  };

  // const [timerStarted, setTimerStarted] = useState(false);

  // const handleLink = (number: any, linkName: any) => {
  //   let link: any = "";
  //   if (linkName === "infodis") {
  //     link = `${contextDetails?.infodisUrl}${number}`;
  //   } else if (linkName === "schenker") {
  //     link = `${contextDetails?.schenkerUrl}${number}`;
  //   } else if (linkName === "tnt") {
  //     link = `${contextDetails?.tntUrl}${number}`;
  //   } else {
  //     link = `${contextDetails?.dpdUrl}${number}`;
  //   }
  //   if (!timerStarted) {
  //     setTimerStarted(true);
  //     // Wait for 2000 milliseconds (2 seconds)
  //     setTimeout(() => {
  //       // Below code is to trigger link
  //       window.open(linkName, '_blank', 'noopener');
  //       setTimerStarted(false);
  //     }, 2000);
  //   }
  // };

  return (
    <div>
      <div>
        <TableContainer
          component={Paper}
          className={styles.tableContainerHeader}
        >
          <Table sx={{ minWidth: 650 }} aria-label="Reports Table">
            <TableHead>
              <TableRow>
                <TableCell
                  key="1"
                  align="center"
                  colSpan={
                    contextDetails?.customerMaterialNumberEnabled ? 9 : 8
                  }
                  style={{
                    borderRight:"1px solid #c8c8c8",
                  }}
                >
                  {t("dnet.order.text")}
                </TableCell>
                <TableCell key="2" align="center" colSpan={9}>
                  {t("dnet.delivery.text")}
                </TableCell>
              </TableRow>
              <TableRow>
                {columns.map((column) => {
                  return column?.id === "orderType" ? (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <img src={order_both} alt={column.label} />
                    </TableCell>
                  ) : column?.id === "customerMaterialNumber" ? (
                    contextDetails?.customerMaterialNumberEnabled && (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    )
                  ) : column?.id === "lockStatus" ? (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Tooltip
                        title={column?.label}
                        arrow
                        placement="left"
                        enterTouchDelay={0}
                      >
                        <IconButton>
                          <LockIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  ) : column?.id === "reportScheduleLines_minShipmentValue" ? (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <img src={blockedOrder} alt={column.label} />
                    </TableCell>
                  ) : column?.id === "rejectionCode" ? (
                    contextDetails?.viewRejectedOrdersEnabled && (
                      <TableCell key={column.id} align={column.align}>
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : "asc"}
                          onClick={createSortHandler(column.id)}
                        >
                          {column.label}
                          {orderBy === column.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    )
                  ) : (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.id === "reqTotalQty" ||
                      column.id === "openQty" ||
                      column.id === "readyToDeliverQty" ? (
                        column.label
                      ) : (
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : "asc"}
                          onClick={createSortHandler(column.id)}
                        >
                          {column.label}
                          {orderBy === column.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {reportedFilteredDetails?.length > 0 ? (
                sortedData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((order: any, key: any) => {
                    return (
                      <React.Fragment key={key}>
                        {order?.reportScheduleLines ? (
                          order?.reportScheduleLines?.map(
                            (lineItem: any, index: any) => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={`row-${key}-cell-${index}`}
                                >
                                  {index === 0 && (
                                    <TableCell
                                      rowSpan={
                                        order?.reportScheduleLines &&
                                        order?.reportScheduleLines?.length > 1
                                          ? order?.reportScheduleLines?.length
                                          : 1
                                      }
                                    >
                                      {order?.productSku?.name}
                                    </TableCell>
                                  )}
                                  {contextDetails?.customerMaterialNumberEnabled &&
                                    index === 0 && (
                                      <TableCell
                                        rowSpan={
                                          order?.reportScheduleLines &&
                                          order?.reportScheduleLines?.length > 1
                                            ? order?.reportScheduleLines?.length
                                            : 1
                                        }
                                      >
                                        {order?.customerMaterialNumber}
                                      </TableCell>
                                    )}
                                  {index === 0 && (
                                    <TableCell
                                      rowSpan={
                                        order?.reportScheduleLines &&
                                        order?.reportScheduleLines?.length > 1
                                          ? order?.reportScheduleLines?.length
                                          : 1
                                      }
                                    >
                                      {order?.quantity}
                                    </TableCell>
                                  )}
                                  {index === 0 && (
                                    <TableCell
                                      rowSpan={
                                        order?.reportScheduleLines &&
                                        order?.reportScheduleLines?.length > 1
                                          ? order?.reportScheduleLines?.length
                                          : 1
                                      }
                                    >
                                      {order?.openQuantity}
                                    </TableCell>
                                  )}
                                  {index === 0 && (
                                    <TableCell
                                      rowSpan={
                                        order?.reportScheduleLines &&
                                        order?.reportScheduleLines?.length > 1
                                          ? order?.reportScheduleLines?.length
                                          : 1
                                      }
                                    >
                                      {moment(order?.firstDeliveryDate).format(
                                        "DD-MMM-YYYY"
                                      )}
                                    </TableCell>
                                  )}
                                  {index === 0 && (
                                    <TableCell
                                      rowSpan={
                                        order?.reportScheduleLines &&
                                        order?.reportScheduleLines?.length > 1
                                          ? order?.reportScheduleLines?.length
                                          : 1
                                      }
                                    >
                                      <Tooltip
                                        title={t(
                                          "dnet.full_order_details.text"
                                        ).replace(
                                          "{0}",
                                          `${order?.orderNumber} / ${parseInt(
                                            order?.orderItemNumber,
                                            10
                                          ).toString()}`
                                        )}
                                        arrow
                                      >
                                        <div>
                                          <Link
                                            id="linkTag"
                                            className={styles.colorBlue}
                                            to="/Reporting/Order_Status"
                                            state={{
                                              internetOrderId:
                                              sortedData?.[
                                                  key+(page*rowsPerPage)
                                                ]?.["orderNumber"],
                                              orderType:
                                                reportedFilteredPayload
                                                  ?.lineItemReportQuery
                                                  ?.orderType,
                                              orderStatus:
                                              sortedData[key+(page*rowsPerPage)]
                                                  ?.reportScheduleLines?.[0]
                                                  ?.orderStatus,
                                            }}
                                          >
                                            {`${
                                              order?.orderNumber
                                            } / ${parseInt(
                                              order?.orderItemNumber,
                                              10
                                            ).toString()}`}
                                          </Link>
                                        </div>
                                      </Tooltip>
                                    </TableCell>
                                  )}
                                  {index === 0 && (
                                    <TableCell
                                      rowSpan={
                                        order?.reportScheduleLines &&
                                        order?.reportScheduleLines?.length > 1
                                          ? order?.reportScheduleLines?.length
                                          : 1
                                      }
                                    >
                                      {
                                        <img
                                          src={
                                            reportedFilteredPayload
                                              ?.lineItemReportQuery
                                              ?.orderType === "salesOrders"
                                              ? order_sales
                                              : reportedFilteredPayload
                                                  ?.lineItemReportQuery
                                                  ?.orderType === "returnOrders"
                                              ? order_return
                                              : order_both
                                          }
                                          alt={
                                            reportedFilteredPayload
                                              ?.lineItemReportQuery?.orderType
                                          }
                                          title={
                                            reportedFilteredPayload
                                              ?.lineItemReportQuery?.orderType
                                          }
                                        />
                                      }
                                    </TableCell>
                                  )}
                                  {index === 0 && (
                                    <TableCell
                                      rowSpan={
                                        order?.reportScheduleLines &&
                                        order?.reportScheduleLines?.length > 1
                                          ? order?.reportScheduleLines?.length
                                          : 1
                                      }
                                    >
                                      {order?.customerReference}
                                    </TableCell>
                                  )}
                                  {contextDetails?.viewRejectedOrdersEnabled &&
                                    index === 0 && (
                                      <TableCell
                                        rowSpan={
                                          order?.reportScheduleLines &&
                                          order?.reportScheduleLines?.length > 1
                                            ? order?.reportScheduleLines?.length
                                            : 1
                                        }
                                      >
                                        {order?.rejectionCode &&
                                        order?.rejectionCode !== ""
                                          ? 
                                          order?.rejectionCode
                                          : ""}
                                      </TableCell>
                                    )}
                                  {index === 0 && (
                                    <TableCell
                                      rowSpan={
                                        order?.reportScheduleLines &&
                                        order?.reportScheduleLines?.length > 1
                                          ? order?.reportScheduleLines?.length
                                          : 1
                                      }
                                    >
                                      <Tooltip
                                        title={generateAccountDetailString(
                                          reportedFilteredDetails[key]
                                        )}
                                        arrow
                                      >
                                        <div>
                                          {order?.shipToAccount?.number}
                                        </div>
                                      </Tooltip>
                                    </TableCell>
                                  )}
                                  <TableCell>
                                    {lineItem?.deliveredQuantity}
                                  </TableCell>
                                  <TableCell>
                                    {lineItem?.confirmed
                                      ? lineItem?.plannedIssueDate !== "" &&
                                        lineItem?.plannedIssueDate &&
                                        moment(
                                          lineItem?.plannedIssueDate
                                        ).format("DD-MMM-YYYY")
                                      : t("dnet.unconfirmed.text")}
                                  </TableCell>
                                  <TableCell>
                                    {lineItem?.goodsIssueDate &&
                                    lineItem?.goodsIssueDate !== ""
                                      ? moment(lineItem?.goodsIssueDate).format(
                                          "DD-MMM-YYYY"
                                        )
                                      : ""}
                                  </TableCell>
                                  <TableCell>
                                    {lineItem?.deliveryNumber &&
                                      lineItem?.deliveryNumber !== "" &&
                                      (contextDetails?.infodisUrl !== "" ? (
                                        contextDetails?.urlType === "tnt" &&
                                        !lineItem?.carrierTrackingNumber &&
                                        lineItem?.carrierTrackingNumber !==
                                          "" ? (
                                          lineItem?.deliveryNumber
                                        ) : (
                                          // <div
                                          //   onClick={() =>
                                          //     handleLink(
                                          //       lineItem?.deliveryNumber,
                                          //       "infodis"
                                          //     )
                                          //   }
                                          //   className={styles.colorBlue}
                                          // >
                                          //   {lineItem?.deliveryNumber}
                                          contextDetails?.infodisUrl ? 
                                          <a 
                                            className={styles.colorBlue} 
                                            href={`${
                                              contextDetails?.infodisUrl
                                              }${
                                                lineItem?.deliveryNumber
                                              }`
                                            } 
                                            target="_blank" 
                                            rel="noreferrer">
                                              {lineItem?.deliveryNumber}
                                          </a> : lineItem?.deliveryNumber
                                          // </div>
                                        )
                                      ) : (
                                        lineItem?.deliveryNumber
                                      ))}
                                  </TableCell>
                                  <TableCell>
                                    {
                                      lineItem?.billOfLadingNumber &&
                                      lineItem?.billOfLadingNumber !== "" ? (
                                        contextDetails?.tntUrl !== "" ||
                                        contextDetails?.dpdUrl !== "" ||
                                        contextDetails?.schenkerUrl !== "" ? (
                                          lineItem?.carrierPartnerName?.toLowerCase() ===
                                          "tnt" ? (
                                            // <div
                                            //   onClick={() =>
                                            //     handleLink(
                                            //       lineItem?.billOfLadingNumber,
                                            //       "tnt"
                                            //     )
                                            //   }
                                            //   className={styles.colorBlue}
                                            // >
                                            contextDetails?.tntUrl ? 
                                            <a 
                                              className={styles.colorBlue} 
                                              href={`${
                                                contextDetails?.tntUrl
                                                }${
                                                  lineItem?.billOfLadingNumber
                                                }`
                                              } 
                                              target="_blank" 
                                              rel="noreferrer">
                                                {lineItem?.billOfLadingNumber}
                                            </a> : lineItem?.billOfLadingNumber
                                            // </div>
                                          ) : lineItem?.carrierPartnerName?.toLowerCase() ===
                                            "direct" ? (
                                            // <div
                                            //   onClick={() =>
                                            //     handleLink(
                                            //       lineItem?.billOfLadingNumber,
                                            //       "dpd"
                                            //     )
                                            //   }
                                            //   className={styles.colorBlue}
                                            // >
                                            contextDetails?.dpdUrl ? 
                                            <a 
                                              className={styles.colorBlue} 
                                              href={`${
                                                contextDetails?.dpdUrl
                                                }${
                                                  lineItem?.billOfLadingNumber
                                                }`
                                              } 
                                              target="_blank" 
                                              rel="noreferrer">
                                                {lineItem?.billOfLadingNumber}
                                            </a> : lineItem?.billOfLadingNumber
                                            // </div>
                                          ) : lineItem?.carrierPartnerName?.toLowerCase() ===
                                              "schenker" &&
                                            lineItem?.deliveryNumber &&
                                            lineItem?.deliveryNumber !== "" ? (
                                            // <div
                                            //   onClick={() =>
                                            //     handleLink(
                                            //       lineItem?.deliveryNumber,
                                            //       "schenker"
                                            //     )
                                            //   }
                                            //   className={styles.colorBlue}
                                            // >
                                            contextDetails?.schenkerUrl ? 
                                            <a 
                                              className={styles.colorBlue} 
                                              href={`${
                                                contextDetails?.schenkerUrl
                                                }${
                                                  lineItem?.deliveryNumber
                                                }`
                                              } 
                                              target="_blank" 
                                              rel="noreferrer">
                                                {lineItem?.deliveryNumber}
                                            </a> : lineItem?.deliveryNumber
                                            // </div>
                                          ) : (
                                            <div>
                                              {lineItem?.billOfLadingNumber}
                                              <Tooltip
                                                title={
                                                  t(
                                                    "dnet.carrier_partner_name.text"
                                                  )?.toString() +
                                                  " " +
                                                  lineItem?.carrierPartnerName
                                                }
                                                placement="right-start"
                                                arrow
                                              >
                                                <IconButton
                                                  className={styles.infoIcon}
                                                >
                                                  <HelpOutlinedIcon />
                                                </IconButton>
                                              </Tooltip>
                                            </div>
                                          )
                                        ) : (
                                          lineItem?.billOfLadingNumber
                                        )
                                      ) : (
                                        lineItem?.billOfLadingNumber === "" &&
                                        lineItem?.deliveryNumber !== "" &&
                                        lineItem?.deliveryStatus !== "" &&
                                        contextDetails?.schenkerUrl !== "" &&
                                        lineItem?.carrierPartnerName?.toLowerCase() ===
                                          "schenker" && (
                                          // <div
                                          //   onClick={() =>
                                          //     handleLink(
                                          //       lineItem?.deliveryNumber,
                                          //       "schenker"
                                          //     )
                                          //   }
                                          //   className={styles.colorBlue}
                                          // >
                                          contextDetails?.schenkerUrl ? 
                                          <a 
                                            className={styles.colorBlue} 
                                            href={`${
                                              contextDetails?.schenkerUrl
                                              }${
                                                lineItem?.deliveryNumber
                                              }`
                                            } 
                                            target="_blank" 
                                            rel="noreferrer">
                                              {lineItem?.deliveryNumber}
                                          </a> : lineItem?.deliveryNumber
                                          // </div>
                                        )
                                      )
                                    }
                                  </TableCell>
                                  <TableCell>
                                    {lineItem?.invoiceNumber}
                                  </TableCell>
                                  <TableCell>
                                    {lineItem?.deliveryStatus !== "" && (
                                      <Tooltip
                                        title={t(
                                          "dnet.padlock_teaser_alttext.text"
                                        )}
                                        arrow
                                        placement="left"
                                        enterTouchDelay={0}
                                      >
                                        <IconButton>
                                          <LockIcon color="primary" />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {lineItem?.documentHeader !== "" && (
                                      <>
                                        <img
                                          src={blockedOrder}
                                          alt={
                                            lineItem?.documentHeader !== ""
                                              ? t(
                                                  "dnet.blocked_delivery_alttext.text"
                                                )?.toString()
                                              : ""
                                          }
                                        />
                                        <>{lineItem?.minShipmentValue}</>
                                      </>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )
                        ) : (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={`row-${key}`}
                          >
                            <TableCell>{order?.productSku?.name}</TableCell>
                            {contextDetails?.customerMaterialNumberEnabled && (
                              <TableCell>
                                {order?.customerMaterialNumber}
                              </TableCell>
                            )}
                            <TableCell>{order?.quantity}</TableCell>
                            <TableCell>{order?.openQuantity}</TableCell>
                            <TableCell>
                              {moment(order?.firstDeliveryDate).format(
                                "DD-MMM-YYYY"
                              )}
                            </TableCell>
                            <TableCell>
                              <Tooltip
                                title={t(
                                  "dnet.full_order_details.text"
                                ).replace(
                                  "{0}",
                                  `${order?.orderNumber} / ${parseInt(
                                    order?.orderItemNumber,
                                    10
                                  ).toString()}`
                                )}
                                arrow
                              >
                                <div>
                                  <Link
                                    id="linkTag"
                                    className={styles.colorBlue}
                                    to="/Reporting/Order_Status"
                                    state={{
                                      internetOrderId:
                                      sortedData?.[key+(page*rowsPerPage)]?.[
                                          "orderNumber"
                                        ],
                                      orderType:
                                        reportedFilteredPayload
                                          ?.lineItemReportQuery?.orderType,
                                      orderStatus:
                                      sortedData[key+(page*rowsPerPage)]
                                          ?.reportScheduleLines?.[0]
                                          ?.orderStatus,
                                    }}
                                  >
                                    {`${order?.orderNumber} / ${parseInt(
                                      order?.orderItemNumber,
                                      10
                                    ).toString()}`}
                                  </Link>
                                </div>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              {
                                <img
                                  src={
                                    reportedFilteredPayload?.lineItemReportQuery
                                      ?.orderType === "salesOrders"
                                      ? order_sales
                                      : reportedFilteredPayload
                                          ?.lineItemReportQuery?.orderType ===
                                        "returnOrders"
                                      ? order_return
                                      : order_both
                                  }
                                  alt={
                                    reportedFilteredPayload?.lineItemReportQuery
                                      ?.orderType
                                  }
                                  title={
                                    reportedFilteredPayload?.lineItemReportQuery
                                      ?.orderType
                                  }
                                />
                              }
                            </TableCell>
                            <TableCell>{order?.customerReference}</TableCell>
                            {contextDetails?.viewRejectedOrdersEnabled && (
                              <TableCell>
                                {order?.rejectionCode &&
                                order?.rejectionCode !== ""
                                  ?
                                  order?.rejectionCode
                                  : ""}
                              </TableCell>
                            )}
                            <TableCell>
                              <Tooltip
                                title={generateAccountDetailString(
                                  reportedFilteredDetails[key]
                                )}
                                arrow
                              >
                                <div>{order?.shipToAccount?.number}</div>
                              </Tooltip>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    align={"center"}
                    className={styles.noRecFound}
                  >
                    {t("gzl.no_data_available.text")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className={styles.tableFooter}>
        <div className={styles.tableFooterButton}>
          <CustomButton
            id="printButton"
            label={t("dnet.printer_friendly.text")}
            onClick={handlePrint}
            color="primary"
          />
          <div style={{ display: "none" }}>
            <div ref={componentRef} style={{ padding: "22px" }}>
              {createTable()}
            </div>
          </div>
          <CustomButton
            id="downloadExcelButton"
            label={t("dnet.download_excel.txt")}
            onClick={handleDownload}
          />
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={
            reportedFilteredDetails?.length
              ? reportedFilteredDetails?.length
              : 0
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

ReportsTable.defaultProps = {
  initialSort: "productSku_name",
};
