import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { getAvailabilityDetails, minus2, plus2 } from "../../../helper/util";
import styles from "./prodCartDetails.module.scss";
import CircleIcon from "@mui/icons-material/Circle";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import CustomTextField from "../../../atoms/customTextField";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { ROUTES_LABEL } from "../../../../constants/routesLabels";
import SearchIcon from "@mui/icons-material/Search";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import {
  addToCart,
  addToCartSuccess,
  fetchLiveAvailability,
  fetchLiveAvailabilitySuccess,
  getContextDetails,
  setSelectedProductsDownload,
} from "../../../../state/actions";
import { CONTEXT_ORDER } from "../../../../constants/commonConstants";

var _ = require("lodash");

export const ProdCartDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const DELIVERY_DATE_PADDING_VALUE = 60;
  const SALES_STATUS_PRE_SALES = "08";

  const currentProductSkuData = useSelector(
    (state: any) => state?.productReducer?.currentProductSku
  );
  useEffect(() => {
    dispatch(
      getContextDetails({
        key: CONTEXT_ORDER?.PRODUCT_MODULE_PAGE,
      })
    );
  }, []);

  const contextDetails = useSelector(
    (state: any) => state?.commonReducer?.contextDetails
  );

  const [currentProductSku, setCurrentProductSku] = useState<any>({});
  const [currentProductData, setCurrentProductData] = useState<any>();

  useEffect(() => {
    if (!_.isEmpty(currentProductSkuData)) {
      setCurrentProductSku({...currentProductSkuData,
      quantityMessageTarget : "",
      otherTarget : "",
      toolTipLive : "",
      liveAvbText : "",
      activeAvailabilityIcon:"plus"
    });
    }
  }, [currentProductSkuData]);
  const isBasicTransactor = contextDetails?.isBasicTransactor;
  const isPriceVisible = contextDetails?.priceVisible;

  const [isListValVisible, setIsListValVisible] = useState<boolean>(true);
  const [details, setDetails] = useState<any>({});
  let [oldAvailabilityMsg, setOldAvailabilityMsg] = useState<any>("");
  const alertstar = require("../../../../assets/images/alertstar_002.gif");
  const [quantity, setQuantity] = useState<any>(0);

  useEffect(() => {
    if (_.isEmpty(details) && !_.isEmpty(currentProductSku)) {
      let d = getAvailabilityDetails(currentProductSku);
      setDetails({
        colorCode: d?.colorCode,
        titleText: t(d?.titleText),
        availabilityMessage: t(d?.availabilityMessage),
      });
      setOldAvailabilityMsg(t(d?.availabilityMessage));
    }
  }, [currentProductSku]);

  const handleAvailabilityClick = (productIdx: any, type: any) => {
    let quantityTargetFinal = "";
    let liveAvbFinal = "";
    let otherTargetFinal = "";
    let toolTipFinal = "";
    let availIcon = "";

    if (type === "plus") {

      let minQuantity: any = productIdx?.product?.detail?.minOrderQuantity;
      let minDelQuantity: any =
        productIdx?.product?.detail?.minDeliveryQuantity;
      let delUnit: any = productIdx?.product?.detail?.deliveryUnit;
      let qty: any = quantity;

      minQuantity = parseInt(minQuantity) || 1 ;
      minDelQuantity= parseInt(minDelQuantity) || 1;
      delUnit = parseInt(delUnit) || 1;

      minQuantity = minQuantity === 0 ? 1 : minQuantity;
      minDelQuantity = minDelQuantity === 0 ? 1 : minDelQuantity;
      delUnit = delUnit === 0 ? 1 : delUnit;
      let setMinOrderQuantity: any = false;

      if (
        qty === undefined ||
        qty === 0 ||
        qty === "" ||
        Number.isNaN(quantity)
      ) {
        qty = Math.max(
          parseInt(minQuantity) || 1,
          parseInt(minDelQuantity) || 1
        );
        setMinOrderQuantity = true;
      }

      let exception: any = null;

      exception = validateQuantity(
        minDelQuantity,
        delUnit,
        qty,
        minDelQuantity
      );

      if (exception == null) {
        let SalesArea: any = productIdx?.salesArea;
        let ProductHierarchyCode: any =
          productIdx?.product?.detail?.thirdLevelHierarchyCode +
          productIdx?.product?.detail?.productHierarchyCode;

        let request: any = {
          productHierarchyCode: ProductHierarchyCode,
          productName: productIdx?.product?.definition?.materialName,
          quantity: qty,
        };

        let productData: any = {
          quantity: qty,
          setMinOrderQuantity: setMinOrderQuantity,
          product: productIdx,
        };
        setCurrentProductData(productData);
        dispatch(fetchLiveAvailabilitySuccess({}));
        dispatch(fetchLiveAvailability(request));
      } else {
        liveAvbFinal = exception;
      }
      availIcon = "minus";
    } else {
      productIdx.quantityMessageTarget = "";
      productIdx.otherTarget = "";
      productIdx.toolTipLive = "";
      productIdx.liveAvbText = "";
      productIdx.activeAvailabilityIcon = "plus";
      availIcon = "plus";
    }
    setCurrentProductSku({
      ...currentProductSku,
      otherTarget: otherTargetFinal,
      toolTipMessageTarget: toolTipFinal,
      activeAvailabilityIcon: availIcon,
      quantityMessageTarget: quantityTargetFinal,
    });
    setDetails({
      ...details,
      availabilityMessage: liveAvbFinal,
    });
  };

  const liveAvailability = useSelector(
    (state: any) => state?.productReducer?.liveAvailability
  );

  useEffect(() => {
    if (
      currentProductData &&
      liveAvailability != null &&
      liveAvailability != undefined &&
      !_.isEmpty(liveAvailability)
    ) {
      getLiveAvailabilityDataProduct(
        liveAvailability,
        currentProductData?.quantity,
        currentProductData?.setMinOrderQuantity
      );
    }
  }, [currentProductData, liveAvailability]);

  const getLiveAvailabilityDataProduct = (
    response: any,
    quantity: any,
    setMinOrderQuantity: any
  ) => {
    let liveAvbFinal = "";
    let otherTargetFinal = "";
    let toolTipFinal = "";
    let availIcon = "";
    let quantityMessageFinal = "";

    if (response != null && response != undefined && response?.liveAvailability) {
      let priceAmount = response?.liveAvailability?.price?.amount;
      let price = response?.liveAvailability?.price?.currency;
      let currency = response?.liveAvailability?.price?.currency
        ? response?.liveAvailability?.price?.currency
        : "";
      let date = isDeliveryDateDisplayable(
        currentProductSku?.product?.detail?.salesStatus,
        response?.liveAvailability
      )
        ? response?.liveAvailability?.date
        : "";
      let confirmedQuantity =
        response?.liveAvailability?.quantity > 0
          ? response?.liveAvailability?.quantity
          : null;
      let newPrice: any = currency + " " + priceAmount;
      let isPriceVisible: any = contextDetails?.priceVisible; 
      let centralStockCheckStatus: any = response?.centralStockCheckStatus;
      if (isPriceVisible) {
        otherTargetFinal = t("dnet.netprice.text")?.toString() + " " + newPrice;
      }
      if (setMinOrderQuantity) {
        let liveAvbPriceText: any = t(
          "dnet.live_avb_price_calc.text"
        )?.toString();
        liveAvbPriceText = liveAvbPriceText.replace("{0}", quantity);
        liveAvbFinal = liveAvbPriceText;
        quantityMessageFinal = liveAvbPriceText;
      } 

      if (date != "") {
        if (setMinOrderQuantity) {
          //On Hover
          toolTipFinal = t("dnet.live_avb_moq.text");
        } else if (quantity > confirmedQuantity) {
          let liveAvbExp: any = t("dnet.live_avb_explain.text")?.toString();
          liveAvbExp = liveAvbExp.replace("{0}", confirmedQuantity);

          //On Hover
          toolTipFinal = liveAvbExp;
        }
        liveAvbFinal = t("dnet.planned_date.text").toString() + " " + date;
      } else {
        if (centralStockCheckStatus == "active") {
          liveAvbFinal = t("dnet.centralstock_overnight.text").toString();
        } else if (setMinOrderQuantity) {
          toolTipFinal = t("dnet.live_avb_moq.text").toString();
          liveAvbFinal = t("dnet.unconfirmed.text").toString();
        } else {
          liveAvbFinal = t("dnet.unconfirmed.text").toString();
        }
      }
    }else if(response?.message !== undefined){
      liveAvbFinal = response?.message;
    }
    setCurrentProductSku({
      ...currentProductSku,
      otherTarget: otherTargetFinal,
      toolTipMessageTarget: toolTipFinal,
      activeAvailabilityIcon: availIcon,
      quantityMessageTarget: quantityMessageFinal,
    });
    setDetails({
      ...details,
      availabilityMessage: liveAvbFinal,
    });
  };

  const isDeliveryDateDisplayable = (salesStatus: any, liveResponse: any) => {
    if (liveResponse?.quantity == 0) return false;
    else if (salesStatus != null || salesStatus != undefined) {
      if (
        salesStatus === SALES_STATUS_PRE_SALES &&
        getDayDiff(new Date(), new Date(liveResponse?.date)) >
          DELIVERY_DATE_PADDING_VALUE
      ) {
        return false;
      }
    }
    return true;
  };

  function getDayDiff(startDate: Date, endDate: Date): number {
    const msInDay = 24 * 60 * 60 * 1000;

    return Math.round(Math.abs(Number(endDate) - Number(startDate)) / msInDay);
  }

  const validateQuantity = (
    minDeliveryUnit: any,
    deliveryUnit: any,
    quantity: any,
    minQuantity: any
  ) => {
    let exception: any = null;
    let value: any = parseInt(quantity);
    if (value > 0) {
      let maxQuantity: any = Math.max(
        parseInt(minDeliveryUnit) || 1,
        parseInt(minQuantity) || 1
      );
      let diff: any = value - maxQuantity;

      if (diff < 0) {
        let invalidMsg: any = t("dnet.not_multiple_of_mdq.text")?.toString();
        invalidMsg = invalidMsg.replace("{0}", minQuantity);
        invalidMsg = invalidMsg.replace("{1}", deliveryUnit);
        exception = invalidMsg;
      } else {
        if (diff % deliveryUnit != 0) {
          let invalidMsg: any = t("dnet.not_multiple_of_mdq.text")?.toString();
          invalidMsg = invalidMsg.replace("{0}", minQuantity);
          invalidMsg = invalidMsg.replace("{1}", deliveryUnit);
          exception = invalidMsg;
        }
      }
    } else {
      exception = t("dnet.invalid_quantity.text");
    }
    let limitOrderQuantity: any = contextDetails?.limitOrderQuantity;

    if (
      limitOrderQuantity != undefined &&
      limitOrderQuantity.enabled &&
      value > limitOrderQuantity.maxQuantity
    ) {
      let invalidLimitMsg: any = t("dnet.invalid_limit_order_quantity.text");
      invalidLimitMsg = invalidLimitMsg.replace(
        "{0}",
        limitOrderQuantity.maxQuantity
      );
      exception = invalidLimitMsg;
    }

    return exception;
  };

  const updateQuantity = (value: any) => {
    let qty = 0;
    if (!isNaN(parseInt(value))) {
      if (parseInt(value) < 0) {
        qty = 0;
      } else {
        qty = parseInt(value);
      }
    } else {
      qty = 0;
    }
    setQuantity(qty);
  };

  const handleQuantityChange = (productIdx:any,type: any) => {
    let qty = 0;
    let minQuantity: any = productIdx?.product?.detail?.minOrderQuantity;
    let minDelQuantity: any =
      productIdx?.product?.detail?.minDeliveryQuantity;
    let delUnit: any = productIdx?.product?.detail?.deliveryUnit;

    minQuantity = parseInt(minQuantity) || 1 ;
    minDelQuantity= parseInt(minDelQuantity) || 1;
    delUnit = parseInt(delUnit) || 1;

    minQuantity = minQuantity === 0 ? 1 : minQuantity;
    minDelQuantity = minDelQuantity === 0 ? 1 : minDelQuantity;
    delUnit = delUnit === 0 ? 1 : delUnit;
    if (type === "increment") {
      if (quantity === 999999) {
        qty = 999999;
      } else {
        qty =  plus2(parseInt(quantity) ,minQuantity,delUnit,minDelQuantity);
      }
    } else {
      if (quantity === 0) {
        qty = minus2(0,minQuantity,delUnit,minDelQuantity);
      } else {
        qty = minus2(parseInt(quantity),minQuantity,delUnit,minDelQuantity);
      }
    }
    setQuantity(qty);
  };

  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );

  const shoppingContextToken = `${currentDealer?.accNum}_${currentDealer?.bgName}_${currentDealer?.loggedInUser?.username}_${currentDealer?.loggedInUser?.profile?.email}`;
  
  const cartShipToOptionsSelections = useSelector(
    (state: any) => state?.cartReducer?.cartShipToOptionsSelections
  );

  const addToCartResponse = useSelector(
    (state: any) => state?.cartReducer?.addToCartResponse
  );

  useEffect(()=>{
    if (!_.isEmpty(addToCartResponse) && addToCartResponse?.success){
      navigate(`/${ROUTES_LABEL.ORDER_ENTRY}`);
    }

  },[addToCartResponse]);

  useEffect(() => {
    return () => {
     dispatch(addToCartSuccess({}));
    };
  }, [dispatch]);

  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.loginResponse?.data?.userDetails
  );

  const redirectToOrderEntry = (productIdx: any) => {
    let prod = {
      prodData: productIdx,
      prodDetails: {},
      availabilityDetails: {},
    };
    let limitOrderQuantity: any = contextDetails?.limitOrderQuantity;
    let cart = {
      shipToAccountId: cartShipToOptionsSelections?.[shoppingContextToken]?.selectedShipToAccount ?? [""],
      lineItems: [
        {
          productSku: {
            name: prod?.prodData?.product?.definition?.materialName,
          },
          subLineItems: [
            {
                quantity: quantity,
                requestedDeliveryDate: cartShipToOptionsSelections?.[shoppingContextToken]?.deliveryDate ?? null,
            }],
          specialPrice: "",
          quantity: quantity,
          quantityTotal: 0,
        },
      ],
      isMultiShipTo: cartShipToOptionsSelections?.[shoppingContextToken]?.multiShipTo ?? false,
      isMultiRequestedDeliveryDate: cartShipToOptionsSelections?.[shoppingContextToken]?.multiRDD ?? false,
      requestedDeliveryDate: cartShipToOptionsSelections?.[shoppingContextToken]?.deliveryDate ?? null,
      spToken: shoppingContextToken,
      key: 2,
      isPartialDelivery: cartShipToOptionsSelections?.[shoppingContextToken]?.partialDelivery === false ? false : true,
      usernameOrEmail: userDetails?.username,
    };

    if (
      limitOrderQuantity != undefined &&
      limitOrderQuantity.enabled &&
      quantity > limitOrderQuantity.maxQuantity
    ) {
      let invalidLimitMsg: any = t("dnet.invalid_limit_order_quantity.text");
      invalidLimitMsg = invalidLimitMsg.replace(
        "{0}",
        limitOrderQuantity.maxQuantity
      );
      setCurrentProductSku({
        ...currentProductSku,
        quantityMessageTarget: invalidLimitMsg,
      });
    } else {
      dispatch(addToCart(cart));
     // navigate(`/${ROUTES_LABEL.ORDER_ENTRY}`);
    
    }
  };

  const redirectToOrdersPage = (product: any) => {
    navigate(`/${ROUTES_LABEL.REPORTING}`, {
      state: {
        selectedModel: product?.product?.definition?.materialName,
      },
    });
  };

  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            variant="subtitle1"
            className={styles.header}
            pb={2}
            color="primary"
          >
            {currentProductSku?.product?.definition?.materialName}
          </Typography>
        </Grid>
        <Grid item lg={5} md={5} sm={5} xs={5}>
          <span className={styles.label}>{t("dnet.availability.text")}</span>
        </Grid>
        <Grid item lg={7} md={7} sm={7} xs={7} mb={1}>
          <Box>
            <Box>
              <CircleIcon
                className={styles.availabilityIcon}
                sx={{
                  color: details?.colorCode ? details?.colorCode[0] : "",
                }}
                titleAccess={details?.titleText}
              />
              <CircleIcon
                className={styles.availabilityIcon}
                sx={{
                  color: details?.colorCode ? details?.colorCode[1] : "",
                }}
                titleAccess={details?.titleText}
              />
              <CircleIcon
                className={styles.availabilityIcon}
                sx={{
                  color: details?.colorCode ? details?.colorCode[2] : "",
                }}
                titleAccess={details?.titleText}
              />
            </Box>
            <Box className={styles.availabilityDiv}>
              <span className={styles.messageStyle}>
                <Tooltip title={currentProductSku?.toolTipMessageTarget}>
                  <span className={styles.messageStyle}>
                    {details?.availabilityMessage}
                  </span>
                </Tooltip>
              </span>
              {currentProductSku?.activeAvailabilityIcon === "plus" ? (
                <IconButton
                  sx={{ paddingTop: "0", paddingBottom: "0" }}
                  title={t("dnet.live_avb_click.text")?.toString()}
                  onClick={(e: any) =>
                    handleAvailabilityClick(currentProductSku, "plus")
                  }
                >
                  <AddBoxOutlinedIcon />
                </IconButton>
              ) : (
                <IconButton
                  sx={{ paddingTop: "0", paddingBottom: "0" }}
                  title={t("dnet.live_avb_click.text")?.toString()}
                  onClick={(e: any) =>
                    handleAvailabilityClick(currentProductSku, "minus")
                  }
                >
                  <IndeterminateCheckBoxOutlinedIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        </Grid>
        {isPriceVisible && (
          <>
            <Grid item lg={5} md={5} sm={5} xs={5} my={1}>
              <span
                className={`${styles.label} ${styles.listStyle}`}
                onClick={() => setIsListValVisible(!isListValVisible)}
              >
                {t("dnet.list_price_colon.text")}
                <img src={alertstar} />
              </span>
            </Grid>
            <Grid item lg={5} md={7} sm={7} xs={7} mt={1} mb={1}>
              {isListValVisible && (
                <Box>
                  <Box>
                    {currentProductSku?.product?.price?.currency}{" "}
                    {currentProductSku?.product?.price?.price &&
                      parseFloat(
                        currentProductSku?.product?.price?.price?.toString()
                      ).toFixed(2)}
                  </Box>
                  <Box className={styles.qtyText}>
                    <span className={styles.messageStyle}>
                      {currentProductSku?.otherTarget}
                    </span>
                  </Box>
                </Box>
              )}
            </Grid>
          </>
        )}
        {currentProductSku?.product?.definition?.ean && (
          <>
            <Grid item lg={5} md={5} sm={5} xs={5} mt={1}>
              <span className={styles.label}>{t("dnet.ean_colon.text")}</span>
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={7} mt={1} mb={1}>
              {currentProductSku?.product?.definition?.ean}
            </Grid>
          </>
        )}
        {!currentProductSku?.product?.definition?.ean &&
          currentProductSku?.product?.definition?.upc && (
            <>
              <Grid item lg={5} md={5} sm={5} xs={5} mt={1}>
                <span className={styles.label}>{t("dnet.upc_colon.text")}</span>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={7} mt={1} mb={1}>
                {currentProductSku?.product?.definition?.upc}
              </Grid>
            </>
          )}
        <Grid item lg={5} md={5} sm={5} xs={5} mt={1}>
          <span className={styles.label}>
            {t("dnet.net_weight_colon.text")}
          </span>
        </Grid>
        <Grid item lg={7} md={7} sm={7} xs={7} mt={1} mb={1}>
          {currentProductSku?.product?.definition?.weight}&nbsp;
          {currentProductSku?.product?.definition?.weightUnit}
        </Grid>
        <Grid item lg={5} md={5} sm={5} xs={5} mt={1}>
          <span className={styles.label}>{t("dnet.quantity_colon.text")}</span>
        </Grid>
        <Grid item lg={7} md={7} sm={7} xs={7} mt={1} mb={1}>
          <Box>
            <CustomTextField
              label={""}
              value={quantity}
              changeHandler={(e: any) => {
                e.target.value = e.target.value.toString().slice(0, 6);
                updateQuantity(e.target.value);
              }}
            />
            <Box className={styles.qtyButtonContaainer}>
              <IconButton
                onClick={() => handleQuantityChange(currentProductSku,"decrement")}
                title={t("dnet.decrement_info.text")?.toString()}
              >
                <RemoveCircleOutlineOutlinedIcon />
              </IconButton>
              <IconButton
                onClick={() => handleQuantityChange(currentProductSku,"increment")}
                title={t("dnet.increment_info.text")?.toString()}
              >
                <AddCircleOutlineOutlinedIcon />
              </IconButton>
            </Box>
            <span className={styles.messageStyle}>
              {currentProductSku?.quantityMessageTarget}
            </span>
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box className={styles.searchCartStyle}>
            <IconButton
              title={t("dnet.search_order_for_product_alt.text")?.toString()}
              onClick={() => redirectToOrdersPage(currentProductSku)}
            >
              <SearchIcon />
            </IconButton>
            <IconButton onClick={() => redirectToOrderEntry(currentProductSku)}>
              <AddShoppingCartOutlinedIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
