import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  Box,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES_LABEL } from "../../constants/routesLabels";
import {
  getBusinessGroup,
  setCurrentDealer,
  setSonyMenuItems,
  validateDealer,
  validateDealerSuccess,
  getContextDetails,
  getContextDetailsSuccess,
  setCurrentDealerInContext,
  getSalesAreaMapping,
  startProductFlow,
  fetchProdCategoryResponse,
  saveQuoteRefData,
  saveQuoteSpecialInstructionData,
  saveMultiAddresses,
  getCartProductsSuccess,
  setLoading,
} from "../../state/actions";
import CustomButton from "../atoms/customButton";
import CustomTextField from "../atoms/customTextField";
import { createMenuItems } from "../helper/util";
import styles from "./changeDealer.module.scss";
import { CONTEXT_ORDER } from "../../constants/commonConstants";
var _ = require("lodash");

export const ChangeDealer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const businessGroups = useSelector(
    (state: any) => state?.changeDealerReducer?.businessGroupList
  );

  const validDealer = useSelector(
    (state: any) => state?.changeDealerReducer?.validateDealer
  );

  useEffect(() => {
    if (businessGroups === undefined || businessGroups?.length === 0) {
      dispatch(getBusinessGroup({}));
    }
  }, []);

  const [selectedBg, setSelectedBg] = useState("");
  const [customerNumber, setCustomerNumber] = useState("");
  const [errorMsg, setErrorMsg] = useState<any>("");
  const [formattedDealerId, setFormattedDealerId] = useState("");

  const handleBgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedBg((event.target as HTMLInputElement).value);
  };

  const changeHandler = () => {
    if (selectedBg === "" && customerNumber === "") {
      setErrorMsg(t("dnet.change_dealer_warning1.text"));
    } else if (selectedBg === "" && customerNumber !== "") {
      setErrorMsg(t("dnet.change_dealer_warning3.text"));
    } else if (selectedBg !== "" && customerNumber === "") {
      setErrorMsg(t("dnet.change_dealer_warning1.text"));
    } else {
      setErrorMsg("");
      dispatch(setLoading(true));
      dispatch(
        validateDealer({
          accountNumber: customerNumber,
          businessGroupName: selectedBg,
        })
      );
    }
  };

  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );

  const siteSectionDetails = useSelector(
    (state: any) => state?.commonReducer?.siteSectionDetails
  );

  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.loginResponse?.data?.userDetails
  );

  useEffect(() => {
    if (!_.isEmpty(validDealer)) {
      if (validDealer?.status === "valid") {
        window.sessionStorage.setItem("changeDealerSuccess", "true");
        setFormattedDealerId(validDealer?.formattedDealerId)
        dispatch(
          getContextDetails({
            key: CONTEXT_ORDER?.CHANGE_DEALER
          })
        );
      } else if (validDealer?.status === "warning_account_not_found") {
        let errMsg = t("dnet.change_dealer_warning2.text")?.replace(
          "{0}",
          customerNumber
        );
        setErrorMsg(errMsg);
        dispatch(setLoading(false));
      }
      dispatch(validateDealerSuccess({}));
    }
  }, [validDealer]);

  const contextDetails = useSelector(
    (state: any) => state?.commonReducer?.contextDetails
  );

  const prodCategoryResponse = useSelector(
    (state: any) => state?.commonReducer?.prodCategoryResponse
  );

  useEffect(() => {
    if (
      !_.isEmpty(prodCategoryResponse) && 
      prodCategoryResponse?.success &&
      window.sessionStorage.getItem("changeDealerSuccess") === "true"
    ) {
      let currentRoles = userDetails?.businessGroups.find(
        (bg: any) => bg.name === selectedBg
      )?.roles;
      let bgName = selectedBg;
      let details = contextDetails?.dealer;
      if (currentRoles?.length > 0) {
        dispatch(
          setSonyMenuItems(
            createMenuItems(
              siteSectionDetails,
              currentRoles,
              bgName,
              userDetails?.profile?.locale,
              userDetails,
              prodCategoryResponse?.data?.catalogList[0],
              details?.ecot3,
            )
          )
        );
      }
      dispatch(getContextDetailsSuccess([]));
      dispatch(saveQuoteRefData({
        customerReference: ""
      }))
      dispatch(saveQuoteSpecialInstructionData({
        specialInstruction: []
      }))
      dispatch(saveMultiAddresses({multiSelectArray: []}));
      dispatch(getCartProductsSuccess({}));
      window.sessionStorage.setItem("changeDealerSuccess", "false");
      dispatch(setLoading(false));
      dispatch(
        getContextDetails({
          key: CONTEXT_ORDER?.RPEORTING_PAGE,
        })
      );
      navigate(`/${ROUTES_LABEL.SONY_HOMEPAGE}`);
    }
  }, [prodCategoryResponse]);

  useEffect(() => {
    if (
      !_.isEmpty(contextDetails) && 
      !_.isEmpty(contextDetails?.dealer) &&
      window.sessionStorage.getItem("changeDealerSuccess") === "true"
    ) {
      let details = contextDetails?.dealer;
      dispatch(
        setCurrentDealer({
          bgName: selectedBg,
          accNum: formattedDealerId,
          accountName: details?.name,
          street: details?.street,
          country: details?.country,
          townCity: details?.townCity,
          loggedInUser: currentDealer?.loggedInUser,
          singleAccount: currentDealer?.singleAccount,
          accountFlag: details?.currentAccountFlag,
          ecot3: details?.ecot3,
        })
      );
      dispatch(
        setCurrentDealerInContext({
          accountFlag: details?.currentAccountFlag,
          accountId: formattedDealerId,
          msa: selectedBg,
        })
      );
      dispatch(
        getSalesAreaMapping({
          selectedDealership: formattedDealerId,
          localeID: selectedBg,
        })
      );
    }
  }, [contextDetails]);

  const setCurrentDealerSuccessRes = useSelector(
    (state: any) => state?.commonReducer?.setCurrentDealerSuccessRes
  );

  const currentLocale = useSelector(
    (state: any) => state?.commonReducer?.locale
  );

  const setProductResponse = useSelector(
    (state: any) => state?.commonReducer?.productFlow
  );
  
  const dispatchSetProduct = () => {
    dispatch(
      startProductFlow({
        siteLocale: currentLocale,
      })
    );
  };

  const dispatchFetchProdCategory = () => {
    dispatch(fetchProdCategoryResponse({}));
  };

  useEffect(() => {
    if (
      setProductResponse?.success && 
      window.sessionStorage.getItem("changeDealerSuccess") === "true"
    ) {
      dispatchFetchProdCategory();
    }
  }, [setProductResponse]);

  useEffect(() => {
    if (
      setCurrentDealerSuccessRes?.success &&
      window.sessionStorage.getItem("changeDealerSuccess") === "true"
    ) {
      dispatchSetProduct();
    }
  }, [setCurrentDealerSuccessRes]);

  return (
    <>
      <Typography variant="subtitle1" className={styles.header} pb={3} color="primary">
        {t("dnet.change_dealer.text")}
      </Typography>
      {errorMsg && (
        <div className={styles.errorMessage}>
          <CancelOutlinedIcon />
          <Typography variant="body2" ml={1}>
            {errorMsg}
          </Typography>
        </div>
      )}
      <Box className={styles.boxContainer} mb={3}>
        <CustomTextField
          id="customerNumber"
          label={t("dnet.customer_number.text")}
          value={customerNumber}
          changeHandler={(e: any) => {
            setCustomerNumber(e.target.value);
          }}
          type="text"
        />
        <span className={styles.changeButton}>
          <CustomButton
            id="changeButton"
            label={t("dnet.change.text")}
            onClick={changeHandler}
            color="primary"
            margin="0"
          />
        </span>
      </Box>
      <FormLabel>{t("dnet.business_group.text")}</FormLabel>
      <Grid container mt={0.8}>
        {businessGroups?.map((bg: any) => (
          <Grid item lg={4} md={6} sm={6} xs={12} key={bg}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={selectedBg}
              onChange={handleBgChange}
            >
              <FormControlLabel
                sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px" } }}
                value={bg}
                control={<Radio />}
                label={t(`dnet.msa_${bg}.text`)}
              />
            </RadioGroup>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
