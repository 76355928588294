import CloseIcon from "@mui/icons-material/Close";
import { Divider, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MODAL_TYPES } from "../../../../constants/commonConstants";
import { DataDownloadDetails } from "./dataDownloadDetails";
import { ImageDetails } from "./imageDetails";
import { PrintDetails } from "./printDetails";
import styles from "./prodDetailsModal.module.scss";
import { VideoDetails } from "./videoDetails";

var _ = require("lodash");

export const ProdDetailsModal = (props: any) => {
  const { open, closeModal, type, data } = props;

  const { t } = useTranslation();

  const printDetails = {
    title: t("dnet.print_options.text"),
    webImages: ["https://sp.sony-europe.com/da/741/232433.jpeg"], //productSheet.firstProductImage('webImages')
    techSpecs: "test", //productSheet.techSpecs()
    iconDescription: "", // productSheet.iconDescription()
    inTheBox: "", //productSheet.inTheBox()
    keyTechSpecs: "", //productSheet.keyTechSpecs()
    overview: "test", //productSheet.proOverview()
    benefits: "test", //productSheet.proBenefit()
    features: "test", //productSheet.featureBenefits()
    accessoryList: [],
    awards: "", //productSheet.awards()
    expertReviews: "", //productSheet.expertReviews()
    ecoEnergy: "", //productSheet.ecoEnergy()
    firstShipmentMonth: "",
    EShotinPrintPage: false, //context.masterSalesArea.EShotinPrintPage
    displayRetailPrice: false, //displayRetailPriceWarning and not empty price.retailPrice
    price: "12321", //price.price
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.modalStyle}>
          <Box className={styles.headerStyle}>
            <Typography variant="subtitle2" color="primary">
              {data?.title}
            </Typography>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
          {type === MODAL_TYPES.IMAGES && !_.isEmpty(data) && (
            <ImageDetails modalDetails={data} />
          )}
          {type === MODAL_TYPES.VIDEOS && !_.isEmpty(data) && (
            <VideoDetails modalDetails={data} />
          )}
          {(type === MODAL_TYPES.DIGITAL_ASSET || type === MODAL_TYPES.PRODUCT_DATA) &&
            !_.isEmpty(data) && (
              <DataDownloadDetails modalDetails={data} />
            )}
          {type === MODAL_TYPES.PRINT && (
            <PrintDetails modalDetails={data}/>
          )}
        </Box>
      </Modal>
    </div>
  );
};
