import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SYSTEM_ADMIN } from "../../../constants/labels";
import { getProductVisibilityData, getProductVisibilityDataSuccess } from "../../../state/actions";
import CustomButton from "../../atoms/customButton";
import CustomTextField from "../../atoms/customTextField";
import styles from "./productVisibility.module.scss";
var _ = require("lodash");

export const ProductVisibility = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //stores the material Name entered
  const [materialName, setMaterialName] = useState<any>("");
  const [message, setMessage] = useState<any>("");

  const productVisibilityData = useSelector(
    (state: any) => state?.systemAdminReducer?.productVisibilityData
  );

  const materialNameHandler = (e: any) => {
    setMaterialName(e?.target?.value);
  };

  const checkHandler = () => {
    setMessage("");
    if (!materialName) {
      setMessage(SYSTEM_ADMIN.VALID_MATERIAL_NAME);
    } else {
      dispatch(getProductVisibilityData({ materialName }));
    }
  };

  useEffect(() => {
      dispatch(getProductVisibilityDataSuccess({}));
  }, []);

  useEffect(() => {
    return () => {
     dispatch(getProductVisibilityDataSuccess({}));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!_.isEmpty(productVisibilityData)) {
      setMessage(productVisibilityData?.message);
    }
  }, [productVisibilityData]);

  return (
    <>
      <Typography variant="subtitle2" color="primary" mb={1}>
        {SYSTEM_ADMIN.MATERIAL_NAME}
      </Typography>
      <Grid container spacing={2} justifyItems={"center"} alignItems={"center"}>
        <Grid item lg={8} md={10} sm={12} xs={12}>
          <CustomTextField
            additionalSxProperties={{ width: "100%" }}
            label=""
            changeHandler={materialNameHandler}
            value={materialName}
          />
        </Grid>
        <Grid item lg={1} md={1} sm={1} xs={1}>
          <CustomButton
            label={SYSTEM_ADMIN.CHECK}
            color="primary"
            onClick={() => checkHandler()}
            margin={0}
          />
        </Grid>
      </Grid>
      {message && (
        <Box mt={2} className={styles.infoStyle}>
          <Typography component="div" variant="caption">
            {message}
          </Typography>
        </Box>
      )}
      {productVisibilityData?.products?.length > 0 && (
        <>
          <Box mt={2}>
            <Typography variant="body1" color="primary" mb={1}>
              {SYSTEM_ADMIN.USER_DATA}
            </Typography>
            <TableContainer
              component={Paper}
              className={styles.tableContainer}
              style={{ maxHeight: 350 }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>{SYSTEM_ADMIN.SALES_AREA}</TableCell>
                    <TableCell>{SYSTEM_ADMIN.PLT}</TableCell>
                    <TableCell>{SYSTEM_ADMIN.EPLT}</TableCell>
                    <TableCell>{SYSTEM_ADMIN.LISTING_CODE}</TableCell>
                    <TableCell>{SYSTEM_ADMIN.SALES_STATUS}</TableCell>
                    <TableCell>{SYSTEM_ADMIN.INCLUDED_SAP_PRODS}</TableCell>
                    <TableCell>{SYSTEM_ADMIN.EXCLUDED_SAP_PRODS}</TableCell>
                    <TableCell>
                      {SYSTEM_ADMIN.HIERARCHICAL_EXCLUDED_PRODUCTS}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={{ verticalAlign: "top" }}>
                      {productVisibilityData?.userQueryParams?.salesAreaPriceTuple?.map(
                        (tuple: any, index: any) => (
                          <span key={index}>
                            {tuple?.salesArea}
                            <br />
                          </span>
                        )
                      )}
                    </TableCell>
                    <TableCell sx={{ verticalAlign: "top" }}>
                      {productVisibilityData?.userQueryParams?.salesAreaPriceTuple?.map(
                        (tuple: any, index: any) => (
                          <span key={index}>
                            {tuple?.priceListingType === "noplt"
                              ? "00"
                              : tuple?.priceListingType}
                            <br />
                          </span>
                        )
                      )}
                    </TableCell>
                    <TableCell sx={{ verticalAlign: "top" }}>
                      {productVisibilityData?.userQueryParams?.salesAreaPriceTuple?.map(
                        (tuple: any, index: any) => (
                          <span key={index}>
                            {tuple?.extendedPriceListingType === "noeplt"
                              ? "00"
                              : tuple?.extendedPriceListingType}
                            <br />
                          </span>
                        )
                      )}
                    </TableCell>
                    <TableCell sx={{ verticalAlign: "top" }}>
                      {productVisibilityData?.userQueryParams?.includeListingCodes[0]?.map(
                        (listingCode: any, index: any) => (
                          <span key={index}>
                            {listingCode}
                            <br />
                          </span>
                        )
                      )}
                    </TableCell>
                    <TableCell sx={{ verticalAlign: "top" }}>
                      {productVisibilityData?.salesStatuses?.map(
                        (salesStatus: any, index: any) => (
                          <span key={index}>
                            {salesStatus === ""
                              ? SYSTEM_ADMIN.EMPTY
                              : salesStatus}
                            <br />
                          </span>
                        )
                      )}
                    </TableCell>
                    <TableCell sx={{ verticalAlign: "top" }}>
                      {productVisibilityData?.userQueryParams?.includedSapProducts?.map(
                        (product: any, index: any) => (
                          <span key={index}>
                            {product}
                            <br />
                          </span>
                        )
                      )}
                    </TableCell>
                    <TableCell sx={{ verticalAlign: "top" }}>
                      {productVisibilityData?.userQueryParams?.excludedSapProducts?.map(
                        (product: any, index: any) => (
                          <span key={index}>
                            {product}
                            <br />
                          </span>
                        )
                      )}
                    </TableCell>
                    <TableCell sx={{ verticalAlign: "top" }}>
                      {productVisibilityData?.userQueryParams?.hierarchicalExcludedProducts?.map(
                        (product: any, index: any) => (
                          <span key={index}>
                            {product} <br />
                          </span>
                        )
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box mt={2}>
            <Typography variant="body1" color="primary" mb={1}>
              {SYSTEM_ADMIN.PRODUCT_DATA}
            </Typography>
            <TableContainer component={Paper} className={styles.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{SYSTEM_ADMIN.SALES_AREA}</TableCell>
                    <TableCell>
                      {SYSTEM_ADMIN.PLT}&nbsp;
                      {productVisibilityData?.usePlt
                        ? SYSTEM_ADMIN.ON
                        : SYSTEM_ADMIN.OFF}
                    </TableCell>
                    <TableCell>
                      {SYSTEM_ADMIN.EPLT}&nbsp;
                      {productVisibilityData?.useEplt
                        ? SYSTEM_ADMIN.ON
                        : SYSTEM_ADMIN.OFF}
                    </TableCell>
                    <TableCell>
                      {SYSTEM_ADMIN.LISTING_CODE}&nbsp;
                      {productVisibilityData?.useListingCode
                        ? SYSTEM_ADMIN.ON
                        : SYSTEM_ADMIN.OFF}
                    </TableCell>
                    <TableCell>{SYSTEM_ADMIN.SALES_STATUS}</TableCell>
                    <TableCell>{SYSTEM_ADMIN.HIERARCHY_CODE}</TableCell>
                  </TableRow>
                </TableHead>
                {/* <TableBody>
                  {productVisibilityData?.products?.map(
                    (product: any, index: number) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{product[2]?.id?.salesArea}</TableCell>
                        <TableCell>{product[2]?.id?.priceListType}</TableCell>
                        <TableCell>
                          {product[2]?.id?.extendedPriceListType}
                        </TableCell>
                        <TableCell>{product[1]?.listingGroupCode}</TableCell>

                        <TableCell>
                          {product[1]?.salesStatus
                            ? product[1]?.salesStatus
                            : SYSTEM_ADMIN.EMPTY}
                        </TableCell>
                        <TableCell>{product[1]?.hierarchyCode}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody> */}
                <TableBody>
                  {productVisibilityData?.products?.map(
                    (product: any, index: number) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{product?.detail?.sku?.salesArea}</TableCell>
                        <TableCell>{product?.price?.priceListType}</TableCell>
                        <TableCell>
                        {product?.price?.extPriceListTyp
                            ? product?.price?.extPriceListTyp
                            : "00"}
                          {}
                        </TableCell>
                        <TableCell>{product?.detail?.listingGroupCode}</TableCell>

                        <TableCell>
                          {product?.detail?.salesStatus
                            ? product?.detail?.salesStatus
                            : SYSTEM_ADMIN.EMPTY}
                        </TableCell>
                        <TableCell>{product?.detail?.thirdLevelHierarchyCode}{product?.detail?.productHierarchyCode}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
    </>
  );
};
