import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ArticlesList } from "../../cms/articlesList";

export const GeneralInformation = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Typography variant="subtitle2" pb={3} color="primary">
        {t("dnet.general_information.text")}
      </Typography>
      <ArticlesList parent="generalInformation" />
    </div>
  );
};
