import React from "react";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";
import "./finalBalanceTable.scss";
import { useTranslation } from "react-i18next";

const FinalBalanceTable = () => {
  const { t } = useTranslation();
  const [rows, setRows] = useState<any>([]);
  const finalBalanceTableData = useSelector(
    (state: any) => state?.gazelleReducer?.paymentDetailsData
  );

  interface Column {
    id: "doct1" | "valueDate" | "finalBalance";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
  }

  const columns: readonly Column[] = [
    { id: "doct1", label: t("gzl.invoice.text") },
    { id: "valueDate", label: t("gzl.due_on.text") },
    { id: "finalBalance", label: t("gzl.org_inv_value.text") },
  ];

  interface Data {
    doct1: any;
    valueDate: any;
    finalBalance: any;
  }

  function createData(doct1: any, valueDate: any, finalBalance: any): Data {
    return { doct1, valueDate, finalBalance };
  }

  const createRowData = () => {
    // Rows data creation from API response
    let data = finalBalanceTableData;
    let rowsdata = [];
    rowsdata.push(createData(data?.doct1, data?.valueDate, data?.finalBalance));
    setRows([...rowsdata]);
  };

  useEffect(() => {
    createRowData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalBalanceTableData]);

  return (
    <>
      <TableContainer component={Paper} className="tableContainerHeader">
        <Table sx={{ minWidth: 650 }} aria-label="Payments Table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.id === "doct1" &&
                  finalBalanceTableData.finalBalance < 0
                    ? t("gzl.credit.text")
                    : column.id === "valueDate" &&
                      finalBalanceTableData.finalBalance < 0
                    ? t("gzl.due_date.text")
                    : column.id === "finalBalance" &&
                      finalBalanceTableData.finalBalance < 0
                    ? t("gzl.org_credit_value.text")
                    : column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length > 0 ? (
              rows.map((row: any, key: any) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  align={"center"}
                  className="noRecFound"
                >
                  {t("gzl.no_data_available.text")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default FinalBalanceTable;
