import React from "react";
import { Statements } from "./components/gazelle/statements";
import { AccountInformation } from "./components/gazelle/account-information";
import styles from "./App.module.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ROUTES_LABEL } from "./constants/routesLabels";
import { OpenItems } from "./components/gazelle/openItems";
import { PaidItems } from "./components/gazelle/paidItems";
import { Payments } from "./components/gazelle/payments";
import { Loader } from "./components/atoms/loader";
import { DebitNoteCreation } from "./components/gazelle/debit-note-creation";
import { ToastMessage } from "./components/atoms/toastMessage";
import { PaidItemDetails } from "./components/gazelle/paidItemDetails";
import { PaymentDetails } from "./components/gazelle/paymentDetails";
import { CreateEnquiry } from "./components/gazelle/createEnquiry";
import { ReplyEnquiry } from "./components/gazelle/reply-enquiry";
import { DisputedItems } from "./components/gazelle/disputed-items";
import { MakePayment } from "./components/gazelle/makePayment";
import { CreditManagementInfo } from "./components/gazelle/credit-management-info";
import { YourReferenceSearch } from "./components/gazelle/your-reference-search";
import { Login } from "./components/login";
import { DealerSelection } from "./components/dealer-selection";
import { Registration } from "./components/registration";
import { useSelector } from "react-redux";
import ProtectedRoutes from "./ProtectedRoutes";
import ScrollToTop from "./scrollToTop";
import CountrySelect from "./components/countrySelect";
import { ResetPassword } from "./components/resetPassword";
import { SonyHome } from "./components/sonyHome";
import { Profile } from "./components/profile";
import { PriceChangeReport } from "./components/priceChangeReport";
import { ChangeDealer } from "./components/changeDealer";
import { ProductRange } from "./components/products/productRange";
import { ProdContentDetail } from "./components/products/prodContentDetail";
import { NewProducts } from "./components/products/newProducts";
import { ProductDetails } from "./components/products/productDetails";
import { Reporting } from "./components/orderManagement/reporting";
import { OrderStatus } from "./components/orderManagement/reporting/orderStatus";
import { ManageReports } from "./components/orderManagement/manageReports";
import { ProductLists } from "./components/orderManagement/productLists";
import { OrderEntry } from "./components/orderManagement/orderEntry";
import { Quotation } from "./components/orderManagement/quotation";
import { SpecialInstructions } from "./components/orderManagement/quotation/specialInstructions";
import { ReceiptPage } from "./components/orderManagement/quotation/receiptPage";
import { GradedStock } from "./components/orderManagement/gradedStock";
import { DataDownload } from "./components/dataDownload";
import { ProductVisibility } from "./components/systemAdmin/productVisibility";
import { SalesRepVisibility } from "./components/systemAdmin/salesRepVisibility";
import { Contact } from "./components/userProfile/contact";
import { Links } from "./components/userProfile/links";
import { Help } from "./components/userProfile/help";
import { ProductCategory } from "./components/products/productCategory";
import { GlobalSearch } from "./components/globalSearch";
import { GeneralInformation } from "./components/serviceAndSupport/generalInformation";
import { ArticleDetailsPage } from "./components/cms/articleDetailsPage";
import { TermsAndCondition } from "./components/termsAndCondition";
import { SERPGeneralInformation } from "./components/SERP";
import { MyQuotations } from "./components/myQuotations";
import { QuotationsDetails } from "./components/myQuotations/quotationDetails";
import { CompareProducts } from "./components/products/compareProducts";
import {PremiumServices} from "./components/premiumServices";
import { OrderChangeReport } from "./components/orderManagement/orderChangeReport";
import { OrderChangeHistory } from "./components/orderManagement/orderChangeReport/orderChangeHistroy";
import { PrimeSupportRegistration } from "./components/orderManagement/primeSupportRegistration";
import { ResellerProdContent } from "./components/products/resellerProdContent";
import NotFoundPage from "./NotFoundPage";

function App() {
  // Below are all the routes to link the Application components
  return (
    <div>
      <Router>
        <ScrollToTop />
          <Routes>
            <Route path={`/${ROUTES_LABEL.COUNTRY_SELECT}`} element={<CountrySelect />} />
            <Route path={`/${ROUTES_LABEL.LOGIN}`} element={<Login />} />
            <Route path={`/${ROUTES_LABEL.REGISTRATION}`} element={<Registration />} />
            <Route
              path={`/${ROUTES_LABEL.HOME}`}
              element={<ProtectedRoutes Component={AccountInformation} />}
            />
            <Route
              path={`/${ROUTES_LABEL.OPEN_ITEMS}`}
              element={<ProtectedRoutes Component={OpenItems} />}
            />
            <Route
              path={`/${ROUTES_LABEL.STATEMENTS}`}
              element={<ProtectedRoutes Component={Statements} />}
            />
            <Route
              path={`/${ROUTES_LABEL.PAID_ITEMS}`}
              element={<ProtectedRoutes Component={PaidItems} />}
            />
            <Route
              path={`/${ROUTES_LABEL.PAYMENTS}`}
              element={<ProtectedRoutes Component={Payments} />}
            />
            <Route
              path={`/${ROUTES_LABEL.DEBIT_NOTE_CREATION}`}
              element={<ProtectedRoutes Component={DebitNoteCreation} />}
            />
            <Route
              path={`/${ROUTES_LABEL.PAID_ITEM_DETAILS}`}
              element={<ProtectedRoutes Component={PaidItemDetails} />}
            />
            <Route
              path={`/${ROUTES_LABEL.PAYMENT_DETAILS}`}
              element={<ProtectedRoutes Component={PaymentDetails} />}
            />
            <Route
              path={`/${ROUTES_LABEL.CREATE_ENQUIRY}`}
              element={<ProtectedRoutes Component={CreateEnquiry} />}
            />
            <Route
              path={`/${ROUTES_LABEL.DISPUTED_ITEMS}`}
              element={<ProtectedRoutes Component={DisputedItems} />}
            />
            <Route
              path={`/${ROUTES_LABEL.OPEN_ITEMS}/${ROUTES_LABEL.MAKE_PAYMENT}`}
              element={<ProtectedRoutes Component={MakePayment} />}
            />
            <Route
              path={`/${ROUTES_LABEL.REPLY_ENQIRY}`}
              element={<ProtectedRoutes Component={ReplyEnquiry} />}
            />
            <Route
              path={`/${ROUTES_LABEL.CREDIT_MANAGEMENT}`}
              element={<ProtectedRoutes Component={CreditManagementInfo} />}
            />
            <Route
              path={`/${ROUTES_LABEL.YOUR_REFERENCE_SEARCH}`}
              element={<ProtectedRoutes Component={YourReferenceSearch} />}
            />
            <Route 
              path={`/${ROUTES_LABEL.DEALER_SELECTION}`} 
              element={<ProtectedRoutes Component={DealerSelection} />} />
            <Route 
              path={`/${ROUTES_LABEL.SONY_HOMEPAGE}`} 
              element={<ProtectedRoutes Component={SonyHome} />} />
            <Route 
              path={`/${ROUTES_LABEL.RESET_PASSWORD}`} 
              element={<ResetPassword />} />
            <Route 
              path={`/${ROUTES_LABEL.UPDATE_PROFILE}`} 
              element={<ProtectedRoutes Component={Profile} />} />
            <Route 
              path={`/${ROUTES_LABEL.PRICE_CHANGES}`} 
              element={<ProtectedRoutes Component={PriceChangeReport} />} />
            <Route 
              path={`/${ROUTES_LABEL.CHANGE_DEALER}`} 
              element={<ProtectedRoutes Component={ChangeDealer} />} />
            <Route 
              path={`/${ROUTES_LABEL.PRODUCT_RANGE}`} 
              element={<ProtectedRoutes Component={ProductRange} />} />
            <Route 
              path={`/${ROUTES_LABEL.PROD_CONTENT_DETAIL}`} 
              element={<ProtectedRoutes Component={ProdContentDetail} />} />
              <Route 
              path={`/${ROUTES_LABEL.RESELLER_PROD_CONTENT_DETAILS}`} 
              element={<ProtectedRoutes Component={ResellerProdContent} />} />
            <Route 
              path={`/${ROUTES_LABEL.PRODUCT_DETAILS}/*`} 
              element={<ProtectedRoutes Component={ProductDetails} />} />
            <Route 
              path={`/${ROUTES_LABEL.PRODUCT_COMPARE}/*`} 
              element={<ProtectedRoutes Component={CompareProducts} />} />
            <Route 
              path={`/${ROUTES_LABEL.REPORTING}`} 
              element={<ProtectedRoutes Component={Reporting} />} />
            <Route 
              path={`/${ROUTES_LABEL.REPORTING}/${ROUTES_LABEL.ORDER_STATUS}`} 
              element={<ProtectedRoutes Component={OrderStatus} />} />
            <Route 
              path={`/${ROUTES_LABEL.REPORTING}/${ROUTES_LABEL.MANAGE_REPORTS}`} 
              element={<ProtectedRoutes Component={ManageReports} />} />
            <Route 
              path={`/${ROUTES_LABEL.MANAGE_REPORTS}`} 
              element={<ProtectedRoutes Component={ManageReports} />} />
            <Route 
              path={`/${ROUTES_LABEL.PRODUCT_LISTS}`} 
              element={<ProtectedRoutes Component={ProductLists} />} />
            <Route 
              path={`/${ROUTES_LABEL.ORDER_ENTRY}`} 
              element={<ProtectedRoutes Component={OrderEntry} />} />
            <Route 
              path={`/${ROUTES_LABEL.ORDER_ENTRY}/${ROUTES_LABEL.QUOTATION}`} 
              element={<ProtectedRoutes Component={Quotation} />} />
            <Route 
              path={`/${ROUTES_LABEL.ORDER_ENTRY}/${ROUTES_LABEL.QUOTATION}/${ROUTES_LABEL.SPECIAL_INSTRUCTIONS}`} 
              element={<ProtectedRoutes Component={SpecialInstructions} />} />
            <Route 
              path={`/${ROUTES_LABEL.ORDER_ENTRY}/${ROUTES_LABEL.QUOTATION}/${ROUTES_LABEL.RECEIPT_OF_ORDER}`} 
              element={<ProtectedRoutes Component={ReceiptPage} />} />
            <Route 
              path={`/${ROUTES_LABEL.ORDER_MANAGEMENT}/${ROUTES_LABEL.GRADED_STOCK}`} 
              element={<ProtectedRoutes Component={GradedStock} />} />
            <Route
              path={`/${ROUTES_LABEL.DATA_DOWNLOAD}`} 
              element={<ProtectedRoutes Component={DataDownload} />} />
            <Route
              path={`/${ROUTES_LABEL.DATA_DOWNLOAD}/${ROUTES_LABEL.DIGITAL_ASSET}`} 
              element={<ProtectedRoutes Component={DataDownload} />} />
            <Route 
              path={`/${ROUTES_LABEL.DATA_DOWNLOAD}/${ROUTES_LABEL.PRODUCT_DATA}`} 
              element={<ProtectedRoutes Component={DataDownload} />} />
            <Route 
              path={`/${ROUTES_LABEL.PRODUCT_VISIBILITY}`} 
              element={<ProtectedRoutes Component={ProductVisibility} />} />
            <Route 
              path={`/${ROUTES_LABEL.SALES_REP_VISIBILITY}`} 
              element={<ProtectedRoutes Component={SalesRepVisibility} />} />
            <Route 
              path={`/${ROUTES_LABEL.CONTACT}`} 
              element={<ProtectedRoutes Component={Contact} />} />
            <Route 
              path={`/${ROUTES_LABEL.LINKS}`} 
              element={<ProtectedRoutes Component={Links} />} />
            <Route 
              path={`/${ROUTES_LABEL.HELP}`} 
              element={<ProtectedRoutes Component={Help} />} />
            <Route
              path={`/${ROUTES_LABEL.PRODUCTS}`} exact
              element={<ProtectedRoutes Component={ProductRange} />} />      
            <Route
              path={`/${ROUTES_LABEL.PRODUCTS}/*`} 
              element={<ProtectedRoutes Component={ProductCategory} />} />
            <Route
              path={`/${ROUTES_LABEL.PRODUCT_RANGE}/*`} 
              element={<ProtectedRoutes Component={ProductCategory} />} />
            <Route 
              path={`/${ROUTES_LABEL.PROD_CONTENT_DETAIL}/*`} 
              element={<ProtectedRoutes Component={ProdContentDetail} />} /> 
            <Route 
              path={`/${ROUTES_LABEL.RESELLER_PROD_CONTENT_DETAILS}/*`} 
              element={<ProtectedRoutes Component={ResellerProdContent} />} /> 
            <Route  
              path={`/${ROUTES_LABEL.SERVCIE_SUPPORT}/${ROUTES_LABEL.GENERAL_INFORMATION}`} 
              element={<ProtectedRoutes Component={GeneralInformation} />} />
            <Route 
              path={`/${ROUTES_LABEL.DETIALS}`} 
              element={<ProtectedRoutes Component={ArticleDetailsPage} />} />
            <Route 
              path={`/${ROUTES_LABEL.TERMS_AND_CONDITION_OF_WEBSITE_USE}`} 
              element={<ProtectedRoutes Component={TermsAndCondition} />} />
            <Route 
              path={`/${ROUTES_LABEL.SERP}/${ROUTES_LABEL.GENERAL_INFORMATION}`} 
              element={<ProtectedRoutes Component={SERPGeneralInformation} />} />
            <Route 
              path={`/${ROUTES_LABEL.MY_QUOTATIONS}`} 
              element={<ProtectedRoutes Component={MyQuotations} />} />
            <Route 
              path={`/${ROUTES_LABEL.MY_QUOTATIONS}/${ROUTES_LABEL.QUOTATIONS_DETAILS}`} 
              element={<ProtectedRoutes Component={QuotationsDetails} />} />
            <Route
              path={`/${ROUTES_LABEL.NEW_PRODUCTS}`}
              element={<ProtectedRoutes Component={NewProducts} />}
              />
            <Route
              path={`/${ROUTES_LABEL.GLOBAL_SEARCH}`}
              element={<ProtectedRoutes Component={GlobalSearch} />}
            />
            <Route
              path={`/${ROUTES_LABEL.PREMIUM_SERVICES}`}
              element={<ProtectedRoutes Component={PremiumServices} />}
            />
            <Route
              path={`/${ROUTES_LABEL.ORDER_CHANGE_REPORT}`}
              element={<ProtectedRoutes Component={OrderChangeReport} />}
            />
            <Route
              path={`/${ROUTES_LABEL.ORDER_CHANGE_REPORT}/${ROUTES_LABEL.ORDER_CHANGE_HISTORY}`}
              element={<ProtectedRoutes Component={OrderChangeHistory} />}
            />
            <Route 
              path={`/${ROUTES_LABEL.ORDER_CHANGE_REPORT}/${ROUTES_LABEL.MANAGE_REPORTS}`} 
              element={<ProtectedRoutes Component={ManageReports} />} />
            <Route
              path={`/${ROUTES_LABEL.PRIME_SUPPORT_REGISTRATION}`}
              element={<ProtectedRoutes Component={PrimeSupportRegistration} />}
            />
            {/* Add new routes above this line */}
            {/* <Route
              path={`/${ROUTES_LABEL.REGISTRATION}`} 
              element={
                <Navigate
                  to={
                      `/${ROUTES_LABEL.REGISTRATION}`
                  }
                  replace
                />
              }
            /> */}
            <Route
              path="/"
              element={
                <Navigate
                  to={
                      `/${ROUTES_LABEL.COUNTRY_SELECT}`
                  }
                  replace
                />
              }
            />
            <Route
              path="*"
              // element={
              //   <Navigate
              //     to={
              //         `/${ROUTES_LABEL.LOGIN}`
              //     }
              //     replace
              //   />
              // }
              element={<NotFoundPage />}
            />
          </Routes>
        <Loader />
        <ToastMessage />
      </Router>
    </div>
  );
}

export default App;
