import { Box, Checkbox, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../../atoms/customButton";
import styles from "./displayCategories.module.scss";
var _ = require("lodash");
const noProduct = require("../../../assets/images/no_prod_tn.gif");

export const DisplayCategories = (props: any) => {
  const {
    selectedCategory,
    checkHandler,
    productClickHandler,
    displaySelected,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      {selectedCategory?.children?.length > 0 &&
        selectedCategory.children[0].children.length > 0 &&
        selectedCategory?.children?.map((subCat: any, index: any) => (
          <React.Fragment key={index}>
            {subCat?.children?.length > 0 && (
              <Box mb={2}>
                <Typography
                  component="div"
                  variant="body1"
                  mb={2}
                  color="primary"
                >
                  {subCat?.displayName}
                </Typography>
                <Grid container spacing={2}>
                  {subCat?.children?.map((sub: any, index: any) => (
                    <React.Fragment key={index}>
                      <Grid item lg={2} md={3} sm={4} xs={12} key={index}>
                        <Box
                          className={styles.imageContainer}
                          onClick={(e: any) =>
                            productClickHandler(
                              selectedCategory,
                              subCat,
                              sub
                            )
                          }
                        >
                          {sub?.image ? (
                            <img
                              src={sub?.image}
                              style={{maxHeight:"150px",maxWidth:"150px"}}
                              className={styles.imageStyle}
                            />
                          ) : (
                            <img
                              src={noProduct}
                              className={styles.noImageStyle}
                            />
                          )}
                          <span className={styles.checkboxStyle}>
                            <Checkbox
                              sx={
                                sub?.children?.length > 0
                                  ? { visibility: "hidden" }
                                  : {}
                              }
                              size="small"
                              onChange={(e: any) => {
                                checkHandler(e, sub, subCat);
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                          </span>
                        </Box>
                        <Typography
                          component="div"
                          variant="caption"
                          color="text.secondary"
                          mt={1}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              color: "black",
                              textDecoration: "underline",
                            },
                            wordWrap: "break-word",
                          }}
                          onClick={(e: any) =>
                            productClickHandler(
                              selectedCategory,
                              subCat,
                              sub
                            )
                          }
                        >
                          {sub?.displayName}
                        </Typography>
                      </Grid>
                      {!subCat?.children[index + 1] &&
                        !(
                          sub?.children?.length > 0 &&
                          sub?.children[0]?.children?.length > 0
                        ) && (
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            sx={{ textAlign: "end" }}
                          >
                            <CustomButton
                              label={t(
                                "dnet.product_multiple_series_button.text"
                              )}
                              color="primary"
                              onClick={(e: any) => displaySelected(subCat)}
                              margin={0}
                            />
                          </Grid>
                        )}
                    </React.Fragment>
                  ))}
                </Grid>
              </Box>
            )}
          </React.Fragment>
        ))}
      {selectedCategory?.children?.length > 0 &&
        selectedCategory.children[0].children.length === 0 && (
          <div {...props}>
            <Grid container spacing={2}>
              {selectedCategory?.children?.map((subCat: any, index: any) => (
                <React.Fragment key={index}>
                  <Grid item lg={2} md={3} sm={4} xs={6} key={index}>
                    <Box
                      className={styles.imageContainer}
                      onClick={(e: any) =>
                        productClickHandler(
                          selectedCategory,
                          subCat
                        )
                      }
                    >
                      {subCat?.image ? (
                        <img
                          src={subCat?.image}
                          style={{maxHeight:"150px",maxWidth:"150px"}}
                          className={styles.imageStyle}
                        />
                      ) : (
                        <img src={noProduct} className={styles.noImageStyle} />
                      )}
                      <span className={styles.checkboxStyle}>
                        <Checkbox
                          sx={
                            subCat?.children?.length > 0 &&
                            subCat?.children[0]?.children?.length > 0
                              ? { visibility: "hidden" }
                              : {}
                          }
                          size="small"
                          onChange={(e: any) => {
                            checkHandler(e, subCat, selectedCategory);
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        />
                      </span>
                    </Box>
                    <Typography
                      component="div"
                      variant="caption"
                      color="text.secondary"
                      mt={1}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          color: "black",
                          textDecoration: "underline",
                        },
                        wordWrap: "break-word",
                      }}
                      onClick={(e: any) =>
                        productClickHandler(
                          selectedCategory,
                          subCat
                        )
                      }
                    >
                      {subCat?.displayName}
                    </Typography>
                  </Grid>
                  {!selectedCategory?.children[index + 1] &&
                    !(
                      subCat?.children?.length > 0 &&
                      subCat?.children[0]?.children?.length > 0
                    ) && (
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{ textAlign: "end" }}
                      >
                        <CustomButton
                          label={t("dnet.product_multiple_series_button.text")}
                          color="primary"
                          onClick={(e: any) =>
                            displaySelected(selectedCategory)
                          }
                          margin={0}
                        />
                      </Grid>
                    )}
                </React.Fragment>
              ))}
            </Grid>
          </div>
        )}
    </>
  );
};
