import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import styles from "./articleDetailsPage.module.scss";
import { CMS } from "../../../constants/commonConstants";
import { Grid, Typography } from "@mui/material";
import CustomDropDown from "../../atoms/customDropDown";
import CustomButton from "../../atoms/customButton";
import {
  leftblockimgrightblcktxt,
  productLinkBlock,
} from "../articleDetailsPageContent";
import { mimeTypeImageMapping } from "../../helper/util";
import { useSelector } from "react-redux";

export const ArticleDetailsPage = ({ selectedArticle }: any) => {
  const location = useLocation();
  const { t } = useTranslation();
  let article =
    location?.state?.selectedArticle !== null &&
    location?.state?.selectedArticle !== undefined
      ? location?.state?.selectedArticle
      : selectedArticle;

  const setImageMimeType = (mimeType: any) => {
    const image = require("../../../assets/images/" +
      mimeTypeImageMapping(mimeType));
    return image;
  };

  let productLinkHeader = t("dnet.product_links.text");

  const [blockArticleDropdown, setBlockArticleDropdown] = useState<any>([]);
  const [selectedAddBlockArticleName, setSelectedAddBlockArticleName] =
    useState("");
  const [oneflashtwoblckArray, setOneflashtwoblckArray] = useState<any>([]);
  const [oneflashtwoblckImage, setOneflashtwoblckImage] = useState<any>({});
  const [twoblcktxtoneflashImage, setTwoblcktxtoneflashImage] = useState<any>(
    {}
  );
  const sonyMenuItems = useSelector(
    (state: any) => state?.commonReducer?.sonyMenuItems
  );

  const handlestep = (step: any) => {
    if (step === "back") {
      let back =
        blockArticleDropdown[
          blockArticleDropdown.indexOf(selectedAddBlockArticleName) - 1
        ];
      setSelectedAddBlockArticleName(back);
    } else {
      let next =
        blockArticleDropdown[
          blockArticleDropdown.indexOf(selectedAddBlockArticleName) + 1
        ];
      setSelectedAddBlockArticleName(next);
    }
  };

  useEffect(() => {
    if (article?.["related_items"]?.length > 0) {
      //if page_break_block is true and section_title is not blank then create dropdown
      let blockArticles: any = [...blockArticleDropdown];
      article?.["related_items"]?.forEach((item: any) => {
        if (
          item?.acf_fc_layout === CMS?.ADD_BLOCK &&
          item?.section_title !== "" &&
          item?.page_break_block
        ) {
          if (blockArticles?.indexOf(item?.section_title) < 0) {
            blockArticles.push(item?.section_title);
          }
        }
      });
      setBlockArticleDropdown(blockArticles);
      setSelectedAddBlockArticleName(blockArticles?.[0]);

      //if acf_fc_layout is add_block and
      //display_preference is oneflashtwoblcktxt or twoblcktxtoneflash
      //then create new array with those objects
      let tempArray: any = [...oneflashtwoblckArray];
      let imageIdObj: any = { ...oneflashtwoblckImage };
      let twoblcktxtoneflashImageObj: any = { ...twoblcktxtoneflashImage };
      article?.["related_items"]?.forEach((item: any, key: any) => {
        if (
          item?.acf_fc_layout === CMS?.ADD_BLOCK &&
          (item?.display_preference === "oneflashtwoblcktxt" ||
            item?.display_preference === "twoblcktxtoneflash")
        ) {
          let currItem: any = article?.["related_items"]?.slice(key, 1);
          tempArray.push(currItem?.[0]);
          if (
            item?.display_preference === "oneflashtwoblcktxt" &&
            Object.keys(oneflashtwoblckImage)?.length === 0 &&
            item?.block_image !== null &&
            item?.block_image
          ) {
            imageIdObj = item?.block_image;
          }
          if (
            item?.display_preference === "twoblcktxtoneflash" &&
            Object.keys(twoblcktxtoneflashImage)?.length === 0 &&
            item?.block_image !== null &&
            item?.block_image
          ) {
            twoblcktxtoneflashImageObj = item?.block_image;
          }
        }
      });
      setOneflashtwoblckArray(tempArray);
      setOneflashtwoblckImage(imageIdObj);
      setTwoblcktxtoneflashImage(twoblcktxtoneflashImageObj);
    }
  }, [article]);

  const addArticleContent = (item: any) => {
    return (
      <div className={styles.boxStyle}>
        <div className={styles.innerBoxStyle}>
          <img
            src={
              item?.article_image &&
              item?.article_image !== "" &&
              item?.article_image !== null
                ? item?.article_image?.url !== null &&
                  item?.article_image?.url !== ""
                  ? item?.article_image?.url
                  : setImageMimeType(item?.article_image?.mime_Type)
                : item?.article_thumbnail_image &&
                  Object.keys(item?.article_thumbnail_image)?.length > 0 &&
                  item?.article_thumbnail_image?.url
            }
            style={{
              width: item?.article_image?.width,
              height: item?.article_image?.height,
            }}
          />
          <div>
            {item?.article_title !== null &&
              item?.article_title !== "" &&
              item?.article_title !== "resource{dnet.contact.section}" && (
                <Typography 
                  variant="subtitle2" 
                  color="primary" 
                  mb={2} 
                  dangerouslySetInnerHTML={{
                    __html: item?.article_title?.toString(),
                  }} 
                />
                //   {item?.article_title}
                // </Typography>
              )}
            {item?.article_long_title !== null &&
              item?.article_long_title !== "" && (
                <Typography variant="body2" mb={1}>
                  {item?.article_long_title}
                </Typography>
              )}
            {item?.introduction !== null && item?.introduction !== "" && (
              <Typography
                variant="body2"
                mb={1}
                dangerouslySetInnerHTML={{
                  __html: item?.introduction?.toString(),
                }}
              />
            )}
            {blockArticleDropdown?.length > 0 && (
              <CustomDropDown
                id="queryType"
                value={selectedAddBlockArticleName}
                handleChange={(e: any) =>
                  setSelectedAddBlockArticleName(e.target.value)
                }
                menuItems={blockArticleDropdown}
                // label=""
              />
            )}
          </div>
        </div>
        {oneflashtwoblckImage !== null &&
          oneflashtwoblckImage &&
          oneflashtwoblckArray?.length > 0 && (
            <div>
              <img
                src={oneflashtwoblckImage?.url}
                alt={oneflashtwoblckImage?.alt}
              />
            </div>
          )}
      </div>
    );
  };
  return (
    <div>
      <div>
        {blockArticleDropdown?.length > 0 ? (
          <Grid container spacing={2}>
            <Grid item md={12} lg={9}>
              <div className={styles.articleBlockStyle}>
                <div>{addArticleContent(article?.["related_items"]?.[0])}</div>
                <Grid container>
                  {article?.["related_items"]?.map((item: any) => {
                    return (
                      item?.section_title === selectedAddBlockArticleName &&
                      (item?.display_preference ===
                      "leftblcktxtrightblckimg" ? (
                        <Grid item xs={12}>
                          {leftblockimgrightblcktxt(item, "reverse")}
                        </Grid>
                      ) : item?.display_preference === "blckimgblcktxt" ? (
                        <Grid item xs={12} sm={6}>
                          {leftblockimgrightblcktxt(item, "block")}
                        </Grid>
                      ) : item?.display_preference === "blcktxtblckimg" ? (
                        <Grid item xs={12} sm={6}>
                          {leftblockimgrightblcktxt(item, "reverseBlock")}
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          {leftblockimgrightblcktxt(item, "")}
                        </Grid>
                      ))
                    );
                  })}
                  <div className={styles.buttonStyle}>
                    {blockArticleDropdown.indexOf(selectedAddBlockArticleName) >
                      0 &&
                      blockArticleDropdown.indexOf(
                        selectedAddBlockArticleName
                      ) < blockArticleDropdown?.length && (
                        <CustomButton
                          id="back"
                          label={t("dnet.back.text")}
                          onClick={() => handlestep("back")}
                          margin="0"
                        />
                      )}
                    {blockArticleDropdown.indexOf(selectedAddBlockArticleName) >
                      -1 &&
                      blockArticleDropdown.indexOf(
                        selectedAddBlockArticleName
                      ) <
                        blockArticleDropdown?.length - 1 && (
                        <CustomButton
                          id="next"
                          label={t("dnet.next.text")}
                          onClick={() => handlestep("next")}
                          margin="0 10px"
                        />
                      )}
                  </div>
                </Grid>
              </div>
            </Grid>
            <Grid item md={12} lg={3}>
              {article?.product_main_category !== "" &&
                article?.product_main_category?.length > 0 &&
                article?.product_main_category &&
                productLinkBlock(
                  article?.product_main_category,
                  productLinkHeader,
                  sonyMenuItems
                )}
            </Grid>
          </Grid>
        ) : Object.keys(article)?.length === 0 ? (
          <div className={styles.articleBlockStyle}>
            <Typography variant="caption">
              {t("dnet.homepage_articles_error.text")}
            </Typography>
          </div>
        ) : (
          <Grid container spacing={2}>
            <Grid item md={12} lg={9} sx={{width: "100%"}}>
              <div className={styles.articleBlockStyle}>
                <Grid container>
                  {article?.["related_items"]?.map((item: any, key: any) => {
                    return item?.acf_fc_layout === CMS?.ADD_ARTICLE ? (
                      <Grid item xs={12} key={key}>
                        {addArticleContent(item)}
                      </Grid>
                    ) : item?.display_preference ===
                      "leftblcktxtrightblckimg" ? (
                      <Grid item xs={12} key={key}>
                        {leftblockimgrightblcktxt(item, "reverse")}
                      </Grid>
                    ) : item?.display_preference === "blckimgblcktxt" ? (
                      <Grid item xs={12} sm={6} key={key}>
                        {leftblockimgrightblcktxt(item, "block")}
                      </Grid>
                    ) : item?.display_preference === "blcktxtblckimg" ? (
                      <Grid item xs={12} sm={6} key={key}>
                        {leftblockimgrightblcktxt(item, "reverseBlock")}
                      </Grid>
                    ) : item?.display_preference === "oneflashtwoblcktxt" ? (
                      <Grid item md={5} sm={6} xs={12} key={key}>
                        {leftblockimgrightblcktxt(item, "")}
                      </Grid>
                    ) : item?.display_preference === "twoblcktxtoneflash" ? (
                      <Grid item md={5} sm={6} xs={12} key={key}>
                        {leftblockimgrightblcktxt(item, "")}
                      </Grid>
                    ) : item?.display_preference === "blcktimgcrossblcktxt" ? (
                      <Grid item xs={12} key={key}>
                        {leftblockimgrightblcktxt(
                          item,
                          article?.["related_items"]?.[key - 1]
                            ?.display_preference === item?.display_preference
                            ? "reverse"
                            : ""
                        )}
                      </Grid>
                    ) : item?.display_preference === "blcktxtcrossblckimg" ? (
                      <Grid item xs={12} key={key}>
                        {leftblockimgrightblcktxt(
                          item,
                          article?.["related_items"]?.[key - 1]
                            ?.display_preference === item?.display_preference
                            ? ""
                            : "reverse"
                        )}
                      </Grid>
                    ) : (
                      <Grid item xs={12} key={key}>
                        {leftblockimgrightblcktxt(item, "")}
                      </Grid>
                    );
                  })}
                  <Grid item xs={12}>
                    {twoblcktxtoneflashImage !== null &&
                      twoblcktxtoneflashImage &&
                      oneflashtwoblckArray?.length > 0 && (
                        <div>
                          <img
                            src={twoblcktxtoneflashImage?.url}
                            alt={twoblcktxtoneflashImage?.alt}
                          />
                        </div>
                      )}
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item md={12} lg={3}>
              {article?.product_main_category !== "" &&
                article?.product_main_category?.length > 0 &&
                article?.product_main_category &&
                productLinkBlock(
                  article?.product_main_category,
                  productLinkHeader,
                  sonyMenuItems
                )}
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};

ArticleDetailsPage.defaultProps = {
  selectedArticle: {},
};
