import React from "react";
import styles from "./links.module.scss";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArticlesList } from "../../cms/articlesList";

export const Links = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Typography
        variant="subtitle1"
        className={styles.header}
        pb={3}
        color="primary"
      >
        {t("dnet.link.section")}
      </Typography>
      <ArticlesList parent="links" />
    </div>
  );
};
