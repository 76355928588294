import React from "react";
import { Breadcrumbs, Typography, Stack } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import styles from "./customBreadcrumbs.module.scss";
import { useTranslation } from "react-i18next";
import { ROUTES_LABEL } from "../../../constants/routesLabels";
import { useSelector } from "react-redux";
import { categoryReplace } from "../../helper/util";

export const CustomBreadcrumbs = () => {
  const location = useLocation();
  let isProductCategory: any = false;
  const prodCategoryResponse = useSelector(
    (state: any) => state?.commonReducer?.prodCategoryResponse
  );
  let pathnames: any = location.pathname.split("/").filter((x) => x);
  if (
    pathnames.length > 0 &&
    (pathnames[0] === ROUTES_LABEL.PROD_CONTENT_DETAIL ||
      pathnames[0] === ROUTES_LABEL.PRODUCT_COMPARE ||
      pathnames[0] === ROUTES_LABEL.PRODUCT_DETAILS ||
      pathnames[0] === ROUTES_LABEL.RESELLER_PROD_CONTENT_DETAILS ||
      pathnames[0] === ROUTES_LABEL.PRODUCT_RANGE ||
      pathnames[0] === ROUTES_LABEL.PRODUCTS)
  ) {
    pathnames[0] = ROUTES_LABEL.PRODUCT_RANGE;
    isProductCategory = true;
  }

  const findDisplayName = (replacedCategoryName: any) => {
    const categories: any =
      prodCategoryResponse?.data?.catalogList[0]?.children? prodCategoryResponse?.data?.catalogList[0]?.children : [];
    for (const mainCategory of categories) {
      if (categoryReplace(mainCategory.displayName) === replacedCategoryName) {
        return mainCategory?.displayName;
      }
      if (mainCategory.children && mainCategory.children.length > 0) {
        for (const subCategory of mainCategory.children) {
          if (
            categoryReplace(subCategory.displayName) === replacedCategoryName
          ) {
            return subCategory.displayName;
          }
          if (subCategory.children && subCategory.children.length > 0) {
            for (const subSubCategory of subCategory.children) {
              if (
                categoryReplace(subSubCategory.displayName) ===
                replacedCategoryName
              ) {
                return subSubCategory.displayName;
              }
            }
          }
        }
      }
    }
    return replacedCategoryName;
  };
  const { t } = useTranslation();

  return ( 
    <div style={{ marginBottom: "10px" }}>
      <Stack spacing={2}>
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          <Link className={styles.linkStyle} to="/Homepage">
            {t("dnet.home.text")}
          </Link>
          {pathnames.map((pathname:any, index:any) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
            return index === pathnames.length - 1 ? (
              <Typography
                sx={{
                  fontSize: "small",
                  color: "#666",
                  marginTop: "1px",
                }}
                key={routeTo}
              >
                {pathname === ROUTES_LABEL.PRODUCT_RANGE
                  ? ROUTES_LABEL.PRODUCTS.replace(/_/g, " ")
                  : (isProductCategory
                  ? findDisplayName(pathname)
                  : pathname.replace(/_/g, " "))}
              </Typography>
            ) : (
              <Link className={styles.linkStyle} to={routeTo} key={routeTo}>
                {pathname === ROUTES_LABEL.PRODUCT_RANGE
                  ? ROUTES_LABEL.PRODUCTS.replace(/_/g, " ")
                  : (isProductCategory
                  ? findDisplayName(pathname)
                  : pathname.replace(/_/g, " "))}
              </Link>
            );
          })}
        </Breadcrumbs>
      </Stack>
    </div>
  );
};
