import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import { visuallyHidden } from "@mui/utils";
import styles from "./myQuotationTable.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sortNestedData } from "../../helper/util";
import moment from "moment";
var currencyFormatter = require("currency-formatter");

const MyQuotationTable = () => {
  const { t } = useTranslation();
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState<any>("quoteNumber");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const quotationTableData = useSelector(
    (state: any) => state?.myQuotationsReducer?.quotationData?.quotations
  );

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };
  
  const sortedData =
  order === "desc"
    ? quotationTableData?.sort(
        (a: any, b: any) => -sortNestedData(a, b, orderBy)
      )
    : quotationTableData?.sort((a: any, b: any) =>
        sortNestedData(a, b, orderBy)
      );
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  interface Column {
    id:
      | "quoteNumber"
      | "name"
      | "creation"
      | "expiration"
      | "totalPrice"
      | "type"
      | "status";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
  }

  const columns: readonly Column[] = [
    { id: "quoteNumber", label: t("dnet.salesforce_quotation_reference.text") },
    { id: "name", label: t("dnet.salesforce_quotation_project.text") },
    { id: "creation", label: t("dnet.das_date.text") },
    { id: "expiration", label: t("dnet.salesforce_quotation_expires.text") },
    { id: "totalPrice", label: t("dnet.salesforce_quotation_value.text") },
    { id: "type", label: t("dnet.salesforce_quotation_type.text") },
    { id: "status", label: t("dnet.salesforce_quotation_status.text") },
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className={styles.tableContainer}>
      <TableContainer component={Paper} className={styles.tableContainerHeader}>
        <Table sx={{ minWidth: 650 }} aria-label="Payments Table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={createSortHandler(column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {quotationTableData?.length > 0 ? (
                sortedData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, key: any) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                      <TableCell key={key}>
                        <Link
                          className={styles.colorBlue}
                          to="/My_Quotations/Quotations_Details"
                          state={{
                            selectedQuotation: row,
                          }}
                        >
                          {row?.["quoteNumber"]}
                        </Link>
                      </TableCell>
                      <TableCell>{row?.["name"]}</TableCell>
                      <TableCell>
                        {row?.["creation"] &&
                          moment(row?.["creation"]).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>
                        {row?.["expiration"] &&
                          moment(row?.["expiration"]).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>
                        {row?.["totalPrice"] &&
                          currencyFormatter.findCurrency(row?.["quoteCurrency"])
                            ?.symbol + row?.["totalPrice"]}
                      </TableCell>
                      <TableCell>
                        {row?.["type"] === 0
                          ? t(
                              "dnet.salesforce_quotation_single_order.text"
                            )?.toString()
                          : t(
                              "dnet.salesforce_quotation_frame_agreement.text"
                            )?.toString()}
                      </TableCell>
                      <TableCell>
                        {t("dnet.salesforce_quotation_open.text").toString()}
                      </TableCell>
                    </TableRow>
                  );
                })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  align={"center"}
                  className={styles.noRecFound}
                >
                  {t("dnet.salesforce_quotation_message.text")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.tableFooter}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={quotationTableData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default MyQuotationTable;
