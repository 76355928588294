import { IconButton, Typography } from "@mui/material";
import React from "react";
import styles from "./articleDetailsPageContent.module.scss";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { Link } from "react-router-dom";

export const openLink = (url: any) => {
  window.open(url, "_blank");
};

//leftblcktxtrightblckimg, leftblockimgrightblcktxt, blckimgblcktxt, oneflashtwoblcktxt, twoblcktxtoneflash
export const leftblockimgrightblcktxt = (item: any, format: any) => {
  return (
    <div className={styles.boxStyle}>
      {item?.pair_title !== "" &&
        item?.block_title !== "" &&
        item?.block_text !== "" && (
          <Typography
            variant="subtitle2"
            color="primary"
            mb={2}
            dangerouslySetInnerHTML={{
              __html: item?.pair_title?.toString(),
            }}
          />
        )}
      <div
        className={styles.innerBoxStyle}
        style={
          format === "reverse"
            ? { flexDirection: "row-reverse" }
            : format === "block"
            ? { display: "block" }
            : format === "reverseBlock"
            ? { flexDirection: "column-reverse" }
            : {}
        }
      >
        {item?.display_preference !== "oneflashtwoblcktxt" &&
          item?.display_preference !== "twoblcktxtoneflash" && (
            <div>
              {item?.block_image !== null && item?.block_image && (
                <img
                  src={item?.block_image?.url}
                  alt={item?.block_image?.alt}
                  // style={{ width: "370px", height: "280px" }}
                /> /*TODO : Data is not clear to implement */
              )}
            </div>
          )}
        <div>
          {item?.block_title !== "" && (
            <Typography
              variant="body1"
              color="primary"
              mb={2}
              dangerouslySetInnerHTML={{
                __html: item?.block_title?.toString(),
              }}
            />
          )}
          {item?.block_text !== "" && (
            <Typography
              variant="body2"
              mb={1}
              dangerouslySetInnerHTML={{
                __html: item?.block_text?.toString(),
              }}
            />
          )}
          {item?.block_linktext_attachment !== null &&
            item?.block_linktext_attachment !== "" &&
            item?.block_attachment &&
            item?.block_attachment !== null && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  marginBottom: "15px",
                }}
              >
                <PictureAsPdfOutlinedIcon />
                <Typography
                  variant="body2"
                  onClick={() => openLink(item?.block_attachment?.url)}
                  className={styles.linkStyle}
                  dangerouslySetInnerHTML={{
                    __html: item?.block_linktext_attachment?.toString(),
                  }}
                />
              </div>
            )}
          {item?.block_linktext_url !== "" && (
            <Typography
              variant="body2"
              onClick={() => openLink(item?.block_url)}
              className={styles.linkStyle}
            >
              {item?.block_linktext_url}
            </Typography>
          )}
          {/*TODO : check the code if anything else need to be visible */}
        </div>
      </div>
    </div>
  );
};

export const productLinkBlock = (
  category: any,
  productLinkHeader: any,
  sonyMenuItems: any
) => {
  const mapCategoryName = (name: any) => {
    const productMenuItems = sonyMenuItems?.filter(
      (item: any) => item?.sectionName === "DNET Products"
    );
    const categoryDeatail =
      productMenuItems?.length > 0
        ? productMenuItems[0]?.subMenu?.filter((item: any) => {
            return item?.genericName === name;
          })
        : [];
    return (
      categoryDeatail?.length > 0 && (
        <Link
          to={`/${categoryDeatail[0]?.route}`}
          className={styles.innerProductBox}
        >
          <IconButton size="small" color="info">
            <ArrowCircleRightOutlinedIcon />
          </IconButton>
          <Typography variant="body2">{categoryDeatail[0]?.label}</Typography>
        </Link>
      )
    );
  };
  return (
    <div className={styles.productBoxStyle}>
      <Typography variant="subtitle2" mb={2}>
        {productLinkHeader}
      </Typography>
      {category?.map((item: any) => {
        return mapCategoryName(item);
      })}
    </div>
  );
};
