import BrokenImageOutlinedIcon from "@mui/icons-material/BrokenImageOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import LocalAtmRoundedIcon from "@mui/icons-material/LocalAtmRounded";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import {
  Box,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./account-information.module.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ROUTES_LABEL } from "../../../constants/routesLabels";
import CustomButton from "../../atoms/customButton";
import CustomModal from "../../atoms/customModal";
import { useDispatch } from "react-redux";
import { downloadInvoicePdf, getAccountInfoCMS } from "../../../state/actions";
var _ = require("lodash");

export const AccountInformation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const cardIcon: any = {
    openitems: (
      <ImportContactsOutlinedIcon className={styles.svg_icons} color="action" />
    ),
    paiditems: <PaidOutlinedIcon className={styles.svg_icons} color="action" />,
    payments: (
      <LocalAtmRoundedIcon className={styles.svg_icons} color="action" />
    ),
    statements: (
      <LibraryBooksOutlinedIcon className={styles.svg_icons} color="action" />
    ),
    disputeditems: (
      <BrokenImageOutlinedIcon className={styles.svg_icons} color="action" />
    ),
  };

  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );

  const getAccountInfoData = useSelector(
    (state: any) => state?.gazelleReducer?.getAccountInfoData
  );

  useEffect(() => {
    let params = {
      [`filter_locale[${i18n.language}]`]: i18n.language,
      [`filter_bg[${currentDealer?.bgName}]`]: currentDealer?.bgName,
      [`filter_ecot[4_GB_CONVE]`]: "4_GB_CONVE",
      [`acf_like[main_content_category]`]: "AccountInformation",
      [`acf_like[content_category]`]: "AccountInformation>HomePage",
      max_result: "1",
    };
    dispatch(
      getAccountInfoCMS({
        params,
      })
    );
  }, []);

  const [accInfo, setAccInfo] = useState<any>({});

  useEffect(() => {
    const ACC_INFO_DUMMY: any = {
      CARD_HEADER: [],
      CARD_CONTENT: [],
    };
    if (!_.isEmpty(getAccountInfoData)) {
      getAccountInfoData[0]?.related_items?.forEach(
        (blocks: any, index: any) => {
          if (index === 0 && blocks?.acf_fc_layout === "add_article") {
            ACC_INFO_DUMMY.HEADER1 = blocks?.article_title;
            ACC_INFO_DUMMY.CONTENT11 = blocks?.introduction;
          } else if (index === 1 && blocks?.acf_fc_layout === "add_block") {
            if (blocks?.block_text?.includes("<ul>")) {
              ACC_INFO_DUMMY.CONTENT12 = blocks?.block_text?.split("<ul>")[0];
              let moduleArr = blocks?.block_text
                ?.split("<ul>")[1]
                ?.replace("</ul>", "")
                ?.replaceAll("<li>", "")
                ?.split("</li>");
              moduleArr?.forEach((module: any, modIndex: any) => {
                if (
                  modIndex < moduleArr?.length - 1 &&
                  module?.split("-")[0]?.trim()?.toLowerCase() !==
                    "direct debits"
                ) {
                  ACC_INFO_DUMMY.CARD_HEADER?.push(
                    module
                      ?.split("-")[0]
                      ?.trim()
                      ?.replace(/[\t\r\n]/g, "")
                  );
                  ACC_INFO_DUMMY.CARD_CONTENT?.push(module?.split("-")[1]);
                }
              });
            }
          } else if (index === 3 && blocks?.acf_fc_layout === "add_block") {
            ACC_INFO_DUMMY.HEADER2 = blocks?.block_title;
            ACC_INFO_DUMMY.CONTENT21 = blocks?.block_text;
          } else if (index === 4 && blocks?.acf_fc_layout === "add_block") {
            ACC_INFO_DUMMY.CONTENT31 = blocks?.block_text;
            ACC_INFO_DUMMY.LINK_TEXT = blocks?.block_linktext_attachment;
          }
        }
      );
      setAccInfo(ACC_INFO_DUMMY);
    }
  }, [getAccountInfoData]);

  const { t } = useTranslation();

  const sonyMenuItems = useSelector(
    (state: any) => state?.commonReducer?.sonyMenuItems
  );

  const [gazelleList, setGazelleList] = useState<any>([]);

  useEffect(() => {
    if (sonyMenuItems?.length > 0) {
      let menu = sonyMenuItems?.filter(
        (menu: any) => menu?.sectionName === "DNET Account Information"
      );
      let gList: any = [];
      if (!_.isEmpty(menu)) {
        menu[0]?.subMenu?.forEach((ele: any) => {
          gList.push(ele?.sectionName);
        });
        setGazelleList(gList);
      }
    }
  }, [sonyMenuItems]);

  const CARD_MENU: any = {
    openitems: "DNET Open Items",
    paiditems: "DNET Paid Items",
    payments: "DNET Payment History",
    statements: "DNET Statement",
    disputeditems: "DNET Disputed Items",
    debitnotcreation: "DNET Debit Note Creation",
  };

  const CARD_ROUTES: any = {
    openitems: ROUTES_LABEL.OPEN_ITEMS,
    paiditems: ROUTES_LABEL.PAID_ITEMS,
    payments: ROUTES_LABEL.PAYMENTS,
    statements: ROUTES_LABEL.STATEMENTS,
    disputeditems: ROUTES_LABEL.DISPUTED_ITEMS,
  };

  const displayCard = (index: any) => {
    if (!_.isEmpty(gazelleList)) {
      return gazelleList?.includes(CARD_MENU?.[index]) ? "inline" : "none";
    } else {
      return "none";
    }
  };

  const [openModal, setOpenModal] = React.useState(false);
  const [invoiceNumber, setInvoiceNumber] = React.useState("");

  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.userDetails
  );
  const closeModal = () => {
    setOpenModal(false);
    setInvoiceNumber("");
  };
  const saveAccountDetails = useSelector(
    (state: any) => state?.commonReducer?.saveAccountDetails
  )
  const downloadPdf = () => {
    dispatch(
      downloadInvoicePdf({
        language: userDetails?.language,
        username: userDetails?.userName,
        partnerKey: saveAccountDetails?.payerToAcc?.number,
        invoiceId: "SD_" + invoiceNumber,
      })
    );
  };

  const informationCardFunction = (htmlString: any) => {
    return <Card
        sx={{
          minWidth: 200,
          width: "40%",
          minHeight: 150,
          padding: "10px",
          borderRadius: "10px",
          textAlign: "left",
        }}
      >
        <CardContent>
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: htmlString,
            }}
          />
        </CardContent>
      </Card>
  }

  return (
    <>
      <Box className={styles.headerButtonStyle}>
        {(gazelleList?.includes("DNET Open Items") ||
          gazelleList?.includes("DNET Paid Items") ||
          gazelleList?.includes("DNET Payment History") ||
          gazelleList?.includes("DDNET Disputed Items") ||
          gazelleList?.includes("DNET Statement")) && (
          <CustomButton
            label={t("dnet.invoice_search.text")}
            onClick={() => setOpenModal(true)}
            color="primary"
          />
        )}
        {gazelleList?.includes("DNET Your Ref Search") && (
          <CustomButton
            label={t("dnet.account_info_your_ref_search.section")}
            onClick={() => navigate(`/${ROUTES_LABEL.YOUR_REFERENCE_SEARCH}`)}
            color="primary"
          />
        )}
      </Box>
      <Box className={styles.boxStyle}>
        <Typography variant="subtitle1" color="primary" mb={2}>
          {accInfo?.HEADER1}
        </Typography>
        <Typography variant="body2">{accInfo?.CONTENT11}</Typography>
        <Typography variant="body2" mb={4}>
          {accInfo?.CONTENT12}
        </Typography>
        <div className={styles.cardStyle}>
          {accInfo?.CARD_HEADER?.map((cardData: any, index: any) => (
            <Card
              key={index}
              sx={{
                width: 200,
                height: 200,
                borderRadius: "10px",
                cursor: "pointer",
                display: displayCard(cardData?.replace(" ", "")?.toLowerCase()),
              }}
              onClick={() =>
                navigate(
                  `/${CARD_ROUTES[cardData?.replace(" ", "")?.toLowerCase()]}`
                )
              }
            >
              <CardContent>
                <Typography variant="button" component="div" mb={2}>
                  {cardData}
                </Typography>
                {cardIcon?.[cardData?.replace(" ", "")?.toLowerCase()]}
                <Typography
                  component="div"
                  variant="caption"
                  color="text.secondary"
                  mt={2}
                >
                  {accInfo?.CARD_CONTENT[index]}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </div>
      </Box>
      <div className={styles.divStyle}>
        <Box className={styles.dualBox}>
          <Typography variant="subtitle1" color="primary" mb={2}>
            {accInfo?.HEADER2}
          </Typography>
          <div className={styles.cardStyle}>
            {informationCardFunction(accInfo?.CONTENT21)}
            {informationCardFunction(accInfo?.CONTENT31)}
          </div>
        </Box>
      </div>
      <CustomModal
        open={openModal}
        closeModal={closeModal}
        heading={t("dnet.invoice_search.text")}
        content={t("gzl.account_information_home_infofield2.text")}
        invoiceSearchModal={true}
        invoiceSearchFunc={downloadPdf}
        getInvoiceNumber={(e: any) => setInvoiceNumber(e.target.value)}
        invoiceNumber={invoiceNumber}
      />
    </>
  );
};
