import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Checkbox, IconButton, Modal, Typography } from "@mui/material";
import CustomButton from "../../../atoms/customButton";
import { useTranslation } from "react-i18next";
import styles from "./orderEntryModal.module.scss";
import { useSelector } from "react-redux";

const invoiceStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
  paddingBottom: "45px",
  textAlign: "left",
  overflowY: "scroll",
  height: "70%",
};

export const OrderEntryModal = ({
  openModal,
  closeModal,
  shipToAccountsArray,
  handleAccountMultiSelection,
  modalErrMsg,
}: any) => {
  const { t } = useTranslation();

  const multiSelectedAddresses = useSelector(
    (state: any) => state?.cartReducer?.multiSelectedAddresses
  )
  const [allAccountsArray, setAllAccountsArray] = useState(multiSelectedAddresses);

  const handleAccountSelection = (value: any, name: any) => {
    let accArray = [...allAccountsArray];
    if (name === "deselect") {
      accArray = accArray.map((elem: any) => {
        return elem?.value !== "0" && elem?.value !== "multiship" ? {
          ...elem,
          multiSelect: false,
        } : {
          ...elem,
        };
      });
      setAllAccountsArray(accArray);
    } else if (name === "select") {
      accArray = accArray.map((elem: any) => {
        return elem?.value !== "0" && elem?.value !== "multiship" ? {
          ...elem,
          multiSelect: true,
        } : {
          ...elem,
        };
      });
      setAllAccountsArray(accArray);
    } else {
      accArray = accArray.map((elem: any) => {
        if (elem?.value === value) {
          return {
            ...elem,
            multiSelect: !elem?.multiSelect,
          };
        } else {
          return { ...elem };
        }
      });
      setAllAccountsArray(accArray);
    }
  };

  return (
    <div>
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={invoiceStyle}>
          <div className={styles.modalHeader}>
            <Typography variant="subtitle2" color="primary">
              {t("dnet.select_delivery_destinations.text")}
            </Typography>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles.modalSubHeader}>
            <Typography variant="body1">
              {t("dnet.select_delivery_introduction.text")}
            </Typography>
            <div className={styles.selectOrdeselectAll}>
              <Typography
                variant="body1"
                color="primary"
                onClick={() => {
                  handleAccountSelection("", "select");
                }}
              >
                {t("dnet.select_all.text")}
              </Typography>
              <Typography
                variant="body1"
                color="primary"
                onClick={() => {
                  handleAccountSelection("", "deselect");
                }}
              >
                {t("dnet.deselect_all.text")}
              </Typography>
            </div>
          </div>
          <div className={styles.listDesign}>
            {allAccountsArray?.length > 0 &&
              allAccountsArray?.map((currentDealer: any) => {
                return currentDealer?.value !== "0" && 
                  currentDealer?.value !== "multiship" 
                  &&(
                  <div className={styles.listItemDesign}>
                    <Checkbox
                      className={styles.checkboxStyle}
                      checked={currentDealer?.multiSelect}
                      onChange={() => {
                        handleAccountSelection(
                          currentDealer?.value,
                          "general"
                        );
                      }}
                      size="small"
                      sx={{ paddingLeft: "0" }}
                    />
                    <Typography
                      variant="body1"
                      onClick={() => {
                        handleAccountSelection(
                          currentDealer?.value,
                          "general"
                        );
                      }}
                    >
                      {currentDealer?.label}
                    </Typography>
                  </div>
                );
              })}
          </div>
          {modalErrMsg !== "" && (
            <div className={styles.errMsg}>
              <Typography color="#721c24" variant="body1">
                {modalErrMsg}
              </Typography>
            </div>
          )}
          <div className={styles.buttonSection}>
            <CustomButton
              label={t("dnet.ok.text")}
              color="primary"
              onClick={() => {
                handleAccountMultiSelection(allAccountsArray);
              }}
              margin={0}
            />
            <CustomButton
              label={t("dnet.cancel.text")}
              onClick={closeModal}
              margin={0}
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
};

OrderEntryModal.defaultProps = {
  openModal: false,
  closeModal: () => {},
  shipToAccountsArray: [],
  handleAccountMultiSelection: () => {},
  modalErrMsg: "",
};
