import React, { useEffect } from "react";
import styles from "./contact.module.scss";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getArticlesFromCMS } from "../../../state/actions";
import { ArticleDetailsPage } from "../../cms/articleDetailsPage";

export const Contact = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const articles = useSelector(
    (state: any) => state?.userProfileOptionsReducer?.cmsArticles
  );
  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );
  useEffect(() => {
    dispatch(
      getArticlesFromCMS({
        module: "Contact",
        params: {
          [`filter_locale[${i18n.language}]`] : i18n.language,
          [`filter_bg[${currentDealer?.bgName}]`] : currentDealer?.bgName,
          [`filter_ecot[4_GB_CONVE]`] : "4_GB_CONVE",
          [`acf_like[main_content_category]`] : "TopNav",
          [`acf_like[content_category]`] : "TopNav>Contact",
          "max_result":"1"
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Typography
        variant="subtitle1"
        className={styles.header}
        pb={3}
        color="primary"
      >
        {t("dnet.contact.text")}
      </Typography>
      <ArticleDetailsPage selectedArticle={articles?.[0]} />
    </div>
  );
};
