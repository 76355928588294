export const LABELS = {
  SONY: "SONY",
  HEADER_TEXT: "The Channel Partner Portal from Sony",
  HOME: "Home",
  OPEN_ITEMS: "Open Items",
  PAID_ITEMS: "Paid Items",
  PAYMENTS: "Payments",
  STATEMENTS: "Statements",
  DISPUTED_ITEMS: "Disputed Items",
  DEBIT_NOTE_CREATION: "Debit Note Creation",
  YOUR_REFERENCE_SEARCH: "Your Reference Search",
  CREDIT_MANAGEMENT: "Credit Management",
  BALANCE_CONFIRMATION_AT: "Balance Confirmation At",
  CURRENT_DATE: "Current Date",
  STATEMENT_DATE: "Statement Date",
  ONE_YEAR_RESTRICTION: "Only last one year can be selected",
  UPDATE_STATEMENT_NOTE:
    "To display your statement as at a specific date, please select the date from above calendar.",
  DOWNLOAD_CSV: "Download CSV",
  DATE: "Date",
  DUE_DATE: "Due Date",
  INVOICE_NUMBER: "Invoice Number",
  REFERENCE: "Reference",
  DELIVERY: "Delivery",
  TYPE: "Type",
  OUTSTANDING_BALANCE: "Outstanding Balance",
  CURRENCY: "Currency",
  DOWNLOAD_PDF: "Download PDF",
  TOTAL_BALANCE: "Total Balance",
  NO_RECORDS_FOUND: "No records found",
  OK: "OK",
  ACCESS_DENIED: "Access Denied",
};

export const ACC_INFO = {
  HEADER1: "Access your account online",
  CONTENT11:
    "The financials section of Sony ONE ‘1’ allows you, the customer to view your account information online 24/7",
  CONTENT12:
    "Different screens are available that provide real time information about various aspects of your financial relationship with Sony",
  CARD_HEADER: [
    "Open Items",
    "Paid items",
    "Payments",
    "Statements",
    "Disputed items",
  ],
  CARD_CONTENT: [
    "Lists all items for your account that have not yet been paid",
    "Allows you to view invoices and credit notes that have been paid",
    "View payments made by you and settled by Sony in any month going back one calendar year",
    "Allows you to view all open items on your account at the moment of viewing and going back one calendar year",
    "See the status of all queries raised through Sony1",
  ],

  HEADER2: "Information about data",
  CONTENT21:
    "Sony uses the accounting module of SAP R3. Data is extracted from SAP to be presented to customers",
  LIST21: [
    "Data includes all your financial transactions based on your SAP account with Sony",
    "Data is ‘cached’ for performance reasons but can be instantly refreshed for real time updates",
  ],

  HEADER3: "Information about access",
  CONTENT31:
    "Access to the accounting information is only available to authorised Sony customers",
  LIST31: [
    "Customers will only ever see data for their own accounts",
    "Access will be granted by Sony upon request Different levels of access are available",
  ],
};

export const OPEN_ITEMS = {
  HEADER: "Open Items",
  MENU_ITEMS: [
    "All documents by Type",
    "All documents by Date",
    "Credits only",
    "Debits only",
  ],
  LAST_DATE: "Last Data Retrival Date",
  REFRESH: "Refresh",
  DOWNLOAD_CSV: "Download CSV",
  YOUR_REFRENCE: "Your Reference",
  LIST: "Find",
  DOCUMENT_BY_TYPE: "All documents by Type",
  DEBITS_ONLY: "Debits only",
  CREDITS_ONLY: "Credits only",
  OVERDUE: "Overdue",
  ADD_OR_REPLY_QUERY: "Display Contact History",
  CREATE_ENQUIRY: "Create Enquiry",
  REPLY_QUERY: "Reply queries",
  CHANGE_TABLE_VIEW: "Change Table View",
};

export const PAID_ITEMS = {
  HEADER: "Paid Items",
  MENU_ITEMS: ["Previous 2 months", "Previous 3 months", "Previous 6 months"],
  LAST_DATE: "Last Data Retrival Date",
  REFRESH: "Refresh",
  DOWNLOAD_CSV: "Download CSV",
  YOUR_REFRENCE: "Your Reference",
  SUBTITLE:
    "To display paid invoices/credits, select the appropriate date period from above:",
  PERIOD: "Period",
  FIND: "Find",
  FIRST_VALUE: "January 2023",
  ADD_OR_REPLY_QUERY: "Display Contact History",
  CREATE_ENQUIRY: "Create Enquiry",
  REPLY_QUERY: "Reply queries",
};

export const PAYMENTS = {
  HEADER: "Payment History",
  MENU_ITEMS: ["Previous 2 months", "Previous 3 months"],
  LAST_DATE: "Last Data Retrival Date",
  REFRESH: "Refresh",
  DOWNLOAD_CSV: "Download CSV",
  PERIOD: "Period",
  SUBTITLE:
    "To display the list of payments, select the appropriate criteria from above:",
  FIND: "Find",
  FIRST_VALUE: "January 2023",
};

export const DEBIT_NOTE_CREATION = {
  HEADER: "Debit Note Creation",
  REASON_SELECTION:
    "To create a debit note on your account, please select the reason type and provide us with the details requested",
  DEBIT_NOTE_NUMBER: "Debit Note Number",
  INVOICE_LINE_ITEM: "Invoice Line Item",
  INVOICE_NUMBER: "Invoice Number",
  MODEL_CODE: "Model Code",
  PRICE_CLAIMED: "Price Claimed",
  PRICE_CHARGED: "Price Charged",
  GROSS_VALUE_OF_CLAIM: "Gross Value of Claim",
  CURRENCY: "Currency",
  REASON_FOR_CLAIM: "Reason for Claim",
  CONTACT_NAME: "Contact Name",
  CONTACT_NUMBER: "Contact Number",
  EMAIL: "E-mail",
  ADDITIONAL_COMMENTS: "Additional Comment",
  MAX_500_CHARACTERS: "max. 500 characters",
  SEND: "Send",
  CLEAR: "Clear",
  QUANTITY: "Quantity",
  REASON_FOR_COLLECTION_OR_RETURN: "Reason for Collection/Return",
  QUANTITY_INVOICED: "Quantity Invoiced",
  QUANTITY_SHORT: "Quantity Short",
  VALUE_OF_CREDIT_EXPECTED: "Value of Credit Expected",
  PERIOD_OF_REBATE: "Period of Rebate",
  PRODUCT_CATEGORY: "Product Category",
  REASON_FOR_REBATE: "Reason for Rebate",
  CUSTOMER_REFERENCE: "Customer Reference",
  MODEL_NAME: "Model Name",
  REASON_FOR_CREDIT: "Reason for Credit",
  PRICE_REPOSITIONING_DATES: "Price Repositioning Dates",
  CLAIM_AGREED_BY: "Claim Agreed By",
  CONFIRMATION_HEADING: "Confirmation of Debit Note Creation",
  CONFIRAMTION_CONTENT:
    'Thank you for your comments. We have a note of your query and it will be given priority. You can access the reply for your accounting clerk by choosing "Display Contact History" in the Action column.',
  REQUIRED: "This value is required",
  MAX_4_DIGITS: "Maximum 4 digits are allowed",
  MAX_10_DIGITS: "Maximum 10 digits are allowed",
  MAX_9_DIGITS: "Maximum 9 digits are allowed",
  MAX_8_DIGITS: "Maximum 8 digits are allowed",
  MAX_12_CHARS: "Maximum 12 characters are allowed",
  MAX_500_CHARS: "Maximum 500 characters are allowed",
  EMAIL_ERROR: "Please enter valid Email ID",
  CONTACT_NUM_ERROR: "Please enter valid contact number",
};
export const PAID_ITEM_DETAILS = {
  HEADER: "Paid Item Details",
  SUBTITLE: "Payment information for the following invoice:",
  SUBTITLE_SEC: "The invoice was paid with the following payments:",
};

export const PAYMENT_DETAILS = {
  HEADER: "Payment Details",
  SUBTITLE: "You have used the following credits for the payment:",
  SUBTITLE_SEC: "The following invoices were paid with this payment:",
  SUBTITLE_3:
    "The following outstanding receivables exist after making the payment:",
  SUBTITLE_4: "You still have the following credits after making the payment:",
  PAYMENT_TEXT_MESSAGE:
    "In addition we have used the following amount of your payment elsewhere:",
};

export const CREATE_ENQUIRY = {
  HEADER: "Create Enquiry",
  SUBTITLE:
    "To create an enquiry for the following document, enter your text in the comment window.",
  SUBTITLE_2: "To send your enquiry, choose Send.",
  SUBTITLE_3: "To return to the overview, choose Back.",
  REASON_SELECTION:
    "To create an enquiry for the following document, please select the reason type and provide us with the details requested",
  CONFIRMATION_HEADING: "Confirmation of Enquiry created",
};

export const DISPUTED_ITEMS = {
  HEADER: "Disputed Items",
  LAST_DATA_RETRIEVAL_DATE: "Last Data Retrieval Date",
  QUERY_TYPE: "Query type",
  RESOLVED_QUERIES: "Resolved Queries",
  UNRESOLVED_QUERIES: "Unresolved Queries",
  REFRESH: "Refresh",
  DATE: "Date",
  INVOICE_NUMBER: "Invoice Number",
  DOCUMENT_TYPE: "Document Type",
  DELIVERY_NUMBER: "Delivery Number",
  YOUR_REFERENCE: "Your Reference",
  ORIGINAL_INVOICE_VALUE: "Original Invoice Value",
  QUERIES: "Queries",
  ADD_OR_REPLY_QUERY: "Add/Reply queries",
  REPLY_QUERY: "Reply queries",
  ADD_QUERY: "Add query",
};

export const MAKE_PAYMENT = {
  HEADER: "List of Invoices Selected for Payment",
  SUBTITLE: "You want to pay the invoices listed with bank details:",
  REASON_SELECTION:
    "Please select the reason type and provide us with the details requested",
  CONFIRMATION_HEADING: "Confirmation",
  CONFIRMATION_HEADING_FOR_MODAL: "Confirmation of payment",
  CONFIRAMTION_CONTENT: "",
  REPLY_QUERY: "Reply queries",
  ADD_QUERY: "Add query",
};

export const REPLY_ENQUIRY = {
  HEADER: "Contact History",
  TABLE_HEADER:
    "History of your enquiries and our replies for the following document",
  INVOICE_OR_CREDIT_NOTE: "Invoice or Credit Note",
  DATE: "Date",
  AMOUNT: "Amount",
  OUTSTANDING_BAL: "Outstanding Balance Less Discount Due",
  ADD_OR_REPLY_ENQUIRY: "Add / Reply Enquiry",
  MAX_500_CHARACTERS: "Max. 500 characters",
  ADDITIONAL_COMMENTS: "Additional Comment",
  MAX_500_CHARS: "Maximum 500 characters are allowed",
  SEND: "Send",
  CANCEL: "Cancel",
  CONFIRMATION_HEADING: "Confirmation of Enquiry",
  CONFIRAMTION_CONTENT:
    'Thank you for your comments. We have a note of your query and it will be given priority. You can access the reply for your accounting clerk by choosing "Display Contact History" in the Action column.',
};

export const CREDIT_MANAGEMENT_INFO = {
  HEADER: "Credit Management Information",
  CREDIT_LIMIT: "Credit Limit",
  CREDIT_EXPOSURE_RISK: "Credit Exposure Risk",
  CONTACT: "Contact",
};

export const YOUR_REFERENCE_SEARCH = {
  HEADER: "Search By Your Reference",
  YOUR_REFRENCE: "Your Reference",
  LIST: "Find",
  PAID_ITEM_PERIOD: "Paid Item Period",
  ENTER_REFERENCE_SEARCH: "Please enter your reference",
};

export const COUNTRY_SELECT = [
  {
    country: "Austria",
    locale: "de_AT",
  },
  {
    country: "Bulgaria",
    locale: "bg_BG",
  },
  {
    country: "Belgium",
    locale: {
      subLocale: ["fr_BE", "nl_BE"]
    },
  },
  {
    country: "Bosnia and Herzegovina",
    locale: "hr_BA",
  },
  {
    country: "CIS",
    locale: "en_GB",
  },
  {
    country: "Croatia",
    locale: "hr_HR",
  },
  {
    country: "Czech Republic",
    locale: "cs_CZ",
  },
  {
    country: "Denmark",
    locale: "da_DK",
  },
  {
    country: "Estonia",
    locale: "en_LV",
  },
  {
    country: "Finland",
    locale: "fi_FI",
  },
  {
    country: "France",
    locale: "fr_FR",
  },
  {
    country: "Germany",
    locale: "de_DE",
  },
  {
    country: "Greece",
    locale: "el_GR",
  },
  {
    country: "Hungary",
    locale: "hu_HU",
  },
  {
    country: "Ireland",
    locale: "en_IE",
  },
  {
    country: "Israel",
    locale: "en_GB",
  },
  {
    country: "Italy",
    locale: "it_IT",
  },
  {
    country: "Latvia",
    locale: "en_LV",
  },
  {
    country: "Lithuania",
    locale: "en_LV",
  },
  {
    country: "Luxembourg",
    locale: "fr_BE",
  },
  {
    country: "Macedonia",
    locale: "mk_MK",
  },
  {
    country: "Malta",
    locale: "en_GB",
  },
  {
    country: "Middle East & Africa",
    locale: "en_GB",
  },
  {
    country: "Netherlands",
    locale: "nl_NL",
  },
  {
    country: "Norway",
    locale: "no_NO",
  },
  {
    country: "Poland",
    locale: "pl_PL",
  },
  {
    country: "Portugal",
    locale: "pt_PT",
  },
  {
    country: "Romania",
    locale: "ro_RO",
  },
  {
    country: "Serbia",
    locale: "sr_YU",
  },
  {
    country: "Slovakia",
    locale: "sk_SK",
  },
  {
    country: "Slovenia",
    locale: "sl_SI",
  },
  {
    country: "Spain",
    locale: "es_ES",
  },
  {
    country: "Sweden",
    locale: "sv_SE",
  },
  {
    country: "Switzerland",
    locale: {
      subLocale: ["fr_CH", "de_CH"]
    },
  },
  {
    country: "Turkey",
    locale: "tr_TR",
  },
  {
    country: "United Kingdom",
    locale: "en_GB",
  },
  {
    country: "Other Countries/ Regions",
    locale: "en_GB",
  },
];

export const SUB_LOCALE: any = {
  Belgium: {
    HEADER: "Sony Belgium",
    SUB_LOCALE_TEXT: [
      { locale: "fr_BE", text: "POUR CONTINUER EN FRANCAIS CLIQUEZ ICI" },
      {
        locale: "nl_BE",
        text: "KLIK HIER, OM VERDER TE GAAN IN HET NEDERLANDS",
      },
    ],
  },
  Switzerland: {
    HEADER: "Sony Switzerland",
    SUB_LOCALE_TEXT: [
      { locale: "fr_CH", text: "POUR CONTINUER EN FRANCAIS CLIQUEZ ICI" },
      { locale: "de_CH", text: "FÜR DEUTSCH KLICKEN SIE BITTE HIER" },
    ],
  },
};

export const SYSTEM_ADMIN = {
  MATERIAL_NAME: "Material Name",
  CHECK: "Check",
  VALID_MATERIAL_NAME: "Please enter a valid material name.",
  SALES_AREA: "Sales Area",
  PLT: "PLT",
  EPLT: "EPLT",
  LISTING_CODE: "Listing Code",
  SALES_STATUS: "Sales Status",
  INCLUDED_SAP_PRODS: "Included SAP Products",
  EXCLUDED_SAP_PRODS: "Excluded SAP Products",
  HIERARCHICAL_EXCLUDED_PRODUCTS: "Hierarchical Excluded Products",
  EMPTY: "Empty",
  USER_DATA: "User Data",
  PRODUCT_DATA: "Product Data",
  HIERARCHY_CODE: "Hierarchy Code",
  ON: "(on)",
  OFF: "(off)",
  CHECK_SALES_REP_USER: "Check Sales Representative User",
  USER_MAIL_PLACEHOLDER: "enter Sony1 user email...",
  ENTER_VALID_SONY1_USER: "Please enter a valid Sony1 user",
  RECEIVE_CONTACT_EMAIL: "Receive Contact Emails of SAP Account",
  ENTER_SAP_ACC_ID: "enter sap account id...",
  ENTER_BUSINESS_GROUP: "enter business group...",
  ENTER_SAP_ACC_AND_BG: "Please enter valid SAP account and business group",
  RECEIVE_SALES_REP_BY_SALES_ORG: "Receive Sales Representatives By Sales Org",
  ENTER_SALES_ORG: "enter sales org: eg. DE10...",
  ENTER_VALID_SALES_ORG: "Please enter a valid Sales Organization",
  ENTER_VALID_SAP_ACC_NUM: "Please enter valid SAP account number in 10 digits",
  ENTER_VALID_BG: "Please enter valid business group",
  USERNAME: "User Name",
  PERSON_ID: "Person ID",
  EMAIL: "Email",
  SALES_REP_LIST: "Sales Representatives List",
  ENTER_VALID_SALES_REP_EMAIL:
    "Please enter a valid Sales Representative Email",
  RECEIVE_ACC_OF_SALES_REP: "Receive Accounts of Sales Representative",
  ENTER_SALES_REP_EMAIL: "enter sales rep. email...",
  ENTER_SALES_ORGANIZATION: "enter sales organization...",
  SALES_REP: "Sales Representative : ",
  DEALERS_LIST: "Dealers List",
  ACCOUNT_REF: "Account Reference",
  BUSINESS_GROUP: "Business Group",
  CONTACT_EMAIL_LIST: "Contact Email List",
  RECEIVE_CONTACT_ACC_BY_SALES_ORG: "Receive Contact Accounts By Sales Org",
  SALES_ORG: "Sales Organization ",
  CONTACT_ACCOUNTS_LIST: "Contact Accounts List",
};
