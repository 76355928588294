import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";

export const Loader = () => {
  const isLoaderActive = useSelector(
    (state: any) => state?.commonReducer?.isLoaderActive
  );
  return (
    <>
      {isLoaderActive && (
        <Box
          sx={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            zIndex: 9999,
          }}
        >
          <Box sx={{ position: "fixed", top: "50%", left: "49%" }}>
            <CircularProgress />
          </Box>
        </Box>
      )}
    </>
  );
};
