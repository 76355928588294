import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import styles from "./orderChangeTable.module.scss";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Link } from "react-router-dom";
import { getComparator, stableSort } from "../../../helper/util";
import { visuallyHidden } from "@mui/utils";
import CustomButton from "../../../atoms/customButton";
// @ts-ignore  
import { useReactToPrint } from "react-to-print";
import { downloadExcel } from "../../../helper/util";
import logger from "../../../helper/logger";
import { CSVLink } from "react-csv";
var _ = require("lodash");

export const OrderChangeTable = ({ initialSort }: any) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>(initialSort);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [rows, setRows] = useState<any>([]);
  const orderChangeReportData = useSelector(
    (state: any) => state?.orderChangeReportReducer?.orderChangeReportData?.data
  );
  const contextDetails = useSelector(
    (state: any) => state?.commonReducer?.contextDetails
  );
  const { t } = useTranslation();

  interface Column {
    id:
      | "model"
      | "customerMaterialNumber"
      | "sonyOrderNumber"
      | "refNo"
      | "dateOfChange"
      | "changeDescription"
      | "oldValue"
      | "newValue";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
  }

  const columns: readonly Column[] = [
    { id: "model", label: t("dnet.model.text") },
    { id: "customerMaterialNumber", label: t("customerMaterialNumber.text") },
    {
      id: "sonyOrderNumber",
      label: t("dnet.sony_ref_no_colon.text"),
    },
    { id: "refNo", label: t("dnet.your_ref.text") },
    { id: "dateOfChange", label: t("priceChange.changeDate.text") },
    { id: "changeDescription", label: t("dnet.change_desc.text") },
    { id: "oldValue", label: t("dnet.old_value.text") },
    { id: "newValue", label: t("dnet.new_value.text") },
  ];

  interface Data {
    model: any;
    customerMaterialNumber: any;
    sonyOrderNumber: any;
    refNo: any;
    dateOfChange: any;
    changeDescription: any;
    oldValue: any;
    newValue: any;
  }

  function createData(
    model: any,
    customerMaterialNumber: any,
    sonyOrderNumber: any,
    refNo: any,
    dateOfChange: any,
    changeDescription: any,
    oldValue: any,
    newValue: any
  ): Data {
    return {
      model,
      customerMaterialNumber,
      sonyOrderNumber,
      refNo,
      dateOfChange,
      changeDescription,
      oldValue,
      newValue,
    };
  }

  const createRowData = () => {
    // Row data creation from API Response
    let data = orderChangeReportData;
    let rowsdata = [];
    for (let i = 0; i < data?.length; i++) {
      rowsdata.push(
        createData(
          data[i]?.materialName,
          data[i]?.customerMaterialNumber,
          data[i]?.combinedIndex,
          data[i]?.customerReference,
          // moment(data[i]?.modificationDate).format("DD/MM/YY hh:mm"),
          data[i]?.modificationDate,
          data[i]?.description,
          data[i]?.oldValue,
          data[i]?.newValue
        )
      );
    }
    setRows([...rowsdata]);
  };

  useEffect(() => {
    if (orderChangeReportData?.length > 0) createRowData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderChangeReportData]);

  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const createTable = () => {
    let cellStyle={
      border:"1px solid #c8c8c8",
      padding:"15px"
    }
    return (
      <div>
        <Typography variant="subtitle2">
          {t("dnet.order_change_report.text")}
        </Typography>
        {/* <TableContainer style={{ maxWidth: "100%" }}> */}
          <table style={{borderCollapse: 'collapse'}}>
            <thead>
              <tr>
                {columns.map((column) => {
                  return column?.id === "customerMaterialNumber" ? (
                    contextDetails?.customerMaterialNumberEnabled && (
                      <td
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, ...cellStyle }}
                      >
                        {column.label}
                      </td>
                    )
                  ) : (
                    <td
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, ...cellStyle }}
                    >
                      {column.label}
                    </td>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {orderChangeReportData?.length > 0 && rows?.length > 0 ? (
                stableSort(rows, getComparator(order, orderBy)).map(
                  (row: any, key: any) => {
                    return (
                      <tr tabIndex={-1} key={key}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return column?.id === "customerMaterialNumber" ? (
                            contextDetails?.customerMaterialNumberEnabled && (
                              <td style={cellStyle} key={column.id} align={column.align}>
                                {value}
                              </td>
                            )
                          ) : column?.id === "sonyOrderNumber" ? (
                            <td style={cellStyle} key={column.id} align={column.align}>
                              <Link
                                id="linkTag"
                                className={styles.colorBlue}
                                to="/Order_Change_Report/Order_Change_History"
                                state={{
                                  sonyOrderNumber:
                                    orderChangeReportData?.[key]?.[
                                      "sonyReference"
                                    ],
                                }}
                              >
                                {value}
                              </Link>
                            </td>
                          ) : (
                            <td style={cellStyle} key={column.id} align={column.align}>
                              {value}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  }
                )
              ) : (
                <tr>
                  <td
                    colSpan={columns.length}
                    align={"center"}
                    className={styles.noRecFound}
                    style={cellStyle}
                  >
                    {t("gzl.no_data_available.text")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        {/* </TableContainer> */}
        <Typography variant="body2">
          {t(
            `dnet.quotationt.c_${currentDealer?.bgName?.slice(0, 4)}.text`
          ).toString()}
        </Typography>
      </div>
    );
  };

  const handleDownload = () => {
    //Excel format
    logger.info("Download Excel Report");
    const excelHeader = [
      { header: t("dnet.model.text"), key: "model", width: 20 },
      {
        header: t("customerMaterialNumber.text"),
        key: "customerMaterialNumber",
        width: 20,
      },
      {
        header: t("dnet.sony_ref_no_colon.text"),
        key: "sonyOrderNumber",
        width: 20,
      },
      {
        header: t("dnet.your_ref.text"),
        key: "refNo",
        width: 20,
      },
      {
        header: t("priceChange.changeDate.text"),
        key: "dateOfChange",
        width: 20,
      },
      {
        header: t("dnet.change_desc.text"),
        key: "changeDescription",
        width: 20,
      },
      { header: t("dnet.old_value.text"), key: "oldValue", width: 20 },
      { header: t("dnet.new_value.text"), key: "newValue", width: 20 },
    ];
    let excelData = [];
    for (let i = 0; i < orderChangeReportData?.length; i++) {
      excelData.push({
        model: orderChangeReportData[i]?.materialName,
        customerMaterialNumber:
          orderChangeReportData[i]?.customerMaterialNumber,
        sonyOrderNumber: orderChangeReportData[i]?.combinedIndex,
        refNo: orderChangeReportData[i]?.customerReference,
        dateOfChange: orderChangeReportData[i]?.modificationDate,
        changeDescription: orderChangeReportData[i]?.description,
        oldValue: orderChangeReportData[i]?.oldValue,
        newValue: orderChangeReportData[i]?.newValue,
      });
    }
    downloadExcel(excelHeader, excelData, "SONYOrderReportAudit.xlsx");
  };

  const [csvData, setCsvData] = useState([]);
  const [readyToDownload, setReadyToDownload] = useState(false);

  const downloadCSV = (tableData: any) => {
    // CSV format creation for downloading
    logger.info("Download OrderChange Report CSV");
    const csvData: any = [
      [
        t("dnet.model.text"),
        t("customerMaterialNumber.text"),
        t("dnet.sony_ref_no_colon.text"),
        t("dnet.your_ref.text"),
        t("priceChange.changeDate.text"),
        t("dnet.change_desc.text"),
        t("dnet.old_value.text"),
        t("dnet.new_value.text"),
      ],
      ...tableData?.map((item: any) => [
        item?.materialName,
        item?.customerMaterialNumber,
        item?.combinedIndex,
        item?.customerReference,
        item?.modificationDate,
        item?.description,
        item?.oldValue,
        item?.newValue,
      ]),
    ];
    setCsvData(csvData);
    setReadyToDownload(true);
  };

  let csvLink: any = React.createRef();
  useEffect(() => {
    if (!_.isEmpty(csvData) && readyToDownload) {
      csvLink.current.link.click();
      setReadyToDownload(false);
    }
  }, [readyToDownload]);

  return (
    <div>
      <div>
        <TableContainer
          component={Paper}
          className={styles.tableContainerHeader}
        >
          <Table sx={{ minWidth: 650 }} aria-label="Reports Table">
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  return column?.id === "customerMaterialNumber" ? (
                    contextDetails?.customerMaterialNumberEnabled && (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : "asc"}
                          onClick={createSortHandler(column.id)}
                        >
                          {column.label}
                          {orderBy === column.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    )
                  ) : (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={createSortHandler(column.id)}
                      >
                        {column.label}
                        {orderBy === column.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {orderChangeReportData?.length > 0 &&
                rows?.length > 0 &&
                stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, key: any) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return column?.id === "customerMaterialNumber" ? (
                            contextDetails?.customerMaterialNumberEnabled && (
                              <TableCell key={column.id} align={column.align}>
                                {value}
                              </TableCell>
                            )
                          ) : column?.id === "sonyOrderNumber" ? (
                            <TableCell key={column.id} align={column.align}>
                              <Link
                                id="linkTag"
                                className={styles.colorBlue}
                                to="/Order_Change_Report/Order_Change_History"
                                state={{
                                  sonyOrderNumber:
                                    orderChangeReportData?.[key+(page*rowsPerPage)]?.[
                                      "sonyReference"
                                    ],
                                }}
                              >
                                {value}
                              </Link>
                            </TableCell>
                          ) : (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className={styles.tableFooter}>
        <div className={styles.tableFooterButton}>
          <CustomButton
            id="printButton"
            label={t("dnet.printer_friendly.text")}
            onClick={handlePrint}
            color="primary"
          />
          <div style={{ display: "none" }}>
            <div ref={componentRef} style={{ padding: "22px" }}>
              {createTable()}
            </div>
          </div>
          <CustomButton
            id="downloadExcelButton"
            label={t("dnet.download_excel.txt")}
            onClick={handleDownload}
          />
          <CSVLink
            data={csvData}
            separator={`;`}
            enclosingCharacter={``}
            filename="SONYOrderReportAudit"
            className={styles.hidden}
            ref={csvLink}
          ></CSVLink>
          <CustomButton
            id="downloadCSV"
            label={t("dnet.download_csv.text")}
            onClick={() => downloadCSV(orderChangeReportData)}
          />
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

OrderChangeTable.defaultProps = {
  initialSort: "model",
};
