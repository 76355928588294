import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./sonyHome.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import {
  homePageButtonsConfig,
  getTranslationAction,
  getPricingInfoArticles,
  getRelatedItemArticles,
  getRelatedToolsArticles,
  getNewProducts,
  getCartTotalProductsCount,
  clearCartOrder,
  getTermsAndConditionSuccess,
  setLoading,
  saveAccountDetails
} from "../../state/actions";
import { useSelector, useDispatch } from "react-redux";
import CustomButton from "../atoms/customButton";
import { ContentArchive } from "./contentArchive";
import { RelatedItemAndPriceInfoTemplate } from "./relatedItemAndPriceInfoTemplate";
import NewProductCard from "./newProductCard";
import CustomModal from "../atoms/customModal";
import { CONTEXT_ORDER } from "../../constants/commonConstants";
var _ = require("lodash");

export const SonyHome = () => {
  // Setting a token for Change dealer module
  window.sessionStorage.setItem("changeDealerSuccess", "false");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [buttonsArray, setButtonsArray] = useState([]);
  const articleTopContent = t("dnet.homepage_articles_top_content_body.text");
  const dispatch = useDispatch();
  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );
  const pricingInfoArticles = useSelector(
    (state: any) => state?.userProfileOptionsReducer?.pricingInfo
  );

  const relatedItemsArticles = useSelector(
    (state: any) => state?.userProfileOptionsReducer?.relatedItem
  );

  const relatedToolsArticles = useSelector(
    (state: any) => state?.userProfileOptionsReducer?.relatedTools
  );

  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.loginResponse?.data?.userDetails?.profile
  );

  const buttonsConfig = useSelector(
    (state: any) => state?.commonReducer?.jsonConfig
  );

  const sonyMenuItems = useSelector(
    (state: any) => state?.commonReducer?.sonyMenuItems
  );

  const currentTranslation = useSelector(
    (state: any) => state?.commonReducer?.translation
  );
  const currentLocale = useSelector(
    (state: any) => state?.commonReducer?.locale
  );

  //New Product Slides
  const newProducts = useSelector(
    (state: any) => state?.commonReducer?.newProducts
  );

  useEffect(() => {
    if (!_.isEmpty(currentTranslation)) {
      i18n.addResourceBundle(
        currentLocale,
        "translation",
        currentTranslation,
        true
      );
      i18n.changeLanguage(currentLocale);
    }
    dispatch(setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTranslation]);

  useEffect(() => {
    dispatch(setLoading(true));
    setButtonsArray([]);
    dispatch(getNewProducts({}));
    dispatch(
      homePageButtonsConfig({
        fileIdentifier: 0,
        inputData: {
          locale: userDetails?.locale,
        },
      })
    );
    if (currentLocale !== userDetails?.locale) {
      dispatch(getTranslationAction({ locale: userDetails?.locale }));
    }
    let params = {
      [`filter_locale[${i18n.language}]`]: i18n.language,
      [`filter_bg[${currentDealer?.bgName}]`]: currentDealer?.bgName,
      [`filter_ecot[4_GB_CONVE]`]: "4_GB_CONVE",
      [`acf_like[main_content_category]`]: "Home",
      [`acf_like[rh_related_category]`]: "SeeAlso",
      max_result: "6",
    };
    let relatedToolsParams = {
      [`filter_locale[${i18n.language}]`]: i18n.language,
      [`filter_bg[${currentDealer?.bgName}]`]: currentDealer?.bgName,
      [`filter_ecot[4_GB_CONVE]`]: "4_GB_CONVE",
      // [`acf_like[product_main_category]`]: "Camerascamcorders",
      [`acf_like[rh_related_category]`]: "Resources",
      max_result: "20",
    };
    let pricingParams = {
      ...params,
      [`acf_like[rh_related_category]`]: "PricingInformation",
    };
    dispatch(
      getPricingInfoArticles({
        module: "PricingInformation",
        params: pricingParams,
      })
    );
    dispatch(getRelatedItemArticles({ module: "RelatedItems", params }));
    dispatch(
      getRelatedToolsArticles({ module: "RelatedTools", params: relatedToolsParams })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let buttonsObj = buttonsConfig?.buttons;
    if (!_.isEmpty(buttonsObj)) {
      let tempArr: any = []
      Object.keys(buttonsObj)?.forEach((button, index) => {
        tempArr?.push(checkSiteSection(
          buttonsObj?.[button]?.nameTranslationKey,
          buttonsObj?.[button]?.section?.split("+"),
          button
        ));
        index === Object.keys(buttonsObj)?.length-1 && 
          tempArr?.push(checkSiteSection(
            "dataDownload.downloadDigitalAsset.title",
            "DNET+Digital+Asset+Download"?.split("+"),
            "lastButton"
          ));
      });
      setButtonsArray(tempArr);
      dispatch(setLoading(false));
    }
    //   checkSiteSection(
    //     "dataDownload.downloadDigitalAsset.title",
    //     "DNET+Digital+Asset+Download"?.split("+"),
    //     "lastButton"
    //   ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonsConfig]);

  //checking buttons with site section mapping
  const checkSiteSection = (
    translationKey: string,
    buttonSection: any,
    buttonPosition: any
  ) => {
    // let buttonsConfiguration = buttonsArray;
    // let validateButton = buttonsConfiguration?.filter(
    //   (elem: any) => elem?.buttonPosition === buttonPosition
    // );
    // if (validateButton?.length === 0) {
      let buttonObj = {};
      for (let i = 0; i < sonyMenuItems.length; i++) {
        let menu = sonyMenuItems?.[i];
        if (menu?.subMenu?.length > 0) {
          let buttonSectionName = buttonSection?.join(" ");
          let subMenuObj = menu?.subMenu?.filter(
            (elem: any) => elem?.sectionName === buttonSectionName
          );
          if (subMenuObj?.length > 0) {
            buttonObj = {
              translationKey,
              route: subMenuObj?.[0]?.route,
              buttonPosition,
            };
            // buttonsConfiguration.push(buttonObj);
          }
        }
      }
      return buttonObj;
      // let buttonArr = [...buttonsConfiguration];
      // setButtonsArray(buttonArr);
    // }
  };

  const cartProductsTotal = useSelector(
    (state: any) => state?.cartReducer?.cartProductsTotal
  )

  const pathname = useLocation();
  const shoppingContextToken = `${currentDealer?.accNum}_${currentDealer?.bgName}_${currentDealer?.loggedInUser?.username}_${currentDealer?.loggedInUser?.profile?.email}`;
  const userNameDetails = useSelector(
    (state: any) => state?.commonReducer?.loginResponse?.data?.userDetails
  );
  useEffect(() => {
    if (_.isEmpty(cartProductsTotal)) {
      dispatch(setLoading(true));
      dispatch(getCartTotalProductsCount({
        shoppingContextToken,
        usernameOrEmail: userNameDetails?.username,
      }));
    }
    dispatch(getTermsAndConditionSuccess({}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const contextDetails = useSelector(
    (state: any) => state?.commonReducer?.contextDetails
  );

  useEffect(()=>{
    if (contextDetails?.key === CONTEXT_ORDER?.RPEORTING_PAGE) {
      dispatch(saveAccountDetails({
        invoiceToAcc: contextDetails?.billToAccounts?.[0],
        payerToAcc: contextDetails?.payerToAccounts?.[0],
        soldToAcc: contextDetails?.soldToAccount,
      }))
    }
  }, [contextDetails])

  const [openModal, setOpenModal] = React.useState(false);

  useEffect(() => {
    if (cartProductsTotal?.totalProductCount > 0 && pathname?.state?.showCartProductModal) {
      dispatch(setLoading(false));
      setOpenModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cartProductsTotal])

  const closeModal = () => {
    setOpenModal(false);
  };
  const clearOrderHandler = () => {
    dispatch(clearCartOrder({
      shoppingContextToken,
      usernameOrEmail: userNameDetails?.username,
    }))
    closeModal()
  }

  const clearOrderRes = useSelector(
    (state:any) => state?.cartReducer?.clearOrderRes
  )

  useEffect(()=>{
    if(clearOrderRes?.contextData)
      dispatch(getCartTotalProductsCount({
        shoppingContextToken,
        usernameOrEmail: userNameDetails?.username,
      }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[clearOrderRes])

  return (
    <>
      {i18n.language === currentLocale && (
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9}>
            <Box className={styles.boxStyle}>
              <Typography variant="subtitle1" color="primary" mb={2}>
                {t("dnet.homepage_articles_top_content_title.text")}
              </Typography>
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{ __html: articleTopContent }}
              ></Typography>
              <div className={styles.buttonBoxStyle}>
                {buttonsArray?.length > 0 &&
                  buttonsArray?.map((button: any, key: any) => {
                    return (
                      !_.isEmpty(button) && 
                      <CustomButton
                        key={key}
                        id={key}
                        label={t(button?.translationKey)}
                        onClick={() => navigate(`/${button?.route}`)}
                        color="primary"
                        margin="0"
                      />
                    );
                  })}
              </div>
            </Box>
            <Box className={styles.boxStyle} style={{ marginTop: "25px" }}>
              <ContentArchive />
            </Box>
          </Grid>
          {(relatedItemsArticles?.length > 0 ||
            pricingInfoArticles?.length > 0 ||
            relatedToolsArticles?.length > 0 || !_.isEmpty(newProducts)) && (
            <Grid item xs={12} lg={3}>
              {!_.isEmpty(newProducts) && (
                <Box
                className={styles.boxStyle}
                mb={2}
                style={{ height: "350px"}}
              >
                <Typography
                  variant="subtitle2"
                  className={styles.header}
                  pb={3}
                  color="primary"
                >
                  {t("dnet.category_newproducts.text")}
                </Typography>
                <NewProductCard
                  products={newProducts}
                />
              </Box>
              )}
              {relatedItemsArticles?.length > 0 && (
                <Box
                  className={styles.boxStyle}
                  mb={3}
                  style={{ height: "300px", overflow: "auto" }}
                >
                  <Typography
                    variant="subtitle2"
                    className={styles.header}
                    pb={3}
                    color="primary"
                  >
                    {t("dnet.related_items.text")}
                  </Typography>
                  <RelatedItemAndPriceInfoTemplate
                    articles={relatedItemsArticles}
                  />
                </Box>
              )}
              {pricingInfoArticles?.length > 0 && (
                <Box
                  className={styles.boxStyle}
                  style={{ height: "300px", overflow: "auto" }}
                  mb={3}
                >
                  <Typography
                    variant="subtitle2"
                    className={styles.header}
                    pb={3}
                    color="primary"
                  >
                    {t("dnet.pricing_info.text")}
                  </Typography>
                  <RelatedItemAndPriceInfoTemplate
                    articles={pricingInfoArticles}
                  />
                </Box>
              )}
              {relatedToolsArticles?.length > 0 && (
                <Box
                  className={styles.boxStyle}
                  style={{ height: "300px", overflow: "auto" }}
                >
                  <Typography
                    variant="subtitle2"
                    className={styles.header}
                    pb={3}
                    color="primary"
                  >
                    {t("dnet.related_tools.text")}
                  </Typography>
                  <RelatedItemAndPriceInfoTemplate
                    articles={relatedToolsArticles}
                  />
                </Box>
              )}
            </Grid>
          )}
        </Grid>
      )}
      <CustomModal
        open={openModal}
        closeModal={closeModal}
        heading={"1"}
        content={
          t("dnet.shopping_cart_memento_message.text")?.toString()?.replace(
            "{0}", cartProductsTotal?.totalProductCount
            )
        }
        cartTotal={true}
        createReport={true}
        clearOrderHandler={clearOrderHandler}
      />
    </>
  );
};
