import { commonConstant } from "../constants/common-constants";

export const initialState = {
  productSearchResult: [],
  dataDownloadData: {},
  productDataDownload:[],
};

const dataDownloadReducer = (state = initialState, action: any) => {
  switch (action?.type) {
    case commonConstant.SEARCH_DATADOWNLOAD_PRODUCT_SUCCESS:
      return {
        ...state,
        productSearchResult: action?.payload,
      };
    case commonConstant.FETCH_DATADOWNLOAD_DATA_SUCCESS:
      return {
        ...state,
        dataDownloadData: action?.payload,
      };
    case commonConstant.DOWNLOAD_DATA_FILE_SUCCESS:
      return {
        ...state,
        productDataDownload: action?.payload,
      }
    default:
      return state;
  }
};

export default dataDownloadReducer;
