import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CONTEXT_ORDER,
  TOAST_SEVERITY,
} from "../../constants/commonConstants";
import { LABELS } from "../../constants/labels";
import { ROUTES_LABEL } from "../../constants/routesLabels";
import {
  getAddressList,
  getSalesAreaMapping,
  setCurrentDealer,
  setCurrentDealerInContext,
  setDealers,
  setOpenToast,
  setSonyMenuItems,
  getTranslationAction,
  startProductFlow,
  fetchProdCategoryResponse,
  getCartTotalProductsCountSuccess,
  saveQuoteRefData,
  saveQuoteSpecialInstructionData,
  saveMultiAddresses,
  getCartProductsSuccess,
  setLoading,
  logoutAction,
  getCurrentDealerSuccess,
  fetchProdCategoryResponseSuccess,
  startProductFlowSuccess,
  setCurrentAddressSet,
  getDealerDetailsSuccess,
  loginUserSuccess,
  setIsUserLoggedin,
  saveLoginError,
  getContextDetails,
} from "../../state/actions";
import CustomModal from "../atoms/customModal";
import logger from "../helper/logger";
import { createMenuItems } from "../helper/util";
import styles from "./dealer-selection.module.scss";
import { Loader } from "../atoms/loader";
var _ = require("lodash");

export const DealerSelection = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.loginResponse?.data?.userDetails
  );

  //list of dealers currenlty displaying on screen
  const dealersList = useSelector(
    (state: any) => state?.commonReducer?.dealersList
  );

  //list of all "bgName,accnum", fetched from login response
  const allDealers = useSelector(
    (state: any) => state?.commonReducer?.allDealers
  );

  //current set of address fetched based on sent payload(list of accounts)
  const addressList = useSelector(
    (state: any) => state?.commonReducer?.addressList
  );

  const currentTranslation = useSelector(
    (state: any) => state?.commonReducer?.translation
  );

  const currentLocale = useSelector(
    (state: any) => state?.commonReducer?.locale
  );

  const siteSectionDetails = useSelector(
    (state: any) => state?.commonReducer?.siteSectionDetails
  );

  const prodCategoryResponse = useSelector(
    (state: any) => state?.commonReducer?.prodCategoryResponse
  );

  useEffect(() => {
    i18n.addResourceBundle(
      currentLocale,
      "translation",
      currentTranslation,
      true
    );
    i18n.changeLanguage(currentLocale);
  }, [currentTranslation]);

  //calls the api to fetch the address details
  useEffect(() => {
    dispatch(setLoading(true));
    // if (_.isEmpty(addressList?.dealers)) {
      let accList: any = [];
      allDealers.forEach((dealer: any) => {
        let details = dealer?.split(",");
        //Duplicate acc num should not be sent due to dynamoDB key restrictions
        let exisitingIndex = accList?.findIndex(
          (acc: any) => acc.accountId === details[1]
        );
        if (exisitingIndex === -1)
          accList.push({ bgGroup: details[0], accountId: details[1] });
      });
      dispatch(getAddressList({ accountDetails: accList }));
    // }
    if (currentLocale !== userDetails?.profile?.locale) {
      dispatch(getTranslationAction({ locale: userDetails?.profile?.locale }));
    }
  }, []);

  const logout = () => {
    dispatch(setIsUserLoggedin(false));
    dispatch(loginUserSuccess({}));
    dispatch(setCurrentDealer({}));
    dispatch(getDealerDetailsSuccess([]));
    dispatch(setSonyMenuItems([]));
    dispatch(setDealers([]));
    dispatch(setCurrentAddressSet(-1));
    dispatch(startProductFlowSuccess({}));
    dispatch(fetchProdCategoryResponseSuccess({}));
    dispatch(getCurrentDealerSuccess({}));
    dispatch(logoutAction({}));
    dispatch(saveLoginError({
      error: t("dnet.no_valid_dealership.text")?.toString()
    }));
    navigate(`/${ROUTES_LABEL.LOGIN}`);
  };

  const checkDuplicateDealer = (
    dealer: any,
    currentDealers: any,
    exisitingIndex: any
  ) => {
    if (currentDealers[exisitingIndex]?.bgName !== dealer[0]) {
      return true;
    } else {
      let duplicateEntry = currentDealers[exisitingIndex]?.accounts?.filter(
        (account: any) => account?.number === dealer[1]
      );
      if (duplicateEntry?.length > 0) {
        return false;
      } else {
        return true;
      }
    }
  };
  const isHybrid = (groups: any[]): boolean => {
    if (groups.length !== 2) {
      return false;
    }
    const hasTypeD = groups.some((group) => group.type === "D");
    const hasTypeR = groups.some((group) => group.type === "R");

    return hasTypeD && hasTypeR;
  };
  useEffect(() => {
    if (addressList?.dealers && addressList?.dealers?.length > 0) {
      if (addressList?.dealers?.length === 1) {
        let accKey = Object.keys(addressList?.dealers?.[0])
        let singleAccountObj = addressList?.dealers?.[0]?.[accKey?.[0]];
        singleAccountObj = {
          ...singleAccountObj,
          number: accKey[0],
        }
        dealerHandler(addressList?.dealers?.[0]?.[accKey?.[0]]?.bgGroup, singleAccountObj, true)
      } else if (allDealers?.length === 1) {
        let dealer = allDealers[0]?.split(",");
        let nameArray: any = addressList?.dealers?.filter(
          (nameList: any) => nameList[dealer[1]] && nameList[dealer[1]]?.bgGroup === dealer[0]
        );
        let singleAccountObj = nameArray?.[0]?.[dealer[1]];
        singleAccountObj = {
          ...singleAccountObj,
          number: dealer[1],
        }
        dealerHandler(dealer[0], singleAccountObj, true)
      } else {
      let currentDealers: any = [];
      allDealers.forEach((d: any) => {
        let dealer = d?.split(",");
        let exisitingIndex = currentDealers?.findIndex(
          (d: any) => d.bgName === dealer[0]
        );
        //if business group is already existing, we need to append accounts to it, else create new entry for the business group
        if (exisitingIndex > -1) {
          let nameArray: any = addressList?.dealers?.filter(
            (nameList: any) =>
              nameList[dealer[1]] &&
              nameList[dealer[1]]?.bgGroup === dealer[0] &&
              checkDuplicateDealer(dealer, currentDealers, exisitingIndex)
          );
          //add account only if the details of it is fetched from BE
          if (nameArray?.length > 0) {
            let details = nameArray?.[0]?.[dealer[1]];
            currentDealers[exisitingIndex].accounts.push({
              number: dealer[1],
              name: details?.name,
              street: details?.street,
              postalCode: details?.postalCode,
              city: details?.city,
              country: details?.country,
              townCity: details?.townCity,
              currentAccountFlag: details?.currentAccountFlag,
              ecot3: details?.ecot3,
            });
          }
        } else {
          let nameArray: any = addressList?.dealers?.filter(
            (nameList: any) => nameList[dealer[1]] && nameList[dealer[1]]?.bgGroup === dealer[0]
          );
          if (nameArray?.length > 0) {
            let details = nameArray?.[0]?.[dealer[1]];
            currentDealers.push({
              bgName: dealer[0],
              accounts: [
                {
                  number: dealer[1],
                  name: details?.name,
                  street: details?.street,
                  postalCode: details?.postalCode,
                  city: details?.city,
                  country: details?.country,
                  townCity: details?.townCity,
                  currentAccountFlag: details?.currentAccountFlag,
                  ecot3: details?.ecot3,
                },
              ],
            });
          }
        }
      });
      dispatch(setDealers(currentDealers));
    }
    } else if (addressList?.dealers && addressList?.dealers?.length === 0) {
      logout()
    }
  }, [addressList]);

  useEffect(()=>{
    if (dealersList?.length > 0)
      dispatch(setLoading(false));
  },[dealersList])

  const [openModal, setOpenModal] = React.useState(false);

  const closeModal = () => {
    setOpenModal(false);
  };

  const sonyMenuItems = useSelector(
    (state: any) => state?.commonReducer?.sonyMenuItems
  );

  const setCurrentDealerSuccessRes = useSelector(
    (state: any) => state?.commonReducer?.setCurrentDealerSuccessRes
  );

  const [selectedBG, setSelectedBG] = useState("");

  const [isInternalAdmin, setIsInternaladmin] = useState(false);

  const [selectedAccount, setSelectedAccount] = useState("");

  const [ecot3, setEcot3] = useState("");

  const loginResponse = useSelector(
    (state: any) => state?.commonReducer?.loginResponse
  );

  const setProductResponse = useSelector(
    (state: any) => state?.commonReducer?.productFlow
  );


  const dispatchSetProduct = () => {
    dispatch(
      startProductFlow({
        siteLocale: currentLocale,
      })
    );
    dispatch(
      getContextDetails({
        key: CONTEXT_ORDER?.RPEORTING_PAGE,
      })
    );
  };

  const dispatchFetchProdCategory = () => {
    if (_.isEmpty(prodCategoryResponse)) {
      dispatch(fetchProdCategoryResponse({}));
    }
  };
  const dispatchSonyMenu = () => {
    if (isHybrid(userDetails?.businessGroups)) {
      let allBgNames = userDetails?.businessGroups?.map(
        (group: any) => group?.name
      );

      let allRoles = userDetails?.businessGroups?.flatMap(
        (group: any) => group.roles
      );
      if (allRoles?.length > 0) {
        dispatch(
          setSonyMenuItems(
            createMenuItems(
              siteSectionDetails,
              allRoles,
              allBgNames,
              userDetails?.profile?.locale,
              userDetails,
              prodCategoryResponse?.data?.catalogList[0],
              ecot3
            )
          )
        );
      }
    } else {
      let currentRoles = userDetails?.businessGroups.find(
        (bg: any) => bg.name === selectedBG
      )?.roles;

      let bgName = isInternalAdmin ? selectedAccount : selectedBG;

      currentRoles = isInternalAdmin ? ["priceChangeViewer"] : currentRoles;
      if (currentRoles?.length > 0) {
        dispatch(
          setSonyMenuItems(
            createMenuItems(
              siteSectionDetails,
              currentRoles,
              bgName,
              userDetails?.profile?.locale,
              userDetails,
              prodCategoryResponse?.data?.catalogList[0],
              ecot3
            )
          )
        );
      }
    }
  };

  useEffect(() => {
   
    if (setCurrentDealerSuccessRes?.success) {
      //navigate(`/${ROUTES_LABEL.SONY_HOMEPAGE}`);
      dispatchSetProduct();
    }
  }, [setCurrentDealerSuccessRes]);

  useEffect(() => {
    if (setProductResponse?.success) {
      dispatchFetchProdCategory();
    }
  }, [setProductResponse]);

  useEffect(() => {
    if (!_.isEmpty(prodCategoryResponse) && prodCategoryResponse?.success) {
      dispatchSonyMenu();
    }
  }, [prodCategoryResponse]);

  useEffect(() => {
    if (
      sonyMenuItems?.length > 0 && 
      setCurrentDealerSuccessRes?.success && 
      setProductResponse?.success
    ) {
      dispatch(getCartTotalProductsCountSuccess({}));
      dispatch(saveQuoteRefData({
        customerReference: ""
      }));
      dispatch(saveQuoteSpecialInstructionData({
        specialInstruction: []
      }));
      dispatch(saveMultiAddresses({
        multiSelectArray: []
      }));
      dispatch(getCartProductsSuccess({}));
      dispatch(setLoading(false));
      navigate(`/${ROUTES_LABEL.SONY_HOMEPAGE}`, {
        state: {
          showCartProductModal: true
        }
      });
    }
  }, [sonyMenuItems]);

  //Fetchs sitesection and sales area mapping based on selected dealer account
  const dealerHandler = (bgName: any, account: any, singleAccount: any = false) => {
    let currentRoles = userDetails?.businessGroups.find(
      (bg: any) => bg.name === bgName
      )?.roles;
      if (currentRoles?.length <= 0) {
        dispatch(
          setOpenToast({
            openToast: true,
            severity: TOAST_SEVERITY.ERROR,
            message: t("dnet.access_error.text")?.toString(),
          })
          );
      } else {
        dispatch(setLoading(true));
        logger.info("Selected Business group ", bgName);
        logger.info("Selected Account ", account?.number);
          setSelectedBG(bgName);
          setSelectedAccount(account);
          setEcot3(account?.ecot3);
          dispatch(
          setCurrentDealer({
            bgName,
            accNum: account?.number,
            accountName: account?.name,
            street: account?.street,
            country: account?.country,
            townCity: account?.townCity,
            loggedInUser: userDetails,
            singleAccount,
            accountFlag: account?.currentAccountFlag,
            ecot3: account?.ecot3,
          })
        );
        dispatch(
          setCurrentDealerInContext({
            accountFlag: account?.currentAccountFlag,
            accountId: account?.number,
            msa: bgName,
          })
        );
      }
    dispatch(
      getSalesAreaMapping({
        selectedDealership: account?.number,
        localeID: bgName,
      })
    );
  };

  //Fetchs sitesection and sales area mapping based on selected internal admin account
  const internalDealerHandler = (bgName: any, account: any, accountFlag: any) => {
    let currentRoles = ["priceChangeViewer"];
    if (currentRoles?.length <= 0) {
      dispatch(
        setOpenToast({
          openToast: true,
          severity: TOAST_SEVERITY.ERROR,
          message: t("dnet.access_error.text")?.toString(),
        })
        );
      } else {
        dispatch(setLoading(true));
        logger.info("Selected Business group ", bgName);
        logger.info("Selected Account ", account);
          setSelectedBG(bgName);
          setIsInternaladmin(true);
          setSelectedAccount(account);
          dispatch(
          setCurrentDealer({
            bgName,
            accNum: account,
            accountName: account,
            loggedInUser: userDetails,
            singleAccount: false,
            accountFlag,
            accountId: account,
          })
        );
        dispatch(
          setCurrentDealerInContext({
            accountFlag,
            accountId: account,
            msa: bgName,
          })
        );
    }
    dispatch(
      getSalesAreaMapping({
        selectedDealership: account,
        localeID: bgName,
      })
    );
  };

  return (
    <>
      {i18n.language == currentLocale && dealersList?.length > 0 ? (
        <>
          <Box className={styles.infoContainer}>
            <InfoOutlinedIcon color="action" />
            <Typography
              component="div"
              variant="body2"
              color="text.secondary"
              className={styles.infoText}
            >
              {t("dnet.site_content1.text")}
            </Typography>
          </Box>
          {/* <InfiniteScroll
          dataLength={dealersList?.length - 1}
          next={fetchMoreRecords}
          hasMore={hasMore}
          loader={<Typography variant="h6" color="primary" mt={1}>Loading...</Typography>}
        > */}
          {dealersList?.map((dealer: any, index: any) => (
            <React.Fragment key={index}>
              {dealer?.accounts?.length > 0 && (
                <Box className={styles.bgContainer} key={dealer?.bgName}>
                  <Typography variant="h6" color="primary" mb={2}>
                    {t(`dnet.msa_${dealer?.bgName}.text`)}
                  </Typography>
                  <Grid container spacing={4}>
                    {dealer?.accounts?.map((account: any, index: any) => (
                      <Grid item lg={3} md={6} sm={6} xs={12} key={index}>
                        <Box
                          className={styles.accCard}
                          onClick={() => {
                            dealerHandler(dealer?.bgName, account);
                          }}
                        >
                          <Typography
                            variant="body2"
                            className={styles.cardHeading}
                          >
                            {account?.number}
                          </Typography>
                          <Typography
                            component="div"
                            variant="body2"
                            color="text.secondary"
                            mt={1}
                          >
                            {/* {`${account.name}, ${account.address.street}, ${account.address.townCity} (${account.address.country})`} */}
                            {account.name}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
            </React.Fragment>
          ))}
          {addressList?.internalAdmins?.length > 0 && 
            <React.Fragment>
                <Box className={styles.bgContainer}>
                  <Typography variant="h6" color="primary" mb={2}>
                    {t("internalAdmin.title").toString()}
                  </Typography>
                  <Grid container spacing={4}>
                  {addressList?.internalAdmins?.length > 0 && 
                    addressList?.internalAdmins?.map((admin: any, index: any) => (
                      <Grid item lg={3} md={6} sm={6} xs={12} key={index}>
                        <Box
                          className={styles.accCard}
                          onClick={() => {
                            internalDealerHandler(admin?.masterSalesArea, admin?.name, admin?.currentAccountFlag);
                          }}
                        >
                          <Typography
                            variant="body2"
                          >
                            {`${admin?.name} - ${admin?.masterSalesArea}`}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
            </React.Fragment>
          }
          {/* </InfiniteScroll> */}
          <CustomModal
            open={openModal}
            closeModal={closeModal}
            heading={LABELS.ACCESS_DENIED}
            content={t("gzl.unauthorized_access.text")}
          />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
