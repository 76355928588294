import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./orderChangeHistory.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getOrderChangeHistoryData, getContextDetails } from "../../../../state/actions";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { OrderChangeHistoryTable } from "./orderChangeHistoryTable";
import CustomDropDown from "../../../atoms/customDropDown";
import { CONTEXT_ORDER } from "../../../../constants/commonConstants";
import { ROUTES_LABEL } from "../../../../constants/routesLabels";
import CustomButton from "../../../atoms/customButton";

export const OrderChangeHistory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  let sonyOrderNumber = location?.state?.sonyOrderNumber;
  const [shippingAgentArr, setShippingAgentArr]: any = useState([]);
  const [selectedShippingAgent, setSelectedShippingAgent]: any = useState("");

  const contextDetails = useSelector(
    (state: any) => state?.commonReducer?.contextDetails
  );
  const accountFormatter = (accountObj: any) => {
    return `${accountObj?.number} - ${accountObj?.name}, ${accountObj?.address?.street}, ${accountObj?.address?.townCity} (${accountObj?.address?.country})`;
  };

  const orderChangeHistoryData = useSelector(
    (state: any) =>
      state?.orderChangeReportReducer?.orderChangeHistoryData
        ?.orderHeaderDetails
  );

  const orderChangeHistoryDataRed = useSelector(
    (state: any) =>
      state?.orderChangeReportReducer?.orderChangeHistoryData
  );

  useEffect(() => {
    dispatch(
      getContextDetails({
        key: CONTEXT_ORDER?.ORDER_CHANGE_HISTORY_PAGE,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contextDetails?.key === CONTEXT_ORDER?.ORDER_CHANGE_HISTORY_PAGE)
      dispatch(
        getOrderChangeHistoryData({ 
          sonyReference: sonyOrderNumber 
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextDetails])

  useEffect(() => {
    if (orderChangeHistoryData?.shippingAgentAccount?.length > 1) {
      let tempArr: any = [];
      tempArr = orderChangeHistoryData?.shippingAgentAccount?.map(
        (elem: any) => {
          return (elem = {
            value: elem?.number,
            label: accountFormatter(elem),
          });
        }
      );
      setShippingAgentArr(tempArr);
      setSelectedShippingAgent(tempArr[0]?.value);
    }
  }, [orderChangeHistoryData]);

  return (
    <div>
      <Typography
        variant="subtitle1"
        className={styles.header}
        pb={3}
        color="primary"
      >
        {t("dnet.order_change_history.text")}
      </Typography>
      {Object.keys(orderChangeHistoryDataRed)?.length > 0 &&
      <Box className={styles.boxContainer}>
        <div>
          <div className={styles.orderBox}>
            <Typography variant="body2" className={styles.textWidth}>
              {t("dnet.order_report.created_on.text")}
            </Typography>
            <div>:</div>
            <Typography variant="body2">
              {moment(orderChangeHistoryData?.creationDate).format(
                "DD-MMM-YYYY"
              )}
            </Typography>
          </div>
          <div className={styles.orderBox}>
            <Typography variant="body2" className={styles.textWidth}>
              {t("dnet.order_report.your_ref.text")}
            </Typography>
            <div>:</div>
            <Typography variant="body2">
              {orderChangeHistoryData?.customerReference}
            </Typography>
          </div>
          <div className={styles.orderBox}>
            <Typography variant="body2" className={styles.textWidth}>
              {t("dnet.order_report.sony_ref.text")}
            </Typography>
            <div>:</div>
            <Typography variant="body2">{sonyOrderNumber}</Typography>
          </div>
          <div className={styles.orderBox}>
            <Typography variant="body2" className={styles.textWidth}>
              {t("dnet.account_no.text")}
            </Typography>
            <div>:</div>
            <Typography variant="body2">{`${orderChangeHistoryData?.soldToAccount?.number} - ${orderChangeHistoryData?.soldToAccount?.name}`}</Typography>
          </div>
          <div className={styles.orderBox}>
            <Typography variant="body2" className={styles.textWidth}>
              {t("dnet.deliver_to.text")}
            </Typography>
            <div>:</div>
            <Typography variant="body2">{`${orderChangeHistoryData?.shipToAccount?.number} - ${orderChangeHistoryData?.shipToAccount?.name}`}</Typography>
          </div>
          <div className={styles.orderBox}>
            <Typography variant="body2" className={styles.textWidth}>
              {t("dnet.order_report.deliver_to_address.text")}
            </Typography>
            <div>:</div>
            <Typography variant="body2">{`${orderChangeHistoryData?.shipToAccount?.address.street} ${orderChangeHistoryData?.shipToAccount?.address?.postCode} ${orderChangeHistoryData?.shipToAccount?.address?.city}`}</Typography>
          </div>
          {orderChangeHistoryData?.shippingAgentAccount?.length > 0 && (
            <div className={styles.orderBox}>
              <Typography variant="body2" className={styles.textWidth}>
                {t("dnet.shipping_agent.text")}
              </Typography>
              <div>:</div>
              {orderChangeHistoryData?.shippingAgentAccount?.length === 1 ? (
                <Typography variant="body2">
                  {accountFormatter(
                    orderChangeHistoryData?.shippingAgentAccount[0]
                  )}
                </Typography>
              ) : (
                <CustomDropDown
                  value={selectedShippingAgent}
                  handleChange={(e: any) => {
                    setSelectedShippingAgent(e.target.value);
                  }}
                  menuOptions={shippingAgentArr}
                  module="debitNoteCreation"
                  label=""
                />
              )}
            </div>
          )}
          {contextDetails?.paymentTermEnabled && (
            <div className={styles.orderBox}>
              <Typography variant="body2" className={styles.textWidth}>
                {t("dnet.payer.text")}
              </Typography>
              <div>:</div>
              <Typography variant="body2">
                {accountFormatter(orderChangeHistoryData?.payerToAccount)}
              </Typography>
            </div>
          )}
          {contextDetails?.paymentTermEnabled && (
            <div className={styles.orderBox}>
              <Typography variant="body2" className={styles.textWidth}>
                {t("dnet.payment_term.text")}
              </Typography>
              <div>:</div>
              <Typography variant="body2">{`${orderChangeHistoryData?.paymentTerm?.name}-${orderChangeHistoryData?.paymentTerm?.description}`}</Typography>
            </div>
          )}
        </div>
        <div className={styles.buttonSection}>
          <CustomButton
            id="back"
            label={t("dnet.back_to_search_results.text")}
            onClick={() => {
              navigate(`/${ROUTES_LABEL?.ORDER_CHANGE_REPORT}`, {
                state: {
                  orderChangeSearch: "true",
                }
              })
            }}
          />
        </div>
        <Box className={styles.tableContainer} style={{ width: "100%" }}>
          <OrderChangeHistoryTable />
        </Box>
      </Box>}
    </div>
  );
};
