import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DISPUTED_ITEMS } from "../../../constants/labels";
import styles from "./disputed-items.module.scss";
import { useDispatch } from "react-redux";
import { getDisputedItems } from "../../../state/actions";
import { useSelector } from "react-redux";
import CustomTextField from "../../atoms/customTextField";
import moment from "moment";
import CustomDropDown from "../../atoms/customDropDown";
import CustomButton from "../../atoms/customButton";
import { DisputedItemsTable } from "./disputed-items-table";
import { useTranslation } from "react-i18next";
import logger from "../../helper/logger";

export const DisputedItems = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.userDetails
  );
  
  const saveAccountDetails = useSelector(
    (state: any) => state?.commonReducer?.saveAccountDetails
  )

  //on load by default unresolved queries are fetched
  useEffect(() => {
    dispatch(
      getDisputedItems({
        language: userDetails?.language,
        username: userDetails?.userName,
        organizationKey: "GB00",
        partnerKey: saveAccountDetails?.payerToAcc?.number,
        resolved: false,
        startDate:  moment(new Date()).format("YYYYMMDD"), 
        endDate: moment(new Date()).format("YYYYMMDD"), 
        itemType: 2
      })
    );
  }, []);

  const disputedItems = useSelector(
    (state: any) => state?.gazelleReducer?.disputedItems
  );

  const queryTypeList = [
    DISPUTED_ITEMS.UNRESOLVED_QUERIES,
    DISPUTED_ITEMS.RESOLVED_QUERIES,
  ];
  const [queryType, setQueryType] = useState(DISPUTED_ITEMS.UNRESOLVED_QUERIES);

  //on change of query type
  const queryTypeHandler = (type: string) => {
    logger.info("Selected query type ",type);
    setQueryType(type);
    let resolved = false;
    if (type === DISPUTED_ITEMS.RESOLVED_QUERIES) resolved = true;
    dispatch(
      getDisputedItems({
        language: userDetails?.language,
        username: userDetails?.userName,
        organizationKey: "GB00",
        partnerKey: saveAccountDetails?.payerToAcc?.number,
        resolved: resolved,
        startDate:  moment(new Date()).format("YYYYMMDD"), 
        endDate: moment(new Date()).format("YYYYMMDD"), 
        itemType: 2
      })
    );
  };

  //loads unresolved on click of refresh
  const onRefresh = () => {
    logger.info("Refreshing disputed items data");
    dispatch(
      getDisputedItems({
        language: userDetails?.language,
        username: userDetails?.userName,
        organizationKey: "GB00",
        partnerKey: saveAccountDetails?.payerToAcc?.number,
        resolved: false,
        startDate:  moment(new Date()).format("YYYYMMDD"), 
        endDate: moment(new Date()).format("YYYYMMDD"), 
        itemType: 2
      })
    );
  };
  return (
    <>
      <Typography variant="subtitle1" className={styles.header} pb={3} color="primary">
        {t("hacksite.function.disputed_items")}
      </Typography>
      <Box>
        <Box className={styles.boxContainer}>
          <CustomTextField
            id="lastRetrievalDate"
            label={t("gzl.last_retrieval_date.text")}
            value={
              moment(disputedItems?.lastRetrievalDate).utc().format(
                "DD/MM/YYYY HH:mm"
              ) + " UTC"
            }
            disabled = {true}
          />
          <Box className={styles.dropDown}>
            <CustomDropDown
              id="queryType"
              value={queryType}
              handleChange={(e: any) => queryTypeHandler(e.target.value)}
              menuItems={queryTypeList}
              label={t("gzl.query_type.text")?.toString()}
            />
          </Box>
        </Box>

        <Box className={styles.tableContainer}>
          <div className={styles.tableHeader}>
            <CustomButton
              id="refresh"
              label={t("gzl.refresh.text")}
              onClick={onRefresh}
              color="black"
            />
          </div>
          <DisputedItemsTable />
        </Box>
      </Box>
    </>
  );
};
