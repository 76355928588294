import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import SearchIcon from "@mui/icons-material/Search";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import {
  Box,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../atoms/customButton";
import CustomTextField from "../../../atoms/customTextField";
import {
  getAvailabilityDetails,
  plus2,
  minus2,
  fetchWarrantyProducts,
} from "../../../helper/util";
import styles from "./singleOrderEntryTable.module.scss";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import { ROUTES_LABEL } from "../../../../constants/routesLabels";
import CustomDropDown from "../../../atoms/customDropDown";
import {
  setCurrentProductSku,
  fetchProductDetailsData,
  getProductsByMaterialName,
  getProductsByMaterialNameSuccess,
  deleteSelectedItemCart,
  fetchGlobalSearchSuggestions,
  fetchGlobalSearchSuggestionsSuccess,
} from "../../../../state/actions";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CSVLink } from "react-csv";
import logger from "../../../helper/logger";

var currencyFormatter = require("currency-formatter");
const alertstar = require("../../../../assets/images/alertstar_002.gif");
const csvImage = require("../../../../assets/images/disccsv.gif");
const xmlImage = require("../../../../assets/images/discxml.gif");
const uploadImage = require("../../../../assets/images/discload.gif");
var _ = require("lodash");
var js2xmlparser = require("js2xmlparser");

export const SingleOrderEntryTable = ({
  parent,
  saveHandler,
  orderEntryMultiship,
  handleOpenFileModal,
  isMultiRequestedDeliveryDate,
  handleQuotationButton,
  updateButtonClickHandler,
  startingMinDate,
  shouldDisableDate,
  clearOrder,
  orderEntryObj,
}: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tableHeaderData = [
    { id: "select", label: "select" },
    { id: "model", label: t("dnet.model.text") },
    { id: "orderSearch", label: t("dnet.find_open_orders.text") },
    { id: "countryoforigin", label: t("dnet.countryoforigin.short.text") },
    // {
    //   id: "customerMaterialNumber",
    //   label: t("customerMaterialNumber.text"),
    // },
    { id: "qty", label: t("dnet.qty.text") },
    { id: "requsestedDate", label: t("dnet.req_date.text") },
    { id: "availability", label: t("dnet.availability.text") },
    { id: "list", label: t("dnet.list.text") },
    { id: "description", label: t("dnet.description.text") },
    { id: "specialPrice", label: t("dnet.special_price.text") },
  ];
  type Order = "asc" | "desc";
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<any>("model");
  const selectedCartProducts = useSelector(
    (state: any) => state?.cartReducer?.selectedCartProducts
  );

  useEffect(() => {
    dispatch(getProductsByMaterialNameSuccess({}));
  }, []);

  const dummyProductData = (Id: any) => {
    return {
        Id,
        "checked": false,
        "productSku": {
            "name": ""
        },
        "availabilityMap": {
            "NOFG": {
                "sku": {
                    "materialName": "",
                    "salesArea": ""
                },
                "date": "",
                "forecastGroup": "",
                "quantity": "",
                "status": "",
                "countryOfOrigin": "",
                "centralStockStatus": "",
                "centralStockQuantity": "",
                "centralStockDate": ""
            }
        },
        "price": "",
        // {
        //     "currency": "",
        //     "retailPrice": ""
        // },
        "description": "",
        "uri": "",
        "customerMaterialNumber": "",
        "origin": "",
        "specialPrice": "",
        "isWarranty": false,
        "warrantyLineItems": [],
        "requestedDeliveryDate": "",
        "quantity": "",
        "subLineItems": [
          {
            "quantity": "",
            "requestedDeliveryDate": null
          }
        ]
    }
  };
  const dummyRddDataFormat = () => {
    return {
        "quantity": "",
        "requestedDeliveryDate": null
    }
  }
  const [prodContentData, setProdContentData] = useState<any>();

  useEffect(() => {
    if (selectedCartProducts?.shipToAccountId?.length > 0 &&
        (selectedCartProducts?.shoppingCart?.lineItems?.length < 100 
        || selectedCartProducts?.shoppingCart?.lineItems === undefined)
      ) {
      let prods: any = JSON.parse(JSON.stringify(selectedCartProducts));
      if (
        selectedCartProducts?.shoppingCart?.lineItems?.length > 0
      ) {
        setUpdateButtonDisabled(false)
        let definedNumber = selectedCartProducts?.shoppingCart?.lineItems?.length > 7 && 
          selectedCartProducts?.shoppingCart?.lineItems?.length > 90 ? 
            100 - selectedCartProducts?.shoppingCart?.lineItems?.length :
            selectedCartProducts?.shoppingCart?.lineItems?.length > 7 ? 10 : 3;
        for(let i=0; i < definedNumber; i++) {
          prods?.shoppingCart?.lineItems?.push(dummyProductData(i+1));
        }
      } else {
        setUpdateButtonDisabled(true)
        let tempLineItems: any = [];
        for (let i = 0; i < 5; i++) {
          tempLineItems?.push(dummyProductData(i+1))
        }
        prods.shoppingCart = {
          ...prods?.shoppingCart,
          lineItems : tempLineItems
        }
      }
      for (let j = 0; j < prods?.shoppingCart?.lineItems?.length; j++) {
        let sublineItemLength = prods?.shoppingCart?.lineItems[j]?.subLineItems?.length;
        if (sublineItemLength < 100 || sublineItemLength===undefined) {
          if (sublineItemLength === 0 || sublineItemLength===undefined) {
            let tempSubLine: any = []
            for (let i = 0; i < 4; i++) {
              tempSubLine.push(dummyRddDataFormat())
            }
            prods.shoppingCart.lineItems[j] = {
              ...prods?.shoppingCart?.lineItems[j],
              subLineItems: tempSubLine
            }
          } else {
            let correctSubLineItem:any = []
            prods?.shoppingCart?.lineItems[j]?.subLineItems?.forEach((nItem: any) => {
              if (nItem?.requestedDeliveryDate !== null)
                correctSubLineItem?.push(nItem);
            })
            let currentSubLineItemLength = correctSubLineItem?.length;
            if (currentSubLineItemLength > 0 && sublineItemLength - currentSubLineItemLength < 3) {
              for (let i = 0; i < 3-(sublineItemLength - currentSubLineItemLength); i++) {
                prods?.shoppingCart?.lineItems[j]?.subLineItems?.push(dummyRddDataFormat())
              }
            } else if (
              currentSubLineItemLength === 0 &&
              sublineItemLength > 0 &&
              sublineItemLength < 4
            ) {
              for (let i = 0; i < 4-sublineItemLength; i++) {
                prods?.shoppingCart?.lineItems[j]?.subLineItems?.push(dummyRddDataFormat())
              }
            }
          }
        }
        let warrantyLineItemsLength = prods?.shoppingCart?.lineItems[j]?.warrantyLineItems?.length;
        if (warrantyLineItemsLength > 0 && prods?.shoppingCart?.lineItems[j]?.isWarranty) {
          for (let i = 0; i < prods?.shoppingCart?.lineItems[j]?.warrantyLineItems?.length; i++) {
            let warrantySublineItemLength = 
              prods?.shoppingCart?.lineItems[j]?.warrantyLineItems[i]?.subLineItems?.length;
            if (warrantySublineItemLength < 100 || warrantySublineItemLength===undefined) {
              if (warrantySublineItemLength === 0 || warrantySublineItemLength===undefined) {
                let tempSubLine: any = []
                for (let k = 0; k < 4; k++) {
                  tempSubLine.push(dummyRddDataFormat())
                }
                prods.shoppingCart.lineItems[j].warrantyLineItems[i] = {
                  ...prods?.shoppingCart?.lineItems[j]?.warrantyLineItems[i],
                  subLineItems: tempSubLine
                }
              } else {
                for (let k = warrantySublineItemLength; k < 4; k++) {
                  prods?.shoppingCart?.lineItems[j]?.warrantyLineItems[i]?.subLineItems?.push(
                    dummyRddDataFormat()
                  )
                }
              }
            }
          }
        }
      }
      prods?.shoppingCart?.lineItems?.forEach((item: any) => {
        item.checked = false
      })
      setProdContentData(prods);
    } else {
      if (!_.isEmpty(selectedCartProducts)) {
        let prods: any = JSON.parse(JSON.stringify(selectedCartProducts));
        prods?.shoppingCart?.lineItems?.forEach((item: any) => {
          item.checked = false
        })
        setUpdateButtonDisabled(false);
        setProdContentData(prods);
      }
    }
  }, [selectedCartProducts]);

  const expandDeliveryDateHandler = (index: any) => {
    let prods = JSON.parse(JSON.stringify(prodContentData));
    let warrantyIndex: any = []
    if (index?.toString()?.includes("_")) {
      warrantyIndex = index?.split("_");
    }
    if (warrantyIndex?.length > 0) {
      prods.shoppingCart.lineItems[warrantyIndex[1]].warrantyLineItems[warrantyIndex[0]].rddExpand = true;
    } else
      prods.shoppingCart.lineItems[index].rddExpand = true;
    setProdContentData(prods);
  };

  const minimizeDeliveryDateHandler = (index: any) => {
    let prods = JSON.parse(JSON.stringify(prodContentData));
    let warrantyIndex: any = []
    if (index?.toString()?.includes("_")) {
      warrantyIndex = index?.split("_");
    }
    if (warrantyIndex?.length > 0) {
      prods.shoppingCart.lineItems[warrantyIndex[1]].warrantyLineItems[warrantyIndex[0]].rddExpand = false;
    } else
      prods.shoppingCart.lineItems[index].rddExpand = false;
    setProdContentData(prods);
  };

  const handleMultiDeliveryDate = (rddItemId: any, value: any, index: any) => {
    let prods = JSON.parse(JSON.stringify(prodContentData));
    let warrantyIndex: any = []
    if (index?.toString()?.includes("_")) {
      warrantyIndex = index?.toString()?.split("_");
      prods.shoppingCart.lineItems[warrantyIndex[1]].warrantyLineItems[warrantyIndex[0]].subLineItems[rddItemId].requestedDeliveryDate = new Date(value?.$d);
    } else
      prods.shoppingCart.lineItems[index].subLineItems[rddItemId].requestedDeliveryDate = new Date(value?.$d);
    setProdContentData(prods);
  };
  const [showPriceList, setShowPriceList] = useState(true);
  const productList = useSelector(
    (state: any) => state?.productListReducer?.productList
  )
  const [productListItems, setProductListItems]: any = useState([]);

  useEffect(()=>{
    if (!_.isEmpty(productList)) {
      let productAllList: any = productList;
      let productListItemsTemp: any = [];
      Object.keys(productAllList)?.length > 0 &&
        Object.keys(productAllList)?.forEach((list: any) => {
          let subListArr: any = [];
          let listName:any = "";
          if(list === "personelProductList"){

            listName = t("dnet.personal_lists.text");

            subListArr.push({
              value: "NPL",
              label: t("dnet.new_personal_list.text"),
              noOfProducts: "",
            });
          }else if(list === "productGroupList"){

            listName = t("dnet.product_lists.text");

            let trans=t("dnet.new_product_list.text");
           
            subListArr.push({
              value: "NPL-M",
              label:  trans.replace("{0}",currentDealer?.bgName),
              noOfProducts: "",
            });
          }else{
            listName = t("dnet.reseller_product_lists.txt");

            let trans=t("dnet.new_product_list.text");
           
            subListArr.push({
              value: "NPL-R",
              label:  trans.replace("{0}",productAllList?.[list]?.[0]?.sales_org),
              noOfProducts: "",
            });
          }
         
          productAllList?.[list]?.forEach((subList: any) => {
            subListArr.push({
              value: subList?.id,
              label: subList?.name,
              noOfProducts: subList?.product_group_members?.length,
            });
          });
          let listCategory = {
            category: listName,
            list: subListArr,
          };
          productListItemsTemp.push(listCategory);
        });
      setProductListItems(productListItemsTemp);
    }
  },[productList]);
  const [selectedProductList, setSelectedProductList] = useState("");

  const priceVisible = selectedCartProducts?.priceVisible; //CONTEXT values
  const isSpecialPriceEnabled = selectedCartProducts?.isSpecialPriceEnabled; //CONTEXT values

  const [selectAllItems, setSelectAllItems] = useState(false);

  const getAvailabilityData: any = (product: any, index: any) => {
    let dummyProduct = {
        product
    }
    let details = getAvailabilityDetails(dummyProduct);
    let colorCode: any = details?.colorCode;
    let titleText: any = t(details?.titleText);
    let availabilityMessage: any = t(details?.availabilityMessage);

    return (
      <Box>
        <Box>
          <CircleIcon
            className={styles.availabilityIcon}
            sx={{ color: colorCode[0] }}
            titleAccess={titleText}
          />
          <CircleIcon
            className={styles.availabilityIcon}
            sx={{ color: colorCode[1] }}
            titleAccess={titleText}
          />
          <CircleIcon
            className={styles.availabilityIcon}
            sx={{ color: colorCode[2] }}
            titleAccess={titleText}
          />
        </Box>
         <Box className={styles.availabilityDiv}>
          <span className={styles.messageStyle}>{availabilityMessage}</span>
         </Box> 
      </Box>
    );
  };

  const productByMaterialName = useSelector(
    (state: any) => state?.productReducer?.productsByMaterialName
  );

  const [productDetailsPageClick, setProductDetailsPageClick] = useState(false);

  const redirectToDetails = (product: any) => {
    dispatch(fetchProductDetailsData({ productNames: product?.index?.materialName}));
    setProductDetailsPageClick(true)
    dispatch(getProductsByMaterialName({materialName:[product?.productSku?.name]}))
  };

  useEffect(()=>{
    if (productByMaterialName?.length > 0 && productDetailsPageClick) {
      let productObj = Object.values(productByMaterialName[0]);
      dispatch(setCurrentProductSku(fetchWarrantyProducts(productObj)[0]));
      navigate(`/${ROUTES_LABEL.PRODUCT_DETAILS}`);
    }
  },[productByMaterialName])

  const updateQuantity = (index: any, e: any, item: any = "false") => {
    let prods = JSON.parse(JSON.stringify(prodContentData));
    let warrantyIndex: any = []
    if (index?.toString()?.includes("_")) {
      warrantyIndex = index?.split("_");
    }
    if (!isNaN(parseInt(e.target.value))) {
      if (parseInt(e.target.value) < 0) {
        warrantyIndex?.length > 0 && item !== "false" ?
          (prods.shoppingCart.lineItems[warrantyIndex[1]].warrantyLineItems[warrantyIndex[0]].subLineItems[item].quantity = "0") :
        warrantyIndex?.length > 0 && item === "false" ?
          (prods.shoppingCart.lineItems[warrantyIndex[1]].warrantyLineItems[warrantyIndex[0]].quantity = "0") :
        item !== "false"
          ? (prods.shoppingCart.lineItems[index].subLineItems[item].quantity = "0")//(prods[index].qtyWithDate[item.Id - 1].qty = 0)
          : (prods.shoppingCart.lineItems[index].quantity = "0");
      } else {
        warrantyIndex?.length > 0 && item !== "false" ?
          (prods.shoppingCart.lineItems[warrantyIndex[1]].warrantyLineItems[warrantyIndex[0]].subLineItems[item].quantity = `${parseInt(
            e?.target?.value
          )}`) :
        warrantyIndex?.length > 0 && item === "false" ?
          (prods.shoppingCart.lineItems[warrantyIndex[1]].warrantyLineItems[warrantyIndex[0]].quantity = `${parseInt(
            e?.target?.value
          )}`) :
        item !== "false"
          ? (prods.shoppingCart.lineItems[index].subLineItems[item].quantity = `${parseInt(
              e?.target?.value
            )}`)
          : (prods.shoppingCart.lineItems[index].quantity = `${parseInt(e?.target?.value)}`);
      }
    } else {
      warrantyIndex?.length > 0 && item !== "false" ?
          (prods.shoppingCart.lineItems[warrantyIndex[1]].warrantyLineItems[warrantyIndex[0]].subLineItems[item].quantity = "0") :
        warrantyIndex?.length > 0 && item === "false" ?
          (prods.shoppingCart.lineItems[warrantyIndex[1]].warrantyLineItems[warrantyIndex[0]].quantity = "0") :
      item !== "false"
        ? (prods.shoppingCart.lineItems[index].subLineItems[item].quantity = "0")
        : (prods.shoppingCart.lineItems[index].quantity = "0");
    }
    if(warrantyIndex?.length > 0 && item!=="false" ){
      let sublineTotal=0;
      prods.shoppingCart.lineItems[warrantyIndex[1]].warrantyLineItems[warrantyIndex[0]].subLineItems?.forEach((sublineItem:any)=>{
        let quan = parseInt(sublineItem?.quantity) || 0;
        sublineTotal+=quan;
      });
      prods.shoppingCart.lineItems[warrantyIndex[1]].warrantyLineItems[warrantyIndex[0]].quantity = sublineTotal;
    }else if(item!=="false"){ 
      let sublineTotal=0;
      prods.shoppingCart.lineItems[index].subLineItems?.forEach((sublineItem:any)=>{
        let quan = parseInt(sublineItem?.quantity) || 0;
        sublineTotal+=quan;
      });
      prods.shoppingCart.lineItems[index].quantity = sublineTotal;
    }
    setProdContentData(prods);
  };

  const handleQuantityChange = (index: any, type: any, item: any = "false") => {
    let prods = JSON.parse(JSON.stringify(prodContentData));
    let warrantyIndex: any = []
    if (index?.toString()?.includes("_")) {
      warrantyIndex = index?.split("_");
    }
    let actualQty = warrantyIndex?.length > 0 && item !== "false" ?
      prods.shoppingCart.lineItems[warrantyIndex[1]].warrantyLineItems[warrantyIndex[0]].subLineItems[item].quantity:
      warrantyIndex?.length > 0 && item==="false" ?
        prods.shoppingCart.lineItems[warrantyIndex[1]].warrantyLineItems[warrantyIndex[0]].quantity :
        item !== "false"
          ? prods.shoppingCart.lineItems[index].subLineItems[item].quantity
          : prods.shoppingCart.lineItems[index].quantity;

    let minQuantity: any = 0;
    let minDelQuantity: any = 0;
    let delUnit: any = 0;
    if (warrantyIndex?.length > 0) {
      minQuantity = prods?.shoppingCart?.lineItems[
          warrantyIndex[1]
        ]?.warrantyLineItems[warrantyIndex[0]]?.detail?.minOrderQuantity;
      minDelQuantity = prods?.shoppingCart?.lineItems[
          warrantyIndex[1]
        ]?.warrantyLineItems[warrantyIndex[0]]?.detail?.minDeliveryQuantity;
      delUnit = prods.shoppingCart.lineItems[
          warrantyIndex[1]
        ].warrantyLineItems[warrantyIndex[0]]?.detail?.deliveryUnit;

      minQuantity = parseInt(minQuantity) || 1 ;
      minDelQuantity= parseInt(minDelQuantity) || 1;
      delUnit = parseInt(delUnit) || 1;

      minQuantity = minQuantity === 0 ? 1 : minQuantity;
      minDelQuantity = minDelQuantity === 0 ? 1 : minDelQuantity;
      delUnit = delUnit === 0 ? 1 : delUnit;
    } else {
      minQuantity = prods.shoppingCart.lineItems[index]?.detail?.minOrderQuantity;
      minDelQuantity = prods.shoppingCart.lineItems[index]?.detail?.minDeliveryQuantity;
      delUnit = prods.shoppingCart.lineItems[index]?.detail?.deliveryUnit;

      minQuantity = parseInt(minQuantity) || 1 ;
      minDelQuantity= parseInt(minDelQuantity) || 1;
      delUnit = parseInt(delUnit) || 1;

      minQuantity = minQuantity === 0 ? 1 : minQuantity;
      minDelQuantity = minDelQuantity === 0 ? 1 : minDelQuantity;
      delUnit = delUnit === 0 ? 1 : delUnit;
    }
    
    if (type === "increment") {
      if (actualQty === "") {
        warrantyIndex?.length > 0 && item !== "false" ?
          (prods.shoppingCart.lineItems[
              warrantyIndex[1]
            ].warrantyLineItems[warrantyIndex[0]].subLineItems[item].quantity = 
            plus2(0,minQuantity,delUnit,minDelQuantity)) :
        warrantyIndex?.length > 0 && item === "false" ?
          (prods.shoppingCart.lineItems[
              warrantyIndex[1]
            ].warrantyLineItems[warrantyIndex[0]].quantity = 
            plus2(0,minQuantity,delUnit,minDelQuantity)) :
        item !== "false"
          ? (prods.shoppingCart.lineItems[index].subLineItems[item].quantity = 
              plus2(0,minQuantity,delUnit,minDelQuantity))
          : (prods.shoppingCart.lineItems[index].quantity = 
              plus2(0,minQuantity,delUnit,minDelQuantity));
      } else {
        let tempQty =
          `${parseInt(
            warrantyIndex?.length > 0 && item !== "false" ?
              prods.shoppingCart.lineItems[
                  warrantyIndex[1]
                ].warrantyLineItems[warrantyIndex[0]].subLineItems[item].quantity :
            warrantyIndex?.length > 0 && item === "false" ?
              prods.shoppingCart.lineItems[
                  warrantyIndex[1]
                ].warrantyLineItems[warrantyIndex[0]].quantity :
            item !== "false" ? prods.shoppingCart.lineItems[index].subLineItems[item].quantity : 
            prods.shoppingCart.lineItems[index].quantity
          )}`;
        warrantyIndex?.length > 0 && item !== "false" ?
          (prods.shoppingCart.lineItems[
              warrantyIndex[1]
            ].warrantyLineItems[warrantyIndex[0]].subLineItems[item].quantity = 
            plus2(parseInt(tempQty),minQuantity,delUnit,minDelQuantity)) :
        warrantyIndex?.length > 0 && item === "false" ?
          (prods.shoppingCart.lineItems[
              warrantyIndex[1]
            ].warrantyLineItems[warrantyIndex[0]].quantity = 
            plus2(parseInt(tempQty),minQuantity,delUnit,minDelQuantity)) :
        item !== "false"
          ? (prods.shoppingCart.lineItems[index].subLineItems[item].quantity = 
            plus2(parseInt(tempQty),minQuantity,delUnit,minDelQuantity))
          : (prods.shoppingCart.lineItems[index].quantity = 
            plus2(parseInt(tempQty),minQuantity,delUnit,minDelQuantity));
      }
    } else {
      if (actualQty === "" || actualQty === "0" || actualQty === 0) {
        warrantyIndex?.length > 0 && item !== "false" ?
          (prods.shoppingCart.lineItems[
              warrantyIndex[1]
            ].warrantyLineItems[warrantyIndex[0]].subLineItems[item].quantity = 
            minus2(0,minQuantity,delUnit,minDelQuantity)) :
        warrantyIndex?.length > 0 && item === "false" ?
          (prods.shoppingCart.lineItems[
              warrantyIndex[1]
            ].warrantyLineItems[warrantyIndex[0]].quantity = 
            minus2(0,minQuantity,delUnit,minDelQuantity)) :
        item !== "false"
          ? (prods.shoppingCart.lineItems[index].subLineItems[item].quantity = 
            minus2(0,minQuantity,delUnit,minDelQuantity))
          : (prods.shoppingCart.lineItems[index].quantity = 
            minus2(0,minQuantity,delUnit,minDelQuantity));
      } else {
        let tempQty =
          `${parseInt(
            warrantyIndex?.length > 0 && item !== "false" ?
            prods.shoppingCart.lineItems[
                warrantyIndex[1]
              ].warrantyLineItems[warrantyIndex[0]].subLineItems[item].quantity :
            warrantyIndex?.length > 0 && item === "false" ?
            prods.shoppingCart.lineItems[
                warrantyIndex[1]
              ].warrantyLineItems[warrantyIndex[0]].quantity :
            item !== "false" ? prods.shoppingCart.lineItems[index].subLineItems[item].quantity : 
            prods.shoppingCart.lineItems[index].quantity
          )}`;
        warrantyIndex?.length > 0 && item !== "false" ?
          (prods.shoppingCart.lineItems[
              warrantyIndex[1]
            ].warrantyLineItems[warrantyIndex[0]].subLineItems[item].quantity = 
            minus2(parseInt(tempQty),minQuantity,delUnit,minDelQuantity)) :
        warrantyIndex?.length > 0 && item === "false" ?
          (prods.shoppingCart.lineItems[
              warrantyIndex[1]
            ].warrantyLineItems[warrantyIndex[0]].quantity = 
            minus2(parseInt(tempQty),minQuantity,delUnit,minDelQuantity)) :
        item !== "false"
          ? (prods.shoppingCart.lineItems[index].subLineItems[item].quantity = 
            minus2(parseInt(tempQty),minQuantity,delUnit,minDelQuantity))
          : (prods.shoppingCart.lineItems[index].quantity = 
            minus2(parseInt(tempQty),minQuantity,delUnit,minDelQuantity));
      }
    }
    if(warrantyIndex?.length > 0 && item!=="false" ){
      let sublineTotal=0;
      prods.shoppingCart.lineItems[warrantyIndex[1]].warrantyLineItems[warrantyIndex[0]].subLineItems?.forEach((sublineItem:any)=>{
        let quan = parseInt(sublineItem?.quantity) || 0;
        sublineTotal+=quan;
      });
      prods.shoppingCart.lineItems[warrantyIndex[1]].warrantyLineItems[warrantyIndex[0]].quantity = sublineTotal;
    }else if(item!=="false"){
      let sublineTotal=0;
      prods.shoppingCart.lineItems[index].subLineItems?.forEach((sublineItem:any)=>{
        let quan = parseInt(sublineItem?.quantity) || 0;
        sublineTotal+=quan;
      });
      prods.shoppingCart.lineItems[index].quantity = sublineTotal;
    }
    setProdContentData(prods);
  };

  const quantityColumn = (index: any, product: any, item: any = "false") => {
    let warrantIndex: any = [];
    if (index?.toString()?.includes("_")) {
      warrantIndex = index?.toString()?.split("_");
    }
    return (
      <Box>
        <Box className={!orderEntryMultiship ? styles.qtyButtonContaainer : styles.multipleQtyContainer}>
          <CustomTextField
            additionalSxProperties={!orderEntryMultiship ? {
              width: "50px",
              height: "15px",
            } : {}}
            label={""}
            value={
              orderEntryMultiship ? 
              // (warrantIndex?.length > 0 ? product?.warrantyLineItems?.[parseInt(warrantIndex[0])]?.quantityTotal : 
              product?.quantityTotal :
              (item !== "false" ? 
                // (warrantIndex?.length > 0 ? product?.warrantyLineItems?.[parseInt(warrantIndex[0])]?.subLineItems[item]?.quantity :
                product?.subLineItems[item]?.quantity : 
                // (warrantIndex?.length > 0 ? product?.warrantyLineItems?.[parseInt(warrantIndex[0])]?.quantity :
                product?.quantity)
            }
            disabled={orderEntryMultiship}
            changeHandler={(e: any) => {
              e.target.value = e.target.value.toString().slice(0, 6);
              updateQuantity(index, e, item);
            }}
          />
          {!orderEntryMultiship && 
            <Box className={styles.qtyButtonContaainer}>
              <IconButton
                onClick={() => handleQuantityChange(index, "decrement", item)}
                title={t("dnet.decrement_info.text")?.toString()}
              >
                <RemoveCircleOutlineOutlinedIcon />
              </IconButton>
              <IconButton
                onClick={() => handleQuantityChange(index, "increment", item)}
                title={t("dnet.increment_info.text")?.toString()}
              >
                <AddCircleOutlineOutlinedIcon />
              </IconButton>
            </Box>
          }
        </Box>
        {/* <Box className={styles.qtyText}>
      <span className={styles.messageStyle}>
        {product?.quantityMessageTarget}
      </span>
    </Box> */}
      </Box>
    );
  };

  let csvImageAltTag = t("dnet.save_as_csv.text").split('"');
  let csvImageAlt = csvImageAltTag[csvImageAltTag?.length - 2];
  let uploadImageAltTag = t("dnet.load_file.text").split('"');
  let uploadImageAlt = uploadImageAltTag[uploadImageAltTag?.length - 2];
  let xmlImageAltTag = t("dnet.save_as_XML").split('"');
  let xmlImageAlt = xmlImageAltTag[xmlImageAltTag?.length - 2];

  const isProductDetailsVisible = (indexObj: any) => {
    if (indexObj?.index?.uri) {
      return true;
    } else {
      return false;
    }
  };

  const handleMaterialNameChange = (index: any, e: any) => {
    let prods = JSON.parse(JSON.stringify(prodContentData));
    prods.shoppingCart.lineItems[index].productSku.name = e.target.value;
    setProdContentData(prods);
  };

  const [updateButtonDisabled, setUpdateButtonDisabled] = useState(true)

  const handleOnBlurMaterialNameChange = (index: any) => {
    if (prodContentData?.shoppingCart?.lineItems[index]?.productSku?.name !== "") {
      setUpdateButtonDisabled(false)
    } else {
      let checkAnyProductName: any = []
      checkAnyProductName = prodContentData?.shoppingCart?.lineItems?.filter((elem: any) => {
        return elem?.productSku?.name !== ""
      })
      if (checkAnyProductName?.length > 0) {
        setUpdateButtonDisabled(false)
      } else {
        setUpdateButtonDisabled(true)
      }
    }
  }

  const globalPayloadStructure = () => {
    let prods = JSON.parse(JSON.stringify(prodContentData));
    let lineItem: any = [];
    prodContentData?.shoppingCart?.lineItems?.forEach((item: any) => 
      item?.productSku?.name !== "" && lineItem?.push(item)
    )
    prods.shoppingCart.lineItems = lineItem
    return prods;
  };

  const updateCart = () => {
    let payloadProds = globalPayloadStructure()
    updateButtonClickHandler(payloadProds)
  };

  const handleQuoteHandler = () => {
    let payloadProds = globalPayloadStructure()
    updateButtonClickHandler(payloadProds, true)
    // handleQuotationButton(payloadProds)
  }

  const [productSearchButtonClicked, setProductSearchButtonClicked] = useState(false);

  const selectedProductSearch = (product: any) => {
    setProductSearchButtonClicked(true);
    dispatch(
      fetchGlobalSearchSuggestions({
        searchString: product?.productSku?.name,
      })
    );
  };

  const globalSearchSuggestions = useSelector(
    (state: any) => state?.commonReducer?.globalSearchSuggestions
  );

  useEffect(()=>{
    if (!_.isEmpty(globalSearchSuggestions) && productSearchButtonClicked) {
      let productArr:any= globalSearchSuggestions.map((product:any)=>product?.item);
      dispatch(
        getProductsByMaterialName({
          materialName: productArr,
        })
      );  
      dispatch(fetchGlobalSearchSuggestionsSuccess([]))
      navigate(`/${ROUTES_LABEL.GLOBAL_SEARCH}`);   
    }
  },[globalSearchSuggestions])

  const updateSpecialPrice = (e: any, index: any) => {
    let specialPriceString = e.target.value;
    let pattern = /^[0-9,.]+$/;
    let prods = JSON.parse(JSON.stringify(prodContentData));
    let warrantyIndex: any = [];
    if (index?.toString()?.includes("_")) {
      warrantyIndex = index?.toString()?.split("_");
    }
    if (pattern.test(specialPriceString)) {
      let dotString = specialPriceString.replace(/,/g, ".");
      let indexOfDot = dotString.indexOf(".");
      if (indexOfDot > 0 && indexOfDot < dotString?.length - 1) {
        let firsthalfString = dotString.slice(0, indexOfDot + 1);
        let splitDotString = dotString
          .slice(indexOfDot + 1, dotString?.length)
          .split(".")
          .join("");
        if (warrantyIndex?.length > 0) {
          prods.shoppingCart.lineItems[warrantyIndex[1]].warrantyLineItems[warrantyIndex[0]].specialPrice = firsthalfString + splitDotString;
        } else
          prods.shoppingCart.lineItems[index].specialPrice = firsthalfString + splitDotString;
      } else {
        if (warrantyIndex?.length > 0) {
          prods.shoppingCart.lineItems[warrantyIndex[1]].warrantyLineItems[warrantyIndex[0]].specialPrice = dotString;
        } else
          prods.shoppingCart.lineItems[index].specialPrice = dotString;
      }
    } else if (specialPriceString === "") {
      if (warrantyIndex?.length > 0) {
        prods.shoppingCart.lineItems[warrantyIndex[1]].warrantyLineItems[warrantyIndex[0]].specialPrice = "";
      } else
        prods.shoppingCart.lineItems[index].specialPrice = "";
    }
    setProdContentData(prods);
  };

  const handleDownloadXml = () => {
    logger.info("Download Sony Order Report XML");
    let requiredJsonFormat: any = {
      Orders: {
        Order: {
          Header: {
            PONumber: " ",
            RequestedDeliveryDate: moment(
              orderEntryObj?.requestedDeliveryDate
            ).format("DD/MM/YYYY"),
          },
          Partners: {
            Partner: [] 
          },
          Items: {
            Item: []
          }
        }
      }
    };
    if(orderEntryMultiship) {
      let itemsArr: any = []
      prodContentData?.shoppingCart?.lineItems?.forEach((lineItem: any) => {
        lineItem?.productSku?.name !== "" && itemsArr?.push({
          Material: lineItem?.productSku?.name,
          OrderQuantity: lineItem?.quantityTotal,
          ShipTos: {
            ShipTo: []
          },
        })
        lineItem?.productSku?.name !== "" &&
        lineItem?.multiLineItems?.forEach((subMultiShip: any) => {
          let shipTo: any = [...itemsArr[itemsArr.length-1]?.ShipTos?.ShipTo]
          subMultiShip?.quantity !== "" &&
          shipTo.push({"@":{
            ID: subMultiShip?.ShiptoAccNo,
            Qty: subMultiShip?.quantity, 
           }})
          itemsArr[itemsArr.length-1] = {
            ...itemsArr[itemsArr.length-1],
            ShipTos: {
              ShipTo: shipTo
            },
          }
        })
        lineItem?.warrantyLineItems?.length > 0 && lineItem?.isWarranty && 
        lineItem?.warrantyLineItems?.forEach((subLineItem: any) => {
          itemsArr?.push({
            Material: subLineItem?.productSku?.name,
            OrderQuantity: subLineItem?.quantityTotal,
            ShipTos: {
              ShipTo: []
            },
          })
          subLineItem?.multiLineItems?.forEach((subMultiShip: any) => {
            let shipTo: any = [...itemsArr[itemsArr.length-1]?.ShipTos?.ShipTo]
            subMultiShip?.quantity !== "" && 
            shipTo.push({"@":{
              ID: subMultiShip?.ShiptoAccNo,
              Qty: subMultiShip?.quantity, 
             }})
            itemsArr[itemsArr.length-1] = {
              ...itemsArr[itemsArr.length-1],
              ShipTos: {
                ShipTo: shipTo
              },
            }
          })
        })
      })
      itemsArr?.length === 0 && itemsArr?.push({
        Material: " ",
        OrderQuantity: " ",
        ShipTos: {
          ShipTo: [
            {"@":{
              ID: "",
              Qty: "", 
            }},
            {"@":{
              ID: "",
              Qty: "", 
            }}
          ]
        },
      })
      requiredJsonFormat.Orders.Order.Items.Item = itemsArr;
      prodContentData?.shipToAccountId?.forEach((shipToAcc: any) => {
        requiredJsonFormat?.Orders?.Order?.Partners?.Partner?.push({ "@": {
            "ID": shipToAcc?.accountNumber,
            "Type":"ShipTo"
          }
        })
      })
      requiredJsonFormat?.Orders?.Order?.Partners?.Partner?.push({ "@": {
          "ID":currentDealer?.accNum,
          "Type":"Payer"
        }
      })
      requiredJsonFormat?.Orders?.Order?.Partners?.Partner?.push({ "@": {
          "ID":currentDealer?.accNum,
          "Type":"InvoiceTo"
        }
      })
      requiredJsonFormat?.Orders?.Order?.Partners?.Partner?.push({ "@": {
          "ID":currentDealer?.accNum,
          "Type":"SoldTo"
        }
      })
    } else if(isMultiRequestedDeliveryDate) {
      let itemsArr: any = []
      prodContentData?.shoppingCart?.lineItems?.forEach((lineItem: any) => {
        lineItem?.subLineItems?.forEach((subMultiDate: any) => {
          lineItem?.productSku?.name !== "" && subMultiDate?.quantity !== "" 
          && itemsArr?.push({
            Material: lineItem?.productSku?.name,
            OrderQuantity: subMultiDate?.quantity,
            OrderRequestedDeliveryDate: moment(
              subMultiDate?.requestedDeliveryDate
            ).format("DD/MM/YYYY"),
          })
        })
        lineItem?.warrantyLineItems?.length > 0 && lineItem?.isWarranty && 
        lineItem?.warrantyLineItems?.forEach((subLineItem: any) => {
          subLineItem?.subLineItems?.forEach((subMultiDate: any) => {
              subMultiDate?.quantity !== "" && 
              itemsArr?.push({
                Material: subLineItem?.productSku?.name,
                OrderQuantity: subMultiDate?.quantity,
                OrderRequestedDeliveryDate: moment(
                  subMultiDate?.requestedDeliveryDate
                ).format("DD/MM/YYYY"),
              })
          })
        })
      })
      itemsArr?.length === 0 && itemsArr?.push({
        Material: " ",
        OrderQuantity: " ",
        OrderRequestedDeliveryDate: " "
      })
      requiredJsonFormat.Orders.Order.Items.Item = itemsArr;
      requiredJsonFormat?.Orders?.Order?.Partners?.Partner?.push({ "@": {
          "ID":orderEntryObj?.shipToAccounts === "0" || orderEntryObj?.shipToAccounts === ""? 
            "0" :
            orderEntryObj?.shipToAccounts,
          "Type":"ShipTo"
        }
      })
      requiredJsonFormat?.Orders?.Order?.Partners?.Partner?.push({ "@": {
          "ID":currentDealer?.accNum,
          "Type":"Payer"
        }
      })
      requiredJsonFormat?.Orders?.Order?.Partners?.Partner?.push({ "@": {
          "ID":currentDealer?.accNum,
          "Type":"InvoiceTo"
        }
      })
      requiredJsonFormat?.Orders?.Order?.Partners?.Partner?.push({ "@": {
          "ID":currentDealer?.accNum,
          "Type":"SoldTo"
        }
      })
    } else {
      let itemsArr: any = []
      prodContentData?.shoppingCart?.lineItems?.forEach((lineItem: any) => {
        lineItem?.productSku?.name !== "" && itemsArr?.push({
          Material: lineItem?.productSku?.name,
          OrderQuantity: lineItem?.quantity
        })
        lineItem?.warrantyLineItems?.length > 0 && lineItem?.isWarranty && 
        lineItem?.warrantyLineItems?.forEach((subLineItem: any) => {
          itemsArr?.push({
            Material: subLineItem?.productSku?.name,
            OrderQuantity: subLineItem?.quantity === "" ? 0 : subLineItem?.quantity
          })
        })
      })
      itemsArr?.length === 0 && itemsArr?.push({
        Material: " ",
        OrderQuantity: " "
      })
      requiredJsonFormat.Orders.Order.Items.Item = itemsArr;
      requiredJsonFormat?.Orders?.Order?.Partners?.Partner?.push({ "@": {
          "ID":orderEntryObj?.shipToAccounts === "0" || orderEntryObj?.shipToAccounts === ""? 
            "0" :
            orderEntryObj?.shipToAccounts,
          "Type":"ShipTo"
        }
      })
      requiredJsonFormat?.Orders?.Order?.Partners?.Partner?.push({ "@": {
          "ID":currentDealer?.accNum,
          "Type":"Payer"
        }
      })
      requiredJsonFormat?.Orders?.Order?.Partners?.Partner?.push({ "@": {
          "ID":currentDealer?.accNum,
          "Type":"InvoiceTo"
        }
      })
      requiredJsonFormat?.Orders?.Order?.Partners?.Partner?.push({ "@": {
          "ID":currentDealer?.accNum,
          "Type":"SoldTo"
        }
      })
    }
    const xmlData = js2xmlparser.parse("Xml4Sap", requiredJsonFormat)
    const blob = new Blob([xmlData], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'SONYORDER.xml';
    a.click();
    URL.revokeObjectURL(url);
  }
  const [csvData, setCsvData] = useState([]);
  const [readyToDownload, setReadyToDownload] = useState(false);

  const downloadCSV = (tableData: any) => {
    // CSV format creation for downloading
    logger.info("Download Sony Order Report CSV");
    const csvData: any = []
    tableData?.shoppingCart?.lineItems?.forEach((item: any) => {
      if (item?.productSku?.name !== "" && isMultiRequestedDeliveryDate) { 
        item?.subLineItems?.forEach((subLineItem: any) => {
          if (subLineItem?.quantity !== "" && subLineItem?.quantity !== 0) {
            csvData?.push([
              item?.productSku?.name,
              subLineItem?.quantity,
              moment(subLineItem?.requestedDeliveryDate).format('DD/MM/YYYY'),
            ])
          }
        })
        item?.isWarranty && item?.warrantyLineItems?.length > 0 &&
          item?.warrantyLineItems?.forEach((subItem: any) => {
            subItem?.subLineItems?.forEach((subLineItem: any) => {
              if (subLineItem?.quantity !== "" && subLineItem?.quantity !== 0) {
                csvData?.push([
                  subItem?.productSku?.name,
                  subLineItem?.quantity,
                  moment(subLineItem?.requestedDeliveryDate).format('DD/MM/YYYY')
                ])
              }
            })
        })
      } else if (item?.productSku?.name !== "") { 
        let finalDate: any = "";
        if (tableData?.requestedDeliveryDate) {
          let rdDate = tableData?.requestedDeliveryDate?.split('/');
          finalDate = rdDate?.reverse()?.join("/");
        }
        csvData?.push([
          item?.productSku?.name,
          item?.quantity,
          tableData?.requestedDeliveryDate && moment(finalDate).format('DD/MM/YYYY'),
        ])
        item?.isWarranty && item?.warrantyLineItems?.length > 0 &&
          item?.warrantyLineItems?.map((subItem: any) => csvData?.push([
            subItem?.productSku?.name,
            subItem?.quantity,
            tableData?.requestedDeliveryDate && moment(finalDate).format('DD/MM/YYYY'),
          ]))
      }
    }
    )
    setCsvData(csvData);
    setReadyToDownload(true);
  };

  let csvLink: any = React.createRef();
  useEffect(() => {
    if (!_.isEmpty(csvData) && readyToDownload) {
      csvLink.current.link.click();
      setReadyToDownload(false);
    }
  }, [readyToDownload]);

  // const handleCustomerMaterialNumberChange = (index: any, e: any) => {
  //   let prods = JSON.parse(JSON.stringify(prodContentData));
  //   prods.shoppingCart.lineItems[index].customerMaterialNumber = e.target.value;
  //   setProdContentData(prods);
  // }
  const checkHandler = (value: any, index: any) => {
    let prods = JSON.parse(JSON.stringify(prodContentData));
    prods.shoppingCart.lineItems[index].checked = value;
    setProdContentData(prods);
  }
  const saveProductListHandler = () => {
    let prods = JSON.parse(JSON.stringify(prodContentData));
    let newSelectedProdList: any = [];
    prods?.shoppingCart?.lineItems?.forEach((prod: any) => {
      if (prod?.checked === true && prod?.productSku?.name !== "") {
        prod?.isWarranty &&
          prod?.warrantyLineItems.length > 0 &&
          prod?.warrantyLineItems?.map((warrantyItem: any) => {
            if (
              warrantyItem?.productSku?.name !== "" &&
              warrantyItem?.quantity !== "0" &&
              warrantyItem?.quantity !== "" &&
              warrantyItem?.quantity !== null &&
              warrantyItem?.quantity !== 0 && warrantyItem?.quantity !== undefined
            ) {
              let selectedWarrantyProdObj = {
                materialName: warrantyItem?.productSku?.name,
                quantity: warrantyItem?.quantity,
              };
              newSelectedProdList?.push(selectedWarrantyProdObj);
            }
          });
        let selectedProdObj = {
          materialName: prod?.productSku?.name,
          quantity: prod?.quantity
        }
        newSelectedProdList?.push(selectedProdObj)
      }
    })
    if (newSelectedProdList?.length > 0) {
      // let prodListItems: any = JSON.parse(JSON.stringify(prodContentData));
      // prodListItems.shoppingCart.lineItems = [];
      // prodListItems.shoppingCart.lineItems = newLineItems;
      let selProdList: any = [];
      productListItems?.forEach((list: any) => {
        list?.list?.forEach((subList: any)=> {
          if (subList?.value === selectedProductList) {
            selProdList?.push(subList)
          }
        })
      })
      navigate(`/${ROUTES_LABEL?.PRODUCT_LISTS}`, {
        state: {
          selectedProdList: newSelectedProdList,
          selectedProductListId: selectedProductList,
          selectedProductListName: selProdList[0]?.label,
        }
      })
    }
  }
  
  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );
  const [shoppingContextToken, setShoppingContextToken] = useState(
    `${currentDealer?.accNum}_${currentDealer?.bgName}_${currentDealer?.loggedInUser?.username}_${currentDealer?.loggedInUser?.profile?.email}`
  );
  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.loginResponse?.data?.userDetails
  );

  const deleteHandler = () => {
    let prods = JSON.parse(JSON.stringify(prodContentData));
    let newLineItems: any = [];
    prods?.shoppingCart?.lineItems?.forEach((prod: any) => 
      prod?.checked === true && newLineItems?.push(prod?.productSku?.name)
    )
    if (newLineItems?.length > 0) {
      dispatch(deleteSelectedItemCart({
        shoppingContextToken,
        product: newLineItems,
        usernameOrEmail: userDetails?.username,
      }))
    }
  }

  const masterCheckHandler = (value: any) => {
    let prods = JSON.parse(JSON.stringify(prodContentData));
    for(let i=0; i<prods.shoppingCart.lineItems?.length; i++) {
      prods.shoppingCart.lineItems[i].checked = value;
    }
    setProdContentData(prods);
  }
  const [selectedPrimeSupport, setSelectedPrimeSupport] = useState<any>([]);
  const toggleWarrantyLines = (index: any) => {
    if (selectedPrimeSupport.indexOf(index) == -1) {
      setSelectedPrimeSupport([...selectedPrimeSupport, index]);
    } else {
      setSelectedPrimeSupport(
        selectedPrimeSupport.filter((idx: any) => idx !== index)
      );
    }
  };

  const tableRowStructure = (product: any, index: any) => {
    return (
      <TableRow
        tabIndex={-1}
        key={index}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell align="center">
          {!index?.toString()?.includes("_") ? (
            <Checkbox
              sx={{ paddingLeft: 0 }}
              checked={product?.checked}
              onChange={(e: any) => {
                checkHandler(e.target.checked, index);
              }}
            />
          ) : (
            <SubdirectoryArrowRightIcon />
          )}
        </TableCell>
        <TableCell>
          <Box className={styles.modelColumn}>
            <span
              className={styles.modelColumn}
              style={{ alignItems: "flex-end" }}
            >
              <span>
                {index?.toString()?.includes("_") ? (
                  product?.productSku?.name
                ) : (
                  <CustomTextField
                    additionalSxProperties={{
                      width: "150px",
                    }}
                    label={""}
                    value={product?.productSku?.name}
                    changeHandler={(e: any) =>
                      handleMaterialNameChange(index, e)
                    }
                    onBlurHandler={() => 
                      handleOnBlurMaterialNameChange(index)
                    }
                  />
                )}
              </span>
              <span>
                {isProductDetailsVisible(product) && (
                  <CustomButton
                    label={<InfoOutlinedIcon />}
                    color="primary"
                    additionalSxProperties={{
                      width: "max-content",
                    }}
                    onClick={() => {
                      redirectToDetails(product);
                    }}
                  />
                )}
              </span>
              <span>
                {product?.isWarranty &&
                  product?.warrantyLineItems?.length > 0 && (
                    <CustomButton
                      label={<AddCircleOutlineOutlinedIcon />}
                      additionalSxProperties={{
                        width: "max-content",
                      }}
                      onClick={() => {
                        toggleWarrantyLines(index);
                      }}
                    />
                  )}
              </span>
            </span>
          </Box>
        </TableCell>
        <TableCell align="center">
          {!index?.toString()?.includes("_") && (
            <IconButton
              title={t("dnet.find.text")?.toString()}
              onClick={() => selectedProductSearch(product)}
            >
              <SearchIcon />
            </IconButton>
          )}
        </TableCell>
        {selectedCartProducts?.countryOfOriginEnabled && (
          <TableCell>{product?.availabilityMap?.NOFG?.countryOfOrigin}</TableCell>
        )}
        {/* {selectedCartProducts?.customerMaterialNumberEnabled && (
          <TableCell>
            <CustomTextField
              additionalSxProperties={{
                width: "150px",
              }}
              label={""}
              value={product?.customerMaterialNumber}
              changeHandler={(e: any) =>
                handleCustomerMaterialNumberChange(index, e)
              }
            />
          </TableCell>
        )} */}
        <TableCell align="center" style={{minWidth:"150px"}}>
          {
            orderEntryMultiship
              ? quantityColumn(index, product) //if multiShipAddress then show number of quantity
              : (isMultiRequestedDeliveryDate
              ? (//prodContentData?.shoppingCart?.isMultiRequestedDeliveryDate &&
                product?.rddExpand
                ? product?.subLineItems?.map((item: any, subItemid: any) => {
                    return quantityColumn(index, product, subItemid);
                  })
                : quantityColumn(index, product, 0))
              : quantityColumn(index, product))
          }
        </TableCell>
        {
          // CONTEXT?.dealership?.shoppingCart
          //   ?.isMultiRequestedDeliveryDate
          isMultiRequestedDeliveryDate &&
            // prodContentData?.shoppingCart?.isMultiRequestedDeliveryDate && 
            (
              <TableCell>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  {
                    // (product?.subLineItems?.length === 1 || product?.subLineItems?.length === 0) &&
                    !product?.rddExpand ? (
                      <AddBoxOutlinedIcon
                        onClick={() => {
                          expandDeliveryDateHandler(index);
                        }}
                        sx={{ cursor: "pointer" }}
                      />
                    ) : (
                      <IndeterminateCheckBoxOutlinedIcon
                        onClick={() => {
                          minimizeDeliveryDateHandler(index);
                        }}
                        sx={{ cursor: "pointer" }}
                      />
                    )
                  }
                  <div>
                    {product?.rddExpand ? (
                      product?.subLineItems?.map(
                        (rddItem: any, rddItemId: any) => {
                          return (
                            <div
                              style={{
                                marginBottom: "5px",
                                marginLeft: "10px",
                              }}
                            >
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                              >
                                <DatePicker
                                  //   label={t("dnet.start_with.text")}
                                  openTo="day"
                                  views={["year", "month", "day"]}
                                  value={rddItem?.requestedDeliveryDate}
                                  onChange={(newValue: any) => {
                                    handleMultiDeliveryDate(
                                      rddItemId, //rddItem?.Id,
                                      newValue,
                                      index
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField 
                                      {...params}
                                      inputProps={{...params.inputProps, readOnly: true}}
                                      size="small"
                                    />
                                  )}
                                  inputFormat="DD/MM/YYYY"
                                  minDate={startingMinDate}
                                  shouldDisableDate={shouldDisableDate}
                                  //   disabled={formEditable}
                                />
                              </LocalizationProvider>
                            </div>
                          );
                        }
                      )
                    ) : (
                      <div
                        style={{
                          marginBottom: "5px",
                          marginLeft: "10px",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            //   label={t("dnet.start_with.text")}
                            openTo="day"
                            views={["year", "month", "day"]}
                            value={
                              product?.subLineItems[0]?.requestedDeliveryDate
                            }
                            onChange={(newValue: any) => {
                              handleMultiDeliveryDate(
                                "0", //rddItem?.Id,
                                newValue,
                                index
                              );
                            }}
                            renderInput={(params) => (
                              <TextField 
                                {...params}
                                inputProps={{...params.inputProps, readOnly: true}}
                                size="small"
                              />
                            )}
                            inputFormat="DD/MM/YYYY"
                            minDate={startingMinDate}
                            shouldDisableDate={shouldDisableDate}
                            //   disabled={formEditable}
                          />
                        </LocalizationProvider>
                      </div>
                    )}
                  </div>
                </div>
              </TableCell>
            )
        }
        <TableCell>
          {_.isEmpty(product?.availabilityMap)
            ? null
            : getAvailabilityData(product, index)}
        </TableCell>

        {priceVisible && (
          <TableCell sx={{ minWidth: "100px" }}>
            {!showPriceList ? null : (
              <Box>
                <Box>
                  {product?.price}
                </Box>
              </Box>
            )}
          </TableCell>
        )}
        <TableCell sx={{ minWidth: "250px" }}>
          {
            isMultiRequestedDeliveryDate &&
            product?.texts?.length > 0 ? 
            product?.texts?.map((desc: any) => {
              return !desc?.error ? 
                <span>{desc?.description}</span> :
                <Typography variant="body2" color="error">
                  {desc?.description}
                </Typography>
            }): product?.texts?.length > 0 ? 
                (!product?.texts[0]?.error ? 
                  <span>{product?.texts[0]?.description}</span> :
                  <Typography variant="body2" color="error">
                    {product?.texts[0]?.description}
                  </Typography>
                ) : 
                (
                  // <span>{t("dnet.empty_description.txt")}</span>
                  <span></span>
                )
          }
        </TableCell>
        {isSpecialPriceEnabled && (
          <TableCell>
            <CustomTextField
              label={""}
              value={product?.specialPrice}
              changeHandler={(e: any) => updateSpecialPrice(e, index)}
            />
          </TableCell>
        )}
      </TableRow>
    );
  };
  return (
    <Box className={styles.tableBox}>
      <div className={styles.tableHeader}>
        <CustomButton
          id="update"
          label={t("dnet.update.text")}
          onClick={() => updateCart()}
          color="info.main"
          margin="0"
          disabled={updateButtonDisabled}
        />
        <CustomButton
          id="getQuote"
          label={t("dnet.get_quote.text")}
          onClick={() => handleQuoteHandler()}
          disabled={updateButtonDisabled}
          color="warning.main"
          margin="0"
        />
      </div>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeaderData.map((headCell: any, index: any) => (
                <React.Fragment key={index}>
                  {headCell?.id === "orderSearch" ? (
                    <TableCell align="center" key={headCell.id}>
                      {t("dnet.find.text")}
                    </TableCell>
                  ) : headCell?.id === "select" ? (
                    <TableCell align="center" key={headCell.id}>
                      <Checkbox
                        checked={selectAllItems}
                        sx={{ paddingLeft: 0 }}
                        onChange={(e: any) => {
                          setSelectAllItems(e.target.checked)
                          masterCheckHandler(e.target.checked)
                        }}
                      />
                    </TableCell>
                  ) : headCell?.id === "countryoforigin" &&
                    selectedCartProducts?.countryOfOriginEnabled ? (
                    <TableCell align="center" key={headCell.id}>
                      {headCell.label}
                    </TableCell>
                  ) : headCell?.id === "customerMaterialNumber" &&
                    selectedCartProducts?.customerMaterialNumberEnabled ? (
                    <TableCell align="center" key={headCell.id}>
                      {headCell.label}
                    </TableCell>
                  ) : headCell?.id === "requsestedDate" &&
                    isMultiRequestedDeliveryDate ? (
                    <TableCell
                      align="center"
                      key={headCell.id}
                      style={{ minWidth: "250px" }}
                    >
                      {headCell.label}
                    </TableCell>
                  ) : headCell?.id === "specialPrice" &&
                    isSpecialPriceEnabled ? (
                    <TableCell
                      align="center"
                      key={headCell.id}
                      style={{ minWidth: "200px" }}
                    >
                      {headCell.label}
                    </TableCell>
                  ) : headCell?.id === "model" ||
                    headCell?.id === "qty" ||
                    headCell?.id === "availability" ||
                    headCell?.id === "description" ? (
                    <TableCell
                      align="center"
                      key={headCell.id}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      {headCell.label}
                    </TableCell>
                  ) : (
                    headCell?.id === "list" &&
                    priceVisible && (
                      <TableCell
                        align="center"
                        key={headCell.id}
                        onClick={() => {
                          setShowPriceList(!showPriceList);
                        }}
                        style={{ color: "#5887f5" }}
                        className={styles.colorBlue}
                      >
                        {headCell.label}
                        <img src={alertstar} />
                      </TableCell>
                    )
                  )}
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {prodContentData?.shoppingCart?.lineItems?.length > 0 ? (
              <>
              {
                prodContentData?.shoppingCart?.lineItems
                .map((product: any, index: any) => {
                  return (
                    <>
                      {tableRowStructure(product, index)}
                      {
                        product?.isWarranty &&
                        product?.warrantyLineItems?.length > 0 &&
                        selectedPrimeSupport?.indexOf(index) !== -1 &&
                        product?.warrantyLineItems?.map((warrantyProduct: any, key: any) => {
                          return <>
                            {tableRowStructure(
                              warrantyProduct,
                              (`${key}_${index}`)
                            )}
                          </>
                        })
                      }
                    </>     
                  );
                })}
            </>
            ) : (
              <TableRow>
                <TableCell
                  colSpan={8}
                  align="center"
                  className={styles.noRecFound}
                >
                  {t("gzl.no_data_available.text")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.tableFooter} style={{ marginLeft: "6px" }}>
        <div className={styles.productListButtonInnerSection}>
          {!orderEntryMultiship && (
            <>
              <CustomDropDown
                label={t("dnet.add_selected_items.text")}
                id="currentList"
                value={selectedProductList}
                handleChange={(e: any) => {
                  setSelectedProductList(e.target.value);
                }}
                menuOptions={productListItems}
                module="productLists"
              />
              <CustomButton
                id="save"
                label={t("dnet.save.text")}
                onClick={() => saveProductListHandler()}
                color="info.main"
                margin="0 0 4px 10px"
              />
            </>
          )}
          <div
            className={styles.orderEntryButtonSection}
            style={
              !orderEntryMultiship
                ? { paddingLeft: "10px" }
                : { paddingLeft: "0" }
            }
          >
            <Typography variant="caption">
              {t("dnet.selected_items.text")}
            </Typography>
            <CustomButton
              id="deleteItem"
              label={t("dnet.delete.text")}
              onClick={()=>deleteHandler()}
              color="warning.main"
            />
          </div>
        </div>
      </div>
      {prodContentData?.shoppingCart?.lineItems?.length > 0 && (
        <div className={styles.tableFooter}>
          <div className={styles.totalAmount}>
            <CustomButton
              id="clearOrder"
              label={t("dnet.clear_order.text")}
              onClick={clearOrder}
              color="info.main"
              margin="6px"
            />
            {!orderEntryMultiship &&
            <>
              <CSVLink
                data={csvData}
                separator={`;`}
                enclosingCharacter={``}
                filename="SONYORDER"
                className={styles.hidden}
                ref={csvLink}
              ></CSVLink>
              <CustomButton
                id="downloadCSV"
                label={<img src={csvImage} alt={csvImageAlt} />}
                onClick={() => downloadCSV(prodContentData)}
                color="info.main"
                margin="6px"
              />
            </>
            }
            <CustomButton
              id="xml"
              label={<img src={xmlImage} alt={xmlImageAlt} />}
              onClick={handleDownloadXml}
              color="info.main"
              margin="6px"
            />
            <CustomButton
              id="upload"
              label={<img src={uploadImage} alt={uploadImageAlt} />}
              onClick={handleOpenFileModal}
              color="info.main"
              margin="6px"
            />
          </div>
        </div>
      )}
    </Box>
  );
};

SingleOrderEntryTable.defaultProps = {
  parent: "",
  saveHandler: () => {},
  orderEntryMultiship: false,
  handleOpenFileModal: () => {},
  isMultiRequestedDeliveryDate: false,
  handleQuotationButton: () => {},
};
