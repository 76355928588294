import { Box, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  creditItems,
  creditItemsSuccess,
  debitItems,
  debitItemsSuccess
} from "../../../state/actions";
import CustomButton from "../../atoms/customButton";
import CustomDropDown from "../../atoms/customDropDown";
import CustomTextField from "../../atoms/customTextField";
import logger from "../../helper/logger";
import CreditTable from "./creditTable";
import DebitTable from "./debitTable";
import styles from "./openItems.module.scss";
var _ = require("lodash");

export const OpenItems = (props: any) => {
  const { t } = useTranslation();
  const { refValue, from } = props;
  // let dateText = "02/01/23 08:54  UTC";
  const [value, setValue] = useState(t("gzl.all_documents_by_type.text"));
  const [refresh, setRefresh] = useState<Boolean>(false);
  const [referenceValue, setReferenceValue] = useState<string>("");
  let menuItems = [
    t("gzl.all_documents_by_type.text"),
    t("gzl.all_documents_by_date.text"),
    t("gzl.debits_only.text"),
    t("gzl.credits_only.text"),
  ];
  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.userDetails
  );
  const creditItemData = useSelector(
    (state: any) => state?.gazelleReducer?.creditItemData
  );
  const lastCreditDate = creditItemData?.lastSapAccessTime;
  const creditItemTableData = creditItemData?.items;

  const debitItemData = useSelector(
    (state: any) => state?.gazelleReducer?.debitItemData
  );
  const lastDebitDate = debitItemData?.lastSapAccessTime;
  const debitItemTableData = debitItemData?.items;
  // console.log(debitItemData, creditItemData, "debitopenItemData")
  const [csvData, setCsvData] = useState([]);
  const [readyToDownload, setReadyToDownload] = useState(false);
  const downloadTableLabel = t("gzl.csv_openitems.text");

  const downloadCSV = (tableData: any) => {
    // CSV format creation for downloading
    logger.info("Download Open Items CSV");
    const csvData: any = [
      [
        t("gzl.date.text"),
        t("gzl.invoice_no.text"),
        t("gzl.doc_type.text"),
        t("gzl.your_ref.text"),
        t("gzl.due_date.text"),
        t("gzl.org_inv_value.text"),
        t("gzl.outstd_blnc_less_disc.text"),
        t("gzl.delivery_no.text"),
        t("gzl.dispute_ref.text"),
        t("gzl.disc_allowed.text"),
        t("gzl.disc_expiry.text"),
        t("gzl.currency.text"),
      ],
      ...tableData?.map((item: any) => [
        item?.invoiceDate,
        item?.invoiceId,
        item?.documentType,
        item?.yourReference,
        item?.dueDate,
        item?.amount,
        item?.openAmount,
        item?.deliveryNumber,
        item?.disputeReference,
        item?.discount,
        item?.discountDate,
        item?.currency,
      ]),
    ];
    setCsvData(csvData);
    setReadyToDownload(true);
  };

  let csvLink: any = React.createRef();
  useEffect(() => {
    if (!_.isEmpty(csvData) && readyToDownload) {
      csvLink.current.link.click();
      setReadyToDownload(false);
    }
  }, [readyToDownload]);

  useEffect(() => {
    if (from === "refSearch" && refValue !== "") {
      setReferenceValue(refValue);
    }
  }, [refValue]);

  useEffect(() => {
    if (from === "refSearch" && refValue !== "") referenceHandler();
  }, [referenceValue]);

  const handleChange = (event: any) => {
    logger.info("Selected value ",event.target.value);
    setValue(event.target.value);
    getDebitAPIData(true);
    setReferenceValue("");
  };

  const onRefresh = () => {
    // On click of Refresh button, fetching API data again
    logger.info("Refreshing Open items data");
    setRefresh(!refresh);
    getDebitAPIData(true);
    setReferenceValue("");
  }

  const saveAccountDetails = useSelector(
    (state: any) => state?.commonReducer?.saveAccountDetails
  )

  const getCreditAPIData = () => {
    setCreditAPICall(false);
    // Hiting Credit Items API
    dispatch(
      creditItems({
        language: userDetails?.language,
        username: userDetails?.userName,
        organizationKey: "GB00",
        partnerKey: saveAccountDetails?.payerToAcc?.number,
        openItemType: 1,
        endDate: moment(new Date()).format("YYYYMMDD"),
        itemType: 0,
        startDate: moment(new Date()).format("YYYYMMDD"),
      })
    );
  };

  const [creditAPICall, setCreditAPICall] = useState<Boolean>(false);

  const getDebitAPIData = (creditAPICallTemp: Boolean = false) => {
    setCreditAPICall(creditAPICallTemp)
    // Hiting Debit Items API
    dispatch(
      debitItems({
        language: userDetails?.language,
        username: userDetails?.userName,
        organizationKey: "GB00",
        partnerKey: saveAccountDetails?.payerToAcc?.number,
        openItemType: 0,
        endDate: moment(new Date()).format("YYYYMMDD"),
        itemType: 0,
        startDate: moment(new Date()).format("YYYYMMDD"),
      })
    );
  };

  const referenceSetter = (event: any) => {
    if (event.target.value === "") {
      getDebitAPIData(true);
    }
    setReferenceValue(event.target.value);
  };

  const searchDataInTable = (tableData:any, itemData:any, tableType:any) => {
    //with your reference value searching in data to show respective result
    if (referenceValue?.length > 0) {
        const arr = tableData?.filter((elem: any) => {
          return (
            elem.yourReference === referenceValue ||
            elem.yourReference.includes(referenceValue)
          );
        });
        if(itemData) itemData.items = arr;
        tableType === t("gzl.debits_only.text")
          ? dispatch(debitItemsSuccess(itemData))
          : dispatch(creditItemsSuccess(itemData))
      } else {
        tableType === t("gzl.debits_only.text")
          ? getDebitAPIData()
          : getCreditAPIData()
      }
  }

  const referenceHandler = () => {
    if(value === t("gzl.debits_only.text")){
        searchDataInTable(debitItemTableData, debitItemData, t("gzl.debits_only.text"))
    }
    else if(value === t("gzl.credits_only.text")){
        searchDataInTable(creditItemTableData, creditItemData, t("gzl.credits_only.text"))
    }
    else{
        searchDataInTable(debitItemTableData, debitItemData, t("gzl.debits_only.text"))
        searchDataInTable(creditItemTableData, creditItemData, t("gzl.credits_only.text"))
    }
  };

  useEffect(() => {
    getDebitAPIData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (creditAPICall)
      getCreditAPIData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debitItemData])

  return (
    <>
      <Typography 
        variant={from !== "refSearch" ? "subtitle1" : "subtitle1"} 
        className={styles.header} 
        pb={3}
        color="primary"
      >
        {t("gzl.open_items_title.text")}
      </Typography>
      <Box>
        <Box className={styles.openBoxContainer}>
          <CustomTextField
            label={t("gzl.last_retrieval_date.text")}
            value={
              value === t("gzl.credits_only.text") && lastCreditDate
                ? moment(lastCreditDate).utc().format(
                  "DD/MM/YYYY HH:mm"
                ) + " UTC"
                : moment(lastDebitDate).utc().format(
                  "DD/MM/YYYY HH:mm"
                ) + " UTC"
            }
            disabled={true}
          />
          <CustomDropDown
            value={value}
            handleChange={handleChange}
            menuItems={menuItems}
            label={t("gzl.change_table_view.text")?.toString()}
            id="changeTableView"
          />
          <div className={styles.referenceFilterStyle}>
            <CustomTextField
              id="RefrenceSearchInput"
              label={t("gzl.your_ref.text")}
              changeHandler={referenceSetter}
              value={referenceValue}
            />
            <CustomButton
              id="refrenceSearchFindButton"
              style={{ marginLeft: "0" }}
              label={t("gzl.find_button.text")}
              onClick={() => referenceHandler()}
            />
          </div>
        </Box>
        {value === t("gzl.debits_only.text") ||
        value === t("gzl.credits_only.text") ? (
          <Box className={styles.tableContainer}>
            <div className={styles.openSingleTableHeader}>
              <div>
              <CustomButton
                id="Refresh"
                label={t("gzl.refresh.text")}
                onClick={onRefresh}
              />
              <CSVLink
                data={csvData}
                separator={`;`}
                enclosingCharacter={``}
                filename={downloadTableLabel}
                className={styles.hidden}
                ref={csvLink}
              ></CSVLink>
              <CustomButton
                id="downloadCSV"
                label={t("gzl.download_csv.text")}
                onClick={
                  value === t("gzl.credits_only.text") ? 
                  () => downloadCSV(creditItemTableData) : 
                  () => downloadCSV(debitItemTableData)
                }
                color="primary"
              />
              </div>
            </div>
            {value === t("gzl.credits_only.text") ? (
              <CreditTable />
            ) : (
              <DebitTable />
            )}
          </Box>
        ) : (
          <Box>
            <Box className={styles.tableContainer}>
              <div className={styles.openTableHeader}>
                <Typography variant="subtitle2" id="debit-table-headline">
                    {t("gzl.debits_only.text")}
                </Typography>
              <div>
                <CustomButton
                  id="DebitRefresh"
                  label={t("gzl.refresh.text")}
                  onClick={onRefresh}
                />
                <CSVLink
                  data={csvData}
                  separator={`;`}
                  enclosingCharacter={``}
                  filename={downloadTableLabel}
                  className={styles.hidden}
                  ref={csvLink}
                ></CSVLink>
                <CustomButton
                  id="downloadCSV"
                  label={t("gzl.download_csv.text")}
                  onClick={() => downloadCSV(debitItemTableData)}
                  color="primary"
                />
              </div>
              </div>
              <DebitTable />
            </Box>
            <Box className={styles.tableContainer}>
              <div className={styles.openTableHeader}>
                <Typography variant="subtitle2" id="credit-table-headline">
                    {t("gzl.credits_only.text")}
                </Typography>              
              <div>
                <CustomButton
                  id="CreditRefresh"
                  label={t("gzl.refresh.text")}
                  onClick={onRefresh}
                />
                <CSVLink
                  data={csvData}
                  separator={`;`}
                  enclosingCharacter={``}
                  filename={downloadTableLabel}
                  className={styles.hidden}
                  ref={csvLink}
                ></CSVLink>
                <CustomButton
                  id="downloadCSV"
                  label={t("gzl.download_csv.text")}
                  onClick={() => downloadCSV(creditItemTableData)}
                  color="primary"
                />
              </div>
              </div>
              <CreditTable />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
