import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./productLists.module.scss";
import { useTranslation } from "react-i18next";
import CustomDropDown from "../../atoms/customDropDown";
import CustomTextField from "../../atoms/customTextField";
import CustomButton from "../../atoms/customButton";
import { Link, useLocation } from "react-router-dom";
import { ProdContentDetail } from "../../products/prodContentDetail";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  deleteproductGroup,
  deleteproductGroupSuccess,
  getProductGroup,
  getProductGroupSuccess,
  getProductList,
  getProductsByMaterialName,
  getProductsByMaterialNameSuccess,
  saveproductGroup,
  saveproductGroupSuccess,
  updateproductGroup,
  updateproductGroupSuccess,
} from "../../../state/actions";
var _ = require("lodash");

export const ProductLists = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  let productFromOrderEntry = location?.state?.selectedProdList;
  let productGroupOrderEntryId = location?.state?.selectedProductListId;
  let productGroupOrderEntryName = location?.state?.selectedProductListName;

  // const productListItems = [
  //   {
  //     category: "New Personal List",
  //     list: [
  //       {
  //         value: "NP1",
  //         label: "New Personal List",
  //         noOfProducts: 0,
  //       },
  //     ],
  //   },
  //   {
  //     category: "Product List",
  //     list: [
  //       {
  //         value: "PL2",
  //         label: "JIT75 July",
  //         noOfProducts: 3,
  //       },
  //       {
  //         value: "PL3",
  //         label: "Cather",
  //         noOfProducts: 1,
  //       },
  //     ],
  //   },
  // ];

  const [productDataList, setProductDataList]: any = useState([]);
  const [isUpdateAPITrigged, setIsUpdatedAPITrigged]: any = useState(false);

  const productList = useSelector(
    (state: any) => state?.productListReducer?.productList
  );

  const productGroup = useSelector(
    (state: any) => state?.productListReducer?.productGroup
  );

  const deleteAPIresponse = useSelector(
    (state: any) => state?.productListReducer?.deleteAPIresponse
  );
  const updateAPIresponse = useSelector(
    (state: any) => state?.productListReducer?.updateAPIresponse
  );

  const saveAPIresponse = useSelector(
    (state: any) => state?.productListReducer?.saveAPIresponse
  );

  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );

  const [updateForOrderEntry, setUpdateForOrderEntry]: any = useState(false);
  const [switchProductGroup, setSwitchProductGroup]: any = useState(true);
  const [productGroupChanged,setProductGroupChanged]:any = useState(false);
  const [validOrderEntry, setValidOrderEntry]: any = useState(true);
  const [productGroupMembers, setProductGroupMembers]: any = useState([]);

  const [productListItems, setProductListItems]: any = useState([]);
  const [productQuantityMap, setProductQuantityMap]: any = useState([]);

  useEffect(() => {
    dispatch(getProductList({}));
    dispatch(deleteproductGroupSuccess({}));
    dispatch(updateproductGroupSuccess({}));
  }, []);

  const handleOrderEntryProducts = () => {
    if (
      !_.isEmpty(productFromOrderEntry) &&
      !_.isEmpty(productGroupOrderEntryId)
    ) {
      
      const products: any = [];
      const productTempQuantityMap: any = [];

      productFromOrderEntry?.map((product: any) => {
        products.push(product?.materialName);
        productTempQuantityMap.push({
          [product?.materialName]: {
            quantity: product?.quantity,
            ean: "",
          },
        });
      });

      if (!_.isEmpty(products)) {
        setProductQuantityMap(productTempQuantityMap);
        dispatch(
          getProductsByMaterialName({
            materialName: products,
          })
        );
      }

      // }else {
      //   let productGroupMembers: any = [];
      //   productFromOrderEntry.forEach((product: any) => {
      //     let productGroup = {
      //       quantity: product?.quantity,
      //       material_number: product?.materialName,
      //       ean_code: product?.ean,
      //     };
      //     productGroupMembers.push(productGroup);
      //   });

      //   dispatch(
      //     getProductGroup({ id: productGroupOrderEntry })
      //   );

      //   if (!_.isEmpty(productGroupMembers) && finalValue !== "") {
      //     dispatch(
      //       updateproductGroup({
      //         productGroup: {
      //           id: changeProductListData?.currentListValue,
      //           name: finalValue,
      //           product_group_members: productGroupMembers,
      //         },
      //       })
      //     );
      //   }

      //   //Product List already present

      //   // check the product list id in the product list available

      // }
    }
  };

  useEffect(() => {
    if (!_.isEmpty(productGroup) && validOrderEntry && productFromOrderEntry?.length>0 && !productGroupOrderEntryId?.includes("NPL")) {
      

      let productGroupMemberAPI: any = [];

      productGroup?.product_group_members?.map((groupMember: any) => {
        let productGroup = {
          quantity: groupMember?.quantity,
          material_number: groupMember?.material_number,
          ean_code: groupMember?.ean_code,
        };
        productGroupMemberAPI.push(productGroup);
      });
      const mergedProductGroupMembers = productGroupMembers.concat(
        productGroupMemberAPI.filter(
          (productGroup1: any) =>
            !productGroupMembers.some(
              (productGroup2: any) =>
                productGroup2.material_number ===
                productGroup1.material_number &&
                productGroup2.ean_code === productGroup1.ean_code
            )
        )
      );
      if (
        !_.isEmpty(mergedProductGroupMembers) &&
        productGroupOrderEntryId !== ""
      ) {
        setValidOrderEntry(false);
        setUpdateForOrderEntry(true);
        setSwitchProductGroup(true);
        dispatch(
          updateproductGroup({
            productGroup: {
              id: productGroupOrderEntryId,
              name: productGroupOrderEntryName,
              product_group_members: mergedProductGroupMembers,
            },
          })
        );
      }
    }
    else if (
      !_.isEmpty(productGroup)  &&
      productGroup?.id === changeProductListData?.currentListValue
    ) {
      const products: any = [];
      const productTempQuantityMap: any = [];

      productGroup?.product_group_members?.map((groupMember: any) => {
        products.push(groupMember?.material_number);
        productTempQuantityMap.push({
          [groupMember?.material_number]: {
            quantity: groupMember?.quantity,
            ean: groupMember?.ean_code,
          },
        });
      });

      if (!_.isEmpty(products)) {
        setProductQuantityMap(productTempQuantityMap);
        dispatch(
          getProductsByMaterialName({
            materialName: products,
          })
        );
      }
    } 
  }, [productGroup]);

  const productByMaterialName = useSelector(
    (state: any) => state?.productReducer?.productsByMaterialName
  );

  useEffect(() => {
    return () => {
     dispatch(getProductGroupSuccess({}));
     dispatch(updateproductGroupSuccess({}));
     dispatch(saveproductGroupSuccess({}));
     dispatch(getProductsByMaterialNameSuccess({}));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!productGroupOrderEntryId?.includes("NPL") && validOrderEntry && productFromOrderEntry?.length>0) {
      let productGroupMembers: any = [];
      productQuantityMap?.forEach((product: any) => {
        const materialName = Object.keys(product)[0];
        const quantity = product[materialName]?.quantity;
        const detailIndex = productByMaterialName.findIndex((detail: any) =>
          detail.hasOwnProperty(materialName)
        );
        if (detailIndex !== -1) {
          const detailIndex = productByMaterialName.findIndex((detail: any) =>
            detail.hasOwnProperty(materialName)
          );
          if (detailIndex !== -1) {
            const details = productByMaterialName[detailIndex][materialName];
            let productGroup = {
              quantity: quantity,
              material_number: materialName,
              ean_code: details?.product?.definition?.ean,
            };
            productGroupMembers.push(productGroup);
          }
        }
      });

      if (!_.isEmpty(productGroupMembers)) {
        setProductGroupMembers(productGroupMembers);
        dispatch(getProductGroup({ id: productGroupOrderEntryId }));
      }
    } else {
      let mergedProductDetails: any = [];

      productQuantityMap?.forEach((product: any) => {
        const materialName = Object.keys(product)[0];
        const quantity = product[materialName]?.quantity;
        const eanCode = product[materialName]?.ean;
        const detailIndex = productByMaterialName.findIndex((detail: any) =>
          detail.hasOwnProperty(materialName)
        );
        if (detailIndex !== -1) {
          const details = productByMaterialName[detailIndex][materialName];
          const matchedProductDetail = {
            [materialName]: {
              ...details,
              qty: quantity,
            },
          };
          mergedProductDetails.push(matchedProductDetail);
        } else {
          const emptyProductDetails = {
            [materialName]: {
              product: {
                definition: { materialName: materialName, ean: eanCode },
                texts: [{ description: t("dnet.model_not_recognised.text") }],
                type: 0,
              },
              qty: quantity,
            },
          };
          mergedProductDetails.push(emptyProductDetails);
        }
      });

      const updatedProducts = mergedProductDetails?.map(
        (product: any) => Object.values(product)[0]
      );
      setProductDataList(updatedProducts);
    }
  }, [productByMaterialName]);


  useEffect(() => {
    if (!_.isEmpty(productList)) {
      let productAllList: any = productList;
      let productListItemsTemp: any = [];
      Object.keys(productAllList)?.length > 0 &&
        Object.keys(productAllList)?.forEach((list: any) => {
          let subListArr: any = [];
          let listName:any = "";
          if(list === "personelProductList"){

            listName = t("dnet.personal_lists.text");

            subListArr.push({
              value: "NPL",
              label: t("dnet.new_personal_list.text"),
              noOfProducts: "",
            });
          }else if(list === "productGroupList"){

            listName = t("dnet.product_lists.text");

            let trans=t("dnet.new_product_list.text");
           
            subListArr.push({
              value: "NPL-M",
              label:  trans.replace("{0}",currentDealer?.bgName),
              noOfProducts: "",
            });
          }else{
            listName = t("dnet.reseller_product_lists.txt");

            let trans=t("dnet.new_product_list.text");
           
            subListArr.push({
              value: "NPL-R",
              label:  trans.replace("{0}",productAllList?.[list]?.[0]?.sales_org),
              noOfProducts: "",
            });
          }
         
          productAllList?.[list]?.forEach((subList: any) => {
            subListArr.push({
              value: subList?.id,
              label: subList?.name,
              noOfProducts: subList?.product_group_members?.length,
            });
          });
          let listCategory = {
            category: listName,
            list: subListArr,
          };
          productListItemsTemp.push(listCategory);
        });
      setProductListItems(productListItemsTemp);
        
      if(validOrderEntry && productFromOrderEntry?.length > 0 && productGroupOrderEntryId?.includes("NPL") )
        {
        setChangeProductListData({
          ...changeProductListData,
        ["currentListValue"]: productGroupOrderEntryId,
        renameList: "",
        currentListLabel:productGroupOrderEntryName,
        });
        }else if(!validOrderEntry && (productGroupOrderEntryId?.includes("NPL") || changeProductListData?.currentListValue?.includes("NPL"))){
          let finalValue = "";
          let mainList = productListItemsTemp?.filter((elem: any) => {
              return elem?.list.some((subList: any) => {
                return subList?.label === changeProductListData?.renameList;
              });
            });
            finalValue = mainList?.[0]?.list?.filter(
              (elem: any) => elem?.label === changeProductListData?.renameList
            )?.[0]?.value;


          setChangeProductListData({
            ...changeProductListData,
          ["currentListValue"]: finalValue,
          renameList: changeProductListData?.renameList,
          currentListLabel:changeProductListData?.renameList,
          });

        }
      validOrderEntry && productFromOrderEntry?.length > 0 && handleOrderEntryProducts();

      if (isUpdateAPITrigged) {
        if (updateForOrderEntry && !validOrderEntry && productFromOrderEntry?.length>0) {
          let finalValue = "";
          let mainList = productListItemsTemp?.filter((elem: any) => {
              return elem?.list.some((subList: any) => {
                return subList?.value === productGroupOrderEntryId;
              });
            });
            finalValue = mainList?.[0]?.list?.filter(
              (elem: any) => elem?.value === productGroupOrderEntryId
            )?.[0]?.label;
          setChangeProductListData({
            ...changeProductListData,
            ["currentListValue"]: productGroupOrderEntryId,
            renameList: finalValue,
            currentListLabel:finalValue,
          });
        } else {
          let finalValue = "";
          if (changeProductListData?.currentListValue !== "") {
            let mainList = productListItemsTemp?.filter((elem: any) => {
              return elem?.list.some((subList: any) => {
                return subList?.value === changeProductListData?.currentListValue;
              });
            });
            finalValue = mainList?.[0]?.list?.filter(
              (elem: any) => elem?.value === changeProductListData?.currentListValue
            )?.[0]?.label;
          }

          setChangeProductListData({
            ...changeProductListData,
            ["currentListValue"]: changeProductListData?.currentListValue,
            renameList: finalValue,
            currentListLabel:finalValue,
          });
        }
      }
    }
  }, [productList]);
  const [changeProductListData, setChangeProductListData] = useState({
    currentListValue: "",
    renameList: "",
    currentListLabel:""
  });
  const [renameProductListData, setRenameProductListData] = useState({
    currentListValue: "",
    renameList: "",
    currentListLabel:""
  });
  useEffect(() => {
    if (
      changeProductListData?.currentListValue !== "" &&
      !_.isEmpty(changeProductListData) &&
      !changeProductListData?.currentListValue?.includes("NPL") &&
      switchProductGroup && changeProductListData?.renameList !== ""
    ) {
      setProductDataList([]);
      setProductGroupChanged(true);
      dispatch(
        getProductGroup({ id: changeProductListData?.currentListValue })
      );
    }else if(switchProductGroup && changeProductListData?.currentListValue?.includes("NPL") && changeProductListData?.renameList === ""){
      setProductDataList([]);
    }
  }, [changeProductListData]);
  const handleProductListChange = (fieldName: any, data: any) => {
    if (fieldName === "currentListValue") {
      setSwitchProductGroup(true);
      let finalValue = "";
      if (data !== "") {
        let mainList = productListItems?.filter((elem: any) => {
          return elem?.list.some((subList: any) => {
            return subList?.value === data;
          });
        });
        finalValue = mainList?.[0]?.list?.filter(
          (elem: any) => elem?.value === data
        )?.[0]?.label;
      }
      setChangeProductListData({
        ...changeProductListData,
        [fieldName]: data,
        renameList: !data?.includes("NPL") ? finalValue: "",
        currentListLabel:finalValue,
      });
      setRenameProductListData({
        ...renameProductListData,
        [fieldName]: data,
        currentListLabel: finalValue,
        renameList: !data?.includes("NPL") ? finalValue: "",
      });
    } else {
      setSwitchProductGroup(false);
      setChangeProductListData({
        ...changeProductListData,
        [fieldName]: data,
      });
      setRenameProductListData({
        ...renameProductListData,
        [fieldName]: data,
      });
    }
  };
  const saveHandler = () => {
    let productGroupMembers: any = [];
    productDataList.forEach((product: any) => {
      let productGroup = {
        quantity: product?.qty,
        material_number: product?.product?.definition?.materialName,
        ean_code: product?.product?.definition?.ean,
      };
      productGroupMembers.push(productGroup);
    });
    if (
      !changeProductListData?.currentListValue?.includes("NPL")
      && changeProductListData?.renameList !== ""
    ) {
      setUpdateForOrderEntry(false);
      setSwitchProductGroup(true);
      dispatch(
        updateproductGroup({
          productGroup: {
            id: changeProductListData?.currentListValue,
            name: changeProductListData?.renameList,           
            product_group_members: productGroupMembers,
          },
        })
      );
    } else {
      if (changeProductListData?.renameList !== "") {
        let type:any="";
      
      if(changeProductListData?.currentListValue === "NPL-M"){
       type="M"
      }else if(changeProductListData?.currentListValue === "NPL-R"){
        type="R"
       }else{
        type="P"
      }
      setSwitchProductGroup(true);
      setValidOrderEntry(false);
      dispatch(
        saveproductGroup({
          productGroup: {
            name: changeProductListData?.renameList,
            type:  type,
            product_group_members: productGroupMembers,
          },
        })
      );
    }
  }
  };

  const deleteProductHandler = (productList: any) => {
    if(validOrderEntry && !productGroupOrderEntryId?.includes("NPL") && changeProductListData?.currentListValue?.includes("NPL")){
      setProductDataList(productList);
    }else {

      let productGroupMembers: any = [];
    productList.forEach((product: any) => {
      let productGroup = {
        quantity: product?.qty,
        material_number: product?.product?.definition?.materialName,
        ean_code: product?.product?.definition?.ean,
      };
      productGroupMembers.push(productGroup);
    });

      setUpdateForOrderEntry(false);
      setSwitchProductGroup(true);
      dispatch(
        updateproductGroup({
          productGroup: {
            id: changeProductListData?.currentListValue,
            name: changeProductListData?.renameList,
            product_group_members: productGroupMembers,
          },
        })
      );
    
    }
  };

  const [saveError,setSaveError]:any=useState("");

  useEffect(() => {
    if (!_.isEmpty(updateAPIresponse) && updateAPIresponse?.success) {
      dispatch(getProductList({}));
      setIsUpdatedAPITrigged(true);
    }
  }, [updateAPIresponse]);

  useEffect(() => {
    if (!_.isEmpty(saveAPIresponse) && saveAPIresponse?.success && _.isEmpty(saveAPIresponse?.data)) {
      setSaveError("");
      setIsUpdatedAPITrigged(false);
      dispatch(getProductList({}));
    }else{
      setSaveError(saveAPIresponse?.data?.error?.message);
      setValidOrderEntry(true);
      setIsUpdatedAPITrigged(false);
    }
  }, [saveAPIresponse]);

  const deleteHandler = () => {
    dispatch(
      deleteproductGroup({
        id: changeProductListData?.currentListValue,
      })
    );
  };

  useEffect(() => {
    if (!_.isEmpty(deleteAPIresponse) && deleteAPIresponse?.success) {
      setChangeProductListData({
        currentListValue: "",
        renameList: "",
        currentListLabel:""
      });
      setProductDataList([]);
      dispatch(getProductList({}));
    }
  }, [deleteAPIresponse]);
  let endMsg = t("dnet.product_list_link.text")?.toString();
  let firstPartOfMsg = endMsg.split("<");
  let lastPartOfEndMsg = endMsg.split(">");
  let moduleNameOutOfMsg = lastPartOfEndMsg[1]?.split("<");

  return (
    <div>
      <Typography variant="subtitle1" pb={3} color="primary">
        {t("dnet.product_lists.text")}
      </Typography>
      <Typography variant="body2" mb={2}>
        {t("dnet.unrecognized_model.text")}
      </Typography>
      {saveError!=="" && <Typography variant="body2" mb={2}>
        {t(saveError)?.replace("{0}",currentDealer?.bgName)}
      </Typography>}
      <div className={styles.productListBox}>
        <CustomDropDown
          label={t("dnet.current_list_colon.text")}
          id="currentList"
          value={changeProductListData?.currentListValue}
          handleChange={(e: any) => {
            handleProductListChange("currentListValue", e.target.value);
          }}
          menuOptions={productListItems}
          module="productLists"
        />
        {changeProductListData?.currentListValue !== "" && (
          <div className={styles.buttonSection}>
            <CustomTextField
              id="renameList"
              label={t("dnet.name_to.text")}
              changeHandler={(e: any) => {
                handleProductListChange("renameList", e.target.value);
              }}
              value={changeProductListData?.renameList}
            />
            <CustomButton
              id="save"
              label={t("dnet.save_changes.text")}
              onClick={saveHandler}
              color="primary"
              margin="0"
            />
          </div>
        )}
      </div>
      {(!changeProductListData?.currentListValue?.includes("NPL") || (validOrderEntry && productFromOrderEntry?.length>0)) && changeProductListData?.currentListValue !== "" && (
        <div>
          <ProdContentDetail
            parent="productLists"
            updateHandler={saveHandler}
            productGroup={productDataList}
            updateProductGroup={setProductDataList}
            saveHandler={saveHandler}
            deleteHandler={deleteHandler}
            deleteProductHandler={deleteProductHandler}
            productGroupChanged={productGroupChanged}
            setProductGroupChanged={setProductGroupChanged}
          />
        </div>
      )}
      <div className={styles.endMsgFormat}>
        <Typography variant="body2">{firstPartOfMsg?.[0]}</Typography>
        <Link to="/Order_Entry" className={styles.linkStyle}>
          <Typography variant="body2">{moduleNameOutOfMsg?.[0]}</Typography>
        </Link>
        <Typography variant="body2">
          {lastPartOfEndMsg?.[lastPartOfEndMsg?.length - 1]}
        </Typography>
      </div>
    </div>
  );
};
