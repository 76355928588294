import * as React from 'react';
import Radio from '@mui/material/Radio';

const CustomRadioButton = ({handleRadioChange, checked}:any) =>{
    // const [value, setValue] = React.useState<any>('')
    // const handleRadioChange=(e:any)=>{
    //     setValue(e.target.value)
    // }
    return (
        <Radio
            checked={checked}
            onChange={handleRadioChange}
        />
    )
}

export default CustomRadioButton;