import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  MODAL_TYPES,
  PRODUCT_STATUS,
} from "../../../../../constants/commonConstants";
import CustomButton from "../../../../atoms/customButton";
import CustomDropDown from "../../../../atoms/customDropDown";
import { downloadExcelFile, downloadFile } from "../../../../helper/util";
import styles from "./dataDownloadDetails.module.scss";
import { useDispatch } from "react-redux";
import { downloadDataFile } from "../../../../../state/actions";
var _ = require("lodash");
var js2xmlparser = require("js2xmlparser");

export const DataDownloadDetails = (props: any) => {
  const { modalDetails } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [fileTypes, setFileTypes] = useState<any>([]);
  const [selectedFileType, setSelectedFileType] = useState<any>("");
  const [languages, setLanguages] = useState<any>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<any>("");
  const [updatedOgConfig, setUpdatedOgConfig] = useState<any>({});
  const [isInternalAdmin, setInternalAdmin] = React.useState<any>(false);
  const userType = useSelector(
    (state: any) => state?.commonReducer?.currentDealer?.accountFlag
  );
  useEffect(() => {
    prepopulateData(modalDetails?.queryType);
    if (userType == 0) {
      setInternalAdmin(true);
    }
  }, [userType]);

  const [displayRetailPriceWarning, setDisplayRetailPriceWarning] =
    useState<any>(false);

  const dataMapping: any = {
    materialName: "material_name",
    modelName: "model_name",
    uniqueModelCode: "unique_model_code",
    color: "color",
    categoryName: "category_name",
    productHierarchyCode: "product_hierarchy_code",
    shortDescription: "description",
    businessGroup: "sales_area",
    language: "language",
    longDescription: "long_description",
    listPrice: "price",
    currency: "currency",
    priceListType: "price_list_type",
    extendedPriceListType: "extended_price_list_type",
    accountNumber: "account_id",
    retailPrice: "retail_price",
    details: "details",
    salesStatus: "sales_status",
    minimumOrderQuantity: "min_order_quantity",
    masterCartonQuantity: "master_carton_quantity",
    availability: "availability",
    availabilityCode: "atp_status",
    availabilityStatus: "availability_status",
    atpDate: "atp_date",
    countryOfOrigin: "country_of_origin",
    commodityCode: "commodity_code",
    firstShipmentMonth: "first_shipment_month",
    firstShipmentDate: "first_shipment_date",
    tax: "tax",
    recycleTax: "recycle_tax",
    batteryTax: "battery_tax",
    fee: "fee",
    ecoFee: "eco_fee",
    visibleFee: "visible_fee",
    width: "width",
    grossWeight: "gross_weight",
    netWeight: "net_weight",
    weightUnit: "weight_unit",
    height: "height",
    length: "length",
    unitOfDimension: "unit_of_dimension",
    volume: "volume",
    volumeUnit: "volume_unit",
    earBatteryWeight: "ear_battery_weight",
    earElectronicAccessoryWeight: "ear_electronic_accessory_weight",
    earNonElectronicAccessoryWeight: "ear_non_electronic_accessory_weight",
    earNetWeight: "ear_net_weight",
    weightOfCardboard: "weight_of_cardboard",
    weightOfCorrugatedCardboard: "weight_of_corrugated_cardboard",
    weightOfNonRecycablePaper: "weight_of_non_recycable_paper",
    totalWeightOfPaper: "total_weight_of_paper",
    weightOfPaper: "weight_of_paper",
    plasticWeightOfCompounds: "plastic_weight_of_compounds",
    plasticEpsWeigth: "plastic_eps_weigth",
    plasticOtherWeight: "plastic_other_weigth",
    plasticPetWeight: "plastic_pet_weigth",
    plasticPeWeight: "plastic_pe_weigth",
    plasticPpWeight: "plastic_pp_weigth",
    plasticPsWeight: "plastic_ps_weigth",
    plasticPvcWeight: "plastic_pvc_weigth",
    plasticTotalWeight: "plastic_total_weigth",
    levies: "levies",
    tapeLevies: "tape_levies",
    packagingLevies: "packaging_levies",
    batteryLevies: "battery_levies",
    authorLevies: "author_levies",
    battery: "battery",
    batteryPerMaterial: "battery_per_material",
  };

  const ogConfig: any = {
    digitalAsset: {
      defaultDataTypes: ["identifiers"],
      mainDataTypes: [
        "descriptions",
        "featureBenefits",
        "images",
        "videos",
        "inTheBox",
        "awardsAndReviews",
        "ecoEnergy",
        "permalinkUrl",
        "techSpecs",
        "keyTechSpecs",
        "iconDescription",
        "proBenefit",
        "proOverview",
        "manuals",
        "relatedProducts",
      ],
      subDataTypes: {
        identifiers: [
          "titleIdentifiers",
          "materialName",
          "modelName",
          "eanCode",
          "categoryName",
          "locale",
          "sunriseDateTime",
        ],
        descriptions: [
          "titleDescriptions",
          "marketingProductName",
          "elevatorPitch",
          "topFeatures",
          "factualShortDescription",
        ],
        featureBenefits: [
          "titleFeatureBenefits",
          "featureBenefits",
          "headline",
          "body",
          "shot",
        ],
        inTheBox: ["titleInTheBox", "inTheBox"],
        awardsAndReviews: ["titleAwardsAndReviews", "awards", "expertReviews"],
        ecoEnergy: ["titleEcoEnergy", "ecoEnergy"],
        permalinkUrl: ["titlePermalinkUrl", "permalinkUrl"],
        relatedProducts: ["titleRelatedProducts", "accessories"],
        images: ["titleImages", "images"],
        videos: ["titleVideos", "videos"],
        techSpecs: ["titleTechSpecs", "techSpecs", "displayName", "value"],
        keyTechSpecs: ["titleKeyTechSpecs", "keyTechSpecs"],
        iconDescription: ["titleIconDescription", "iconDescription"],
        proBenefit: ["titleProBenefit", "proBenefit"],
        proOverview: ["titleProOverview", "proOverview"],
        manuals: ["titleManuals", "manuals", "description", "language", "url"],
      },
      fileTypes: ["EXCEL", "JSON", "XML", "CSV"],
    },
    productData: {
      mainDataTypes: [
        "metaData",
        "shortDescription",
        "longDescription",
        "price",
        "retailPrice",
        "details",
        "quantity",
        "availability",
        "shipmentDate",
        "tax",
        "fee",
        "metricalUnits",
        "earWeight",
        "otherWeight",
        "levies",
        "battery",
      ],
      subDataTypes: {
        metaData: [
          "titleMetaData",
          "materialName",
          "modelName",
          "uniqueModelCode",
          "color",
          "categoryName",
          "productHierarchyCode",
        ],
        shortDescription: ["titleShortDescription", "shortDescription"],
        longDescription: [
          "titleLongDescription",
          "businessGroup",
          "language",
          "longDescription",
        ],
        price: [
          "titlePrice",
          "businessGroup",
          "listPrice",
          "currency",
          "priceListType",
          "extendedPriceListType",
          "accountNumber",
        ],
        retailPrice: [
          "titleRetailPrice",
          "businessGroup",
          "retailPrice",
          "currency",
        ],
        details: [
          "titleDetails",
          "details",
          "businessGroup",
          "salesStatus",
          "minimumOrderQuantity",
        ],
        quantity: ["titleQuantity", "masterCartonQuantity"],
        availability: [
          "titleAvailability",
          "availability",
          "businessGroup",
          "availabilityCode",
          "availabilityStatus",
          "atpDate",
          "countryOfOrigin",
          "commodityCode",
        ],
        shipmentDate: [
          "titleShipmentDate",
          "firstShipmentMonth",
          "firstShipmentDate",
        ],
        tax: [
          "titleTax",
          "tax",
          "businessGroup",
          "recycleTax",
          "batteryTax",
          "currency",
        ],
        fee: [
          "titleFee",
          "fee",
          "businessGroup",
          "ecoFee",
          "visibleFee",
          "currency",
        ],
        metricalUnits: [
          "titleMetricalUnits",
          "width",
          "grossWeight",
          "netWeight",
          "weightUnit",
          "height",
          "length",
          "unitOfDimension",
          "volume",
          "volumeUnit",
        ],
        levies: [
          "titleLevies",
          "levies",
          "businessGroup",
          "tapeLevies",
          "packagingLevies",
          "batteryLevies",
          "authorLevies",
        ],
        earWeight: [
          "titleEarWeight",
          "earBatteryWeight",
          "earElectronicAccessoryWeight",
          "earNonElectronicAccessoryWeight",
          "earNetWeight",
        ],
        otherWeight: [
          "titleOtherWeight",
          "weightOfCardboard",
          "weightOfCorrugatedCardboard",
          "weightOfNonRecycablePaper",
          "totalWeightOfPaper",
          "weightOfPaper",
          "plasticWeightOfCompounds",
          "plasticEpsWeigth",
          "plasticOtherWeight",
          "plasticPetWeight",
          "plasticPeWeight",
          "plasticPpWeight",
          "plasticPsWeight",
          "plasticPvcWeight",
          "plasticTotalWeight",
        ],
        battery: ["titleBattery", "battery", "batteryPerMaterial"],
      },
      defaultDataTypes: [],
      fileTypes: ["EXCEL", "XML", "CSV", "TXT"],
    },
    sampleBusinessGroup: {
      excludedDataTypes: ["videos", "techSpecs"],
      includedDataTypes: ["retailPrice"],
    },
    reseller: {
      excludedDataTypes: ["retailPrice"],
    },
    dealer: {
      excludedDataTypes: ["retailPrice"],
    },
    hybrid: {
      excludedDataTypes: ["retailPrice"],
    },
    internal_admin: {
      excludedDataTypes: [],
    },
  };

  const currentLocale = useSelector(
    (state: any) => state?.commonReducer?.locale
  );

  useEffect(() => {
    const tempConfig: any = { ...ogConfig };
    if (!isInternalAdmin) {
      tempConfig.productData.mainDataTypes =
        tempConfig?.productData?.mainDataTypes?.filter(
          (dataType: any) => dataType !== "retailPrice"
        );
    }
    setUpdatedOgConfig(tempConfig);
  }, [isInternalAdmin]);

  //Populate the data based on querytype
  const prepopulateData = (queryType: any) => {
    if (queryType === MODAL_TYPES.DIGITAL_ASSET) {
      const filteredMainDataTypes = !isInternalAdmin
        ? ogConfig?.digitalAsset?.mainDataTypes?.filter(
            (value: any) => value !== "retailPrice"
          )
        : ogConfig?.digitalAsset?.mainDataTypes;
      filteredMainDataTypes?.includes("retailPrice")
        ? setDisplayRetailPriceWarning(true)
        : setDisplayRetailPriceWarning(false);
      setFileTypes(ogConfig?.digitalAsset?.fileTypes);
      setSelectedFileType(ogConfig?.digitalAsset?.fileTypes[0]);
    } else {
      const filteredMainDataTypes = !isInternalAdmin
        ? ogConfig?.productData?.mainDataTypes?.filter(
            (value: any) => value !== "retailPrice"
          )
        : ogConfig?.productData?.mainDataTypes;
      filteredMainDataTypes?.includes("retailPrice")
        ? setDisplayRetailPriceWarning(true)
        : setDisplayRetailPriceWarning(false);
      setFileTypes(ogConfig?.productData?.fileTypes);
      setSelectedFileType(ogConfig?.productData?.fileTypes[0]);
    }
    let langList: any = [];
    let lang: any = currentLocale?.split("_")[0];
    langList.push({
      label: t(`dataDownload.language.${lang}.text`),
      value: lang,
    });
    setLanguages(langList);
    setSelectedLanguage(langList[0]?.value);
  };

  const productDetailsData = useSelector(
    (state: any) => state?.productReducer?.productDetailsData
  );

  const downloadData = () => {
    if (modalDetails?.queryType === MODAL_TYPES.DIGITAL_ASSET) {
      switch (selectedFileType) {
        case "EXCEL":
          const ExcelJS = require("exceljs");
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("Sheet1");
          prepareDigitalAssetExcelData(sheet);
          downloadExcelFile(
            workbook,
            `${productDetailsData?.materialName}.xlsx`
          );
          break;
        case "JSON":
          downloadFile(
            JSON.stringify(productDetailsData, null, 4),
            `${productDetailsData?.materialName}.json`,
            "text/json"
          );
          break;
        case "XML":
          const xmlData = js2xmlparser.parse("product", productDetailsData);
          downloadFile(
            xmlData,
            `${productDetailsData?.materialName}.xml`,
            "text/xml"
          );
          break;
        case "CSV":
          let csvContent =
            "data:text/csv;charset=utf-8," +
            "\ufeff" +
            prepareDigitalAssetCsvData();
          var encodedUri = encodeURI(csvContent);
          var link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute(
            "download",
            `${productDetailsData?.materialName}.csv`
          );
          document.body.appendChild(link);
          link.click();
          break;
        default:
          break;
      }
    } else if (modalDetails?.queryType === MODAL_TYPES.PRODUCT_DATA) {
      const subSelectedDataType: any = [];

      updatedOgConfig?.productData.mainDataTypes.forEach(
        (mainDataTypes: any) => {
          updatedOgConfig?.productData.subDataTypes?.[mainDataTypes].forEach(
            (subDataType: any) => {
              subSelectedDataType.push(dataMapping[subDataType]);
            }
          );
        }
      );

      dispatch(
        downloadDataFile({
          selectedDataTypes: subSelectedDataType,
          selectedProducts: [productDetailsData?.materialName],
          selectedCategory: "",
        })
      );
    }
  };

  const productDataDownload = useSelector(
    (state: any) => state?.dataDownloadReducer?.productDataDownload
  );
  useEffect(() => {
    if (!_.isEmpty(productDataDownload)) {
      switch (selectedFileType) {
        case "EXCEL":
          const ExcelJS = require("exceljs");
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("Sheet1");
          prepareProductDownloadExcelData(sheet, productDataDownload[0]);
          downloadExcelFile(
            workbook,
            `${productDataDownload[0]?.material_name}.xlsx`
          );
          break;
        case "XML":
          productDataDownload.forEach((product: any, index: any) => {
            let productToParse: any = {};
            updatedOgConfig?.productData.mainDataTypes.forEach(
              (mainDataTypes: any) => {
                productToParse[mainDataTypes] = {};
                updatedOgConfig?.productData.subDataTypes?.[
                  mainDataTypes
                ].forEach((subDataType: any) => {
                  if (subDataType === "availabilityStatus") {
                    //row.push(product["atp_status"] ? checkStatus(product["atp_status"]?.toString()) : "");
                    productToParse[mainDataTypes][subDataType] = product[
                      "atp_status"
                    ]
                      ? checkStatus(product["atp_status"]?.toString())
                      : "";
                  } else if (!subDataType?.includes("title")) {
                    productToParse[mainDataTypes][subDataType] = product?.[
                      dataMapping?.[subDataType]
                    ]
                      ? product[dataMapping[subDataType]]
                      : "";
                  }
                });
              }
            );

            const xmlData = js2xmlparser.parse("product", productToParse);
            downloadFile(
              xmlData,
              `${productDataDownload[0]?.material_name}.xml`,
              "text/xml"
            );
          });
          break;
        case "CSV":
          let csvContent =
            "data:text/csv;charset=utf-8," +
            "\ufeff" +
            prepareProductDownloadCsvData(productDataDownload);
          var encodedUri = encodeURI(csvContent);
          var link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute(
            "download",
            `${productDataDownload[0]?.material_name}.csv`
          );
          document.body.appendChild(link);
          link.click();
          break;
        case "TXT":
          let txtContent = prepareProductDownloadCsvData(productDataDownload);
          const rows = txtContent.split("\n");
          const quotedRows = rows.map((row: any) => {
            const fields = row.split(",");
            const quotedFields = fields.map((field: any) => `"${field}"`);
            return quotedFields.join(",");
          });
          let quotedContent = quotedRows.join("\n");
          downloadFile(
            quotedContent,
            `${productDataDownload[0]?.material_name}.txt`,
            "text/plain"
          );
          break;
        default:
          break;
      }
    }
  }, [productDataDownload]);
  const checkStatus = (status: any) => {
    switch (status) {
      case PRODUCT_STATUS.PRODUCT_AVAILABILITY:
        return t("dnet.available.text");
      case PRODUCT_STATUS.PRODUCT_AVAILABILITY_ON_KNOWN_DATE:
        return t("dnet.next_available_stock.text");
      case PRODUCT_STATUS.PRODUCT_UNAVAILABLE:
        return t("dnet.contact_sony_for_avb.text");
      case PRODUCT_STATUS.PRODUCT_SUBJECT_TO_MANUAL_ALLOCATION:
        return t("dnet.contact_sony_for_avb.text");
      case PRODUCT_STATUS.PRODUCT_AVAILABLE_BUT_SUBJECT_TO_QUOTA:
        return t("dnet.current_stock.text");
      case PRODUCT_STATUS.PRODUCT_AVAILABLE_ON_KNOWN_DATE_AND_SUBJECT_TO_QUOTA:
        return t("dnet.next_available_stock.text");
      case PRODUCT_STATUS.PRODUCT_PRE_SALES:
        return t("dnet.new_launch.text");
      default:
        return "";
    }
  };

  const prepareProductDownloadCsvData = (productList: any) => {
    const subSelectedDataType: any = [];
    let data: any = [];

    updatedOgConfig?.productData.mainDataTypes.forEach((mainDataTypes: any) => {
      updatedOgConfig?.productData.subDataTypes?.[mainDataTypes].forEach(
        (subDataType: any) => {
          !subDataType?.includes("title") &&
            subSelectedDataType.push(subDataType);
        }
      );
    });

    let header = subSelectedDataType.map((subDataTypes: any) =>
      t(`dataDownload.dataType.${subDataTypes}.text`)
    );

    productList?.map((product: any) => {
      let row: any = [];
      updatedOgConfig?.productData.mainDataTypes.forEach(
        (mainDataTypes: any) => {
          updatedOgConfig?.productData.subDataTypes?.[mainDataTypes].forEach(
            (subDataType: any) => {
              if (!subDataType?.includes("title")) {
                if (subDataType === "availabilityStatus") {
                  row.push(
                    product["atp_status"]
                      ? checkStatus(product["atp_status"]?.toString())
                      : ""
                  );
                } else {
                  row.push(
                    product[dataMapping?.[subDataType]]
                      ? product[dataMapping[subDataType]]?.toString()
                      : ""
                  );
                }
              }
            }
          );
        }
      );
      data.push(row);
    });
    let replacedArr: any = data.map((row: any) =>
      row.map((field: any) => field?.replaceAll(",", ";"))
    );

    return (
      header + "\n" + replacedArr.map((row: any) => row.join(",")).join("\n")
    );
  };

  const prepareProductDownloadExcelData = (sheet: any, product: any) => {
    let headerRows: any = [];

    updatedOgConfig?.productData.mainDataTypes.forEach((mainDataTypes: any) => {
      let subDataTypeArr =
        updatedOgConfig?.productData.subDataTypes?.[mainDataTypes];
      if (
        (subDataTypeArr.includes(mainDataTypes) &&
          subDataTypeArr.length !== 2 &&
          mainDataTypes !== "battery") ||
        mainDataTypes === "price"
      ) {
        let subDataTypeProductData: any = [];
        let subArr: any = [];
        subDataTypeArr.forEach((subDataType: any) => {
          if (
            mainDataTypes !== subDataType ||
            subDataType === "battery" ||
            subDataType === "longDescription"
          ) {
            subArr.push(t(`dataDownload.dataType.${subDataType}.text`));
            subDataType?.includes("title")
              ? subDataTypeProductData.push("")
              : subDataType === "availabilityStatus"
              ? subDataTypeProductData.push(
                  product["atp_status"]
                    ? checkStatus(product["atp_status"]?.toString())
                    : ""
                )
              : subDataTypeProductData.push(
                  product?.[dataMapping?.[subDataType]]
                );
          }
        });
        setMainHeaderStyle(sheet.addRow(subArr), headerRows);
        sheet.addRow(subDataTypeProductData);
      } else {
        updatedOgConfig?.productData.subDataTypes?.[mainDataTypes].forEach(
          (subDataType: any) => {
            subDataType?.includes("title")
              ? setMainHeaderStyle(
                  sheet.addRow([
                    t(`dataDownload.dataType.${subDataType}.text`),
                  ]),
                  headerRows
                )
              : subDataType === "availabilityStatus"
              ? //row.push(product["atp_status"] ? checkStatus(product["atp_status"]?.toString()) : "");
                sheet.addRow([
                  t(`dataDownload.dataType.${subDataType}.text`),
                  product["atp_status"]
                    ? checkStatus(product["atp_status"]?.toString())
                    : "",
                ])
              : sheet.addRow([
                  t(`dataDownload.dataType.${subDataType}.text`),
                  product?.[dataMapping?.[subDataType]]
                    ? product[dataMapping[subDataType]]
                    : "",
                ]);
          }
        );
      }
    });

    //set the width of columns
    for (let i = 1; i <= sheet.actualColumnCount; i++) {
      const col = sheet.getColumn(i);
      col.width = 30;
    }

    //set the background color for subheaders
    for (let i = 1; i <= sheet.actualRowCount; i++) {
      if (!headerRows.includes(i)) {
        const row = sheet.getRow(i);
        row.getCell(1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "969696" },
          bgColor: { argb: "969696" },
        };
        row.getCell(1).font = {
          bold: true,
        };
      }
    }
  };

  const prepareDigitalAssetExcelData = (sheet: any) => {
    let headerRows: any = [];
    let product: any = productDetailsData;
    //Identifiers
    setMainHeaderStyle(
      sheet.addRow([t("dataDownload.dataType.titleIdentifiers.text")]),
      headerRows
    );
    productDetailsData?.materialName &&
      sheet.addRow([
        t("dataDownload.dataType.materialName.text"),
        productDetailsData?.materialName,
      ]);
    product?.modelName &&
      sheet.addRow([
        t("dataDownload.dataType.modelName.text"),
        productDetailsData?.modelName,
      ]);
    product?.eanCode &&
      sheet.addRow([
        t("dataDownload.dataType.eanCode.text"),
        productDetailsData?.eanCode,
      ]);
    sheet.addRow([
      t("dataDownload.dataType.categoryName.text"),
      productDetailsData?.categoryName,
    ]);
    product?.locale &&
      sheet.addRow([
        t("dataDownload.dataType.locale.text"),
        productDetailsData?.locale,
      ]);
    product?.sunriseDateTime &&
      sheet.addRow([
        t("dataDownload.dataType.sunriseDateTime.text"),
        productDetailsData?.sunriseDateTime,
      ]);

    //Description
    setMainHeaderStyle(
      sheet.addRow([t("dataDownload.dataType.titleDescriptions.text")]),
      headerRows
    );
    product?.marketingProductName &&
      sheet.addRow([
        t("dataDownload.dataType.marketingProductName.text"),
        productDetailsData?.marketingProductName,
      ]);
    product?.elevatorPitch &&
      sheet.addRow([
        t("dataDownload.dataType.elevatorPitch.text"),
        productDetailsData?.elevatorPitch,
      ]);
    product?.topFeatures &&
      sheet.addRow([
        t("dataDownload.dataType.topFeatures.text"),
        productDetailsData?.topFeatures
          ? productDetailsData?.topFeatures[0]
          : "",
      ]);
    for (let i = 1; i < productDetailsData?.topFeatures?.length; i++) {
      product?.topFeatures &&
        sheet.addRow(["", productDetailsData?.topFeatures[i]]);
    }
    product?.factualShortDescription &&
      sheet.addRow([
        t("dataDownload.dataType.factualShortDescription.text"),
        productDetailsData?.factualShortDescription,
      ]);

    //Feature Benefits
    setMainHeaderStyle(
      sheet.addRow([
        t("dataDownload.dataType.titleFeatureBenefits.text"),
        t("dataDownload.dataType.headline.text"),
        t("dataDownload.dataType.body.text"),
        t("dataDownload.dataType.shot.text"),
      ]),
      headerRows
    );
    product?.featureBenefits &&
      productDetailsData?.featureBenefits?.forEach((ele: any) => {
        product?.featureBenefits &&
          sheet.addRow(["", ele?.headline, ele?.bodyCopy, ele?.shot]);
      });

    //Images
    setMainHeaderStyle(
      sheet.addRow([t("dataDownload.dataType.titleImages.text")]),
      headerRows
    );
    product?.images &&
      Object.keys(productDetailsData?.images).forEach((ele: any) => {
        product?.images &&
          sheet.addRow([ele, productDetailsData?.images[ele][0]]);
        for (let i = 1; i < productDetailsData?.images[ele]?.length; i++) {
          sheet.addRow(["", productDetailsData?.images[ele][i]]);
        }
      });

    //Videos
    setMainHeaderStyle(
      sheet.addRow([t("dataDownload.dataType.titleVideos.text")]),
      headerRows
    );
    product?.videos &&
      sheet.addRow([
        "externalUrl",
        product?.videos?.[0]?.videoFamily?.externalUrl,
      ]);
    //In the box
    setMainHeaderStyle(
      sheet.addRow([t("dataDownload.dataType.titleInTheBox.text")]),
      headerRows
    );
    product?.inTheBox &&
      productDetailsData?.inTheBox?.forEach((ele: any) => {
        product?.inTheBox && sheet.addRow(["", ele]);
      });

    //Awards And reviews
    setMainHeaderStyle(
      sheet.addRow([t("dataDownload.dataType.titleAwardsAndReviews.text")]),
      headerRows
    );
    if (productDetailsData?.awards?.length > 0) {
      sheet.addRow([
        t("dataDownload.dataType.awards.text"),
        productDetailsData?.awards[0],
      ]);
      for (let i = 1; i < productDetailsData?.awards?.length; i++) {
        sheet.addRow(["", productDetailsData?.awards[i]]);
      }
    }
    if (productDetailsData?.expertReviews?.length > 0) {
      sheet.addRow([
        t("dataDownload.dataType.expertReviews.text"),
        productDetailsData?.expertReviews[0],
      ]);
      for (let i = 1; i < productDetailsData?.expertReviews?.length; i++) {
        sheet.addRow(["", productDetailsData?.expertReviews[i]]);
      }
    }

    //TODO - Need data to cross check
    //Eco Energy
    setMainHeaderStyle(
      sheet.addRow([t("dataDownload.dataType.titleEcoEnergy.text")]),
      headerRows
    );
    if (!_.isEmpty(productDetailsData?.ecoEnergy)) {
      Object.keys(productDetailsData?.ecoEnergy).forEach((ele: any) => {
        sheet.addRow([ele, productDetailsData?.ecoEnergy[ele]]);
      });
    }

    //Global product page
    setMainHeaderStyle(
      sheet.addRow([t("dataDownload.dataType.titlePermalinkUrl.text")]),
      headerRows
    );

    if (productDetailsData?.permalinkUrl) {
      sheet.addRow([
        t("dataDownload.dataType.permalinkUrl.text"),
        productDetailsData?.permalinkUrl,
      ]);
    }

    //Technical specification
    setMainHeaderStyle(
      sheet.addRow([t("dataDownload.dataType.titleTechSpecs.text")]),
      headerRows
    );
    if (productDetailsData?.techSpecs?.length > 0) {
      productDetailsData?.techSpecs.forEach((ele: any) => {
        sheet.addRow([
          ele?.displayName,
          ele?.specs?.[0]?.name
            ? ele?.specs?.[0]?.name
            : ele?.specs?.[0]?.displayName,
          ele?.specs?.[0]?.value?.toString(),
        ]);
        for (let i = 1; i < ele?.specs?.length; i++) {
          sheet.addRow([
            "",
            ele?.specs?.[i]?.name
              ? ele?.specs?.[i]?.name
              : ele?.specs?.[i]?.displayName,
            ele?.specs?.[i]?.value?.toString(),
          ]);
        }
      });
    }

    //key tech specification
    setMainHeaderStyle(
      sheet.addRow([
        t("dataDownload.dataType.titleKeyTechSpecs.text"),
        t("dataDownload.dataType.displayName.text"),
        t("dataDownload.dataType.value.text"),
      ]),
      headerRows
    );
    if (productDetailsData?.keyTechSpecs?.length > 0) {
      productDetailsData?.keyTechSpecs.forEach((ele: any) => {
        sheet.addRow(["", ele?.displayName, ele?.value?.toString()]);
      });
    }

    //Icon description
    setMainHeaderStyle(
      sheet.addRow([t("dataDownload.dataType.titleIconDescription.text")]),
      headerRows
    );
    if (productDetailsData?.iconDescription?.length > 0) {
      productDetailsData?.iconDescription.forEach((ele: any) => {
        sheet.addRow(["", ele]);
      });
    }

    //Pro Benefit
    setMainHeaderStyle(
      sheet.addRow([t("dataDownload.dataType.titleProBenefit.text")]),
      headerRows
    );
    if (productDetailsData?.proBenefit?.length > 0) {
      productDetailsData?.proBenefit.forEach((ele: any) => {
        sheet.addRow(["", ele?.bodyCopy]);
      });
    }

    //Pro Overview
    setMainHeaderStyle(
      sheet.addRow([t("dataDownload.dataType.titleProOverview.text")]),
      headerRows
    );
    if (productDetailsData?.proOverview?.length > 0) {
      productDetailsData?.proOverview.forEach((ele: any) => {
        sheet.addRow(["", ele?.bodyCopy]);
      });
    }

    //related products
    setMainHeaderStyle(
      sheet.addRow([t("dataDownload.dataType.titleRelatedProducts.text")]),
      headerRows
    );
    if (productDetailsData?.accessories?.length > 0) {
      productDetailsData?.accessories.forEach((ele: any) => {
        sheet.addRow(["", ele?.productDisplayName, ele?.headlineDescription]);
      });
    }

    //set the width of columns
    for (let i = 1; i <= sheet.actualColumnCount; i++) {
      const col = sheet.getColumn(i);
      col.width = 30;
    }

    //set the background color for subheaders
    for (let i = 1; i <= sheet.actualRowCount; i++) {
      if (!headerRows.includes(i)) {
        const row = sheet.getRow(i);
        row.getCell(1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "969696" },
          bgColor: { argb: "969696" },
        };
        row.getCell(1).font = {
          bold: true,
        };
      }
    }
  };

  const setMainHeaderStyle = (row: any, headerRows: any) => {
    headerRows.push(row?._number);
    row.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "000000" },
      bgColor: { argb: "000000" },
    };
    row.font = {
      color: { argb: "FFFFFF" },
      bold: true,
    };
  };

  const prepareDigitalAssetCsvData = () => {
    let header = Object.keys(productDetailsData);
    let data: any = [];
    Object.keys(productDetailsData).forEach((ele: any) => {
      switch (ele) {
        case "topFeatures":
        case "inTheBox":
        case "awards":
        case "expertReviews":
        case "iconDescription":
          productDetailsData[ele]?.length > 0
            ? data.push(productDetailsData[ele]?.join("|"))
            : data.push("");
          break;
        case "featureBenefits":
          let bene: any = [];
          if (productDetailsData[ele]?.length > 0) {
            productDetailsData[ele]?.forEach((feature: any) => {
              let row: any = [];
              Object.keys(feature)?.forEach((key: any) => {
                row.push(key + ":" + feature[key]);
              });
              bene.push(row?.join("~"));
            });
            data.push(bene?.join("|"));
          } else {
            data.push("");
          }
          break;
        case "images":
          if (!_.isEmpty(productDetailsData[ele])) {
            let imageList: any = [];
            Object.keys(productDetailsData[ele])?.forEach((type: any) => {
              imageList.push(
                type + ":" + productDetailsData?.images[type]?.join("~")
              );
            });
            data.push(imageList?.join("|"));
          } else {
            data.push("");
          }
          break;
        case "videos":
          if (!_.isEmpty(productDetailsData[ele])) {
            data.push(
              `externalUrl:${productDetailsData[ele]?.[0]?.videoFamily?.externalUrl}`
            );
          } else {
            data.push("");
          }
          break;
        case "ecoEnergy":
          if (!_.isEmpty(productDetailsData[ele])) {
            let energyDetails: any = [];
            Object.keys(productDetailsData[ele]).forEach((key: any) => {
              energyDetails.push(key + ":" + productDetailsData[ele][key]);
            });
            data.push(energyDetails?.join("|"));
          } else {
            data.push("");
          }
          break;
        case "techSpecs":
          if (productDetailsData[ele]?.length > 0) {
            let specList: any = [];
            productDetailsData[ele].forEach((mainSpec: any) => {
              let spec: any = [];
              mainSpec?.specs?.forEach((subSpec: any) => {
                spec.push(mainSpec?.displayName);
                spec.push(subSpec?.displayName);
                spec.push(subSpec?.value?.toString());
              });
              specList.push(spec?.join("~"));
            });
            data.push(specList?.join("|"));
          } else {
            data.push("");
          }
          break;
        case "keyTechSpecs":
          if (productDetailsData[ele]?.length > 0) {
            let specList: any = [];
            productDetailsData[ele].forEach((mainSpec: any) => {
              let spec: any = [];
              spec.push(mainSpec?.displayName);
              spec.push(mainSpec?.value?.toString());
              specList.push(spec?.join("~"));
            });
            data.push(specList?.join("|"));
          } else {
            data.push("");
          }
          break;
        case "proBenefit":
        case "proOverview":
          if (productDetailsData[ele]?.length > 0) {
            let resultList: any = [];
            productDetailsData[ele].forEach((res: any) => {
              resultList.push(res?.bodyCopy);
            });
            data.push(resultList?.join("|"));
          } else {
            data.push("");
          }
          break;
        case "accessories":
          if (productDetailsData[ele]?.length > 0) {
            let resultList: any = [];
            productDetailsData[ele].forEach((res: any) => {
              let subList: any = [];
              subList.push(res?.productDisplayName);
              subList.push(res?.headlineDescription);
              resultList.push(subList?.join("~"));
            });
            data.push(resultList?.join("|"));
          } else {
            data.push("");
          }
          break;
        default:
          data.push(
            productDetailsData[ele] ? productDetailsData[ele]?.toString() : ""
          );
      }
    });
    return (
      header +
      "\n" +
      data?.map((item: any) => {
        return item.replaceAll(",", ";");
      })
    );
  };

  return (
    <>
      <Box className={styles.mainContainer}>
        <RadioGroup
          sx={{ marginBottom: "20px" }}
          value={selectedFileType}
          onChange={(e: any) => {
            setSelectedFileType(e.target.value);
          }}
        >
          {fileTypes?.map((type: any, i: any) => (
            <FormControlLabel
              value={type}
              control={<Radio size="small" />}
              label={type}
              key={i}
            />
          ))}
        </RadioGroup>
        <CustomDropDown
          id="language"
          value={selectedLanguage}
          handleChange={(e: any) => {
            setSelectedLanguage(e.target.value);
          }}
          menuOptions={languages}
          label={t("dataDownload.selectLanguage.title")}
          module="debitNoteCreation"
        />
        {modalDetails?.queryType === "digitalAsset" && (
          <Box className={styles.infoContainer} mt={2}>
            <Typography
              component="div"
              variant="body2"
              color="text.secondary"
              dangerouslySetInnerHTML={{
                __html: modalDetails?.isVisibleBaseLink
                  ? t("dataDownload.baseInformation.text")
                  : t("dataDownload.baseInformationDisabled.text"),
              }}
            ></Typography>
          </Box>
        )}

        {displayRetailPriceWarning && (
          <Box className={styles.infoContainer} mt={2}>
            <Typography
              component="div"
              variant="body2"
              color="text.secondary"
              dangerouslySetInnerHTML={{
                __html: t("dataDownload.disclaimer.retailPrice.text"),
              }}
            ></Typography>
          </Box>
        )}

        <Box className={styles.downloadButton}>
          <CustomButton
            label={t("dataDownload.download.title")}
            color="primary"
            onClick={() => downloadData()}
            margin={0}
          />
        </Box>
      </Box>
    </>
  );
};
