import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ArticlesList } from "../../cms/articlesList";

export const Help = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Typography
        variant="subtitle1"
        pb={3}
        color="primary"
      >
        {t("dnet.help.section")}
      </Typography>
      <ArticlesList parent="Help" />
    </div>
  );
};
