import { Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { mimeTypeImageMapping } from "../../helper/util";
import styles from "./relatedItemAndPriceInfoTemplate.module.scss";

export const RelatedItemAndPriceInfoTemplate = ({ articles }: any) => {
  const navigate = useNavigate();
  const setImageMimeType = (mimeType: any) => {
    if (mimeTypeImageMapping(mimeType) !== "") {
      const image = require("../../../assets/images/" +
        mimeTypeImageMapping(mimeType));
      return image;
    }
    return "";
  };
  const openNewTab = (url: any) => {
    window.open(url, "_blank");
  };
  return (
    <div>
      {articles?.length > 0 &&
        articles?.map((article: any) => {
          return (
            article?.["related_items"]?.length > 0 &&
            article?.["related_items"]?.map((item: any, key: any) => {
              return item?.acf_fc_layout === "add_article" ? (
                <div
                  key={key}
                  className={styles.boxStyle}
                  style={{ width: "93%", border: 0 }}
                >
                  <div className={styles.innerBoxStyle}>
                    <img
                      src={
                        item?.article_thumbnail_image &&
                        Object.keys(item?.article_thumbnail_image)?.length >
                          0 &&
                        Object.keys(item?.article_thumbnail_image?.sizes)
                          ?.length > 0
                          ? item?.article_thumbnail_image?.sizes?.thumbnail !==
                              null &&
                            item?.article_thumbnail_image?.sizes?.thumbnail !==
                              ""
                            ? item?.article_thumbnail_image?.sizes?.thumbnail
                            : setImageMimeType(
                                item?.article_thumbnail_image?.mime_Type
                              )
                          : item?.article_thumbnail_image?.url
                      }
                      alt="Thumbnail"
                      style={{
                        width: "66px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        item?.article_attachment !== null &&
                        item?.article_attachment
                          ? openNewTab(item?.article_attachment?.url)
                          : item?.article_url === ""
                          ? navigate("/Details", {
                              state: {
                                selectedArticle: article,
                              },
                            })
                          : openNewTab(item?.article_url);
                      }}
                    />
                    <div>
                      <div className={styles.titleSection}>
                        <Typography
                          variant="body2"
                          color="primary"
                          mb={1}
                          className={styles.titleLink}
                          onClick={() => {
                            item?.article_attachment !== null &&
                            item?.article_attachment
                              ? openNewTab(item?.article_attachment?.url)
                              : item?.article_url === ""
                              ? navigate("/Details", {
                                  state: {
                                    selectedArticle: article,
                                  },
                                })
                              : openNewTab(item?.article_url);
                          }}
                        >
                          {item?.article_title}
                        </Typography>
                      </div>
                      <Typography
                        variant="caption"
                        dangerouslySetInnerHTML={{
                          __html: item?.article_long_title?.toString(),
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                item?.acf_fc_layout === "add_attachmentfile" && (
                  <div
                    className={styles.boxStyle}
                    style={{ width: "93%", border: 0 }}
                  >
                    <div className={styles.innerBoxStyle}>
                      <img
                        src={setImageMimeType(item?.file_upload?.mime_type)}
                        style={{
                          width: "66px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          openNewTab(item?.file_upload?.url);
                        }}
                        alt="Mime Type"
                      />
                      <div>
                        <div className={styles.titleSection}>
                          <Typography
                            variant="body2"
                            color="primary"
                            mb={2}
                            className={styles.titleLink}
                            onClick={() => {
                              openNewTab(item?.file_upload?.url);
                            }}
                          >
                            {item?.file_upload?.title}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              );
            })
          );
        })}
    </div>
  );
};
