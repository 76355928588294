import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../atoms/customButton";
import CustomTextField from "../../../atoms/customTextField";
import styles from "./specialInstructions.module.scss";
import CloseIcon from "@mui/icons-material/Close";

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
  paddingBottom: "45px",
  textAlign: "left",
  overflowY: "scroll",
  height: "70%",
};

export const SpecialInstructions = ({
  openModal,
  closeModal,
  sendSpecialInstructions,
  specialInstructionsData,
}: any) => {
  const { t } = useTranslation();
  const warningMsg = t("dnet.special_instructions_warning.text");
  const [specialInstructions, setSpecialInstructions] = useState<any>(specialInstructionsData);
  const handleSpecialInstructions = (key: any, fieldName: any, data: any) => {
    let setUpdatedValues = [...specialInstructions];
    if (fieldName === "check") {
      setUpdatedValues[key] = {
        ...setUpdatedValues?.[key],
        [fieldName]: data,
        fieldValue: "",
      };
    } else {
      setUpdatedValues[key] = {
        ...setUpdatedValues?.[key],
        [fieldName]: data,
      };
    }
    setSpecialInstructions(setUpdatedValues);
  };
  return (
    <div>
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div className={styles.modalHeader}>
            <Typography variant="subtitle2" color="primary">
              {t("dnet.special_instructions.text")}
            </Typography>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </div>
          <div>
            <div className={styles.warningMsg}>
              <Typography variant="caption">{warningMsg}</Typography>
            </div>
            {specialInstructions?.map((item: any, key: any) => {
              return (
                <Grid container className={styles.boxDesign}>
                  <Grid item xs={12} md={6} className={styles.innerBox}>
                    <Checkbox
                      id={item?.id}
                      checked={item?.check}
                      onChange={(e: any) => {
                        handleSpecialInstructions(
                          key,
                          "check",
                          e.target.checked
                        );
                      }}
                      size="small"
                      sx={{ padding: 0 }}
                    />
                    <Typography variant="body2">{item?.label}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomTextField
                      label=""
                      value={item?.fieldValue}
                      changeHandler={(e: any) => {
                        handleSpecialInstructions(
                          key,
                          "fieldValue",
                          e.target.value
                        );
                      }}
                      disabled={!item?.check}
                    />
                  </Grid>
                </Grid>
              );
            })}
            <CustomButton
              id="cancel"
              label={t("dnet.cancel.text")}
              onClick={() => {
                closeModal();
              }}
              margin="20px 0"
            />
            <CustomButton
              id="update"
              label={t("dnet.update.text")}
              onClick={() => {
                sendSpecialInstructions(specialInstructions);
                closeModal();
              }}
              margin="20px"
              color="info.main"
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
};
