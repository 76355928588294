import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import styles from "./customCard.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchProductDetailsData,
  setCurrentProductSku,
} from "../../../../state/actions";
import { ROUTES_LABEL } from "../../../../constants/routesLabels";
import { useTranslation } from "react-i18next";
var _ = require("lodash");
const noProduct = require("../../../../assets/images/no_prod_tn.gif");

const CustomCard = (product: any) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const redirectToDetails = (product: any) => {
    dispatch(fetchProductDetailsData({ productNames: product?.product?.index?.materialName }));
    dispatch(setCurrentProductSku(product));
    navigate(`/${ROUTES_LABEL.PRODUCT_DETAILS}`);
  };

  const redirectToNewProduct = () => {
    navigate(`/${ROUTES_LABEL.NEW_PRODUCTS}`);
  };

  const descriptionHanlder = (descString: any) => {
    if(descString?.length > 55) {
      return descString?.substring(0, 55) + "...";
    }
    else
      return descString;
  }

  return (
    <Card className={styles.card}>
      <br />
      <Link
        style={{ cursor: "pointer" }}
        underline="hover"
        onClick={() => {
          redirectToDetails(product);
        }}
      >
        <CardMedia
          className={styles.cardMedia}
          sx={{ width: 99 }}
          component="img"
          height={82}
          image={
            product?.product?.index?.thumbnail
              ? product?.product?.index?.thumbnail
              : noProduct
          }
          alt={product?.product?.index?.displayName}
        />
        <CardContent className={styles.cardContent}>
          <Typography gutterBottom variant="subtitle2" component="div">
            {product?.product?.index?.displayName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {product?.product?.index?.headlineDescription
              ? descriptionHanlder(product?.product?.index?.headlineDescription)
              : t("dnet.empty_description.txt")}
          </Typography>
        </CardContent>
      </Link>
      <CardActions
        style={{ justifyContent: "flex-end" }}
      >
        <Button
          onClick={() => {
            redirectToNewProduct();
          }}
          size="medium"
        >
          <Typography variant="caption">{t("dnet.more.text")}</Typography>
        </Button>
      </CardActions>
    </Card>
  );
};

export default CustomCard;
