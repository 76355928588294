import React from "react";
import { Reporting } from "../reporting";

export const OrderChangeReport = () => {
  return (
    <div>
      <Reporting parent="OrderChangeReport" />
    </div>
  );
};
