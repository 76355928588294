import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import './createEnquiryTable.scss';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import './createEnquiryTable.scss';
import moment from "moment";
var currencyFormatter = require('currency-formatter');
  

const CreateEnquiryTable = () => {
  const { t } = useTranslation();
  const [rows, setRows] = useState<any>([]);
  const createEnquiryTableData = useSelector(
    (state: any) => state?.gazelleReducer?.enquiryDetailsData
  );

  interface Column {
    id: 'name' | 'dueDate' | 'amount' | 'openAmount';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
  }
  
  const columns: readonly Column[] = [
    {id: 'name', label: t("gzl.inv_credit.text")},
    {id: 'dueDate', label: t("gzl.date.text"), minWidth:150},
    {id: 'amount', label: t("gzl.amount.txt"), minWidth:150},
    {id: 'openAmount', label: t("gzl.outstd_blnc_less_disc.text")}
  ];
  
  interface Data {
    name: any,
    dueDate: any,
    amount: any,
    openAmount: any,
  }

  function createData(
    name: any,
    dueDate: any,
    amount: any,
    openAmount: any,
  ): Data {
    return { name, dueDate, amount, openAmount};
  }

  const createRowData=()=>{
    // Rows data creation from API response
    let data = createEnquiryTableData
    let rowsdata = []
    rowsdata.push(
      createData(
        data?.name, 
        moment(data?.dueDate).format("DD.MM.YYYY"), 
        data?.amount, 
        data?.openAmount
      )
    )
    setRows([...rowsdata])
    
  }

  useEffect(()=>{
    createRowData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[createEnquiryTableData])

    return (
        <>
          <TableContainer component={Paper} className="tableContainerHeader">
            <Table sx={{ minWidth: 650 }} aria-label="Payments Table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    column.id==="openAmount" &&
                    createEnquiryTableData?.amount !== createEnquiryTableData?.openAmount ? 
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                        {column.label}
                    </TableCell>
                    : column.id!=="openAmount"?
                    <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                      {column.label}
                  </TableCell>:null
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                rows?.length > 0 ? rows.map((row:any, key:any) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return column.id==="openAmount" && 
                            createEnquiryTableData?.amount !== createEnquiryTableData?.openAmount ? (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          ): column.id!=="openAmount" && column.id === "amount"?(
                            <TableCell key={column.id} align={column.align}>
                              {value}&nbsp;
                              {currencyFormatter.findCurrency(createEnquiryTableData?.currency)?.symbol}
                            </TableCell>
                          ): column.id!=="openAmount"?(
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          ):
                            null
                        })}
                      </TableRow>
                    );
                  }):<TableRow>
                        <TableCell colSpan={columns.length} align={"center"} className="noRecFound">
                          {t("gzl.no_data_available.text")}
                        </TableCell>
                    </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );
}

export default CreateEnquiryTable;