import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import MyQuotationTable from "./myQuotationTable";
import styles from "./myQuotations.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getContextDetails, getQuotationsData } from "../../state/actions";
import { CONTEXT_ORDER } from "../../constants/commonConstants";

export const MyQuotations = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const quotationData = useSelector(
    (state: any) => state?.myQuotationsReducer?.quotationData
  );
  useEffect(() => {
    dispatch(getQuotationsData({}));
    dispatch(getContextDetails({ key: CONTEXT_ORDER?.GET_QUOTATIONS_PAGE }));
  }, []);

  return (
    <div>
      <Typography
        variant="subtitle1"
        className={styles.header}
        pb={3}
        color="primary"
      >
        {t("dnet.salesforce_quotation_title.text")}
      </Typography>
      <Typography variant="body2">
        {t("dnet.salesforce_quotation_description.text")}
      </Typography>
      {quotationData?.quotations?.length > 0 ? (
        <Box className={styles.tableContainer}>
          <MyQuotationTable />
        </Box>
      ) : (
        <Typography variant="body2" className={styles.noDataMsg}>
          {t("dnet.salesforce_quotation_message.text")}
        </Typography>
      )}
    </div>
  );
};
