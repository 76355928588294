import { commonConstant } from "../constants/common-constants";

export const initialState = {
  changePasswordResponse: {},
};

const changePasswordReducer = (state = initialState, action: any) => {
  switch (action?.type) {
    case commonConstant.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordResponse: action?.payload,
      };
    default:
      return state;
  }
};

export default changePasswordReducer;
