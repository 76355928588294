export const ROUTES_LABEL = {
    SONY_HOMEPAGE: "Homepage",
    HOME: "Account_Information_Home",
    OPEN_ITEMS: "Open_Items",
    PAID_ITEMS: "Paid_Items",
    PAYMENTS: "Payments",
    STATEMENTS: "Statements",
    DISPUTED_ITEMS: "Disputed_Items",
    DEBIT_NOTE_CREATION: "Debit_Note_Creation",
    CREDIT_MANAGEMENT: "Credit_Management",
    YOUR_REFERENCE_SEARCH: "Your_Reference_Search",
    PAID_ITEM_DETAILS: "Paid_Item_Details",
    PAYMENT_DETAILS: "Payment_Details",
    CREATE_ENQUIRY: "Create_Enquiry",
    MAKE_PAYMENT: "Make_Payment",
    REPLY_ENQIRY: "Reply_Enquiry",
    LOGIN: "Login",
    DEALER_SELECTION: "Dealer_Selection",
    COUNTRY_SELECT: "Country_Select",
    REGISTRATION: "Registration",
    RESET_PASSWORD: "Reset_Password",
    UPDATE_PROFILE: "Update_Profile",
    PRICE_CHANGES: "Price_Changes",
    CHANGE_DEALER: "Change_Dealer",
    PRODUCT_RANGE: "Product_Range",
    PROD_CONTENT_DETAIL: "Prod_Content_Detail",
    PRODUCT_DETAILS: "Prod_Details",
    PRODUCT_COMPARE: "Prod_Compare",
    REPORTING: "Reporting",
    ORDER_STATUS: "Order_Status",
    MANAGE_REPORTS: "Manage_Reports",
    PRODUCT_LISTS: "Product_Lists",
    ORDER_ENTRY: "Order_Entry",
    QUOTATION: "Quotation",
    SPECIAL_INSTRUCTIONS: "Special_Instructions",
    RECEIPT_OF_ORDER: "Receipt_Of_Order",
    GRADED_STOCK: "Graded_Stock",
    ORDER_MANAGEMENT: "Order_Management",
    DATA_DOWNLOAD: "Data_Download",
    DIGITAL_ASSET: "Digital_Asset",
    PRODUCT_DATA: "Product_Data",
    PRODUCT_VISIBILITY: "Product_Visibility",
    SALES_REP_VISIBILITY: "SalesRep_Visibility",
    CONTACT: "Contact",
    LINKS: "Links",
    HELP: "Help",
    PRODUCTS: "Products",
    NEW_PRODUCTS: "New_Products",
    GLOBAL_SEARCH: "Search",
    SERVCIE_SUPPORT: "Service_&_Support",
    GENERAL_INFORMATION: "General_Information",
    DETIALS: "Details",
    TERMS_AND_CONDITION_OF_WEBSITE_USE: "Terms_and_Condition_of_Website_use",
    SERP: "Sony_Europe_Retail_Partner",
    MY_QUOTATIONS: "My_Quotations",
    QUOTATIONS_DETAILS: "Quotations_Details",
    PREMIUM_SERVICES: "Premium_Services",
    ORDER_CHANGE_REPORT: "Order_Change_Report",
    ORDER_CHANGE_HISTORY: "Order_Change_History",
    PRIME_SUPPORT_REGISTRATION: "PrimeSupport_Registration",
    RESELLER_PROD_CONTENT_DETAILS: "Reseller_Prod_Content_Details",
  };