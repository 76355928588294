import { PERMA_LINK } from "../../constants/commonConstants";
import { commonConstant } from "../constants/common-constants";
let _ = require("lodash");

export const initialState = {
  selectedProducts: [],
  productContentData: {},
  productDetailsData: {},
  currentProductSku: {},
  prodCompareData: {},
  productCompareList: [],
  liveAvailability:{},
  productsByMaterialName:[],
  sendProductDetailsMail:{},
  productImages:{},
};

const ProductReducer = (state = initialState, action: any) => {
  switch (action?.type) {
    case commonConstant.GET_PRODUCTS_BY_MATERIAL_SUCCESS:
      return{
        ...state,
        productsByMaterialName:action?.payload,
      }
    case commonConstant.GET_CATEGORY_PRODUCT_SUCCESS:
      return{
        ...state,
        productContentData:action?.payload,
      }
    case commonConstant.SELECTED_PRODUCTS:
      return {
        ...state,
        selectedProducts: action?.payload,
      };
    case commonConstant.PRODUCT_CONTENT_DATA:
      return {
        ...state,
        productContentData: action?.payload,
      };
    case commonConstant.PRODUCT_DETAILS_DATA_SUCCESS:
    if (action?.payload?.compare) {
        let compareList: any = [];
        action?.payload?.response.forEach((ele: any) => {
          compareList.push(
            ele?.publication === "global"
              ? extractProductDetails(ele?.data,ele?.categoryRootPath)
              : productParser(ele?.data,ele?.categoryRootPath)
          );
        });
        return {
          ...state,
          productCompareList: compareList,
        };
      } else {
        let publication: string = action?.payload?.response[0]?.publication;
        let productDetailsData: any = {};
        if (action?.payload?.response && !_.isEmpty(action?.payload?.response)) {
          productDetailsData =
            publication === "global"
              ? extractProductDetails(action?.payload?.response[0]?.data,action?.payload?.response[0]?.categoryRootPath)
              : productParser(action?.payload?.response[0].data,action?.payload?.response[0]?.categoryRootPath);
            }
        return {
          ...state,
          productDetailsData,
        };
      }
    case commonConstant.SET_SELECTED_PRODUCTS_DOWNLOAD:
      return {
        ...state,
        selectedProducts: action?.payload,
      };

    case commonConstant.SET_CURRENT_PRODUCT_SKU:
      return {
        ...state,
        currentProductSku: action?.payload,
      };
    case commonConstant.SET_PROD_COMPARE_DATA:
      return {
        ...state,
        prodCompareData: action?.payload,
      };
    case commonConstant.CLEAR_PROD_COMPARE_LIST:
      return {
        ...state,
        productCompareList: [],
      };
    case commonConstant.SET_PRODUCT_DETAILS_DATA:
      return {
        ...state,
        productDetailsData: action?.payload,
      };
    case commonConstant.FETCH_LIVE_AVAILABILITY_SUCCESS:
      return {
        ...state,
        liveAvailability: action?.payload,
        
      }
    case commonConstant.SEND_PRODUCT_DETAILS_MAIL_SUCCESS:
      return {
        ...state,
        sendProductDetailsMail: action?.payload,
      }
   case commonConstant.GET_PRODUCT_IMAGES_SUCCESS:
      return {
        ...state,
        productImages: action?.payload,
      }
    default:
      return state;
  }
};
const productParser = (data: any,category:any) => {
  const productSheet: any = {};
  productSheet.materialName = data?.materialName;
  productSheet.publication = data?.fileMeta?.publication;
  let lang = data?.fileMeta?.language;
  let country = data?.fileMeta?.country;
  productSheet.locale = lang + "_" + country;
  productSheet.modelName = data?.catalogName;
  productSheet.categoryName = category?.[1];
  productSheet.eanCode = "";
  productSheet.marketingProductName = data?.friendlyName;
  productSheet.factualShortDescription = data?.factualShortDescription;
  productSheet.elevatorPitch = data?.headline;
  productSheet.sunriseDateTime = data?.introductionDate;
  productSheet.sunsetDateTime = "";
  productSheet.topFeatures = data?.reasonsToBuy?.statements;

  let marketingFactsGlobal: { statement: string; benefit: string }[] = [];
  marketingFactsGlobal = data?.globalMarketingFacts?.map((ele: any) => {
    return {
      headline: ele?.statement,
      bodyCopy: ele?.benefit,
    };
  });

  let marketingFactsLocal: { statement: string; benefit: string }[] = [];
  marketingFactsLocal = data?.localMarketingFacts?.map((ele: any) => {
    return {
      headline: ele?.statement,
      bodyCopy: ele?.benefit,
    };
  });
  let marketingFacts: { statement: string; benefit: string }[] = [];
  if (marketingFactsGlobal && marketingFactsLocal) {
    marketingFacts = marketingFactsGlobal.concat(marketingFactsLocal);
  } else {
    marketingFacts = marketingFactsLocal
      ? marketingFactsLocal
      : marketingFactsGlobal;
  }

  if (marketingFacts === undefined) {
    marketingFacts = data?.marketingFeatures?.map((ele: any) => {
      return {
        bodyCopy: ele?.value,
      };
    });
  }

  productSheet.featureBenefits = marketingFacts;
  productSheet.keyTechSpecs = [];
  productSheet.inTheBox = data?.relations?.inTheBox;
  productSheet.iconDescription = [];
  let imageMap: any = {};
  data?.digitalAssetGroups && Object.keys(data?.digitalAssetGroups)?.forEach((ele: any) => {
    imageMap[ele] = data?.digitalAssetGroups[ele]?.items?.map(
      (ele1: any) => "https:" + ele1?.url
    );
  });

  productSheet.images = imageMap;

  productSheet.videos = data?.videos?.productVideos?.[0]?.videoFamily?.externalUrl ? data?.videos?.productVideos: "";
  productSheet.techSpecs = data?.technicalFeatureGroups?.map((ele: any) => {
    return {
      displayName: ele?.groupName ? ele?.groupName : ele.name,
      specs: ele?.technicalFeatures,
      value: ele?.value ? ele?.value : [],
    };
  });
  let accessories: string[] = [];
  accessories = data?.relations?.accessories;

  let ProAccessory: string[] = [];
  ProAccessory = data?.relations?.["Pro Accessory"];

  let mergedAccessory: string[] = [];

  if (accessories && ProAccessory) {
    mergedAccessory = accessories.concat(ProAccessory);
  } else {
    mergedAccessory = accessories ? accessories : ProAccessory;
  }
  productSheet.accessories = mergedAccessory;

  productSheet.awards = [];
  productSheet.expertReviews = [];

  productSheet.proBenefit = data?.atomizedData?.professionalBenefits?.map(
    (ele: any) => {
      return {
        headline: ele?.statement,
        bodyCopy: ele?.description,
      };
    }
  );

  productSheet.proOverview = data?.atomizedData?.overviews?.map((ele: any) => {
    return {
      bodyCopy: ele?.summary,
    };
  });
  //Deprecated
  productSheet.manuals = [];
  productSheet.permalinkUrl = "";
  productSheet.ecoEnergy = {};

  return productSheet;
};
const extractProductDetails = (data: any,category:any) => {
  const productSheet: any = {};
  productSheet.publication="GLOBAL";
  productSheet.materialName = data?.skuName;
  productSheet.locale = data?.locale;
  productSheet.modelName = data?.modelName;
  productSheet.topFeatures = data?.topFeatures;
  productSheet.marketingProductName = data?.marketingProductName;
  productSheet.factualShortDescription = data?.factualShortDescription;
  productSheet.elevatorPitch = data?.elevatorPitch;
  productSheet.iconDescription = data?.featureIcons?.map(
    (ele: any) => ele.copy
  );
  productSheet.inTheBox = data?.inTheBox;
  productSheet.keyTechSpecs = data?.keyTechSpecs;
  productSheet.techSpecs = data?.techSpecs;
  productSheet.sunriseDateTime = data?.sunriseDateTime;
  productSheet.sunsetDateTime = data?.sunsetDateTime;
  productSheet.awards = data?.awardsAndReviews?.awards?.map((ele: any) =>
    ele?.image?.imageFamily?.images?.desktop?.externalUrl
      ? "https:" + ele?.image?.imageFamily?.images?.desktop?.externalUrl
      : ""
  );
  productSheet.expertReviews = data?.awardsAndReviews?.expertReviews?.map(
    (ele: any) =>
      ele?.image?.imageFamily?.images?.desktop?.externalUrl
        ? "https:" + ele?.image?.imageFamily?.images?.desktop?.externalUrl
        : ""
  );
  productSheet.eanCode = data?.eanCodes
    ? data?.eanCodes?.length > 0
      ? data?.eanCodes[0]
      : ""
    : "";
  productSheet.featureBenefits = data?.featureBenefits?.map((ele: any) => {
    return {
      headline: ele?.headline,
      bodyCopy: ele?.bodyCopy,
      shot: ele?.shot?.imageFamily?.images?.desktop?.externalUrl? "https:" + ele?.shot?.imageFamily?.images?.desktop?.externalUrl: "",
    };
  });
  let imageMap: any = {};
  data?.images && Object.keys(data?.images)?.forEach((ele: any) => {
    imageMap[ele] = data?.images[ele]?.map(
      (ele1: any) => "https:" + ele1?.imageFamily?.images?.desktop?.externalUrl
    );
  });
  productSheet.images = imageMap;
  productSheet.videos = data?.videos?.productVideos?.[0]?.videoFamily?.externalUrl ? data?.videos?.productVideos: "";

  let obj = {
    ecoEnergyLevel: data?.energyLevel?.displayName,
    ecoScreenSize: data?.sBraviaTvEcoEnergyScrSizMeasDiag,
    ecoPowerConsumptionOnMode: data?.sBraviaTvEcoEnergyPowerCompOnStan,
    ecoAnnualEnergyConsumption: data?.sBraviaTvEcoEnergyAnnualEnerCons,
    ecoPowerConsumptionStandyBy: data?.sBraviaTvEcoEnergyPowerComp,
    ecoDisplayResolution: data?.sBraviaTvDisplayResolution?.toString(),
    ecoMessageShots: imageMap["ecoMessageShots"],
  };

  if (
    (obj.ecoEnergyLevel &&
      obj.ecoScreenSize &&
      obj.ecoPowerConsumptionOnMode &&
      obj.ecoAnnualEnergyConsumption &&
      obj.ecoPowerConsumptionStandyBy &&
      obj.ecoDisplayResolution) ||
    !_.isEmpty(obj.ecoMessageShots)
  ) {
    productSheet.ecoEnergy = obj;
  } else {
    productSheet.ecoEnergy = {};
  }
  productSheet.categoryName = category?.[1];

  //Deprecated
  productSheet.manuals = [];

  let formatedProdName = data?.skuName
    ?.replace(/[?#[\]@!$&'()*+,;=]+/g, "-")
    ?.replace(/-+/g, "-")
    ?.replace(/ /, "-");
  let link = PERMA_LINK.replace("prodValue", formatedProdName).replace(
    "localeValue",
    data?.locale
  );
  productSheet.permalinkUrl = link;

  productSheet.proBenefit = data?.atomizedData?.professionalBenefits?.map(
    (ele: any) => {
      return {
        headline: ele?.statement,
        bodyCopy: ele?.description,
      };
    }
  );

  productSheet.proOverview = data?.atomizedData?.overviews?.map((ele: any) => {
    return {
      bodyCopy: ele?.summary,
    };
  });

  productSheet.accessories = data?.relatedProducts?.accessoryProducts?.map((item: any) => {
    return item?.product
  });
    return productSheet;
};

export default ProductReducer;
