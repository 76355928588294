import { Typography, Box, IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import './createEnquiry.scss';
import { useDispatch } from "react-redux";
import { getEnquiryTableDetailsData } from "../../../state/actions";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useLocation, useNavigate} from 'react-router-dom';
import CreateEnquiryTable from './createEnquiryTable';
import { DebitNoteCreation } from '../debit-note-creation';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';


export const CreateEnquiry = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    let invoiceId = location?.state?.invoiceId
    const backButtonText = t("gzl.back.text");

    const userDetails = useSelector(
      (state: any) => state?.commonReducer?.userDetails
    );

    const saveAccountDetails = useSelector(
      (state: any) => state?.commonReducer?.saveAccountDetails
    )

    const getAPIData = () => {
        dispatch(getEnquiryTableDetailsData({
            invoiceId,
            partnerKey: saveAccountDetails?.payerToAcc?.number,
        }));
    };

    useEffect(() => {
        if(!invoiceId){
          // Navigating back in case invoiceId not present
          navigate(-1)
        }
        getAPIData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <>
        <Box className="headerBox">
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIosIcon titleAccess={backButtonText} />
          </IconButton>
          <Typography
            sx={{ paddingBottom: "0px" }}
            variant="subtitle1"
            className="header"
            pb={3}
            color="primary"
          >
            {t("gzl.create_enquiry.text")}
          </Typography>
        </Box>
        <Box>
          <Box className="tableContainer">
            <div className="tableHeader">
              <Typography variant="body2">
                {t("gzl.create_enq_headline1.text")}
              </Typography>
            </div>
            <CreateEnquiryTable />
          </Box>
          <Box>
            <DebitNoteCreation enquiryCheck={true} invoiceId={invoiceId}/>
          </Box>
        </Box>
      </>
    );
}