import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import styles from "./creditTable.module.scss";
import { useSelector, useDispatch } from "react-redux";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { downloadInvoicePdf } from "../../../../state/actions";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import CustomRadioButton from "../../../atoms/customRadioButton";
import CustomTextField from "../../../atoms/customTextField";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { Link } from "react-router-dom";
import MoreTimeOutlinedIcon from "@mui/icons-material/MoreTimeOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import {useTranslation} from 'react-i18next';
import { getComparator, stableSort } from '../../../helper/util';
import moment from "moment";
var currencyFormatter = require('currency-formatter');


const CreditItemTable = () => {
  const {t} = useTranslation();
  const [rows, setRows] = useState<any>([]);
  const creditItemData = useSelector(
    (state: any) => state?.gazelleReducer?.creditItemData?.items
  );
  const netBalance = useSelector(
    (state: any) => state?.gazelleReducer?.creditItemData?.credits?.[0]
  );
  const salesAreaMapping = useSelector(
    (state: any) => state?.commonReducer?.salesAreaMapping
  );
  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.userDetails
  );
  const [payCheck, setPayCheck] = React.useState<any>("");
  const dispatch = useDispatch();
  const [payBalanceAmount, setPayBalanceAmount] = React.useState<any>("");
  const addReplyEnquiryText= t("gzl.add_reply_enquiry.text")
  const displayPdfText = t("gzl.display_pdf.text")
  const createEnquiryText = t("gzl.create_enquiry.text")
  const payPartialText = t("gzl.pay_partial.text")

  const handleRadioChange = (key: any) => {
    // Disable/Enable Partial Payment option for particular row 
    setPayCheck(key);
  };

  interface Column {
    id:
      | "radioButton"
      | "invoiceDate"
      | "invoiceId"
      | "documentType"
      | "yourReference"
      | "dueDate"
      | "amount"
      | "openAmount"
      | "paymentAmount"
      | "deliveryNumber"
      | "queries"
      | "disputeReference"
      | "discount"
      | "discountDate"
      | "currency"
      | "text";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
  }
  
  const columns: readonly Column[] = [
    { id: "radioButton", label: "" },
    { id: "invoiceDate", label: t("gzl.date.text"), minWidth: 80 },
    { id: "invoiceId", label: t("gzl.credit_note.text"), minWidth: 170 },
    { id: "documentType", label: t("gzl.doc_type.text"), minWidth: 150 },
    { id: "yourReference", label: t("gzl.your_ref.text") },
    { id: "dueDate", label: t("gzl.due_date.text") },
    { id: "amount", label: t("gzl.org_credit_value.text") },
    { id: "openAmount", label: t("gzl.outstanding_balance.text") },
    { id: "paymentAmount", label: t("gzl.payment_amount.text") },
    { id: "deliveryNumber", label: t("gzl.delivery_no.text") },
    { id: "queries", label: t("gzl.queries.text") },
    { id: "disputeReference", label: t("gzl.dispute_ref.text") },
    { id: "discount", label: t("gzl.disc_allowed.text") },
    { id: "discountDate", label: t("gzl.disc_expiry.text") },
    { id: "currency", label: t("gzl.currency.text") },
    { id: "text", label: t("gzl.additional_text.text") },
  ];
  
  interface Data {
    radioButton: any;
    invoiceDate: any;
    invoiceId: any;
    documentType: any;
    yourReference: any;
    dueDate: any;
    amount: any;
    openAmount: any;
    paymentAmount: any;
    deliveryNumber: any;
    queries: any;
    disputeReference: any;
    discount: any;
    discountDate: any;
    currency: any;
    text: any;
  }
  
  function createData(
    radioButton: any,
    invoiceDate: any,
    invoiceId: any,
    documentType: any,
    yourReference: any,
    dueDate: any,
    amount: any,
    openAmount: any,
    paymentAmount: any,
    deliveryNumber: any,
    queries: any,
    disputeReference: any,
    discount: any,
    discountDate: any,
    currency: any,
    text: any
  ): Data {
    return {
      radioButton,
      invoiceDate,
      invoiceId,
      documentType,
      yourReference,
      dueDate,
      amount,
      openAmount,
      paymentAmount,
      deliveryNumber,
      queries,
      disputeReference,
      discount,
      discountDate,
      currency,
      text,
    };
  }

  const createRowData = () => {
    // Table Row creation functionality from API response
    let data = creditItemData;
    let rowsdata = [];
    for (let i = 0; i < data?.length; i++) {
      rowsdata.push(
        createData(
          "",
          data[i]?.invoiceDate,
          data[i]?.invoiceId,
          data[i]?.documentType,
          data[i]?.yourReference,
          data[i]?.dueDate,
          data[i]?.amount,
          data[i]?.openAmount,
          data[i]?.paymentAmount,
          data[i]?.deliveryNumber,
          data[i]?.queries,
          data[i]?.disputeReference,
          data[i]?.discount,
          data[i]?.discountDate,
          data[i]?.currency,
          data[i]?.text
        )
      );
    }
    setRows([...rowsdata]);
  };

  useEffect(() => {
    if(creditItemData?.length>0)
    createRowData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditItemData]);

  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };
  const [order, setOrder] = useState<'asc' | 'desc'>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("invoiceDate");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    //Sorting table data on click of header
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const saveAccountDetails = useSelector(
    (state: any) => state?.commonReducer?.saveAccountDetails
  )

  const downloadPdf = (columnData: any) => {
    //PDF download
    dispatch(
      downloadInvoicePdf({
        language: userDetails?.language,
        username: userDetails?.userName,
        partnerKey: saveAccountDetails?.payerToAcc?.number,
        invoiceId: "SD_"+columnData?.invoiceId,
      })
    );
  };

  const fillPaymentAmount = (event: any) => {
    setPayBalanceAmount(event.target.value);
  };

  return (
    <div className={styles.tableContainer}>
      <TableContainer component={Paper} className={styles.tableContainerHeader}>
        <Table
          sx={{ minWidth: 650 }}
          aria-label="Credit item table"
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                return (column.id === "radioButton" ||
                  column.id === "paymentAmount") &&
                  salesAreaMapping?.payment_enabled === "true" ? (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ) : column.id === "disputeReference" &&
                  salesAreaMapping?.dispute_reference?.visible === "true" ? (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    sortDirection={orderBy === column.id ? order : false}
                    sx={{
                      backgroundColor: "#f9f6f6",
                      fontWeight: "bold",
                      color: "#727272",
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ) : (column.id === "discountDate" ||
                    column.id === "discount") &&
                    salesAreaMapping?.display_discount_enabled === "true" ? (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    sortDirection={orderBy === column.id ? order : false}
                    sx={{
                      backgroundColor: "#f9f6f6",
                      fontWeight: "bold",
                      color: "#727272",
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ) : column.id === "text" &&
                  salesAreaMapping?.text_visible === "true" ? (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    sortDirection={orderBy === column.id ? order : false}
                    sx={{
                      backgroundColor: "#f9f6f6",
                      fontWeight: "bold",
                      color: "#727272",
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ) : column.id !== "radioButton" &&
                  column.id !== "paymentAmount" &&
                  column.id !== "disputeReference" &&
                  column.id !== "discountDate" &&
                  column.id !== "discount" &&
                  column.id !== "text" ? (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    sortDirection={orderBy === column.id ? order : false}
                    sx={{
                      backgroundColor: "#f9f6f6",
                      fontWeight: "bold",
                      color: "#727272",
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ) : null;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {creditItemData?.length > 0 && rows?.length > 0 ? (
              stableSort(creditItemData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row:any, key:any) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return column.id === "invoiceId" ? (
                          <TableCell key={column.id} align={column.align}>
                            <span className={!row?.pdfAvailable ? styles.disableDownload : styles.pdfIcon}
                              title={!row?.pdfAvailable ? "" : displayPdfText}
                              onClick={(e: any) => {downloadPdf(row);}}>
                                {value}
                            </span>
                            {row?.overdue &&
                            <Tooltip title={t("gzl.overdue.text")} arrow>
                              <IconButton>
                                <InfoOutlinedIcon color="action" />
                              </IconButton>
                            </Tooltip>}
                            <IconButton 
                              className={styles.pdfIcon}
                              disabled={!row?.pdfAvailable}
                              onClick={(e: any) => {
                                downloadPdf(row);
                              }}
                            >
                              <PictureAsPdfOutlinedIcon
                                titleAccess={displayPdfText}
                              />
                            </IconButton>
                          </TableCell>
                        ) : (column.id === "amount" ||
                            column.id === "openAmount") &&
                          value < 0 ? (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ color: "red" }}
                          >
                            {value}
                          </TableCell>
                        ) : column.id === "disputeReference" &&
                          salesAreaMapping?.dispute_reference?.visible === "true" ? (
                          <TableCell key={column.id} align={column.align}>
                            {salesAreaMapping?.dispute_reference?.field ===
                            row?.xref1
                              ? row?.xref1
                              : row?.xref2}
                          </TableCell>
                        ) : column.id === "discountDate" &&
                          salesAreaMapping?.display_discount_enabled === "true" ? (
                          <TableCell key={column.id} align={column.align}>
                            {
                              row?.discount !== 0 && value && 
                              moment(value).format("DD.MM.YYYY")
                            }
                          </TableCell>
                        ) : column.id === "invoiceDate" || column.id === "dueDate" ? (
                          <TableCell key={column.id} align={column.align}>
                            {
                              value && 
                              moment(value).format("DD.MM.YYYY")
                            }
                          </TableCell>
                        ) : column.id === "discount" &&
                          salesAreaMapping?.display_discount_enabled === "true" ? (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        ) : column.id === "queries" &&
                          row?.disputed &&
                          salesAreaMapping?.history_icon?.openItems === "true" &&
                          salesAreaMapping?.history_icon?.creditItemsInOpenItems === "true" ? (
                          <TableCell key={column.id} align={column.align}>
                            <Link
                              to="/Reply_Enquiry"
                              state={{
                                invoiceId: row?.invoiceId,
                              }}
                            >
                              <IconButton>
                                {row?.newContact ? (
                                  <MoreTimeOutlinedIcon
                                    titleAccess={addReplyEnquiryText}
                                  />
                                ) : (
                                  <QueryBuilderIcon
                                    titleAccess={addReplyEnquiryText}
                                  />
                                )}
                              </IconButton>
                            </Link>
                          </TableCell>
                        ) : column.id === "queries" &&
                          salesAreaMapping?.create_enquiry?.openItems === "true" &&
                          salesAreaMapping?.create_enquiry?.creditItemsInOpenItems === "true" ? (
                          <TableCell key={column.id} align={column.align}>
                            <Link
                              style={{ color: "black" }}
                              to="/Create_Enquiry"
                              state={{
                                invoiceId: row?.invoiceId,
                              }}
                            >
                              <IconButton>
                                <NoteAddOutlinedIcon
                                  titleAccess={createEnquiryText}
                                />
                              </IconButton>
                            </Link>
                          </TableCell>
                        ) : column.id === "radioButton" &&
                          salesAreaMapping?.payment_enabled === "true" ? (
                          <TableCell key={column.id} align={column.align}>
                            {row?.payable && row?.openAmount > 0 &&
                              <CustomRadioButton
                                checked={payCheck === key}
                                handleRadioChange={() => handleRadioChange(key)}
                              />
                            }
                          </TableCell>
                        ) : column.id === "paymentAmount" &&
                          salesAreaMapping?.payment_enabled === "true" ? (
                          <TableCell key={column.id} align={column.align}>
                            {payCheck === key ?<>
                            <CustomTextField
                              width={"60%"}
                              label={""}
                              changeHandler={fillPaymentAmount}
                            />
                            <Link
                              to="/Open_Items/Make_Payment"
                              state={{
                                openItemRowData: row,
                                PayBalanceAmount: payBalanceAmount,
                              }}
                            >
                              <IconButton>
                                <ReceiptIcon
                                  titleAccess={payPartialText}
                                />
                              </IconButton>
                            </Link>
                            </>: <>{value}</>}
                          </TableCell>
                        ) : column.id === "text" &&
                          salesAreaMapping?.text_visible === "true" ? (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        ) : column.id !== "radioButton" &&
                          column.id !== "paymentAmount" &&
                          column.id !== "disputeReference" &&
                          column.id !== "discountDate" &&
                          column.id !== "discount" &&
                          column.id !== "text" ? (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        ) : null;
                      })}
                    </TableRow>
                  );
                })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  align={"center"}
                  className={styles.noRecFound}
                >
                  {t("gzl.no_data_available.text")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.tableFooter}>
        <div className={styles.totalAmount}>
          <Typography variant="body1" color="#F25529" mr={2}>
            {t("gzl.net_balance.text")}
          </Typography>
          <Typography
            variant="body1"
            color={netBalance?.amount < 0 ? "red" : "#727272"}
          >
            {currencyFormatter.format(netBalance?.amount, {code: netBalance?.currency})}
          </Typography>
        </div>
        <TablePagination
          className={styles.pagination}
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default CreditItemTable;
