import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import styles from "./footer.module.scss";

export const Footer = ({ fromLogin }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={styles.footerStyle}>
      {!fromLogin && (
        <Typography
          onClick={() => navigate("/Terms_and_Condition_of_Website_use")}
          className={styles.footerMsg}
        >
          {t("dnet.terms_and_conditions.section")}
        </Typography>
      )}
    </div>
  );
};

Footer.defaultProps = {
  fromLogin: false,
};
