import { commonConstant } from "../constants/common-constants";

export const initialState = {
  accountDetails: {},
  referenceData: {},
  registerResponse: {},
};

const signUpReducer = (state = initialState, action: any) => {
  switch (action?.type) {
    case commonConstant.VALIDATE_ACCOUNT_NUMBER_SUCCESS:
      return {
        ...state,
        accountDetails: action?.payload,
      };
    case commonConstant.FETCH_REFERENCE_DATA_SUCCESS:
      return {
        ...state,
        referenceData: action?.payload,
      };
    case commonConstant.REGISTER_USER_SUCCESS:
      return {
        ...state,
        registerResponse: action?.payload,
      };
    default:
      return state;
  }
};

export default signUpReducer;
