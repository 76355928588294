import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ChangePassword } from "./changePassword";
import styles from "./profile.module.scss";
import { UpdateProfile } from "./updateProfile";

export const Profile = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState("profile");

  return (
    <div>
      <Box className={styles.boxStyle}>
        <div className={styles.tabs}>
          <Typography
            className={
              currentTab.includes("profile")
                ? styles.activeTab
                : styles.secondTab
            }
            onClick={() => {
              setCurrentTab("profile");
            }}
          >
            {t("dnet.update_profile.section")}
          </Typography>
          <Typography
            className={
              currentTab.includes("password")
                ? styles.activeTab
                : styles.secondTab
            }
            onClick={() => {
              setCurrentTab("password");
            }}
          >
            {t("dnet.change_password.text")}
          </Typography>
        </div>
        <div className={styles.innerBox}>
          {currentTab.includes("profile") ? (
            <UpdateProfile />
          ) : (
            <ChangePassword />
          )}
        </div>
      </Box>
    </div>
  );
};
