import { commonConstant } from "../constants/common-constants";

export const initialState = {
  premiumServiceData: {},
  premiumServiceResponse: [],
};

const premiumServiceReducer = (state = initialState, action: any) => {
  switch (action?.type) {
    case commonConstant.GET_PREMIUM_SERVICE_DATA_SUCCESS:
      return {
        ...state,
        premiumServiceData: action?.payload,
      };
    default:
      return state;
  }
};

export default premiumServiceReducer;
