import { Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import * as React from "react";
import styles from "./customAutoComplete.module.scss";

export default function CustomAutoComplete(props: any) {
  const { options, selectedItems, value, setValue, placeHolder,textFieldStyle ,disableClose,forwardedKeyDown,setShowSearchField} = props;

  const optionHandler = (e: any, option: any) => {
    selectedItems(option?.item);
  };
  
  const customFilter =(options:any,{inputValue}:any) =>{
   const matcher = new RegExp(inputValue,'i');

   return options.filter((option:any)=>
    matcher.test(option.item)||matcher.test(option.description)
   );
  };

  const regexMatcher=(value:any)=>{
    const matcher = new RegExp(value,'i');
    return options.filter((option:any)=> matcher.test(option.description));
  }

  const handleClear=()=>{
    setValue("");
    setShowSearchField?.(false);
  };

  return (
    <Autocomplete
      freeSolo
      inputValue={value}
      onClose={handleClear}
      filterOptions={customFilter}
      onInputChange={(event, value, reason) => {
        if (event && event.type === "blur") {
          setValue("");
        } else if (reason !== "reset") {
          setValue(value);
        }
      }}
      onChange={(e, newValue) => {
        if (newValue) {
          if (options.find((option: any) => option?.item === newValue?.item)) {
            selectedItems(newValue?.item);
            setValue("");
          } else {
            forwardedKeyDown(e);
            setValue("");
          }
        }
      }}
      options={(value.length > 2 && options !== undefined )? options : []}
      disableCloseOnSelect
      getOptionDisabled={() => value.length < 3}
      getOptionLabel={(option: any) => {
        if (!option) return "";
        return `${option?.item}`;
      }}
      renderOption={(props, option) => {
        return (
        <li
        {...props}
        style={{display:"block"}}
        onClick={(e: any) => optionHandler(e, option)}
      >
        <Typography fontWeight="bold" variant="body2"> {option?.item} </Typography>
        {regexMatcher(value).length >0 && <Typography style={{fontSize:'12px'}} dangerouslySetInnerHTML={{
                  __html: option?.description?.replaceAll(new RegExp(value,'gi'),`<strong>${value}</strong>`)
                }}> 
                </Typography>
         
        }
      </li>
        );
      }
      }
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          className={styles.test}
          placeholder={placeHolder}
        />
      )}
    />
  );
}
