import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./subLocaleModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { SUB_LOCALE } from "../../../constants/labels";

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
  paddingBottom: "45px",
  textAlign: "left",
};

export const SubLocaleModal = ({
  openModal,
  closeModal,
  countryObject,
  changeLanguage,
}: any) => {
  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState<any>(
    SUB_LOCALE?.[countryObject?.country]
  );
  return (
    <div>
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div className={styles.modalHeader}>
            <Typography variant="subtitle2" color="primary">
              {selectedCountry?.HEADER}
            </Typography>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </div>
          <Grid container spacing={2}>
            {selectedCountry?.SUB_LOCALE_TEXT?.length > 0 &&
              selectedCountry?.SUB_LOCALE_TEXT?.map(
                (subText: any, index: any) => {
                  return (
                    <Grid item md={6} xs={12} key={index}>
                      <Typography
                        variant="caption"
                        className={styles.textLink}
                        onClick={() => changeLanguage(subText?.locale)}
                      >
                        {subText?.text}
                      </Typography>
                    </Grid>
                  );
                }
              )}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
