import { commonConstant } from "../constants/common-constants";

export const initialState = {
  openToast: false,
  toastContent: {},
  isLoaderActive: false,
  loginResponse: {},
  allDealers: [],
  isLoggedin: false,
  addressList: {},
  currentDealer: {},
  userDetails: {},
  salesAreaMapping: {},
  dealersList: [],
  sapEnvDetails: "",
  siteSectionDetails: {},
  sonyMenuItems: [],
  dealerDetails: [],
  translation: {},
  locale: "en_GB",
  jsonConfig: {},
  loginWithoutPasswordResponse: {},
  updateProfileRes: {},
  prodCategoryResponse: {},
  validateCaptchaRes: {},
  loginValidateCaptchaRes: {},
  globalSearchSuggestions: [],
  globalSearchResponse: {},
  termsAndCondition: "",
  isUserAuthorized: true,
  setCurrentDealerSuccessRes: {},
  newProducts:[],
  contextDetails: {},
  calendarDetails: [],
  loginPageError: "",
  saveAccountDetails: {},
};
// var _ = require("lodash");

const commonReducer = (state = initialState, action: any) => {
  switch (action?.type) {
    case commonConstant.SET_LOADER:
      return {
        ...state,
        isLoaderActive: action?.payload,
      };
    case commonConstant.SET_OPEN_TOAST:
      return {
        ...state,
        openToast: action?.payload?.openToast,
        toastContent: {
          severity: action?.payload?.severity,
          message: action?.payload?.message,
        },
      };
    case commonConstant.SET_UNAUTH_USER:
      return {
        ...state,
        isUserAuthorized: action?.payload?.isUserAuthorized,
      };
    case commonConstant.LOGIN_USER_SUCCESS:
      const allDealers = buildDealerList(action?.payload);
      return {
        ...state,
        allDealers,
        loginResponse: action?.payload,
      };
    case commonConstant.IS_USER_LOGGED_IN:
      return {
        ...state,
        isLoggedin: action?.payload,
      };
    case commonConstant.GET_ADDRESS_LIST_SUCCESS:
      return {
        ...state,
        addressList: action?.payload,
      };
    case commonConstant.GET_CURRENT_DEALER_SUCCESS:
      return {
        ...state,
        setCurrentDealerSuccessRes: action?.payload
      }
    case commonConstant.SET_CURRENT_DEALER:
      let loggedInUser = action?.payload?.loggedInUser;
      const userDetails = {
        salesOrganisation: "",
        language: loggedInUser?.profile?.locale?.split("_")[0]?.toUpperCase(),
        country: loggedInUser?.profile?.locale?.split("_")[1],
        password: "",
        firstName: loggedInUser?.profile?.firstName,
        lastName: loggedInUser?.profile?.lastName,
        companyCode: action?.payload?.bgName,
        account: action?.payload?.accNum,
        soldToAccount: "",
        userName: "DN".concat(action?.payload?.accNum),
        bankDescription: "",
        bankDetail: "",
        partnerType: "",
        partnerDescription: "",
        permissionList: "",
        source: "",
      };
      return {
        ...state,
        currentDealer: action?.payload,
        userDetails,
      };
    case commonConstant.GET_SALES_AREA_MAPPING_SUCCESS:
      return {
        ...state,
        salesAreaMapping: action?.payload,
      };
    case commonConstant.SET_DEALERS_LIST:
      return {
        ...state,
        dealersList: action?.payload,
      };
    case commonConstant.GET_SAP_ENV_DETAILS_SUCCESS:
      return {
        ...state,
        sapEnvDetails: action?.payload,
      };
    case commonConstant.FETCH_SITESECTION_DETAILS_SUCCESS:
      return {
        ...state,
        siteSectionDetails: action?.payload,
      };
    case commonConstant.SET_SONY_MENU_ITEMS:
      return {
        ...state,
        sonyMenuItems: action?.payload,
      };
    case commonConstant.GET_DEALER_DETAILS_SUCCESS:
      return {
        ...state,
        dealerDetails: action?.payload,
      };
    case commonConstant.CHANGE_TRANSLATION_SUCCESS:
      return {
        ...state,
        translation: action?.payload,
      };
    case commonConstant.CHANGE_TRANSLATION:
      return {
        ...state,
        locale: action?.payload?.locale,
      };
    case commonConstant.HOME_PAGE_BUTTONS_CONFIG_SUCCESS:
      return {
        ...state,
        jsonConfig: action?.payload,
      };
    case commonConstant.GET_USER_DETAILS_WITHOUT_PASSWORD_SUCCESS:
      return {
        ...state,
        loginWithoutPasswordResponse: action?.payload,
      };
    case commonConstant.UPDATE_PROFILE_SUCCESS:
      let changedLoginRes = {
        data: {
          userDetails: action?.payload?.data?.userProfile,
        }
      };
      if (action?.payload?.success === true) {
        return {
          ...state,
          updateProfileRes: action?.payload,
          loginResponse: changedLoginRes,
        };
      } else {
        return {
          ...state,
          updateProfileRes: action?.payload,
        };
      }
    case commonConstant.FETCH_PROD_CATEGORY_RESPONSE_SUCCESS:
      // Removed Display Attribute
      //const categoryData = fetchCategoryData(action?.payload);
      return {
        ...state,
        prodCategoryResponse: action?.payload,
      };
    case commonConstant.GET_CAPTCHA_VALIDATION_SUCCESS:
      return {
        ...state,
        validateCaptchaRes: action?.payload,
      };
    case commonConstant.GET_LOGIN_CAPTCHA_VALIDATION_SUCCESS:
      return {
        ...state,
        loginValidateCaptchaRes: action?.payload,
      };
    case commonConstant.GLOBAL_SEARCH_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        globalSearchSuggestions: action?.payload,
      };
    case commonConstant.GLOBAL_SEARCH_RESPONSE_SUCCESS:
      return {
        ...state,
        globalSearchResponse: action?.payload,
      };
    case commonConstant.GET_TERMS_AND_CONDITION_SUCCESS:
      return {
        ...state,
        termsAndCondition: action?.payload,
      };
      case commonConstant.GET_NEW_PRODUCTS_SUCCESS:
      return{
        ...state,
        newProducts: action?.payload,
      }
    case commonConstant.START_PRODUCT_FLOW_SUCCESS:
      return{
        ...state,
        productFlow: action?.payload,
      }
    case commonConstant.GET_CONTEXT_DETAILS_SUCCESS:
      return {
        ...state,
        contextDetails: action?.payload,
      };
    case commonConstant.GET_CALENDAR_DETAILS_SUCCESS:
      return {
        ...state,
        calendarDetails: action?.payload,
      };
    case commonConstant.SAVE_LOGIN_ERROR:
      return {
        ...state,
        loginPageError: action?.payload?.error,
      };
    case commonConstant.SAVE_ACCOUNT_DETAILS:
      return {
        ...state,
        saveAccountDetails: action?.payload,
      };
    default:
      return state;
  }
};

// creates comma separated bgName and accNum, used during dealerLIst display
const buildDealerList = (data: any) => {
  let bgData = data?.data?.userDetails?.businessGroups;
  let dealerList: any = [];
  if (bgData?.length > 0) {
    bgData.forEach((bg: any) => {
      if (bg?.accounts?.length > 0) {
        bg?.accounts?.forEach((acc: any) => {
          dealerList.push(bg?.name + "," + acc);
        });
      }
    });
  }
  return dealerList;
};

// const fetchCategoryData = (data: any) => {
//   if (data) {
//     data?.children.forEach((mainCategory: any) => {
//       let publication = "";
//       if (mainCategory?.name === "Sony Professional") {
//         publication = "score";
//       } else {
//         publication = "global";
//       }
//       mainCategory?.children?.forEach((category: any) => {
//         productListAvailable(category, publication);
//       });
//     });
//   }
//   return data;
// };

// const productListAvailable = (category: any, publication: any) => {
//   if (category?.length > 0) {
//     category.forEach((cat: any) => {
//       if (!_.isEmpty(cat?.children) && _.isEmpty(cat?.productList)) {
//         productListAvailable(cat?.children, publication);
//       } else {
//         cat?.productList?.forEach((prod: any) => {
//           if (
//             prod?.product?.index?.publication === null ||
//             prod?.product?.index?.publication === publication
//           ) {
//             prod.display = true;
//           } else {
//             prod.display = false;
//           }
//         });
//       }
//     });
//   } else {
//     if (!_.isEmpty(category?.children) && _.isEmpty(category?.productList)) {
//       productListAvailable(category?.children, publication);
//     } else {
//       category?.productList?.forEach((prod: any) => {
//         if (
//           prod?.product?.index?.publication === null ||
//           prod?.product?.index?.publication === publication
//         ) {
//           prod.display = true;
//         } else {
//           prod.display = false;
//         }
//       });
//     }
//   }
// };

export default commonReducer;
