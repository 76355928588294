import React from "react";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import Box from "@mui/material/Box";
import "./creditDetailsTable.scss";
import { useTranslation } from "react-i18next";
import { getComparator, stableSort } from '../../../helper/util';
import moment from "moment";
var currencyFormatter = require('currency-formatter');


const CreditDetailsTable = () => {
  const { t } = useTranslation();
  const [rows, setRows] = useState<any>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const creditsDetailsTableData = useSelector(
    (state: any) => state?.gazelleReducer?.paymentDetailsData?.credits
  );

  interface Column {
    id: "label" | "dueDate" | "amount" | "openAmount";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
  }

  const columns: readonly Column[] = [
    { id: "label", label: t("gzl.credit.text") },
    { id: "dueDate", label: t("gzl.payment_date.text") },
    { id: "amount", label: t("gzl.org_credit_value.text") },
    { id: "openAmount", label: t("gzl.used.text") },
  ];

  interface Data {
    label: any;
    dueDate: any;
    amount: any;
    openAmount: any;
  }

  function createData(
    label: any,
    dueDate: any,
    amount: any,
    openAmount: any
  ): Data {
    return { label, dueDate, amount, openAmount };
  }

  const createRowData = () => {
    //Rows data creation from API response
    let data = creditsDetailsTableData;
    let rowsdata = [];
    for (let i = 0; i < data?.length; i++) {
      rowsdata.push(
        createData(
          data[i]?.label,
          data[i]?.dueDate,
          data[i]?.amount,
          data[i]?.openAmount
        )
      );
    }
    setRows([...rowsdata]);
  };

  useEffect(() => {
    createRowData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditsDetailsTableData]);

  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("label");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    //Sorting table data on click of header
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // Changing number of rows per page 
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <TableContainer component={Paper} className="tableContainerHeader">
        <Table sx={{ minWidth: 650 }} aria-label="Payments Table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={createSortHandler(column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length > 0 ? (
              stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, key: any) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return column.id === "amount" ||
                          column.id === "openAmount" ? (
                          <TableCell key={column.id} align={column.align}>
                            {value}&nbsp;
                            {currencyFormatter.findCurrency(
                              creditsDetailsTableData[key]?.currency
                              )?.symbol}
                          </TableCell>
                        ) : column.id === "dueDate" ? (
                          <TableCell key={column.id} align={column.align}>
                            {
                              value && moment(value).format("DD.MM.YYYY") 
                            }
                          </TableCell>
                        ) : (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  align={"center"}
                  className="noRecFound"
                >
                  {t("gzl.no_data_available.text")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="tableFooter">
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default CreditDetailsTable;
