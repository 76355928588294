import { Box } from "@mui/material";
import React from "react";
import styles from "./videoDetails.module.scss";

export const VideoDetails = (props: any) => {
  const { modalDetails } = props;

  return (
    <>
      <Box className={styles.videoContainer}>
        <Box mt={2} mb={3} >
          <video preload="auto" controls className={styles.videoBox}>
            <source
              src={modalDetails?.videos?.[0]?.videoFamily?.externalUrl}
              type="video/mp4"
            />
            {modalDetails?.videos?.[0]?.videoFamily?.subtitlesUrl && (
              <track
                kind="subtitles"
                default
                src={modalDetails?.videos?.[0]?.videoFamily?.subtitlesUrl}
                label="en"
              />
            )}
            <p>
              To view this video please enable JavaScript, and consider
              upgrading to a web browser that
              <a href="http://videojs.com/html5-video-support/" target="_blank">
                supports HTML5 video
              </a>
            </p>
          </video>
        </Box>
      </Box>
    </>
  );
};
