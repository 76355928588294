import { TextField } from "@mui/material";
import React from "react";

const CustomTextField = ({
  label,
  variant,
  value,
  disabled,
  changeHandler,
  onKeyDown,
  helperText,
  error,
  type,
  additionalSxProperties,
  onBlurHandler,
  placeholder,
}: any) => {
  return (
    <TextField
      label={label}
      variant={variant}
      sx={{
        borderRadius: "4px",
        ...additionalSxProperties,
      }}
      size="small"
      value={value}
      placeholder={placeholder}
      InputProps={{
        disabled,
        style: {
          fontSize: 15
        },
        
      }}
      onChange={(e) => changeHandler(e)}
      onBlur={(e) => onBlurHandler(e)}
      helperText={helperText}
      error={error}
      type={type}
      onKeyDown={(e) => onKeyDown(e)}
    />
  );
};

CustomTextField.defaultProps = {
  label: "label",
  variant: "outlined",
  type: "text",
  changeHandler: () => {},
  onKeyDown: () => {},
  onBlurHandler: () => {},
  disabled: false,
  placeholder: "",
};

export default CustomTextField;
