import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import styles from "./technicalSummaryTab.module.scss";

export const TechnicalSummaryTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const productDetailsData = useSelector(
    (state: any) => state?.productReducer?.productDetailsData
  );

  // const techSummary = {
  //   iconDescription: ["description 1", "description 2"],
  //   keyTechSpecs: [
  //     {
  //       displayName: "Capsule Type",
  //       value: ["Electret Condenser"],
  //     },
  //     {
  //       displayName: "Frequency Response",
  //       value: ["40 Hz to 15kHz"],
  //     },
  //     {
  //       displayName: "Directivity",
  //       value: ["Omni-directional"],
  //     },
  //     {
  //       displayName: "Sensitivity *[1]",
  //       value: ["-53.0 dB ±3 dB"],
  //     },
  //     {
  //       displayName: "Output Impedance *[2]",
  //       value: ["250Ω±20%, Balanced"],
  //     },
  //     {
  //       displayName: "Dynamic Range",
  //       value: ["90 dB or more"],
  //     },
  //     {
  //       displayName: "Signal-to-Noise Ratio *[3]",
  //       value: ["62 dB or more"],
  //     },
  //     {
  //       displayName: "Inherent Noise *[4]",
  //       value: ["32 dB SPL or less"],
  //     },
  //     {
  //       displayName: "Induction Noise From External Magnetic Field *[5]",
  //       value: ["5 dB SPL or less"],
  //     },
  //     {
  //       displayName: "Wind Noise *[6]",
  //       value: ["40 dB SPL or less (with windscreen)"],
  //     },
  //     {
  //       displayName: "Maximum Input Sound Pressure Level *[7]",
  //       value: ["122 dB SPL"],
  //     },
  //   ],
  //   imageMap: {
  //     dimensionShots: [
  //       "https://sp.sony-europe.com/da/1035/307319.jpeg",
  //       "https://sp.sony-europe.com/da/645/208119.jpeg",
  //     ],
  //   },
  //   inTheBox: ["box item 1", "box item 2"],
  // };
  return (
    <>
      <Box className={styles.borderBoxStyle}>
        <Typography variant="body1" color="primary" pb={1}>
          {t("dnet.technicalSummary.iconDescriptions.title")}
        </Typography>
        {productDetailsData?.iconDescription?.map((desc: any, index: any) => (
          <Typography
            key={index}
            component="div"
            variant="caption"
            color="text.secondary"
          >
            {desc}
          </Typography>
        ))}
      </Box>
      <Box className={styles.borderBoxStyle} mt={4}>
        <Typography variant="body1" color="primary" pb={1}>
          {t("dnet.technicalSummary.keyTechSpecs.title")}
        </Typography>
        {productDetailsData?.keyTechSpecs?.map((feature: any, index: any) => (
          <Grid container mb={0.5} key={index}>
            <Grid item lg={5} md={5} sm={6} xs={12}>
              <Typography
                component="span"
                variant="caption"
                color="black"
                sx={{ fontWeight: "600" }}
              >
                {feature?.displayName}
              </Typography>
            </Grid>
            <Grid item lg={7} md={7} sm={6} xs={12}>
              {feature?.value?.map((spec: any, index: any) => (
                <Typography
                  key={index}
                  component="span"
                  variant="caption"
                  color="text.secondary"
                  dangerouslySetInnerHTML={{ __html: spec }}
                ></Typography>
              ))}
            </Grid>
          </Grid>
        ))}
      </Box>
      <Box className={styles.borderBoxStyle} mt={4}>
        <Typography variant="body1" color="primary" pb={1}>
          {t("dnet.technicalSummary.whatsInTheBox.title")}
        </Typography>
        <Typography variant="body2" color="text.secondary" pb={1}>
          {t("dnet.technicalSummary.whatsInTheBox.text")}
        </Typography>
        <Box className={styles.imageBoxStyle} mb={2}>
          {productDetailsData?.images?.dimensionShots?.map(
            (shot: any, index: any) => (
              <img
                key={index}
                src={shot}
                alt={t("dnet.technicalSummary.whatsInTheBox.title")?.toString()}
                width="200px"
              />
            )
          )}
        </Box>
        {productDetailsData?.inTheBox?.map((boxItem: any, index: any) => (
          <Typography
            key={index}
            component="div"
            variant="caption"
            color="text.secondary"
          >
            {boxItem}
          </Typography>
        ))}
      </Box>
    </>
  );
};
