import React from "react";
import { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Box,
  Typography,
  Checkbox,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import styles from "./quotationDetailsTable.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getComparator, stableSort } from "../../../helper/util";
import CustomTextField from "../../../atoms/customTextField";
var currencyFormatter = require("currency-formatter");

const QuotationDetailsTable = ({
  selectedQuotation,
  updateQuotationOrder,
}: any) => {
  const { t } = useTranslation();
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("model");
  const [rows, setRows] = useState<any>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const quotationData?.items = selectedQuotation?.items;
  // let quoteData = selectedQuotation;
  const [quotationData, setQuotationData] = useState(selectedQuotation);

  // useEffect(()=>{
  //   let quote = JSON.parse(JSON.stringify(selectedQuotation))
  //   for(let i=0; i <quote?.items?.length; i++){
  //     quote.items[i].orderQuantity = quote?.items?.[i]?.remainingQuantity
  //   }
  //   setQuotationData(quote)
  // },[quoteData])

  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  interface Column {
    id:
      | "model"
      | "quantity"
      | "remainingQuantity"
      | "orderQuantity"
      | "listPrice"
      | "normalDiscount"
      | "specialDiscount"
      | "invoiceUnitPrice"
      | "totalPrice";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
  }

  const columns: readonly Column[] = [
    { id: "model", label: t("dnet.salesforce_quotation_model.text") },
    { id: "quantity", label: t("dnet.salesforce_quotation_quantity.text") },
    {
      id: "remainingQuantity",
      label: t("dnet.salesforce_quotation_remaining_quantity.text"),
    },
    {
      id: "orderQuantity",
      label: t("dnet.salesforce_quotation_order_quantity.text"),
    },
    { id: "listPrice", label: t("dnet.salesforce_quotation_list_price.text") },
    {
      id: "normalDiscount",
      label: t("dnet.salesforce_quotation_normal_discount.text"),
    },
    {
      id: "specialDiscount",
      label: t("dnet.salesforce_quotation_special_discount.text"),
    },
    {
      id: "invoiceUnitPrice",
      label: t("dnet.salesforce_quotation_unit_price.text"),
    },
    {
      id: "totalPrice",
      label: t("dnet.salesforce_quotation_total_price.text"),
    },
  ];

  interface Data {
    model: any;
    quantity: any;
    remainingQuantity: any;
    orderQuantity: any;
    listPrice: any;
    normalDiscount: any;
    specialDiscount: any;
    invoiceUnitPrice: any;
    totalPrice: any;
  }

  function createData(
    model: any,
    quantity: any,
    remainingQuantity: any,
    orderQuantity: any,
    listPrice: any,
    normalDiscount: any,
    specialDiscount: any,
    invoiceUnitPrice: any,
    totalPrice: any
  ): Data {
    return {
      model,
      quantity,
      remainingQuantity,
      orderQuantity,
      listPrice,
      normalDiscount,
      specialDiscount,
      invoiceUnitPrice,
      totalPrice,
    };
  }

  const createRowData = () => {
    let quote = JSON.parse(JSON.stringify(quotationData));
    for (let i = 0; i < quote?.items?.length; i++) {
      let selQuote = quote.items[i];
      selQuote.totalPrice = 0;
      selQuote.orderQuantity = 0;
      if (selQuote?.hasOwnProperty("warranties")) {
        for (let j = 0; j < selQuote?.warranties?.length; j++) {
          selQuote.warranties[j].checked = true;
          selQuote.warranties[j].orderQuantity = 0;
        }
      }
    }
    quote.totalPrice = 0;
    setQuotationData(quote);
  };

  useEffect(() => {
    if (quotationData?.items?.length > 0 && quotationData?.type !== 0)
      createRowData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let warrantySpan = '<span class="pr-1">&boxur;&boxh;</span>';
  let padlockSpan = '<span class="pr-1">&nbsp;&#128274;</span>';

  const formatModelColumnData = (item: any, model: any) => {
    let modelData = `${
      item?.parentItem !== null &&
      item?.parentItem !== "" &&
      item?.parentItem !== "null"
        ? warrantySpan
        : ""
    } ${model} ${item.lockQtyToParent === "true" ? padlockSpan : ""}`;
    return <span dangerouslySetInnerHTML={{ __html: modelData?.toString() }} />;
  };

  const handleWarrantiesCheck = (event: any, quoteIndex: any, warrantyIndex: any) => {
    let quote = JSON.parse(JSON.stringify(quotationData));
    quote.items[quoteIndex].warranties[warrantyIndex].checked = event.target.checked;
    if (quote?.items[quoteIndex]?.warranties[warrantyIndex]?.checked) {
      quote.totalPrice += (parseInt(quote?.items[quoteIndex]?.orderQuantity) * quote?.items?.[quoteIndex]?.warranties?.[warrantyIndex]?.invUnitPrice);
      quote.items[quoteIndex].warranties[warrantyIndex].orderQuantity =
        quote?.items[quoteIndex]?.orderQuantity;
    }
    else {
      quote.totalPrice -= (quote?.items[quoteIndex]?.warranties[warrantyIndex]?.orderQuantity * quote?.items?.[quoteIndex]?.warranties?.[warrantyIndex]?.invUnitPrice);
      quote.items[quoteIndex].warranties[warrantyIndex].orderQuantity = "";
    }
    setQuotationData(quote);
    updateQuotationOrder(quote);
  };

  const handleOrderQuantity = 
  (e: any, remainingQuantity: any, index: any, rowId: any = 0, warranty: any = false) => {
    let quote = JSON.parse(JSON.stringify(quotationData));
    let quantity = e.target.value;
    if (quantity === "") {
      quantity = "";
    } else if (isNaN(quantity)) {
      quantity = remainingQuantity;
    } else if (quantity > remainingQuantity) {
      quantity = remainingQuantity;
    } else if (quantity <= 0) {
      quantity = "";
    }
    if (warranty)
      quote.items[index].warranties[rowId].orderQuantity = quantity;  
    else
      quote.items[index].orderQuantity = quantity;
    if (quote?.items[index]?.hasOwnProperty("warranties") && !warranty) {
      for (let j = 0; j < quote?.items[index]?.warranties?.length; j++) {
        if (quote?.items[index]?.warranties[j]?.checked === true && 
          (quote?.items[index]?.warranties[j]?.parentItem != null &&
          quote?.items[index]?.warranties[j]?.parentItem != "") &&
          quote?.items[index]?.warranties[j]?.lockQtyToParent === "true")
          quote.items[index].warranties[j].orderQuantity = quantity;
      }
    }
    if (parseInt(quantity) > 0) {
      if(warranty) {
        quote.items[index].warranties[rowId].totalPrice = `${
          parseInt(quantity) * quote?.items?.[index]?.warranties?.[rowId]?.invUnitPrice
        }`;
      } else {
        quote.items[index].totalPrice = `${
          parseInt(quantity) * quote?.items?.[index]?.invUnitPrice
        }`;
        for(let i=0; i<quote?.items[index]?.warranties?.length; i++) {
          quote.items[index].warranties[i].totalPrice = `${
            parseInt(quantity) * quote?.items?.[index]?.warranties?.[i]?.invUnitPrice
          }`;
        }
      }
    } else {
      if(warranty)
        quote.items[index].warranties[rowId].totalPrice = 0 * quote?.items?.[index]?.warranties?.[rowId]?.invUnitPrice;
      else {
        quote.items[index].totalPrice = 0 * quote?.items?.[index]?.invUnitPrice;
        for(let i=0; i<quote?.items[index]?.warranties?.length; i++) {
          quote.items[index].warranties[i].totalPrice = `${
            0 * quote?.items?.[index]?.warranties?.[i]?.invUnitPrice
          }`;
        }
      }
    }
    let sum = 0;
    for (let i = 0; i < quote?.items?.length; i++) {
      if(quote?.items[i]?.warranties?.length > 0){
        for (let j = 0; j < quote?.items[i]?.warranties?.length; j++) {
          if(parseInt(quote?.items[i]?.warranties?.[j]?.orderQuantity) > 0) {
            sum += parseFloat(quote?.items?.[i]?.warranties?.[j]?.totalPrice);
          }
        }
      }
      sum += parseFloat(quote?.items?.[i]?.totalPrice);
    }
    quote.totalPrice = sum;
    setQuotationData(quote);
    updateQuotationOrder(quote);
  };

  const totalPrice = () => {
    let quote = JSON.parse(JSON.stringify(quotationData));
    let sum = quotationData?.totalPrice;
    if (quotationData?.smallOrderFeeAmount > 0 &&
    quotationData?.smallOrderFeeThreshold > 0 &&
              quotationData?.totalPrice > 0 &&
              quotationData?.totalPrice <
              quotationData?.smallOrderFeeThreshold) {
                sum = parseFloat(sum)+parseFloat(quotationData?.smallOrderFeeAmount)
              }
    return (
      currencyFormatter.findCurrency(quote?.["quoteCurrency"])?.symbol +
      (parseFloat(sum?.toFixed(10)))
    );
  };
  return (
    <div className={styles.tableContainer}>
      <TableContainer component={Paper} className={styles.tableContainerHeader}>
        <Table sx={{ minWidth: 650 }} aria-label="Payments Table">
          <TableHead>
            <TableRow>
              {columns.map((column) =>
                selectedQuotation["type"] === 0 &&
                column.id !== "remainingQuantity" &&
                column.id !== "orderQuantity" ? (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {/* <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={createSortHandler(column.id)}
                    > */}
                    {column.label}
                    {/* {orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null} */}
                    {/* </TableSortLabel> */}
                  </TableCell>
                ) : (
                  selectedQuotation["type"] !== 0 && (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {/* <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={createSortHandler(column.id)}
                      > */}
                      {column.label}
                      {/* {orderBy === column.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel> */}
                    </TableCell>
                  )
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {quotationData?.items?.length > 0 &&
              quotationData?.items?.map((quote: any, key: any) => {
                return (
                  <>
                    <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                      <TableCell sx={{ width: "200px" }}>
                        {formatModelColumnData(quote, quote?.modelName)}
                      </TableCell>
                      <TableCell>{parseInt(quote?.quantity)}</TableCell>
                      {selectedQuotation["type"] !== 0 && (
                        <TableCell>{quote?.remainingQuantity}</TableCell>
                      )}
                      {selectedQuotation["type"] !== 0 && (
                        <TableCell>
                          {
                          // (quote?.parentItem === null &&
                          // quote?.parentItem === "") ||
                          // quote?.lockQtyToParent === "false" ?
                          quote?.remainingQuantity > 0 && (
                            <CustomTextField
                              label=""
                              changeHandler={(e: any) =>
                                handleOrderQuantity(
                                  e,
                                  quote?.remainingQuantity,
                                  key
                                )
                              }
                              value={quote?.orderQuantity}
                              disabled={quote?.lockQtyToParent === "true"}
                            />
                          )}
                        </TableCell>
                      )}
                      <TableCell>
                        {quote?.listPrice &&
                          currencyFormatter.findCurrency(
                            quotationData["quoteCurrency"]
                          )?.symbol + quote?.listPrice}
                      </TableCell>
                      <TableCell>
                        {quote?.normalDiscount
                          ? quote?.normalDiscount + "%"
                          : ""}
                      </TableCell>
                      <TableCell>
                        {quote?.specialDiscount
                          ? quote?.specialDiscount + "%"
                          : ""}
                      </TableCell>
                      <TableCell>
                        {currencyFormatter.findCurrency(
                          quotationData["quoteCurrency"]
                        )?.symbol + quote?.invUnitPrice}
                      </TableCell>
                      <TableCell>
                        {(quote?.orderQuantity && quotationData?.type !== 0) ||
                        quotationData?.type === 0
                          ? currencyFormatter.findCurrency(
                              quotationData["quoteCurrency"]
                            )?.symbol + quote?.totalPrice
                          : 0}
                      </TableCell>
                    </TableRow>
                    {quote?.warranties?.length > 0 &&
                      quote?.warranties?.map((subQuote: any, rowId: any) => {
                        return (
                          <TableRow>
                            <TableCell sx={{ width: "200px" }}>
                              {formatModelColumnData(
                                subQuote,
                                subQuote?.modelName
                              )}
                            </TableCell>
                            <TableCell>{parseInt(subQuote?.quantity)}</TableCell>
                            {selectedQuotation["type"] !== 0 && (
                              <TableCell>
                                {parseInt(subQuote?.remainingQuantity)}
                              </TableCell>
                            )}
                            {selectedQuotation["type"] !== 0 && (
                              <TableCell>
                                {subQuote?.lockQtyToParent === "false" ?
                                  (subQuote?.remainingQuantity > 0 &&
                                  <div style={{ display: "flex" }}>
                                    <CustomTextField
                                      label=""
                                      value={subQuote?.orderQuantity}
                                      disabled={
                                        subQuote?.lockQtyToParent === "true"
                                      }
                                      changeHandler={(e: any) =>
                                        handleOrderQuantity(
                                          e,
                                          quote?.remainingQuantity,
                                          key,
                                          rowId,
                                          true
                                        )
                                      }
                                    />
                                  </div>
                                ) : (subQuote?.remainingQuantity > 0 &&
                                  <div style={{ display: "flex" }}>
                                    <Checkbox
                                      size="small"
                                      defaultChecked
                                      onChange={(e: any) =>
                                        handleWarrantiesCheck(e, key, rowId)
                                      }
                                    />
                                    <CustomTextField
                                      label=""
                                      value={subQuote?.orderQuantity}
                                      disabled={
                                        subQuote?.lockQtyToParent === "true"
                                      }
                                    />
                                  </div>
                                )}
                              </TableCell>
                            )}
                            <TableCell>
                              {subQuote?.listPrice &&
                                currencyFormatter.findCurrency(
                                  quotationData["quoteCurrency"]
                                )?.symbol + subQuote?.listPrice}
                            </TableCell>
                            <TableCell>
                              {subQuote?.normalDiscount
                                ? subQuote?.normalDiscount + "%"
                                : ""}
                            </TableCell>
                            <TableCell>
                              {subQuote?.specialDiscount
                                ? subQuote?.specialDiscount + "%"
                                : ""}
                            </TableCell>
                            <TableCell>
                              {currencyFormatter.findCurrency(
                                quotationData["quoteCurrency"]
                              )?.symbol + subQuote?.invUnitPrice}
                            </TableCell>
                            <TableCell>
                              {(subQuote?.orderQuantity &&
                                quotationData?.type !== 0) ||
                              quotationData?.type === 0
                                ? currencyFormatter.findCurrency(
                                    quotationData["quoteCurrency"]
                                  )?.symbol + subQuote?.totalPrice
                                : 0}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </>
                );
              })}
            {selectedQuotation?.smallOrderFeeAmount > 0 &&
              selectedQuotation?.smallOrderFeeThreshold > 0 &&
              quotationData?.totalPrice > 0 &&
              quotationData?.totalPrice <
                selectedQuotation?.smallOrderFeeThreshold && (
                <TableRow hover tabIndex={-1}>
                  <TableCell
                    colSpan={selectedQuotation["type"] === 0 ? 6 : 8}
                    align="right"
                  >
                    {t("dnet.total_fee.text")?.toString()}
                  </TableCell>
                  <TableCell>
                    {currencyFormatter.findCurrency(
                      selectedQuotation?.["quoteCurrency"]
                    )?.symbol + selectedQuotation?.smallOrderFeeAmount}
                  </TableCell>
                </TableRow>
              )}
            <TableRow hover tabIndex={-1}>
              <TableCell
                colSpan={selectedQuotation["type"] === 0 ? 6 : 8}
                align="right"
              >
                {t("dnet.salesforce_quotation_total.text")?.toString()}
              </TableCell>
              <TableCell>
                {totalPrice()}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {/* <div className={styles.tableFooter}> */}
        {/* <div className={styles.totalPriceStyle}>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {t("dnet.salesforce_quotation_total.text")}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {currencyFormatter.findCurrency(selectedQuotation["quoteCurrency"])
              ?.symbol + selectedQuotation?.totalPrice}
          </Typography>
        </div> */}
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      {/* </div> */}
    </div>
  );
};

export default QuotationDetailsTable;
