import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import { useSelector } from "react-redux";
import styles from "./orderChangeHistoryTable.module.scss";
import { useTranslation } from "react-i18next";
import moment from "moment";

export const OrderChangeHistoryTable = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [rows, setRows] = useState<any>([]);
  const orderChangeHistoryData = useSelector(
    (state: any) =>
      state?.orderChangeReportReducer?.orderChangeHistoryData?.orderChanges
  );

  const contextDetails = useSelector(
    (state: any) => state?.commonReducer?.contextDetails
  );

  const { t } = useTranslation();
  interface Column {
    id:
      | "itemNo"
      | "model"
      | "customerMaterialNumber"
      | "dateOfChange"
      | "changeDescription"
      | "oldValue"
      | "newValue";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
  }

  const columns: readonly Column[] = [
    { id: "itemNo", label: t("dnet.item_number.text") },
    { id: "model", label: t("dnet.model.text") },
    { id: "customerMaterialNumber", label: t("customerMaterialNumber.text") },
    { id: "dateOfChange", label: t("priceChange.changeDate.text") },
    { id: "changeDescription", label: t("dnet.change_desc.text") },
    { id: "oldValue", label: t("dnet.old_value.text") },
    { id: "newValue", label: t("dnet.new_value.text") },
  ];

  interface Data {
    itemNo: any;
    model: any;
    customerMaterialNumber: any;
    dateOfChange: any;
    changeDescription: any;
    oldValue: any;
    newValue: any;
  }

  function createData(
    itemNo: any,
    model: any,
    customerMaterialNumber: any,
    dateOfChange: any,
    changeDescription: any,
    oldValue: any,
    newValue: any
  ): Data {
    return {
      itemNo,
      model,
      customerMaterialNumber,
      dateOfChange,
      changeDescription,
      oldValue,
      newValue,
    };
  }

  const createRowData = () => {
    // Row data creation from API Response
    let data = orderChangeHistoryData;
    let rowsdata = [];
    for (let i = 0; i < data?.length; i++) {
      rowsdata.push(
        createData(
          parseInt(data[i]?.itemNumber, 10).toString(),
          data[i]?.materialName,
          data[i]?.customerMaterialNumber,
          data[i]?.modificationDate,
          data[i]?.description,
          data[i]?.oldValue,
          data[i]?.newValue
        )
      );
    }
    setRows([...rowsdata]);
  };

  useEffect(() => {
    if (orderChangeHistoryData?.length > 0) createRowData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderChangeHistoryData]);

  return (
    <div>
      <div ref={componentRef}>
        <TableContainer
          component={Paper}
          className={styles.tableContainerHeader}
        >
          <Table sx={{ minWidth: 650 }} aria-label="Reports Table">
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  return column?.id === "customerMaterialNumber" ? (
                    contextDetails?.customerMaterialNumberEnabled && (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    )
                  ) : (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {orderChangeHistoryData?.length > 0 && rows?.length > 0 ? (
                rows.map((row: any, key: any) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return column?.id === "customerMaterialNumber" ? (
                          contextDetails?.customerMaterialNumberEnabled && (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          )
                        ) : (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    align={"center"}
                    className={styles.noRecFound}
                  >
                    {t("gzl.no_data_available.text")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* <div className={styles.tableFooter}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div> */}
    </div>
  );
};
