import React, { useEffect, useState } from "react";
import styles from "./articlesList.module.scss";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
  Pagination,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useDispatch, useSelector } from "react-redux";
import { getArticlesFromCMS } from "../../../state/actions";
import moment from "moment";
import { CMS } from "../../../constants/commonConstants";
import { useNavigate } from "react-router";
import CustomTextField from "../../atoms/customTextField";
import { mimeTypeImageMapping } from "../../helper/util";
var _ = require("lodash");
const sonyThumbnailImage = require("../../../assets/images/thumbnail.jpg");

export const ArticlesList = ({ parent }: any) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allArticles: any = useSelector(
    (state: any) => state?.userProfileOptionsReducer?.cmsArticles
  );
  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );
  const sonyMenuItems = useSelector(
    (state: any) => state?.commonReducer?.sonyMenuItems
  );
  const [sonyArticleSearch, setSonyArticleSearch] = useState("");
  const [noArticleMsg, setNoArticleMsg] = useState("");
  const [articles, setArticles] = useState([]);
  const articlesPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    let startIndexValue = (value - 1) * articlesPerPage;
    setStartIndex(startIndexValue);
    setEndIndex(startIndexValue + articlesPerPage);
    setCurrentPage(value);
    let articleArr = [...articles];
    articleArr = allArticles?.slice(
      startIndexValue,
      startIndexValue + articlesPerPage
    );
    setArticles(articleArr);
  };
  const [startIndex, setStartIndex] = useState(
    (currentPage - 1) * articlesPerPage
  );
  const [endIndex, setEndIndex] = useState(startIndex + articlesPerPage);
  const [filteredCategoryList, setFilteredCategoryList] = useState([]);

  useEffect(() => {
    let articleArr: any = [];
    if (allArticles?.length > 0) {
      if (parent === "home") {
        articleArr = allArticles?.slice(startIndex, endIndex);
        setArticles(articleArr);
        let filterList: any = [...filteredCategoryList];
        filterList = [];
        allArticles?.forEach((item: any) => {
          item?.filter_type !== "" &&
            item?.filter_type?.length > 0 &&
            item?.filter_type?.forEach((category: any) => {
              filterList?.indexOf(category) === -1 && filterList.push(category);
            });
        });
        setFilteredCategoryList(filterList);
      } else {
        articleArr = allArticles;
        setArticles(articleArr);
      }
    } else {
      setArticles([])
    }
  }, [allArticles]);

  useEffect(() => {
    let params = {
      [`filter_locale[${i18n.language}]`]: i18n.language,
      [`filter_bg[${currentDealer?.bgName}]`]: currentDealer?.bgName,
      [`filter_ecot[4_GB_CONVE]`]: "4_GB_CONVE",
      [`acf_like[main_content_category]`]: "TopNav",
      [`acf_like[content_category]`]: "TopNav>Help",
      max_result: "50",
    };
    if (parent === "Help") {
      dispatch(getArticlesFromCMS({ module: "help", params }));
      //TODO: params required for CMS API call: ?filter_locale[en_GB]=en_GB&filter_bg[GB200201]=GB200201&filter_ecot[4_GB_CONVE]=4_GB_CONVE&acf_like[main_content_category]=TopNav&acf_like[content_category]=TopNav>Help&max_result=50
    } else if (parent === "generalInformation") {
      params["acf_like[main_content_category]"] = "Service&Support";
      params["acf_like[content_category]"] =
        "Service&Support>GeneralInformation";
      dispatch(getArticlesFromCMS({ module: "generalInformation", params }));
      //TODO: params required for CMS API call: ?filter_locale[en_GB]=en_GB&filter_bg[GB200201]=GB200201&filter_ecot[4_GB_CONVE]=4_GB_CONVE&acf_like[main_content_category]=Service%26Support&acf_like[content_category]=Service%26Support>GeneralInformation&max_result=50
    } else if (parent === "home") {
      let paramObject = _.omit(params, "acf_like[content_category]");
      paramObject["acf_like[main_content_category]"] = "Home";
      paramObject["acf_like[rh_related_category]"] = "Newsletter";
      paramObject["filter_ecot[4_OLD]"] = "4_OLD";
      paramObject["max_result"] = "500";
      dispatch(getArticlesFromCMS({ module: "home", params: paramObject }));
      //TODO: params required for CMS API call: ?filter_locale[en_GB]=en_GB&filter_bg[GB200301]=GB200301&filter_ecot[4_OLD]=4_OLD&acf_like[rh_related_category]=Newsletter&acf_like[main_content_category]=Home&max_result=500
    } else {
      params["acf_like[content_category]"] = "TopNav>Links";
      dispatch(getArticlesFromCMS({ module: "links", params }));
      //TODO: params required for CMS API call: ?filter_locale[en_GB]=en_GB&filter_bg[GB200201]=GB200201&filter_ecot[4_GB_CONVE]=4_GB_CONVE&acf_like[main_content_category]=TopNav&acf_like[content_category]=TopNav>Links&max_result=50
    }
  }, []);

  const setImageMimeType = (mimeType: any) => {
    const image = require("../../../assets/images/" +
      mimeTypeImageMapping(mimeType));
    return image;
  };

  const formatValidationDatesMsg = (date1: any, date2: any) => {
    let formatMsg = t("dnet.valid_from_to.text").toString();
    formatMsg = formatMsg.replace("{0}", moment(date1).format("DD/MM/YYYY"));
    formatMsg = formatMsg.replace("{1}", moment(date2).format("DD/MM/YYYY"));
    return formatMsg;
  };

  const openNewTab = (url: any) => {
    window.open(url, "_blank");
    // window.open(url, "_blank", "noreferrer");
    // const anchorElement = document.createElement('a');
    // anchorElement.href = url;
    // anchorElement.target = '_blank';
    // anchorElement.rel = 'noreferrer';

    // document.body.appendChild(anchorElement);

    // anchorElement.click();

    // document.body.removeChild(anchorElement);
  };

  let contentArchiveMessage: any = t("dnet.homepage_pagination.text");
  contentArchiveMessage = contentArchiveMessage
    .split("{0}")
    .join(startIndex + 1);
  contentArchiveMessage = contentArchiveMessage
    .split("{1}")
    .join(endIndex > allArticles?.length ? allArticles?.length : endIndex);
  contentArchiveMessage = contentArchiveMessage
    .split("{2}")
    .join(allArticles?.length);

  const [selectedFilteredList, setSelectedFilteredList] = React.useState<
    string[]
  >([]);

  const handleChangeFilterList = (
    event: SelectChangeEvent<typeof selectedFilteredList>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedFilteredList(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const searchFunctionality = () => {
    let newSearchedTermArticles = [...articles];
    let presentArticles =
      selectedFilteredList?.length > 0 ? articles : allArticles;
    newSearchedTermArticles = presentArticles?.filter((item: any) => {
      return (
        item?.["related_items"]?.[0]?.article_title
          ?.toLowerCase()
          ?.includes(sonyArticleSearch.toLowerCase()) ||
        item?.["related_items"]?.[0]?.article_long_title
          ?.toLowerCase()
          ?.includes(sonyArticleSearch.toLowerCase()) ||
        item?.["related_items"]?.[0]?.introduction
          ?.toLowerCase()
          ?.includes(sonyArticleSearch.toLowerCase())
      );
    });
    if (newSearchedTermArticles?.length === 0) {
      let msg = t("dnet.homepage_articles_notfound.text");
      msg = msg?.split("{0}").join(sonyArticleSearch);
      setNoArticleMsg(msg);
    }
    setArticles(newSearchedTermArticles);
  };

  const categorizeArticles = () => {
    let newArticles = [...articles];
    let presentArticles =
      sonyArticleSearch?.length > 0 ? articles : allArticles;
    newArticles = presentArticles?.filter((item: any) => {
      return (
        item?.filter_type !== "" &&
        item?.filter_type?.length > 0 &&
        selectedFilteredList?.map((category: any) => {
          return item?.filter_type?.indexOf(category);
        })
      );
    });
    if (newArticles?.length === 0) {
      let msg = t("dnet.no_result_found.text");
      setNoArticleMsg(msg);
    }
    setArticles(newArticles);
  };

  const resetAllArticles = () => {
    let newSearchedTermArticles = [...articles];
    newSearchedTermArticles = allArticles?.slice(startIndex, endIndex);
    setArticles(newSearchedTermArticles);
  };

  useEffect(() => {
    if (sonyArticleSearch?.length > 0) {
      searchFunctionality();
    } else if (
      sonyArticleSearch?.length === 0 &&
      selectedFilteredList?.length < 1
    ) {
      resetAllArticles();
    } else if (
      selectedFilteredList?.length > 0 &&
      sonyArticleSearch?.length < 1
    ) {
      categorizeArticles();
    }
  }, [sonyArticleSearch]);

  useEffect(() => {
    if (selectedFilteredList?.length > 0) {
      categorizeArticles();
    } else if (
      selectedFilteredList?.length < 1 &&
      sonyArticleSearch?.length < 1
    ) {
      resetAllArticles();
    } else if (
      selectedFilteredList?.length === 0 &&
      sonyArticleSearch?.length > 0
    ) {
      searchFunctionality();
    }
  }, [selectedFilteredList]);

  const fetchCategoryName = (category: any) => {
    let tempArr = category?.split("_");
    const productMenuItems = sonyMenuItems?.filter(
      (item: any) => item?.sectionName === "DNET Products"
    );
    const categoryDeatail =
      productMenuItems?.length > 0
        ? productMenuItems[0]?.subMenu?.filter((item: any) => {
            return (
              item?.genericName.toLowerCase() ===
              (tempArr?.[tempArr?.length - 1]).toLowerCase()
            );
          })
        : [];
    return categoryDeatail?.length > 0
      ? categoryDeatail[0]?.label
      : tempArr?.[tempArr?.length - 1];
  };

  return (
    <div>
      {parent === "home" && allArticles?.length > 0 && (
        <div className={styles.searchBox}>
          {/*TODO: filter code implementation pending, data is not clear to implement */}
          <CustomTextField
            id="sonyArticles"
            type="search"
            value={sonyArticleSearch}
            changeHandler={(e: any) => {
              setSonyArticleSearch(e.target.value);
            }}
            label=""
            placeholder={t("dnet.search_in_sony1_articles.text")}
            additionalSxProperties={{ width: "350px", backgroundColor: "#fff" }}
          />
          {filteredCategoryList?.length > 0 && (
            <FormControl sx={{ m: 1, width: 300 }} size="small">
              <InputLabel id="multiple-checkbox-label">
                {t("dnet.filter_by_categories.text")}
              </InputLabel>
              <Select
                labelId="filter_category"
                id="filtercategory"
                multiple
                value={selectedFilteredList}
                onChange={handleChangeFilterList}
                input={
                  <OutlinedInput
                    label={t("dnet.filter_by_categories.text").toString()}
                  />
                }
                renderValue={(selected) => selected.join(", ")}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      width: 250,
                    },
                  },
                }}
                sx={{
                  borderRadius: "4px",
                  backgroundColor: "white",
                  fontSize: "15px",
                }}
                size="small"
              >
                {filteredCategoryList.map((item: any) => (
                  <MenuItem key={item} value={item}>
                    <Checkbox
                      checked={selectedFilteredList.indexOf(item) > -1}
                    />
                    <div className={styles?.[item]} />
                    <ListItemText primary={fetchCategoryName(item)} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {selectedFilteredList?.length > 0 && (
            <Typography
              variant="caption"
              className={styles.titleLink}
              onClick={() => {
                setSelectedFilteredList([]);
              }}
            >
              {t("dnet.unselect_all.text")}
            </Typography>
          )}
        </div>
      )}
      {articles?.length > 0 ? (
        articles?.map((article: any) => {
          return article?.["related_items"]?.map((item: any, key: any) => {
            return item?.acf_fc_layout === CMS?.ADD_ARTICLE ? (
              <div className={styles.boxStyle} key={key}>
                <div className={styles.innerBoxStyle}>
                  {parent === "home" ? (
                    <img
                      src={
                        item?.article_thumbnail_image &&
                        Object.keys(item?.article_thumbnail_image)?.length > 0
                          ? item?.article_thumbnail_image?.url
                          : sonyThumbnailImage
                      }
                      onClick={() => {
                        item?.article_attachment !== null &&
                        item?.article_attachment
                          ? openNewTab(item?.article_attachment?.url)
                          : item?.article_url === ""
                          ? navigate("/Details", {
                              state: {
                                selectedArticle: article,
                              },
                            })
                          : openNewTab(item?.article_url);
                      }}
                      style={{cursor: "pointer"}}
                      // style={{
                      //   width: item?.article_image?.width,
                      //   height: item?.article_image?.height,
                      // }}
                    />
                  ) : (
                    <img
                      src={
                        item?.article_thumbnail_image &&
                        Object.keys(item?.article_thumbnail_image)?.length >
                          0 &&
                        Object.keys(item?.article_thumbnail_image?.sizes)
                          ?.length > 0
                          ? item?.article_thumbnail_image?.sizes?.thumbnail !==
                              null &&
                            item?.article_thumbnail_image?.sizes?.thumbnail !==
                              ""
                            ? item?.article_thumbnail_image?.sizes?.thumbnail
                            : setImageMimeType(
                                item?.article_thumbnail_image?.mime_Type
                              )
                          : item?.article_thumbnail_image?.url
                      }
                      onClick={() => {
                        item?.article_attachment !== null &&
                        item?.article_attachment
                          ? openNewTab(item?.article_attachment?.url)
                          : item?.article_url === ""
                          ? navigate("/Details", {
                              state: {
                                selectedArticle: article,
                              },
                            })
                          : openNewTab(item?.article_url);
                      }}
                      style={{cursor: "pointer"}}
                      // style={{
                      //   width: item?.article_image?.width,
                      //   height: item?.article_image?.height,
                      // }}
                    />
                  )}
                  <div style={{width: "100%"}}>
                    <div className={styles.titleSection}>
                      <Typography
                        variant="subtitle2"
                        color="primary"
                        mb={2}
                        className={styles.titleLink}
                        onClick={() => {
                          item?.article_attachment !== null &&
                          item?.article_attachment
                            ? openNewTab(item?.article_attachment?.url)
                            : item?.article_url === ""
                            ? navigate("/Details", {
                                state: {
                                  selectedArticle: article,
                                },
                              })
                            : openNewTab(item?.article_url);
                        }}
                        dangerouslySetInnerHTML={{
                          __html: item?.article_title?.toString(),
                        }}
                      />
                        {/* {item?.article_title}
                      </Typography> */}
                      <div>
                        {article?.filter_type !== "" &&
                          article?.filter_type?.length > 0 &&
                          parent === "home" &&
                          article?.filter_type?.map((category: any) => {
                            return <div className={styles?.[category]} />;
                          })}
                      </div>
                    </div>
                    <Typography
                      variant="body2"
                      mb={1}
                      dangerouslySetInnerHTML={{
                        __html: item?.article_long_title?.toString(),
                      }}
                    />
                    {parent === "home" ? (
                      <Typography variant="body2" mb={1}>
                        {moment(article?.start_date).format("DD/MM/YYYY")}
                      </Typography>
                    ) : article?.isContextualPressReleaseInstance ? (
                      <Typography variant="body2" mb={1}>
                        {moment(article?.startDate).format("DD/MM/YYYY")}
                      </Typography>
                    ) : article?.isContextualPromotionInstance ? (
                      <div>
                        <Typography variant="body2" mb={1}>
                          {formatValidationDatesMsg(
                            article?.validationStartDate,
                            article?.validationEndDate
                          )}
                        </Typography>
                      </div>
                    ) : null}
                    <Typography
                      variant="body2"
                      mb={1}
                      dangerouslySetInnerHTML={{
                        __html: item?.introduction?.toString(),
                      }}
                    />
                  </div>
                </div>
                <div
                  className={styles.buttonStyle}
                  onClick={() => {
                    item?.article_attachment !== null &&
                    item?.article_attachment
                      ? openNewTab(item?.article_attachment?.url)
                      : item?.article_url === ""
                      ? navigate("/Details", {
                          state: {
                            selectedArticle: article,
                          },
                        })
                      : openNewTab(item?.article_url);
                  }}
                >
                  <Typography variant="body2" color="info.main">
                    {t("dnet.find_out_more.text")}
                  </Typography>
                  <ArrowRightIcon color="info" />
                </div>
              </div>
            ) : (
              item?.acf_fc_layout === "add_attachmentfile" && (
                <div className={styles.boxStyle}>
                  <div className={styles.innerBoxStyle}>
                    {parent === "home" ? (
                      <img
                        src={
                          item?.article_thumbnail_image &&
                          Object.keys(item?.article_thumbnail_image)?.length > 0
                            ? item?.article_thumbnail_image?.url
                            : sonyThumbnailImage
                        }
                        onClick={() => {
                          item?.file_upload !== null &&
                            item?.file_upload &&
                            openNewTab(item?.file_upload?.url);
                        }}
                        style={{cursor: "pointer"}}
                        // style={{
                        //   width: item?.article_image?.width,
                        //   height: item?.article_image?.height,
                        // }}
                      />
                    ) : (
                      <img
                        src={setImageMimeType(item?.file_upload?.mime_type)}
                        style={{
                          width: item?.article_image?.width,
                          height: item?.article_image?.height,
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          item?.file_upload !== null &&
                            item?.file_upload &&
                            openNewTab(item?.file_upload?.url);
                        }}
                      />
                    )}
                    <div className={styles.titleSection}>
                      <Typography
                        variant="subtitle2"
                        color="primary"
                        mb={2}
                        className={styles.titleLink}
                        onClick={() => {
                          openNewTab(item?.file_upload?.url);
                        }}
                      >
                        {item?.file_upload?.title}
                      </Typography>
                    </div>
                  </div>
                  <div
                    className={styles.buttonStyle}
                    onClick={() => {
                      item?.file_upload !== null &&
                        item?.file_upload &&
                        openNewTab(item?.file_upload?.url);
                    }}
                  >
                    <Typography variant="body2" color="info.main">
                      {t("dnet.find_out_more.text")}
                    </Typography>
                    <ArrowRightIcon color="info" />
                  </div>
                </div>
              )
            );
          });
        })
      ) : noArticleMsg?.length > 0 ? (
        <Typography variant="caption">{noArticleMsg}</Typography>
      ) : (
        allArticles?.length === 0 && (
          <Typography variant="caption">
            {t("dnet.homepage_articles_error.text")}
          </Typography>
        )
      )}
      {articles?.length > 0 &&
        parent === "home" &&
        sonyArticleSearch?.length < 1 &&
        selectedFilteredList?.length < 1 && (
          <div className={styles.pagination}>
            <Typography variant="body2">{contentArchiveMessage}</Typography>
            <Pagination
              count={Math.ceil(allArticles?.length / articlesPerPage)}
              page={currentPage}
              defaultPage={1}
              onChange={handleChangePage}
              color="primary"
              shape="rounded"
            />
          </div>
        )}
    </div>
  );
};
