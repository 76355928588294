import React from "react";
import { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  // TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  Checkbox,
  TextField,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import styles from "./orderDetailsTable.module.scss";
import { useTranslation } from "react-i18next";
import moment from "moment";
import LockIcon from "@mui/icons-material/Lock";
import { getCalendarData } from "../../../../../state/actions";
//@ts-ignore
import spacer from "../../../../../assets/images/spacer.gif";
//@ts-ignore
import blockedOrder from "../../../../../assets/images/blocked_order.jpg";
//@ts-ignore
import alertstar from "../../../../../assets/images/alertstar_002.gif";
import { shouldDisableDateFunc, sortNestedData } from "../../../../helper/util";
import { visuallyHidden } from "@mui/utils";
import { LINE_ITEM_ORDER_STATUS } from "../../../../../constants/commonConstants";
import CustomButton from "../../../../atoms/customButton";
import CustomTextField from "../../../../atoms/customTextField";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";
// var currencyFormatter = require("currency-formatter");
var _ = require("lodash");

export const OrderDetailsTable = ({
  formEditable,
  handleUpdate,
  updateComponent,
  confirmHandler,
  modifyHandler,
  handleShowPrice,
  showPrice,
}: any) => {
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState<any>("model");
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [qtyError, setQtyError] = useState<any>({});
  const contextDetails = useSelector(
    (state: any) => state?.commonReducer?.contextDetails
  );
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleChangePage = (event: unknown, newPage: number) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };
  const [firstColspan, setFirstColspan] = useState(9);
  // const [rows, setRows] = useState<any>([]);
  // const [showPrice, setShowPrice] = useState(true);
  const orderDetailsRes = useSelector(
    (state: any) => state?.reportingReducer?.orderDetails?.orders
  );
  // const reportedFilteredDetails = useSelector(
  //   (state: any) => state?.reportingReducer?.reportedFilteredDetails
  // );
  const [orderDetails, setOrderDetails]: any = useState({});
  useEffect(() => {
    if(!_.isEmpty(orderDetailsRes)) {
      let orderDetailsTemp: any = JSON.parse(JSON.stringify(orderDetailsRes));
      orderDetailsTemp?.lineItems?.map((line: any) => line.isDelete = false)
      setOrderDetails(JSON.parse(JSON.stringify(orderDetailsTemp)));
    }
  }, [orderDetailsRes]);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sortedData = order === "desc"
  ? orderDetails?.lineItems?.sort((a: any, b: any) => -sortNestedData(a, b, orderBy))
  : orderDetails?.lineItems?.sort((a: any, b: any) => sortNestedData(a, b, orderBy))
  interface Column {
    id:
      | "itemNo"
      | "productCategory"
      | "productSku_name"
      | "customerMaterialNumber"
      | "eanCode"
      | "editQuantity"
      | "reqDate"
      | "reqTotalQty"
      | "unitPrice"
      | "netPrice"
      | "specialPrice"
      | "confirmedQty"
      | "openQty"
      | "rejectCode"
      | "plannedDispatchDate"
      | "actualDispatchDate"
      | "plannedDeliveryDate"
      | "readyToDeliverQty"
      | "quantity"
      | "despatched"
      | "deliveryNo"
      | "carierRefNo"
      | "invoiceNo"
      | "lockStatus"
      | "blockStatus"
      | "deleteCol";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
  }

  const columns: readonly Column[] = [
    { id: "itemNo", label: t("dnet.item_number.text") },
    { id: "productCategory", label: t("dnet.order_report.category.text") },
    { id: "productSku_name", label: t("dnet.model.text") },
    { id: "customerMaterialNumber", label: t("customerMaterialNumber.text") },
    { id: "eanCode", label: t("dnet.order_report.ean_code.text") },
    { id: "editQuantity", label: t("dnet.quantity.text") },
    { id: "reqDate", label: t("dnet.order_report.requested_date.text") },
    {
      id: "reqTotalQty",
      label: t("dnet.order_report.requested_total_quantity.text"),
    },
    {
      id: "unitPrice",
      label: t("dnet.order_report.unit_price.text"),
    },
    {
      id: "netPrice",
      label: t("dnet.order_report.net_price.text"),
    },
    {
      id: "specialPrice",
      label: t("dnet.special_price.text"),
    },
    {
      id: "confirmedQty",
      label: t("dnet.order_report.confirmed_quantity.text"),
    },

    { id: "openQty", label: t("dnet.order_report.open_quantity.text") },
    { id: "rejectCode", label: t("dnet.order_report.rejection_code.text") },
    {
      id: "plannedDispatchDate",
      label: t("dnet.order_report.planned_dispatch_date.text"),
    },
    {
      id: "actualDispatchDate",
      label: t("dnet.order_report.actual_dispatch_date.text"),
    },
    {
      id: "plannedDeliveryDate",
      label: t("dnet.order_report.planned_delivery_date.text"),
    },
    {
      id: "readyToDeliverQty",
      label: t("dnet.order_report.ready_to_deliver_quantity.text"),
    },
    { id: "quantity", label: t("dnet.quantity.text") },
    { id: "despatched", label: t("dnet.despatched.text") },
    { id: "deliveryNo", label: t("dnet.delivery_no.text") },
    { id: "carierRefNo", label: t("dnet.carrier_reference_no.text") },
    { id: "invoiceNo", label: t("dnet.invoice_no.text") },
    { id: "lockStatus", label: t("dnet.padlock_alttext.text") },
    { id: "blockStatus", label: "" },
    { id: "deleteCol", label: t("dnet.delete.text") },
  ];

  const updateQuantity = (index: any, e: any) => {
    let placedOrder = { ...orderDetails };
    if (!isNaN(parseInt(e.target.value))) {
      if (parseInt(e.target.value) < 0) {
        placedOrder.lineItems[index].quantity = 0;
      } else {
        placedOrder.lineItems[index].quantity = parseInt(e?.target?.value);
      }
    } else {
      placedOrder.lineItems[index].quantity = 0;
    }
    let qtyErrorObj: any = { ...qtyError };
    qtyErrorObj = {};
    setQtyError(qtyErrorObj);
    if(placedOrder?.lineItems[index]?.quantity > orderDetailsRes?.lineItems[index]?.quantity)
      placedOrder.lineItems[index].quantity = orderDetailsRes?.lineItems[index]?.quantity;
    setOrderDetails(placedOrder);
  };

  const handleQuantityChange = (index: any, type: any) => {
    let placedOrder = { ...orderDetails };
    if (type === "increment") {
      if (placedOrder?.lineItems?.[index]?.quantity === "") {
        placedOrder.lineItems[index].quantity = 1;
      } else if (
        parseInt(placedOrder?.lineItems?.[index]?.quantity) <
          parseInt(orderDetailsRes?.lineItems?.[index]?.quantity)
      ) {
        placedOrder.lineItems[index].quantity =
          parseInt(placedOrder?.lineItems?.[index]?.quantity) + 1;
      }
    } else {
      if (
        placedOrder?.lineItems?.[index]?.quantity === "" ||
        placedOrder?.lineItems?.[index]?.quantity === "0" ||
        placedOrder?.lineItems?.[index]?.quantity === 0
      ) {
        placedOrder.lineItems[index].quantity = 0;
      } else if (parseInt(placedOrder?.lineItems?.[index]?.quantity) >= 1) {
        placedOrder.lineItems[index].quantity =
          parseInt(placedOrder?.lineItems?.[index]?.quantity) - 1;
      }
    }
    let qtyErrorObj: any = { ...qtyError };
    qtyErrorObj = {};
    setQtyError(qtyErrorObj);
    setOrderDetails(placedOrder);
  };

  const quantityColumn = (index: any, order: any) => {
    return (
      <Box>
        <Box className={styles.qtyButtonContaainer}>
          <CustomTextField
            additionalSxProperties={{
              width: "50px",
              height: "15px",
            }}
            label={""}
            value={order?.lineItems?.[index]?.quantity}
            changeHandler={(e: any) => {
              e.target.value = e.target.value.toString().slice(0, 6);
              updateQuantity(index, e);
            }}
          />
          <Box className={styles.qtyButtonContaainer}>
            <IconButton
              onClick={() => handleQuantityChange(index, "decrement")}
              title={t("dnet.decrement_info.text")?.toString()}
            >
              <RemoveCircleOutlineOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={() => handleQuantityChange(index, "increment")}
              title={t("dnet.increment_info.text")?.toString()}
            >
              <AddCircleOutlineOutlinedIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    let colspanValue = 9;
    if (contextDetails?.customerMaterialNumberEnabled) colspanValue++;
    if (contextDetails?.priceVisible) colspanValue++;
    if (contextDetails?.viewRejectedOrdersEnabled) colspanValue++;
    if (contextDetails?.isSpecialPriceEnabled) colspanValue++;
    setFirstColspan(colspanValue);
  }, [contextDetails]);

  const [basicCalender, setBasicCalender] = useState<any>(true)
  // const [startingMinDate, setStartingMinDate] = useState<any>(null);
  const calendarDetails = useSelector(
    (state: any) => state?.commonReducer?.calendarDetails
  );
  useEffect(() => {
    if(!_.isEmpty(contextDetails) && !_.isEmpty(orderDetailsRes)) {
      let reportShipToAccDetails = contextDetails?.shipToAccounts?.filter((acc: any) => 
        acc?.number === orderDetailsRes?.shipToAccount?.number
      )
      if(contextDetails?.isCustomCalendarEnabled) {
        setBasicCalender(false)
        let cdate = new Date();
        let calenderId = reportShipToAccDetails[0]?.calenderId
        let tempCalenderId = 
        calenderId === "" || calenderId === undefined || calenderId === null
          ? contextDetails?.defaultCalendarId 
          : calenderId
        dispatch(
          getCalendarData({
            calendarId: tempCalenderId,
            year: cdate.getFullYear(),
          })
        )
      } else {
        setBasicCalender(true)
      }
    }
    let currentDate = new Date();
    let twoDaysLater = new Date(currentDate);
    twoDaysLater.setDate(currentDate.getDate() + 2);
    // setStartingMinDate(twoDaysLater.toDateString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextDetails, orderDetailsRes]);

  const handleRDD = (value: any, index: any) => {
    let placedOrder = JSON.parse(JSON.stringify(orderDetails));
    placedOrder.lineItems[index].requestedDeliveryDate = new Date(value?.$d);
    let qtyErrorObj: any = { ...qtyError };
    qtyErrorObj = {};
    setQtyError(qtyErrorObj);
    setOrderDetails(placedOrder);
  };

  const handleMonthChange = (newMonth: any) => {
    // let yearObject = calendarDetails?.filter((item: any) => item?.year === date?.year()?.toString())
    // if (newMonth.$y !== selectedYear && !basicCalender) {
    //   setSelectedYear(newMonth.$y)
    //   dispatch(
    //     getCalendarData({
    //       calendarId: calenderId,
    //       year: newMonth.$y,
    //     })
    //   );
    // }
  }

  const shouldDisableDate = (date: any) => {
    return shouldDisableDateFunc(date, basicCalender, calendarDetails, true)
  }

  const handleUpdateClick = () => {
    let placedOrder = JSON.parse(JSON.stringify(orderDetails));
    let qtyErrorObj: any = { ...qtyError };
    let orderStatusError = "";
    let overallNoChange = true;
    for (let i = 0; i < placedOrder?.lineItems?.length; i++) {
      if (
        placedOrder?.lineItems?.[i]?.quantity >
        orderDetailsRes?.lineItems?.[i]?.quantity
      ) {
        qtyErrorObj = {
          [i]: t("dnet.quantity_error_more.text"),
        };
      } else if (
        placedOrder?.lineItems?.[i]?.quantity === "0" ||
        placedOrder?.lineItems?.[i]?.quantity === 0 ||
        placedOrder?.lineItems?.[i]?.quantity === ""
      ) {
        qtyErrorObj = {
          [i]: t("dnet.specify_quantity.text"),
        };
      } else if (placedOrder?.lineItems?.[i]?.requestedDeliveryDate === "") {
        qtyErrorObj = {
          [i + "date"]: t("dnet.specify_rdd.text"),
        };
      }
      if (
        moment(placedOrder?.lineItems?.[i]?.requestedDeliveryDate).format(
          "DD-MM-YYYY"
        ) ===
          moment(orderDetailsRes?.lineItems?.[i]?.requestedDeliveryDate).format(
            "DD-MM-YYYY"
          ) &&
        placedOrder?.lineItems?.[i]?.quantity ===
          orderDetailsRes?.lineItems?.[i]?.quantity &&
        placedOrder?.lineItems?.[i]?.isDelete === false &&
        overallNoChange
      ) {
        overallNoChange = true
      } else {
        overallNoChange = false
      }
    }
    orderStatusError = overallNoChange ? t("dnet.order_no_change.text") : ""; 
    setQtyError(qtyErrorObj);
    handleUpdate(placedOrder, orderStatusError, qtyErrorObj);
  };

  // const [timerStarted, setTimerStarted] = useState(false);

  // const handleLink = (number: any, linkName: any) => {
  //   let link: any = "";
  //   if (linkName === "infodis") {
  //     link = `${contextDetails?.infodisUrl}${number}`;
  //   } else if (linkName === "schenker") {
  //     link = `${contextDetails?.schenkerUrl}${number}`;
  //   } else if (linkName === "tnt") {
  //     link = `${contextDetails?.tntUrl}${number}`;
  //   } else {
  //     link = `${contextDetails?.dpdUrl}${number}`;
  //   }
  //   if (!timerStarted) {
  //     setTimerStarted(true);
  //     // Wait for 2000 milliseconds (2 seconds)
  //     setTimeout(() => {
  //       // Below code is to trigger link
  //       window.open();
  //       setTimerStarted(false);
  //     }, 2000);
  //   }
  // };

  const isEditableCheckFunction = (lineItem: any) => {
    let editable = false
    if(lineItem?.scheduleLines?.length > 0) {
      let totalDeliveredQty = 0;
      let deliveryCheck = false
      for(let i=0; i<lineItem?.scheduleLines?.length; i++) {
        if(lineItem?.scheduleLines[i]?.deliveryStatus !== null && 
          lineItem?.scheduleLines[i]?.deliveryStatus !== "") {
            deliveryCheck = true;
          }
        totalDeliveredQty += lineItem?.scheduleLines[i]?.quantity;
        if (lineItem?.scheduleLines[i]?.orderStatus === null || 
        lineItem?.scheduleLines[i]?.orderStatus === LINE_ITEM_ORDER_STATUS?.STATUS_AMENDABLE ||
        lineItem?.scheduleLines[i]?.orderStatus === LINE_ITEM_ORDER_STATUS?.STATUS_MATERIAL_ON_SHORTAGE) 
          editable = true;
      }
      if(deliveryCheck) {
          if (totalDeliveredQty === lineItem?.quantity)
            editable = false;
      }
    }
    
    return editable;
  }

  const handleDelete = (key: any, deleteValue: any) => {
    let placedOrder = { ...orderDetails };
    placedOrder.lineItems[key].isDelete = deleteValue;
    setOrderDetails(placedOrder);
  }

  const [deleteAll, setDeleteAll] = useState(false);

  const handleDeleteAll = () => { 
    let placedOrder = { ...orderDetails };
    if (placedOrder?.lineItems?.length > 0) {
      placedOrder?.lineItems?.map((item: any) => {
        return item.isDelete = !deleteAll
      })
    }
    setOrderDetails(placedOrder);
    setDeleteAll(!deleteAll);
  }
  return (
    <div>
      {formEditable && !updateComponent && (
        <div className={styles.buttonSection}>
          <CustomButton
            id="updateButton"
            label={t("dnet.update.text")}
            onClick={handleUpdateClick}
            color="primary"
          />
        </div>
      )}
      <TableContainer component={Paper} className={styles.tableContainerHeader}>
        <Table sx={{ minWidth: 650 }} aria-label="Reports Table">
          <TableHead>
            <TableRow>
              <TableCell
                key="1"
                align="center"
                colSpan={formEditable ? 5 : firstColspan}
                style={{
                  borderRight:"1px solid #c8c8c8",
                }}
              >
                {t("dnet.order.text")}
              </TableCell>
              <TableCell key="2" align="center" colSpan={formEditable ? 4 : 9}>
                {t("dnet.delivery.text")}
              </TableCell>
            </TableRow>
            <TableRow>
              {columns.map((column) => {
                return column?.id === "productSku_name" ? (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ) : column?.id === "productCategory" ||
                  column?.id === "eanCode" ||
                  column?.id === "reqTotalQty" ||
                  column?.id === "confirmedQty" ||
                  column?.id === "openQty" ||
                  column?.id === "plannedDispatchDate" ||
                  column?.id === "actualDispatchDate" ||
                  column?.id === "plannedDeliveryDate" ||
                  column?.id === "readyToDeliverQty" ||
                  column?.id === "carierRefNo" ||
                  column?.id === "invoiceNo" ? (
                  !formEditable && (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  )
                ) : column?.id === "editQuantity" ? (
                  formEditable && (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: "100px" }}
                    >
                      {column.label}
                    </TableCell>
                  )
                ) : column?.id === "quantity" ||
                  column?.id === "despatched" ||
                  column?.id === "deleteCol" ? (
                  formEditable && (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {(column?.id === "deleteCol" && !updateComponent) ?
                      <div 
                        onClick={()=>handleDeleteAll()}
                        className={styles.deleteAll}
                      >
                        {column.label}
                      </div> :
                      column?.id !== "deleteCol" ? (
                        column.label
                      ) : (
                        <IconButton>
                          <LockIcon color="primary" />
                        </IconButton>
                      )}
                    </TableCell>
                  )
                ) : column?.id === "customerMaterialNumber" ? (
                  contextDetails?.customerMaterialNumberEnabled &&
                  !formEditable && (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  )
                ) : column?.id === "unitPrice" ? (
                  contextDetails?.priceVisible && (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, color: "#5887f5" }}
                      className={styles.colorBlue}
                      onClick={() => handleShowPrice()}
                    >
                      {column.label}
                      <img src={alertstar} alt="alertstar"/>
                    </TableCell>
                  )
                ) : column?.id === "netPrice" ? (
                  !formEditable && (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, color: "#5887f5" }}
                      className={styles.colorBlue}
                      onClick={() => handleShowPrice()}
                    >
                      {column.label}
                      <img src={alertstar} alt="alertstar"/>
                    </TableCell>
                  )
                ) : column?.id === "specialPrice" ? (
                  contextDetails?.isSpecialPriceEnabled &&
                  !formEditable && (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  )
                ) : column?.id === "rejectCode" ? (
                  contextDetails?.viewRejectedOrdersEnabled &&
                  !formEditable && (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  )
                ) : column?.id === "lockStatus" ? (
                  !formEditable && (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Tooltip
                        title={column?.label}
                        arrow
                        placement="left"
                        enterTouchDelay={0}
                      >
                        <IconButton>
                          <LockIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  )
                ) : column?.id === "blockStatus" ? (
                  !formEditable && (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <img src={blockedOrder} alt="blockedOrder"/>
                    </TableCell>
                  )
                ) : (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {orderDetails?.lineItems?.length > 0 ? (
              <>
                {
                // sortedData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                sortedData?.map((order: any, key: any) => {
                    return (
                      <React.Fragment key={key}>
                        {order?.scheduleLines?.length > 0 ? (
                          order?.scheduleLines?.map(
                            (subElem: any, index: any) => {
                              return (
                                ((!formEditable && !updateComponent) || 
                              ((formEditable || updateComponent) &&
                              subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED))
                              &&
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={`row-${key}-cell-${index}`}
                                >
                                  {index === 0 && (
                                    <TableCell
                                      rowSpan={
                                        order?.scheduleLines &&
                                        order?.scheduleLines?.length > 1
                                          ? order?.scheduleLines?.length
                                          : 1
                                      }
                                    >
                                      {parseInt(order?.index, 10).toString()}
                                    </TableCell>
                                  )}
                                  {index === 0 && !formEditable && (
                                    <TableCell
                                      rowSpan={
                                        order?.scheduleLines &&
                                        order?.scheduleLines?.length > 1
                                          ? order?.scheduleLines?.length
                                          : 1
                                      }
                                    >
                                      {order?.productCategoryRootPathList
                                        ?.length >= 2
                                        ? order?.productCategoryRootPathList[
                                            order?.productCategoryRootPathList
                                              ?.length - 2
                                          ]
                                        : order?.productSku?.productHierarchy}
                                    </TableCell>
                                  )}
                                  {index === 0 && (
                                    <TableCell
                                      rowSpan={
                                        order?.scheduleLines &&
                                        order?.scheduleLines?.length > 1
                                          ? order?.scheduleLines?.length
                                          : 1
                                      }
                                    >
                                      {order?.productSku?.name}
                                    </TableCell>
                                  )}
                                  {index === 0 &&
                                    contextDetails?.customerMaterialNumberEnabled &&
                                    !formEditable && (
                                      <TableCell
                                        rowSpan={
                                          order?.scheduleLines &&
                                          order?.scheduleLines?.length > 1
                                            ? order?.scheduleLines?.length
                                            : 1
                                        }
                                      >
                                        {order?.customerMaterialNumber}
                                      </TableCell>
                                    )}
                                  {index === 0 && !formEditable && (
                                    <TableCell
                                      rowSpan={
                                        order?.scheduleLines &&
                                        order?.scheduleLines?.length > 1
                                          ? order?.scheduleLines?.length
                                          : 1
                                      }
                                    >
                                      {order?.productSku?.EANCode}
                                    </TableCell>
                                  )}
                                  {index === 0 &&
                                  formEditable &&
                                  !updateComponent &&
                                  isEditableCheckFunction(order) &&
                                  !order?.productMissing ? (
                                    <TableCell
                                      rowSpan={
                                        order?.scheduleLines &&
                                        order?.scheduleLines?.length > 1
                                          ? order?.scheduleLines?.length
                                          : 1
                                      }
                                    >
                                      {quantityColumn(key, orderDetails)}
                                      {Object.keys(qtyError)?.length > 0 && (
                                        <Typography
                                          variant="caption"
                                          sx={{ fontWeight: "bold" }}
                                          color="warning.main"
                                        >
                                          {qtyError?.[key]}
                                        </Typography>
                                      )}
                                    </TableCell>
                                  ) : (
                                    (formEditable || updateComponent) &&
                                    index === 0 && (
                                      <TableCell
                                        rowSpan={
                                          order?.scheduleLines &&
                                          order?.scheduleLines?.length > 1
                                            ? order?.scheduleLines?.length
                                            : 1
                                        }
                                      >
                                        {order?.quantity}
                                      </TableCell>
                                    )
                                  )}
                                  {
                                    index === 0 && 
                                        <TableCell
                                          rowSpan={
                                            order?.scheduleLines &&
                                            order?.scheduleLines?.length > 1
                                              ? order?.scheduleLines?.length
                                              : 1
                                          }
                                        >
                                    {isEditableCheckFunction(orderDetails?.lineItems?.[key]) &&
                                      !orderDetails?.lineItems?.[key]
                                      ?.productMissing &&
                                      !(subElem?.deliveryStatus !== null && 
                                      subElem?.deliveryStatus !== "" )
                                      && formEditable && !updateComponent ?
                                          <>
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                          >
                                            <DatePicker
                                              openTo="day"
                                              views={["year", "month", "day"]}
                                              value={
                                                orderDetails?.lineItems?.[key]
                                                  ?.requestedDeliveryDate
                                              }
                                              onChange={(newValue: any) => {
                                                handleRDD(newValue, key);
                                              }}
                                              renderInput={(params) => (
                                                <TextField 
                                                  {...params}
                                                  inputProps={{...params.inputProps, readOnly: true}}
                                                  size="small"
                                                />
                                              )}
                                              inputFormat="DD/MM/YYYY"
                                              minDate={orderDetailsRes?.lineItems?.[key]
                                                ?.requestedDeliveryDate}
                                              shouldDisableDate={shouldDisableDate}
                                              onMonthChange={handleMonthChange}
                                            />
                                          </LocalizationProvider>
                                          {Object.keys(qtyError)?.length >
                                            0 && (
                                            <Typography
                                              variant="caption"
                                              sx={{ fontWeight: "bold" }}
                                              color="warning.main"
                                            >
                                              {qtyError?.[key + "date"]}
                                            </Typography>
                                          )}
                                        </> 
                                        :
                                        <>
                                          {moment(order?.requestedDeliveryDate).format("DD/MM/YYYY")}
                                        </>
                                      }</TableCell>}
                                  {/* {index === 0 && (
                                  <TableCell
                                    rowSpan={
                                      order?.scheduleLines &&
                                      order?.scheduleLines?.length > 1
                                        ? order?.scheduleLines?.length
                                        : 1
                                    }
                                  >
                                    {order?.requestedDeliveryDate !== ""
                                      ? moment(
                                          order?.requestedDeliveryDate
                                        ).format("DD-MMM-YYYY")
                                      : ""}
                                  </TableCell>
                                )} */}
                                  {index === 0 && !formEditable && (
                                    <TableCell
                                      rowSpan={
                                        order?.scheduleLines &&
                                        order?.scheduleLines?.length > 1
                                          ? order?.scheduleLines?.length
                                          : 1
                                      }
                                    >
                                      {order?.quantity}
                                    </TableCell>
                                  )}
                                  {index === 0 &&
                                    contextDetails?.priceVisible && (
                                      <TableCell
                                        rowSpan={
                                          order?.scheduleLines &&
                                          order?.scheduleLines?.length > 1
                                            ? order?.scheduleLines?.length
                                            : 1
                                        }
                                      >
                                        {showPrice &&
                                          // currencyFormatter.format(
                                          //   order?.unitPrice?.amount,
                                          //   {
                                          //     code: order?.unitPrice?.currency,
                                          //   }
                                          // )
                                          `${order?.unitPrice?.currency} ${order?.unitPrice?.amount}`}
                                      </TableCell>
                                    )}
                                  {index === 0 && !formEditable && (
                                    <TableCell
                                      rowSpan={
                                        order?.scheduleLines &&
                                        order?.scheduleLines?.length > 1
                                          ? order?.scheduleLines?.length
                                          : 1
                                      }
                                    >
                                      {contextDetails?.priceVisible &&
                                        showPrice &&
                                        // currencyFormatter.format(
                                        //   order?.netValuePrice?.amount,
                                        //   {
                                        //     code: order?.netValuePrice?.currency,
                                        //   }
                                        // )
                                        `${order?.netValuePrice?.currency} ${order?.netValuePrice?.amount}`}
                                    </TableCell>
                                  )}
                                  {index === 0 &&
                                    contextDetails?.isSpecialPriceEnabled &&
                                    !formEditable && (
                                      <TableCell
                                        rowSpan={
                                          order?.scheduleLines &&
                                          order?.scheduleLines?.length > 1
                                            ? order?.scheduleLines?.length
                                            : 1
                                        }
                                      >
                                        {
                                          order?.specialPrice?.amount
                                            ? order?.specialPrice?.amount
                                            : "" 
                                        }
                                      </TableCell>
                                    )}
                                  {index === 0 && !formEditable && (
                                    <TableCell
                                      rowSpan={
                                        order?.scheduleLines &&
                                        order?.scheduleLines?.length > 1
                                          ? order?.scheduleLines?.length
                                          : 1
                                      }
                                    >
                                      {order?.confirmedQuantity}
                                    </TableCell>
                                  )}
                                  {index === 0 && !formEditable && (
                                    <TableCell
                                      rowSpan={
                                        order?.scheduleLines &&
                                        order?.scheduleLines?.length > 1
                                          ? order?.scheduleLines?.length
                                          : 1
                                      }
                                    >
                                      {order?.openQuantity}
                                    </TableCell>
                                  )}
                                  {index === 0 &&
                                    contextDetails?.viewRejectedOrdersEnabled &&
                                    !formEditable && (
                                      <TableCell
                                        rowSpan={
                                          order?.scheduleLines &&
                                          order?.scheduleLines?.length > 1
                                            ? order?.scheduleLines?.length
                                            : 1
                                        }
                                      >
                                        {order?.rejectionCode === null
                                          ? ""
                                          : order?.rejectionCode}
                                      </TableCell>
                                    )}
                                  {!formEditable && (
                                    <TableCell>
                                      {subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED
                                        ? subElem?.confirmed
                                          ? subElem?.plannedIssueDate === "" ? "" :
                                            moment(
                                              subElem?.plannedIssueDate
                                            ).format("DD-MMM-YYYY")
                                          : t("dnet.unconfirmed.text")
                                        : ""}
                                    </TableCell>
                                  )}
                                  {!formEditable && (
                                    <TableCell>
                                      {subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED &&
                                      subElem?.goodsIssueDate !== ""
                                        ? moment(
                                            subElem?.goodsIssueDate
                                          ).format("DD-MMM-YYYY")
                                        : ""}
                                    </TableCell>
                                  )}
                                  {!formEditable && (
                                    <TableCell>
                                      {subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED &&
                                      subElem?.plannedDeliveryDate !== ""
                                        ? moment(
                                            subElem?.plannedDeliveryDate
                                          ).format("DD-MMM-YYYY")
                                        : ""}
                                    </TableCell>
                                  )}
                                  {!formEditable && (
                                    <TableCell>
                                      {subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED
                                        ? subElem?.quantity
                                        : ""}
                                    </TableCell>
                                  )}
                                  {formEditable && (
                                    <TableCell>
                                      {subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED
                                        ? subElem?.quantity
                                        : ""}
                                    </TableCell>
                                  )}
                                  {formEditable && (
                                    <TableCell>
                                      {subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED &&
                                        subElem?.goodsIssueDate}
                                    </TableCell>
                                  )}
                                  <TableCell>
                                    {!updateComponent && !formEditable ? (
                                      subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED &&
                                      //&& updateComponent
                                      // ? subElem?.carrierTrackingNumber
                                      // : subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED
                                      subElem?.[0]?.deliveryNumber !== "" && 
                                      subElem?.[0]?.deliveryNumber !== null &&
                                      subElem?.[0]?.deliveryStatus !== "" && 
                                      subElem?.[0]?.deliveryStatus !== null ? (
                                        contextDetails?.infodisUrl!=="" ? (
                                          contextDetails?.urlType === "tnt" &&
                                          !subElem?.carrierTrackingNumber &&
                                          subElem?.carrierTrackingNumber !==
                                            "" ? (
                                            subElem?.deliveryNumber
                                          ) : (
                                            // <div
                                            //   onClick={() =>
                                            //     handleLink(
                                            //       subElem?.deliveryNumber,
                                            //       "infodis"
                                            //     )
                                            //   }
                                            //   className={styles.colorBlue}
                                            // >
                                            contextDetails?.infodisUrl ? 
                                            <a 
                                              className={styles.colorBlue} 
                                              href={`${
                                                contextDetails?.infodisUrl
                                                }${
                                                  subElem?.deliveryNumber
                                                }`
                                              } 
                                              target="_blank" 
                                              rel="noreferrer">
                                                {subElem?.deliveryNumber}
                                            </a> : subElem?.deliveryNumber
                                            // </div>
                                          )
                                        ) : (
                                          subElem?.deliveryNumber
                                        )
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      subElem?.deliveryNumber
                                    )}
                                  </TableCell>
                                  {!formEditable && (
                                    <TableCell>
                                      {
                                        subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED &&
                                          (subElem?.billOfLadingNumber !== "" &&
                                          subElem?.billOfLadingNumber !== null &&
                                          subElem?.deliveryStatus !== "" &&
                                          subElem?.deliveryStatus !== null ? (
                                            contextDetails?.tntUrl!=="" ||
                                            contextDetails?.dpdUrl!=="" ||
                                            contextDetails?.schenkerUrl!=="" ? (
                                              subElem?.carrierPartnerName?.toLowerCase() ===
                                              "tnt" ? (
                                                // <div
                                                //   onClick={() =>
                                                //     handleLink(
                                                //       subElem?.billOfLadingNumber,
                                                //       "tnt"
                                                //     )
                                                //   }
                                                //   className={styles.colorBlue}
                                                // >
                                                contextDetails?.tntUrl ? 
                                                <a 
                                                  className={styles.colorBlue} 
                                                  href={`${
                                                    contextDetails?.tntUrl
                                                    }${
                                                      subElem?.billOfLadingNumber
                                                    }`
                                                  } 
                                                  target="_blank" 
                                                  rel="noreferrer">
                                                    {subElem?.billOfLadingNumber}
                                                </a> : subElem?.billOfLadingNumber
                                                // </div>
                                              ) : subElem?.carrierPartnerName?.toLowerCase() ===
                                                "direct" ? (
                                                // <div
                                                //   onClick={() =>
                                                //     handleLink(
                                                //       subElem?.billOfLadingNumber,
                                                //       "dpd"
                                                //     )
                                                //   }
                                                //   className={styles.colorBlue}
                                                // >
                                                contextDetails?.dpdUrl ? 
                                                <a 
                                                  className={styles.colorBlue} 
                                                  href={`${
                                                    contextDetails?.dpdUrl
                                                    }${
                                                      subElem?.billOfLadingNumber
                                                    }`
                                                  } 
                                                  target="_blank" 
                                                  rel="noreferrer">
                                                    {subElem?.billOfLadingNumber}
                                                </a> : subElem?.billOfLadingNumber
                                                // </div>
                                              ) : subElem?.carrierPartnerName?.toLowerCase() ===
                                                  "schenker" &&
                                                subElem?.deliveryNumber !== "" &&
                                                subElem?.deliveryNumber !== null ? (
                                                // <div
                                                //   onClick={() =>
                                                //     handleLink(
                                                //       subElem?.deliveryNumber,
                                                //       "schenker"
                                                //     )
                                                //   }
                                                //   className={styles.colorBlue}
                                                // >
                                                contextDetails?.schenkerUrl ? 
                                                <a 
                                                  className={styles.colorBlue} 
                                                  href={`${
                                                    contextDetails?.schenkerUrl
                                                    }${
                                                      subElem?.deliveryNumber
                                                    }`
                                                  } 
                                                  target="_blank" 
                                                  rel="noreferrer">
                                                    {subElem?.deliveryNumber}
                                                </a> : subElem?.deliveryNumber
                                                // </div>
                                              ) : (
                                                <div>
                                                  {subElem?.billOfLadingNumber}
                                                  <Tooltip
                                                    title={
                                                      t(
                                                        "dnet.carrier_partner_name.text"
                                                      )?.toString() +
                                                      " " +
                                                      subElem?.carrierPartnerName
                                                    }
                                                    placement="right-start"
                                                    arrow
                                                  >
                                                    <IconButton
                                                      className={
                                                        styles.infoIcon
                                                      }
                                                    >
                                                      <HelpOutlinedIcon />
                                                    </IconButton>
                                                  </Tooltip>
                                                </div>
                                              ) 
                                            ) : (
                                              subElem?.billOfLadingNumber
                                            )
                                          ) : (
                                            (subElem?.billOfLadingNumber === "" || 
                                            subElem?.billOfLadingNumber === null) &&
                                            subElem?.deliveryNumber !== "" &&
                                            subElem?.deliveryNumber !== null &&
                                            subElem?.deliveryStatus !== "" &&
                                            subElem?.deliveryStatus !== null &&
                                            contextDetails?.schenkerUrl!=="" &&
                                            subElem?.carrierPartnerName ===
                                              "schenker" && (
                                              // <div
                                              //   onClick={() =>
                                              //     handleLink(
                                              //       subElem?.deliveryNumber,
                                              //       "schenker"
                                              //     )
                                              //   }
                                              //   className={styles.colorBlue}
                                              // >
                                              contextDetails?.schenkerUrl ? 
                                              <a 
                                                className={styles.colorBlue} 
                                                href={`${
                                                  contextDetails?.schenkerUrl
                                                  }${
                                                    subElem?.deliveryNumber
                                                  }`
                                                } 
                                                target="_blank" 
                                                rel="noreferrer">
                                                  {subElem?.deliveryNumber}
                                              </a> : subElem?.deliveryNumber
                                              // </div>
                                            )
                                          )) 
                                      }
                                    </TableCell>
                                  )}
                                  {!formEditable && (
                                    <TableCell>
                                      {subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED
                                        ? subElem?.invoiceNumber
                                        : ""}
                                    </TableCell>
                                  )}
                                  {!formEditable && (
                                    <TableCell>
                                      {subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED &&
                                      subElem?.deliveryStatus !== "" &&
                                      subElem?.deliveryStatus !== null ? (
                                        <Tooltip
                                          title={
                                            subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED &&
                                            subElem?.deliveryStatus !== "" &&
                                            subElem?.deliveryStatus !== null
                                              ? t(
                                                  "dnet.padlock_teaser_alttext.text"
                                                )
                                              : ""
                                          }
                                          arrow
                                          placement="left"
                                          enterTouchDelay={0}
                                        >
                                          <IconButton>
                                            <LockIcon color="primary" />
                                          </IconButton>
                                        </Tooltip>
                                      ) : (
                                        <img src={spacer} alt="spacer"/>
                                      )}
                                    </TableCell>
                                  )}
                                  {!formEditable && (
                                    <TableCell>
                                      {subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED &&
                                      subElem?.documentHeader !== "" &&
                                      subElem?.documentHeader !== null ? (
                                        <img
                                          src={blockedOrder}
                                          alt={
                                              t(
                                                "dnet.blocked_delivery_alttext.text"
                                              )?.toString()
                                              
                                          }
                                        />
                                      ) : subElem?.orderStatus !== LINE_ITEM_ORDER_STATUS?.STATUS_DELETED && (
                                        <img src={spacer} alt="spacer"/>
                                      )}
                                    </TableCell>
                                  )}
                                  {index === 0 &&
                                  (formEditable ||
                                  updateComponent) && (
                                    <TableCell
                                      rowSpan={
                                        order?.scheduleLines &&
                                        order?.scheduleLines?.length > 1
                                          ? order?.scheduleLines?.length
                                          : 1
                                      }
                                    >
                                  {
                                    isEditableCheckFunction(orderDetails?.lineItems?.[key]) &&
                                    !(subElem?.deliveryStatus !== null && subElem?.deliveryStatus !== "")
                                    && formEditable && !updateComponent ? 
                                    <Checkbox 
                                      checked={orderDetails?.lineItems?.[key]?.isDelete} 
                                      onChange={(e)=>handleDelete(key, e.target.checked)}
                                    /> : 
                                    subElem?.deliveryStatus !== null && subElem?.deliveryStatus !== "" ?
                                    <Tooltip
                                      title="OrderLocked"
                                      arrow
                                      placement="bottom"
                                      enterTouchDelay={0}
                                    >
                                      <IconButton>
                                        <LockIcon color="primary" />
                                      </IconButton>
                                    </Tooltip> : ""
                                  }
                                    </TableCell>
                                  ) }
                                </TableRow>
                              );
                            }
                          )
                        ) : (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={`row-${key}`}
                          >
                            {
                              <TableCell
                                rowSpan={
                                  order?.scheduleLines &&
                                  order?.scheduleLines?.length > 1
                                    ? order?.scheduleLines?.length
                                    : 1
                                }
                              >
                                {parseInt(order?.index, 10).toString()}
                              </TableCell>
                            }
                            {!formEditable && (
                              <TableCell
                                rowSpan={
                                  order?.scheduleLines &&
                                  order?.scheduleLines?.length > 1
                                    ? order?.scheduleLines?.length
                                    : 1
                                }
                              >
                                {order?.productCategoryRootPathList?.length >= 2
                                  ? order?.productCategoryRootPathList[
                                      order?.productCategoryRootPathList
                                        ?.length - 2
                                    ]
                                  : order?.productSku?.productHierarchy}
                              </TableCell>
                            )}
                            {
                              <TableCell
                                rowSpan={
                                  order?.scheduleLines &&
                                  order?.scheduleLines?.length > 1
                                    ? order?.scheduleLines?.length
                                    : 1
                                }
                              >
                                {order?.productSku?.name}
                              </TableCell>
                            }
                            {contextDetails?.customerMaterialNumberEnabled &&
                              !formEditable && (
                                <TableCell
                                  rowSpan={
                                    order?.scheduleLines &&
                                    order?.scheduleLines?.length > 1
                                      ? order?.scheduleLines?.length
                                      : 1
                                  }
                                >
                                  {order?.customerMaterialNumber}
                                </TableCell>
                              )}
                            {!formEditable && (
                              <TableCell
                                rowSpan={
                                  order?.scheduleLines &&
                                  order?.scheduleLines?.length > 1
                                    ? order?.scheduleLines?.length
                                    : 1
                                }
                              >
                                {order?.productSku?.EANCode}
                              </TableCell>
                            )}
                            {formEditable &&
                            !updateComponent &&
                              isEditableCheckFunction(order) &&
                              !order?.productMissing ? (
                              <TableCell
                                rowSpan={
                                  order?.scheduleLines &&
                                  order?.scheduleLines?.length > 1
                                    ? order?.scheduleLines?.length
                                    : 1
                                }
                              >
                                {quantityColumn(key, orderDetails)}
                                {Object.keys(qtyError)?.length > 0 && (
                                  <Typography
                                    variant="caption"
                                    sx={{ fontWeight: "bold" }}
                                    color="warning.main"
                                  >
                                    {qtyError?.[key]}
                                  </Typography>
                                )}
                              </TableCell>
                            ) : (
                              (formEditable || updateComponent) && (
                                <TableCell
                                  rowSpan={
                                    order?.scheduleLines &&
                                    order?.scheduleLines?.length > 1
                                      ? order?.scheduleLines?.length
                                      : 1
                                  }
                                >
                                  {order?.quantity}
                                </TableCell>
                              )
                            )}
                            {updateComponent ||
                            !formEditable ||
                            (formEditable &&
                              isEditableCheckFunction(orderDetails?.lineItems?.[key]) &&
                              !orderDetails?.lineItems?.[key]
                                ?.productMissing 
                                // &&
                                // !(subElem?.deliveryStatus !== null && 
                                // subElem?.deliveryStatus !== "" )
                            ) ? (
                              <TableCell
                                rowSpan={
                                  order?.scheduleLines &&
                                  order?.scheduleLines?.length > 1
                                    ? order?.scheduleLines?.length
                                    : 1
                                }
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    //   label={t("dnet.start_with.text")}
                                    openTo="day"
                                    views={["year", "month", "day"]}
                                    value={
                                      orderDetails?.lineItems?.[key]
                                        ?.requestedDeliveryDate
                                    }
                                    onChange={(newValue: any) => {
                                      handleRDD(newValue, key);
                                    }}
                                    renderInput={(params) => (
                                      <TextField 
                                        {...params}
                                        inputProps={{...params.inputProps, readOnly: true}}
                                        size="small"
                                      />
                                    )}
                                    inputFormat="DD/MM/YYYY"
                                    minDate={orderDetailsRes?.lineItems?.[key]
                                      ?.requestedDeliveryDate}
                                    shouldDisableDate={shouldDisableDate}
                                    onMonthChange={handleMonthChange}
                                    //   disabled={formEditable}
                                  />
                                </LocalizationProvider>
                                {Object.keys(qtyError)?.length > 0 && (
                                  <Typography
                                    variant="caption"
                                    sx={{ fontWeight: "bold" }}
                                    color="warning.main"
                                  >
                                    {qtyError?.[key + "date"]}
                                  </Typography>
                                )}
                              </TableCell>
                            ) : (
                              <TableCell
                                rowSpan={
                                  order?.scheduleLines &&
                                  order?.scheduleLines?.length > 1
                                    ? order?.scheduleLines?.length
                                    : 1
                                }
                              >
                                {moment(order?.requestedDeliveryDate).format("DD/MM/YYYY")}
                              </TableCell>
                            )}
                            {!formEditable && (
                              <TableCell
                                rowSpan={
                                  order?.scheduleLines &&
                                  order?.scheduleLines?.length > 1
                                    ? order?.scheduleLines?.length
                                    : 1
                                }
                              >
                                {order?.quantity}
                              </TableCell>
                            )}
                            {contextDetails?.priceVisible && (
                              <TableCell
                                rowSpan={
                                  order?.scheduleLines &&
                                  order?.scheduleLines?.length > 1
                                    ? order?.scheduleLines?.length
                                    : 1
                                }
                              >
                                {showPrice &&
                                  `${order?.unitPrice?.currency} ${order?.unitPrice?.amount}`}
                              </TableCell>
                            )}
                            {!formEditable && (
                              <TableCell
                                rowSpan={
                                  order?.scheduleLines &&
                                  order?.scheduleLines?.length > 1
                                    ? order?.scheduleLines?.length
                                    : 1
                                }
                              >
                                {contextDetails?.priceVisible &&
                                  showPrice &&
                                  `${order?.netValuePrice?.currency} ${order?.netValuePrice?.amount}`}
                              </TableCell>
                            )}
                            {contextDetails?.isSpecialPriceEnabled &&
                              !formEditable && (
                                <TableCell
                                  rowSpan={
                                    order?.scheduleLines &&
                                    order?.scheduleLines?.length > 1
                                      ? order?.scheduleLines?.length
                                      : 1
                                  }
                                >
                                  {
                                    order?.specialPrice?.amount
                                      ? order?.specialPrice?.amount
                                      : "" 
                                  }
                                </TableCell>
                              )}
                            {!formEditable && (
                              <TableCell
                                rowSpan={
                                  order?.scheduleLines &&
                                  order?.scheduleLines?.length > 1
                                    ? order?.scheduleLines?.length
                                    : 1
                                }
                              >
                                {order?.confirmedQuantity}
                              </TableCell>
                            )}
                            {!formEditable && (
                              <TableCell
                                rowSpan={
                                  order?.scheduleLines &&
                                  order?.scheduleLines?.length > 1
                                    ? order?.scheduleLines?.length
                                    : 1
                                }
                              >
                                {order?.openQuantity}
                              </TableCell>
                            )}
                            {contextDetails?.viewRejectedOrdersEnabled &&
                              !formEditable && (
                                <TableCell
                                  rowSpan={
                                    order?.scheduleLines &&
                                    order?.scheduleLines?.length > 1
                                      ? order?.scheduleLines?.length
                                      : 1
                                  }
                                >
                                  {order?.rejectionCode === null
                                    ? ""
                                    : order?.rejectionCode}
                                </TableCell>
                              )}
                            {!formEditable && <TableCell></TableCell>}
                            {!formEditable && <TableCell></TableCell>}
                            {!formEditable && <TableCell></TableCell>}
                            {!formEditable && <TableCell></TableCell>}
                            {formEditable && <TableCell></TableCell>}
                            {formEditable && <TableCell></TableCell>}
                            <TableCell></TableCell>
                            {!formEditable && <TableCell></TableCell>}
                            {!formEditable && <TableCell></TableCell>}
                            {!formEditable && <TableCell></TableCell>}
                            {!formEditable && <TableCell></TableCell>}
                            {formEditable &&
                            !updateComponent ? (
                              <TableCell></TableCell>
                            ) : (
                              (formEditable || updateComponent) && (
                                <TableCell></TableCell>
                              )
                            )}
                          </TableRow>
                        )}
                      </React.Fragment>
                    );
                  })}
              </>
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  align={"center"}
                  className={styles.noRecFound}
                >
                  {t("gzl.no_data_available.text")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.tableFooter}>
        <div className={styles.tableFooterButton}>
          {!formEditable && (
            <Typography variant="body2" color="primary" mr={2}>
              {`${t("dnet.total.text")} ${t("dnet.order_report.net_price.text")}`}
            </Typography>
          )}
          {!updateComponent && formEditable && (
            <Typography variant="body2" mr={2}>
              {t("dnet.selected_items.text")}
            </Typography>
          )}
          {!formEditable && (
            <Typography
              variant="body2"
              // color={netBalance?.amount < 0 ? "red" : "#727272"}
              style={{ fontWeight: "normal" }}
            >
              {`${orderDetails?.totalNetValue?.currency} ${(orderDetails?.totalNetValue?.amount)?.toFixed(2)}`}
            </Typography>
          )}
          {formEditable && !updateComponent && (
            <CustomButton
              id="deleteButton"
              label={t("dnet.delete.text")}
              onClick={handleUpdateClick}
              color="primary"
            />
          )}
          {updateComponent && (
            <div>
              <CustomButton
                id="confirm"
                label={t("dnet.confirm.text")}
                onClick={confirmHandler}
                color="primary"
              />
              <CustomButton
                id="modify"
                label={t("dnet.modify.text")}
                onClick={modifyHandler}
              />
            </div>
          )}
        </div>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={
            orderDetails?.lineItems?.length
              ? orderDetails?.lineItems?.length
              : 0
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </div>
    </div>
  );
};
