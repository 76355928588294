import React from 'react';
import {useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSelector } from "react-redux";
import styles from './invoiceDetailTable.module.scss';
import { useTranslation } from 'react-i18next';
import moment from "moment";
var currencyFormatter = require('currency-formatter');


const InvoiceDetailTable = () => {
  const { t } = useTranslation();
  const [rows, setRows] = useState<any>([])
  const invoiceDetailTableData = useSelector(
    (state: any) => state?.gazelleReducer?.paidItemDetailsData
  );

  interface Column {
    id: 'label' | 'dueDate' | 'amount' | 'openAmount' | 'discount';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
  }
  
  const columns: readonly Column[] = [
    {id: 'label', label: t("gzl.invoice.text")},
    {id: 'dueDate', label: t("gzl.due_on.text")},
    {id: 'amount', label: t("gzl.org_inv_value.text")},
    {id: 'openAmount', label: t("gzl.amount_collected.text")},
    {id: 'discount', label: t("gzl.discount_granted.text")},
  ];
  
  interface Data {
    label: any,
    dueDate: any,
    amount: any,
    openAmount: any,
    discount: any,
  }

  function createData(
    label: any,
    dueDate: any,
    amount: any,
    openAmount: any,
    discount: any,
  ): Data {
    return { label, dueDate, amount, openAmount, discount};
  }

  const createRowData=()=>{
    // Rows creation for table from API response
    let data = invoiceDetailTableData
    let rowsdata = []
    rowsdata.push(
      createData(
        data?.label, 
        moment(data?.dueDate).format("DD.MM.YYYY"), 
        data?.amount, 
        data?.openAmount, 
        data?.discount
      )
    )
    setRows([...rowsdata])
  }

  useEffect(()=>{
    createRowData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[invoiceDetailTableData])

    return (
        <div>
          <TableContainer component={Paper} className={styles.tableContainerHeader}>
            <Table sx={{ minWidth: 650 }} aria-label="Payments Table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                        {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  rows?.length > 0 ?rows.map((row:any, key:any) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return column.id === "discount"?(
                            <TableCell key={column.id} align={column.align} sx={{color: value>0 ? "":"red"}} >
                              {value}&nbsp;
                              {currencyFormatter.findCurrency(invoiceDetailTableData?.currency)?.symbol}
                            </TableCell>
                          ):column.id === "openAmount" || column.id === "amount" ?(
                            <TableCell key={column.id} align={column.align}>
                              {value}&nbsp;
                              {currencyFormatter.findCurrency(invoiceDetailTableData?.currency)?.symbol}
                            </TableCell>
                          ):(
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    );
                  }):<TableRow>
                          <TableCell colSpan={columns.length} align={"center"} className={styles.noRecFound}>
                            {t("gzl.no_data_available.text")}
                          </TableCell>
                    </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
}

export default InvoiceDetailTable;