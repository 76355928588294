import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { ROUTES_LABEL } from "../../constants/routesLabels";
import {
  fetchProdCategoryResponseSuccess,
  getCurrentDealerSuccess,
  getDealerDetailsSuccess,
  loginUserSuccess,
  logoutAction,
  setCurrentAddressSet,
  setCurrentDealer,
  setDealers,
  setIsUserLoggedin,
  setSonyMenuItems,
  startProductFlowSuccess,
} from "../../state/actions";
import CustomButton from "../atoms/customButton";
import logger from "../helper/logger";
import styles from "./idleTimerContainer.module.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
  textAlign: "center",
};

function IdleTimerContainer() {
  const timeout = 60_60_000;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [time, setTime] = useState({
    minutes: 29,
    seconds: 59,
  });
  const [endFormattedTime, setEndFormattedTime] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let modalMsg = t("dnet.session_check_info.text");
  modalMsg = modalMsg.split("{0}").join(` ${time.minutes}:${time.seconds} `);
  modalMsg = modalMsg.split("{1}").join(` ${endFormattedTime}`);

  const tick = () => {
    if (time.seconds == 0) {
      if (time.minutes == 0) {
        // Logging off user once timer completed
        setModalIsOpen(false);
        dispatch(setIsUserLoggedin(false));
        dispatch(loginUserSuccess({}));
        navigate(`/${ROUTES_LABEL.LOGIN}`);
      }
      setTime({
        minutes: time.minutes - 1,
        seconds: 59,
      });
    } else
      setTime({
        minutes: time.minutes,
        seconds: time.seconds - 1,
      });
  };

  const onIdle = () => {
    // once timer is completed redirecting to login page
    setModalIsOpen(false);
    dispatch(setIsUserLoggedin(false));
    dispatch(loginUserSuccess({}));
    dispatch(setCurrentDealer({}));
    dispatch(getDealerDetailsSuccess([]));
    dispatch(setSonyMenuItems([]));
    dispatch(setDealers([]));
    dispatch(startProductFlowSuccess({}));
    dispatch(fetchProdCategoryResponseSuccess({}));
    dispatch(getCurrentDealerSuccess({}));
    dispatch(logoutAction({}));
    navigate(`/${ROUTES_LABEL.LOGIN}`);
  };

  const onActive = () => {
    // continue the session if user is active
    setModalIsOpen(false);
  };

  const onPrompt = () => {
    // Timer creation and setting up modal on UI once user is inactive
    setTime({
      minutes: 29,
      seconds: 59,
    });
    let date = new Date();
    let endTime = moment(date).format("hh:mm A");

    let endTimeArr = endTime.split(":");
    let endTimeMin = parseInt(endTimeArr[1].split(" ")[0]) + 30;
    let endTimeHour = parseInt(endTimeArr[0]);

    if (endTimeMin > 60) {
      endTimeMin -= 60;
      endTimeHour === 12 ? (endTimeHour = 1) : (endTimeHour += 1);
      setEndFormattedTime(
        endTimeHour + ":" + endTimeMin + " " + endTimeArr[1].split(" ")[1]
      );
    } else {
      setEndFormattedTime(
        endTimeHour + ":" + endTimeMin + " " + endTimeArr[1].split(" ")[1]
      );
    }
    setModalIsOpen(true);
  };

  const { activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout: timeout,
    promptBeforeIdle: 30_60_000,
    throttle: 500,
    crossTab: true,
    syncTimers: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (modalIsOpen) tick();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const logout = () => {
    // Logging out user on click of logout
    logger.info("User logged out");
    setModalIsOpen(false);
    dispatch(setIsUserLoggedin(false));
    dispatch(loginUserSuccess({}));
    dispatch(setCurrentDealer({}));
    dispatch(getDealerDetailsSuccess([]));
    dispatch(setSonyMenuItems([]));
    dispatch(setDealers([]));
    dispatch(setCurrentAddressSet(-1));
    dispatch(startProductFlowSuccess({}));
    dispatch(fetchProdCategoryResponseSuccess({}));
    dispatch(getCurrentDealerSuccess({}));
    dispatch(logoutAction({}));
    navigate(`/${ROUTES_LABEL.LOGIN}`);
  };

  const handleStillHere = () => {
    activate();
  };

  return (
    <div id="idleTimerModal">
      <Modal
        open={modalIsOpen}
        onClose={handleStillHere}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className={styles.box_idleTimer}
          >
            <Typography variant="h6" color="primary">
              {t("dnet.session_check_header.text")}
            </Typography>
            <IconButton onClick={handleStillHere}>
              <CloseIcon />
            </IconButton>
          </div>

          <div className={styles.textAlign}>
            <Typography sx={{ mt: 2, mb: 2 }}>
              {t("dnet.session_check_description.text")}
            </Typography>
            <Typography sx={{ mt: 2, mb: 2 }}>{modalMsg}</Typography>
          </div>

          <div className={styles.button_wrapper}>
            <CustomButton
              label={t("dnet.session_check_end_session.text")}
              onClick={logout}
            />
            <CustomButton
              label={t("dnet.session_check_continue.text")}
              color="primary"
              onClick={handleStillHere}
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default IdleTimerContainer;
