import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getNewProducts,setProductContentData } from "../../../state/actions";
import { ProdContentDetail } from "../prodContentDetail";
import styles from "./newProducts.module.scss";
var _ = require("lodash");

export const NewProducts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const newProducts = useSelector(
    (state: any) => state?.commonReducer?.newProducts
  );

  const [loadNewProducts, setLoadNewProducts] = useState<any>(false);

  useEffect(() => {
    if (_.isEmpty(newProducts)) {
      dispatch(getNewProducts({}));
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(newProducts)) {
      dispatch(
        setProductContentData({ displayItem: [], prodList: newProducts })
      );
      setLoadNewProducts(true);
    }
  }, [newProducts]);

  return (
    <>
      <Typography variant="h5" className={styles.header} pb={3} color="primary">
        {t("dnet.new_products.section")}
      </Typography>
      {loadNewProducts && <ProdContentDetail parent="newProducts" />}
    </>
  );
};
