import { commonConstant } from "../constants/common-constants";

export const initialState = {
  selectedCartProducts : {},
  cartProductsTotal: {},
  singleQuoteLoad: {},
  multiQuoteLoad: {},
  clearOrderRes: {},
  deleteSelectedProdouctsRes: {},
  addToCartResponse:{},
  placeOrderRes:{},
  customerReference: "",
  specialInstruction: [],
  fileUploadResponse: {},
  multiSelectedAddresses: [],
  cartShipToOptionsSelections: {},
};

const cartReducer = (state = initialState, action: any) => {
  switch (action?.type) {
    case commonConstant.GET_CART_PRODUCTS_SUCCESS:
      return {
        ...state,
        selectedCartProducts: action?.payload,
      };
    case commonConstant.GET_CART_PRODUCTS_TOTAL_SUCCESS:
      return {
        ...state,
        cartProductsTotal: action?.payload,
      };
    case commonConstant.GET_QUOTE_SUCCESS:
      return {
        ...state,
        singleQuoteLoad: action?.payload,
      };
    case commonConstant.CLEAR_CART_ORDER_SUCCESS:
      return {
        ...state,
        clearOrderRes: action?.payload,
      };
    case commonConstant.UPDATE_CART_ORDER_SUCCESS:
      return {
        ...state,
        selectedCartProducts: action?.payload,
      };
    case commonConstant.DELETE_SELECTED_CART_ORDER_SUCCESS:
      return {
        ...state,
        selectedCartProducts: action?.payload,
      };
    case commonConstant.ADD_TO_CART_SUCCESS:
      return{
        ...state,
        addToCartResponse:action?.payload,
      };
    case commonConstant.PLACE_SAP_ORDER_SUCCESS:
      return{
        ...state,
        placeOrderRes:action?.payload,
      };
    case commonConstant.GET_MULTI_QUOTE_SUCCESS:
      return {
        ...state,
        multiQuoteLoad: action?.payload,
      };
    case commonConstant.SAVE_QUOTE_REFERENCE_DATA:
      return {
        ...state,
        customerReference: action?.payload?.customerReference,
      };
    case commonConstant.SAVE_QUOTE_SPECIAL_INSTRUCTION_DATA:
      return {
        ...state,
        specialInstruction: action?.payload?.specialInstruction,
      };
    case commonConstant.CART_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        selectedCartProducts: action?.payload,
        fileUploadResponse: action?.payload,
      };
    case commonConstant.SAVE_MULTI_ADDRESSES:
      return {
        ...state,
        multiSelectedAddresses: action?.payload?.multiSelectArray,
      };
    case commonConstant.SET_SELECTED_SHIP_TO_ACC:
      return {
        ...state,
        cartShipToOptionsSelections : {
          ...state?.cartShipToOptionsSelections,
          [action?.payload?.shoppingContextToken] : {
            selectedShipToAccount: action?.payload?.selectedShipToAccount,
            multiShipTo: action?.payload?.multiShipTo,
            multiRDD: action?.payload?.multiRDD,
            partialDelivery: action?.payload?.partialDelivery,
            deliveryDate: action?.payload?.deliveryDate,
          },
        }
      };
    default:
      return state;
  }
};

export default cartReducer;
