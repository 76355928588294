import { commonConstant } from "../constants/common-constants";

export const initialState = {
  resetPasswordRequest: false,
  userInfoFromURLID: {},
  passwordChanged: false,
};

const resetPasswordReducer = (state = initialState, action: any) => {
  switch (action?.type) {
    case commonConstant.RESET_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        resetPasswordRequest: action?.payload,
      };
    case commonConstant.USER_DETAILS_FROM_URL_ID_SUCCESS:
      return {
        ...state,
        userInfoFromURLID: action?.payload,
      };
    case commonConstant.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordChanged: action?.payload,
      };
    default:
      return state;
  }
};

export default resetPasswordReducer;
