import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "../header";
import styles from "./login.module.scss";
import { LoginForm } from "./loginForm";
import {
  getLoginCMSData,
  getSAPEnvDetails,
  getTranslationAction,
  setLoading,
} from "../../state/actions";
import { CMS } from "../../constants/commonConstants";
import { useNavigate } from "react-router";
import { ROUTES_LABEL } from "../../constants/routesLabels";
import CustomButton from "../atoms/customButton";
import { Footer } from "../atoms/footer";

export const Login = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const related_items = useSelector(
    (state: any) => state?.userProfileOptionsReducer?.loginCMSData
  );
  const [articleClicked, setArticleClicked] = useState(false);
  const sapEnvDetails = useSelector(
    (state: any) => state?.commonReducer?.sapEnvDetails
  );
  const currentTranslation = useSelector(
    (state: any) => state?.commonReducer?.translation
  );

  const currentLocale = useSelector(
    (state: any) => state?.commonReducer?.locale
  );

  useEffect(() => {
    i18n.addResourceBundle(
      currentLocale !== undefined ? currentLocale : "en_GB",
      "translation",
      currentTranslation,
      true
    );
    i18n.changeLanguage(currentLocale !== undefined ? currentLocale : "en_GB");
    dispatch(setLoading(false));
  }, [currentTranslation]);

  useEffect(() => {
    //onload fetch translation and clear st key
    window.sessionStorage.removeItem("st");
    dispatch(setLoading(true));
    dispatch(
      getTranslationAction({
        locale: currentLocale !== undefined ? currentLocale : "en_GB",
      })
    );
    dispatch(
      getLoginCMSData({
        module: "login",
        params: {
          [`filter_locale[${i18n.language}]`]: i18n.language,
          [`filter_bg[S1_0]`]: "S1_0",
          [`filter_ecot[4_GB_CONVE]`]: "4_GB_CONVE",
          [`acf_like[main_content_category]`]: "Home",
          [`acf_like[content_category]`]: "Home>Landing",
          [`acf[rh_related_category]`]: "__EMPTY__",
          max_result: "1",
        },
      })
    );
  }, []);

  useEffect(() => {
    if (sapEnvDetails === "") dispatch(getSAPEnvDetails({}));
  }, []);

  const checkArticleClick = () => {
    setArticleClicked(true);
  };
  return (
    <div>
      {Object.keys(currentTranslation).length >= 1 && (
        <Box className={styles.loginContainer}>
          <Header fromLogin={true} />
          <Grid container spacing={0.5}>
            <Grid item md={6} xs={12}>
              <Box className={styles.boxStyle}>
                <Box className={styles.loginCard}>
                  {related_items?.length > 0 &&
                    related_items?.map((item: any) => {
                      return (
                        item?.related_items?.length > 0 &&
                        item?.related_items?.map((article: any, key: any) => {
                          return (
                            <div key={key}>
                              {article?.acf_fc_layout === CMS.ADD_ARTICLE ? (
                                <div className={styles.innerBox}>
                                  {article?.article_image &&
                                    article?.article_image !== null && (
                                      <img
                                        src={article?.article_image?.url}
                                        style={{
                                          width: "100%",
                                        }}
                                      />
                                    )}
                                  <Typography variant="subtitle1">
                                    {article?.article_title}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ textAlign: "left" }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        article?.article_long_title?.toString(),
                                    }}
                                  />
                                  <Typography
                                    variant="caption"
                                    sx={{ textAlign: "left" }}
                                    dangerouslySetInnerHTML={{
                                      __html: article?.introduction?.toString(),
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className={styles.innerBox}>
                                  <Typography
                                    variant="body2"
                                    dangerouslySetInnerHTML={{
                                      __html: article?.block_title,
                                    }}
                                  />
                                  <Typography
                                    variant="caption"
                                    sx={{ textAlign: "left" }}
                                    dangerouslySetInnerHTML={{
                                      __html: article?.block_text?.toString(),
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          );
                        })
                      );
                    })}
                  {!articleClicked && (
                    <div style={{ textAlign: "end" }}>
                      <CustomButton
                        id="registrationButton"
                        label={t("dnet.apply_registration.text")}
                        onClick={() =>
                          navigate(`/${ROUTES_LABEL.REGISTRATION}`)
                        }
                        color="primary"
                      />
                    </div>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <LoginForm
                checkArticleClick={checkArticleClick}
                articleClicked={articleClicked}
              />
            </Grid>
          </Grid>
          <div style={{ padding: "20px 32px" }}>
            {" "}
            {process.env.REACT_APP_ENV !== "prod" &&
              `SAP : ${sapEnvDetails?.sapENV}`}
          </div>
          <Footer fromLogin={true} />
        </Box>
      )}
    </div>
  );
};
