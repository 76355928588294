import {
  Box,
  Grid,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import QuotationDetailsTable from "./quotationDetailsTable";
import styles from "./quotationDetails.module.scss";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import CustomButton from "../../atoms/customButton";
import { useSelector } from "react-redux";
import moment from "moment";
import { OrderPageModal } from "./orderPageModal";
var currencyFormatter = require("currency-formatter");

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
  textAlign: "center",
};

export const QuotationsDetails = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const selectedQuotation = location?.state?.selectedQuotation;
  const [orderQuotation, setOrderQuotation] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(true);
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [orderMsg, setOrderMsg] = useState(
    t("dnet.salesforce_place_order_warning.text")?.toString()
  );
  const navigate = useNavigate();

  const soldToAccount = useSelector(
    (state: any) => state?.myQuotationsReducer?.quotationData?.soldToAccount
  );

  const closeModal = () => {
    setOpen(false);
    setOpenOrderModal(false);
  };

  const quotationValidation = () => {
    let quote: any = {};
    if (selectedQuotation?.type === 0) {
      quote = selectedQuotation;
    } else {
      quote = orderQuotation;
    }
    if (
      quote !== "undefined" &&
      quote !== null &&
      Object.keys(quote)?.length > 0
    ) {
      if (quote?.totalPrice > 0) {
        if (
          quote?.smallOrderFeeAmount > 0 &&
          quote?.smallOrderFeeThreshold > 0 &&
          quote?.totalPrice < quote?.smallOrderFeeThreshold
        ) {
          let modalContent = t(
            "dnet.salesforce_quotation_sof_warning.text"
          )?.toString();
          modalContent = modalContent.replace(
            "{0}",
            currencyFormatter.findCurrency(selectedQuotation["quoteCurrency"])
              ?.symbol + selectedQuotation?.smallOrderFeeAmount
          );
          modalContent = modalContent.replace(
            "{1}",
            currencyFormatter.findCurrency(selectedQuotation["quoteCurrency"])
              ?.symbol + selectedQuotation?.smallOrderFeeThreshold
          );
          setOrderMsg(modalContent);
          setError(false);
          setOpen(true);
        } else {
          setOrderMsg("");
          setError(false);
          setOpenOrderModal(!openOrderModal);
        }
      } else {
        setOrderMsg(t("dnet.salesforce_place_order_warning.text")?.toString());
        setError(true);
        setOpen(true);
      }
    } else {
      setOrderMsg(t("dnet.salesforce_place_order_warning.text")?.toString());
      setError(true);
      setOpen(true);
    }
  };

  const initialQuoteData = (text: any, value: any) => {
    return (
      <Grid container className={styles.boxStyle}>
        <Grid item xs={6} md={3} lg={2}>
          <Typography variant="body2">{text}</Typography>
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <Typography variant="body2">{value}</Typography>
        </Grid>
      </Grid>
    );
  };
  const initialQuoteTypeData = () => {
    return (
      <Grid container xs={12} className={styles.boxStyle}>
        <Grid item xs={6} md={3} lg={2}>
          <Typography variant="body2">
            {t("dnet.salesforce_quotation_type.text")}
          </Typography>
        </Grid>
        <Grid item xs={6} md={3} lg={3}>
          <Typography variant="body2">
            {selectedQuotation?.type === 0
              ? t("dnet.salesforce_quotation_single_order.text")?.toString()
              : t("dnet.salesforce_quotation_frame_agreement.text")?.toString()}
            <Tooltip
              title={
                selectedQuotation?.type === 0
                  ? t(
                      "dnet.salesforce_quotation_single_order_alttext.text"
                    )?.toString()
                  : t(
                      "dnet.salesforce_quotation_frame_agreement_alttext.text"
                    )?.toString()
              }
              placement="right-start"
              arrow
            >
              <IconButton className={styles.infoIcon} sx={{ padding: "0" }}>
                <HelpOutlineOutlinedIcon
                  color="primary"
                  sx={{ width: "20px", height: "20px", marginLeft: "5px" }}
                />
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const buttonClickHandler = (type: any) => {
    if (type === "back") {
      navigate(-1);
    } else {
      selectedQuotation?.type === 0 && setOrderQuotation(selectedQuotation);
      quotationValidation();
      // : !error && orderMsg !== "" ? setOpen(!open) : setOpenOrderModal(!openOrderModal);
    }
  };

  const updateQuotationOrder = (quote: any) => {
    setOrderQuotation(quote);
  };

  const okHandler = () => {
    setOpen(!open);
    if (error === false) {
      setOpenOrderModal(!openOrderModal);
    }
  };

  return (
    <div>
      <Typography
        variant="subtitle1"
        className={styles.header}
        pb={3}
        color="primary"
      >
        {t("dnet.salesforce_quotation_title.text")}
      </Typography>
      <Typography variant="body2" mb={2}>
        {t("dnet.salesforce_quotation_description.text")}
      </Typography>
      <div>
        <Typography variant="subtitle2" mb={1} className={styles.quoteTitle}>
          {selectedQuotation?.name}
        </Typography>
        <Grid container>
          <Grid container xs={12} md={8}>
            {initialQuoteData(
              t("dnet.das_date.text"),
              moment(selectedQuotation?.creation).format("DD/MM/YYYY")
            )}
            {initialQuoteTypeData()}
            {initialQuoteData(
              t("dnet.salesforce_quotation_reference.text"),
              selectedQuotation?.quoteNumber
            )}
            {initialQuoteData(
              t("dnet.salesforce_quotation_contact.text"),
              selectedQuotation?.salesContact
            )}
            {initialQuoteData(
              t("dnet.salesforce_quotation_expires.text"),
              selectedQuotation?.expiration
            )}
          </Grid>
          <Grid container xs={12} md={4}>
            <div className={styles.dealerDetailBox}>
              <Typography variant="body1">{soldToAccount?.number}</Typography>
              <Typography variant="body2">{soldToAccount?.name}</Typography>
              <Typography variant="body2">
                {soldToAccount?.address?.street}
              </Typography>
              <Typography variant="body2">{`${soldToAccount?.address?.townCity} (${soldToAccount?.address?.country})`}</Typography>
            </div>
          </Grid>
        </Grid>
        <div className={styles.projectDescription}>
          <Typography variant="body1" className={styles.buttonBoxStyle}>
            {t("dnet.salesforce_quotation_project.text")}
          </Typography>
          <Typography variant="body1">{selectedQuotation?.name}</Typography>
        </div>
        <Typography variant="caption">
          {t("dnet.salesforce_quotation_description2.text")}
        </Typography>
      </div>
      {selectedQuotation?.isPrimeSupportLinkedToLineItem && (
        <div className={styles.primeSupportBox}>
          <Typography variant="body2">
            {t("dnet.salesforce_quotation_description_primesupport.text")}
          </Typography>
        </div>
      )}
      <Box className={styles.tableContainer}>
        <QuotationDetailsTable
          selectedQuotation={selectedQuotation}
          updateQuotationOrder={updateQuotationOrder}
        />
      </Box>
      <div>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {t("dnet.salesforce_quotation_description3.text")}
        </Typography>
        <Typography variant="body2">
          {t("dnet.salesforce_quotation_header3.text")}
        </Typography>
        <div className={styles.buttonSection}>
          <CustomButton
            label={t("dnet.back_to_search_results.text")}
            onClick={() => buttonClickHandler("back")}
            margin="0"
          />
          <CustomButton
            label={t("dnet.salesforce_quotation_place_order.text")}
            color="primary"
            onClick={() => buttonClickHandler("placeOrder")}
            margin="0"
          />
        </div>
      </div>
      {orderMsg !== "" && (
        <Modal
          open={open}
          onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography sx={{ mt: 2, mb: 2 }}>{orderMsg}</Typography>
            <CustomButton
              label={t("dnet.ok.text")?.toString()}
              color="primary"
              onClick={okHandler}
            />
            <CustomButton
              label={t("dnet.cancel.text")?.toString()}
              color="primary"
              onClick={closeModal}
            />
          </Box>
        </Modal>
      )}
      <OrderPageModal
        openModal={openOrderModal}
        closeModal={closeModal}
        selectedQuotation={orderQuotation}
      />
    </div>
  );
};
