import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import {
  Box,
  IconButton,
  TablePagination,
  TableSortLabel,
  Typography
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
// import { getSymbolFromCode } from "currency-code-symbol-map";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { downloadInvoicePdf } from "../../../../state/actions";
import { getComparator, stableSort } from '../../../helper/util';
import styles from "./statements-table.module.scss";
import moment from "moment";
var currencyFormatter = require('currency-formatter');

export const StatementsTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const statementData = useSelector(
    (state: any) => state?.gazelleReducer?.statementData
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.userDetails
  );
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //based on certain field values, we have to either enable or disable pdf download
  const enablePdfDownload = (statement: any) => {
    if (
      statement?.objTypeVBRK ||
      (statement?.objTypeBKPF && (statement.docTypeVK || statement.docTypeDG))
    ) {
      return false;
    }
    return true;
  };

  const saveAccountDetails = useSelector(
    (state: any) => state?.commonReducer?.saveAccountDetails
  )
  
  const downloadPdf = (statement: any) => {
    dispatch(
      downloadInvoicePdf({
        language: userDetails?.language,
        username: userDetails?.userName,
        partnerKey: saveAccountDetails?.payerToAcc?.number,
        invoiceId: getInvoiceId(statement),
      })
    );
  };

  //Creating invoiceID based on statement type
  const getInvoiceId = (statement : any) => {
    if(statement?.type === 'VK' || statement?.type === 'DG'){
      return statement?.compCode.concat(statement?.fiscYear.concat(statement?.refDoc));
    }
    else {
      return "SD_"+statement?.refDoc;
    }
  }
  type Order = "asc" | "desc";

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("date");

  const tableHeaderData = [
    { id: "date", label: t("gzl.st_date.text") },
    { id: "dueDate", label: t("gzl.due_date.text") },
    { id: "invoiceNo", label: t("gzl.invoice_no.text") },
    { id: "referenceNo", label: t("gzl.reference.text") },
    { id: "deliveryNo", label: t("gzl.delivery.text") },
    { id: "type", label: t("gzl.type.text") },
    { id: "amount", label: t("gzl.st_outstanding_balance.text") },
  ];

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeaderData.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {statementData?.statementList?.length > 0 ? (
              <>
                {stableSort(
                  statementData?.statementList,
                  getComparator(order, orderBy)
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((statement, index) => {

                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          {
                            statement?.date && 
                            moment(statement?.date).format("DD.MM.YYYY")
                          }
                        </TableCell>
                        <TableCell>
                          {
                            statement?.dueDate && 
                            moment(statement?.dueDate).format("DD.MM.YYYY")
                          }
                        </TableCell>
                        <TableCell className={styles.invoiceTableCell}>
                          <span className={enablePdfDownload(statement) ? styles.disableDownload : styles.pdfIcon}
                            title={enablePdfDownload(statement) ? "" : t("gzl.display_pdf.text")?.toString()}
                            onClick={(e: any) => {downloadPdf(statement);}}>
                              {statement?.invoiceNo}
                          </span>
                          <IconButton
                            className={styles.pdfIcon}
                            disabled={enablePdfDownload(statement)}
                            onClick={(e: any) => {
                              downloadPdf(statement);
                            }}
                          >
                            <PictureAsPdfOutlinedIcon
                              titleAccess={t("gzl.display_pdf.text")?.toString()}
                            />
                          </IconButton>
                        </TableCell>
                        <TableCell>{statement?.referenceNo}</TableCell>
                        <TableCell>{statement?.deliveryNo}</TableCell>
                        <TableCell>{statement?.type}</TableCell>
                        <TableCell
                          className={
                            statement?.debitCreditIndicatorH
                              ? `${styles.errorText} ${styles.amountWidth}`
                              : styles.amountWidth
                          }
                        >
                          {(parseFloat(statement?.amount?.toString()))?.toFixed(2)}&nbsp;
                          {/* {getSymbolFromCode(statementData?.currencyType)} */}
                          {currencyFormatter.findCurrency(statement?.currency)?.symbol}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </>
            ) : (
              <TableRow>
                <TableCell
                  colSpan={8}
                  align="center"
                  className={styles.noRecFound}
                >
                  {t("gzl.no_data_available.text")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {statementData?.statementList?.length > 0 && (
        <div className={styles.tableFooter}>
          <div className={styles.totalAmount}>
            <Typography variant="body1" color="#F25529" mr={2}>
              {t("gzl.total_balance.text")}
            </Typography>
            <Typography
              variant="body1"
              color={statementData?.statementAmount < 0 ? "red" : "#727272"}
            >
              {(parseFloat(statementData?.statementAmount?.toString()))?.toFixed(2)}&nbsp;
              {/* {getSymbolFromCode(statementData?.currencyType)} */}
              {currencyFormatter.findCurrency(statementData?.currencyType)?.symbol}
            </Typography>
          </div>
          <TablePagination
            className={styles.pagination}
            component="div"
            count={
              statementData?.statementList?.length
                ? statementData.statementList.length
                : 0
            }
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 20]}
          />
        </div>
      )}
    </>
  );
};
