import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ProdCartDetails } from "./prodCartDetails";
import { ProdDetailsView } from "./prodDetailsView";
import styles from "./productDetails.module.scss";
import { useSelector } from "react-redux";
import { getContextDetails, getProductImagesSuccess } from "../../../state/actions";
import { CONTEXT_ORDER } from "../../../constants/commonConstants";


var _ = require("lodash");

export const ProductDetails = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getContextDetails({
        key: CONTEXT_ORDER?.PRODUCT_MODULE_PAGE,
      })
    );
    dispatch(getProductImagesSuccess({}));
  }, []);

  const contextDetails = useSelector(
    (state: any) => state?.commonReducer?.contextDetails
  );

  useEffect(() => {
    if (!_.isEmpty(contextDetails)) {
      setIsReseller(contextDetails?.isReseller);
    }
  }, [contextDetails]);
  const [isReseller, setIsReseller] = useState<any>(false);
  const [isInternalAdmin, setIsInternalAdmin] = useState<any>(false);

  const currUserType = useSelector(
    (state: any) => state?.commonReducer?.currentDealer?.accountFlag
  );
  const currentProductSku = useSelector(
    (state: any) => state?.productReducer?.currentProductSku
  );
  useEffect(() => {
    if (currUserType == 0) {
      setIsInternalAdmin(true);
    }
  }, [currUserType]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={9} md={12} sm={12} xs={12}>
          <Box className={styles.mainGrid}>
            <ProdDetailsView />
          </Box>
        </Grid>
        <Grid item lg={3} md={12} sm={12} xs={12}>
          {!isReseller && !isInternalAdmin && currentProductSku?.product?.type == 0 && (
            <Box className={styles.mainGrid}>
              <ProdCartDetails />
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};
