import { combineReducers } from "redux";
import gazelleReducer from "./gazelleReducer";
import signUpReducer from "./signUpReducer";
import resetPasswordReducer from "./resetPasswordReducer";
import changePasswordReducer from "./changePasswordReducer";
import changeDealerReducer from "./changeDealerReducer";
import priceChangeReducer from "./priceChangeReducer";
import commonReducer from "./commonReducer";
import reportingReducer from "./reportingReducer";
import dataDownloadReducer from "./dataDownloadReducer";
import productReducer from "./productReducer";
import systemAdminReducer from "./systemAdminReducer";
import userProfileOptionsReducer from "./userProfileOptionsReducer";
import myQuotationsReducer from "./myQuotationsReducer";
import cartReducer from "./cartReducer";
import gradedStockReducer from "./gradedStockReducer";
import orderChangeReportReducer from "./orderChangeReportReducer";
import premiumServiceReducer from "./premiumServiceReducer";
import productListReducer from "./productListReducer";

const reducers = combineReducers({
  gazelleReducer,
  resetPasswordReducer,
  changePasswordReducer,
  signUpReducer,
  changeDealerReducer,
  priceChangeReducer,
  commonReducer,
  reportingReducer,
  dataDownloadReducer,
  productReducer,
  systemAdminReducer,
  userProfileOptionsReducer,
  myQuotationsReducer,
  cartReducer,
  gradedStockReducer,
  orderChangeReportReducer,
  premiumServiceReducer,
  productListReducer,
});

export default reducers;
