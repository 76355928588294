import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Radio,
  Tab,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../../atoms/customButton";
import styles from "./compareProducts.module.scss";
import {
  CONTEXT_ORDER,
  GREEN,
  MODAL_TYPES,
  PUBLICATION,
} from "../../../constants/commonConstants";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import PrintIcon from "@mui/icons-material/Print";
import CircleIcon from "@mui/icons-material/Circle";
import CustomTextField from "../../atoms/customTextField";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import { ROUTES_LABEL } from "../../../constants/routesLabels";
import {
  addToCart,
  addToCartSuccess,
  getContextDetails,
  getProductImages,
  getProductImagesSuccess,
  setCurrentProductSku,
  setProductDetailsData,
  setSelectedProductsDownload,
} from "../../../state/actions";
import { useDispatch } from "react-redux";
import { ProdDetailsModal } from "../productDetails/prodDetailsModal";
import {
  downloadExcelFile,
  checkStatus,
  plus2,
  minus2,
} from "../../helper/util";

var _ = require("lodash");
const noProduct = require("../../../assets/images/no_prod_tn.gif");

export const CompareProducts = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getContextDetails({
        key: CONTEXT_ORDER?.PRODUCT_MODULE_PAGE,
      })
    );
    dispatch(getProductImagesSuccess({}));
  }, []);

  const contextDetails = useSelector(
    (state: any) => state?.commonReducer?.contextDetails
  );

  const [isPriceVisible, setPriceVisible] = useState<any>(true);
  const [isDownloadTrigged,setIsDownloadTriggered] = useState<any>(false);

  const [isReseller, setIsReseller] = useState<any>(false);
  const [isInternalAdmin, setIsInternalAdmin] = useState<any>(false);

  useEffect(() => {
    if (!_.isEmpty(contextDetails)) {
      contextDetails?.priceVisible
        ? setPriceVisible(contextDetails?.priceVisible)
        : setPriceVisible(true);
      setIsReseller(contextDetails?.isReseller);
    }
  }, [contextDetails]);

  const currUserType = useSelector(
    (state: any) => state?.commonReducer?.currentDealer?.accountFlag
  );
  useEffect(() => {
    if (currUserType == 0) {
      setIsInternalAdmin(true);
    }
  }, [currUserType]);

  const prodCompareDataRedux = useSelector(
    (state: any) => state?.productReducer?.prodCompareData
  );

  const [prodCompareData, setProdCompareData] = useState<any>({});
  const [selectedTechIndex, setSelectedTechIndex] = useState<any>(-1);

  const [specData, setSpecData] = useState<any>([]);
  //preparing spec data in order to handle radio button switches
  useEffect(() => {
    if (!_.isEmpty(prodCompareData)) {
      let selectedIndex = -1;
      let data: any = [];
      prodCompareData?.selectedProducts &&
        prodCompareData?.selectedProducts?.forEach((prod: any, index: any) => {
          data[prod?.prodDetails?.materialName] = {};
          let subList: any = {};
          prod?.prodDetails?.techSpecs?.forEach((spec: any) => {
            spec?.specs?.forEach((sub: any) => {
              subList[spec?.name + sub?.name] = sub?.value?.toString();
            });
          });
          data[prod?.prodDetails?.materialName] = subList;
          if (!_.isEmpty(subList) && selectedIndex === -1) {
            setSelectedTechIndex(index);
            selectedIndex = index;
          }
        });
      setSpecData(data);
    }
  }, [prodCompareData]);

  const downloadXlsHandler = () => {
    const productImageURL: any = [];

    prodCompareData?.selectedProducts?.map((product: any, index: any) => {
      let url: any = getImage(product?.prodDetails?.images)?.[0];
      const alteredURL = url;
      const parts = alteredURL?.split("/");
      const relevantPart = parts?.slice(3).join("/");
      const flag = url?.includes("sp.sony-europe.com") ? 2 : (url?.includes("sony.scene7.com") ? 3 : 1);

      productImageURL.push({
        imageURL: relevantPart,
        flag: flag,
        materialName: product?.prodData?.product?.sku?.materialName,
      });
    });
    dispatch(
      getProductImages({ payload: productImageURL, separateImage: true })
    );
    setIsDownloadTriggered(true);
  };

  useEffect(() => {
    setProdCompareData(prodCompareDataRedux);
  }, [prodCompareDataRedux]);

  useEffect(() => {
    prodCompareData?.showFeature
      ? setType("feature")
      : prodCompareData?.showBenefits
      ? setType("benefit")
      : setType("spec");
  }, [prodCompareData]);

  const toDataURL = (url: any) => {
    const promise = new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.readAsDataURL(xhr.response);
        reader.onloadend = function () {
          resolve({ base64Url: reader.result });
        };
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    });

    return promise;
  };

  
  const productImages = useSelector(
    (state: any) => state?.productReducer?.productImages
  );
  
  useEffect(()=>{

    if((!_.isEmpty(productImages)|| productImages===undefined) && isDownloadTrigged )
    {
    let images:any  = productImages===undefined? []: productImages?.data;
    const ExcelJS = require("exceljs");
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Sheet1");
    prepareExcelData(sheet, workbook,images);
    let pathnames: any = location.pathname.split("/").filter((x) => x);
    const fileName = pathnames?.[pathnames.length - 1];
    downloadExcelFile(workbook, `${fileName}.xlsx`);
    setIsDownloadTriggered(false);
     }     

  },[productImages]);

  const getBase64ByMaterialName=(productImages:any,materialName:any)=>{
  for(let i=0;i<productImages.length;i++)
  {
    if(productImages[i].materialName===materialName)
    {
      return productImages[i].base64Image;
    }
  }
  return "";
  }
  const prepareExcelData = (sheet: any, workbook: any,images:any) => {
    //Adds 1st 3 rows
    let firstRow: any = [" ", " "];
    let imageRow: any = [" ", " "];
    let descRow: any = [" ", " "];
    prodCompareData?.selectedProducts?.forEach((ele: any) => {
      firstRow.push(ele?.prodData?.product?.sku?.materialName);
      imageRow.push("");
      descRow.push(ele?.prodData?.product?.index?.headlineDescription);
    });

     !_.isEmpty(images) && prodCompareData?.selectedProducts?.map(
        (product: any, index: any) => {
          const colNumber = index + 3;
         const result= getBase64ByMaterialName(images,product?.prodData?.product?.sku?.materialName);
          if(result){
          const extName = result?.split("/")[1].split(";")[0]
            ? result?.split("/")[1].split(";")[0]
            : "png";

          const imageId2 = workbook.addImage({
            base64: result,
            extension: `${extName}`,
          });

          // await sheet.addImage(imageId2, `)+index}2:C2`);
         sheet.addImage(imageId2, {
            tl: { col: 2 + index, row: 0 },
            ext: { width: 100, height: 100 },
          });
        }
        }
      );
    sheet.addRow(imageRow);
    sheet.addRow(imageRow);
    sheet.addRow(imageRow);
    sheet.addRow(imageRow);
    sheet.addRow(imageRow);
    setBoldCell(sheet.addRow(firstRow));
    sheet.addRow(descRow);

    // Technical specification rows
    let techSpecRow = [t("dataDownload.dataType.titleTechSpecs.text"), " "];
    prodCompareData?.selectedProducts?.forEach((ele: any) => {
      techSpecRow.push(ele?.prodData?.product?.sku?.materialName);
    });
    setBoldCell(sheet.addRow(techSpecRow));

    let specRowStart = 0;
    let specColumnIndex = 3;
    prodCompareData?.selectedProducts[
      selectedTechIndex
    ]?.prodDetails?.techSpecs?.forEach((ele: any, index: any) => {
      let r=sheet.addRow();
      if (index === 0) specRowStart = r?._number;
      ele?.specs &&
        ele?.specs?.forEach((spec: any, specIndex: any) => {
          let r = sheet.addRow([ele?.displayName, spec?.displayName]);
          if (index === 0 && specIndex === 0) specRowStart = r?._number;
        });
    });

    prodCompareData?.selectedProducts?.forEach((prod: any) => {
      let rowIndex = specRowStart;
      prodCompareData?.selectedProducts[
        selectedTechIndex
      ]?.prodDetails?.techSpecs?.forEach((ele: any) => {
        ele?.specs &&
          ele?.specs?.forEach((spec: any) => {
            let r = sheet.getRow(rowIndex);
            r.getCell(specColumnIndex).value = specData[
              prod?.prodDetails?.materialName
            ][ele?.name + spec?.name]
              ? specData[prod?.prodDetails?.materialName][
                  ele?.name + spec?.name
                ]
              : t("dnet.na.text");
            rowIndex++;
          });
      });
      specColumnIndex++;
    });

    // Fetaures row start
    let featureRow: any = [t("dnet.features.text"), " "];

    prodCompareData?.selectedProducts?.forEach((ele: any) => {
      featureRow.push(ele?.prodData?.product?.sku?.materialName);
    });
    setBoldCell(sheet.addRow(featureRow));

    let featureRowStart = 0;
    let columnIndex = 3;
    prodCompareData?.selectedProducts?.forEach((ele: any, index: any) => {
      if (index === 0) {
        let r = sheet.addRow();
        featureRowStart = r?._number;
        ele?.prodDetails?.featureBenefits &&
          ele?.prodDetails?.featureBenefits?.forEach((feature: any, i: any) => {          
            if (i === 0) featureRowStart = r?._number;
            r.getCell(columnIndex).value =
              feature?.headline? feature?.headline: "" + feature?.bodyCopy;
          });
      } else {
        let rowIndex = featureRowStart;
        ele?.prodDetails?.featureBenefits &&
          ele?.prodDetails?.featureBenefits?.forEach((feature: any) => {
            let r = sheet.getRow(rowIndex);
            r.getCell(columnIndex).value =
              feature?.headline? feature?.headline: "" + feature?.bodyCopy;
            rowIndex++;
          });
      }
      columnIndex++;
    });

   
    // product details adding
    let infoRow = [" ", " "];
    prodCompareData?.selectedProducts?.forEach((ele: any) => {
      infoRow.push(ele?.prodData?.product?.sku?.materialName);
    });
    setBoldCell(sheet.addRow(infoRow));

    let statusRow: any = [
      t("dataDownload.dataType.availabilityStatus.text"),
      " ",
    ];
    prodCompareData?.selectedProducts?.forEach((ele: any) => {
      statusRow.push(
        checkStatus(ele?.prodData)
          ? t(`dataDownload.availability.${checkStatus(ele?.prodData)}.text`)
          : ""
      );
    });
    sheet.addRow(statusRow);

    let priceRow: any = [t("dataDownload.dataType.listPrice.text"), " "];
    prodCompareData?.selectedProducts?.forEach((prod: any) => {
      let text = prod?.prodData?.product?.price?.currency
        ?.concat(" ")
        ?.concat(
          prod?.prodData?.product?.price?.price &&
            parseFloat(
              prod?.prodData?.product?.price?.price?.toString()
            ).toFixed(2)
        );
      priceRow.push(text);
    });
    sheet.addRow(priceRow);

    let eancodeRow: any = [t("dataDownload.dataType.eanCode.text"), " "];
    prodCompareData?.selectedProducts?.forEach((prod: any) => {
      eancodeRow.push(prod?.prodData?.product?.definition?.ean);
    });
    sheet.addRow(eancodeRow);

    let netWeightRow: any = [t("dataDownload.dataType.netWeight.text"), " "];
    prodCompareData?.selectedProducts?.forEach((prod: any) => {
      netWeightRow.push(
        prod?.prodData?.product?.definition?.weight
          ?.concat(" ")
          ?.concat(prod?.prodData?.product?.definition?.weightUnit)
      );
    });
    sheet.addRow(netWeightRow);

    //set the width of columns
    for (let i = 1; i <= sheet.actualColumnCount; i++) {
      const col = sheet.getColumn(i);
      col.width = 30;
    }

    sheet.getColumn(1).font = {
      bold: true,
    };
    sheet.getColumn(2).font = {
      bold: true,
    };

    //wrap text in all cells
    for (let i = 1; i <= sheet.actualRowCount; i++) {
      const row = sheet.getRow(i);
      for (let j = 1; j <= sheet.actualColumnCount; j++) {
        row.getCell(j).alignment = {
          wrapText: true,
          vertical: "top",
          horizontal: "left",
        };
      }
    }
  };

  const setBoldCell = (row: any) => {
    row.font = {
      bold: true,
    };
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [type, setType] = React.useState("");

  const compareHandler = (data: any) => {
    setType(data);
  };

  useEffect(() => {
    setProdCompareData(prodCompareDataRedux);
  }, []);

  const getImage = (data: any) => {
    let img: any = [];
    if (data) {
      img = Object.values(data)[0];
    }
    return img;
  };

  const removeProduct = (index: any) => {
    const updatedProducts = prodCompareData?.selectedProducts?.filter((product:any)=>product?.index!==index);
    setProdCompareData((prevState: any) => {
      return {
        ...prevState,
        selectedProducts: updatedProducts,
      };
    });
  };

  const getIndexOfProduct = (idx: any) => {
    let prods: any = prodCompareData;

    return prods?.selectedProducts?.findIndex(
      (product: any) => product?.index === idx
    );
  };

  const [isListValVisible, setIsListValVisible] = useState<boolean>(true);
  const [quantity, setQuantity] = useState<any>(1);
  const updateQuantity = (value: any, index: any) => {
    let idx: any = getIndexOfProduct(index);
    let qty = 0;
    if (!isNaN(parseInt(value))) {
      if (parseInt(value) < 0) {
        qty = 0;
      } else {
        qty = parseInt(value);
      }
    } else {
      qty = 0;
    }
    setProdCompareData((prevState: any) => {
      const updatedSelectProd = [...prevState?.selectedProducts];
      const updatedProdData = { ...updatedSelectProd[idx].prodData, qty: qty };
      updatedSelectProd[idx] = {
        ...updatedSelectProd[idx],
        prodData: updatedProdData,
      };
      return {
        ...prevState,
        selectedProducts: updatedSelectProd,
      };
    });
  };

  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );

  const shoppingContextToken = `${currentDealer?.accNum}_${currentDealer?.bgName}_${currentDealer?.loggedInUser?.username}_${currentDealer?.loggedInUser?.profile?.email}`;

  const cartShipToOptionsSelections = useSelector(
    (state: any) => state?.cartReducer?.cartShipToOptionsSelections
  );

  const addToCartResponse = useSelector(
    (state: any) => state?.cartReducer?.addToCartResponse
  );

  const handleQuantityChange = (type: any, index: any) => {
    let idx: any = getIndexOfProduct(index);
    let qty: any = prodCompareData?.selectedProducts[idx]?.prodData?.qty;

    let minQuantity: any =
      prodCompareData?.selectedProducts[idx]?.prodData?.product?.detail
        ?.minOrderQuantity;
    let minDelQuantity: any =
      prodCompareData?.selectedProducts[idx]?.prodData?.product?.detail
        ?.minDeliveryQuantity;
    let delUnit: any =
      prodCompareData?.selectedProducts[idx]?.prodData?.product?.detail
        ?.deliveryUnit;

    minQuantity = parseInt(minQuantity) || 1;
    minDelQuantity = parseInt(minDelQuantity) || 1;
    delUnit = parseInt(delUnit) || 1;

    minQuantity = minQuantity === 0 ? 1 : minQuantity;
    minDelQuantity = minDelQuantity === 0 ? 1 : minDelQuantity;
    delUnit = delUnit === 0 ? 1 : delUnit;
    if (type === "increment") {
      if (qty === undefined || qty === "" || Number.isNaN(qty)) {
        qty = plus2(0, minQuantity, delUnit, minDelQuantity);
      } else {
        qty = plus2(parseInt(qty), minQuantity, delUnit, minDelQuantity);
      }
    } else {
      if (
        qty === undefined ||
        Number.isNaN(qty) ||
        qty === "" ||
        qty === "0" ||
        qty === 0
      ) {
        qty = minus2(0, minQuantity, delUnit, minDelQuantity);
      } else {
        qty = minus2(parseInt(qty), minQuantity, delUnit, minDelQuantity);
      }
    }
    setProdCompareData((prevState: any) => {
      const updatedSelectProd = [...prevState?.selectedProducts];
      const updatedProdData = { ...updatedSelectProd[idx].prodData, qty: qty };
      updatedSelectProd[idx] = {
        ...updatedSelectProd[idx],
        prodData: updatedProdData,
      };
      return {
        ...prevState,
        selectedProducts: updatedSelectProd,
      };
    });
  };
  useEffect(() => {
    if (!_.isEmpty(addToCartResponse) && addToCartResponse?.success) {
      navigate(`/${ROUTES_LABEL.ORDER_ENTRY}`);
    }
  }, [addToCartResponse]);

  useEffect(() => {
    return () => {
      dispatch(addToCartSuccess({}));
    };
  }, [dispatch]);

  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.loginResponse?.data?.userDetails
  );

  const redirectToOrderEntryAddSelection = () => {
    let productSelected: any = prodCompareData?.selectedProducts.filter(
      (item: any) =>
        item?.prodData?.qty !== undefined && item?.prodData?.qty !== 0
    );
    let shouldRedirect: any = true;
    let cartItems: any = [];
    productSelected?.map((product: any) => {
      let cartProduct = {
        productSku: {
          name: product?.prodData?.product?.definition?.materialName,
        },
        subLineItems: [
          {
              quantity: product?.prodData?.qty,
              requestedDeliveryDate: cartShipToOptionsSelections?.[shoppingContextToken]?.deliveryDate ?? null,
          }],
        specialPrice: "",
        quantity: product?.prodData?.qty,
        quantityTotal: 0,
      };
      cartItems.push(cartProduct);
      let limitOrderQuantity: any = contextDetails?.limitOrderQuantity;

      let idx: any = getIndexOfProduct(product?.index);

      if (
        limitOrderQuantity != undefined &&
        limitOrderQuantity.enabled &&
        product?.prodData?.qty > limitOrderQuantity.maxQuantity
      ) {
        let invalidLimitMsg: any = t("dnet.invalid_limit_order_quantity.text");
        invalidLimitMsg = invalidLimitMsg.replace(
          "{0}",
          limitOrderQuantity.maxQuantity
        );

        setProdCompareData((prevState: any) => {
          const updatedSelectProd = [...prevState?.selectedProducts];
          const updatedProdData = {
            ...updatedSelectProd[idx].prodData,
            quantityMessageTarget: invalidLimitMsg,
          };
          updatedSelectProd[idx] = {
            ...updatedSelectProd[idx],
            prodData: updatedProdData,
          };
          return {
            ...prevState,
            selectedProducts: updatedSelectProd,
          };
        });
        shouldRedirect = false;
      } else {
        setProdCompareData((prevState: any) => {
          const updatedSelectProd = [...prevState?.selectedProducts];
          const updatedProdData = {
            ...updatedSelectProd[idx].prodData,
            quantityMessageTarget: "",
          };
          updatedSelectProd[idx] = {
            ...updatedSelectProd[idx],
            prodData: updatedProdData,
          };
          return {
            ...prevState,
            selectedProducts: updatedSelectProd,
          };
        });
      }
    });

    if (shouldRedirect) {
      let cart = {
        shipToAccountId: cartShipToOptionsSelections?.[shoppingContextToken]?.selectedShipToAccount ?? [""],
        lineItems: cartItems,
        isMultiShipTo: cartShipToOptionsSelections?.[shoppingContextToken]?.multiShipTo ?? false,
        isMultiRequestedDeliveryDate: cartShipToOptionsSelections?.[shoppingContextToken]?.multiRDD ?? false,
        requestedDeliveryDate: cartShipToOptionsSelections?.[shoppingContextToken]?.deliveryDate ?? null,
        spToken: shoppingContextToken,
        key: 2,
        isPartialDelivery: cartShipToOptionsSelections?.[shoppingContextToken]?.partialDelivery === false ? false : true,
        usernameOrEmail: userDetails?.username,
      };
      dispatch(addToCart(cart));
      //navigate(`/${ROUTES_LABEL.ORDER_ENTRY}`);
    }
  };

  const redirectToOrderEntry = (index: any) => {
    let idx: any = getIndexOfProduct(index);
    let prod: any = {
      prodData: prodCompareData?.selectedProducts[idx]?.prodData,
      prodDetails: {},
      availabilityDetails: {},
    };
    let qty: any = prodCompareData?.selectedProducts[idx]?.prodData?.qty;
    let cart = {
      shipToAccountId: cartShipToOptionsSelections?.[shoppingContextToken]?.selectedShipToAccount ?? [""],
      lineItems: [
        {
          productSku: {
            name: prod?.prodData?.product?.definition?.materialName,
          },
          subLineItems: [
            {
                quantity: qty,
                requestedDeliveryDate: cartShipToOptionsSelections?.[shoppingContextToken]?.deliveryDate ?? null,
            }],
          specialPrice: "",
          quantity: qty,
          quantityTotal: 0,
        },
      ],
      isMultiShipTo: cartShipToOptionsSelections?.[shoppingContextToken]?.multiShipTo ?? false,
      isMultiRequestedDeliveryDate: cartShipToOptionsSelections?.[shoppingContextToken]?.multiRDD ?? false,
      requestedDeliveryDate: cartShipToOptionsSelections?.[shoppingContextToken]?.deliveryDate ?? null,
      spToken: shoppingContextToken,
      key: 2,
      isPartialDelivery: cartShipToOptionsSelections?.[shoppingContextToken]?.partialDelivery === false ? false : true,
      usernameOrEmail: userDetails?.username,
    };
    let limitOrderQuantity: any = contextDetails?.limitOrderQuantity;

    if (
      limitOrderQuantity != undefined &&
      limitOrderQuantity.enabled &&
      qty > limitOrderQuantity.maxQuantity
    ) {
      let invalidLimitMsg: any = t("dnet.invalid_limit_order_quantity.text");
      invalidLimitMsg = invalidLimitMsg.replace(
        "{0}",
        limitOrderQuantity.maxQuantity
      );

      setProdCompareData((prevState: any) => {
        const updatedSelectProd = [...prevState?.selectedProducts];
        const updatedProdData = {
          ...updatedSelectProd[idx].prodData,
          quantityMessageTarget: invalidLimitMsg,
        };
        updatedSelectProd[idx] = {
          ...updatedSelectProd[idx],
          prodData: updatedProdData,
        };
        return {
          ...prevState,
          selectedProducts: updatedSelectProd,
        };
      });
    } else {
      dispatch(addToCart(cart));
      // navigate(`/${ROUTES_LABEL.ORDER_ENTRY}`);
    }
  };

  const redirectToDetails = (data: any) => {
    dispatch(setProductDetailsData(data?.prodDetails));
    dispatch(setCurrentProductSku(data?.prodData));
    navigate(`/${ROUTES_LABEL.PRODUCT_DETAILS}`);
  };

  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setOpenModal(false);
  };
  const [modalType, setModalType] = useState<any>("");
  const [modalData, setModalData] = useState<any>({});

  const openProdModal = (type: any, prod: any) => {
    dispatch(setProductDetailsData(prod?.prodDetails));
    dispatch(setCurrentProductSku(prod?.prodData));
    let publication: string = prod?.prodDetails?.publication;
    let images: any = [];
    if (!_.isEmpty(prod?.prodDetails)) {
      if (publication == "GLOBAL") {
        if (prod?.prodDetails?.images?.primaryShot?.length > 0) {
          images.push(...prod?.prodDetails?.images?.primaryShot);
        }
        if (prod?.prodDetails?.images?.productShots?.length > 0) {
          images.push(...prod?.prodDetails?.images?.productShots);
        }
      } else if (publication == "SCORE") {
        // if (prod?.prodDetails?.images?.miniThumbnails?.length > 0) {
        //   images.push(...prod?.prodDetails?.images?.miniThumbnails);
        // }
        if (prod?.prodDetails?.images?.images?.length > 0) {
          images.push(...prod?.prodDetails?.images?.images);
        }
        if (prod?.prodDetails?.images?.largerImage?.length > 0) {
          images.push(...prod?.prodDetails?.images?.largerImage);
        }
        if (prod?.prodDetails?.images?.zoomImage?.length > 0) {
          images.push(...prod?.prodDetails?.images?.zoomImage);
        }
      } else if (publication == "PSE" || publication == "NGP") {
        // if (prod?.prodDetails?.images?.proThumbnailx113x68?.length > 0) {
        //   images.push(...prod?.prodDetails?.images?.proThumbnailx113x68);
        // }
        if (prod?.prodDetails?.images?.proWebImage?.length > 0) {
          images.push(...prod?.prodDetails?.images?.proWebImage);
        }
        if (prod?.prodDetails?.images?.proLargeImage?.length > 0) {
          images.push(...prod?.prodDetails?.images?.proLargeImage);
        }
        if (prod?.prodDetails?.images?.proZoomImage?.length > 0) {
          images.push(...prod?.prodDetails?.images?.proZoomImage);
        }
      }
    }
    let data: any = {};
    switch (type) {
      case MODAL_TYPES.IMAGES:
        data.title = prod?.prodDetails?.skuName;
        data.isVisibleBaseLink = false;
        data.largeImages = images;
        data.productDisplayName=  prod?.prodDetails?.materialName;
        break;
      case MODAL_TYPES.VIDEOS:
        data.title = prod?.prodDetails?.skuName;
        data.videos = prod?.prodDetails?.videos;
        break;
      case MODAL_TYPES.PRINT:
        data.title = t("dnet.print_options.text");
        data.images = images;
        break;
    }
    setModalType(type);
    setOpenModal(true);
    setModalData(data);
  };

  return (
    <>
      <Typography variant="h5" pb={1} color="primary">
        {t("dnet.product_comparison_features_title.text")}
      </Typography>
      <Box className={styles.buttonContainer}>
        <Box mt={1}>
          <Tabs
            variant="scrollable"
            allowScrollButtonsMobile
            value={value}
            onChange={handleChange}
            className={styles.tabsStyle}
            TabIndicatorProps={{
              sx: {
                bottom: "4px",
              },
            }}
          >
            {prodCompareData?.showFeature && (
              <Tab
                label={t("dnet.features.text")}
                onClick={(e: any) => compareHandler("feature")}
              />
            )}
            {prodCompareData?.showBenefits && (
              <Tab
                label={t("dnet.benefits.text")}
                onClick={(e: any) => compareHandler("benefit")}
              />
            )}
            {prodCompareData?.showTech && (
              <Tab
                label={t("dnet.specifications.text")}
                onClick={(e: any) => compareHandler("spec")}
              />
            )}
          </Tabs>
        </Box>
        <Box mt={1}>
          <CustomButton
            label={t("downloadExcel.text")}
            onClick={() => downloadXlsHandler()}
            color={GREEN}
          />
        </Box>
      </Box>
      <TableContainer className={styles.tableContainer} component={Paper}>
        <Table size="small">
          <TableRow>
            <TableCell style={{ minWidth: "160px" }}>
              <CustomButton
                label={t("dnet.back_to_selection.text")}
                onClick={() => navigate(-1)}
                margin={0}
              />
            </TableCell>
            {prodCompareData?.selectedProducts?.map((prod: any) => (
              <TableCell style={{ minWidth: "300px" }}>
                {prodCompareData?.selectedProducts?.length > 2 ? (
                  <CustomButton
                    label={t("dnet.remove.text")}
                    onClick={() => removeProduct(prod?.index)}
                    margin={0}
                    color="#bd2130"
                  />
                ) : (
                  <>&nbsp;</>
                )}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h6" color="primary">
                {t("dnet.product.text")}
              </Typography>
            </TableCell>
            {prodCompareData?.selectedProducts?.map((prod: any) => {
              return (
                <TableCell>
                  <Typography variant="h6">
                    {prod?.prodData?.product?.index?.catalogName}
                  </Typography>
                  <img
                    style={{ cursor: "pointer" }}
                    src={getImage(prod?.prodDetails?.images)?.[0]}
                    alt="Image"
                    width="47"
                    onClick={(e: any) => redirectToDetails(prod)}
                  />
                  <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                  >
                    {prod?.prodData?.product?.index?.headlineDescription}
                  </Typography>
                  <ul className={styles.linksListStyle}>
                    {!_.isEmpty(prod?.prodDetails?.images) && (
                      <li
                        onClick={() => openProdModal(MODAL_TYPES.IMAGES, prod)}
                      >
                        <Typography variant="caption" className={styles.links}>
                          <ImageOutlinedIcon sx={{ marginRight: "6px" }} />
                          {t("dnet.images_subtype.text")}
                        </Typography>
                      </li>
                    )}
                    {prod?.prodDetails?.videos?.length > 0 && (
                      <li
                        onClick={() => openProdModal(MODAL_TYPES.VIDEOS, prod)}
                      >
                        <Typography variant="caption" className={styles.links}>
                          <VideocamOutlinedIcon sx={{ marginRight: "6px" }} />
                          {t("dnet.videos_subtype.text")}
                        </Typography>
                      </li>
                    )}
                    <li onClick={() => openProdModal(MODAL_TYPES.PRINT, prod)}>
                      <Typography variant="caption" className={styles.links}>
                        <PrintIcon sx={{ marginRight: "6px" }} />
                        {t("dnet.print.text")}
                      </Typography>
                    </li>
                  </ul>
                </TableCell>
              );
            })}
          </TableRow>
          {type === "feature" && (
            <TableRow>
              <TableCell>
                <Typography variant="h6" color="primary">
                  {t("dnet.features.text")}
                </Typography>
              </TableCell>
              {prodCompareData?.selectedProducts?.map((prod: any) => (
                <TableCell>
                  {prod?.prodDetails?.featureBenefits?.length > 0 ? (
                    <ul className={styles.featureUl}>
                      {prod?.prodDetails?.featureBenefits?.map(
                        (feature: any) => (
                          <li>
                            <Typography
                              variant="caption"
                              component="div"
                              color="text.secondary"
                            >
                              {feature?.headline}&nbsp;{feature?.bodyCopy}
                            </Typography>
                          </li>
                        )
                      )}
                    </ul>
                  ) : (
                    t("dnet.no_info_for_comparison.text")
                  )}
                </TableCell>
              ))}
            </TableRow>
          )}
          {type === "benefit" && (
            <TableRow>
              <TableCell>
                <Typography variant="h6" color="primary">
                  {t("dnet.benefits.text")}
                </Typography>
              </TableCell>
              {prodCompareData?.selectedProducts?.map((prod: any) => (
                <TableCell>
                  {prod?.prodDetails?.proBenefit?.length > 0 ? (
                    <ul className={styles.featureUl}>
                      {prod?.prodDetails?.proBenefit?.map((bene: any) => (
                        <li>
                          <Typography
                            variant="caption"
                            component="div"
                            color="text.secondary"
                          >
                            {bene?.headline}&nbsp;{bene?.bodyCopy}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    t("dnet.no_info_for_comparison.text")
                  )}
                </TableCell>
              ))}
            </TableRow>
          )}
          {type === "spec" && (
            <>
              {prodCompareData?.selectedProducts[
                selectedTechIndex
              ]?.prodDetails?.techSpecs?.map((spec: any) => (
                <>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1" color="primary">
                        {spec?.displayName}
                      </Typography>
                    </TableCell>
                    {prodCompareData?.selectedProducts?.map((prod: any) => (
                      <TableCell>
                        <Typography variant="body1">
                          {prod?.prodData?.product?.index?.catalogName}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>

                  {spec?.specs?.map((sub: any) => (
                    <TableRow>
                      <TableCell>
                        <Typography variant="caption">
                          {sub?.displayName}
                        </Typography>
                      </TableCell>
                      {prodCompareData?.selectedProducts?.map((prod: any) => (
                        <TableCell>
                          <Typography variant="caption">
                            {specData[prod?.prodDetails?.materialName][
                              spec?.name + sub?.name
                            ]
                              ? specData[prod?.prodDetails?.materialName][
                                  spec?.name + sub?.name
                                ]
                              : t("dnet.na.text")}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              ))}
              <TableRow>
                <TableCell>&nbsp;</TableCell>
                {prodCompareData?.selectedProducts?.map(
                  (prod: any, index: any) => (
                    <TableCell>
                      {!_.isEmpty(specData[prod?.prodDetails?.materialName]) &&
                      index !== selectedTechIndex ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Radio
                            size="small"
                            onChange={(e: any) => setSelectedTechIndex(index)}
                            sx={{ paddingLeft: 0 }}
                          />
                          <Typography variant="caption">
                            {t("dnet.compare_acc_master.text")?.replace(
                              "{0}",
                              prod?.prodData?.product?.index?.catalogName
                            )}
                          </Typography>
                        </div>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  )
                )}
              </TableRow>
            </>
          )}
          <TableRow>
            <TableCell>
              {!isReseller && (
                <CustomButton
                  label={t("dnet.add_selection.text")}
                  onClick={() => redirectToOrderEntryAddSelection()}
                  margin={"0 0 8px 0"}
                />
              )}
              <CustomButton
                label={t("downloadExcel.text")}
                onClick={() => downloadXlsHandler()}
                color={GREEN}
                margin={0}
              />
            </TableCell>
            {!isReseller &&
              !isInternalAdmin &&
              prodCompareData?.selectedProducts?.map((prod: any) => (
                <TableCell>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography variant="h6" color="primary" mb={1}>
                        {prod?.prodData?.product?.index?.catalogName}
                      </Typography>
                    </Grid>
                    <Grid item lg={5} md={5} sm={5} xs={5}>
                      <span className={styles.label}>
                        {t("dnet.availability.text")}
                      </span>
                    </Grid>
                    <Grid item lg={7} md={7} sm={7} xs={7}>
                      <Box>
                        <Box>
                          <CircleIcon
                            className={styles.availabilityIcon}
                            sx={{
                              color: prod?.availabilityDetails?.colorCode
                                ? prod?.availabilityDetails?.colorCode[0]
                                : "",
                            }}
                          />
                          <CircleIcon
                            className={styles.availabilityIcon}
                            sx={{
                              color: prod?.availabilityDetails?.colorCode
                                ? prod?.availabilityDetails?.colorCode[1]
                                : "",
                            }}
                          />
                          <CircleIcon
                            className={styles.availabilityIcon}
                            sx={{
                              color: prod?.availabilityDetails?.colorCode
                                ? prod?.availabilityDetails?.colorCode[2]
                                : "",
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    {isPriceVisible && (
                      <>
                        <Grid item lg={5} md={5} sm={5} xs={5} my={1}>
                          <span
                            className={`${styles.label} ${styles.listStyle}`}
                            onClick={() =>
                              setIsListValVisible(!isListValVisible)
                            }
                          >
                            {t("dnet.list_price_colon.text")}
                          </span>
                        </Grid>
                        <Grid item lg={5} md={7} sm={7} xs={7} mt={1} mb={1}>
                          {isListValVisible && (
                            <Box>
                              <Box>
                                {prod?.prodData?.product?.price?.currency}{" "}
                                {prod?.prodData?.product?.price?.price &&
                                  parseFloat(
                                    prod?.prodData?.product?.price?.price?.toString()
                                  ).toFixed(2)}
                              </Box>
                              <Box className={styles.qtyText}>
                                <span className={styles.messageStyle}>
                                  {prod?.prodData?.otherTarget}
                                </span>
                              </Box>
                            </Box>
                          )}
                        </Grid>
                      </>
                    )}
                    {prod?.prodData?.product?.definition?.ean && (
                      <>
                        <Grid item lg={5} md={5} sm={5} xs={5} mt={1}>
                          <span className={styles.label}>
                            {t("dnet.ean_colon.text")}
                          </span>
                        </Grid>
                        <Grid item lg={7} md={7} sm={7} xs={7} mt={1} mb={1}>
                          {prod?.prodData?.product?.definition?.ean}
                        </Grid>
                      </>
                    )}
                    {!prod?.prodData?.product?.definition?.ean &&
                      prod?.prodData?.product?.definition?.upc && (
                        <>
                          <Grid item lg={5} md={5} sm={5} xs={5} mt={1}>
                            <span className={styles.label}>
                              {t("dnet.upc_colon.text")}
                            </span>
                          </Grid>
                          <Grid item lg={7} md={7} sm={7} xs={7} mt={1} mb={1}>
                            {prod?.prodData?.product?.definition?.upc}
                          </Grid>
                        </>
                      )}
                    <Grid item lg={5} md={5} sm={5} xs={5} mt={1}>
                      <span className={styles.label}>
                        {t("dnet.net_weight_colon.text")}
                      </span>
                    </Grid>
                    <Grid item lg={7} md={7} sm={7} xs={7} mt={1} mb={1}>
                      {prod?.prodData?.product?.definition?.weight}&nbsp;
                      {prod?.prodData?.product?.definition?.weightUnit}
                    </Grid>
                    <Grid item lg={5} md={5} sm={5} xs={5} mt={1}>
                      <span className={styles.label}>
                        {t("dnet.quantity_colon.text")}
                      </span>
                    </Grid>
                    <Grid item lg={7} md={7} sm={7} xs={7} mt={1} mb={1}>
                      <Box className={styles.qtyContainer}>
                        <CustomTextField
                          label={""}
                          value={prod?.prodData?.qty}
                          changeHandler={(e: any) => {
                            e.target.value = e.target.value
                              .toString()
                              .slice(0, 6);
                            updateQuantity(e.target.value, prod?.index);
                          }}
                          additionalSxProperties={{
                            width: "70px",
                            height: "15px",
                          }}
                        />
                        <IconButton
                          onClick={() =>
                            handleQuantityChange("decrement", prod?.index)
                          }
                          title={t("dnet.decrement_info.text")?.toString()}
                        >
                          <RemoveCircleOutlineOutlinedIcon />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            handleQuantityChange("increment", prod?.index)
                          }
                          title={t("dnet.increment_info.text")?.toString()}
                        >
                          <AddCircleOutlineOutlinedIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className={styles.searchCartStyle}>
                        <IconButton
                          onClick={() => redirectToOrderEntry(prod?.index)}
                        >
                          <AddShoppingCartOutlinedIcon />
                        </IconButton>
                      </Box>
                      <Box className={styles.qtyText}>
                        <span className={styles.messageStyle}>
                          {prod?.prodData?.quantityMessageTarget}
                        </span>
                      </Box>
                    </Grid>
                  </Grid>
                </TableCell>
              ))}
          </TableRow>
        </Table>
      </TableContainer>
      <ProdDetailsModal
        open={openModal}
        closeModal={closeModal}
        type={modalType}
        data={modalData}
      />
    </>
  );
};
