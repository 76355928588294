import { commonConstant } from "../constants/common-constants";

export const initialState = {
  businessGroupList : [],
  validateDealer : {},
};

const changeDealerReducer = (state = initialState, action: any) => {
  switch (action?.type) {
    case commonConstant.BUSINESS_GROUP_SUCCESS:
      return {
        ...state,
        businessGroupList: action?.payload,
      };
    case commonConstant.VALIDATE_DEALER_SUCCESS:
      return {
        ...state,
        validateDealer: action?.payload,
      };
    default:
      return state;
  }
};

export default changeDealerReducer;
