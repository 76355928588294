import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCreditManagementInfo } from "../../../state/actions";
import styles from "./credit-management-info.module.scss";

export const CreditManagementInfo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );

  const saveAccountDetails = useSelector(
    (state: any) => state?.commonReducer?.saveAccountDetails
  )
  //on load dispatches an event to fetch the details of credit management
  useEffect(() => {
    dispatch(
      getCreditManagementInfo({
        organizationKey: currentDealer?.bgName,
        partnerKey: saveAccountDetails?.payerToAcc?.number
      })
    );
  }, []);

  const creditInfo = useSelector(
    (state: any) => state?.gazelleReducer?.creditManagementInfo
  );

  return (
    <>
      <Typography variant="subtitle1" className={styles.header} pb={3} color="primary">
        {t("gzl.credit_management_title.text")}
      </Typography>
      <Grid container>
        <Grid item lg={9} md={9} sm={12} xs={12}>
          <TableContainer component={Paper} className={styles.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("gzl.credit_limit.text")}</TableCell>
                  <TableCell>{t("gzl.credit_exposure_risk.text")}</TableCell>
                  <TableCell>{t("gzl.contact.text")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {creditInfo?.map((info: any,index:number) => (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    key={index}
                  >
                    <TableCell>
                      {info?.creditLimit}&nbsp;{info?.currency}{" "}
                    </TableCell>
                    <TableCell>
                      {info?.exposure}&nbsp; {info?.currency}{" "}
                    </TableCell>
                    <TableCell>{info?.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
