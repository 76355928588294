import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./printDetails.module.scss";
import { useDispatch } from "react-redux";
import CustomButton from "../../../../atoms/customButton";
import CustomTextField from "../../../../atoms/customTextField";
import FileUploadOutlined from "@mui/icons-material/FileUploadOutlined";
import { useSelector } from "react-redux";
// @ts-ignore  
import { useReactToPrint } from "react-to-print";
import { ComponentToPrint } from "../../../../componentToPrint";
//@ts-ignore
import html2pdf from "html2pdf.js";
import { getContextDetails, getProductImages, getProductsByMaterialName, sendProductDetailsMail } from "../../../../../state/actions";
import { CONTEXT_ORDER } from "../../../../../constants/commonConstants";
const FileSaver = require("file-saver");
const _ = require("lodash");

export const PrintDetails = (props: any) => {
  const { modalDetails } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const productDetailsData = useSelector(
    (state: any) => state?.productReducer?.productDetailsData
  );
  const currentProductSku = useSelector(
    (state: any) => state?.productReducer?.currentProductSku
  );
  const productByMaterialName = useSelector(
    (state: any) => state?.productReducer?.productsByMaterialName
  );
  useEffect(()=>{
    if (productDetailsData?.accessories?.length > 0) {
      dispatch(
        getProductsByMaterialName({
          materialName: productDetailsData?.accessories,
        })
      );
    }
  },[productDetailsData]);
  
  useEffect(() => {
    let  updatedAccesories:any = [];
    if(!_.isEmpty(productByMaterialName))
    {
      updatedAccesories = productByMaterialName?.map(
        (product: any) => Object.values(product)[0]
      );
    } 
    const filteredProducts = updatedAccesories?.filter((product:any)=> product?.product?.index && product?.product?.index?.uri);
    setAccessories(filteredProducts);
}, [productByMaterialName]);

useEffect(() => {
  let productImageURL = [];
  let url: any = modalDetails?.images[0];
  const alteredURL = url;
  const parts = alteredURL.split("/");
  const relevantPart = parts.slice(3).join("/");
  const flag = url?.includes("sp.sony-europe.com") ? 2 : (url?.includes("sony.scene7.com") ? 3 : 1);

  productImageURL.push({
    imageURL: relevantPart,
    flag: flag,
    materialName: currentProductSku?.product?.definition?.materialName,
  });
  dispatch(getProductImages({ payload: productImageURL, separateImage: true }));
}, [modalDetails]);

const productImages = useSelector(
  (state: any) => state?.productReducer?.productImages
);

useEffect(()=>{

  if(!_.isEmpty(productImages) || productImages === undefined)
  {
    dispatch(
      getContextDetails({
        key: CONTEXT_ORDER?.PRODUCT_MODULE_PAGE,
      })
    );
  }

},[productImages]);


const [accessories,setAccessories]= useState([]);

const sendMailResponse= useSelector(
    (state: any) => state?.productReducer?.sendProductDetailsMail
)
const [displayRetailPrice, setDisplayRetailPrice] = useState<any>(false);

const [isInternalAdmin, setIsInternalAdmin] = useState(false);

const currUserType = useSelector(
    (state: any) => state?.commonReducer?.currentDealer?.accountFlag
);
  useEffect(() => {
    if (currUserType == 0) {
      setIsInternalAdmin(true);
    }
  }, [currUserType]);

  // decide to display retail price warning
  // boolean displayRetailPriceWarning = mainDataTypes.contains(RETAIL_PRICE);
  // modelMap.put("displayRetailPriceWarning", displayRetailPriceWarning);

  const [mailResponse,setMailResponse]= useState<any>("");

  useEffect(()=>{
   setMailResponse(sendMailResponse?.successMessage?.toString() || sendMailResponse?.errorMessage?.toString() );
  },[sendMailResponse]);

  useEffect(() => {
    const displayRetailPriceWarning = false;
    setDisplayRetailPrice(
      displayRetailPriceWarning &&
        currentProductSku?.product?.price?.retailPrice
    );
  }, [currentProductSku]);



  const contextDetails = useSelector(
    (state: any) => state?.commonReducer?.contextDetails
  );
  const EShotinPrintPage = contextDetails?.isEShotinPrintPage; 
  const [checkBoxOptions, setCheckBoxOptions] = useState<any>({
    nameAndDescription: true,
    image: true,
    specifications: true,
    price: false,
    technicalSummary: false,
    overview: false,
    benefits: false,
    features: false,
    accessories: false,
    dealerDetails: false,
    productAwardsAndReviews: false,
    energyClass: false,
    productIntroMonth: false,
  });

  const handleCheckbox = (data: any, fieldName: any) => {
    setCheckBoxOptions({ ...checkBoxOptions, [fieldName]: data });
  };
  const mailHandler = () => {
    
    setMailResponse("");
    setOpenEmail(!openEmail);
    // componentRef?.current?.reset();
  };

  const [introduction,setIntroduction]= useState('');
  const [emailList,setEmailList]= useState('');
  const [includeMyEmail,setIncludeMyEmail]= useState(false);

  const handleIntroductionChange =(e:any)=>{
    setMailResponse("");
    setIntroduction(e.target.value);
  }
  const handleEmailChange =(e:any)=>{
    setMailResponse("");
    setEmailList(e.target.value);
  }

  const handleCheckboxChange= (e:any)=>{
    setMailResponse("");
    setIncludeMyEmail(e.target.checked);
  }

  const fetchCheckbox = (fieldName: any, label: any) => {
    return (
      <Box className={styles.checkboxStyle}>
        <Checkbox
          checked={checkBoxOptions[fieldName]}
          onChange={(e: any) => {
            handleCheckbox(e.target.checked, fieldName);
          }}
          size="small"
          sx={{ padding: "2px 0" }}
          id={fieldName}
        />
        <Typography
          component="div"
          variant="body2"
          color="text.secondary"
          ml={1}
        >
          {t(label)}
        </Typography>
      </Box>
    );
  };

  const [priceInfo, setPriceInfo] = useState({
    priceType: "sales",
    retailPrice: currentProductSku?.product?.price?.price,
    currency: currentProductSku?.product?.price?.currency,
    priceTypeText: "",
    disablePriceTypeText: false,
    salesPriceDetail: "",
  });

  const [dealerDetails, setDealerDetails] = useState<any>({
    dealerAddress: "",
    file: {},
  });

  useEffect(() => {
    if (priceInfo?.priceType && priceInfo?.priceType === "list") {
      setPriceInfo({
        ...priceInfo,
        priceTypeText: priceInfo?.currency?.concat(
          " " + priceInfo?.retailPrice
        ),
        disablePriceTypeText: true,
      });
    } else {
      setPriceInfo({
        ...priceInfo,
        priceTypeText: "",
        disablePriceTypeText: false,
      });
    }
  }, [priceInfo?.priceType]);

  let componentRef: any = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [pdfBase64,setPdfBase64]= useState<string | null>(null);

  function pdfToBase64(pdfBlob: Blob): Promise<string>{
    return new Promise((resolve,reject)=>{
      const reader= new FileReader();
      reader.onload=()=>{
        if(typeof reader.result==='string'){
          const base64String=reader.result.split(',')[1];
          resolve(base64String);
        }else{
          reject(new Error('Unable to read file as Base64'));
        }
      };
      reader.onerror= error=> reject(error);
      reader.readAsDataURL(pdfBlob);
    })
  }

  // const handlePDFConverterForEmail = (e: any) => {
  //   handlePrint(e);
  //   html2pdf().from(componentRef.current).toPdf().get('pdf').then((pdf:Blob)=>{
  //     pdfToBase64(pdf).then(base64String=>{
  //       setPdfBase64(base64String);
  //     }).catch(error=>{
  //       console.log('Error converting pdf to base 64',error);
  //     });
  //   });
  // }
  const handleBase64 = useReactToPrint({
    
    content: () => componentRef.current,
    copyStyles: true,
    print: async (printIframe: HTMLIFrameElement) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByTagName("html")[0];
        var opt = {
          margin: 0,
          filename: `${currentProductSku?.product?.definition?.materialName}.pdf`,
          image: { type: "jpeg", quality: 0.2 },
          html2canvas: { scale: 2, useCORS: true },
          jsPDF: { unit: "in", format: "a4", orientation: "p" },
        };
        await html2pdf().set(opt).from(html).outputPdf('blob').then((pdf:Blob)=>{
          pdfToBase64(pdf).then(base64String=>{
            setPdfBase64(base64String);
          }).catch(error=>{
            console.log('Error converting pdf to base 64',error);
          });
        });
        // const pdfData = new Blob([pdfContent?.outerHTML],{type: 'application/pdf'});
      }
    },
  });


  




  const handleDownload = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    print: async (printIframe: HTMLIFrameElement) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByTagName("html")[0];
        var opt = {
          margin: 0,
          filename: `${currentProductSku?.product?.definition?.materialName}.pdf`,
          image: { type: "jpeg", quality: 0.5 },
          html2canvas: { scale: 2, useCORS: true },
          jsPDF: { unit: "in", format: "a4", orientation: "l",precision: 100 },
        };
        await html2pdf().set(opt).from(html).save();
      }
    },
  });

  const fetchPrintDetails = () => {
    let yourByteArrayAsBase64 = "";
    if (dealerDetails?.displayFile) {
      new Response(dealerDetails?.displayFile)
        .arrayBuffer()
        .then((res: any) => {
          yourByteArrayAsBase64 = res;
        });
    }

   return (
     <div>
       {checkBoxOptions?.nameAndDescription && (
         <>
           <div
             style={{
               fontSize: "1rem",
               fontWeight: "bold",
               paddingBottom: "1",
               color: "#f25529",
             }}
           >
             {currentProductSku?.product?.index?.displayName}
           </div>
           <div
             style={{
               fontSize: "0.83rem",
               fontWeight: "bold",
               paddingBottom: "1",
             }}
           >
             {productDetailsData?.marketingProductName}
           </div>
           <div
             style={{
               fontSize: "0.67rem",
               color: "#000000",
             }}
           >
             {productDetailsData?.elevatorPitch}
           </div>
           <hr style={{ marginTop: "24px" }} />
         </>
       )}
       {(checkBoxOptions?.nameAndDescription ||
         checkBoxOptions?.image ||
         checkBoxOptions?.price) && (
         <>
           <div style={{ display: "flex" }}>
             {checkBoxOptions?.image && (
               <img
                 src={productImages?.data? productImages?.data?.[0]?.base64Image : ""}
                 style={{ width: 250, marginRight: "32px" }}
               />
             )}
             {(checkBoxOptions?.nameAndDescription ||
               checkBoxOptions?.price) && (
               <div>
                 {checkBoxOptions?.price && (
                   <div style={{ marginTop:2 }}>
                     {priceInfo?.priceType === "sales"
                       ? priceInfo?.priceTypeText && (
                           <div>
                            <div style={{display:"flex"}}>
                             <div
                               style={{
                                 fontSize: "1rem",
                                 color: "black",
                               }}
                             >
                               {t("dnet.sales_price.text")}&nbsp;:&nbsp;
                             </div>
                             <div
                               style={{
                                 fontSize: "1rem",
                                 color: "#f25529",
                               }}
                             >
                               {priceInfo?.priceTypeText}
                             </div>
                             </div>
                             {priceInfo?.salesPriceDetail && (
                               <div style={{display:"flex"}}>
                                 <div
                                   style={{
                                     fontSize: "1rem",
                                     color: "black",
                                   }}
                                 >
                                   {t("salesPriceDetailInfo.text")}&nbsp;:&nbsp;
                                 </div>
                                 <div
                                   style={{
                                     fontSize: "1rem",
                                     color: "#f25529",
                                   }}
                                 >
                                   {priceInfo?.salesPriceDetail}
                                 </div>
                               </div>
                             )}
                           </div>
                         )
                       : priceInfo?.priceTypeText && (
                           <div>
                            <div style={{display:"flex"}}>
                             <div
                               style={{
                                 fontSize: "1rem",
                                 color: "black",
                               }}
                             >
                               {t("dnet.list_price.text")}&nbsp;:&nbsp;
                             </div>
                             <div
                               style={{
                                 fontSize: "1rem",
                                 color: "#f25529",
                               }}
                             >
                               {priceInfo?.priceTypeText}
                             </div>
                             </div>
                             {priceInfo?.salesPriceDetail && (
                               <div style={{display:"flex"}}>
                                 <div
                                   style={{
                                     fontSize: "1rem",
                                     color: "black",
                                   }}
                                 >
                                   {t("listPriceDetailInfo.text")}&nbsp;:&nbsp;
                                 </div>
                                 <div
                                   style={{
                                     fontSize: "1rem",
                                     color: "#f25529",
                                   }}
                                 >
                                   {priceInfo?.salesPriceDetail}
                                 </div>
                               </div>
                             )}
                           </div>
                         )}
                   </div>
                 )}
                 {checkBoxOptions?.nameAndDescription && (
                   <div style={{ marginTop: 2 }}>
                     <div
                       style={{ fontSize: "1rem", color: "#f25529" }}
                       dangerouslySetInnerHTML={{
                         __html: productDetailsData?.factualShortDescription,
                       }}
                     />
                     <div style={{ fontSize: "1rem", color: "#000000" }}>
                       <ul>
                         {productDetailsData?.topFeatures?.map(
                           (feature: any, index: any) => (
                             <li key={index}>{feature}</li>
                           )
                         )}
                       </ul>
                     </div>
                   </div>
                 )}
               </div>
             )}
           </div>
           <Divider style={{ marginTop: "24px" }} />
         </>
       )}
       {checkBoxOptions?.productAwardsAndReviews && (
         <div style={{ marginTop: 4, display: "flex" }}>
           {productDetailsData?.awards?.map((award: any, index: any) => (
             <img
               key={index}
               src={award}
               alt="Award"
               title="Award"
               width="67"
             />
           ))}
           {productDetailsData?.expertReviews?.map(
             (review: any, index: any) => (
               <img
                 key={index}
                 src={review}
                 alt="Review"
                 title="Review"
                 width="67"
               />
             )
           )}
         </div>
       )}
       {checkBoxOptions?.technicalSummary && (
         <div style={{ marginTop: 4 }}>
           <div
             style={{
               backgroundColor: "#dedede",
               padding: "5px",
               fontSize: "18px",
             }}
           >
             {t("dnet.technical_summary.text")}
           </div>
           <div style={{ marginTop: 2, display: "inline-flex", gap: "32px" }}>
             {productDetailsData?.iconDescription && (
               <div>
                 <div
                   style={{
                     fontSize: "1rem",
                     color: "black",
                     marginBottom: 1,
                   }}
                 >
                   {t("dnet.technicalSummary.iconDescriptions.title")}
                 </div>
                 <ul style={{ paddingLeft: "20px" }}>
                   {productDetailsData?.iconDescription?.map(
                     (desc: any, index: any) => (
                       <li>
                         <div
                           style={{ fontSize: "0.67rem", color: "#000000" }}
                           key={index}
                         >
                           {desc}
                         </div>
                       </li>
                     )
                   )}
                 </ul>
               </div>
             )}
             {productDetailsData?.keyTechSpecs && (
               <div>
                 <div
                   style={{
                     fontSize: "1rem",
                     color: "black",
                   }}
                 >
                   {t("dnet.technicalSummary.keyTechSpecs.title")}
                 </div>
                 <ul style={{ paddingLeft: "20px" }}>
                   {productDetailsData?.keyTechSpecs?.map(
                     (feature: any, index: any) => (
                       <li>
                         <div
                           style={{
                             fontSize: "0.83rem",
                             color: "black",
                           }}
                         >
                           {feature?.displayName}
                         </div>

                         {!_.isEmpty(feature?.value) &&
                           feature?.value?.map((spec: any, index: any) => (
                             <div
                               style={{ fontSize: "0.67rem", color: "#000000" }}
                               key={index}
                               color="text.secondary"
                               dangerouslySetInnerHTML={{ __html: spec }}
                             ></div>
                           ))}
                       </li>
                     )
                   )}
                 </ul>
               </div>
             )}
             {productDetailsData?.inTheBox && (
               <div>
                 <div
                   style={{
                     fontSize: "1rem",
                     color: "black",
                     marginBottom: 1,
                   }}
                 >
                   {t("dnet.technicalSummary.whatsInTheBox.title")}
                 </div>
                 <ul style={{ paddingLeft: "20px" }}>
                   {productDetailsData?.inTheBox?.map(
                     (boxItem: any, index: any) => (
                       <li>
                         <div
                           style={{ fontSize: "0.67rem", color: "#000000" }}
                           key={index}
                         >
                           {boxItem}
                         </div>
                       </li>
                     )
                   )}
                 </ul>
               </div>
             )}
           </div>
         </div>
       )}
       {checkBoxOptions?.specifications && productDetailsData?.techSpecs && (
         <div style={{ marginTop: 4 }}>
           <div
             style={{
               backgroundColor: "#dedede",
               padding: "5px",
               fontSize: "18px",
             }}
           >
             {t("dnet.specifications.text")}
           </div>
           <Grid container>
             {productDetailsData?.techSpecs?.map(
               (featureGroup: any, index: any) => (
                 <Grid item mt={2} lg={6}>
                   <div style={{ fontSize: "1rem", color: "#f25529" }}>
                     {featureGroup?.displayName}
                   </div>
                   {featureGroup?.specs?.map((feature: any, index: any) => (
                     <Grid container>
                       <Grid item lg={4}>
                         <div style={{ fontSize: "0.67rem", color: "black" }}>
                           {feature?.displayName || feature?.name}
                         </div>
                       </Grid>
                       <Grid item lg={8}>
                         {Array.isArray(feature?.value) ? (
                           feature?.value?.map((spec: any, index: any) => (
                             <React.Fragment key={index}>
                               <div
                                 style={{
                                   fontSize: "0.67rem",
                                   color: "#000000",
                                 }}
                                 dangerouslySetInnerHTML={{ __html: spec }}
                               ></div>
                               <br />
                             </React.Fragment>
                           ))
                         ) : (
                           <React.Fragment>
                             <div
                               style={{ fontSize: "0.67rem", color: "#000000" }}
                               dangerouslySetInnerHTML={{
                                 __html: feature?.value,
                               }}
                             ></div>
                             <br />
                           </React.Fragment>
                         )}
                       </Grid>
                     </Grid>
                   ))}
                 </Grid>
               )
             )}
           </Grid>
         </div>
       )}
       {checkBoxOptions?.features && (
         <div style={{ marginTop: 4 }}>
           <div
             style={{
               backgroundColor: "#dedede",
               padding: "5px",
               fontSize: "18px",
             }}
           >
             {t("dnet.features.text")}
           </div>
           <Grid container mb={2}>
             {productDetailsData?.featureBenefits?.map(
               (overview: any, index: any) => (
                 <Grid item lg={6} p={2}>
                   <div
                     style={{
                       fontSize: "1rem",
                       color: "#f25529",
                       paddingBottom: 0.5,
                     }}
                   >
                     {overview?.headline}
                   </div>
                   <div style={{ fontSize: "0.67rem", color: "#000000" }}>
                     {overview?.bodyCopy}
                   </div>
                 </Grid>
               )
             )}
           </Grid>
         </div>
       )}
       {checkBoxOptions?.overview && (
         <div style={{marginTop:4}}>
           <div
             style={{
               backgroundColor: "#dedede",
               padding: "5px",
               fontSize: "18px",
             }}
           >
             {t("dnet.overview.text")}
           </div>
           <Grid container mb={2}>
             {productDetailsData?.proOverview?.map(
               (overview: any, index: any) => (
                 <Grid item lg={6} p={2}>
                   <div
                     style={{
                       fontSize: "1rem",
                       color: "#f25529",
                       paddingBottom: 0.5,
                     }}
                   >
                     {overview?.headline}
                   </div>
                   <div style={{ fontSize: "0.67rem", color: "#000000" }}>
                     {overview?.bodyCopy}
                   </div>
                 </Grid>
               )
             )}
           </Grid>
         </div>
       )}
       {checkBoxOptions?.benefits && (
         <div style={{marginTop:4}}>
           <div
             style={{
               backgroundColor: "#dedede",
               padding: "5px",
               fontSize: "18px",
             }}
           >
             {t("dnet.benefits.section")}
           </div>
           <Grid container mb={2}>
             {productDetailsData?.proBenefit?.map(
               (overview: any, index: any) => (
                 <Grid item lg={6} p={2}>
                   <div
                     style={{
                       fontSize: "1rem",
                       color: "#f25529",
                       paddingBottom: 0.5,
                     }}
                   >
                     {overview?.headline}
                   </div>
                   <div style={{ fontSize: "0.67rem", color: "#000000" }}>
                     {overview?.bodyCopy}
                   </div>
                 </Grid>
               )
             )}
           </Grid>
         </div>
       )}
       {checkBoxOptions?.energyClass && (
         <div style={{ marginTop: 4 }}>
           <div
             style={{
               backgroundColor: "#dedede",
               padding: "5px",
               fontSize: "18px",
             }}
           >
             {t("energyClass.text")}
           </div>
           <Grid container>
             <Grid item lg={4}>
               {productDetailsData?.ecoEnergy?.ecoMessageShots?.map(
                 (imageUrl: any, index: any) => (
                   <img
                     key={index}
                     style={{ width: "100%", marginBottom: "8px" }}
                     height={150}
                     src={imageUrl}
                     alt="Energy Class"
                     title="Energy Class"
                   />
                 )
               )}
             </Grid>
             <Grid item lg={8}>
               <div style={{ padding: "12px 16px" }}>
                 {getItem(
                   "energyLabelRating.text",
                   productDetailsData?.ecoEnergy?.ecoEnergyLevel
                 )}
                 {getItem(
                   "screenSize.text",
                   productDetailsData?.ecoEnergy?.ecoScreenSize
                 )}
                 {getItem(
                   "powerConsumptionOnMode.text",
                   productDetailsData?.ecoEnergy?.ecoPowerConsumptionOnMode
                 )}
                 {getItem(
                   "annualEnergyConsumption.text",
                   productDetailsData?.ecoEnergy?.ecoAnnualEnergyConsumption
                 )}
                 {getItem(
                   "powerConsumptionStandyBy.text",
                   productDetailsData?.ecoEnergy?.ecoPowerConsumptionStandyBy
                 )}
                 {getItem(
                   "resolution.text",
                   productDetailsData?.ecoEnergy?.ecoDisplayResolution
                 )}
               </div>
             </Grid>
           </Grid>
         </div>
       )}
       {checkBoxOptions?.accessories && (
         <div style={{ marginTop: 4 }}>
           <div
             style={{
               backgroundColor: "#dedede",
               padding: "5px",
               fontSize: "18px",
             }}
           >
             {t("dnet.optional_accessories.text")}
           </div>
           <Grid container mb={2}>
             {productDetailsData?.accessories &&
               accessories?.map((accessory: any, index: any) => (
                 <Grid item lg={6} p={2}>
                   <div
                     style={{
                       fontSize: "1rem",
                       color: "#f25529",
                       paddingBottom: 0.5,
                     }}
                   >
                     {accessory?.product?.index?.displayName}
                   </div>
                   <div style={{ fontSize: "0.67rem", color: "#000000" }}>
                     {accessory?.product?.index?.headlineDescription}
                   </div>
                 </Grid>
               ))}
           </Grid>
         </div>
       )}
       <hr/>
       <div style={{ display: "flex", justifyContent: "space-between" }}>
         <div>
           {dealerDetails?.displayFile && (
             <img
               alt="Company Logo"
               height={100}
               width={100}
               // src={dealerDetails?.displayFile}
               src={`data:image/png;base64,${yourByteArrayAsBase64}`}
             />
           )}
           {checkBoxOptions?.dealerDetails && (
             <div style={{ fontSize: "0.67rem", color: "#000000" }}>
               {dealerDetails?.dealerAddress}
             </div>
           )}
         </div>
         <div>
           {checkBoxOptions?.productIntroMonth && (
             <div style={{ fontSize: "1rem", color: "#000000" }}>
               {currentProductSku?.product?.definition?.firstShipmentMonth}
             </div>
           )}
           {productDetailsData?.permalinkUrl && (
             <>
               <div style={{ fontSize: "1rem", color: "#000000" }}>
                 {t("marketingWebSiteProductPage.text")}
               </div>
               <a href={productDetailsData?.permalinkUrl}>
                 {productDetailsData?.permalinkUrl}
               </a>
             </>
           )}
         </div>
       </div>
     </div>
   );
  };

  const getItem = (label: any, value: any) => {
    return (
      <Box>
        <Typography
          component="span"
          variant="caption"
          color="black"
          sx={{ fontWeight: "600" }}
        >
          {t(label)} : &nbsp;
        </Typography>
        <Typography component="span" variant="caption" color="text.secondary">
          {value}
        </Typography>
      </Box>
    );
  };

  useEffect(()=>{
    let emailArr: any = emailList.split(",").map(email=>email.trim());
    if(pdfBase64 != undefined && pdfBase64 !=null)
    {
      setEmailList('');
      setIncludeMyEmail(false);
      setIntroduction('');
      dispatch(
        sendProductDetailsMail({
          modelName: currentProductSku?.product?.definition?.materialName,
          introductionText: introduction,
          toEmails: emailArr,
          sendToMe: includeMyEmail,
          base64EncodedString: pdfBase64
        })
      )
    }
  },[pdfBase64]);

  const [openEmail,setOpenEmail]= useState(false);
  const sendMail=(e: any)=>{
    setMailResponse("");
    handleBase64(e);
  };

  return (
    <>
    {openEmail? (<Box className={styles.mainContainer}> <div id="emailOptions">
        <div className="DealerInfo">
          <Box>
           {mailResponse!=="" && ( <><Typography
              component="div"
              variant="h6"
              color="black"
              ml={1}
              sx={{ fontWeight: "600" }}
            >
              {t(mailResponse)}
            </Typography>
            <br/>
            </>
           )
            
           }
            <Typography
              component="div"
              variant="body2"
              color="text-primary"
              ml={1}
            >
              {t("dnet.product_print_introduction.text")}
            </Typography>
            <TextField value={introduction} onChange={(e) =>handleIntroductionChange(e)} multiline rows={4} variant="outlined" className="Address" label="" sx={{ width: "350px", backgroundColor: "#fff"}}/>
          </Box>
          <br />
          <Box>
            <Typography
              component="div"
              variant="body2"
              color="text-primary"
              ml={1}
            >
              {t("dnet.product_print_email_address.text")}
            </Typography>
            <TextField value={emailList} onChange={(e:any) =>handleEmailChange(e)} variant="outlined" id="emaillist" label="" />
          </Box>
          <br />
          <div>
            <Box className={styles.checkboxStyle}>
              <Checkbox
                className="inputCheckBox"
                onChange={(e) =>handleCheckboxChange(e)}
                checked={includeMyEmail}
                size="small"
                sx={{ padding: "2px 0" }}
              />
              <Typography
                component="div"
                variant="body2"
                color="text.secondary"
                ml={1}
              >
                {t("dnet.product_print_send_email_to_me.text")}
              </Typography>
            </Box>
          </div>
          <br />
          <div className="buttons">
            <Box className={styles.buttonContainer}>
              <CustomButton
                label={t("dnet.confirm.text")}
                color="primary"
                onClick={(e: any) => sendMail(e)}
              />
              <CustomButton
                label={t("dnet.preview.text")}
                color="primary"
                onClick={handlePrint}
              />
              <div style={{ display: "none" }}>
              <ComponentToPrint
                ref={componentRef}
                details={fetchPrintDetails()}
              />
              </div>
              <CustomButton
                label={t("dnet.back.text")}
                color="primary"
                onClick={() => {
                  mailHandler();
                }}
              />
            </Box>
          </div>
        </div>
      </div></Box>):
    
     ( <Box className={styles.mainContainer}>
        <Box className={styles.optionsContainer}>
          <Box className={styles.optionHeading} mb={1}>
            {t("default.text")}:
          </Box>
          {fetchCheckbox("nameAndDescription", "dnet.name_description.text")}
          {(!_.isEmpty(productDetailsData?.images) || productDetailsData?.images?.primaryShot?.length > 0 ||
            productDetailsData?.images?.productShots?.length > 0) &&
            fetchCheckbox("image", "primaryImage.text")}
          {productDetailsData?.techSpecs?.length > 0 &&
            fetchCheckbox("specifications", "dnet.specifications.text")}

          <Box className={styles.optionHeading} mb={1} my={1}>
            {t("optional.text")}:
          </Box>
          {fetchCheckbox("price", "dataDownload.dataType.price.text")}
          {(productDetailsData?.iconDescription?.length > 0 ||
            productDetailsData?.inTheBox?.length > 0 ||
            productDetailsData?.keyTechSpecs?.length > 0) &&
            fetchCheckbox("technicalSummary", "dnet.technical_summary.text")}
          {productDetailsData?.proOverview?.length > 0 &&
            fetchCheckbox("overview", "dnet.overview.text")}
          {productDetailsData?.proBenefit?.length > 0 &&
            fetchCheckbox("benefits", "dnet.benefits.text")}
          {productDetailsData?.featureBenefits?.length > 0 &&
            fetchCheckbox("features", "dnet.features.text")}
          {productDetailsData?.accessories?.length > 0 && !_.isEmpty(accessories) &&
            fetchCheckbox("accessories", "dnet.accessories.text")}
          {fetchCheckbox("dealerDetails", "dnet.dealer_details.text")}
          {(productDetailsData?.awards?.length > 0 ||
            productDetailsData?.expertReviews?.length > 0) &&
            fetchCheckbox(
              "productAwardsAndReviews",
              "dnet.product_awards_reviews.text"
            )}
          {!_.isEmpty(productDetailsData?.ecoEnergy) &&
            fetchCheckbox("energyClass", "energyClass.text")}
          {currentProductSku?.product?.definition?.firstShipmentMonth &&
            fetchCheckbox("productIntroMonth", "productIntroductionMonth.text")}
        </Box>
        <Divider orientation="vertical" sx={{ height: "auto" }} />
        <Box className={styles.detailsContainer}>
          {checkBoxOptions["price"] && (
            <Box className={styles.priceContainer}>
              <Box>{t("dnet.sales_price.text")}:</Box>
              <RadioGroup
                row
                value={priceInfo?.priceType}
                onChange={(e: any) => {
                  setPriceInfo({ ...priceInfo, priceType: e.target.value });
                }}
              >
                {displayRetailPrice && (
                  <FormControlLabel
                    value={"retail"}
                    control={
                      <Radio sx={{ paddingRight: "4px" }} size="small" />
                    }
                    label={
                      <Typography
                        component="div"
                        variant="body2"
                        color="text.secondary"
                      >
                        {t("dataDownload.dataType.retailPrice.text")}
                      </Typography>
                    }
                  />
                )}
                {!isInternalAdmin && currentProductSku?.product?.price && (
                  <FormControlLabel
                    value={"list"}
                    control={
                      <Radio sx={{ paddingRight: "4px" }} size="small" />
                    }
                    label={
                      <Typography
                        component="div"
                        variant="body2"
                        color="text.secondary"
                      >
                        {t("dnet.list_price.text")}
                      </Typography>
                    }
                  />
                )}
                <FormControlLabel
                  value={"sales"}
                  control={<Radio sx={{ paddingRight: "4px" }} size="small" />}
                  label={
                    <Typography
                      component="div"
                      variant="body2"
                      color="text.secondary"
                    >
                      {t("dnet.sales_price.text")}
                    </Typography>
                  }
                />
              </RadioGroup>
              <CustomTextField
                label={""}
                changeHandler={(e: any) => {
                  setPriceInfo({ ...priceInfo, priceTypeText: e.target.value });
                }}
                additionalSxProperties={{ width: "100%" }}
                value={priceInfo?.priceTypeText}
                disabled={priceInfo?.disablePriceTypeText}
              />
              <Box mb={1} mt={2}>
                {priceInfo?.priceType=== "sales" ? t("dnet.sales_price_detail.text"): t("listPriceDetailInfo.text")+" "+t("priceDetailExplanation.text")}:
              </Box>
              <CustomTextField
                label={""}
                changeHandler={(e: any) => {
                  setPriceInfo({
                    ...priceInfo,
                    salesPriceDetail: e.target.value,
                  });
                }}
                additionalSxProperties={{ width: "100%" }}
                value={priceInfo?.salesPriceDetail}
              />
            </Box>
          )}
          {checkBoxOptions["dealerDetails"] && (
            <Box className={styles.dealerDetailsContainer}>
              <Box mb={1}>{t("dnet.dealer_address.text")}:</Box>
              <TextField
                sx={{ width: "100%" }}
                multiline
                rows={2}
                InputProps={{
                  style: {
                    fontSize: 15,
                    padding: "8px 12px",
                  },
                }}
                onChange={(e: any) => {
                  setDealerDetails({
                    ...dealerDetails,
                    dealerAddress: e.target.value,
                  });
                }}
                value={dealerDetails?.dealerAddress}
              />
              <Box mb={1} mt={2}>
                {t("dnet.dealer_logo.text")}:
              </Box>

              <Button
                variant="contained"
                component="label"
                size="medium"
                sx={{
                  borderRadius: "4px",
                  margin: "0",
                  backgroundColor: "#000000",
                  fontSize: "11px",
                  padding: "2px 10px",
                }}
                onChange={(e: any) => {
                  setDealerDetails({
                    ...dealerDetails,
                    file: e.target.files[0],
                    displayFile: URL.createObjectURL(e.target.files[0]),
                  });
                }}
              >
                Choose File
                <input type="file" hidden />
              </Button>
              <Typography
                component="span"
                variant="body2"
                color="text.secondary"
                ml={1}
              >
                {dealerDetails?.file?.name
                  ? dealerDetails?.file?.name
                  : "No file chosen"}
              </Typography>
              <Typography
                component="div"
                variant="body2"
                color="red"
                mt={1}
                mb={1}
              >
                {t("dnet.browse_explantion.text")}
              </Typography>
            </Box>
          )}
          <Box className={styles.buttonContainer}>
            <CustomButton
              label={t("dnet.printer_friendly.text")}
              color="primary"
              onClick={handlePrint}
              margin="0"
            />
            <div style={{ display: "none" }}>
              <ComponentToPrint
                ref={componentRef}
                details={fetchPrintDetails()}
              />
            </div>
            <CustomButton
              label={t("dnet.pdf.text")}
              color="primary"
              onClick={handleDownload}
            />
            {EShotinPrintPage && (
              <CustomButton
                label={t("dnet.product_print_eshot.text")}
                color="primary"
                onClick={() => mailHandler()}
              />
            )}
          </Box>

          {/* //TODO - userCookiePreservable alert and email options to be added */}
        </Box>
      </Box>)
    }
    </>
  );
};
