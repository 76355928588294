import { Typography, Box, IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import InvoiceDetailTable from './invoiceDetailTable';
import styles from './paidItemDetails.module.scss';
import { useDispatch } from "react-redux";
import { getPaidItemDetailsData } from "../../../state/actions";
import PaidItemDetailTable from './paidItemDetailTable';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useLocation, useNavigate} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';


export const PaidItemDetails = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    let invoiceId = location?.state?.invoiceId
    const backButtonText = t("gzl.back.text");
    const userDetails = useSelector(
      (state: any) => state?.commonReducer?.userDetails
    );
    const saveAccountDetails = useSelector(
      (state: any) => state?.commonReducer?.saveAccountDetails
    )
    const getAPIData = () => {
        dispatch(getPaidItemDetailsData({
            invoiceId,
            partnerKey: saveAccountDetails?.payerToAcc?.number,
        }));
    };

    useEffect(() => {
        if(!invoiceId){
          // If no invoice navigating it back
            navigate(-1)
        }
        getAPIData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <>
        <Box className={styles.headerBox}>
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIosIcon titleAccess={backButtonText} />
          </IconButton>
          <Typography
            sx={{ paddingBottom: "0px" }}
            variant="subtitle1"
            className={styles.header}
            pb={3}
            color="primary"
          >
            {t("gzl.payments.text")}
          </Typography>
        </Box>
        <Box>
          <Box className={styles.tableContainer}>
            <div className={styles.tableHeader}>
              <Typography variant="body2">
                {t("gzl.payments_headline.text")}
              </Typography>
            </div>
            <InvoiceDetailTable />
          </Box>
          <Box className={styles.tableContainer}>
            <div className={styles.tableHeader}>
              <Typography variant="body2">
                {t("gzl.payments_headline2.text")}
              </Typography>
            </div>
            <PaidItemDetailTable />
          </Box>
        </Box>
      </>
    );
}