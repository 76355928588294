import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import styles from "./benefitsTab.module.scss";

export const BenefitsTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const productDetailsData = useSelector(
    (state: any) => state?.productReducer?.productDetailsData
  );

  return (
    <>
      {productDetailsData?.proBenefit?.map((overview: any, index: any) => (
        <Grid key={index} container className={styles.benefitsCard} mb={3}>
          <Grid item lg={12} md={12} sm={12} xs={12} p={2}>
            <Typography variant="body1" color="primary" pb={1}>
              {overview?.headline}
            </Typography>
            <Typography
              component="div"
              variant="caption"
              color="text.secondary"
              dangerouslySetInnerHTML={{__html: overview?.bodyCopy}}
            >
            </Typography>
          </Grid>
        </Grid>
      ))}
    </>
  );
};
