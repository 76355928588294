import { commonConstant } from "../constants/common-constants";

export const initialState = {
  quotationData: {},
  placeOrderResponse: {},
};

const myQuotationsReducer = (state = initialState, action: any) => {
  switch (action?.type) {
    case commonConstant.GET_QUOTATIONS_DATA_SUCCESS:
      return {
        ...state,
        quotationData: action?.payload,
      };
    case commonConstant.SFDC_PLACE_ORDER_MY_QUOTATIONS_SUCCESS:
      return {
        ...state,
        placeOrderResponse: action?.payload,
      };
    default:
      return state;
  }
};

export default myQuotationsReducer;
