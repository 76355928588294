import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./prodDetailsView.module.scss";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import PublicIcon from "@mui/icons-material/Public";
import CustomButton from "../../../atoms/customButton";
import { ProdDetailsModal } from "../prodDetailsModal";
import { ProdTabs } from "../prodTabs";
import { useSelector } from "react-redux";
import {
  CONTEXT_ORDER,
  MODAL_TYPES,
  PERMA_LINK,
  PUBLICATION,
} from "../../../../constants/commonConstants";
import { getContextDetails } from "../../../../state/actions";
var _ = require("lodash");
const noProduct = require("../../../../assets/images/no_prod_tn.gif");

export const ProdDetailsView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const productDetailsData = useSelector(
    (state: any) => state?.productReducer?.productDetailsData
  );

  const currentProductSku = useSelector(
    (state: any) => state?.productReducer?.currentProductSku
  );

  const [images, setImages] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(productDetailsData)) {
            // Logic to fetch image URLs
      let images: any = [];
      let publication : string = productDetailsData?.publication;
      if (publication==="GLOBAL") {
        if (productDetailsData?.images?.primaryShot?.length > 0) {
          images.push(...productDetailsData?.images?.primaryShot);
        }
        if (productDetailsData?.images?.productShots?.length > 0) {
          images.push(...productDetailsData?.images?.productShots);
        }
        setImages(images);
      } else if (publication==="SCORE") {
        if (productDetailsData?.images?.images?.length > 0) {
          images.push(...productDetailsData?.images?.images);
        }
        if (productDetailsData?.images?.largerImage?.length > 0) {
          images.push(...productDetailsData?.images?.largerImage);
        }
        if (productDetailsData?.images?.zoomImage?.length > 0) {
          images.push(...productDetailsData?.images?.zoomImage);
        }
        setImages(images);
      } else if (publication==="PSE"||publication==="NGP") {
        if (productDetailsData?.images?.proWebImage?.length > 0) {
          images.push(...productDetailsData?.images?.proWebImage);
        }
        if (productDetailsData?.images?.proLargeImage?.length > 0) {
          images.push(...productDetailsData?.images?.proLargeImage);
        }
        if (productDetailsData?.images?.proZoomImage?.length > 0) {
          images.push(...productDetailsData?.images?.proZoomImage);
        }
        setImages(images);
      }
    }
  }, [productDetailsData]);

  useEffect(() => {
    dispatch(
      getContextDetails({
        key: CONTEXT_ORDER?.PRODUCT_MODULE_PAGE,
      })
    );
  }, []);

  const contextDetails = useSelector(
    (state: any) => state?.commonReducer?.contextDetails
  );

  const sonyMenuItems = useSelector(
    (state: any) => state?.commonReducer?.sonyMenuItems
  );

  const displayMenuItems = (itemName: any) => {
    const menuItem = sonyMenuItems?.filter((item: any) => {
      return item?.sectionName === "DNET Data Download"
    })
    const subMenu = menuItem[0]?.subMenu?.filter((subItem: any) => {
      return subItem?.sectionName === itemName
    })
    return subMenu?.length === 1;
  }

  const digitalAssetDownloadAvailable = displayMenuItems("DNET Digital Asset Download"); //context.digitalAssetDownloadAvailable
  const productDataDownloadAvailable = displayMenuItems("DNET Product Data Download"); //context.productDataDownloadAvailable
  const pdfEnabled = contextDetails?.pdfEnabled; //TODO not sure where the value is set
  const showEmail = contextDetails?.isEShotinPrintPage; //context.masterSalesArea.EShotinPrintPage


  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setOpenModal(false);
  };
  const [modalType, setModalType] = useState<any>("");
  const [modalData, setModalData] = useState<any>({});

  const openProdModal = (type: any) => {
    let data: any = {};
    switch (type) {
      case MODAL_TYPES.IMAGES:
        data.title = productDetailsData?.modelName;
        data.isVisibleBaseLink = false;
        data.largeImages = images;
        data.productDisplayName=  productDetailsData?.materialName;
        break;
      case MODAL_TYPES.VIDEOS:
        data.title = productDetailsData?.modelName;
        data.videos = productDetailsData?.videos;
        break;
      case MODAL_TYPES.DIGITAL_ASSET:
        data.title = t("dnet.select_format.text");
        data.queryType = MODAL_TYPES.DIGITAL_ASSET;
        data.isVisibleBaseLink = false;
        break;
      case MODAL_TYPES.PRODUCT_DATA:
        data.title = t("dnet.select_format.text");
        data.queryType = MODAL_TYPES.PRODUCT_DATA;
        data.isVisibleBaseLink = false;
        break;
      case MODAL_TYPES.PRINT:
        data.title = t("dnet.print_options.text");
        data.images = images;
        break;
    }
    setModalType(type);
    setOpenModal(true);
    setModalData(data);
  };

  return (
    <>
      <Grid container>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box className={styles.imageBox}>
            {images?.length > 0 ? (
              <img
                src={images[0]}
                className={styles.mainImage}
                style={{ cursor: "pointer" }}
                onClick={() => openProdModal(MODAL_TYPES.IMAGES)}
              />
            ) : (
              <img src={noProduct} className={styles.mainImage} />
            )}
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography
            variant="subtitle1"
            className={styles.header}
            pb={1}
            color="primary"
          >
            {currentProductSku?.product?.index?.displayName}
            {currentProductSku?.isNew && (
              <span className={styles.newBadgeStyle}>{t("dnet.new.text")}</span>
            )}
          </Typography>
          <Typography variant="subtitle2" className={styles.header} pb={1}>
            {productDetailsData?.marketingProductName}
          </Typography>
          <Typography component="div" variant="caption" color="text.secondary" dangerouslySetInnerHTML={{
            __html:productDetailsData?.elevatorPitch 
          }}/>
          <Typography component="div" variant="caption" color="text.secondary" dangerouslySetInnerHTML={{
            __html:productDetailsData?.factualShortDescription
          }}/>
          <Typography component="div" variant="caption" color="text.secondary">
            <ul>
              {productDetailsData?.topFeatures?.map(
                (feature: any, index: any) => (
                  <li key={index}>{feature}</li>
                )
              )}
            </ul>
          </Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <ul className={styles.linksListStyle}>
            {images?.length > 0 && (
              <li onClick={() => openProdModal(MODAL_TYPES.IMAGES)}>
                <Typography variant="body2" className={styles.links}>
                  <ImageOutlinedIcon sx={{ marginRight: "6px" }} />
                  {t("dnet.images_subtype.text")}
                </Typography>
              </li>
            )}
            {productDetailsData?.videos?.length > 0 && (
              <li onClick={() => openProdModal(MODAL_TYPES.VIDEOS)}>
                <Typography variant="body2" className={styles.links}>
                  <VideocamOutlinedIcon sx={{ marginRight: "6px" }} />
                  {t("dnet.videos_subtype.text")}
                </Typography>
              </li>
            )}
            {digitalAssetDownloadAvailable && (
              <li onClick={() => openProdModal(MODAL_TYPES.DIGITAL_ASSET)}>
                <Typography variant="body2" className={styles.links}>
                  <DownloadForOfflineOutlinedIcon sx={{ marginRight: "6px" }} />
                  {t("dataDownload.downloadDigitalAsset.title")}
                </Typography>
              </li>
            )}
            {productDataDownloadAvailable && (
              <li onClick={() => openProdModal(MODAL_TYPES.PRODUCT_DATA)}>
                <Typography variant="body2" className={styles.links}>
                  <FileDownloadOutlinedIcon sx={{ marginRight: "6px" }} />
                  {t("dataDownload.downloadProductData.title")}
                </Typography>
              </li>
            )}
            {productDetailsData?.permalinkUrl && (
              <li onClick={() => window.open(productDetailsData?.permalinkUrl, "_blank")}>
                <Typography variant="body2" className={styles.links}>
                  <PublicIcon sx={{ marginRight: "6px" }} />
                  {t("dataDownload.dataType.titlePermalinkUrl.text")}
                </Typography>
              </li>
            )}
          </ul>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={styles.buttonStyles}
        >
          <CustomButton
            label={t("dnet.print.text")}
            color="primary"
            onClick={() => openProdModal(MODAL_TYPES.PRINT)}
          />
          {pdfEnabled && (
            <CustomButton
              label={t("dnet.pdf.text")}
              color="primary"
              onClick={() => openProdModal(MODAL_TYPES.PRINT)}
            />
          )}
          {showEmail && (
            <CustomButton
              label={t("dnet.email.text")}
              color="primary"
               onClick={()=>openProdModal(MODAL_TYPES.PRINT)}
            />
          )}
        </Grid>
        <Divider sx={{ width: "100%", marginTop: "16px" }} />
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <ProdTabs />
        </Grid>
      </Grid>
      <ProdDetailsModal
        open={openModal}
        closeModal={closeModal}
        type={modalType}
        data={modalData}
      />
    </>
  );
};
