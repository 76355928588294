import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  getComments,
  getEnquiryTableDetailsData
} from "../../../state/actions";
import { AddComments } from "./add-comments";
import { ReplyEnquiryTable } from "./reply-enquiry-table";
import styles from "./reply-enquiry.module.scss";

export const ReplyEnquiry = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  let invoiceId = location?.state?.invoiceId;
  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.userDetails
  );
  const saveAccountDetails = useSelector(
    (state: any) => state?.commonReducer?.saveAccountDetails
  )
  useEffect(() => {
    if(!invoiceId){
      // Navigating user back if no invoiceID present
      navigate(-1)
    }
    // Hitting API data with respect to invoice ID recieved from the selected record
    dispatch(
      getEnquiryTableDetailsData({
        invoiceId,
        partnerKey: saveAccountDetails?.payerToAcc?.number,
      })
    );
    dispatch(
      getComments({
        language: userDetails?.language,
        accountId: saveAccountDetails?.payerToAcc?.number,
        username: userDetails?.userName,
        invoiceId: invoiceId,
      })
    );
  }, []);
  return (
    <>
      <Box className={styles.headerBox}>
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon titleAccess="Back" />
        </IconButton>
        <Typography variant="subtitle1" className={styles.header} color="primary">
          {t("gzl.contact_history_title.text")}
        </Typography>
      </Box>
      <Box>
        <Box className={styles.tableContainer}>
          <div className={styles.tableHeader}>
            <Typography variant="body2">
              {t("gzl.contact_history_headline.text")}
            </Typography>
          </div>
          <ReplyEnquiryTable />
        </Box>
        <AddComments invoiceId={invoiceId} />
      </Box>
    </>
  );
};
