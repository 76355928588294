import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import CustomButton from "../../../../atoms/customButton";
import styles from "./energyTab.module.scss";
import { getProductImages } from "../../../../../state/actions";
var _ = require("lodash");

export const EnergyTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const productDetailsData = useSelector(
    (state: any) => state?.productReducer?.productDetailsData
  );

  const getItem = (label: any, value: any) => {
    return (
      <Box>
        <Typography
          component="span"
          variant="caption"
          color="black"
          sx={{ fontWeight: "600" }}
        >
          {t(label)} : &nbsp;
        </Typography>
        <Typography component="span" variant="caption" color="text.secondary">
          {value}
        </Typography>
      </Box>
    );
  };

  const productImages = useSelector(
    (state: any) => state?.productReducer?.productImages
  );

  const [isDownloadTrigged, setIsDownloadTrigged] = useState(false);
  
  useEffect(() => {
    if (!_.isEmpty(productImages) && isDownloadTrigged) {
      const url = productImages?.data[0]?.base64Image;
      const link = document.createElement("a");
      link.href = url;
      link.download = `${productDetailsData?.materialName}.jpeg`;
      link.click();
      setIsDownloadTrigged(false);
    }
  }, [productImages]);

  const downloadHandler = () => {
    const productImageURL: any = [];
    let url = productDetailsData?.ecoEnergy?.ecoMessageShots[0]
      .replace("fmt=png-alpha", "fmt=jpeg")
      .replace("fmt=png", "fmt=jpeg");
    const alteredURL = url?.includes("sp.sony-europe.com")
      ? url
      : `${url}&wid=1000`;
    const parts = alteredURL.split("/");
    const relevantPart = parts.slice(3).join("/");
    const flag = url?.includes("sp.sony-europe.com")
      ? 2
      : url?.includes("sony.scene7.com")
      ? 3
      : 1;
    productImageURL.push({ imageURL: relevantPart, flag: flag });
    setIsDownloadTrigged(true);
    dispatch(getProductImages({ payload: productImageURL, separateImage: true }));
  };
  return (
    <Box className={styles.mainContainer}>
      <Grid container>
        <Grid item lg={4}>
          {productDetailsData?.ecoEnergy?.ecoMessageShots?.map((imageUrl: any, index: any) => (
            <img
              key={index}
              className={styles.imageStyle}
              src={imageUrl}
              alt="Energy Class"
              title="Energy Class"
            />
          ))}
        </Grid>
        <Grid item lg={8}>
          <Box className={styles.listStyle}>
            {productDetailsData?.ecoEnergy?.ecoEnergyLevel && 
              getItem(
              "energyLabelRating.text", productDetailsData?.ecoEnergy?.ecoEnergyLevel
              )
            }
            {productDetailsData?.ecoEnergy?.ecoScreenSize && 
              getItem(
                "screenSize.text", productDetailsData?.ecoEnergy?.ecoScreenSize
              )
            }
            {productDetailsData?.ecoEnergy?.ecoPowerConsumptionOnMode && 
              getItem(
                "powerConsumptionOnMode.text",
                productDetailsData?.ecoEnergy?.ecoPowerConsumptionOnMode
              )
            }
            {productDetailsData?.ecoEnergy?.ecoAnnualEnergyConsumption && 
              getItem(
                "annualEnergyConsumption.text",
                productDetailsData?.ecoEnergy?.ecoAnnualEnergyConsumption
              )
            }
            {productDetailsData?.ecoEnergy?.ecoPowerConsumptionStandyBy && 
              getItem(
                "powerConsumptionStandyBy.text",
                productDetailsData?.ecoEnergy?.ecoPowerConsumptionStandyBy
              )
            }
            {productDetailsData?.ecoEnergy?.ecoDisplayResolution && 
              getItem(
                "resolution.text", productDetailsData?.ecoEnergy?.ecoDisplayResolution
              )
            }
          </Box>
          <Box className={styles.buttonStyle}>
            <CustomButton
              label="Download"
              color="primary"
              onClick={downloadHandler}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
