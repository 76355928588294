// import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  ACCOUNT_NUM_PATTERN,
  NEW_EMAIL_REGEX,
  NAME_REGEX,
  PASSWORD_PATTERN,
  TELEPHONE_NUM_PATTERN,
} from "../../constants/commonConstants";
import { ROUTES_LABEL } from "../../constants/routesLabels";
import {
  fetchReferenceData,
  registerUser,
  registerUserSuccess,
  validateAccountNumber,
  validateAccountNumberSuccess,
  getTranslationAction,
  // getCaptchaValidation,
  // getCaptchaValidationSuccess,
  setLoading,
} from "../../state/actions";
import CustomButton from "../atoms/customButton";
import CustomDropDown from "../atoms/customDropDown";
import CustomModal from "../atoms/customModal";
// import CustomMultiSelect from "../atoms/customMultiSelect";
import CustomTextField from "../atoms/customTextField";
import { Header } from "../header";
import styles from "./registration.module.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { Footer } from "../atoms/footer";
import { getComparator, stableSort } from "../helper/util";
var _ = require("lodash");

export const Registration = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [sortedCountryRefData, setSortedCountryRefData]:any = useState({})

  const currentTranslation = useSelector(
    (state: any) => state?.commonReducer?.translation
  );

  const currentLocale = useSelector(
    (state: any) => state?.commonReducer?.locale
  );

  const referenceData = useSelector(
    (state: any) => state?.signUpReducer?.referenceData
  );

  useEffect(() => {
    if (_.isEmpty(referenceData)) {
      dispatch(setLoading(true)); 
      dispatch(fetchReferenceData({}));
    }
  }, [referenceData, dispatch]);

  useEffect(() => {
    if (!_.isEmpty(currentTranslation)) {
      i18n.addResourceBundle(
        currentLocale,
        "translation",
        currentTranslation,
        true
      );
      i18n.changeLanguage(currentLocale);
    } else {
      dispatch(setLoading(true)); 
      dispatch(getTranslationAction({ locale: "en_GB" }));
    }
    if (!_.isEmpty(referenceData) && !_.isEmpty(currentTranslation)) {
      if (referenceData?.salutations?.length > 0) {
        referenceData?.salutations.forEach((c: any) => {
          c.label = t(c.value);
        });
        populateForm(referenceData?.salutations[0]?.key, "salutation");
      }
      if (referenceData?.countries?.length > 0) {
        referenceData?.countries.forEach((c: any) => {
          let label = `dnet.country_${c.key}.text`
          c.label = t(label);
        });
        referenceData.countries = stableSort(referenceData?.countries, getComparator("asc", "label"))
      }
      if (referenceData?.departments?.length > 0) {
        referenceData?.departments.forEach((c: any) => {
          c.label = t(c.value);
        });
      }
      if (referenceData?.jobFunctions?.length > 0) {
        referenceData?.jobFunctions.forEach((c: any) => {
          c.label = t(c.value);
        });
      }
      dispatch(setLoading(false)); 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceData, currentTranslation]);

  const defaultValue = {
    salutation: "",
    firstName: "",
    lastName: "",
    email: "",
    officePhone: "",
    mobilePhone: "",
    password1: "",
    password2: "",
    userCountry: "",
    language: "",
    job: "",
    accountNo: "",
    isPSE: "no",
    companyName: "",
    companyCountry: "",
    address1: "",
    address2: "",
    address3: "",
    city: "",
    postalCode: "",
    webAddress: "",
    department: "",
    vatNumber: "",
    agreed: false,
    subscribedToNewsletters: false,
    // domainName: window.location.hostname,
  };

  let [formData, setFormData]: any = useState(defaultValue);
  let [errorMsg, setErrorMsg]: any = useState({});

  let [pseTrue, setPseTrue] = useState(true);
  let [pseFalse, setPseFalse] = useState(true);

  const [languageOptions, setLanguageOptions] = useState([]);

  //sets form data based on the fieldName
  const populateForm = (data: any, fieldName: any) => {
    setFormData({ ...formData, [fieldName]: data });
  };

  //sets form data based on the fieldName
  const populateErrorMsg = (data: any, fieldName: any) => {
    setErrorMsg({ ...errorMsg, [fieldName]: data });
  };

  const [validAccNum, setValidAccNum] = useState("");

  const validateAccNumber = (accNum: any) => {
    if (accNum) {
      if (!ACCOUNT_NUM_PATTERN.test(accNum)) {
        populateErrorMsg(t("dnet.account_not_valid.txt"), "accountNo");
        setValidAccNum("inValid");
      } else {
        populateErrorMsg("", "accountNo");
        dispatch(validateAccountNumber({ accountNumber: accNum }));
      }
    } else {
      setAccountDetails("", "", "", "", "", "", "no");
      dispatch(validateAccountNumberSuccess({}));
      setValidAccNum("");
      populateErrorMsg("", "accountNo");
      setPseTrue(true);
      setPseFalse(true);
    }
  };

  const setAccountDetails = (
    compName: any,
    compCountry: any,
    address1: any,
    address2: any,
    city: any,
    postalCode: any,
    pse: any
  ) => {
    setFormData({
      ...formData,
      companyName: compName,
      companyCountry: compCountry,
      address1,
      address2,
      city: city,
      postalCode,
      isPSE: pse,
    });
  };

  const accountDetails = useSelector(
    (state: any) => state?.signUpReducer?.accountDetails
  );

  useEffect(() => {
    if (!_.isEmpty(accountDetails)) {
      if (accountDetails?.result === "ok") {
        let pseVal = "no";
        if (accountDetails?.accountType === "consumerAccount") {
          pseVal = "no";
          setPseTrue(false);
          setPseFalse(true);
        } else if (accountDetails?.accountType === "pseAccount") {
          pseVal = "yes";
          setPseTrue(true);
          setPseFalse(false);
        } else {
          pseVal = "no";
          setPseTrue(true);
          // setPseFalse(false);
        }
        setValidAccNum("valid");
        setAccountDetails(
          accountDetails?.companyName,
          accountDetails?.companyCountry,
          accountDetails?.address1,
          accountDetails?.address2,
          accountDetails?.city,
          accountDetails?.postalCode,
          pseVal
        );
      } else {
        setAccountDetails("", "", "", "", "", "", "no");
        populateErrorMsg(t("dnet.account_not_valid.txt"), "accountNo");
        setValidAccNum("inValid");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountDetails]);

  let CAPTCHA_ENABLED = process.env?.REACT_APP_CAPTCHA_ENABLED
    ? process.env?.REACT_APP_CAPTCHA_ENABLED
    : false;

  const registerHandler = () => {
    if(CAPTCHA_ENABLED){
      captchaRef.current.execute();
    }
    else{
      registerFormHandler()
    }
  };
  const registerFormHandler = (captchaRes: string = "") => {
    let formValidity = validateRegisterFormData();
    // dispatch(getCaptchaValidationSuccess({success: false}))
    setTimeout(() => {
      if (formValidity === "validForm") {
        if (validAccNum === "valid" || formData["accountNo"] === "") {
          let payload = JSON.parse(JSON.stringify(formData))
          payload.googleRecaptcha = captchaRes
          let requestPayload = getRequestPayload(payload);
          dispatch(registerUser(requestPayload));
        } else {
          dispatch(setLoading(false))
          populateErrorMsg(t("dnet.account_not_valid.txt"), "accountNo");
        }
      } else 
        dispatch(setLoading(false))
    }, 1000);
  }
  // const validateCaptchaRes = useSelector(
  //   (state: any) => state?.commonReducer?.validateCaptchaRes
  // );
  // useEffect(()=>{
  //   if(validateCaptchaRes?.success){
  //     registerFormHandler()
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[validateCaptchaRes])

  const getRequestPayload = (data: any) => {
    let req = Object.assign({}, data);
    // req.job = data?.job[0];
    // req.department = data?.department[0];
    req.isPSE = data?.isPSE === "no" ? false : true;
    req.locale = i18n?.language;
    return req;
  };

  const registerResponse = useSelector(
    (state: any) => state?.signUpReducer?.registerResponse
  );

  useEffect(() => {
    if (!_.isEmpty(registerResponse)) {
      if (registerResponse?.successful) {
        setHeading(t("dnet.registration_confirmation_title.text"));
        setContent(
          "<div style='text-align: justify;font-size: 14px;'>" +
            t("dnet.registration_confirmation_description1.text") +
            t("dnet.registration_confirmation_description2.text") +
            t("dnet.registration_confirmation_description3.text") +
            "<br />" +
            t("dnet.registration_confirmation_description4.text") +
            "</div>"
        );
      } else {
        setHeading("");
        setContent(t(registerResponse?.failureCause?.message));
      }
      dispatch(setLoading(false)); 
      setOpenModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerResponse]);

  const validateRegisterFormData = () => {
    let reqLocale =  referenceData?.fields_mandatory_definition?.value?.split(",")
    let errorObj: any = {};
    let requiredErrorMsg = t("dnet.field_required.txt");
    if (formData["firstName"] === "") {
      errorObj.firstName = requiredErrorMsg;
    } else if (!NAME_REGEX.test(formData["firstName"])) {
      errorObj.firstName = t("dnet.first_name_not_valid.txt");
    }
    if (formData["lastName"] === "") {
      errorObj.lastName = requiredErrorMsg;
    } else if (!NAME_REGEX.test(formData["lastName"])) {
      errorObj.lastName = t("dnet.first_name_not_valid.txt");
    }
    if (formData["email"] === "") {
      errorObj.email = requiredErrorMsg;
    } else if (!NEW_EMAIL_REGEX.test(formData["email"])) {
      errorObj.email = t("dnet.email_not_valid.txt");
    }
    if (formData["password1"] === "") {
      errorObj.password1 = requiredErrorMsg;
    } else if (!PASSWORD_PATTERN.test(formData["password1"])) {
      errorObj.password1 = t("dnet.invalid_excel_format.text");
    }
    if (formData["password2"] === "") {
      errorObj.password2 = requiredErrorMsg;
    }
    if (formData["password1"] !== "" && formData["password2"] !== "") {
      if (formData["password1"] !== formData["password2"]) {
        errorObj.password1 = t("dnet.passwords_are_different.text");
      }
    }
    if (formData["officePhone"] === "") {
      errorObj.officePhone = requiredErrorMsg;
    } else if (!TELEPHONE_NUM_PATTERN.test(formData["officePhone"])) {
      errorObj.officePhone = t("dnet.invalid_excel_format.text");
    }
    if (
      formData["mobilePhone"] !== "" &&
      !TELEPHONE_NUM_PATTERN.test(formData["mobilePhone"])
    ) {
      errorObj.mobilePhone = t("dnet.invalid_excel_format.text");
    }
    if (formData["userCountry"] === "") {
      errorObj.userCountry = requiredErrorMsg;
    }
    if (formData["language"] === "") {
      errorObj.language = requiredErrorMsg;
    }
    // if (_.isEmpty(formData["job"])) {
    //   errorObj.job = requiredErrorMsg;
    // }
    if (formData["job"] === "") {
      errorObj.job = requiredErrorMsg;
    }
    if (formData["accountNo"] !== "" && !ACCOUNT_NUM_PATTERN.test(formData["accountNo"])) {
      errorObj.accountNo = t("dnet.account_not_valid.txt");
      setValidAccNum("inValid");
    }
    if (formData["agreed"] === false) {
      errorObj.agreed = requiredErrorMsg;
    }
    if (formData["companyName"] === "") {
      errorObj.companyName = requiredErrorMsg;
    }
    if (formData["address1"] === "") {
      errorObj.address1 = requiredErrorMsg;
    }
    if (formData["city"] === "") {
      errorObj.city = requiredErrorMsg;
    }
    if (formData["postalCode"] === "") {
      errorObj.postalCode = requiredErrorMsg;
    }
    if (formData["companyCountry"] === "") {
      errorObj.companyCountry = requiredErrorMsg;
    }
    if (formData["vatNumber"] === "" && reqLocale.indexOf(currentLocale) !== -1) {
      if(referenceData?.fields_mandatory_definition?.key === "vatNumber"){
        errorObj.vatNumber = requiredErrorMsg;
      }
    }
    setErrorMsg(errorObj);
    if (!errorObj?.accountNo) setValidAccNum("valid");
    if (_.isEmpty(errorObj)) return "validForm";
    else{
      captchaRef.current.reset();
      setLoading(false);
      return "invalidForm"
    };
  };

  const handleCountryChange = (d: any) => {
    let lang: any = [];
    let selectedCountryArr = referenceData?.countries.filter((c: any) => {
      return c?.key === d
    });
    if(selectedCountryArr[0]?.value.includes(",")) {
      selectedCountryArr[0]?.value?.split(",").forEach((elem: any) => {
        let translatedLanguage = t(`dnet.language_${elem}.text`)
        lang.push({ key: elem, label: translatedLanguage })
      })
    } else {
      let translatedLanguage = t(`dnet.language_${selectedCountryArr[0]?.value}.text`)
      lang = [{ key: selectedCountryArr[0]?.value, label: translatedLanguage }]
    }
    setFormData({ ...formData, language: lang[0]?.key, userCountry: d });
    setLanguageOptions(lang);
    // let langObj: any = referenceData?.langMapping[d];
    // if (langObj) {
    //   Object.keys(langObj).forEach(function (key) {
    //     lang.push({ value: key, label: langObj[key] });
    //   });
    //   setFormData({ ...formData, language: lang[0]?.value, userCountry: d });
    //   setLanguageOptions(lang);
    // }
  };

  const [heading, setHeading] = useState<any>("");
  const [content, setContent] = useState<any>("");
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setOpenModal(false);
    setFormData(defaultValue);
    setErrorMsg({});
    dispatch(validateAccountNumberSuccess({}));
    dispatch(registerUserSuccess({}));
    navigate(`/${ROUTES_LABEL.LOGIN}`);
  };

  // useEffect(() => {
  //   if (!_.isEmpty(currentTranslation)) {
  //     i18n.addResourceBundle(
  //       currentLocale,
  //       "translation",
  //       currentTranslation,
  //       true
  //     );
  //     i18n.changeLanguage(currentLocale);
  //   } else dispatch(getTranslationAction({ locale: "en_GB" }));
  // }, [currentTranslation]);

  const captchaRef = useRef<any>();
  const handleCaptchaChange = (value: any) => {
    if(value){
      dispatch(setLoading(true)); 
      // dispatch(getCaptchaValidation({googleRecaptcha: value, from: "registration"}))
      registerFormHandler(value)
    }
  }
  // useEffect(()=>{
    // dispatch(getCaptchaValidationSuccess({success: false}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[])

  let sapAccNoText: any = t("dnet.registration_sap_account_no.text")?.toString();
  sapAccNoText = sapAccNoText.split("<br />")
  sapAccNoText = sapAccNoText?.join("")

  return (
    <>
    {!_.isEmpty(currentTranslation) && (
      <>
      <Header fromLogin={true} />
      <Box className={styles.boxStyle}>
        <Typography
          variant="subtitle1"
          className={styles.header}
          pb={3}
          color="primary"
        >
          {t("dnet.registration_title.text")}
        </Typography>
        <Box className={styles.boxContainer}>
          <Typography
            variant="subtitle2"
            className={styles.header}
            pb={3}
            color="primary"
          >
            {t("dnet.registration_sub_title.text")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <div className={styles.salDropdown}>
                <CustomDropDown
                  id="salutation"
                  value={formData["salutation"]}
                  handleChange={(e: any) => {
                    populateForm(e.target.value, "salutation");
                  }}
                  menuOptions={referenceData?.salutations}
                  label={t("dnet.registration_salutation.text")}
                  module="department"
                />
              </div>
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <CustomTextField
                id="firstName"
                label={t("dnet.registration_first_name.text")}
                value={formData["firstName"]}
                changeHandler={(e: any) => {
                  populateForm(e.target.value, "firstName");
                }}
                type="text"
                helperText={errorMsg["firstName"]}
                error={errorMsg["firstName"]?.length > 0}
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <CustomTextField
                id="lastName"
                label={t("dnet.registration_last_name.text")}
                value={formData["lastName"]}
                changeHandler={(e: any) => {
                  populateForm(e.target.value, "lastName");
                }}
                type="text"
                helperText={errorMsg["lastName"]}
                error={errorMsg["lastName"]?.length > 0}
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <CustomTextField
                id="email"
                label={t("dnet.registration.email.text")}
                value={formData["email"]}
                changeHandler={(e: any) => {
                  populateForm(e.target.value, "email");
                }}
                type="text"
                helperText={errorMsg["email"]}
                error={errorMsg["email"]?.length > 0}
              />
              <Tooltip
                title={t("dnet.registration_email_info.text")}
                arrow
                placement="right"
                enterTouchDelay={0}
              >
                <IconButton className={styles.infoIcon}>
                  <InfoOutlinedIcon color="action" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={0}>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <CustomTextField
                id="officePhone"
                label={t("registration.officePhone.text")}
                value={formData["officePhone"]}
                changeHandler={(e: any) => {
                  populateForm(e.target.value, "officePhone");
                }}
                type="text"
                helperText={errorMsg["officePhone"]}
                error={errorMsg["officePhone"]?.length > 0}
              />
              <Tooltip
                title={t("dnet.phone_info.text")}
                arrow
                placement="right"
                enterTouchDelay={0}
              >
                <IconButton className={styles.infoIcon}>
                  <InfoOutlinedIcon color="action" />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <CustomTextField
                id="mobilePhone"
                label={t("registration.mobilePhone.text")}
                value={formData["mobilePhone"]}
                changeHandler={(e: any) => {
                  populateForm(e.target.value, "mobilePhone");
                }}
                type="text"
                helperText={errorMsg["mobilePhone"]}
                error={errorMsg["mobilePhone"]?.length > 0}
              />
              <Tooltip
                title={t("dnet.phone_info.text")}
                arrow
                placement="right"
                enterTouchDelay={0}
              >
                <IconButton className={styles.infoIcon}>
                  <InfoOutlinedIcon color="action" />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <CustomTextField
                id="password1"
                label={t("dnet.new_password.text")}
                value={formData["password1"]}
                changeHandler={(e: any) => {
                  populateForm(e.target.value, "password1");
                }}
                type="password"
                helperText={errorMsg["password1"]}
                error={errorMsg["password1"]?.length > 0}
              />
              <Tooltip
                title={t("dnet.password_info.text")}
                arrow
                placement="right"
                enterTouchDelay={0}
              >
                <IconButton className={styles.infoIcon}>
                  <InfoOutlinedIcon color="action" />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <CustomTextField
                id="password2"
                label={t("dnet.new_password_again.text")}
                value={formData["password2"]}
                changeHandler={(e: any) => {
                  populateForm(e.target.value, "password2");
                }}
                type="password"
                helperText={errorMsg["password2"]}
                error={errorMsg["password2"]?.length > 0}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={0}>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <div className={styles.salDropdown}>
                <CustomDropDown
                  id="userCountry"
                  value={formData["userCountry"]}
                  handleChange={(e: any) => {
                    handleCountryChange(e.target.value);
                  }}
                  menuOptions={referenceData?.countries}
                  label={t("dnet.registration_country.text")}
                  module="department"
                  helperText={errorMsg["userCountry"]}
                  error={errorMsg["userCountry"]?.length > 0}
                />
              </div>
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <div className={styles.salDropdown}>
                <CustomDropDown
                  id="language"
                  value={formData["language"]}
                  handleChange={(e: any) => {
                    populateForm(e.target.value, "language");
                  }}
                  menuOptions={languageOptions}
                  label={t("dnet.registration_language.text")}
                  module="department"
                  helperText={errorMsg["language"]}
                  error={errorMsg["language"]?.length > 0}
                />
              </div>
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <div className={styles.salDropdown}>
                <CustomDropDown
                  id="job"
                  value={formData["job"]}
                  handleChange={(e: any) => {
                    populateForm(e.target.value, "job");
                  }}
                  menuOptions={referenceData?.jobFunctions}
                  label={t("dnet.registration_function.text")}
                  module="department"
                  helperText={errorMsg["job"]}
                  error={errorMsg["job"]?.length > 0}
                />
              </div>
            </Grid>
          </Grid>
          <Divider sx={{ paddingTop: "32px" }} />
          <Typography
            variant="subtitle2"
            className={styles.header}
            pb={2}
            pt={2}
            color="primary"
          >
            {t("dnet.registration_subtitle2.text")}
          </Typography>
          <Box className={styles.infoContainer}>
            <Typography component="div" variant="body2" color="text.secondary">
              {t("dnet.registration_company.info.text")}
            </Typography>
          </Box>
          <Grid container spacing={3} mt={0}>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <CustomTextField
                id="accountNo"
                label = {sapAccNoText?.toString()}
                value={formData["accountNo"]}
                changeHandler={(e: any) => {
                  populateForm(e.target.value, "accountNo");
                }}
                onBlurHandler={(e: any) => {
                  validateAccNumber(e.target.value);
                }}
                type="text"
                helperText={errorMsg["accountNo"]}
                error={errorMsg["accountNo"]?.length > 0}
              />
              <Tooltip
                title={<div
                dangerouslySetInnerHTML={{
                  __html: t("dnet.registration_sap_account_no.text")?.toString()
                }}/>}
                placement="right-start"
                arrow
              >
                <IconButton className={styles.infoIcon}>
                  <InfoOutlinedIcon color="action" />
                </IconButton>
              </Tooltip>
              {validAccNum === "valid" && (
                <IconButton className={styles.accValidIcon}>
                  <CheckCircleIcon style={{ fill: "#A9CD68" }} />
                </IconButton>
              )
              //  : validAccNum === "inValid" ? (
              //   <IconButton className={styles.accValidIcon}>
              //     <CancelIcon style={{ fill: "red" }} />
              //   </IconButton>
              // ) : (
              //   ""
              // )
              }
            </Grid>
            <Grid item lg={9} md={6} sm={6} xs={12}>
              <FormControl>
                <FormLabel className={styles.radioText}>
                  {t("registration.are_you_a_pse_dealer.text")?.toString()}
                </FormLabel>
                <RadioGroup
                  row
                  value={formData["isPSE"]}
                  onChange={(e: any) => {
                    populateForm(e.target.value, "isPSE");
                  }}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio size="small" />}
                    label={t("dnet.yes.text")}
                    disabled={!pseTrue}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio size="small" />}
                    label={t("dnet.no.text")}
                    disabled={!pseFalse}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={0}>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <CustomTextField
                id="companyName"
                label={t("dnet.registration_company_name.text")}
                value={formData["companyName"]}
                changeHandler={(e: any) => {
                  populateForm(e.target.value, "companyName");
                }}
                type="text"
                helperText={errorMsg["companyName"]}
                error={errorMsg["companyName"]?.length > 0}
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <div className={styles.salDropdown}>
                <CustomDropDown
                  id="companyCountry"
                  value={formData["companyCountry"]}
                  handleChange={(e: any) => {
                    populateForm(e.target.value, "companyCountry");
                  }}
                  menuOptions={referenceData?.countries}
                  label={t("dnet.registration_country.text")}
                  module="department"
                  helperText={errorMsg["companyCountry"]}
                  error={errorMsg["companyCountry"]?.length > 0}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={0}>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <CustomTextField
                id="address1"
                // label={t("dnet.registration_address.text")}
                label={t("dnet.registration_address1.text")?.toString()}
                value={formData["address1"]}
                changeHandler={(e: any) => {
                  populateForm(e.target.value, "address1");
                }}
                type="text"
                helperText={errorMsg["address1"]}
                error={errorMsg["address1"]?.length > 0}
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <CustomTextField
                id="address2"
                label={t("dnet.registration_address2.text")?.toString()}
                value={formData["address2"]}
                changeHandler={(e: any) => {
                  populateForm(e.target.value, "address2");
                }}
                type="text"
                helperText={errorMsg["address2"]}
                error={errorMsg["address2"]?.length > 0}
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <CustomTextField
                id="address3"
                label={t("dnet.registration_address3.text")?.toString()}
                value={formData["address3"]}
                changeHandler={(e: any) => {
                  populateForm(e.target.value, "address3");
                }}
                type="text"
                helperText={errorMsg["address3"]}
                error={errorMsg["address3"]?.length > 0}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={0}>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <CustomTextField
                id="city"
                label={t("dnet.registration_city.text")}
                value={formData["city"]}
                changeHandler={(e: any) => {
                  populateForm(e.target.value, "city");
                }}
                type="text"
                helperText={errorMsg["city"]}
                error={errorMsg["city"]?.length > 0}
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <CustomTextField
                id="postalCode"
                label={t("dnet.registration_postal_code.text")}
                value={formData["postalCode"]}
                changeHandler={(e: any) => {
                  populateForm(e.target.value, "postalCode");
                }}
                type="text"
                helperText={errorMsg["postalCode"]}
                error={errorMsg["postalCode"]?.length > 0}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={0}>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <CustomTextField
                id="webAddress"
                label={t("dnet.registration_web_address.text")}
                value={formData["webAddress"]}
                changeHandler={(e: any) => {
                  populateForm(e.target.value, "webAddress");
                }}
                type="text"
                helperText={errorMsg["webAddress"]}
                error={errorMsg["webAddress"]?.length > 0}
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <div className={styles.salDropdown}>
                <CustomDropDown
                  id="department"
                  value={formData["department"]}
                  handleChange={(e: any) => {
                    populateForm(e.target.value, "department");
                  }}
                  menuOptions={referenceData?.departments}
                  label={t("dnet.registration_categories.text")}
                  module="department"
                  helperText={errorMsg["department"]}
                  error={errorMsg["department"]?.length > 0}
                />
              </div>
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <CustomTextField
                id="vatNumber"
                label={t("dnet.registration_vat.text")}
                value={formData["vatNumber"]}
                changeHandler={(e: any) => {
                  populateForm(e.target.value, "vatNumber");
                }}
                type="text"
                helperText={errorMsg["vatNumber"]}
                error={errorMsg["vatNumber"]?.length > 0}
              />
            </Grid>
          </Grid>
          <Box mt={2}>
            <div className={styles.checkboxStyle}>
              <Checkbox
                checked={formData["agreed"]}
                onChange={(e: any) => {
                  populateForm(e.target.checked, "agreed");
                }}
                size="small"
                sx={{ paddingLeft: "0" }}
              />
              <Typography
                component="div"
                variant="body2"
                color="text.secondary"
              >
                {t("dnet.registration_agreement1.text")?.toString()}
              </Typography>
              <FormHelperText style={{ color: "#d32f2f", paddingLeft: "8px" }}>
                {errorMsg["agreed"]}
              </FormHelperText>
            </div>
            <div className={styles.checkboxStyle}>
              <Checkbox
                checked={formData["subscribedToNewsletters"]}
                onChange={(e: any) => {
                  populateForm(e.target.checked, "subscribedToNewsletters");
                }}
                size="small"
                sx={{ paddingLeft: "0" }}
              />
              <Typography
                component="div"
                variant="body2"
                color="text.secondary"
              >
                {t("dnet.registration_agreement2.text")?.toString()}
              </Typography>
            </div>
            <Typography 
              component="div" 
              variant="body2" 
              color="text.secondary"
              dangerouslySetInnerHTML={{__html: t("dnet.registration_privacy_policy.text")?.toString()}}
            />
              {/* <a
                href="http://www.sony.co.uk/pages/privacy/Privacy_statement_en_GB.html"
                target="_blank"
              >
                Privacy Policy
              </a> */}
            {/* </Typography> */}
          </Box>
          {CAPTCHA_ENABLED &&
            <ReCAPTCHA 
              ref={captchaRef}
              sitekey={
                process.env?.REACT_APP_CAPTCHA_SITEKEY ? 
                process.env?.REACT_APP_CAPTCHA_SITEKEY : "" 
              }
              onChange={handleCaptchaChange}
              size="invisible"
            />
          }
          <Grid container spacing={3} mt={0}>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <CustomButton
                id="registerButton"
                label={t("dnet.register.text")}
                onClick={registerHandler}
                color="primary"
                margin="0"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer fromLogin={true} />
      </>
    )}
      <CustomModal
        id="confirmationModal"
        open={openModal}
        closeModal={closeModal}
        heading={heading}
        content={content}
      />
    </>
  );
};
