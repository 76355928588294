import React, { useEffect, useState } from "react";
import styles from "./manageReports.module.scss";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { CreateReport } from "./createReport";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllReports,
  getSelectedReportData,
  getSelectedReportDataSuccess,
  createNewReport,
  updateReport,
  updateReportSuccess,
  getContextDetails,
} from "../../../state/actions";
import { useLocation } from "react-router-dom";
import { SelectionCriteria } from "../reporting/selectionCriteria";
import {
  INITIAL_NUMBER_OF_PLANNEDDISPATCHED_UNTIL_DATES,
  INITIAL_NUMBER_OF_DISPATHED_SINCE_DATES,
  REPORT_STATUS,
  REPORT_PERIOD,
  REPORT_FREQUENCY,
  REPORT_TYPE,
  CONTEXT_ORDER,
} from "../../../constants/commonConstants";
import CustomButton from "../../atoms/customButton";
import moment from "moment";
var _ = require("lodash");

export const ManageReports = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = useLocation();
  const [autoEmailReport, setAutoEmailReport] = useState(
    location?.state?.autoEmailReport
  );
  let reportingFilteredValueData = location?.state?.dispatchedData;
  let parentCheckBoxIDs = location?.state?.checkBoxIDs;
  const [selectedReport, setSelectedReport] = useState(
    t("dnet.please_select.text")
  );
  const reportSelectionData = useSelector(
    (state: any) => state?.reportingReducer?.allReportsData
  );
  const [reportSelection, setReportSelection] = useState([]);
  const loginDetails = useSelector(
    (state: any) => state?.commonReducer?.loginResponse?.data?.userDetails
  );
  const contextDetails = useSelector(
    (state: any) => state?.commonReducer?.contextDetails
  );

  useEffect(() => {
    if (pathname === "/Manage_Reports") setAutoEmailReport(false);
  }, [pathname]);
  useEffect(() => {
    let allReportsArray = [];
    if (!autoEmailReport) {
      allReportsArray = reportSelectionData;
      allReportsArray?.unshift({ id: "p", name: t("dnet.please_select.text") });
      setReportSelection(allReportsArray);
    }
  }, [reportSelectionData]);

  const selectedReportData = useSelector(
    (state: any) => state?.reportingReducer?.getSelectedReportData?.[0]
  );
  const updateReportSuccessData = useSelector(
    (state: any) => state?.reportingReducer?.updateReportSuccessData
  );

  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );

  const [error, setError] = useState(false);
  let errorMsg = t("dnet.blank_field_warning.text")?.replace(
    "{0}",
    t("dnet.report_name.text")
  );
  errorMsg = errorMsg?.replace("{1}", t("dnet.email_frequency.text"));
  errorMsg = errorMsg?.replace("{2}", t("dnet.start_with.text"));

  const [reportCreationData, setReportCreationData] = useState<any>({
    id: "",
    name: "",
    email: loginDetails?.profile?.email,
    orderDatePeriod: "p",
    reportType: REPORT_TYPE?.ORDER_REPORT,
    sendEmailtoSalesRepresentativeContacts: true,
    status: REPORT_STATUS?.indexOf("ACTIVE"),
    // dealershipId: currentDealer?.accNum,
    // salesDivision: currentDealer?.bgName?.substring(
    //   currentDealer?.bgName?.length - 2,
    //   currentDealer?.bgName?.length
    // ),
    frequency: "p",
    // locale: i18n.language,
    // nextExecutionDate: "2023-03-01", NOT Required
    requestedDeliveryDatePeriod: "p",
    // distributionChannel: currentDealer?.bgName?.substring(4, 6),
    // salesOrganization: currentDealer?.bgName?.substring(0, 4),
    startingDate: null,
    userid: loginDetails?.personID,
  });

  const StartingMinDate = selectedReportData?.startingDate;

  const getKeyByValue = (object: any, value: any) => {
    return Object.keys(object).find((key) => object[key] === value);
  };

  const destructureCriteria = (arr: any) => {
    let criteriaObj: any = {};
    arr?.forEach((item: any) => {
      criteriaObj = {
        ...criteriaObj,
        [item?.name]: item?.value,
      };
    });
    let formDataCriteriaObj = {
      productSkuId: criteriaObj?.MATERIAL_NUMBER,
      openItems: criteriaObj?.OPEN_ITEMS,
      customerReference: criteriaObj?.CUSTOMER_REFERENCE,
      elapsedDays: criteriaObj?.DISPATCHED_SINCE_DATE,
      dispatchedSinceDate:
        criteriaObj?.DISPATCHED_SINCE_DATE === ""
          ? INITIAL_NUMBER_OF_DISPATHED_SINCE_DATES
          : criteriaObj?.DISPATCHED_SINCE_DATE,
      estimatedDays: criteriaObj?.PLANNED_DISPATCHED_UNTIL_DATE,
      plannedDispatchedUntilDate:
        criteriaObj?.PLANNED_DISPATCHED_UNTIL_DATE === ""
          ? INITIAL_NUMBER_OF_PLANNEDDISPATCHED_UNTIL_DATES
          : criteriaObj?.PLANNED_DISPATCHED_UNTIL_DATE,
      blockedOrders: criteriaObj?.DELIVERY_BLOCKED,
      orderNumbers: criteriaObj?.ORDER_NUMBER,
      orderType: criteriaObj?.ORDER_TYPE,
      shipToAccountId:
        criteriaObj?.SHIP_TO === "" ? "All" : criteriaObj?.SHIP_TO,
      rejectedOrdersEnabled: criteriaObj?.REJECTED_ORDER_CODE,
      rejectedOrderCode:
        criteriaObj?.REJECTED_ORDER_CODE !== ""
          ? criteriaObj?.REJECTED_ORDER_CODE
          : "99",
      onlyRequestedDeliveryDateChanges:
        criteriaObj?.ONLY_REQUESTED_DELIVERY_DATE_CHANGES,
      customerMaterialNumber: criteriaObj?.CUSTOMER_MATERIAL_NUMBER,
      itemsChecked: 0,
      chkDispachedDate: "",
      orderDateFrom: "",
      orderDateTo: "",
      requestedDeliveryDateFrom: "",
      requestedDeliveryDateTo: "",
      orderReportSortingAttribute: "productSku.name",
      rejectedOrders: "",
      rejectionCode: "",
      materialName: "",
      orderNumber: "",
      hotFlag: false,
      hotOrders: false,
      deliverToAddress: "",
      shipTo: "",
      sortingFieldName: "",
      orderItemNumber: "",
      sortingType: "",
      tolerance: "",
    };
    let checkBoxIDsObj: any = checkBoxIDs;
    checkBoxIDsObj = {
      ...checkBoxIDsObj,
      openItems: criteriaObj?.OPEN_ITEMS === "true" ? true : false,
      rejectedOrdersEnabled:
        criteriaObj?.REJECTED_ORDER_CODE !== "" ? true : false,
      estimatedDays:
        criteriaObj?.PLANNED_DISPATCHED_UNTIL_DATE !== "" ? true : false,
      elapsedDays: criteriaObj?.DISPATCHED_SINCE_DATE !== "" ? true : false,
      blockedOrders: criteriaObj?.DELIVERY_BLOCKED === "true" ? true : false,
      onlyRequestedDeliveryDateChanges:
        criteriaObj?.ONLY_REQUESTED_DELIVERY_DATE_CHANGES === "true"
          ? true
          : false,
    };
    setCheckBoxIDs(checkBoxIDsObj);
    return formDataCriteriaObj;
  };

  const [formData, setFormData] = useState({
    itemsChecked: 0,
    productSkuId: "",
    openItems: true,
    customerReference: "",
    chkDispachedDate: "",
    elapsedDays: "",
    dispatchedSinceDate: INITIAL_NUMBER_OF_DISPATHED_SINCE_DATES,
    estimatedDays: "",
    plannedDispatchedUntilDate: INITIAL_NUMBER_OF_PLANNEDDISPATCHED_UNTIL_DATES,
    blockedOrders: false,
    orderNumbers: "",
    orderType: "",
    shipToAccountId: "",
    orderDateFrom: "",
    orderDateTo: "",
    requestedDeliveryDateFrom: "",
    requestedDeliveryDateTo: "",
    orderReportSortingAttribute: "productSku.name",
    rejectedOrdersEnabled: false,
    rejectedOrderCode: "",
    rejectedOrders: "",
    rejectionCode: "",
    materialName: "",
    orderNumber: "",
    hotFlag: false,
    hotOrders: false,
    deliverToAddress: "",
    shipTo: "",
    sortingFieldName: "",
    onlyRequestedDeliveryDateChanges: false,
    orderItemNumber: "",
    sortingType: "",
    tolerance: "",
    customerMaterialNumber: "",
  });

  useEffect(() => {
    dispatch(
      getContextDetails({
        key: CONTEXT_ORDER?.MANAGE_REPORT_PAGE,
      })
    );
  }, []);

  useEffect(() => {
    if (location?.state?.dispatchedData === undefined) {
      const criteriaFormData = destructureCriteria(
        selectedReportData?.criteria
      );
      setFormData(criteriaFormData);
      let newReportData = reportCreationData;
      newReportData = {
        ...newReportData,
        id: selectedReportData?.id,
        name: selectedReportData?.name,
        orderDatePeriod: getKeyByValue(
          REPORT_PERIOD,
          selectedReportData?.orderDatePeriod
        ),
        requestedDeliveryDatePeriod: getKeyByValue(
          REPORT_PERIOD,
          selectedReportData?.requestedDeliveryDatePeriod
        ),
        frequency: REPORT_FREQUENCY[selectedReportData?.frequency],
        startingDate: selectedReportData?.startingDate,
      };
      setReportCreationData(newReportData);
    }
  }, [selectedReportData]);

  useEffect(() => {
    if (updateReportSuccessData?.success === true) {
      setSelectedReport(t("dnet.please_select.text"));
      dispatch(getSelectedReportDataSuccess([]));
      dispatch(getAllReports({ userid: loginDetails?.personID }));
    }
  }, [updateReportSuccessData]);

  const handleReportCreationData = (fieldName: any, data: any) => {
    setError(false);
    if (fieldName === "startingDate") {
      setReportCreationData({
        ...reportCreationData,
        [fieldName]: data,
      });
    } else {
      setReportCreationData({
        ...reportCreationData,
        [fieldName]: data,
      });
    }
  };

  const createCriteria = (obj: any) => {
    const criteria: any = [
      { name: "CUSTOMER_REFERENCE", value: obj?.customerReference },
      { name: "DELIVERY_BLOCKED", value: `${obj?.blockedOrders}` },
      { name: "MATERIAL_NUMBER", value: obj?.productSkuId },
      {
        name: "REJECTED_ORDER_CODE",
        value:
          parentCheckBoxIDs?.rejectedOrdersEnabled ||
          checkBoxIDs?.rejectedOrdersEnabled
            ? obj?.rejectedOrderCode
            : "",
      },
      { name: "SHIP_TO", value: obj?.shipToAccountId },
      { name: "ORDER_NUMBER", value: obj?.orderNumbers },
      {
        name: "SORTING_FIELD_NAME",
        value: obj?.orderChangeReportSortingAttribute,
      },
      {
        name: "OPEN_ITEMS",
        value:
          pathname === "/Reporting/Manage_Reports" ||
          pathname === "/Manage_Reports"
            ? obj?.openItems
            : "",
      },
      {
        name: "ORDER_TYPE",
        value:
          pathname === "/Reporting/Manage_Reports" ||
          pathname === "/Manage_Reports"
            ? obj?.orderType
            : "",
      },
      {
        name: "PLANNED_DISPATCHED_UNTIL_DATE",
        value:
          (pathname === "/Reporting/Manage_Reports" &&
            parentCheckBoxIDs?.estimatedDays) ||
          (pathname === "/Manage_Reports" && checkBoxIDs?.estimatedDays)
            ? obj?.plannedDispatchedUntilDate
            : "",
      },
      {
        name: "DISPATCHED_SINCE_DATE",
        value:
          (pathname === "/Reporting/Manage_Reports" &&
            parentCheckBoxIDs?.elapsedDays) ||
          (pathname === "/Manage_Reports" && checkBoxIDs?.elapsedDays)
            ? obj?.dispatchedSinceDate
            : "",
      },
      { name: "TOLERANCE", value: contextDetails?.tolerance },
      {
        name: "ONLY_REQUESTED_DELIVERY_DATE_CHANGES",
        value: `${obj?.onlyRequestedDeliveryDateChanges}`,
      },
      { name: "CUSTOMER_MATERIAL_NUMBER", value: obj?.customerMaterialNumber },
      {
        name: "SEND_EMAIL_TO_SALES_REPRESENTATIVE_CONTACTS",
        value: loginDetails?.profile?.salesRepresentative,
      },
    ];
    return criteria;
  };

  const reportCreationSaveHandler = () => {
    if (
      reportCreationData?.name !== "" &&
      reportCreationData?.frequency !== "p" &&
      reportCreationData?.startingDate !== null
    ) {
      setError(false);
      const criteria = createCriteria(reportingFilteredValueData);
      let reportCreationDataTemp = {
        ...reportCreationData,
        startingDate: moment(
          new Date(reportCreationData?.startingDate?.$d)
        ).format("YYYY-MM-DD"),
      };
      let data = {
        ...reportCreationDataTemp,
        criteria,
      };
      data.orderDatePeriod = Object.keys(REPORT_PERIOD)?.indexOf(
        data?.orderDatePeriod
      );
      data.reportType =
        pathname === "/Reporting/Manage_Reports"
          ? REPORT_TYPE?.ORDER_REPORT
          : pathname === "/Manage_Reports"
          ? selectedReportData?.reportType
          : REPORT_TYPE?.ORDER_CHANGE_HISTORY;
      data.frequency = REPORT_FREQUENCY?.indexOf(data?.frequency);
      data.requestedDeliveryDatePeriod = Object.keys(REPORT_PERIOD)?.indexOf(
        data?.requestedDeliveryDatePeriod
      );
      dispatch(createNewReport({ report: data }));
    } else {
      setError(true);
    }
  };

  const handleCheckBox = (e: any) => {
    let checkBoxIDsObj: any = checkBoxIDs;
    let targetId = e.target.id;
    let targetValue = e.target.checked;
    if (targetId === "openItems" && targetValue === true) {
      checkBoxIDsObj = {
        ...checkBoxIDsObj,
        [targetId]: targetValue,
        rejectedOrdersEnabled: false,
      };
      setFormData({
        ...formData,
        [targetId]: targetValue,
        rejectedOrdersEnabled: false,
      });
    }
    if (targetId === "rejectedOrdersEnabled" && targetValue === true) {
      checkBoxIDsObj = {
        ...checkBoxIDsObj,
        [targetId]: targetValue,
        openItems: false,
        estimatedDays: false,
        elapsedDays: false,
      };
      setFormData({
        ...formData,
        [targetId]: targetValue,
        openItems: false,
        elapsedDays: "",
        estimatedDays: "",
      });
    } else if (targetId === "rejectedOrdersEnabled" && targetValue === false) {
      checkBoxIDsObj = {
        ...checkBoxIDsObj,
        [targetId]: targetValue,
        openItems: true,
      };
      setFormData({
        ...formData,
        [targetId]: targetValue,
        openItems: true,
      });
    } else if (targetId === "estimatedDays" && targetValue === true) {
      checkBoxIDsObj = {
        ...checkBoxIDsObj,
        [targetId]: targetValue,
        elapsedDays: false,
        rejectedOrdersEnabled: false,
      };
      setFormData({
        ...formData,
        elapsedDays: "",
        estimatedDays: formData?.plannedDispatchedUntilDate,
        rejectedOrdersEnabled: false,
      });
    } else if (targetId === "elapsedDays" && targetValue === true) {
      checkBoxIDsObj = {
        ...checkBoxIDsObj,
        [targetId]: targetValue,
        estimatedDays: false,
        rejectedOrdersEnabled: false,
      };
      setFormData({
        ...formData,
        elapsedDays: formData?.dispatchedSinceDate,
        estimatedDays: "",
        rejectedOrdersEnabled: false,
      });
    } else {
      checkBoxIDsObj = {
        ...checkBoxIDsObj,
        [targetId]: targetValue,
      };
      setFormData({
        ...formData,
        [targetId]: targetValue,
      });
    }
    setCheckBoxIDs(checkBoxIDsObj);
  };

  const handleFormData = (data: any, fieldName: any) => {
    if (fieldName === "dispatchedSinceDate") {
      setFormData({
        ...formData,
        [fieldName]: data,
        elapsedDays: data,
      });
    } else if (fieldName === "plannedDispatchedUntilDate") {
      setFormData({
        ...formData,
        [fieldName]: data,
        estimatedDays: data,
      });
    } else {
      setFormData({ ...formData, [fieldName]: data });
    }
  };

  const [checkBoxIDs, setCheckBoxIDs] = useState({
    openItems: true,
    rejectedOrdersEnabled: false,
    estimatedDays: false,
    elapsedDays: true,
    blockedOrders: false,
  });

  const handleReportChange = (event: any) => {
    setSelectedReport(event.target.value);
    setFormEditable(true);
    dispatch(getSelectedReportDataSuccess([]));
    dispatch(
      getSelectedReportData({
        name: event?.target.value,
        userid: loginDetails?.personID,
      })
    );
  };

  useEffect(() => {
    if (!autoEmailReport) {
      dispatch(getAllReports({ userid: loginDetails?.personID }));
    }
    dispatch(getSelectedReportDataSuccess([]));
    dispatch(updateReportSuccess({}));
  }, [autoEmailReport]);

  const [formEditable, setFormEditable] = useState(true);

  const buttonHandler = (action: any) => {
    const criteria = createCriteria(formData);
    let data: any = {
      ...reportCreationData,
      criteria,
    };
    data.orderDatePeriod = Object.keys(REPORT_PERIOD)?.indexOf(
      data?.orderDatePeriod
    );
    data.reportType = selectedReportData?.reportType;
    data.frequency = REPORT_FREQUENCY?.indexOf(data?.frequency);
    data.requestedDeliveryDatePeriod = Object.keys(REPORT_PERIOD)?.indexOf(
      data?.requestedDeliveryDatePeriod
    );
    if (action === "save") {
      setFormEditable(true);
      dispatch(updateReport({ report: data }));
    }
    if (action === "delete") {
      data.status = REPORT_STATUS?.indexOf("DELETED");
      dispatch(updateReport({ report: data }));
    }
    if (action === "subscribe") {
      selectedReportData?.status === REPORT_STATUS?.indexOf("ACTIVE")
        ? (data.status = REPORT_STATUS?.indexOf("INACTIVE"))
        : (data.status = REPORT_STATUS?.indexOf("ACTIVE"));
      dispatch(updateReport({ report: data }));
    }
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Typography
        variant="subtitle1"
        className={styles.header}
        pb={3}
        color="primary"
      >
        {t("dnet.report_management.text")}
      </Typography>
      {error && (
        <Typography variant="body2" className={styles.errorMsg} color="error">
          {errorMsg}
        </Typography>
      )}
      {autoEmailReport ? (
        <CreateReport
          reportCreationData={reportCreationData}
          handleReportCreationData={handleReportCreationData}
          reportCreationSaveHandler={reportCreationSaveHandler}
        />
      ) : (
        <div>
          <Typography variant="body2" mb={2}>
            {t("dnet.report_man_info.text")}
          </Typography>
          {reportSelection?.length > 0 && 
          <FormControl fullWidth>
            <InputLabel
              id="select-label1"
              sx={selectedReport ? {} : { top: "-7px" }}
            >
              {t("dnet.report_selection.text")}
            </InputLabel>
            <Select
              id="select1"
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={selectedReport}
              onChange={(e) => handleReportChange(e)}
              label={t("dnet.report_selection.text")}
              sx={{
                borderRadius: "4px",
                textAlign: "initial",
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: 200,
                  },
                },
              }}
              size="small"
            >
              {reportSelection?.map((option: any) => {
                return (
                  <MenuItem
                    key={option?.name}
                    value={option?.name}
                    disabled={
                      option?.name === t("dnet.please_select.text")
                        ? true
                        : false
                    }
                  >
                    {option?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          }
          {!_.isEmpty(selectedReportData) && (
            <div className={styles.showReport}>
              <CreateReport
                emailReport={false}
                formEditable={formEditable}
                reportCreationData={reportCreationData}
                handleReportCreationData={handleReportCreationData}
                reportCreationSaveHandler={reportCreationSaveHandler}
                StartingMinDate={StartingMinDate}
              />
              <SelectionCriteria
                formData={formData}
                handleFormData={handleFormData}
                handleCheckBox={handleCheckBox}
                formEditable={formEditable}
                checkBoxIDs={checkBoxIDs}
                parent={
                  selectedReportData?.reportType === 1
                    ? "OrderChangeReport"
                    : ""
                }
              />
              {formEditable ? (
                <div className={styles.buttonsSection}>
                  <CustomButton
                    id="delete"
                    label={t("dnet.delete.text")}
                    onClick={() => buttonHandler("delete")}
                    color="warning.main"
                    margin="0 20px"
                  />
                  <CustomButton
                    id="subscribe"
                    label={
                      REPORT_STATUS?.[selectedReportData?.status] === "ACTIVE"
                        ? t("dnet.unsubscribe.text")
                        : t("dnet.subscribe.text")
                    }
                    onClick={() => buttonHandler("subscribe")}
                    margin="0"
                  />
                  <CustomButton
                    id="edit"
                    label={t("dnet.edit.text")}
                    onClick={() => setFormEditable(false)}
                    color="primary"
                    margin="0 20px"
                  />
                </div>
              ) : (
                <div className={styles.buttonsSection}>
                  <CustomButton
                    id="save"
                    label={t("dnet.save.text")}
                    onClick={() => buttonHandler("save")}
                    color="primary"
                    margin="0 20px"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
