import { Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getPremiumServiceData, getPremiumServiceDataSuccess } from "../../state/actions";
import { useDispatch } from "react-redux";

var _ = require("lodash");

export const PremiumServices = () => {
  const [formSubmitted,setFormSubmitted]= useState(false);
  const dispatch = useDispatch();
  const premiumServiceData = useSelector(
    (state: any) => state?.premiumServiceReducer?.premiumServiceData
  );
  let url = process.env?.REACT_APP_PREMIUM_SERVICE_URL
    ? process.env?.REACT_APP_PREMIUM_SERVICE_URL
    : "https://premium-services.staging.sony.eu/dispatchservlet";
  useEffect(()=>{
    return ()=>{
      dispatch(getPremiumServiceDataSuccess({}));
    }; 
  },[dispatch]);

  const [viewIframe, setViewFrame] = useState(false);
  useEffect(() => {
    if( _.isEmpty(premiumServiceData)){
      dispatch(getPremiumServiceData({}));
      setFormSubmitted(false);
    }   
    else if (!_.isEmpty(premiumServiceData) && !formSubmitted) {
      formRef.current.submit();
      setFormSubmitted(true);
      setViewFrame(true);
    }
  }, [premiumServiceData]);

  const formRef: any = useRef(null);
  return (
    <div>
      <form
        ref={formRef}
        name="warrantyForm"
        method="post"
        target="theIFrame"
        action={url}
      >
        <input type="hidden" name="submitFlag" />
        <input
          type="hidden"
          name="ParentApplicationID"
          value={premiumServiceData?.parentApplicationId}
        />
        {premiumServiceData?.aesEnabled === "true" ||
        premiumServiceData?.aesEnabled ? (
          <input
            type="hidden"
            name="vendorTicket"
            value={premiumServiceData?.vendorTicket}
          />
        ) : (
          <input
            type="hidden"
            name="vendorData"
            value={premiumServiceData?.vendorTicket}
          />
        )}
        <iframe
          name="theIFrame"
          width="100%"
          height="1300px"
          style={viewIframe ? {} : { display: "none" }}
        ></iframe>
      </form>
    </div>
  );
};
