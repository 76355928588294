export const ENDPOINTS = {
  OPEN_ITEMS_API: "",
  CREDIT_ITEMS_API: "invoice/getOpenItems",
  DEBIT_ITEMS_API: "invoice/getOpenItems",
  GET_STATEMENTS_API: "statement/retrieveStatement",
  DOWNLOAD_INVOICE_PDF: "invoice/createInvoice",
  GET_DEBIT_NOTE_REASONS:"",
  GET_USER_CURRENCIES:"currency/retrieveCurrency",
  CREATE_DEBIT_NOTE:"debit-note/createDebitNote",
  PAYMENT_HISTORY_API: "payments/retrievePaymentHistory",
  PAID_ITEMS_API: "invoice/retrievePaidItems",
  PAID_ITEM_DETAILS_API: "payments/retrievePaidItemDetails",
  PAYMENT_DETAILS_API: "payments/retrievePaymentDetails",
  ENQUIRY_DETAILS_API: "invoice/retrieveInvoice",
  GET_MAKE_PAYMENT_DEATILS_API: "invoice/retrieveInvoice",
  GET_BANK_DESCRIPTION_API: "accounts/retrieveBankDescription",
  SUBMIT_PAYMENT: "payments/makePayment",
  GET_DISPUTED_ITEMS: "invoice/retrieveDisputedItems",
  GET_REPLY_ENQUIRY_COMMENTS: "enquiry/retrieveReplyEnquiry",
  SAVE_COMMENT:"enquiry/addReply",
  GET_CREDIT_MANAGEMENT_INFO:"credit/creditManagement",
  LOGIN_USER: "auth/loginWithPassword",
  GET_DEALERS: "auth/retrieveMoreDealersFromCache",
  GET_ACCESSIBLE_MENU: "config/siteSectionConfig",
  SALES_AREA_MAPPING: "config/salesOrganisation",
  GET_SAP_ENV_DETAILS: "accounts/getSAPEnvDetails",
  VALIDATE_ACCOUNT_NUMBER: "signup/validateSapAccountNumber",
  FETCH_REFERENCE_DATA: "signup/getRegistrationInfo",
  REGISTER_USER: "signup/registerUser",
  LOGIN_WITHOUT_PASSWORD: "auth/loginWithoutPassword",
  RESET_PASSWORD: "auth/forgotPasswordHandler",
  GET_USER_INFO_RESET_PASS_ID_API: "auth/getResetPasswordTicketDetails",
  RESET_PASSWORD_API: "auth/resetPassword",
  RETRIEVE_TRANSLATION: "translation/retrieveTranslation",
  CHANGE_PASSWORD_API: "auth/changePassword",
  GET_BUSINESS_GROUP : "dealer/viewChangeDealer",
  VALIDATE_DEALER: "dealer/updateChangeDealer",
  UPDATE_PROFILE: "auth/updateUser",
  FETCH_SITE_SECTION_DETAILS: "config/siteSectionConfig",
  FETCH_JSON_CONFIG: "config/sony1ConfigFetch",
  FETCH_ORDER_DETAILS: "reports/viewSingleOrderDetail",
  FETCH_SELECTED_REPORT: "reports/findUserReportsByName",
  FETCH_ALL_REPORTS: "reports/findUserReports",
  CREATE_REPORT: "reports/insertReport",
  REPORT_FILTERED_DATA: "reports/viewAllReport",
  UPDATE_REPORT_API: "reports/updateReport",
  SEARCH_DATADOWNLOAD_PRODUCTS: "datadownload/search",
  FETCH_DATADOWNLOAD_DATA: "datadownload/data",
  DOWNLOAD_DATA_FILE: "/data-download/product-data-download",
  VALIDATE_CAPTCHA_API: "captcha/validateRecaptcha",
  PRODUCT_VISIBILITY: "/product/productVisibility",
  CHECK_SALES_REP: "salesrepvisibility/checksalesrep",
  CHECK_CONTACT_EMAILS: "salesrepvisibility/contactemails",
  CHECK_SALES_REP_BY_SALES_ORG: "salesrepvisibility/salesrepsbysalesorg",
  CHECK_SALES_ORG_ACCOUNTS: "salesrepvisibility/salesorgaccounts",
  FETCH_PROD_CATEGORY_RESPONSE: "/product/getOnlyCategoryHierarchy",
  CHECK_SALES_REP_DEALER: "systemAdmin/salesrepsdealers",
  GLOBAL_SEARCH_SUGGESTIONS: "/product/searchProduct",
  GLOBAL_SEARCH_RESPONSE: "response",
  FETCH_PRODUCT_DETAILS: "getproductdetails",
  CMS_ARTICLES_API: "getcmsdata",
  TERMS_AND_CONDITION_API: "termscondtranslate",
  QUOTATIONS_API: "getQuotationList",
  LIVE_AVAILABILITY_API:"/product/checkCentralStockFromConfig",
  SET_CURRENT_ACC: "auth/setCurrentAccountForDealer",
  START_PRODUCT_FLOW_API:"product/setProducts",
  GET_NEW_PRODUCT_API:"/product/getNewProducts",
  GET_CATEGORY_PRODUCT_API:"/product/getOneCategoryProductsFromProductList",
  PRICE_CHANGE: "pricechange/getPriceChange",
  ORDER_CHANGE_REPORT_API: "reports/viewAllReportChanges",
  GET_CONTEXT_DETAILS_API: "context/getContextDetails",
  ORDER_CHANGE_HISTORY_API: "reports/viewDetailedReportChange",
  GET_CALENDAR_DETAILS_API: "calendar/getCalendar",
  SFDC_PLACE_ORDER: "sfdcPlaceOrder",
  EDIT_REPORT_API: "reports/editReport",
  GET_PRODUCTS_MATERIAL_API: "/product/getProductByMaterialName",
  SEND_PRODUCT_DETAILS_MAIL:"/product/sendProductDetailEmail",
  PREMIUM_SERVICE_API: "premium-service/generateTicket",
  GET_GRADED_STOCK_API: "product/gradedStocks",
  GET_PRODUCT_LIST_API: "productList/getAllProductLists",
  GET_QUOTE_API: "orderEntry/getQuoteSingleShipTo",
  LOGOUT_API: "auth/logout",
  GET_PRODUCT_GROUP_API:"/productList/getProductGroup",
  DELETE_PRODUCT_GROUP_API:"/productList/deleteProductGroup",
  SAVE_PRODUCT_GROUP_API:"/productList/saveProductGroup",
  UPDATE_PRODUCT_GROUP_API:"/productList/updateProductGroup",
  CART_API: "add-to-shopping-cart",
  CLEAR_ORDER: "delete-shopping-context",
  UPDATE_CART_ORDER_API: "load-shopping-context",
  CART_TOTAL_API: "count-shopping-cart",
  DELETE_SELECTED_CART_ORDER_API: "clear-shopping-cart",
  ADD_TO_CART_API: "load-shopping-context",
  PLACE_SAP_ORDER_API: "place-order",
  GET_MULTI_QUOTE_API: "orderEntry/getQuoteMultiShipTo",
  UPLOAD_FILE_SHOPPING_CART: "upload-file-shopping-cart",
  GET_PRODUCT_IMAGES_API: "/getProductImages",
};
