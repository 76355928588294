import { Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
// @ts-ignore
import { useReactToPrint } from "react-to-print";
import CustomButton from "../../../atoms/customButton";
import { QuotationTable } from "../quotationTable";
import styles from "./receiptPage.module.scss";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  clearCartOrder,
  getCartTotalProductsCount,
  saveQuoteRefData,
  saveQuoteSpecialInstructionData,
  setSelectedShipToAccount,
} from "../../../../state/actions";
// var _ = require("lodash");
const hotIconFlag = require("../../../../assets/images/icon_flag.gif");

export const ReceiptPage = () => {
  const { t } = useTranslation();
  const singleQuoteLoad = useSelector(
    (state: any) => state?.cartReducer?.singleQuoteLoad
  );
  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );
  const clearOrderRes = useSelector(
    (state: any) => state?.cartReducer?.clearOrderRes
  );
  const placeOrderRes = useSelector(
    (state: any) => state?.cartReducer?.placeOrderRes?.data
  );
  const placeOrderResData = useSelector(
    (state: any) => state?.cartReducer?.placeOrderRes
  );

  const location = useLocation();
  const dispatch = useDispatch();
  const componentRef = useRef<HTMLDivElement>(null);
  let heading: any = t("dnet.new_acknowledge_title.text");
  heading = heading.split("<");
  let subHeading: any = t("dnet.new_acknowledge_title.text");
  subHeading = subHeading.split("<p>");

  const prodContentData = placeOrderRes?.output;
  const [totalNetValue, setTotalNetValue] = useState<any>(0);
  const [contextKeys, setContextKeys] = useState<any>({});

  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.loginResponse?.data?.userDetails
  );

  const shoppingContextToken = `${currentDealer?.accNum}_${currentDealer?.bgName}_${currentDealer?.loggedInUser?.username}_${currentDealer?.loggedInUser?.profile?.email}`;

  useEffect(() => {
    dispatch(
      clearCartOrder({
        shoppingContextToken,
        usernameOrEmail: userDetails?.username,
      })
    );
    dispatch(setSelectedShipToAccount({
      selectedShipToAccount: [""],
      multiShipTo: false,
      multiRDD: false,
      partialDelivery: true,
      deliveryDate: null,
      shoppingContextToken,
    }))
    dispatch(
      saveQuoteRefData({
        customerReference: "",
      })
    );
    dispatch(
      saveQuoteSpecialInstructionData({
        specialInstruction: [],
      })
    );
    if (placeOrderResData?.data?.output?.length === 1) {
      setTotalNetValue(
        placeOrderResData?.data?.output[0]?.totalNetValue?.amount
      );
      setContextKeys(placeOrderRes?.payload);
    } else if (placeOrderResData?.data?.output?.length > 1) {
      let total = 0;
      placeOrderResData?.data?.output?.forEach((item: any) => {
        total += item?.totalNetValue?.amount;
      });
      setTotalNetValue(total);
      setContextKeys(placeOrderRes?.payload?.products?.[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeOrderResData]);

  useEffect(() => {
    dispatch(
      getCartTotalProductsCount({
        shoppingContextToken,
        usernameOrEmail: userDetails?.username,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearOrderRes]);

  const confirmationObject = {
    sonyRefNumber:
      location?.state?.deliverTo === "multiship"
        ? null
        : placeOrderRes?.output[0]?.orderNumber,
    creationDate: moment(placeOrderRes?.output[0]?.creationDate)?.format("DD-MMM-YYYY"),
    accountNo: `${currentDealer?.accNum} - ${currentDealer?.accountName}`,
    supplier: currentDealer?.bgName?.slice(0, 4),
    // payer: "",
    paymentTermText: "",
    customerReference:
      location?.state?.deliverTo === "multiship"
        ? placeOrderRes?.payload?.formFields?.customerReference
        : placeOrderRes?.payload?.customerReference,
    rddDate:
      location?.state?.deliverTo === "multiship"
        ? moment(
            placeOrderRes?.payload?.formFields?.requestedDeliveryDate
          )?.format("DD-MMM-YYYY")
        : moment(singleQuoteLoad?.requestedDeliveryDate)?.format("DD-MMM-YYYY"),
    multiShipTo: location?.state?.deliverTo === "multiship",
    deliveryType:
      location?.state?.deliverTo === "multiship"
        ? placeOrderRes?.payload?.products[0]?.isPartialDelivery
        : placeOrderRes?.payload?.isPartialDelivery,
    specialInstructions:
      location?.state?.deliverTo === "multiship"
        ? placeOrderRes?.payload?.specialInstructions
        : placeOrderRes?.payload?.specialInstructions,
    expressDelivery:
      location?.state?.deliverTo === "multiship"
        ? placeOrderRes?.formFields?.expressDelivery
        : placeOrderRes?.payload?.expressDeliveryCheck,
  };

  let supplier_value = confirmationObject?.supplier;
  let exDeliveryFlag =
    location?.state?.deliverTo === "multiship"
      ? placeOrderRes?.payload?.products[0]?.expressDelivery
      : placeOrderRes?.payload?.expressDelivery;
  const receiptPageData = () => {
    return (
      <div>
        <Typography variant="body2" mb={2}>
          {subHeading[1]?.split("<")?.[0]}
        </Typography>
        <Grid container spacing={2} className={styles.gridCustomDesign}>
          {confirmationObject?.sonyRefNumber !== null && (
            <Grid item sm={2} xs={12}>
              <Typography variant="body2">{t("dnet.sony_ref.text")}</Typography>
            </Grid>
          )}
          {confirmationObject?.sonyRefNumber !== null && (
            <Grid item sm={10} xs={12}>
              <Typography variant="body2">
                {confirmationObject?.sonyRefNumber}
              </Typography>
            </Grid>
          )}
          <Grid item sm={2} xs={12}>
            <Typography variant="body2">{t("dnet.date.text")}</Typography>
          </Grid>
          <Grid item sm={10} xs={12}>
            <Typography variant="body2">
              {confirmationObject?.creationDate}
            </Typography>
          </Grid>
          <Grid item sm={2} xs={12}>
            <Typography variant="body2">{t("dnet.account_no.text")}</Typography>
          </Grid>
          <Grid item sm={10} xs={12}>
            <Typography variant="body2">
              {confirmationObject?.accountNo}
            </Typography>
          </Grid>
          <Grid item sm={2} xs={12}>
            <Typography variant="body2">{t("dnet.supplier.text")}</Typography>
          </Grid>
          <Grid item sm={10} xs={12}>
            <Typography variant="body2">
              {t(`salesorganization.${supplier_value}`)}
            </Typography>
          </Grid>
          {((location?.state?.deliverTo === "multiship" &&
            placeOrderRes?.payload?.products[0]?.paymentTermEnabled ===
              "true") ||
            (location?.state?.deliverTo !== "multiship" &&
              placeOrderRes?.payload?.paymentTermEnabled === "true")) &&
          confirmationObject?.paymentTermText !== "" ? (
            <>
              <Grid item sm={2} xs={12}>
                <Typography variant="body2">{t("dnet.payer.text")}</Typography>
              </Grid>
              <Grid item sm={10} xs={12}>
                {confirmationObject?.paymentTermText}
              </Grid>
            </>
          ) : (
            confirmationObject?.paymentTermText !== "" && (
              <>
                <Grid item sm={2} xs={12}>
                  <Typography variant="body2">
                    {t("dnet.payment_term.text")}
                  </Typography>
                </Grid>
                <Grid item sm={10} xs={12}>
                  <Typography variant="body2">
                    {confirmationObject?.paymentTermText}
                  </Typography>
                </Grid>
              </>
            )
          )}
          <Grid item sm={2} xs={12}>
            <Typography variant="body2">{t("dnet.your_ref.text")}</Typography>
          </Grid>
          <Grid item sm={10} xs={12}>
            <Typography variant="body2">
              {confirmationObject?.customerReference}
            </Typography>
          </Grid>
          <Grid item sm={2} xs={12}>
            <Typography variant="body2">
              {t("dnet.requested_delivery_date.text")}
            </Typography>
          </Grid>
          <Grid item sm={10} xs={12} className={styles.gridCustomDesign}>
            <Typography variant="body2">
              {confirmationObject?.rddDate}
            </Typography>
          </Grid>
          <Grid item sm={2} xs={12}>
            <Typography variant="body2">{t("dnet.deliver_to.text")}</Typography>
          </Grid>
          <Grid item sm={10} xs={12} className={styles.gridCustomDesign}>
            <Typography variant="body2">
              {location?.state?.deliverTo === "multiship"
                ? t("dnet.multiple_delivery.text")
                : `${prodContentData[0]?.shipToAccount?.number} - ${prodContentData[0]?.shipToAccount?.name}`}
            </Typography>
          </Grid>
          {((location?.state?.deliverTo === "multiship" &&
            placeOrderRes?.payload?.products[0]?.isCompleteDeliveryEnabled) ||
            (location?.state?.deliverTo !== "multiship" &&
              placeOrderRes?.payload?.isCompleteDeliveryEnabled)) && (
            <>
              <Grid item sm={2} xs={12}>
                <Typography variant="body2">
                  {t("dnet.delivery_type.text")}
                </Typography>
              </Grid>
              <Grid item sm={10} xs={12} className={styles.gridCustomDesign}>
                <Typography variant="body2">
                  {confirmationObject?.deliveryType
                    ? t("dnet.partial.text")
                    : t("dnet.complete.text")}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item sm={2} xs={12}>
            <Typography variant="body2">
              {t("dnet.special_instructions_colon.text")}
            </Typography>
          </Grid>
          <Grid item sm={10} xs={12} className={styles.gridCustomDesign}>
            <Typography variant="body2">
              {confirmationObject?.specialInstructions?.length > 0 ? (
                confirmationObject?.specialInstructions?.map((item: any) => {
                  return (
                    <Typography variant="body2">
                      {item?.check && `${item?.label} : ${item?.fieldValue}`}
                    </Typography>
                  );
                })
              ) : (
                <Typography variant="body2">none</Typography>
              )}
            </Typography>
          </Grid>
          {exDeliveryFlag && (
            <>
              <Grid item sm={2} xs={12}>
                <Typography variant="body2">
                  {t("dnet.print_express_delivery.text")}
                </Typography>
              </Grid>
              <Grid item sm={10} xs={12} className={styles.gridCustomDesign}>
                <Typography variant="body2">
                  {confirmationObject?.expressDelivery
                    ? t("dnet.yes.text")
                    : t("dnet.no.text")}
                </Typography>
              </Grid>
            </>
          )}
          {((location?.state?.deliverTo === "multiship" &&
            placeOrderRes?.formFields?.sendOrderByEmailCheck) ||
            (location?.state?.deliverTo !== "multiship" &&
              placeOrderRes?.payload?.sendOrderByEmailCheck)) && (
            <Grid item xs={12}>
              <Typography
                variant="body2"
                sx={{
                  backgroundColor: "#d1ecf1",
                  color: "#0c5460",
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 20px",
                  borderRadius: "1px",
                  marginBottom: "15px",
                }}
              >
                {t("dnet.email_order_details_message.text")
                  ?.toString()
                  ?.replace("{0}", "") +
                  (location?.state?.deliverTo === "multiship"
                    ? placeOrderRes?.formFields?.sendOrderByEmailList?.join(
                        ", "
                      )
                    : placeOrderRes?.payload?.sendOrderByEmailList?.join(", "))}
              </Typography>
            </Grid>
          )}
        </Grid>
      </div>
    );
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const priceVisible = contextKeys?.priceVisible;
  const [showPrice, setShowPrice] = useState(true);

  const createTable = () => {
    const tableHeaderData = [
      { id: "line", label: t("dnet.line.text") },
      { id: "model", label: t("dnet.model.text") },
      { id: "orderedQuantity", label: t("dnet.ordered_quantity.text") },
      { id: "reqDate", label: t("dnet.req_date.text") },
      { id: "plannedQuantity", label: t("dnet.planned_quantity.text") },
      { id: "plannedDate", label: t("dnet.planned_date.text") },
      { id: "unitPrice", label: t("dnet.unit_price.text"), minWidth: "120px" },
      { id: "netPrice", label: t("dnet.netprice.text"), minWidth: "120px" },
      { id: "description", label: t("dnet.description.text") },
      { id: "specialPrice", label: t("dnet.special_price.text") },
    ];
    let cellStyle = {
      border: "1px solid #c8c8c8",
    };
    return (
      <div>
        <Typography
          variant="subtitle1"
          className={styles.header}
          pb={3}
          color="primary"
        >
          {heading[0]}
        </Typography>
        <div>
          {receiptPageData()}
          <div className={styles.tableBox}>
            <div className={styles.tableContainer}>
              <table style={{ borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    {tableHeaderData.map((headCell: any, index: any) => (
                      <React.Fragment key={index}>
                        {headCell.id === "unitPrice" ? (
                          priceVisible && (
                            <td
                              key={headCell.id}
                              style={{
                                minWidth: headCell?.minWidth,
                                ...cellStyle,
                              }}
                            >
                              {headCell.label}
                            </td>
                          )
                        ) : headCell.id === "netPrice" ? (
                          <td
                            key={headCell.id}
                            style={{
                              minWidth: headCell?.minWidth,
                              ...cellStyle,
                            }}
                          >
                            {headCell.label}
                          </td>
                        ) : headCell.id === "specialPrice" ? (
                          contextKeys?.isSpecialPriceEnabled && (
                            <td key={headCell.id} style={cellStyle}>
                              {headCell.label}
                            </td>
                          )
                        ) : headCell.id === "reqDate" ? (
                          location?.state?.multiRdd && (
                            <td key={headCell.id} style={cellStyle}>
                              {headCell.label}
                            </td>
                          )
                        ) : (
                          <td key={headCell.id} style={cellStyle}>
                            {headCell.label}
                          </td>
                        )}
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {prodContentData?.length > 0 ? (
                    prodContentData?.map((order: any, index: any) => {
                      return (
                        <>
                          {location?.state?.deliverTo === "multiship" && (
                            <tr
                              tabIndex={-1}
                              //   key={index}
                            >
                              <td
                                colSpan={tableHeaderData?.length - 1}
                                style={cellStyle}
                              >
                                <div>
                                  {`${t("dnet.deliver_to.text")} ${
                                    order?.shipToAccount?.number
                                  } - ${order?.shipToAccount?.name}, ${
                                    order?.shipToAccount?.address?.street
                                  }, ${
                                    order?.shipToAccount?.address?.townCity
                                  } (${
                                    order?.shipToAccount?.address?.country
                                  })`}
                                </div>
                                {order?.orderNumber !== "" && (
                                  <div>
                                    {`${t("dnet.sony_ref.text")} ${
                                      order?.orderNumber
                                    }`}
                                  </div>
                                )}
                              </td>
                            </tr>
                          )}
                          {order?.lineItems?.map(
                            (product: any, productIndex: any) => {
                              return (
                                <tr tabIndex={-1} key={index}>
                                  <td style={cellStyle}>
                                    {parseInt(product?.index, 10).toString()}
                                    {product?.hotflagged && (
                                      <img
                                        src={hotIconFlag}
                                        alt="hotIconFlag"
                                      />
                                    )}
                                  </td>
                                  <td style={cellStyle}>
                                    {product?.productSku?.name}
                                  </td>
                                  <td style={cellStyle}>{product?.quantity}</td>
                                  {location?.state?.multiRdd && (
                                    <td style={cellStyle}>
                                      {moment(
                                        product?.requestedDeliveryDate
                                      )?.format("DD-MMM-YYYY")}
                                    </td>
                                  )}
                                  {product?.scheduleLines?.length > 0 ? (
                                    <td style={cellStyle}>
                                      {product?.scheduleLines[0]?.quantity}
                                    </td>
                                  ) : (
                                    <td style={cellStyle}></td>
                                  )}
                                  {product?.scheduleLines?.length > 0 ? (
                                    <td style={cellStyle}>
                                      {product?.scheduleLines?.map((item: any) => (
                                        <div>
                                          {item?.confirmed && item?.plannedIssueDate
                                            ? moment(item?.plannedIssueDate)?.format(
                                                "DD-MMM-YYYY"
                                              )
                                            : product?.centralStockStatus
                                            ? t("dnet.centralstock_overnight.text")
                                            : t("dnet.unconfirmed.text")}
                                        </div>
                                      ))}
                                    </td>
                                  ) : (
                                    <td style={cellStyle}></td>
                                  )}
                                  {priceVisible && (
                                    <td style={cellStyle}>
                                      {showPrice &&
                                        `${product?.unitPrice?.currency} ${(product?.unitPrice?.amount)?.toFixed(2)}`}
                                    </td>
                                  )}
                                  <td style={cellStyle}>
                                    {showPrice &&
                                      `${product?.netValuePrice?.currency} ${(product?.netValuePrice?.amount)?.toFixed(2)}`}
                                  </td>
                                  <td style={cellStyle}>
                                    {product?.description}
                                  </td>
                                  {contextKeys?.isSpecialPriceEnabled && (
                                    <td style={cellStyle}>
                                      {product?.specialPrice &&
                                        product?.specialPrice?.amount &&
                                        `${product?.specialPrice?.currency} ${product?.specialPrice?.amount}`}
                                    </td>
                                  )}
                                </tr>
                              );
                            }
                          )}
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan={8}
                        style={cellStyle}
                        align="center"
                        className={styles.noRecFound}
                      >
                        {t("gzl.no_data_available.text")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className={styles.tableFooterDesign}>
              {contextKeys?.belowQuantityPrice?.amount > 0 && (
                <div className={styles.totalAmount}>
                  <Typography variant="body2">
                    {t("dnet.total_fee.text")}
                  </Typography>
                  <Typography variant="body2">
                    {`${contextKeys?.belowQuantityPrice?.currency} ${contextKeys?.belowQuantityPrice?.amount}`}
                  </Typography>
                </div>
              )}
              <div className={styles.totalAmount}>
                <Typography variant="body2">
                  {`${t("dnet.total.text")} ${t(
                    "dnet.order_report.net_price.text"
                  )}`}
                </Typography>
                <Typography variant="body2">
                  {`${
                    prodContentData[0]?.totalNetValue?.currency
                  } ${totalNetValue?.toFixed(2)}`}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Typography
        variant="subtitle1"
        className={styles.header}
        pb={3}
        color="primary"
      >
        {heading[0]}
      </Typography>
      <div>
        {receiptPageData()}
        <div className={styles.buttonSection}>
          <CustomButton
            id="printer_friendly"
            label={t("dnet.printer_friendly.text")}
            onClick={handlePrint}
            margin="0"
          />
          <div style={{ display: "none" }}>
            <div ref={componentRef} style={{ padding: "22px" }}>
              {createTable()}
            </div>
          </div>
        </div>
        <QuotationTable
          isMultiShip={
            location?.state?.deliverTo === "multiship" ? true : false
          }
          multiRdd={location?.state?.multiRdd}
          showPrice={showPrice}
          setShowPrice={setShowPrice}
          placeOrderPage={true}
        />
      </div>
    </div>
  );
};
