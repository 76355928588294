import React, { useCallback, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import CustomButton from "../../../atoms/customButton";
import { useTranslation } from "react-i18next";
import styles from "./fileUpload.module.scss";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { cartFileUpload } from "../../../../state/actions";
import moment from "moment";

const invoiceStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
  paddingBottom: "45px",
  textAlign: "left",
  overflowY: "scroll",
  height: "70%",
};

const dropzoneStyle = {
  border: '2px dashed #ccc',
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
}

export const FileUpload = ({
  openFileModal,
  closeFileModal,
  handleFileChange,
  orderEntryObj,
  radioButtonValue,
  isMultiRequestedDeliveryDate,
}: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()

  const [fileData, setFileData] = useState(null);
  const [fileUploadPayload, setFileUploadPayload] = useState({});

  const loadOrderFunction = () => {
    dispatch(cartFileUpload(fileUploadPayload));
    setFileData(null);
    handleFileChange();
    closeFileModal();
  }

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      readFileAsBase64(file);
    }
  }, [])

  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );

  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.loginResponse?.data?.userDetails
  );

  const readFileAsBase64 = (file: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      let base64Data: any = reader?.result;
      base64Data = base64Data?.split(',')?.[1];
      setFileData(file.name)
      let fileType = (file?.type?.split("/"))?.[1];
      let payload = {
        usernameOrEmail: userDetails?.username,
        spToken: `${currentDealer?.accNum}_${currentDealer?.bgName}_${currentDealer?.loggedInUser?.username}_${currentDealer?.loggedInUser?.profile?.email}`,
        base64String: base64Data,
        shipToAccountId:
          orderEntryObj?.shipToAccounts === "0"
            ? []
            : [orderEntryObj?.shipToAccounts],
        requestedDeliveryDate:
          orderEntryObj?.requestedDeliveryDate === null
            ? null
            : moment(orderEntryObj?.requestedDeliveryDate).format(
                "DD/MM/YYYY"
              ),
        isPartialDelivery: radioButtonValue === "partial",
        invoiceTo: orderEntryObj?.invoiceToAccounts,
        payerToAccount: orderEntryObj?.payerToAccounts,
        isMultiShipTo: orderEntryObj?.shipToAccounts === "multiship",
        isMultiRequestedDeliveryDate: isMultiRequestedDeliveryDate,
        isOrderEntryPage: true,
        fileType,
      }
      setFileUploadPayload(payload)
    };
    reader.readAsDataURL(file);
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {"text/csv" : [".csv"], "text/xml": [".xml"]},
  })

  return (
    <div>
      <Modal
        open={openFileModal}
        // onClose={closeFileModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={invoiceStyle}>
          <div className={styles.modalHeader}>
            <Typography variant="subtitle2" color="primary">
              {t("dnet.load_from_file.text")}
            </Typography>
            <IconButton onClick={closeFileModal}>
              <CloseIcon />
            </IconButton>
          </div>
          <div>
            <div className={styles.info}>
              <Typography color="#0c5460" variant="body1">
                {t("dnet.load_from_file_title.text")}
              </Typography>
            </div>
            <div className={styles.errMsg}>
              <Typography color="#721c24" variant="body1">
                {t("dnet.load_fromfilewarning.text")}
              </Typography>
            </div>
          </div>
          <div className={styles.listDesign}>
            {/* <Typography variant="body1" mb={3}>
              {t("dnet.upload_file.text")}
            </Typography> */}
            <div className={styles.uploadInnerSection}>
              <div 
                {...getRootProps()} 
                style={{
                  border: '2px dashed #f25529',
                  borderRadius: "4px",
                  padding: "20px",
                  textAlign: "center",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p className={styles.uploadFileButtonStyle}>{t("dnet.upload_file.text")}</p>
                ) : (
                  <p className={styles.uploadFileButtonStyle}>{t("dnet.upload_file.text")}</p>
                )}
              </div>
            </div>
            {fileData && (
              <div className={styles.filenameArea}>
                <Typography variant="body1">{t("dnet.file_name.text")?.toString()}</Typography>
                <Typography variant="body1">{fileData}</Typography>
              </div>
            )}
            {fileData && (
              <div>
                <CustomButton
                  label={t("dnet.load_order.text")}
                  onClick={loadOrderFunction}
                  margin={0}
                  color="primary"
                />
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

FileUpload.defaultProps = {
  openFileModal: false,
  closeFileModal: () => {},
  handleFileChange: () => {},
};
