import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import styles from "./globalSearch.module.scss";
import { ProdContentDetail } from "../products/prodContentDetail";
import { NoResultFound } from "./noResultFound";
import { ResellerProdContent } from "../products/resellerProdContent";

var _ = require("lodash");

export const GlobalSearch = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const productByMaterialName = useSelector(
    (state: any) => state?.productReducer?.productsByMaterialName
  );

  const globalSearchSuggestions = useSelector(
    (state: any) => state?.commonReducer?.globalSearchSuggestions
  );

  //Used to switch tab values
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [isReseller, setIsReseller] = useState<any>(false);
  const userType = useSelector(
    (state: any) =>
      state?.commonReducer?.loginResponse?.data?.userDetails?.profile?.organisation?.type
  );
  useEffect(() => {
    if (userType === "Reseller") {
      setIsReseller(true);
    }
  }, [userType]);


  const [productSearchResponse, setProductSearchResponse] = useState<any>({});
  const [contentSearchResponse, setContentSearchResponse] = useState<any>({});

  useEffect(() => {
      setProductSearchResponse(productByMaterialName);
  }, [productByMaterialName]);

  return (
    <>
      <Typography
        variant="subtitle1"
        className={styles.header}
        pb={1}
        color="primary"
      >
        {t("dnet.search_results.text")}
      </Typography>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        sx={{ borderBottom: "1px solid #c8c8c8" }}
        className={styles.tabsStyle}
        TabIndicatorProps={{
          sx: {
            bottom: "4px",
            height: "1.5px",
          },
        }}
      >
        <Tab label={t("dnet.search_product_tabs.text")} />
        <Tab label={t("dnet.search_content_tabs.text")} />
      </Tabs>

      {value === 0 && (
        <Box mt={4}>
          {!_.isEmpty(productSearchResponse) ? (isReseller ? <ResellerProdContent parent="globalSearch" searchResult={productSearchResponse}/> : ( 
            <ProdContentDetail parent="globalSearch" searchResult={productSearchResponse}/>
          )) : (
            <NoResultFound />
          )}
        </Box>
      )}
      {value === 1 && (
        <Box mt={4}>
          {!_.isEmpty(contentSearchResponse) ? (
            <h4>content is available</h4>
          ) : (
            <NoResultFound />
          )}
        </Box>
      )}
    </>
  );
};
