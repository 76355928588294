import { Typography, Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./makePayment.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getMakePaymentTableData,
  getBankDescriptionData,
  getComments,
  submitPayment,
  submitPaymentSuccess,
} from "../../../state/actions";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate } from "react-router-dom";
import MakePaymentTable from "./makePaymentTable";
import CustomButton from "../../atoms/customButton";
import { DebitNoteCreation } from "../debit-note-creation";
import CustomModal from "../../atoms/customModal";
import { AddComments } from "../reply-enquiry/add-comments";
import { useTranslation } from "react-i18next";
import logger from "../../helper/logger";
var currencyFormatter = require('currency-formatter');

export const MakePayment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  let openItemRowData = location?.state?.openItemRowData;
  let payBalanceAmount = location?.state?.PayBalanceAmount;
  const [openModal, setOpenModal] = useState(false);
  const backButtonText = t("gzl.back.text");

  const makePaymentTableData = useSelector(
    (state: any) => state?.gazelleReducer?.makePaymentTableData
  );

  const bankDescription = useSelector(
    (state: any) => state?.gazelleReducer?.bankDescription
  );
  let bankDescriptionMsg = t("gzl.make_payment_headline1.text");
  bankDescriptionMsg = bankDescriptionMsg.split("{0}").join(bankDescription);

  let partialPayment = !(
    payBalanceAmount === ""
  );

  const paymentSubmissionResponseStatus = useSelector(
    (state: any) => state?.gazelleReducer?.paymentSubmissionResponse
  );
  const enablePayButton = useSelector(
    (state: any) => state?.gazelleReducer?.enablePayButton
  )

  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.userDetails
  );

  const saveAccountDetails = useSelector(
    (state: any) => state?.commonReducer?.saveAccountDetails
  )

  const getInvoiceData = () => {
    dispatch(
      getMakePaymentTableData({
        invoiceId: openItemRowData?.invoiceIdPdf,
        partnerKey: saveAccountDetails?.payerToAcc?.number,
      })
    );
  };

  const closeModal = () => {
    // On close of payment success modal disabling payment button
    setOpenModal(false);
    dispatch(submitPaymentSuccess(false));
  };

  const getBankDescription = () => {
    dispatch(
      getBankDescriptionData({
        language: userDetails?.language,
        partnerKey: saveAccountDetails?.payerToAcc?.number,
        organizationKey: "GB00",
      })
    );
  };

  const submitPaymentOnClick = () => {
    // Payment submission functionality
    let sendInvoiceId = [openItemRowData?.invoiceIdPdf];
    let sendAmount = [
      payBalanceAmount ? payBalanceAmount : openItemRowData?.openAmount
    ];
    logger.info("Triggered payment of amount ",sendAmount);
    dispatch(
      submitPayment({
        username: userDetails?.userName,
        partnerKey: saveAccountDetails?.payerToAcc?.number,
        invoiceIds: sendInvoiceId,
        paymentsAmounts: sendAmount,
        language: userDetails?.language,
      })
    );
  };

  useEffect(() => {
    if(!openItemRowData?.invoiceId){
      navigate(-1)
    }
    getInvoiceData();
    getBankDescription();
    if(partialPayment && openItemRowData?.disputed)
    dispatch(
      getComments({
        language: userDetails?.language,
        accountId: saveAccountDetails?.payerToAcc?.number,
        username: userDetails?.userName,
        invoiceId: openItemRowData?.invoiceId,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paymentSubmissionResponseStatus) {
      setOpenModal(true);
      setSubmitButtonDisabled(true);
    }
  }, [paymentSubmissionResponseStatus]);
  
  let confirmationModalMsg = t("gzl.pay_confirmation_headline1.text")
  confirmationModalMsg = confirmationModalMsg.split("{0}").join(bankDescription)

  return (
    <>
      <Box className={styles.headerBox}>
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon titleAccess={backButtonText} />
        </IconButton>
        <Typography
          sx={{ paddingBottom: "0px" }}
          variant="subtitle1"
          className={styles.header}
          pb={3}
          color="primary"
        >
          {t("gzl.make_payment_title.text")}
        </Typography>
      </Box>
      <Box>
        <Box className={styles.tableContainer}>
          <div className={styles.tableHeader}>
            <Typography variant="body2" className={styles.tableHeading} dangerouslySetInnerHTML={{__html: bankDescriptionMsg}}>
            </Typography>
          </div>
          <MakePaymentTable
            payBalanceAmount={payBalanceAmount}
            openItemRowData={openItemRowData}
          />
        </Box>
        <Box>
          <CustomButton
            label={t("gzl.button_pay.text")}
            color="primary"
            onClick={submitPaymentOnClick}
            disabled={partialPayment ? !enablePayButton : false}
          />
        </Box>
        {partialPayment && (
          <Box className={styles.Box_style_pay}>
            {!openItemRowData?.disputed ? (
              <DebitNoteCreation fromPaymentPage={true} invoiceId={openItemRowData?.invoiceIdPdf} />
            ) : (
              <Box>
                <AddComments invoiceId={openItemRowData?.invoiceIdPdf} />
              </Box>
            )}
          </Box>
        )}
      </Box>
      <CustomModal
        open={openModal}
        closeModal={closeModal}
        heading={t("gzl.pay_confirmation_title.text")}
        content={
          confirmationModalMsg + " " + (payBalanceAmount
            ? payBalanceAmount
            : openItemRowData?.openAmount) + " " + currencyFormatter.findCurrency(
              openItemRowData?.currency
            )?.symbol
        }
      />
    </>
  );
};
