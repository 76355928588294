export const TOAST_SEVERITY = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
};

export const TOAST_MESSAGES = {
  DOWNLOAD_SUCCESSFUL: "PDF downloaded successfully",
  API_FAILURE: "Error occured while fetching data, please try again",
  DEBIT_NOTE_CREATION_ERROR:
    "Error occured while creating debit note, please try again",
  INVALID_FORM: "Kindly fill valid details in all the fields and save",
  PAYMENT_ERROR: "Error occured while submiting the payment, please try again",
  SAVE_COMMENT_ERROR:
    "Error occured while saving the comment, please try again",
  LOGIN_ERROR: "Not able to login, please try again",
  PDF_DOWNLOAD_ERROR:
    "Invoice reference is not valid. Contact your system administrator.",
  ROLES_UNASSIGNED:
    "Roles are not assigned for the selected dealer. Contact your system administrator.",
};

export const REASON_TYPE = {
  PR: "PR",
  RT: "RT",
  DD: "DD",
  RE: "RE",
  SC: "SC",
  TA: "TA",
  SE: "SE",
  SP: "SP",
  WA: "WA",
};

// export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const EMAIL_REGEX = /^\S+@\S+$/; //Same as Existing
export const NEW_EMAIL_REGEX =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export const PHONE_NUM_REGEX =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

export const NAME_REGEX = /^[A-Za-z .’\-,]{2,35}$/;

export const PASSWORD_PATTERN =
  /^((?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])^(?!.* ).{8,})$/;

export const ACCOUNT_NUM_PATTERN = /^[0-9]{0,12}$/;

export const TELEPHONE_NUM_PATTERN = /^(\+)?[0-9 -]{5,20}$/;

export const isValidRefNum = /^[a-zA-Z0-9 /-]*$/;

export const FILE_NAME_PATTERN =
  /^(?!(?:CON|PRN|AUX|NUL|COM[1-9]|LPT[1-9])(?:\.[^.]*)?$)[^<>:"/\\|?*\x00-\x1F]*[^<>:"/\\|?*\x00-\x1F\ .]$/;

export const ACCOUNT_NUM_10_DIGIT_PATTERN = /^[0-9]{10}$/;

export const BUSINESS_GROUP_PATTERN = /^[A-Za-z]{2}[0-9]{6}$/;

export const ACCESS_DENIED = "accessDenied";

export const PRODUCT_STATUS = {
  PRODUCT_AVAILABILITY: "1",
  PRODUCT_AVAILABILITY_ON_KNOWN_DATE: "2",
  PRODUCT_UNAVAILABLE: "3",
  PRODUCT_SUBJECT_TO_MANUAL_ALLOCATION: "4",
  PRODUCT_AVAILABLE_BUT_SUBJECT_TO_QUOTA: "5",
  PRODUCT_AVAILABLE_ON_KNOWN_DATE_AND_SUBJECT_TO_QUOTA: "6",
  PRODUCT_AVAILABILITY_UNKNOWN: "7",
  PRODUCT_PRE_SALES: "8",
};

export const RED = "#CC0000";
export const GREEN = "#22C083";
export const YELLOW = "#ebac3d";
export const BLUE = "dodgerblue";
export const GRAY = "#eee";
export const INITIAL_NUMBER_OF_PLANNEDDISPATCHED_UNTIL_DATES = "7";
export const INITIAL_NUMBER_OF_DISPATHED_SINCE_DATES = "-7";

export const REPORT_STATUS = ["ACTIVE", "INACTIVE", "DELETED", "AUTOMATED"];
export const REPORT_PERIOD: any = {
  /**
   * Respectively Last Week, Last 2 Weeks, Last 3 Weeks, Last Month, Last
   * 2 Months, Last 3 Months, Last 6 Months, Next Week, Next 2 Weeks, Next
   * 3 Weeks, Next Month, Next 2 Months, Next 3 Months, Next 6 Months
   */
  LW: -7,
  L2W: -14,
  L3W: -21,
  LM: -30,
  L2M: -60,
  L3M: -90,
  L6M: -180,
  NW: 7,
  N2W: 14,
  N3W: 21,
  NM: 30,
  N2M: 60,
  N3M: 90,
  N6M: 180,
};

export const REPORT_FREQUENCY = ["W", "M"];

export enum REPORT_TYPE {
  "ORDER_REPORT",
  "ORDER_CHANGE_HISTORY",
}

export const QUERY_TYPE = {
  DIGITAL_ASSET: "digitalAsset",
  PRODUCT_DATA: "productData",
};

export const SEARCH_TAB_INDEX = {
  CONTENT_SEARCH: "ContentSearch",
  PRODUCT_SEARCH: "ProductSearch",
};

export const PUBLICATION = {
  GLOBAL: "global",
  SCORE: "score",
  PSE: "pse",
};

export const PERMA_LINK =
  "https://www.sony.co.uk/permalink/product/prodValue?locale=localeValue";

export const MODAL_TYPES = {
  IMAGES: "images",
  VIDEOS: "videos",
  DIGITAL_ASSET: "digitalAsset",
  PRODUCT_DATA: "productData",
  PRINT: "print",
};

export const CMS = {
  ADD_BLOCK: "add_block",
  ONE_FLASH_TWO_BLOCK_TXT: "oneflashtwoblcktxt",
  TWO_BLOCK_TXT_ONE_FLASH: "twoblcktxtoneflash",
  ADD_ARTICLE: "add_article",
};

export enum CONTEXT_ORDER {
  ORDER_CHANGE_HISTORY_PAGE,
  MANAGE_REPORT_PAGE,
  RPEORTING_PAGE,
  ORDER_STATUS_PAGE,
  GET_QUOTATIONS_PAGE,
  PRODUCT_MODULE_PAGE,
  CHANGE_DEALER,
  DATA_DOWNLOAD_PAGE,
}

export const LINE_ITEM_ORDER_STATUS = {
  STATUS_AMENDABLE: "",
  STATUS_MATERIAL_ON_SHORTAGE: "1",
  STATUS_GIRO: "2",
  STATUS_CONTRACTED: "3",
  STATUS_DELETED: "4",
};
