export const commonConstant = {
  GET_STATEMENT_DATA: "GET_STATEMENT_DATA",
  GET_STATEMENT_DATA_SUCCESS: "GET_STATEMENT_DATA_SUCCESS",
  SET_OPEN_ITEMS: "SET_OPEN_ITEMS",
  GET_OPEN_ITEMS: "GET_OPEN_ITEMS",
  DOWNLOAD_INVOICE_PDF: "DOWNLOAD_STATEMENT_PDF",
  SET_LOADER: "SET_LOADER",
  SET_OPEN_TOAST: "SET_OPEN_TOAST",
  GET_DEBIT_NOTE_REASONS: "GET_DEBIT_NOTE_REASONS",
  GET_DEBIT_NOTE_REASONS_SUCCESS: "GET_DEBIT_NOTE_REASONS_SUCCESS",
  GET_USER_CURRENCIES: "GET_USER_CURRENCIES",
  GET_USER_CURRENCIES_SUCCESS: "GET_USER_CURRENCIES_SUCCESS",
  CREATE_DEBIT_NOTE: "CREATE_DEBIT_NOTE",
  CREATE_DEBIT_NOTE_SUCCESS: "CREATE_DEBIT_NOTE_SUCCESS",
  GET_CREDIT_ITEMS: "GET_CREDIT_ITEMS",
  GET_CREDIT_ITEMS_SUCCESS: "GET_CREDIT_ITEMS_SUCCESS",
  SET_PAYMENT_HISTORY: "SET_PAYMENT_HISTORY",
  SET_PAYMENT_HISTORY_SUCCESS: "SET_PAYMENT_HISTORY_SUCCESS",
  SET_PAID_ITEMS: "SET_PAID_ITEMS",
  SET_PAID_ITEMS_SUCCESS: "SET_PAID_ITEMS_SUCCESS",
  GET_DEBIT_ITEMS: "GET_DEBIT_ITEMS",
  GET_DEBIT_ITEMS_SUCCESS: "GET_DEBIT_ITEMS_SUCCESS",
  SET_PAID_ITEM_DETAILS: "SET_PAID_ITEM_DETAILS",
  SET_PAID_ITEM_DETAILS_SUCCESS: "SET_PAID_ITEM_DETAILS_SUCCESS",
  SET_PAYMENT_ITEM_DETAILS_DATA: "SET_PAYMENT_ITEM_DETAILS_DATA",
  SET_PAYMENT_ITEM_DETAILS_DATA_SUCCESS:
    "SET_PAYMENT_ITEM_DETAILS_DATA_SUCCESS",
  SET_ENQUIRY_TABLE_DATA: "SET_ENQUIRY_TABLE_DATA",
  SET_ENQUIRY_TABLE_DATA_SUCCESS: "SET_ENQUIRY_TABLE_DATA_SUCCESS",
  GET_DISPUTED_ITEMS: "GET_DISPUTED_ITEMS",
  GET_DISPUTED_ITEMS_SUCCESS: "GET_DISPUTED_ITEMS_SUCCESS",
  GET_MAKE_PAYMENT_TABLE_DATA: "GET_MAKE_PAYMENT_TABLE_DATA",
  GET_MAKE_PAYMENT_TABLE_DATA_SUCCESS: "GET_MAKE_PAYMENT_TABLE_DATA_SUCCESS",
  GET_BANK_DESCRIPTION_DATA: "GET_BANK_DESCRIPTION_DATA",
  GET_BANK_DESCRIPTION_DATA_SUCCESS: "GET_BANK_DESCRIPTION_DATA_SUCCESS",
  SUBMIT_PAYMENT: "SUBMIT_PAYMENT",
  SUBMIT_PAYMENT_SUCCESS: "SUBMIT_PAYMENT_SUCCESS",
  GET_COMMENTS: "GET_COMMENTS",
  GET_COMMENTS_SUCCESS: "GET_COMMENTS_SUCCESS",
  SAVE_COMMENT: "SAVE_COMMENT",
  SAVE_COMMENT_SUCCESS: "SAVE_COMMENT_SUCCESS",
  GET_CREDIT_MANAGEMENT_INFO: "GET_CREDIT_MANAGEMENT_INFO",
  GET_CREDIT_MANAGEMENT_INFO_SUCCESS: "GET_CREDIT_MANAGEMENT_INFO_SUCCESS",
  LOGIN_USER: "LOGIN_USER",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  IS_USER_LOGGED_IN: "IS_USER_LOGGED_IN",
  GET_ADDRESS_LIST: "GET_ADDRESS_LIST",
  GET_ADDRESS_LIST_SUCCESS: "GET_ADDRESS_LIST_SUCCESS",
  SET_CURRENT_DEALER: "SET_CURRENT_DEALER",
  SET_CURRENT_ACCESSIBLE_MENU: "SET_CURRENT_ACCESSIBLE_MENU",
  GET_CURRENT_ACCESSIBLE_MENU: "GET_CURRENT_ACCESSIBLE_MENU",
  GET_SALES_AREA_MAPPING: "GET_SALES_AREA_MAPPING",
  GET_SALES_AREA_MAPPING_SUCCESS: "GET_SALES_AREA_MAPPING_SUCCESS",
  CREATE_INVOICE_DEBIT_NOTE: "CREATE_INVOICE_DEBIT_NOTE",
  SET_ADDRESS_LIST: "SET_ADDRESS_LIST",
  SET_DEALERS_LIST: "SET_DEALERS_LIST",
  SET_CURRENT_ADDRESS_SET: "SET_CURRENT_ADDRESS_SET",
  GET_SAP_ENV_DETAILS: "GET_SAP_ENV_DETAILS",
  GET_SAP_ENV_DETAILS_SUCCESS: "GET_SAP_ENV_DETAILS_SUCCESS",
  VALIDATE_ACCOUNT_NUMBER: "VALIDATE_ACCOUNT_NUMBER",
  VALIDATE_ACCOUNT_NUMBER_SUCCESS: "VALIDATE_ACCOUNT_NUMBER_SUCCESS",
  FETCH_REFERENCE_DATA: "FETCH_REFERENCE_DATA",
  FETCH_REFERENCE_DATA_SUCCESS: "FETCH_REFERENCE_DATA_SUCCESS",
  REGISTER_USER: "REGISTER_USER",
  REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
  GET_USER_DETAILS_WITHOUT_PASSWORD: "GET_USER_DETAILS_WITHOUT_PASSWORD",
  GET_USER_DETAILS_WITHOUT_PASSWORD_SUCCESS: "GET_USER_DETAILS_WITHOUT_PASSWORD_SUCCESS",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_REQUEST_SUCCESS: "RESET_PASSWORD_REQUEST_SUCCESS",
  USER_DETAILS_FROM_URL_ID: "USER_DETAILS_FROM_URL_ID",
  USER_DETAILS_FROM_URL_ID_SUCCESS: "USER_DETAILS_FROM_URL_ID_SUCCESS",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD: "RESET_PASSWORD",
  CHANGE_TRANSLATION: "CHANGE_TRANSLATION",
  CHANGE_TRANSLATION_SUCCESS: "CHANGE_TRANSLATION_SUCCESS",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  BUSINESS_GROUP: "BUSINESS_GROUP",
  BUSINESS_GROUP_SUCCESS: "BUSINESS_GROUP_SUCCESS",
  VALIDATE_DEALER: "VALIDATE_DEALER",
  VALIDATE_DEALER_SUCCESS: "VALIDATE_DEALER_SUCCESS",
  PRICE_CHANGE: "PRICE_CHANGE",
  PRICE_CHANGE_SUCCESS: "PRICE_CHANGE_SUCCESS",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  FETCH_SITESECTION_DETAILS: "FETCH_SITESECTION_DETAILS",
  FETCH_SITESECTION_DETAILS_SUCCESS: "FETCH_SITESECTION_DETAILS_SUCCESS",
  SET_SONY_MENU_ITEMS: "SET_SONY_MENU_ITEMS",
  HOME_PAGE_BUTTONS_CONFIG: "HOME_PAGE_BUTTONS_CONFIG",
  HOME_PAGE_BUTTONS_CONFIG_SUCCESS: "HOME_PAGE_BUTTONS_CONFIG_SUCCESS",
  GET_DEALER_DETAILS: "GET_DEALER_DETAILS",
  GET_DEALER_DETAILS_SUCCESS: "GET_DEALER_DETAILS_SUCCESS",
  GET_ORDER_DETAILS: "GET_ORDER_DETAILS",
  GET_ORDER_DETAILS_SUCCESS: "GET_ORDER_DETAILS_SUCCESS",
  GET_REPORTING_FILTERED_DATA: "GET_REPORTING_FILTERED_DATA",
  GET_REPORTING_FILTERED_DATA_SUCCESS: "GET_REPORTING_FILTERED_DATA_SUCCESS",
  CREATE_NEW_REPORT: "CREATE_NEW_REPORT",
  CREATE_NEW_REPORT_SUCCESS: "CREATE_NEW_REPORT_SUCCESS",
  GET_ALL_REPORTS: "GET_ALL_REPORTS",
  GET_ALL_REPORTS_SUCCESS: "GET_ALL_REPORTS_SUCCESS",
  GET_SELECTED_REPORT_DATA: "GET_SELECTED_REPORT_DATA",
  GET_SELECTED_REPORT_DATA_SUCCESS: "GET_SELECTED_REPORT_DATA_SUCCESS",
  UPDATE_REPORT: "UPDATE_REPORT",
  UPDATE_REPORT_SUCCESS: "UPDATE_REPORT_SUCCESS",
  SEARCH_DATADOWNLOAD_PRODUCT: "SEARCH_DATADOWNLOAD_PRODUCT",
  SEARCH_DATADOWNLOAD_PRODUCT_SUCCESS: "SEARCH_DATADOWNLOAD_PRODUCT_SUCCESS",
  FETCH_DATADOWNLOAD_DATA: "FETCH_DATADOWNLOAD_DATA",
  FETCH_DATADOWNLOAD_DATA_SUCCESS: "FETCH_DATADOWNLOAD_DATA_SUCCESS",
  SELECTED_PRODUCTS: "SELECTED_PRODUCTS",
  DOWNLOAD_DATA_FILE: "DOWNLOAD_DATA_FILE",
  DOWNLOAD_DATA_FILE_SUCCESS: "DOWNLOAD_DATA_FILE_SUCCESS",
  GET_CAPTCHA_VALIDATION: "GET_CAPTCHA_VALIDATION",
  GET_CAPTCHA_VALIDATION_SUCCESS: "GET_CAPTCHA_VALIDATION_SUCCESS",
  GET_LOGIN_CAPTCHA_VALIDATION_SUCCESS: "GET_LOGIN_CAPTCHA_VALIDATION_SUCCESS",
  PRODUCT_VISIBILITY_DATA: "PRODUCT_VISIBILITY_DATA",
  PRODUCT_VISIBILITY_DATA_SUCCESS: "PRODUCT_VISIBILITY_DATA_SUCCESS",
  CHECK_SALES_REP: "CHECK_SALES_REP",
  CHECK_SALES_REP_SUCCESS: "CHECK_SALES_REP_SUCCESS",
  CHECK_CONTACT_EMAIL: "CHECK_CONTACT_EMAIL",
  CHECK_CONTACT_EMAIL_SUCCESS: "CHECK_CONTACT_EMAIL_SUCCESS",
  CHECK_SALES_REP_BY_SALES_ORG: "CHECK_SALES_REP_BY_SALES_ORG",
  CHECK_SALES_REP_BY_SALES_ORG_SUCCESS: "CHECK_SALES_REP_BY_SALES_ORG_SUCCESS",
  CHECK_SALES_REP_DEALER: "CHECK_SALES_REP_DEALER",
  CHECK_SALES_REP_DEALER_SUCCESS: "CHECK_SALES_REP_DEALER_SUCCESS",
  CHECK_SALES_ORG_ACCOUNTS: "CHECK_SALES_ORG_ACCOUNTS",
  CHECK_SALES_ORG_ACCOUNTS_SUCCESS: "CHECK_SALES_ORG_ACCOUNTS_SUCCESS",
  GET_CMS_ARTICLES: "GET_CMS_ARTICLES",
  GET_CMS_ARTICLES_SUCCESS: "GET_CMS_ARTICLES_SUCCESS",
  FETCH_PROD_CATEGORY_RESPONSE: "FETCH_PROD_CATEGORY_RESPONSE",
  FETCH_PROD_CATEGORY_RESPONSE_SUCCESS: "FETCH_PROD_CATEGORY_RESPONSE_SUCCESS",
  GLOBAL_SEARCH_SUGGESTIONS: "GLOBAL_SEARCH_SUGGESTIONS",
  GLOBAL_SEARCH_SUGGESTIONS_SUCCESS: "GLOBAL_SEARCH_SUGGESTIONS_SUCCESS",
  GLOBAL_SEARCH_RESPONSE: "GLOBAL_SEARCH_RESPONSE",
  GLOBAL_SEARCH_RESPONSE_SUCCESS: "GLOBAL_SEARCH_RESPONSE_SUCCESS",
  PRODUCT_CONTENT_DATA:"PRODUCT_CONTENT_DATA",
  PRODUCT_DETAILS_DATA:"PRODUCT_DETAILS_DATA",
  PRODUCT_DETAILS_DATA_SUCCESS:"PRODUCT_CONTENT_DATA_SUCCESS",
  SET_CURRENT_PRODUCT_SKU: "SET_CURRENT_PRODUCT_SKU",
  SET_CURRENT_PROD_CATEGORY:"SET_CURRENT_PROD_CATEGORY",
  GET_TERMS_AND_CONDITION: "GET_TERMS_AND_CONDITION",
  GET_TERMS_AND_CONDITION_SUCCESS: "GET_TERMS_AND_CONDITION_SUCCESS",
  GET_PRICING_INFO_ARTICLES_SUCCESS: "GET_PRICING_INFO_ARTICLES_SUCCESS",
  GET_RELATED_ITEMS_ARTICLES_SUCCESS: "GET_RELATED_ITEMS_ARTICLES_SUCCESS",
  GET_PRICING_INFO_ARTICLES: "GET_PRICING_INFO_ARTICLES",
  GET_RELATED_ITEMS_ARTICLES: "GET_RELATED_ITEMS_ARTICLES",
  GET_QUOTATIONS_DATA: "GET_QUOTATIONS_DATA",
  GET_QUOTATIONS_DATA_SUCCESS: "GET_QUOTATIONS_DATA_SUCCESS",
  GET_CART_PRODUCTS: "GET_CART_PRODUCTS",
  GET_CART_PRODUCTS_SUCCESS: "GET_CART_PRODUCTS_SUCCESS",
  GET_LOGIN_CMS_DATA: "GET_LOGIN_CMS_DATA",
  GET_LOGIN_CMS_DATA_SUCCESS: "GET_LOGIN_CMS_DATA_SUCCESS",
  GET_GRADED_STOCK_DATA: "GET_GRADED_STOCK_DATA",
  GET_GRADED_STOCK_DATA_SUCCESS: "GET_GRADED_STOCK_DATA_SUCCESS",
  SET_PROD_COMPARE_DATA: "SET_PROD_COMPARE_DATA",
  CLEAR_PROD_COMPARE_LIST: "CLEAR_PROD_COMPARE_LIST",
  SET_PRODUCT_DETAILS_DATA:"SET_PRODUCT_DETAILS_DATA",
  GET_ORDER_CHANGE_REPORT: "GET_ORDER_CHANGE_REPORT",
  GET_ORDER_CHANGE_REPORT_SUCCESS: "GET_ORDER_CHANGE_REPORT_SUCCESS",
  GET_ORDER_CHANGE_HISTORY: "GET_ORDER_CHANGE_HISTORY",
  GET_ORDER_CHANGE_HISTORY_SUCCESS: "GET_ORDER_CHANGE_HISTORY_SUCCESS",
  GET_RELATED_TOOLS_ARICLES: "GET_RELATED_TOOLS_ARICLES",
  GET_RELATED_TOOLS_ARICLES_SUCCESS: "GET_RELATED_TOOLS_ARICLES_SUCCESS",
  GET_PRIME_SUPPORT_REGISTRATION: "GET_PRIME_SUPPORT_REGISTRATION",
  GET_PRIME_SUPPORT_REGISTRATION_SUCCESS: "GET_PRIME_SUPPORT_REGISTRATION_SUCCESS",
  SET_SELECTED_PRODUCTS_DOWNLOAD:"SET_SELECTED_PRODUCTS_DOWNLOAD",
  FETCH_LIVE_AVAILABILITY:"FETCH_LIVE_AVAILABILITY",
  FETCH_LIVE_AVAILABILITY_SUCCESS:"FETCH_LIVE_AVAILABILITY_SUCCESS",
  SET_UNAUTH_USER: "SET_UNAUTH_USER",
  SET_CURRENT_DEALER_IN_CONTEXT: "SET_CURRENT_DEALER_IN_CONTEXT",
  GET_CURRENT_DEALER_SUCCESS: "GET_CURRENT_DEALER_SUCCESS",
  START_PRODUCT_FLOW:"START_PRODUCT_FLOW",
  START_PRODUCT_FLOW_SUCCESS:"START_PRODUCT_FLOW_SUCCESS",
  GET_NEW_PRODUCTS:"GET_NEW_PRODUCTS",
  GET_NEW_PRODUCTS_SUCCESS:"GET_NEW_PRODUCTS_SUCCESS",
  GET_CATEGORY_PRODUCT_SUCCESS:"GET_CATEGORY_PRODUCT_SUCCESS",
  FETCH_PRODUCT_CONTENT_DATA:"FETCH_PRODUCT_CONTENT_DATA",
  GET_CONTEXT_DETAILS: "GET_CONTEXT_DETAILS",
  GET_CONTEXT_DETAILS_SUCCESS: "GET_CONTEXT_DETAILS_SUCCESS",
  GET_CALENDAR_DETAILS: "GET_CALENDAR_DETAILS",
  GET_CALENDAR_DETAILS_SUCCESS: "GET_CALENDAR_DETAILS_SUCCESS",
  SFDC_PLACE_ORDER_MY_QUOTATIONS: "SFDC_PLACE_ORDER_MY_QUOTATIONS",
  SFDC_PLACE_ORDER_MY_QUOTATIONS_SUCCESS: "SFDC_PLACE_ORDER_MY_QUOTATIONS_SUCCESS",
  EDIT_ORDER_REPORT: "EDIT_ORDER_REPORT",
  EDIT_ORDER_REPORT_SUCCESS: "EDIT_ORDER_REPORT_SUCCESS",
  GET_PRODUCTS_BY_MATERIAL: "GET_PRODUCTS_BY_MATERIAL",
  GET_PRODUCTS_BY_MATERIAL_SUCCESS: "GET_PRODUCTS_BY_MATERIAL_SUCCESS",
  SEND_PRODUCT_DETAILS_MAIL:"SEND_PRODUCT_DETAILS_MAIL",
  SEND_PRODUCT_DETAILS_MAIL_SUCCESS:" SEND_PRODUCT_DETAILS_MAIL_SUCCESS",
  GET_PREMIUM_SERVICE_DATA: "GET_PREMIUM_SERVICE_DATA",
  GET_PREMIUM_SERVICE_DATA_SUCCESS: "GET_PREMIUM_SERVICE_DATA_SUCCESS",
  GET_CART_PRODUCTS_TOTAL: "GET_CART_PRODUCTS_TOTAL",
  GET_CART_PRODUCTS_TOTAL_SUCCESS: "GET_CART_PRODUCTS_TOTAL_SUCCESS",
  GET_ALL_PRODUCT_LIST: "GET_ALL_PRODUCT_LIST",
  GET_ALL_PRODUCT_LIST_SUCCESS: "GET_ALL_PRODUCT_LIST_SUCCESS",
  GET_QUOTE: "GET_QUOTE",
  GET_QUOTE_SUCCESS: "GET_QUOTE_SUCCESS",
  CALL_LOGOUT: "CALL_LOGOUT",
  GET_PRODUCT_GROUP:"GET_PRODUCT_GROUP",
  GET_PRODUCT_GROUP_SUCCESS:"GET_PRODUCT_GROUP_SUCCESS",
  DELETE_PRODUCT_GROUP:"DELETE_PRODUCT_GROUP",
  DELETE_PRODUCT_GROUP_SUCCESS:"DELETE_PRODUCT_GROUP_SUCCESS",
  UPDATE_PRODUCT_GROUP:"UPDATE_PRODUCT_GROUP",
  UPDATE_PRODUCT_GROUP_SUCCESS:"UPDATE_PRODUCT_GROUP_SUCCESS",
  SAVE_PRODUCT_GROUP:"SAVE_PRODUCT_GROUP",
  SAVE_PRODUCT_GROUP_SUCCESS:"SAVE_PRODUCT_GROUP_SUCCESS",
  CLEAR_CART_ORDER: "CLEAR_CART_ORDER",
  CLEAR_CART_ORDER_SUCCESS: "CLEAR_CART_ORDER_SUCCESS",
  UPDATE_CART_ORDER: "UPDATE_CART_ORDER",
  UPDATE_CART_ORDER_SUCCESS: "UPDATE_CART_ORDER_SUCCESS",
  DELETE_SELECTED_CART_ORDER: "DELETE_SELECTED_CART_ORDER",
  DELETE_SELECTED_CART_ORDER_SUCCESS: "DELETE_SELECTED_CART_ORDER_SUCCESS",
  ADD_TO_CART:"ADD_TO_CART",
  ADD_TO_CART_SUCCESS:"ADD_TO_CART_SUCCESS",
  PLACE_SAP_ORDER: "PLACE_SAP_ORDER",
  PLACE_SAP_ORDER_SUCCESS: "PLACE_SAP_ORDER_SUCCESS",
  GET_MULTI_QUOTE: "GET_MULTI_QUOTE",
  GET_MULTI_QUOTE_SUCCESS: "GET_MULTI_QUOTE_SUCCESS",
  SAVE_QUOTE_REFERENCE_DATA: "SAVE_QUOTE_REFERENCE_DATA",
  SAVE_QUOTE_SPECIAL_INSTRUCTION_DATA: "SAVE_QUOTE_SPECIAL_INSTRUCTION_DATA",
  CART_FILE_UPLOAD: "CART_FILE_UPLOAD",
  CART_FILE_UPLOAD_SUCCESS: "CART_FILE_UPLOAD_SUCCESS",
  SAVE_MULTI_ADDRESSES: "SAVE_MULTI_ADDRESSES",
  SET_SELECTED_SHIP_TO_ACC: "SET_SELECTED_SHIP_TO_ACC",
  GET_PRODUCT_IMAGES: "GET_PRODUCT_IMAGES",
  GET_PRODUCT_IMAGES_SUCCESS: "GET_PRODUCT_IMAGES_SUCCESS",
  SAVE_LOGIN_ERROR: "SAVE_LOGIN_ERROR",
  GET_ACCOUNT_INFO_CMS: "GET_ACCOUNT_INFO_CMS",
  GET_ACCOUNT_INFO_CMS_SUCCESS: "GET_ACCOUNT_INFO_CMS_SUCCESS",
  SAVE_ACCOUNT_DETAILS: "SAVE_ACCOUNT_DETAILS",
};
