import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getDebitNoteReasons, getUserCurrencies } from "../../../state/actions";
import CustomDropDown from "../../atoms/customDropDown";
import styles from "./debit-note-creation.module.scss";
import { DebitNoteForm } from "./debit-note-form";
const API_DATA = require("../../../state/apiData.json");

export const DebitNoteCreation = ({ enquiryCheck, fromPaymentPage, invoiceId }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [reasonVal, setReasonVal] = useState<any>("");

  //loads xmls based on modules
  const debitNoteMetadata = (
    enquiryCheck || fromPaymentPage
  ) ? API_DATA.reasonsCodeMetadata : API_DATA.debitNoteMetadata;
  
  
  const [selectedMetadata, setSelectedMetadata] = useState({});

  //based on selected reason metadata is fetched from xml lot
  const handleChange = (event: any) => {
    let metadata  = debitNoteMetadata?.reasonCode?.filter((x:any) => x.code === event.target.value);
    if(metadata?.length > 0){
      setSelectedMetadata(metadata[0]);
    }
    setReasonVal(event.target.value);
  };
  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );
  const saveAccountDetails = useSelector(
    (state: any) => state?.commonReducer?.saveAccountDetails
  )
  useEffect(() => {
    dispatch(getDebitNoteReasons({}));
    dispatch(getUserCurrencies({
      accountNumber: saveAccountDetails?.payerToAcc?.number,
      companyCode: "GB00"
    }));
  }, []);

  const debitNoteReasons = useSelector(
    (state: any) => state?.gazelleReducer?.debitNoteReasons
  );
  const [enabledReasons, setEnabledReasons] = useState([]);

  const salesAreaMapping = useSelector(
    (state: any) => state?.commonReducer?.salesAreaMapping
  );
  useEffect(() => {
    let enabled : any = [];
    //enable only those reasons which are configured in sales area mapping
    if(debitNoteReasons?.length > 0) {
      salesAreaMapping?.reasons_enabled_debitnote?.split(",")?.forEach((reason:any) => {
        let present = debitNoteReasons.filter((x:any) => x.value === reason);
        if(present?.length > 0)
          enabled.push(present[0])
      });
    }
    setEnabledReasons(enabled);
  },[debitNoteReasons]);

  return (
    <>
      {!enquiryCheck && !fromPaymentPage ? <Typography variant="subtitle1" className={styles.header} pb={3} color="primary">
        {t("hacksite.function.debit_note_creation")}
      </Typography> : null}
      <div className={styles.dropdownContainer}>
        <Box>
          <CustomDropDown
            id="reasons-dropdown"
            value={reasonVal}
            handleChange={handleChange}
            module="debitNoteCreation"
            menuOptions={enabledReasons}
            label={t("gzl.select_reason.text")?.toString()}
          />
        </Box>
        <Tooltip
          title={enquiryCheck ? t("gzl.debit_note_headline1.text")?.toString()?.split(',')[1]
            : fromPaymentPage ? t("gzl.debit_note_headline1.text")?.toString()?.split(',')[1]
              : t("gzl.debit_note_headline1.text")?.toString()}
          placement="right-start"
          arrow
        >
          <IconButton className={styles.infoIcon}>
            <InfoOutlinedIcon color="action" />
          </IconButton>
        </Tooltip>
      </div>
      {reasonVal && <DebitNoteForm reason={reasonVal} 
      setReason={setReasonVal} 
      enquiryCheck={enquiryCheck} 
      fromPaymentPage={fromPaymentPage}
      invoiceId={invoiceId}
      selectedMetadata={selectedMetadata} />}
    </>
  );
};

DebitNoteCreation.defaultProps = {
  enquiryCheck: false,
  fromPaymentPage: false
}
