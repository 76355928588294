import {
  Box,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TableHead,
  TableBody,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
// @ts-ignore
import { useReactToPrint } from "react-to-print";
import { CONTEXT_ORDER } from "../../../constants/commonConstants";
import CustomButton from "../../atoms/customButton";
import { ProdContentDetail } from "../../products/prodContentDetail";
import styles from "./gradedStock.module.scss";
import { getContextDetails, getGradedStockDetails } from "../../../state/actions";
import { ComponentToPrint } from "../../componentToPrint";
var _ = require("lodash");

export const GradedStock = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );

  const gradedStockData = useSelector(
    (state: any) => state?.gradedStockReducer?.gradedStockData
  );

  const contextDetails = useSelector(
    (state: any) => state?.commonReducer?.contextDetails
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  let priceVisible = contextDetails?.priceVisible;
  const [showPriceList, setShowPriceList] = useState(true);
  // let showPriceList = false; // TODO: change accordingly with Context

  const handlePriceListVisibility = (price: any) => {//TODO: call this from ProdContentDetail Component
    setShowPriceList(price);
  };

  useEffect(() => {
    if (
      _.isEmpty(contextDetails) || 
      contextDetails?.key !== CONTEXT_ORDER?.PRODUCT_MODULE_PAGE
    ) {
      dispatch(
        getContextDetails({
          key: CONTEXT_ORDER?.PRODUCT_MODULE_PAGE,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (
      !_.isEmpty(contextDetails) && 
      contextDetails?.key === CONTEXT_ORDER?.PRODUCT_MODULE_PAGE &&
      contextDetails?.isGradedStockEnabled
    ) {
      if (
        (_.isEmpty(gradedStockData) && process.env.NODE_ENV === "development") || 
        process.env.NODE_ENV !== "development"
      ) {
        dispatch(
          getGradedStockDetails({})
        );
      }
    }
  }, [contextDetails]);

  const createTable = () => {
    const tableHeader = [
      { id: "modelType", label: t("dnet.model_type.text") },
      { id: "listPrice", label: t("dnet.list_price.text") },
      { id: "description", label: t("dnet.description.text") },
    ];
    return (
      <div>
        <Typography variant="subtitle2">
          {t("dnet.graded_stock.text")}
        </Typography>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableHeader.map((headCell: any) => (
                  <TableCell key={headCell.id}>{headCell.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {gradedStockData?.length > 0 ? (
                  gradedStockData.map((prod: any, key: any) => (
                    <TableRow key={key}>
                      <TableCell>
                        {prod?.product?.definition?.materialName}
                      </TableCell>
                      {priceVisible && (
                        <TableCell sx={{ minWidth: "100px" }}>
                          {showPriceList && ( //TODO: put a check to show or hide price list
                            <Box>
                              {prod?.product?.price?.currency}{" "}
                              {prod?.product?.price?.price &&
                                parseFloat(
                                  prod?.product?.price?.price?.toString()
                                ).toFixed(2)}
                            </Box>
                          )}
                        </TableCell>
                      )}
                      <TableCell>
                        {prod?.product?.texts[0]?.description}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>{t("gzl.no_data_available.text")}</TableCell>
                  </TableRow>
                )}
              </>
            </TableBody>
          </Table>
        </TableContainer>
        <div>
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: t(
                `dnet.graded_terms_${currentDealer?.bgName}.text`
              ).toString(),
            }}
          />
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: t(
                `dnet.graded_conditions_${currentDealer?.bgName}.text`
              ).toString(),
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <Typography
        variant="subtitle1"
        className={styles.header}
        pb={3}
        color="primary"
      >
        {t("dnet.graded_stock.text")}
      </Typography>
      <Box className={styles.boxContainer}>
        {!contextDetails?.isGradedStockEnabled ? (
          <Typography variant="body1">
            {t("dnet.no_information_available.text")}
          </Typography>
        ) : (
          <div className={styles.gradedStockEnabled}>
            <div className={styles.upperButtonSection}>
              <CustomButton
                id="graded_stock_terms_link"
                label={t("dnet.graded_stock_terms_link.text")}
                onClick={() => {
                  const section = document.getElementById("termsnCondition");
                  if (section) {
                    section.scrollIntoView();
                  }
                }}
                color="primary"
                margin="0"
              />
              <CustomButton
                id="printer_friendly"
                label={t("dnet.printer_friendly.text")}
                onClick={handlePrint}
                margin="0"
              />
              <div style={{ display: "none" }}>
                <ComponentToPrint ref={componentRef} details={createTable()} />
              </div>
            </div>
            <ProdContentDetail
              parent="gradedStock"
              // handlePriceListVisibility={handlePriceListVisibility}
            />
            <div className={styles.termsConsitionBox} id="termsnCondition">
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: t(
                    `dnet.graded_terms_${currentDealer?.bgName}.text`
                  ).toString(),
                }}
              />
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: t(
                    `dnet.graded_conditions_${currentDealer?.bgName}.text`
                  ).toString(),
                }}
              />
            </div>
          </div>
        )}
      </Box>
    </div>
  );
};
