import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Collapse, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./sonyMenu.module.scss";

export const SonyMenu = (props: any) => {
  const { t } = useTranslation();
  const { openMenu, toggleDrawer } = props;

  const navigate = useNavigate();

  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );

  const sonyMenuItems = useSelector(
    (state: any) => state?.commonReducer?.sonyMenuItems
  );

  const [menuItems, setMenuItems] = useState<any>([]);

  useEffect(()=>{
    let currectMenuItems = JSON.parse(JSON.stringify(sonyMenuItems));
    setMenuItems(currectMenuItems)
  },[sonyMenuItems])

  
  const handleExpandClick = (
    selectedIndex: any,
    route: any,
    subMenuLength: any
  ) => {
    if (subMenuLength > 0) {
      setMenuItems(
        menuItems.map((item: any, index: any) =>
          index === selectedIndex ? { ...item, expanded: !item.expanded } : item
        )
      );
    } else {
      navigate(`/${route}`);
    }
  };

  const clickHandler = (route: string) => {
    navigate(`/${route}`);
  };

  const sapEnvDetails = useSelector(
    (state: any) => state?.commonReducer?.sapEnvDetails
  );

  return (
    <div id="gazelle-menu">
      {openMenu && (
        <Drawer anchor={"left"} open={openMenu} onClose={toggleDrawer(false)}>
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onKeyDown={toggleDrawer(false)}
          >
            <Box className={styles.detailsDiv}>
              <div className={styles.label}>{t("dnet.welcome_back.text")} </div>
              <div className={styles.header}>
                {currentDealer?.loggedInUser?.profile?.firstName}{" "}
                {currentDealer?.loggedInUser?.profile?.lastName}
              </div>
              <Box mt={1}>
                <div className={styles.label}>
                  {t("dnet.customer_no.text")}{" "}
                </div>
                <span className={styles.header}>{currentDealer?.accNum} </span>
                <br /> {currentDealer?.accountName}
                <br /> {currentDealer?.street}
                <br /> {currentDealer?.townCity}
                <br /> {currentDealer?.country && currentDealer?.country}
              </Box>
            </Box>
            <Divider sx={{ marginTop: "16px" }} />
            <List>
              {menuItems?.length > 0 && 
                menuItems?.map((menu: any, index: any) => (
                <React.Fragment key={index}>
                  {menu?.display && menu?.menuType === "mainMenu" && (
                    <>
                      <ListItem
                        onClick={toggleDrawer(
                          menu?.subMenu?.length > 0 ? true : false
                        )}
                        disablePadding
                      >
                        <ListItemButton
                          onClick={() =>
                            handleExpandClick(
                              index,
                              menu?.route,
                              menu?.subMenu?.length
                            )
                          }
                          sx={{ paddingTop: "2px", paddingBottom: "2px" }}
                        >
                          <ListItemText
                            primaryTypographyProps={{
                              fontSize: "16px",
                            }}
                            primary={t(menu?.label)}
                          />
                          {menu?.subMenu?.length > 0 &&
                            (menu?.expanded ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            ))}
                        </ListItemButton>
                      </ListItem>
                      <>
                        {menu?.subMenu?.length > 0 && (
                          <Collapse
                            in={menu?.expanded}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List>
                              {menu?.subMenu?.map((ele: any, index: any) => (
                                <React.Fragment key={index}>
                                  {ele?.display && ele?.route && (
                                    <ListItem
                                      disablePadding
                                      sx={{ paddingLeft: "20px" }}
                                      onClick={toggleDrawer(false)}
                                    >
                                      <ListItemButton
                                        onClick={() =>
                                          clickHandler(t(ele?.route))
                                        }
                                        sx={{
                                          paddingTop: "2px",
                                          paddingBottom: "2px",
                                        }}
                                      >
                                        <ListItemText
                                          primaryTypographyProps={{
                                            fontSize: "13px",
                                          }}
                                          primary={t(ele?.label)}
                                        />
                                      </ListItemButton>
                                    </ListItem>
                                  )}
                                </React.Fragment>
                              ))}
                            </List>
                          </Collapse>
                        )}
                      </>
                    </>
                  )}
                </React.Fragment>
              ))}
            </List>
            <div className={styles.footerDiv}>
              {process.env.REACT_APP_ENV !== "prod" && `SAP Env : ${sapEnvDetails?.sapENV}`}
            </div>
          </Box>
        </Drawer>
      )}
    </div>
  );
};
