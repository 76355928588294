import * as React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Box, Typography } from "@mui/material";
import styles from "./customTransferList.module.scss";

function not(a: any, b: any) {
  return a.filter((value: any) => b.indexOf(value) === -1);
}

function intersection(a: any, b: any) {
  return a.filter((value: any) => b.indexOf(value) !== -1);
}

export default function CustomTransferList(props: any) {
  const { leftLabel, rightLabel, leftList, rightList, selectedList } = props;
  const [checked, setChecked] = React.useState<any>([]);
  const [left, setLeft] = React.useState<any>([]);
  const [right, setRight] = React.useState<any>([]);

  React.useEffect(() => {
    setLeft(leftList);
  }, [leftList]);

  React.useEffect(() => {
    setRight(rightList);
  }, [rightList]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: any) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
    selectedList(right.concat(left));
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    selectedList(right.concat(leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    selectedList(not(right, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
    selectedList([]);
  };

  const customList = (items: any) => (
    <Paper sx={{ height: 150, overflow: "auto" }}>
      <List dense component="div" role="list">
        {items.map((value: any) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
              sx={{ padding: "0 4px" }}
            >
              <ListItemIcon sx={{ minWidth: "auto" }}>
                <Checkbox
                  size="small"
                  sx={{ padding: "0 8px" }}
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={4}>
      <Grid item lg={4} md={5} sm={12} xs={12}>
        <Typography variant="body2" pb={1}>
          {leftLabel}
        </Typography>
        {customList(left)}
      </Grid>
      <Grid
        item
        lg={1}
        md={1}
        sm={12}
        xs={12}
        className={styles.buttonContainer}
      >
        <Box className={styles.buttonStyle}>
          <Button
            sx={{ my: 0.5, padding: 0 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            <KeyboardDoubleArrowRightIcon />
          </Button>
          <Button
            sx={{ my: 0.5, padding: 0 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            <KeyboardArrowRightIcon />
          </Button>
          <Button
            sx={{ my: 0.5, padding: 0 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            <KeyboardArrowLeftIcon />
          </Button>
          <Button
            sx={{ my: 0.5, padding: 0 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            <KeyboardDoubleArrowLeftIcon />
          </Button>
        </Box>
        {/* </Grid> */}
      </Grid>
      <Grid item lg={4} md={5} sm={12} xs={12}>
        <Typography variant="body2" pb={1}>
          {rightLabel}
        </Typography>
        {customList(right)}
      </Grid>
    </Grid>
  );
}
