import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getStatementData } from "../../../state/actions";
import CustomButton from "../../atoms/customButton";
import CustomTextField from "../../atoms/customTextField";
import logger from "../../helper/logger";
import { StatementsTable } from "./statements-table";
import styles from "./statements.module.scss";
var _ = require("lodash");

export const Statements = () => {
  const { t } = useTranslation();
  const [dateValue, setDateValue] = React.useState(new Date());
  const dispatch = useDispatch();
  const salesAreaMapping = useSelector(
    (state: any) => state?.commonReducer?.salesAreaMapping
  );
  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.userDetails
  );

  const saveAccountDetails = useSelector(
    (state: any) => state?.commonReducer?.saveAccountDetails
  )

  //on load of screen, fetches the statement for current date
  React.useEffect(() => {
    dispatch(
      getStatementData({
        keyDate: moment(dateValue).format("YYYYMMDD"),
        accountId: saveAccountDetails?.payerToAcc?.number,
        // companyCode: userDetails?.companyCode,
        companyCode: "GB00",
        doctypeEnabled: salesAreaMapping?.doctypes_enabled,
        docTypes: salesAreaMapping?.billing_doctypes,
      })
    );
  }, []);

  // on date change an event is triggered to fetch statements based on selected date
  const handleDateChange = (newDateVal: any) => {
    logger.info("Selected date ",newDateVal);
    setDateValue(newDateVal);
    dispatch(
      getStatementData({
        keyDate: moment(new Date(newDateVal?.$d)).format("YYYYMMDD"),
        accountId: saveAccountDetails?.payerToAcc?.number,
        // companyCode: userDetails?.companyCode,
        companyCode: "GB00",
        doctypeEnabled: salesAreaMapping?.doctypes_enabled,
        docTypes: salesAreaMapping?.billing_doctypes,
      })
    );
  };
  const statementData = useSelector(
    (state: any) => state?.gazelleReducer?.statementData
  );
  const [csvData, setCsvData] = useState([]);
  const [readyToDownload, setReadyToDownload] = useState(false);

  //forms CSV data from loaded statements
  const downloadCSV = () => {
    logger.info("Download Statements CSV");
    const csvData: any = [
      [
        t("gzl.st_date.text"),
        t("gzl.due_date.text"),
        t("gzl.invoice_no.text"),
        t("gzl.reference.text"),
        t("gzl.delivery.text"),
        t("gzl.type.text"),
        t("gzl.st_outstanding_balance.text"),
        t("gzl.currency.text"),
      ],
      ...statementData?.statementList?.map((item: any) => [
        item?.date,
        item?.dueDate,
        item?.invoiceNo,
        item?.referenceNo,
        item?.deliveryNo,
        item?.type,
        item?.amount,
        statementData?.currencyType,
      ]),
      [t("gzl.total_balance.text") , statementData?.statementAmount,statementData?.currencyType]
    ];
    setCsvData(csvData);
    setReadyToDownload(true);
  };
  let csvLink: any = React.createRef();
  //Triggers csv download
  useEffect(() => {
    if (!_.isEmpty(csvData) && readyToDownload) {
      csvLink.current.link.click();
      setReadyToDownload(false);
    }
  }, [readyToDownload]);

  return (
    <>
      <Typography variant="subtitle1" className={styles.header} pb={3} color="primary">
        {t("hacksite.function.statement")}
      </Typography>
      <Box>
        <Box className={styles.boxContainer}>
          <CustomTextField
            label={t("gzl.balance_conf_date.text")}
            value={moment(new Date()).format("DD.MM.YYYY")}
            disabled = {true}
          />
          <CustomTextField
            label={t("gzl.current_date.text")}
            value={moment(new Date()).format("DD.MM.YYYY")}
            disabled = {true}
          />
          <div className={styles.datePickerDiv}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disableFuture
                label={t("gzl.statement_date.text")}
                openTo="day"
                views={["year", "month", "day"]}
                value={dateValue}
                onChange={(newValue: any) => {
                  handleDateChange(newValue);
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
                className={styles.datePicker}
                inputFormat="DD.MM.YYYY"
                minDate={new Date().setFullYear(new Date().getFullYear() - 1)}
              />
            </LocalizationProvider>
            <span>
              <Tooltip title={t("gzl.calendar_info.text")} arrow>
                <IconButton className={styles.infoIcon}>
                  <InfoOutlinedIcon color="action" />
                </IconButton>
              </Tooltip>
            </span>
          </div>
        </Box>
        <Box className={styles.tableContainer}>
          <div className={styles.tableHeader}>
            <Typography variant="body2">
              {t("gzl.statement_explanation.text")}
            </Typography>
            <CSVLink
              data={csvData}
              separator={`;`}
              enclosingCharacter={``}
              filename={t("gzl.csv_statements.text")?.toString()}
              className={styles.hidden}
              ref={csvLink}
            ></CSVLink>
            <CustomButton
              label={t("gzl.download_csv.text")}
              onClick={downloadCSV}
              color="primary"
            />
          </div>
          <StatementsTable />
        </Box>
      </Box>
    </>
  );
};
