import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import SearchIcon from "@mui/icons-material/Search";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import {
  Box,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  CONTEXT_ORDER,
  PRODUCT_STATUS,
  PUBLICATION,
  TOAST_SEVERITY,
} from "../../../constants/commonConstants";
import CustomButton from "../../atoms/customButton";
import CustomTextField from "../../atoms/customTextField";
import {
  getComparator,
  stableSort,
  getAvailabilityDetails,
  fetchWarrantyProducts,
  plus2,
  minus2,
} from "../../helper/util";
import styles from "./prodContentDetail.module.scss";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import { ROUTES_LABEL } from "../../../constants/routesLabels";
import CustomDropDown from "../../atoms/customDropDown";
import {
  setCurrentProductSku,
  setProductContentData,
  fetchProductDetailsData,
  setProdCompareData,
  clearProdCompareList,
  setOpenToast,
  setSelectedProductsDownload,
  fetchLiveAvailability,
  fetchLiveAvailabilitySuccess,
  getContextDetails,
  addToCart,
  addToCartSuccess,
  getProductsByMaterialNameSuccess,
  fetchProductDetailsDataSuccess,
  getProductImagesSuccess,
} from "../../../state/actions";

var currencyFormatter = require("currency-formatter");
const exclamation = require("../../../assets/images/exclamation.gif");
const alertstar = require("../../../assets/images/alertstar_002.gif");
const csvImage = require("../../../assets/images/disccsv.gif");
const xmlImage = require("../../../assets/images/discxml.gif");
const uploadImage = require("../../../assets/images/discload.gif");
var _ = require("lodash");
const DELIVERY_DATE_PADDING_VALUE = 60;
const SALES_STATUS_PRE_SALES = "08";

export const ProdContentDetail = ({
  parent,
  saveHandler,
  deleteHandler,
  orderEntryMultiship,
  handleOpenFileModal,
  accessories,
  searchResult,
  productGroup,
  deleteProductHandler,
  updateHandler,
  updateProductGroup,
  productGroupChanged,
  setProductGroupChanged,
}: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categoryNames = useParams()["*"];

  useEffect(() => {
    setProdContentData([]);
    if(parent !== "accessories" && parent !== "productTabs"){
      dispatch(
      fetchProductDetailsDataSuccess({
        response: [],
        compare: false,
      })
    );
  }
    if(parent !== "gradedStock")
    {
      dispatch(
      getContextDetails({
        key: CONTEXT_ORDER?.PRODUCT_MODULE_PAGE,
      })
    );
    }
  }, []);

  const contextDetails = useSelector(
    (state: any) => state?.commonReducer?.contextDetails
  );

  const [tableHeaderData, setTableHeaderData] = useState([
    { id: "product.definition.materialName", label: t("dnet.model.text") },
    { id: "orderSearch", label: t("dnet.find_open_orders.text") },
    { id: "qty", label: t("dnet.qty.text") },
    {
      id: "product.availabilityMap.NOFG.status",
      label: t("dnet.availability.text"),
    },
    { id: "product.price.price", label: t("dnet.list.text") },
    { id: "description", label: t("dnet.description.text") },
  ]);
  useEffect(() => {
    if (parent === "productLists") {
      let changeTableHeaderData = [...tableHeaderData];
      changeTableHeaderData.unshift({ id: "select", label: "select" });
      setTableHeaderData(changeTableHeaderData);
    }
    if (parent === "orderEntry") {
      let changeTableHeaderData = [
        { id: "select", label: "select" },
        { id: "product.definition.materialName", label: t("dnet.model.text") },
        { id: "orderSearch", label: t("dnet.find_open_orders.text") },
        { id: "countryoforigin", label: t("dnet.countryoforigin.short.text") },
        {
          id: "customerMaterialNumber",
          label: t("customerMaterialNumber.text"),
        },
        { id: "qty", label: t("dnet.qty.text") },
        { id: "requsestedDate", label: t("dnet.req_date.text") },
        {
          id: "product.availabilityMap.NOFG.status",
          label: t("dnet.availability.text"),
        },
        { id: "product.price.price", label: t("dnet.list.text") },
        { id: "description", label: t("dnet.description.text") },
        { id: "specialPrice", label: t("dnet.special_price.text") },
      ];
      setTableHeaderData(changeTableHeaderData);
    }
  }, [parent]);
  type Order = "asc" | "desc";
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>(
    parent!== "globalSearch"? "product.definition.materialName" : ""
  );
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      if (
        property === "product.definition.materialName" ||
        property === "product.availabilityMap.NOFG.status" ||
        property === "product.price.price"
      ) {
        handleRequestSort(event, property);
      }
    };

  const [isInternalAdmin, setInternalAdmin] = React.useState<any>(false);


  const userType = useSelector(
    (state: any) => state?.commonReducer?.currentDealer?.accountFlag
 );
  useEffect(() => {
    if (userType == 0) {
      setInternalAdmin(true);
    }
  }, [userType]);

  //newly added fields based on new response
  const warranties = [];
  const errorMessage = "";

  const getIndexOfProduct = (materialName: any) => {
    let prods = [...prodContentData];

    return prods.findIndex(
      (product) => product?.product?.definition?.materialName === materialName
    );
  };

  const productContentData = useSelector(
    (state: any) => state?.productReducer?.productContentData
  );

  const gradedStockData = useSelector(
    (state: any) => state?.gradedStockReducer?.gradedStockData
  );

  const currentProductSku = useSelector(
    (state: any) => state?.productReducer?.currentProductSku
  );
  const [showCompare, setShowCompare] = useState(false);
  const [selectAllCheckBox, setSelectAllCheckBox] = useState(false);

  const deleteProdContentHandler = () => {
    let productDeleteList = prodContentData.filter(
      (product: any) =>
        !selectedProducts?.some(
          (selected: any) =>
            selected?.prodData?.product?.definition?.materialName ===
            product?.product?.definition?.materialName
        )
    );
    deleteProductHandler(productDeleteList);
    setSelectedProducts([]);
  };

  useEffect(() => {
  if (parent === "gradedStock") {
      // if(!_.isEmpty(gradedStockData)){
      //   dispatch(
      //     getContextDetails({
      //       key: CONTEXT_ORDER?.PRODUCT_MODULE_PAGE,
      //     })
      //   );
      // }
      let prodList: any = [];
      let availableDetailsCount = 0;
      gradedStockData?.forEach((prod: any) => {
        prodList.push(prod);
        if (prod?.product?.index?.uri) {
          availableDetailsCount++;
        }
      });
      if (availableDetailsCount >= 2) setShowCompare(true);
      else setShowCompare(false);
      setProdContentData(fetchWarrantyProducts(prodList));
    } else if (parent === "productLists") {
      if(productGroupChanged)
      {
      setProductGroupChanged(false);
      setSelectedProducts([]);
      setSelectAllCheckBox(false);
      }
      setShowCompare(false);
      setProdContentData(fetchWarrantyProducts(productGroup));
    } else if (parent === "accessories" ) {
      let availableDetailsCount = 0;
      !_.isEmpty(accessories) && accessories?.forEach((prod: any) => {
        if (prod?.product?.index?.uri) {
          availableDetailsCount++;
        }
      });
      if (availableDetailsCount >= 2) setShowCompare(true);
      else setShowCompare(false);
      setProdContentData(fetchWarrantyProducts(accessories));
    } else if (parent === "productTabs" ) {
      let availableDetailsCount = 0;
      if (currentProductSku?.product?.warranties) {
        currentProductSku?.product?.warranties?.forEach((prod: any) => {
          if (prod?.product?.index?.uri) {
            availableDetailsCount++;
          }
        });
      }
      if (availableDetailsCount >= 2) setShowCompare(true);
      else setShowCompare(false);
      setProdContentData(currentProductSku?.product?.warranties);
    } else if (parent === "globalSearch") {
      let availableDetailsCount = 0;
      const updatedProduct = searchResult?.map(
        (product: any) => Object.values(product)[0]
      );
      updatedProduct?.forEach((prod: any) => {
        if (prod?.product?.index?.uri) {
          availableDetailsCount++;
        }
      });
      if (availableDetailsCount >= 2) setShowCompare(true);
      else setShowCompare(false);
      setProdContentData(fetchWarrantyProducts(updatedProduct));
    } else if (!_.isEmpty(productContentData)) {
      let prodList: any = [];
      let availableDetailsCount = 0;
      productContentData?.prodList?.forEach((prod: any) => {
        prodList.push(prod);
        if (prod?.product?.index?.uri) {
          availableDetailsCount++;
        }
        //}
      });
      prodList = prodList?.map((prod: any) => {
        return (prod = {
          ...prod,
          qty: "",
          quantityMessageTarget: "",
          otherTarget: "",
          toolTipLive: "",
          liveAvbText: "",
          activeAvailabilityIcon: "plus",
          colorCode: "",
        });
      });
      if (availableDetailsCount >= 2) setShowCompare(true);
      else setShowCompare(false);
      setProdContentData(fetchWarrantyProducts(prodList));
    }
  }, [productContentData, gradedStockData,accessories,currentProductSku,searchResult,productGroup]);

  useEffect(() => {
    if (isInternalAdmin) {
      let changeTableHeaderData = [...tableHeaderData];
      changeTableHeaderData = changeTableHeaderData.filter((header: any) => {
        return (
          header?.id !== "orderSearch" &&
          header?.id !== "product.availabilityMap.NOFG.status" &&
          header.id !== "product.price.price" &&
          header.id !== "qty"
        );
      });
      setTableHeaderData(changeTableHeaderData);
    }
  }, [isInternalAdmin]);

  // const [prodContentData, setProdContentData] = useState<any>(data);
  const [prodContentData, setProdContentData] = useState<any>();
  const [showPriceList, setShowPriceList] = useState(true);
  const productListItems = [
    {
      category: "New Personal List",
      list: [
        {
          value: "NP1",
          label: "New Personal List",
          noOfProducts: 0,
        },
      ],
    },
    {
      category: "Product List",
      list: [
        {
          value: "PL2",
          label: "JIT75 July",
          noOfProducts: 3,
        },
        {
          value: "PL3",
          label: "Cather",
          noOfProducts: 1,
        },
      ],
    },
  ];

  const [selectedProductList, setSelectedProductList] = useState("");

  const priceVisible = contextDetails?.priceVisible; 
  const isSpecialPriceEnabled = contextDetails?.isSpecialPriceEnabled;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const validateQuantity = (
    minDeliveryUnit: any,
    deliveryUnit: any,
    quantity: any,
    minQuantity: any
  ) => {
    let exception: any = null;
    let value: any = parseInt(quantity);
    if (value > 0) {
      let maxQuantity: any = Math.max(
        parseInt(minDeliveryUnit) || 1,
        parseInt(minQuantity) || 1
      );
      let diff: any = value - maxQuantity;

      if (diff < 0) {
        let invalidMsg: any = t("dnet.not_multiple_of_mdq.text")?.toString();
        invalidMsg = invalidMsg.replace("{0}", minQuantity);
        invalidMsg = invalidMsg.replace("{1}", deliveryUnit);
        exception = invalidMsg;
      } else {
        if (diff % deliveryUnit != 0) {

          let invalidMsg: any = t("dnet.not_multiple_of_mdq.text")?.toString();
          invalidMsg = invalidMsg.replace("{0}", minQuantity);
          invalidMsg = invalidMsg.replace("{1}", deliveryUnit);
          exception = invalidMsg;
        }
      }
    } else {
      exception = t("dnet.invalid_quantity.text");
    }

    let limitOrderQuantity: any = contextDetails?.limitOrderQuantity;

    if (
      limitOrderQuantity != undefined &&
      limitOrderQuantity.enabled &&
      value > limitOrderQuantity.maxQuantity
    ) {
      let invalidLimitMsg: any = t("dnet.invalid_limit_order_quantity.text");
      invalidLimitMsg = invalidLimitMsg.replace(
        "{0}",
        limitOrderQuantity.maxQuantity
      );
      exception = invalidLimitMsg;
    }

    return exception;
  };

  const [currentProductData, setCurrentProductData] = useState<any>();

  const handleAvailabilityClick = (type: any, product: any, index: any) => {
    let prods = [...prodContentData];
    let productIdx: any = null;
    index = String(index);

    if (index.indexOf("_") != -1) {
      //it is warranty product
      let warrantyIndex = index.substring(0, index.indexOf("_"));

      let productIndex = index.substring(index.indexOf("_") + 1, index.length);

      productIdx = prods[productIndex]?.product?.warranties[warrantyIndex];
    } else {
      productIdx = prods[index];
    }

    if (type === "plus") {
      let minQuantity: any = productIdx?.product?.detail?.minOrderQuantity;
      let minDelQuantity: any =
        productIdx?.product?.detail?.minDeliveryQuantity;
      let delUnit: any = productIdx?.product?.detail?.deliveryUnit;
      let quantity: any = productIdx?.qty;

      minQuantity = parseInt(minQuantity) || 1 ;
      minDelQuantity= parseInt(minDelQuantity) || 1;
      delUnit = parseInt(delUnit) || 1;

      minQuantity = minQuantity === 0 ? 1 : minQuantity;
      minDelQuantity = minDelQuantity === 0 ? 1 : minDelQuantity;
      delUnit = delUnit === 0 ? 1 : delUnit;
      let setMinOrderQuantity: any = false;

      if (
        quantity === undefined ||
        quantity === 0 ||
        quantity === "" ||
        Number.isNaN(quantity)
      ) {
        quantity = Math.max(
          parseInt(minQuantity) || 1,
          parseInt(minDelQuantity) || 1
        );
        setMinOrderQuantity = true;
      }

      let exception: any = null;

      exception = validateQuantity(
        minDelQuantity,
        delUnit,
        quantity,
        minDelQuantity
      );

      if (exception == null) {
        let SalesArea: any = product?.salesArea;
        let ProductHierarchyCode: any =
          product?.product?.detail?.thirdLevelHierarchyCode +
          product?.product?.detail?.productHierarchyCode;

        let request: any = {
          productHierarchyCode: ProductHierarchyCode,
          productName: product?.product?.definition?.materialName,
          quantity: quantity,
        };

        let ProductData: any = {
          quantity: quantity,
          index: index,
          setMinOrderQuantity: setMinOrderQuantity,
          product: product,
        };
        setCurrentProductData(ProductData);
        dispatch(fetchLiveAvailabilitySuccess({}));
        dispatch(fetchLiveAvailability(request));
      } else {
        productIdx.liveAvbText = exception;
      }
      productIdx.activeAvailabilityIcon = "minus";
    } else {
      productIdx.quantityMessageTarget = "";
      productIdx.otherTarget = "";
      productIdx.toolTipLive = "";
      productIdx.liveAvbText = "";
      productIdx.activeAvailabilityIcon = "plus";
    }
    setProdContentData(prods);
  };

  const liveAvailability = useSelector(
    (state: any) => state?.productReducer?.liveAvailability
  );

  useEffect(() => {
    if (
      currentProductData &&
      liveAvailability != null &&
      liveAvailability != undefined && !_.isEmpty(liveAvailability)
    ) {
      getLiveAvailabilityDataProduct(
        liveAvailability,
        currentProductData?.quantity,
        currentProductData?.setMinOrderQuantity,
        currentProductData?.index
      );
    }
  }, [currentProductData,liveAvailability]);

  const getLiveAvailabilityDataProduct = (
    response: any,
    quantity: any,
    setMinOrderQuantity: any,
    index: any
  ) => {
    let prods = [...prodContentData];
    let productIdx: any = null;
    index = String(index);

    if (index.indexOf("_") != -1) {
      //it is warranty product
      let warrantyIndex = index.substring(0, index.indexOf("_"));
      let productIndex = index.substring(index.indexOf("_") + 1, index.length);

      productIdx = prods[productIndex]?.product?.warranties[warrantyIndex];
    } else {
      productIdx = prods[index];
    }

    if (response != null && response != undefined && response?.liveAvailability) {
      let priceAmount = response?.liveAvailability?.price?.amount;
      let price = response?.liveAvailability?.price?.currency;
      let currency = response?.liveAvailability?.price?.currency ? response?.liveAvailability?.price?.currency : "";
      let date = isDeliveryDateDisplayable(
        productIdx?.product?.detail?.salesStatus,
        response?.liveAvailability
      )
        ? response?.liveAvailability?.date
        : "";
      let confirmedQuantity =
      response?.liveAvailability?.quantity > 0 ? response?.liveAvailability?.quantity : null;
      let newPrice: any = currency + " " + priceAmount;
      let isPriceVisible: any = contextDetails?.priceVisible;
      let centralStockCheckStatus: any = response?.centralStockCheckStatus;
      if (isPriceVisible) {
        productIdx.otherTarget =
          t("dnet.netprice.text")?.toString() + " " + newPrice;
      }
      if (setMinOrderQuantity) {
        let liveAvbPriceText: any = t(
          "dnet.live_avb_price_calc.text"
        )?.toString();
        liveAvbPriceText = liveAvbPriceText.replace("{0}", quantity);
        productIdx.quantityMessageTarget = liveAvbPriceText;
      } else {
        productIdx.quantityMessageTarget = "";
      }

      if (date != "") {
        if (setMinOrderQuantity) {
          //On Hover
          productIdx.toolTipLive = t("dnet.live_avb_moq.text");
        } else if (quantity > confirmedQuantity) {
          let liveAvbExp: any = t("dnet.live_avb_explain.text")?.toString();
          liveAvbExp = liveAvbExp.replace("{0}", confirmedQuantity);

          //On Hover
          productIdx.toolTipLive = liveAvbExp;
        }

        productIdx.liveAvbText =
          t("dnet.planned_date.text").toString() + " " + date;
      } else {
        if (centralStockCheckStatus == "active") {
          productIdx.liveAvbText = t(
            "dnet.centralstock_overnight.text"
          ).toString();
        } else if (setMinOrderQuantity) {
          productIdx.toolTipLive = t("dnet.live_avb_moq.text").toString();

          productIdx.liveAvbText = t("dnet.unconfirmed.text").toString();
        } else {
          productIdx.liveAvbText = t("dnet.unconfirmed.text").toString();
        }
      }
    }
    else if(response?.message !== undefined){
      productIdx.liveAvbText = response?.message;
    }

    setProdContentData(prods);
  };

  const isDeliveryDateDisplayable = (salesStatus: any, liveResponse: any) => {
    if (liveResponse?.quantity === 0) return false;
    else if (salesStatus != null || salesStatus != undefined) {
      if (
        salesStatus === SALES_STATUS_PRE_SALES &&
        getDayDiff(new Date(), new Date(liveResponse?.date)) >
          DELIVERY_DATE_PADDING_VALUE
      ) {
        return false;
      }
    }
    return true;
  };

  function getDayDiff(startDate: Date, endDate: Date): number {
    const msInDay = 24 * 60 * 60 * 1000;

    return Math.round(Math.abs(Number(endDate) - Number(startDate)) / msInDay);
  }

  const getAvailabilityData: any = (product: any, index: any) => {
    let details = getAvailabilityDetails(product);
    let colorCode: any = details?.colorCode;
    let titleText: any = t(details?.titleText);
    let availabilityMessage: any = t(details?.availabilityMessage);

    return (
      <Box>
        <Box>
          <CircleIcon
            className={styles.availabilityIcon}
            sx={{ color: colorCode[0] }}
            titleAccess={titleText}
          />
          <CircleIcon
            className={styles.availabilityIcon}
            sx={{ color: colorCode[1] }}
            titleAccess={titleText}
          />
          <CircleIcon
            className={styles.availabilityIcon}
            sx={{ color: colorCode[2] }}
            titleAccess={titleText}
          />
        </Box>
          <Box className={styles.availabilityDiv}>
            <span className={styles.messageStyle}>{availabilityMessage}</span>
            {parent !== "productLists" && (product?.activeAvailabilityIcon === "minus" ? (
              <IconButton
                sx={{ paddingTop: "0", paddingBottom: "0" }}
                title={t("dnet.live_avb_click.text")?.toString()}
                onClick={(e: any) =>
                  handleAvailabilityClick("minus", product, index)
                }
              >
                <IndeterminateCheckBoxOutlinedIcon />
              </IconButton>
            ) : (
              <IconButton
                sx={{ paddingTop: "0", paddingBottom: "0" }}
                title={t("dnet.live_avb_click.text")?.toString()}
                onClick={(e: any) =>
                  handleAvailabilityClick("plus", product, index)
                }
              >
                <AddBoxOutlinedIcon />
              </IconButton>
            ))}
          </Box>
      </Box>
    );
  };

  const redirectToDetails = (product: any) => {
    dispatch(fetchProductDetailsData({ productNames: product?.product?.index?.materialName }));
    dispatch(setCurrentProductSku(product));
    dispatch(getProductImagesSuccess({}));
    categoryNames? navigate(`/${ROUTES_LABEL.PRODUCT_DETAILS}/${categoryNames}`): navigate(`/${ROUTES_LABEL.PRODUCT_DETAILS}`);
  };

  const redirectToOrdersPage = (product: any, navigateTo: any) => {
    if (navigateTo === "orderSearch") {
      navigate(`/${ROUTES_LABEL.REPORTING}`, {
        state: {
          selectedModel: product?.product?.definition?.materialName,
        },
      });
    } else {
      navigate(`/${ROUTES_LABEL.SONY_HOMEPAGE}`);
    }
  };

  const updateQuantity = (index: any, e: any, product: any) => {
    let prods = [...prodContentData];
    let productIdx: any = null;
    index = String(index);

    if (index.indexOf("_") != -1) {
      //it is warranty product
      let warrantyIndex = index.substring(0, index.indexOf("_"));
      let productIndex = index.substring(index.indexOf("_") + 1, index.length);

      productIdx = prods[productIndex]?.product?.warranties[warrantyIndex];
    } else {
      productIdx = prods[index];
    }
    if (!isNaN(parseInt(e.target.value))) {
      if (parseInt(e.target.value) < 0) {
        productIdx.qty = 0;
      } else {
        productIdx.qty = parseInt(e?.target?.value);
      }
    } else {
      productIdx.qty = 0;
    }
    setProdContentData(prods);
    if(parent === "productLists")
    {
      updateProductGroup(prods);
    }
  };

  const handleQuantityChange = (index: any, type: any, product: any) => {
    let prods = [...prodContentData];
    let productIdx: any = null;
    index = String(index);

    if (index.indexOf("_") != -1) {
      //it is warranty product
      let warrantyIndex = index.substring(0, index.indexOf("_"));
      let productIndex = index.substring(index.indexOf("_") + 1, index.length);

      productIdx = prods[productIndex]?.product?.warranties[warrantyIndex];
    } else {
      productIdx = prods[index];
    }

    let minQuantity: any = productIdx?.product?.detail?.minOrderQuantity;
    let minDelQuantity: any =
      productIdx?.product?.detail?.minDeliveryQuantity;
    let delUnit: any = productIdx?.product?.detail?.deliveryUnit;

    minQuantity = parseInt(minQuantity) || 1 ;
    minDelQuantity= parseInt(minDelQuantity) || 1;
    delUnit = parseInt(delUnit) || 1;

    minQuantity = minQuantity === 0 ? 1 : minQuantity;
    minDelQuantity = minDelQuantity === 0 ? 1 : minDelQuantity;
    delUnit = delUnit === 0 ? 1 : delUnit;
    

    if (type === "increment") {
     if (
        productIdx.qty === undefined ||
        productIdx.qty === "" ||
        Number.isNaN(productIdx.qty)
      ) {
        productIdx.qty = plus2(0,minQuantity,delUnit,minDelQuantity);
      } else {
        productIdx.qty = plus2(parseInt(productIdx.qty),minQuantity,delUnit,minDelQuantity);
      }
    } else {
      if (
        productIdx.qty === undefined ||
        Number.isNaN(productIdx.qty) ||
        productIdx.qty === "" ||
        productIdx.qty === "0" ||
        productIdx.qty === 0
      ) {
        productIdx.qty = minus2(0,minQuantity,delUnit,minDelQuantity);
      } else {
        productIdx.qty =minus2(parseInt(productIdx.qty),minQuantity,delUnit,minDelQuantity);
      }
    }
    setProdContentData(prods);
    if(parent === "productLists")
    {
      updateProductGroup(prods);
    }
    
  };
  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );

  const addToCartResponse = useSelector(
    (state: any) => state?.cartReducer?.addToCartResponse
  );
  const shoppingContextToken = `${currentDealer?.accNum}_${currentDealer?.bgName}_${currentDealer?.loggedInUser?.username}_${currentDealer?.loggedInUser?.profile?.email}`;

  const cartShipToOptionsSelections = useSelector(
    (state: any) => state?.cartReducer?.cartShipToOptionsSelections
  );

  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.loginResponse?.data?.userDetails
  );

  const redirectToOrderEntry = (product: any, index: any) => {
    let prod = {
      index,
      prodData: product,
      prodDetails: {},
      availabilityDetails: {},
    };

    let cart = {
      shipToAccountId: cartShipToOptionsSelections?.[shoppingContextToken]?.selectedShipToAccount ?? [""],
      lineItems: [
        {
          productSku: {
            name: product?.product?.definition?.materialName,
          },
          subLineItems: [
            {
                quantity: product?.qty,
                requestedDeliveryDate: cartShipToOptionsSelections?.[shoppingContextToken]?.deliveryDate ?? null,
            }],
          specialPrice: "",
          quantity: product?.qty,
          quantityTotal: 0,
          isGradedStock: parent === "gradedStock"? true: false,
          availabilityMap:  parent === "gradedStock"? product?.product?.availabilityMap: "",
          index:  parent === "gradedStock"? product?.product?.index: "",
          texts:  parent === "gradedStock"? product?.product?.texts: "",
          detail:  parent === "gradedStock"? product?.product ?.detail: "",
          price:  parent === "gradedStock"? `${product?.product?.price?.currency} ${product?.product?.price?.price}`: "",
          isWarranty: parent === "gradedStock"? (!_.isEmpty(product?.product?.warranties) ? true: false): "",
          warranties: parent === "gradedStock"? (!_.isEmpty(product?.product?.warranties) ? product?.product?.warranties: ""): "",
        },
      ],
      isMultiShipTo: cartShipToOptionsSelections?.[shoppingContextToken]?.multiShipTo ?? false,
      isMultiRequestedDeliveryDate: cartShipToOptionsSelections?.[shoppingContextToken]?.multiRDD ?? false,
      requestedDeliveryDate: cartShipToOptionsSelections?.[shoppingContextToken]?.deliveryDate ?? null,
      spToken: shoppingContextToken,
      key: 2,
      isPartialDelivery: cartShipToOptionsSelections?.[shoppingContextToken]?.partialDelivery === false ? false : true,
      isOrderEntryPage: false,
      usernameOrEmail: userDetails?.username,
    };

    let prods = [...prodContentData];
    let productIdx: any = null;
    index = String(index);

    if (index.indexOf("_") != -1) {
      //it is warranty product
      let warrantyIndex = index.substring(0, index.indexOf("_"));
      let productIndex = index.substring(index.indexOf("_") + 1, index.length);

      productIdx = prods[productIndex]?.product?.warranties[warrantyIndex];
    } else {
      productIdx = prods[index];
    }
    let limitOrderQuantity: any = contextDetails?.limitOrderQuantity;

    if (
      limitOrderQuantity != undefined &&
      limitOrderQuantity.enabled &&
      productIdx.qty > limitOrderQuantity.maxQuantity
    ) {
      let invalidLimitMsg: any = t("dnet.invalid_limit_order_quantity.text");
      invalidLimitMsg = invalidLimitMsg.replace(
        "{0}",
        limitOrderQuantity.maxQuantity
      );
      productIdx.quantityMessageTarget = invalidLimitMsg;
      setProdContentData(prods);
    } else {
      dispatch(addToCart(cart));
     // navigate(`/${ROUTES_LABEL.ORDER_ENTRY}`);
    }
  };

  useEffect(()=>{
    if (!_.isEmpty(addToCartResponse) && addToCartResponse?.success){
      navigate(`/${ROUTES_LABEL.ORDER_ENTRY}`);
    }

  },[addToCartResponse]);

  useEffect(() => {
    return () => {
     dispatch(addToCartSuccess({}));
    };
  }, [dispatch]);

  const redirectToOrderEntryAddSelection = () => {
    let productSelected = selectedProducts.filter(
      (item: any) =>
        item?.prodData?.qty !== undefined && item?.prodData?.qty !== 0 && item?.prodData?.product?.type== 0
    );
    let prods = [...prodContentData];
    let shouldRedirect: any = true;
    let cartItems: any = [];
    productSelected.map((product: any) => {
      let productIdx: any = null;
      let index: any = String(product?.index);
      let cartProduct =  {
        productSku: {
          name: product?.prodData?.product?.definition?.materialName,
        },
        subLineItems: [
          {
              quantity: product?.prodData?.qty,
              requestedDeliveryDate: cartShipToOptionsSelections?.[shoppingContextToken]?.deliveryDate ?? null,
          }],
        specialPrice: "",
        quantity: product?.prodData?.qty,
        quantityTotal: 0,
        isGradedStock: parent === "gradedStock"? true: false,
        availabilityMap:  parent === "gradedStock"? product?.prodData?.product?.availabilityMap: "",
        index:  parent === "gradedStock"? product?.prodData?.product?.index: "",
        texts:  parent === "gradedStock"? product?.prodData?.product?.texts: "",
        detail:  parent === "gradedStock"? product?.prodData?.product?.detail: "",
        price:  parent === "gradedStock"? `${product?.prodData?.product?.price?.currency} ${product?.prodData?.product?.price?.price}`: "",
        isWarranty: parent === "gradedStock"? (!_.isEmpty(product?.prodData?.product?.warranties) ? true: false): "",
        warranties: parent === "gradedStock"? (!_.isEmpty(product?.prodData?.product?.warranties) ? product?.prodData?.product?.warranties: ""): "",
      
      }
      cartItems.push(cartProduct);

      if (index.indexOf("_") != -1) {
        //it is warranty product
        let warrantyIndex: any = index.substring(0, index.indexOf("_"));
        let productIndex: any = index.substring(
          index.indexOf("_") + 1,
          index.length
        );

        productIdx = prods[productIndex]?.product?.warranties[warrantyIndex];
      } else {
        productIdx = prods[index];
      }
      let limitOrderQuantity: any = contextDetails?.limitOrderQuantity;

      if (
        limitOrderQuantity != undefined &&
        limitOrderQuantity.enabled &&
        productIdx.qty > limitOrderQuantity.maxQuantity
      ) {
        let invalidLimitMsg: any = t("dnet.invalid_limit_order_quantity.text");
        invalidLimitMsg = invalidLimitMsg.replace(
          "{0}",
          limitOrderQuantity.maxQuantity
        );
        productIdx.quantityMessageTarget = invalidLimitMsg;
        shouldRedirect = false;
      }
    });

    if (shouldRedirect) {
      let cart = {
        shipToAccountId: cartShipToOptionsSelections?.[shoppingContextToken]?.selectedShipToAccount ?? [""],
        lineItems: cartItems,
        isMultiShipTo: cartShipToOptionsSelections?.[shoppingContextToken]?.multiShipTo ?? false,
        isMultiRequestedDeliveryDate: cartShipToOptionsSelections?.[shoppingContextToken]?.multiRDD ?? false,
        requestedDeliveryDate: cartShipToOptionsSelections?.[shoppingContextToken]?.deliveryDate ?? null,
        spToken: shoppingContextToken,
        key: 2,
        isPartialDelivery: cartShipToOptionsSelections?.[shoppingContextToken]?.partialDelivery === false ? false : true,
        usernameOrEmail: userDetails?.username,
      };
      dispatch(addToCart(cart));
      //navigate(`/${ROUTES_LABEL.ORDER_ENTRY}`);
    } else {
      setProdContentData(prods);
    }
  };

  const redirectToDataDownload = () => {
    dispatch(getProductsByMaterialNameSuccess({}));
    dispatch(setSelectedProductsDownload(selectedProducts));
    navigate(`/${ROUTES_LABEL.DATA_DOWNLOAD}/${ROUTES_LABEL.PRODUCT_DATA}`);
  };

  const redirectToDigitalAssetDownload = () => {
    dispatch(getProductsByMaterialNameSuccess({}));
    dispatch(setSelectedProductsDownload(selectedProducts));
    navigate(`/${ROUTES_LABEL.DATA_DOWNLOAD}/${ROUTES_LABEL.DIGITAL_ASSET}`);
  };
  const productCompareList = useSelector(
    (state: any) => state?.productReducer?.productCompareList
  );

  useEffect(() => {
    if (productCompareList?.length > 0) {
      selectedProducts.forEach((ele: any) => {
        ele.prodDetails = productCompareList?.filter(
          (prod: any) =>
            prod?.materialName ===
            ele?.prodData?.product?.definition?.materialName
        )[0];
        ele.availabilityDetails = getAvailabilityDetails(ele?.prodData);
      });
      let tabList: any = [];
      productCompareList.forEach((ele: any) => {
        if (ele?.featureBenefits?.length > 0) tabList.push("feature");
        if (ele?.proBenefit?.length > 0) tabList.push("benefit");
        if (ele?.techSpecs?.length > 0) tabList.push("spec");
      });
      if (tabList?.length === 0) {
        dispatch(
          setOpenToast({
            openToast: true,
            severity: TOAST_SEVERITY.ERROR,
            message: t("dnet.no_info_for_comparison.text"),
          })
        );
      } else {
        dispatch(
          setProdCompareData({
            showFeature: tabList?.includes("feature"),
            showBenefits: tabList?.includes("benefit"),
            showTech: tabList?.includes("spec"),
            selectedProducts,
          })
        );
        dispatch(clearProdCompareList({}));
        categoryNames ? navigate(`/${ROUTES_LABEL.PRODUCT_COMPARE}/${categoryNames}`): navigate(`/${ROUTES_LABEL.PRODUCT_COMPARE}`) ;
      }
    }
  }, [productCompareList]);

  const redirectToCompare = () => {
    dispatch(getProductImagesSuccess({}));
    let productNames: any = [];
    setSelectedProducts(
      selectedProducts.filter((item: any) => !String(item.index).includes("_"))
    );
    selectedProducts.forEach((ele: any) => {
      if (ele?.prodData?.product?.index?.materialName)
        productNames.push(ele?.prodData?.product?.index?.materialName);
    });
    if (productNames?.length >= 2) {
      dispatch(fetchProductDetailsData({ productNames: productNames }));
    } else {
      dispatch(
        setOpenToast({
          openToast: true,
          severity: TOAST_SEVERITY.ERROR,
          message: t("dnet.no_info_for_comparison.text"),
        })
      );
    }
  };

  const sonyMenuItems = useSelector(
    (state: any) => state?.commonReducer?.sonyMenuItems
  );

  const displayMenuItems = (itemName: any) => {
    const menuItem = sonyMenuItems?.filter((item: any) => {
      return item?.sectionName === "DNET Data Download"
    })
    const subMenu = menuItem[0]?.subMenu?.filter((subItem: any) => {
      return subItem?.sectionName === itemName
    })
    return subMenu?.length === 1;
  }
  let csvImageAltTag = t("dnet.save_as_csv.text").split('"');
  let csvImageAlt = csvImageAltTag[csvImageAltTag?.length - 2];
  let uploadImageAltTag = t("dnet.load_file.text").split('"');
  let uploadImageAlt = uploadImageAltTag[uploadImageAltTag?.length - 2];
  let xmlImageAltTag = t("dnet.save_as_XML").split('"');
  let xmlImageAlt = xmlImageAltTag[xmlImageAltTag?.length - 2];
  const digitalAssetDownloadAvailable = displayMenuItems("DNET Digital Asset Download"); 
  const productDataDownloadAvailable = displayMenuItems("DNET Product Data Download"); 

  const isProductDetailsVisible = (indexObj: any) => {
    if (indexObj?.uri) {
      return true;
    } else {
      return false;
    }
  };
  const [selectedPrimeSupport, setSelectedPrimeSupport] = useState<any>([]);
  const showWarranties = (index: any) => {
    if (selectedPrimeSupport.indexOf(index) == -1) {
      setSelectedPrimeSupport([...selectedPrimeSupport, index]);
    } else {
      setSelectedPrimeSupport(
        selectedPrimeSupport.filter((idx: any) => idx !== index)
      );
    }
  };
  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const checkHandler = (e: any, data: any, index: any) => {
    let prod = {
      index,
      prodData: data,
      prodDetails: {},
      availabilityDetails: {},
    };
    if (e?.target?.checked) {
      setSelectedProducts([...selectedProducts, prod]);
    } else {
      setSelectedProducts(
        selectedProducts.filter((item: any) => item.index !== index)
      );
    }
  };

  const prodCategoryResponse = useSelector(
    (state: any) => state?.commonReducer?.prodCategoryResponse
  );


  const findDisplayName = (categoryId: any) => {
    const categories: any =
      prodCategoryResponse?.data?.catalogList[0]?.children;
    for (const mainCategory of categories) {
      if (mainCategory.id === categoryId) {
        return mainCategory?.displayName;
      }
      if (mainCategory.children && mainCategory.children.length > 0) {
        for (const subCategory of mainCategory.children) {
          if (
           subCategory.id === categoryId
          ) {
            return subCategory.displayName;
          }
          if (subCategory.children && subCategory.children.length > 0) {
            for (const subSubCategory of subCategory.children) {
              if (
                subSubCategory.id === categoryId
              ) {
                return subSubCategory.displayName;
              }
            }
          }
        }
      }
    }
    return t("dnet.products.section");
  };

  const checkAllHandler = (e: any) => {
    let selectedProdArr:any=[];

    prodContentData?.map((data:any)=>{

      let index:any= getIndexOfProduct(data?.product?.definition?.materialName);

      let prod = {
        index,
        prodData: data,
        prodDetails: {},
        availabilityDetails: {},
      };

      selectedProdArr.push(prod);

    });

    if (e?.target?.checked) {
      setSelectAllCheckBox(true);
      setSelectedProducts(selectedProdArr);
    } else {
      setSelectAllCheckBox(false);
      setSelectedProducts([]);
    }
    
  };

  const row = (product: any, index: any, isWarranty: any) => {
    return (
      <React.Fragment>
        <TableRow
          tabIndex={-1}
          key={index}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          {(parent === "productLists" || parent === "orderEntry") && (
            <TableCell align="center">
              {isWarranty && <SubdirectoryArrowRightIcon />}
              <Checkbox
                checked={
                  selectedProducts.filter((item: any) => item.index == index)
                    .length > 0
                }
                sx={{ paddingLeft: 0 }}
                onChange={(e: any) => {
                  checkHandler(e, product, index);
                }}
              />
            </TableCell>
          )}
          <TableCell>
            <Box className={styles.modelColumn}>
              {parent !== "productLists" && parent !== "orderEntry" && (
                <>
                  {isWarranty && <SubdirectoryArrowRightIcon />}
                  <Checkbox
                    checked={
                      selectedProducts.filter(
                        (item: any) => item.index == index
                      ).length > 0
                    }
                    sx={{ paddingLeft: 0 }}
                    onChange={(e: any) => {
                      checkHandler(e, product, index);
                    }}
                  />
                </>
              )}
              <Tooltip
                title={
                  <div>
                    {product?.product?.definition?.ean && (
                      <span>
                        {t("dnet.ean_colon.text")}&nbsp;
                        {product?.product?.definition?.ean}
                      </span>
                    )}
                    {!product?.product?.definition?.ean &&
                      product?.product?.definition?.upc && (
                        <span>
                          {t("dnet.upc_colon.text")}&nbsp;
                          {product?.product?.definition?.upc}
                        </span>
                      )}
                    <br />
                    <span>
                      {t("dnet.net_weight_colon.text")}&nbsp;
                      {product?.product?.definition?.weight &&
                      product?.product?.definition?.weightUnit ? (
                        <span>
                          {product?.product?.definition?.weight}
                          &nbsp;
                          {product?.product?.definition?.weightUnit}
                        </span>
                      ) : (
                        <span>{t("dnet.not_available.text")}</span>
                      )}
                    </span>
                  </div>
                }
                placement="top"
                arrow
              >
                <span className={styles.modelColumn}>
                  {product?.product?.index?.thumbnail &&
                    product?.product?.index?.uri && (
                      <span
                        className={styles.imageStyle}
                        onClick={() => {
                          redirectToDetails(product);
                        }}
                      >
                        <img 
                          className={styles.productImageStyle}
                          style={{maxWidth: "150px",maxHeight: "150px"}}
                          src={product?.product?.index?.thumbnail?.toString()}
                        />
                      </span>
                    )}
                  <span>
                    {product?.product?.definition?.materialName}
                    {product?.isNew && (
                      <span className={styles.newBadgeStyle}>
                        {t("dnet.new.text")}
                      </span>
                    )}
                  </span>
                  {parent !== "productLists" && parent !== "orderEntry" && (
                    <span>
                      {isProductDetailsVisible(product?.product?.index) && (
                        <CustomButton
                          label={t("dnet.product_details.text")}
                          color="primary"
                          additionalSxProperties={{
                            width: "max-content",
                          }}
                          onClick={() => {
                            redirectToDetails(product);
                          }}
                        />
                      )}
                    </span>
                  )}
                  {parent !== "productLists" && parent !== "orderEntry" && (
                    <span>
                      {product?.product?.warranties?.length > 0 && (
                        <CustomButton
                          label={t("dnet.prime_support.text")}
                          additionalSxProperties={{
                            width: "max-content",
                          }}
                          onClick={() => {
                            showWarranties(index);
                          }}
                        />
                      )}
                    </span>
                  )}
                </span>
              </Tooltip>
            </Box>
          </TableCell>
          {!isInternalAdmin && (
            <>
              {parent !== "productLists" && (
                //Order Search
                <TableCell align="center">
                  {product?.product?.type == 0 && (
                    <IconButton
                      title={
                        parent !== "orderEntry"
                          ? t(
                              "dnet.search_order_for_product_alt.text"
                            )?.toString()
                          : t("dnet.find.text")?.toString()
                      }
                      onClick={() =>
                        redirectToOrdersPage(
                          product,
                          parent !== "orderEntry"
                            ? "orderSearch"
                            : "globalSearch"
                        )
                      }
                    >
                      <SearchIcon />
                    </IconButton>
                  )}
                </TableCell>
              )}
              {parent === "orderEntry" && contextDetails?.isCountryOfOriginEnabled && (
                <TableCell>
                  {/* showCountryOfOriginFromProductsData */}
                </TableCell>
              )}
              {parent === "orderEntry" &&
                contextDetails?.customerMaterialNumberEnabled && (
                  <TableCell>
                    {/* show custom TextField with materialNumber */}
                  </TableCell>
                )}
              {((contextDetails?.isBasicTransactor &&
                parent === "gradedStock") ||
                parent !== "gradedStock") && (
                //Quantity
                <TableCell align="center">
                  {product?.product?.type == 0 &&
                    (!orderEntryMultiship ? (
                      <Box>
                        <Box className={styles.qtyButtonContaainer}>
                          <CustomTextField
                            additionalSxProperties={{
                              width: "50px",
                              height: "15px",
                            }}
                            label={""}
                            value={product?.qty}
                            changeHandler={(e: any) => {
                              e.target.value = e.target.value
                                .toString()
                                .slice(0, 6);
                              updateQuantity(index, e, product);
                            }}
                          />
                          <Box className={styles.qtyButtonContaainer}>
                            <IconButton
                              onClick={() =>
                                handleQuantityChange(
                                  index,
                                  "decrement",
                                  product
                                )
                              }
                              title={t("dnet.decrement_info.text")?.toString()}
                            >
                              <RemoveCircleOutlineOutlinedIcon />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                handleQuantityChange(
                                  index,
                                  "increment",
                                  product
                                )
                              }
                              title={t("dnet.increment_info.text")?.toString()}
                            >
                              <AddCircleOutlineOutlinedIcon />
                            </IconButton>
                            {parent !== "productLists" &&
                              parent !== "orderEntry" && (
                                <IconButton
                                  onClick={() =>
                                    redirectToOrderEntry(product, index)
                                  }
                                >
                                  <AddShoppingCartOutlinedIcon />
                                </IconButton>
                              )}
                          </Box>
                        </Box>
                        <Box className={styles.qtyText}>
                          <span className={styles.messageStyle}>
                            {product?.quantityMessageTarget}
                          </span>
                        </Box>
                      </Box>
                    ) : (
                      1 //if multiShipAddress then show number of quantity
                    ))}
                </TableCell>
              )}
              {parent === "orderEntry" &&
                contextDetails?.isMultiRequestedDeliveryDate && (
                  <TableCell>
                  </TableCell>
                )}

              <TableCell>
                {product?.product?.type == 0 &&
                  getAvailabilityData(product, index)}
                <Tooltip title={product?.toolTipLive}>
                  <span className={styles.messageStyle}>
                    {product?.liveAvbText}
                  </span>
                </Tooltip>
              </TableCell>

              {priceVisible && (
                <TableCell sx={{ minWidth: "100px" }}>
                  {showPriceList &&
                    product?.product?.type == 0 &&
                    (parent === "orderEntry" ? null : (
                      <Box>
                        <Box>
                          {product?.product?.price?.currency}{" "}
                          {product?.product?.price?.price &&
                            parseFloat(
                              product?.product?.price?.price?.toString()
                            ).toFixed(2)}
                        </Box>
                        <Box className={styles.qtyText}>
                          <span className={styles.messageStyle}>
                            {product?.otherTarget}
                          </span>
                        </Box>
                      </Box>
                    ))}
                </TableCell>
              )}
            </>
          )}
          <TableCell sx={{ minWidth: "250px" }}>
            {errorMessage ? (
              <span className={styles.errorMessageStyle}>
                <img src={exclamation} className={styles.noImageStyle} />
                {/* TODO - to check from where value is coming */}
                <span>{errorMessage}</span>
              </span>
            ) : !errorMessage && product?.product?.definition?.materialName ? (
              product?.product?.texts[0]?.description ? (
                <span>{product?.product?.texts[0]?.description}</span>
              ) : (
                <span>{t("dnet.empty_description.txt")}</span>
              )
            ) : (
              <span></span>
            )}
            {/* TODO - should include promotions logic */}
          </TableCell>
          {parent === "orderEntry" && isSpecialPriceEnabled && (
            <TableCell>
            </TableCell>
          )}
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <>
      {parent !== "productLists" &&
        parent !== "orderEntry" &&
        parent !== "productTabs" &&
        parent !== "gradedStock" &&
        parent !== "globalSearch" &&
        parent !== "newProducts" &&
        parent !== "accessories" && (
          <Typography
            variant="h5"
            className={styles.header}
            pb={3}
            color="primary"
          >
            {!_.isEmpty(productContentData?.displayItem) ? (
              productContentData?.displayItem?.length > 1 ? (
                <>
                  <div>{t("dnet.product_multiple_series.text")}</div>
                  <span className={styles.multiProds}>
                    {productContentData?.displayItem.map((category:any)=>`${findDisplayName(category)}, `)}
                  </span>
                </>
              ) : (
                findDisplayName(productContentData?.displayItem.toString())
              )
            ) : (
              t("dnet.products.section")
            )}
          </Typography>
        )}
      <Box className={styles.tableBox}>
        {parent === "productLists" && (
          <div className={styles.tableHeader}>
            <div>
              <CustomButton
                id="deleteList"
                label={t("dnet.delete_list.text")}
                onClick={deleteHandler}
                color="warning.main"
                margin="0"
              />
            </div>
          </div>
        )}
        <TableContainer component={Paper} className={styles.tableContainer}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableHeaderData.map((headCell: any, index: any) => (
                  <React.Fragment key={index}>
                    {headCell?.id === "orderSearch" &&
                    parent === "orderEntry" ? (
                      <TableCell align="center" key={headCell.id}>
                        {t("dnet.find.text")}
                      </TableCell>
                    ) : headCell?.id === "select" &&
                      (parent === "productLists" || parent === "orderEntry") ? (
                      <TableCell align="center" key={headCell.id}>
                        <Checkbox
                          sx={{ paddingLeft: 0 }}
                          onChange={(e: any) => {
                            checkAllHandler(e)
                          }}
                          checked={selectAllCheckBox}
                        />
                      </TableCell>
                    ) : headCell?.id === "countryoforigin" &&
                      parent === "orderEntry" &&
                      contextDetails?.isCountryOfOriginEnabled ? (
                      <TableCell align="center" key={headCell.id}>
                        {headCell.label}
                      </TableCell>
                    ) : headCell?.id === "customerMaterialNumber" &&
                      parent === "orderEntry" &&
                      contextDetails?.customerMaterialNumberEnabled ? (
                      <TableCell align="center" key={headCell.id}>
                        {headCell.label}
                      </TableCell>
                    ) : headCell?.id === "requsestedDate" &&
                      parent === "orderEntry" &&
                      contextDetails?.isMultiRequestedDeliveryDate ? (
                      <TableCell align="center" key={headCell.id}>
                        {headCell.label}
                      </TableCell>
                    ) : headCell?.id === "specialPrice" &&
                      parent === "orderEntry" &&
                      isSpecialPriceEnabled ? (
                      <TableCell align="center" key={headCell.id}>
                        {headCell.label}
                      </TableCell>
                    ) : (headCell?.id === "product.definition.materialName" ||
                        headCell?.id === "qty" ||
                        headCell?.id ===
                          "product.availabilityMap.NOFG.status" ||
                        headCell?.id === "description") &&
                      (parent === "productLists" || parent === "orderEntry") ? (
                      <TableCell
                        align="center"
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : "asc"}
                          onClick={createSortHandler(headCell.id)}
                          hideSortIcon={true}
                        >
                          {headCell.label}
                          {orderBy === headCell.id &&
                          (headCell.id === "product.definition.materialName" ||
                            headCell.id ===
                              "product.availabilityMap.NOFG.status" ||
                            headCell.id === "product.price.price") ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ) : headCell?.id === "product.price.price" &&
                      (parent === "productLists" || parent === "orderEntry") &&
                      priceVisible ? (
                      <TableCell
                        align="center"
                        key={headCell.id}
                        style={{ color: "#5887f5" }}
                        className={styles.colorBlue}
                      >
                        {headCell.label}
                        {headCell.id === "product.price.price" ? (
                          <img
                            style={{ color: "#5887f5", cursor: "pointer" }}
                            onClick={() => {
                              setShowPriceList(!showPriceList);
                            }}
                            src={alertstar}
                          />
                        ) : null}
                      </TableCell>
                    ) : (headCell?.id !== "qty" ||
                        (headCell?.id === "qty" &&
                          contextDetails?.isBasicTransactor)) &&
                      (headCell?.id !== "product.price.price" ||
                        (headCell?.id === "product.price.price" &&
                          priceVisible)) &&
                      parent === "gradedStock" ? (
                      <TableCell
                        align="center"
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : "asc"}
                          onClick={createSortHandler(headCell.id)}
                          hideSortIcon={true}
                        >
                          {headCell.label}
                          {orderBy === headCell.id &&
                          (headCell.id === "product.definition.materialName" ||
                            headCell.id ===
                              "product.availabilityMap.NOFG.status" ||
                            headCell.id === "product.price.price") ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                        {headCell.id === "product.price.price" ? (
                          <img
                            style={{ color: "#5887f5", cursor: "pointer" }}
                            onClick={() => {
                              setShowPriceList(!showPriceList);
                            }}
                            src={alertstar}
                          />
                        ) : null}
                      </TableCell>
                    ) : (
                      //List Component
                      (headCell?.id !== "product.price.price" ||
                        (headCell?.id === "product.price.price" &&
                          priceVisible)) &&
                      parent !== "productLists" &&
                      parent !== "orderEntry" &&
                      parent !== "gradedStock" && (
                        <TableCell
                          align="center"
                          key={headCell.id}
                          sortDirection={
                            orderBy === headCell.id ? order : false
                          }
                        >
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            hideSortIcon={true}
                          >
                            {headCell.label}
                            {orderBy === headCell.id &&
                            (headCell.id ===
                              "product.definition.materialName" ||
                              headCell.id ===
                                "product.availabilityMap.NOFG.status" ||
                              headCell.id === "product.price.price") ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                          {headCell.id === "product.price.price" ? (
                            <img
                              style={{ color: "#5887f5", cursor: "pointer" }}
                              onClick={() => {
                                setShowPriceList(!showPriceList);
                              }}
                              src={alertstar}
                            />
                          ) : null}
                        </TableCell>
                      )
                    )}
                  </React.Fragment>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {prodContentData?.length > 0 ? (
                <>
                  {stableSort(prodContentData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((product: any, index: any) => {
                      return (
                        <>
                          {row(
                            product,
                            getIndexOfProduct(
                              product?.product?.definition?.materialName
                            ),
                            false
                          )}

                          {product?.product?.warranties?.length > 0 &&
                          selectedPrimeSupport.indexOf(
                            getIndexOfProduct(
                              product?.product?.definition?.materialName
                            )
                          ) == -1
                            ? false
                            : true &&
                              product?.product?.warranties?.map(
                                (warrantyProduct: any, idx: any) => {
                                  return row(
                                    warrantyProduct,
                                    idx +
                                      "_" +
                                      getIndexOfProduct(
                                        product?.product?.definition
                                          ?.materialName
                                      ),
                                    true
                                  );
                                }
                              )}
                        </>
                      );
                    })}
                </>
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={8}
                    align="center"
                    className={styles.noRecFound}
                  >
                    {t("gzl.no_data_available.text")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {parent === "orderEntry" && (
          <div className={styles.tableFooter} style={{ marginLeft: "6px" }}>
            <div className={styles.productListButtonInnerSection}>
              {parent === "orderEntry" && !orderEntryMultiship && (
                <>
                  <CustomDropDown
                    label={t("dnet.add_selected_items.text")}
                    id="currentList"
                    value={selectedProductList}
                    handleChange={(e: any) => {
                      setSelectedProductList(e.target.value);
                    }}
                    menuOptions={productListItems}
                    module="productLists"
                  />
                  <CustomButton
                    id="save"
                    label={t("dnet.save.text")}
                    onClick={() => {}}
                    color="info.main"
                    margin="0 0 4px 10px"
                  />
                </>
              )}
              <div
                className={styles.orderEntryButtonSection}
                style={
                  parent === "orderEntry" && !orderEntryMultiship
                    ? { paddingLeft: "10px" }
                    : { paddingLeft: "0" }
                }
              >
                <Typography variant="caption">
                  {t("dnet.selected_items.text")}
                </Typography>
                <CustomButton
                  id="deleteItem"
                  label={t("dnet.delete.text")}
                  onClick={saveHandler}
                  color="warning.main"
                />
              </div>
            </div>
          </div>
        )}
        {prodContentData?.length > 0 && (
          <div className={styles.tableFooter}>
            {parent !== "productLists" && parent !== "orderEntry" ? (
              <div className={styles.totalAmount}>
                {/* ShowCompare */}
                {false && (
                  <CustomButton
                    label={t("dnet.compare_button.text")}
                    color="primary"
                    onClick={() => redirectToCompare()}
                    disabled={selectedProducts?.length < 2}
                    margin="6px"
                  />
                )}
               {digitalAssetDownloadAvailable && <CustomButton
                  label={t("dataDownload.downloadDigitalAsset.title")}
                  color="primary"
                  onClick={() => redirectToDigitalAssetDownload()}
                  margin="6px"
                /> }
                {productDataDownloadAvailable &&
                <CustomButton
                  label={t("dataDownload.downloadProductData.title")}
                  color="primary"
                  onClick={() => redirectToDataDownload()}
                  margin="6px"
                />
                 }
                {!isInternalAdmin &&  (
                  <CustomButton
                    label={t("dnet.add_selection.text")}
                    onClick={() => redirectToOrderEntryAddSelection()}
                    margin="6px"
                  />
                )}
              </div>
            ) : parent !== "productLists" ? (
              <div className={styles.totalAmount}>
                <CustomButton
                  id="clearOrder"
                  label={t("dnet.clear_order.text")}
                  onClick={() => {}}
                  color="info.main"
                />
                <CustomButton
                  id="csv"
                  label={<img src={csvImage} alt={csvImageAlt} />}
                  onClick={() => {}}
                  color="info.main"
                />
                <CustomButton
                  id="xml"
                  label={<img src={xmlImage} alt={xmlImageAlt} />}
                  onClick={() => {}}
                  color="info.main"
                />
                <CustomButton
                  id="upload"
                  label={<img src={uploadImage} alt={uploadImageAlt} />}
                  onClick={handleOpenFileModal}
                  color="info.main"
                />
              </div>
            ) : (
              <div className={styles.totalAmount}>
                <div className={styles.selectedItemSection}>
                  <Typography variant="caption">
                    {t("dnet.selected_items.text")}
                  </Typography>
                  <CustomButton
                    id="addToOrder"
                    label={t("dnet.add_to_order.text")}
                    onClick={redirectToOrderEntryAddSelection}
                    color="info.main"
                  />
                  <CustomButton
                    id="deleteItem"
                    label={t("dnet.delete.text")}
                    onClick={deleteProdContentHandler}
                    color="warning.main"
                  />
                  <CustomButton
                    id="save"
                    label={t("dnet.save_changes.text")}
                    onClick={updateHandler}
                    color="primary"
                    margin="0"
                  />
                </div>
              </div>
            )}
            <TablePagination
              className={styles.pagination}
              component="div"
              count={prodContentData?.length ? prodContentData.length : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 20]}
            />
          </div>
        )}
      </Box>
    </>
  );
};

ProdContentDetail.defaultProps = {
  parent: "",
  saveHandler: () => {},
  orderEntryMultiship: false,
  handleOpenFileModal: () => {},
};
