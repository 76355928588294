import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../atoms/customButton";
import CustomTextField from "../atoms/customTextField";
import styles from "./resetPassword.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserInfoFromURLID,
  getResetPassword,
  getTranslationAction,
  setLoading,
} from "../../state/actions";
import { ROUTES_LABEL } from "../../constants/routesLabels";
import { Header } from "../header";

export const ResetPassword = () => {
  const { t, i18n } = useTranslation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const passNotMatching = t("dnet.passwords_are_different.text");
  const passwordInfo = t("dnet.password_info.text");

  //fetching URL strings locale and ticket
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let ticket = params.get("ticket");
  let currentLocale = params.get("locale");

  let dispatch = useDispatch();
  const userInfo = useSelector(
    (state: any) => state?.resetPasswordReducer?.userInfoFromURLID?.data
  );
  const linkDetails = useSelector(
    (state: any) => state?.resetPasswordReducer?.userInfoFromURLID
  );
  const passwordChanged = useSelector(
    (state: any) => state?.resetPasswordReducer?.passwordChanged
  );
  const navigate = useNavigate();
  const currentTranslation = useSelector(
    (state: any) => state?.commonReducer?.translation
  );

  const passwordCheck = () => {
    let lowerCase = false;
    let upperCase = false;
    let num = false;

    let name = userInfo?.nameSurname?.split(" ");
    let firstName = name[0];
    let surName = name[1];
    let email = userInfo?.emailTo;
    let passLowerCase = password.toLowerCase();

    if (password.length >= 8) {
      for (let i = 0; i < password.length; i++) {
        let passChar = password[i];
        if (
          passChar === passChar.toUpperCase() &&
          !upperCase &&
          isNaN(parseFloat(passChar))
        ) {
          upperCase = true;
        } else if (passChar === passChar.toLowerCase() && !lowerCase) {
          lowerCase = true;
        } else if (!isNaN(parseFloat(passChar)) && !num) {
          num = true;
        }
      }
      if (
        upperCase &&
        lowerCase &&
        num &&
        !passLowerCase.includes(firstName.toLowerCase()) &&
        !passLowerCase.includes(surName.toLowerCase()) &&
        !passLowerCase.includes(email.toLowerCase())
      )
        return true;
      else return false;
    }
    return false;
  };

  const resetPasswordHandler = () => {
    setPassword("");
    setConfirmPassword("");
    //Password validations
    if (password !== confirmPassword) {
      setErrorMsg(passNotMatching);
    } else if (!passwordCheck()) {
      setErrorMsg(passwordInfo);
    } else {
      setErrorMsg("");
      dispatch(setLoading(true)); 
      dispatch(getUserInfoFromURLID({ ticket }));
      dispatch(
        getResetPassword({
          email: userInfo?.emailTo,
          newPassword: password,
          ticket: ticket,
        })
      );
    }
  };

  useEffect(() => {
    if (ticket) {
      dispatch(setLoading(true)); 
      //Onload calling reset password API to check the URL status, active or not
      dispatch(getUserInfoFromURLID({ ticket }));
    }
  }, [ticket, dispatch]);

  useEffect(() => {
    let locale = currentLocale ? currentLocale : "en_GB";
    dispatch(getTranslationAction({ locale }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkDetails]);

  useEffect(() => {
    //change language according to the locale fetched from URL
    let locale = currentLocale ? currentLocale : "en_GB";
    if (Object.keys(currentTranslation).length >= 1) { 
      i18n.addResourceBundle(locale, "translation", currentTranslation, true);
      i18n.changeLanguage(locale);
      dispatch(setLoading(false));
    } else {
      dispatch(setLoading(true));
      dispatch(getTranslationAction({ locale: "en_GB" }));
    }
  }, [currentTranslation, currentLocale, dispatch, i18n]);

  useEffect(() => {
    //Once passowrd changes successfully navigate user to login
    if (passwordChanged) {
      dispatch(setLoading(false)); 
      navigate(`/${ROUTES_LABEL.LOGIN}`);
    }
  }, [passwordChanged, dispatch, navigate]);

  return (
    <>
      <Box className={styles.resetPassContainer}>
        <Header fromLogin={true} />
        <Box className={styles.boxStyle}>
          {userInfo?.status === 0 &&
          Object.keys(currentTranslation).length >= 1 ? (
            <Box className={styles.resetPassCard}>
              <div className={styles.resetHeader}>
                <Typography variant="subtitle1" color="primary">
                  {t("dnet.reset_your_password.text")}
                </Typography>
              </div>
              <div className={styles.resetPassSubHeading}>
                <Typography>
                  {t("dnet.please_enter_new_password.text")}
                </Typography>
              </div>
              <CustomTextField
                additionalSxProperties={{ margin: "12px 16px" }}
                id="password"
                label={t("dnet.new_password.text")}
                value={password}
                changeHandler={(e: any) => setPassword(e.target.value)}
                type="password"
              />
              <CustomTextField
                additionalSxProperties={{ margin: "12px 16px" }}
                id="confirmPassword"
                label={t("dnet.new_password_again.text")}
                value={confirmPassword}
                changeHandler={(e: any) => setConfirmPassword(e.target.value)}
                // onKeyDown ={onKeyDown}
                type="password"
              />
              <div className={styles.buttonStyle}>
                <div
                  style={
                    errorMsg
                      ? {
                          backgroundColor: "#f8d7da",
                          borderColor: "#f5c6cb",
                          margin: "0 16px",
                          padding: ".75rem 1.25rem",
                        }
                      : {
                          display: "none",
                        }
                  }
                >
                  <Typography color="#721c24">{errorMsg}</Typography>
                </div>
                <CustomButton
                  id="submitButton"
                  label={t("dnet.submit.text")}
                  onClick={resetPasswordHandler}
                  color="primary"
                  margin="12px 16px"
                  additionalSxProperties={{ width: "30%" }}
                />
              </div>
            </Box>
          ) : linkDetails?.success === false &&
            Object.keys(currentTranslation).length >= 1 ? (
            <Box className={styles.resetExpiredPassCard}>
              <Typography mt={2}>
                {t("dnet.reset_pswd_url_expired.text")}
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                <CustomButton
                  id="closeButton"
                  label={t("dnet.close.text")}
                  onClick={() => navigate(`/${ROUTES_LABEL.LOGIN}`)}
                  color="primary"
                  margin="12px 6px"
                  additionalSxProperties={{ width: "30%" }}
                />
              </div>
            </Box>
          ) : null}
        </Box>
      </Box>
    </>
  );
};
