import React from "react";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";
import "./paymentDetailsTable.scss";
import { useTranslation } from "react-i18next";
import moment from "moment";
var currencyFormatter = require('currency-formatter');

const PaymentDetailsTable = () => {
  const { t } = useTranslation();
  const [rows, setRows] = useState<any>([]);
  const paymentDetailsTableData = useSelector(
    (state: any) => state?.gazelleReducer?.paymentDetailsData
  );

  interface Column {
    id: "payment" | "valueDate" | "amount";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
  }

  const columns: readonly Column[] = [
    { id: "payment", label: t("gzl.payments.text") },
    { id: "valueDate", label: t("gzl.paid_on.text") },
    { id: "amount", label: t("gzl.actual_paid_value.text") },
  ];

  interface Data {
    payment: any;
    valueDate: any;
    amount: any;
  }

  function createData(payment: any, valueDate: any, amount: any): Data {
    return { payment, valueDate, amount };
  }

  const createRowData = () => {
    // Rows data creation from API response
    let data = paymentDetailsTableData;
    let rowsdata = [];
    rowsdata.push(
      createData(
        data?.payment, 
        moment(data?.valueDate).format("DD.MM.YYYY"), 
        data?.amount
      )
    );
    setRows([...rowsdata]);
  };

  useEffect(() => {
    createRowData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentDetailsTableData]);

  return (
    <>
      <TableContainer component={Paper} className="tableContainerHeader">
        <Table sx={{ minWidth: 650 }} aria-label="Payments Table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length > 0 ? (
              rows.map((row: any, key: any) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return column.id === "amount" ? (
                        <TableCell key={column.id} align={column.align}>
                          {value}&nbsp;
                          {currencyFormatter.findCurrency(
                            paymentDetailsTableData?.currency
                            )?.symbol}
                        </TableCell>
                      ) : (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  align={"center"}
                  className="noRecFound"
                >
                  {t("gzl.no_data_available.text")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PaymentDetailsTable;
