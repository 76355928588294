import React, { useState } from "react";
import { MenuItem, Select, InputLabel, FormControl, Box, FormHelperText } from "@mui/material";

const CustomDropDown = ({
  value,
  handleChange,
  menuItems,
  label,
  menuOptions,
  module,
  minWidth,
  error,
  helperText,
  disabled,
  width
}: any) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Box sx={{ minWidth: minWidth }}>
        <FormControl fullWidth disabled={disabled} size="small">
          {module === "debitNoteCreation" ? (
            <>
              <InputLabel id="select-label1">
                {label}
              </InputLabel>
              <Select
                id="select1"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={value}
                onChange={(e) => handleChange(e)}
                label={label}
                error={error}
                sx={{
                  borderRadius: "4px",
                  textAlign: "initial",
                  width: {width},
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 200,
                    },
                  },
                }}
                size="small"
              >
                {menuOptions.map((option: any) => {
                  return (
                    <MenuItem key={option?.value} value={option?.value} disabled={option?.disabled}>
                      {option?.label}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText style={{ color: "#d32f2f" }}>
                {helperText}
              </FormHelperText>
            </>
          ) : module === "department" ? (
            <>
              <InputLabel id="select-label1">
                {label}
              </InputLabel>
              <Select
                id="select1"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={value}
                onChange={(e) => handleChange(e)}
                label={label}
                error={error}
                sx={{
                  borderRadius: "4px",
                  textAlign: "initial",
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 200,
                    },
                  },
                }}
                size="small"
              >
                {menuOptions.map((option: any, index: any) => {
                  return (
                    <MenuItem key={index} value={option?.key} disabled={option?.disabled}>
                      {option?.label}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText style={{ color: "#d32f2f" }}>
                {helperText}
              </FormHelperText>
            </>
          ) : module === "productLists" ? (
            <>
              <InputLabel
                htmlFor="grouped-native-select"
              >
                {label}
              </InputLabel>
              <Select
                id="grouped-native-select"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                native
                defaultValue=""
                value={value}
                onChange={(e) => handleChange(e)}
                label={label}
                error={error}
                sx={{
                  borderRadius: "4px",
                  textAlign: "initial",
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 200,
                    },
                  },
                }}
                size="small"
              >
                <option aria-label="None" value="" />
                {menuOptions.map((option: any) => {
                  return (
                    <optgroup label={option?.category}>
                      {option?.list?.map((subOption: any) => {
                        return (
                          <option
                            key={subOption?.value}
                            value={subOption?.value}
                          >
                            {subOption?.noOfProducts ===""?`${subOption?.label}`:`${subOption?.label} (${subOption?.noOfProducts})`}
                          </option>
                        );
                      })}
                    </optgroup>
                  );
                })}
              </Select>
              <FormHelperText style={{ color: "#d32f2f" }}>
                {helperText}
              </FormHelperText>
            </>
          ) : (
            <>
              <InputLabel id="select-label2">{label}</InputLabel>
              <Select
                id="select2"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={value}
                onChange={(e) => handleChange(e)}
                label={label}
                error={error}
                sx={{
                  borderRadius: "4px",
                  backgroundColor: "white",
                  fontSize: "15px",
                  width: width,
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 200,
                    },
                  },
                }}
                size="small"
              >
                {menuItems.map((item: any) => {
                  return (
                    <MenuItem
                      key={item}
                      value={item}
                      style={{ fontSize: "15px" }}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText style={{ color: "#d32f2f" }}>
                {helperText}
              </FormHelperText>
            </>
          )}
        </FormControl>
      </Box>
    </>
  );
};

CustomDropDown.defaultProps = {
  menuItems: [""],
  menuOptions: [{}],
  module: "",
  value: "",
  minWidth: 222,
  disabled: false
};

export default CustomDropDown;
