import MoreTimeOutlinedIcon from "@mui/icons-material/MoreTimeOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import {
  Box,
  IconButton,
  TablePagination,
  TableSortLabel
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import moment from "moment";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DISPUTED_ITEMS, LABELS } from "../../../../constants/labels";
import { downloadInvoicePdf } from "../../../../state/actions";
import { getComparator, stableSort } from "../../../helper/util";
import styles from "./disputed-items-table.module.scss";

export const DisputedItemsTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const disputedItems = useSelector(
    (state: any) => state?.gazelleReducer?.disputedItems
  );
  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.userDetails
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const saveAccountDetails = useSelector(
    (state: any) => state?.commonReducer?.saveAccountDetails
  )

  const downloadPdf = (disputedItem: any) => {
    dispatch(
      downloadInvoicePdf({
        language: userDetails?.language,
        username: userDetails?.userName,
        partnerKey: saveAccountDetails?.payerToAcc?.number,
        invoiceId: "SD_"+disputedItem?.invoiceId,
      })
    );
  };

  type Order = "asc" | "desc";

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("date");

  const tableHeaderData = [
    { id: "invoiceDate", label: t("gzl.st_date.text") },
    { id: "invoiceNumber", label: t("gzl.invoice_no.text") },
    { id: "documentType", label: t("gzl.doc_type.text") },
    { id: "deliveryNumber", label: t("gzl.delivery_no.text") },
    { id: "yourReference", label: t("gzl.your_ref.text") },
    { id: "amount", label: t("gzl.org_inv_value.text") },
    { id: "queries", label: t("gzl.queries.text") },
  ];

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  //based on overdue value pdf download will be either enabled and disabled
  const disablePdfDownload = (disputedItem: any) => {
    if (disputedItem?.overdue) {
      return true;
    }
    return false;
  };

  return (
    <>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeaderData.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {disputedItems?.data?.length > 0 ? (
              <>
                {stableSort(disputedItems?.data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((disputedItem, index) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={disputedItem?.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          {moment(disputedItem?.invoiceDate).format(
                            "DD.MM.YYYY"
                          )}
                        </TableCell>
                        <TableCell className={styles.invoiceTableCell}>
                          <span className={disablePdfDownload(disputedItem) ? styles.disableDownload : styles.pdfIcon}
                            title={disablePdfDownload(disputedItem) ? "" : t("gzl.display_pdf.text")?.toString()}
                            onClick={(e: any) => {downloadPdf(disputedItem);}}>
                              {disputedItem?.invoiceNumber}
                          </span>
                          <IconButton
                            onClick={(e: any) => {
                              downloadPdf(disputedItem);
                            }}
                            disabled={disablePdfDownload(disputedItem)}
                          >
                            <PictureAsPdfOutlinedIcon
                              titleAccess={t("gzl.display_pdf.text")?.toString()}
                            />
                          </IconButton>
                        </TableCell>
                        <TableCell>{disputedItem?.documentType}</TableCell>
                        <TableCell>{disputedItem?.deliveryNumber}</TableCell>
                        <TableCell>{disputedItem?.yourReference}</TableCell>
                        <TableCell
                          className={
                            disputedItem?.amount < 0
                              ? `${styles.errorText} ${styles.amountWidth}`
                              : styles.amountWidth
                          }
                        >
                          {disputedItem?.amount}
                        </TableCell>
                        <TableCell>
                          {disputedItem.disputed ? (
                            <Link
                              to="/Reply_Enquiry"
                              state={{ invoiceId: disputedItem?.invoiceId }}
                            >
                              <IconButton>
                                {disputedItem.newContact ? (
                                  <MoreTimeOutlinedIcon
                                    titleAccess={DISPUTED_ITEMS.REPLY_QUERY}
                                  />
                                ) : (
                                  <QueryBuilderIcon
                                    titleAccess={DISPUTED_ITEMS.REPLY_QUERY}
                                  />
                                )}
                              </IconButton>
                            </Link>
                          ) : (
                            <Link
                              to="/Create_Enquiry"
                              state={{ invoiceId: disputedItem?.invoiceId }}
                            >
                              <IconButton>
                                <NoteAddOutlinedIcon
                                  titleAccess={DISPUTED_ITEMS.ADD_QUERY}
                                />
                              </IconButton>
                            </Link>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </>
            ) : (
              <TableRow>
                <TableCell
                  colSpan={8}
                  align="center"
                  className={styles.noRecFound}
                >
                  {t("gzl.no_data_available.text")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {disputedItems?.data?.length > 0 && (
        <div className={styles.tableFooter}>
          <TablePagination
            component="div"
            count={disputedItems?.data?.length ? disputedItems.data.length : 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </div>
      )}
    </>
  );
};
