import { unzip } from "../../components/helper/util";
import { commonConstant } from "../constants/common-constants";

export const getStatementData = (payload: any) => {
  return {
    type: commonConstant.GET_STATEMENT_DATA,
    payload,
  };
};

export const getStatementDataSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_STATEMENT_DATA_SUCCESS,
    payload,
  };
};

export const setOpenItems = (payload: any) => {
  return {
    type: commonConstant.SET_OPEN_ITEMS,
    payload,
  };
};

export const getOpenItems = (payload: any) => {
  return {
    type: commonConstant.GET_OPEN_ITEMS,
    payload,
  };
};

export const creditItems = (payload: any) => {
  return {
    type: commonConstant.GET_CREDIT_ITEMS,
    payload,
  };
};

export const creditItemsSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_CREDIT_ITEMS_SUCCESS,
    payload,
  };
};

export const downloadInvoicePdf = (payload: any) => {
  return {
    type: commonConstant.DOWNLOAD_INVOICE_PDF,
    payload,
  };
};

export const setLoading = (payload: any) => {
  return {
    type: commonConstant.SET_LOADER,
    payload,
  };
};

export const setOpenToast = (payload: any) => {
  return {
    type: commonConstant.SET_OPEN_TOAST,
    payload,
  };
};

export const setUnauthorizedUser = (payload: any) => {
  return {
    type: commonConstant.SET_UNAUTH_USER,
    payload,
  };
};

export const getDebitNoteReasons = (payload: any) => {
  return {
    type: commonConstant.GET_DEBIT_NOTE_REASONS,
    payload,
  };
};

export const getDebitNoteReasonsSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_DEBIT_NOTE_REASONS_SUCCESS,
    payload,
  };
};

export const getPaymentHistory = (payload: any) => {
  return {
    type: commonConstant.SET_PAYMENT_HISTORY,
    payload,
  };
};

export const getPaymentHistorySuccess = (payload: any) => {
  return {
    type: commonConstant.SET_PAYMENT_HISTORY_SUCCESS,
    payload,
  };
};

export const getUserCurrencies = (payload: any) => {
  return {
    type: commonConstant.GET_USER_CURRENCIES,
    payload,
  };
};
export const getUserCurrenciesSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_USER_CURRENCIES_SUCCESS,
    payload,
  };
};
export const getPaidItemsData = (payload: any) => {
  return {
    type: commonConstant.SET_PAID_ITEMS,
    payload,
  };
};

export const getPaidItemsDataSuccess = (payload: any) => {
  return {
    type: commonConstant.SET_PAID_ITEMS_SUCCESS,
    payload,
  };
};

export const createDebitNote = (payload: any) => {
  return {
    type: commonConstant.CREATE_DEBIT_NOTE,
    payload,
  };
};

export const createDebitNoteSuccess = (payload: any) => {
  return {
    type: commonConstant.CREATE_DEBIT_NOTE_SUCCESS,
    payload,
  };
};

export const debitItems = (payload: any) => {
  return {
    type: commonConstant.GET_DEBIT_ITEMS,
    payload,
  };
};

export const debitItemsSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_DEBIT_ITEMS_SUCCESS,
    payload,
  };
};

export const getPaidItemDetailsData = (payload: any) => {
  return {
    type: commonConstant.SET_PAID_ITEM_DETAILS,
    payload,
  };
};

export const getPaidItemDetailsDataSuccess = (payload: any) => {
  return {
    type: commonConstant.SET_PAID_ITEM_DETAILS_SUCCESS,
    payload,
  };
};

export const getPaymentDetailsData = (payload: any) => {
  return {
    type: commonConstant.SET_PAYMENT_ITEM_DETAILS_DATA,
    payload,
  };
};

export const getPaymentDetailsDataSuccess = (payload: any) => {
  return {
    type: commonConstant.SET_PAYMENT_ITEM_DETAILS_DATA_SUCCESS,
    payload,
  };
};

export const getEnquiryTableDetailsData = (payload: any) => {
  return {
    type: commonConstant.SET_ENQUIRY_TABLE_DATA,
    payload,
  };
};

export const getEnquiryTableDetailsDataSuccess = (payload: any) => {
  return {
    type: commonConstant.SET_ENQUIRY_TABLE_DATA_SUCCESS,
    payload,
  };
};

export const getDisputedItems = (payload: any) => {
  return {
    type: commonConstant.GET_DISPUTED_ITEMS,
    payload,
  };
};

export const getDisputedItemsSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_DISPUTED_ITEMS_SUCCESS,
    payload,
  };
};

export const getMakePaymentTableData = (payload: any) => {
  return {
    type: commonConstant.GET_MAKE_PAYMENT_TABLE_DATA,
    payload,
  };
};

export const getComments = (payload: any) => {
  return {
    type: commonConstant.GET_COMMENTS,
    payload,
  };
};

export const getMakePaymentTableDataSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_MAKE_PAYMENT_TABLE_DATA_SUCCESS,
    payload,
  };
};

export const getCommentsSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_COMMENTS_SUCCESS,
    payload,
  };
};

export const getBankDescriptionData = (payload: any) => {
  return {
    type: commonConstant.GET_BANK_DESCRIPTION_DATA,
    payload,
  };
};

export const saveComment = (payload: any) => {
  return {
    type: commonConstant.SAVE_COMMENT,
    payload,
  };
};

export const getBankDescriptionDataSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_BANK_DESCRIPTION_DATA_SUCCESS,
    payload,
  };
};

export const saveCommentSuccess = (payload: any) => {
  return {
    type: commonConstant.SAVE_COMMENT_SUCCESS,
    payload,
  };
};

export const submitPayment = (payload: any) => {
  return {
    type: commonConstant.SUBMIT_PAYMENT,
    payload,
  };
};

export const getCreditManagementInfo = (payload: any) => {
  return {
    type: commonConstant.GET_CREDIT_MANAGEMENT_INFO,
    payload,
  };
};

export const submitPaymentSuccess = (payload: any) => {
  return {
    type: commonConstant.SUBMIT_PAYMENT_SUCCESS,
    payload,
  };
};
export const getCreditManagementInfoSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_CREDIT_MANAGEMENT_INFO_SUCCESS,
    payload,
  };
};

export const loginUser = (payload: any) => {
  return {
    type: commonConstant.LOGIN_USER,
    payload,
  };
};

export const loginUserSuccess = (payload: any) => {
  return {
    type: commonConstant.LOGIN_USER_SUCCESS,
    payload,
  };
};

export const setIsUserLoggedin = (payload: any) => {
  return {
    type: commonConstant.IS_USER_LOGGED_IN,
    payload,
  };
};

export const getAddressList = (payload: any) => {
  return {
    type: commonConstant.GET_ADDRESS_LIST,
    payload,
  };
};

export const getAddressListSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_ADDRESS_LIST_SUCCESS,
    payload,
  };
};

export const setDealers = (payload: any) => {
  return {
    type: commonConstant.SET_DEALERS_LIST,
    payload,
  };
};

export const setCurrentAddressSet = (payload: any) => {
  return {
    type: commonConstant.SET_CURRENT_ADDRESS_SET,
    payload,
  };
};

export const setCurrentDealer = (payload: any) => {
  return {
    type: commonConstant.SET_CURRENT_DEALER,
    payload,
  };
};

export const setCurrentDealerInContext = (payload: any) => {
  return {
    type: commonConstant.SET_CURRENT_DEALER_IN_CONTEXT,
    payload,
  };
};

export const getCurrentDealerSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_CURRENT_DEALER_SUCCESS,
    payload,
  };
};

export const getSalesAreaMapping = (payload: any) => {
  return {
    type: commonConstant.GET_SALES_AREA_MAPPING,
    payload,
  };
};

export const getSalesAreaMappingSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_SALES_AREA_MAPPING_SUCCESS,
    payload,
  };
};

export const createInvoiceDebitNote = (payload: any) => {
  return {
    type: commonConstant.CREATE_INVOICE_DEBIT_NOTE,
    payload,
  };
};

export const getSAPEnvDetails = (payload: any) => {
  return {
    type: commonConstant.GET_SAP_ENV_DETAILS,
    payload,
  };
};

export const getSAPEnvDetailsSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_SAP_ENV_DETAILS_SUCCESS,
    payload,
  };
};

export const validateAccountNumber = (payload: any) => {
  return {
    type: commonConstant.VALIDATE_ACCOUNT_NUMBER,
    payload,
  };
};

export const validateAccountNumberSuccess = (payload: any) => {
  return {
    type: commonConstant.VALIDATE_ACCOUNT_NUMBER_SUCCESS,
    payload,
  };
};

export const fetchReferenceData = (payload: any) => {
  return {
    type: commonConstant.FETCH_REFERENCE_DATA,
    payload,
  };
};

export const fetchReferenceDataSuccess = (payload: any) => {
  return {
    type: commonConstant.FETCH_REFERENCE_DATA_SUCCESS,
    payload,
  };
};

export const registerUser = (payload: any) => {
  return {
    type: commonConstant.REGISTER_USER,
    payload,
  };
};

export const registerUserSuccess = (payload: any) => {
  return {
    type: commonConstant.REGISTER_USER_SUCCESS,
    payload,
  };
};
export const userDetailsWithoutPassword = (payload: any) => {
  return {
    type: commonConstant.GET_USER_DETAILS_WITHOUT_PASSWORD,
    payload,
  };
};

export const userDetailsWithoutPasswordSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_USER_DETAILS_WITHOUT_PASSWORD_SUCCESS,
    payload,
  };
};

export const resetPasswordRequest = (payload: any) => {
  return {
    type: commonConstant.RESET_PASSWORD_REQUEST,
    payload,
  };
};

export const resetPasswordRequestSuccess = (payload: any) => {
  return {
    type: commonConstant.RESET_PASSWORD_REQUEST_SUCCESS,
    payload,
  };
};

export const getUserInfoFromURLID = (payload: any) => {
  return {
    type: commonConstant.USER_DETAILS_FROM_URL_ID,
    payload,
  };
};

export const getUserInfoFromURLIDSuccess = (payload: any) => {
  return {
    type: commonConstant.USER_DETAILS_FROM_URL_ID_SUCCESS,
    payload,
  };
};

export const getResetPassword = (payload: any) => {
  return {
    type: commonConstant.RESET_PASSWORD,
    payload,
  };
};

export const getResetPasswordSucess = (payload: any) => {
  return {
    type: commonConstant.RESET_PASSWORD_SUCCESS,
    payload,
  };
};

export const getTranslationAction = (payload: any) => {
  return {
    type: commonConstant.CHANGE_TRANSLATION,
    payload,
  };
};

export const getTranslationActionSuccess = (payload: any) => {
  return {
    type: commonConstant.CHANGE_TRANSLATION_SUCCESS,
    payload,
  };
};

export const changePasswordAction = (payload: any) => {
  return {
    type: commonConstant.CHANGE_PASSWORD,
    payload,
  };
};

export const changePasswordActionSuccess = (payload: any) => {
  return {
    type: commonConstant.CHANGE_PASSWORD_SUCCESS,
    payload,
  };
};

export const getBusinessGroup = (payload: any) => {
  return {
    type: commonConstant.BUSINESS_GROUP,
    payload,
  };
};

export const getPriceChangeData = (payload: any) => {
  return {
    type: commonConstant.PRICE_CHANGE,
    payload,
  };
};

export const getBusinessGroupSuccess = (payload: any) => {
  return {
    type: commonConstant.BUSINESS_GROUP_SUCCESS,
    payload,
  };
};

export const validateDealer = (payload: any) => {
  return {
    type: commonConstant.VALIDATE_DEALER,
    payload,
  };
};

export const validateDealerSuccess = (payload: any) => {
  return {
    type: commonConstant.VALIDATE_DEALER_SUCCESS,
    payload,
  };
};

export const getPriceChangeDataSuccess = (payload: any) => {
  return {
    type: commonConstant.PRICE_CHANGE_SUCCESS,
    payload,
  };
};

export const updateProfile = (payload: any) => {
  return {
    type: commonConstant.UPDATE_PROFILE,
    payload,
  };
};

export const updateProfileSuccess = (payload: any) => {
  return {
    type: commonConstant.UPDATE_PROFILE_SUCCESS,
    payload,
  };
};
export const fetchSiteSectionDetails = (payload: any) => {
  return {
    type: commonConstant.FETCH_SITESECTION_DETAILS,
    payload,
  }
}

export const fetchSiteSectionDetailsSuccess = (payload: any) => {
  return {
    type: commonConstant.FETCH_SITESECTION_DETAILS_SUCCESS,
    payload,
  }
}

export const setSonyMenuItems = (payload: any) => {
  return {
    type: commonConstant.SET_SONY_MENU_ITEMS,
    payload,
  }
}

export const homePageButtonsConfig = (payload: any) => {
  return {
    type: commonConstant.HOME_PAGE_BUTTONS_CONFIG,
    payload,
  }
}

export const homePageButtonsConfigSuccess = (payload: any) => {
  return {
    type: commonConstant.HOME_PAGE_BUTTONS_CONFIG_SUCCESS,
    payload,
  }
}
export const getDealerDetails = (payload: any) => {
  return {
    type: commonConstant.GET_DEALER_DETAILS,
    payload,
  };
};

export const getDealerDetailsSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_DEALER_DETAILS_SUCCESS,
    payload,
  };
};

export const getReportingfilteredData = (payload: any) => {
  return {
    type: commonConstant.GET_REPORTING_FILTERED_DATA,
    payload,
  };
};

export const getReportingfilteredDataSuccess = (payload: any) => {
  let response = unzip(payload);
  return {
    type: commonConstant.GET_REPORTING_FILTERED_DATA_SUCCESS,
    payload: response,
  };
};

export const getOrderDetails = (payload: any) => {
  return {
    type: commonConstant.GET_ORDER_DETAILS,
    payload,
  };
};

export const getOrderDetailsSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_ORDER_DETAILS_SUCCESS,
    payload,
  };
};

export const createNewReport = (payload: any) => {
  return {
    type: commonConstant.CREATE_NEW_REPORT,
    payload,
  };
};

export const createNewReportSuccess = (payload: any) => {
  return {
    type: commonConstant.CREATE_NEW_REPORT_SUCCESS,
    payload,
  };
};

export const getAllReports = (payload: any) => {
  return {
    type: commonConstant.GET_ALL_REPORTS,
    payload,
  };
};

export const getAllReportsSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_ALL_REPORTS_SUCCESS,
    payload,
  };
};

export const getSelectedReportData = (payload: any) => {
  return {
    type: commonConstant.GET_SELECTED_REPORT_DATA,
    payload,
  };
};

export const getSelectedReportDataSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_SELECTED_REPORT_DATA_SUCCESS,
    payload,
  };
};

export const updateReport = (payload: any) => {
  return {
    type: commonConstant.UPDATE_REPORT,
    payload,
  };
};

export const updateReportSuccess = (payload: any) => {
  return {
    type: commonConstant.UPDATE_REPORT_SUCCESS,
    payload,
  };
};

export const searchDataDownloadProducts = (payload: any) => {
  return {
    type: commonConstant.SEARCH_DATADOWNLOAD_PRODUCT,
    payload,
  };
};

export const searchDataDownloadProductsSuccess = (payload: any) => {
  return {
    type: commonConstant.SEARCH_DATADOWNLOAD_PRODUCT_SUCCESS,
    payload,
  };
};

export const fetchDataDownloadData = (payload: any) => {
  return {
    type: commonConstant.FETCH_DATADOWNLOAD_DATA,
    payload,
  };
};

export const fetchDataDownloadDataSuccess = (payload: any) => {
  return {
    type: commonConstant.FETCH_DATADOWNLOAD_DATA_SUCCESS,
    payload,
  };
};

export const downloadDataFile = (payload: any) => {
  return {
    type: commonConstant.DOWNLOAD_DATA_FILE,
    payload,
  };
};

export const downloadDataFileSuccess = (payload: any) => {
  let response=unzip(payload);
  return {
    type: commonConstant.DOWNLOAD_DATA_FILE_SUCCESS,
    payload:response,
  };
};

export const getCaptchaValidation = (payload: any) => {
  return {
    type: commonConstant.GET_CAPTCHA_VALIDATION,
    payload,
  }
}

export const getCaptchaValidationSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_CAPTCHA_VALIDATION_SUCCESS,
    payload,
  }
}

export const getLoginCaptchaValidationSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_LOGIN_CAPTCHA_VALIDATION_SUCCESS,
    payload,
  }
}

export const getProductVisibilityData = (payload: any) => {
  return {
    type: commonConstant.PRODUCT_VISIBILITY_DATA,
    payload,
  };
};

export const getProductVisibilityDataSuccess = (payload: any) => {
  return {
    type: commonConstant.PRODUCT_VISIBILITY_DATA_SUCCESS,
    payload,
  };
};

export const checkSalesRep = (payload: any) => {
  return {
    type: commonConstant.CHECK_SALES_REP,
    payload,
  };
};

export const checkSalesRepSuccess = (payload: any) => {
  return {
    type: commonConstant.CHECK_SALES_REP_SUCCESS,
    payload,
  };
};

export const checkContactEmail = (payload: any) => {
  return {
    type: commonConstant.CHECK_CONTACT_EMAIL,
    payload,
  };
};

export const checkContactEmailSuccess = (payload: any) => {
  return {
    type: commonConstant.CHECK_CONTACT_EMAIL_SUCCESS,
    payload,
  };
};

export const checkSalesRepBySalesOrg = (payload: any) => {
  return {
    type: commonConstant.CHECK_SALES_REP_BY_SALES_ORG,
    payload,
  };
};

export const checkSalesRepBySalesOrgSuccess = (payload: any) => {
  return {
    type: commonConstant.CHECK_SALES_REP_BY_SALES_ORG_SUCCESS,
    payload,
  };
};

export const checkSalesRepDealer = (payload: any) => {
  return {
    type: commonConstant.CHECK_SALES_REP_DEALER,
    payload,
  };
};

export const checkSalesRepDealerSuccess = (payload: any) => {
  return {
    type: commonConstant.CHECK_SALES_REP_DEALER_SUCCESS,
    payload,
  };
};

export const checkSalesOrgAccounts = (payload: any) => {
  return {
    type: commonConstant.CHECK_SALES_ORG_ACCOUNTS,
    payload,
  };
};

export const checkSalesOrgAccountsSuccess = (payload: any) => {
  return {
    type: commonConstant.CHECK_SALES_ORG_ACCOUNTS_SUCCESS,
    payload,
  };
};

export const getArticlesFromCMS = (payload: any) => {
  return {
    type: commonConstant.GET_CMS_ARTICLES,
    payload,
  }
}

export const fetchProdCategoryResponse = (payload: any) => {
  return {
    type: commonConstant.FETCH_PROD_CATEGORY_RESPONSE,
    payload,
  };
};

export const getArticlesFromCMSSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_CMS_ARTICLES_SUCCESS,
    payload,
  };
};

export const getPricingInfoArticles = (payload: any) => {
  return {
    type: commonConstant.GET_PRICING_INFO_ARTICLES,
    payload,
  };
};

export const getRelatedItemArticles = (payload: any) => {
  return {
    type: commonConstant.GET_RELATED_ITEMS_ARTICLES,
    payload,
  };
};

export const getPricingInfoArticlesSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_PRICING_INFO_ARTICLES_SUCCESS,
    payload,
  };
};

export const getRelatedItemArticlesSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_RELATED_ITEMS_ARTICLES_SUCCESS,
    payload,
  };
};

export const getRelatedToolsArticles = (payload: any) => {
  return {
    type: commonConstant.GET_RELATED_TOOLS_ARICLES,
    payload,
  };
};

export const getRelatedToolsArticlesSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_RELATED_TOOLS_ARICLES_SUCCESS,
    payload,
  };
};

export const fetchProdCategoryResponseSuccess = (payload: any) => {
  return {
    type: commonConstant.FETCH_PROD_CATEGORY_RESPONSE_SUCCESS,
    payload,
  };
};

export const fetchGlobalSearchSuggestions = (payload: any) => {
  return {
    type: commonConstant.GLOBAL_SEARCH_SUGGESTIONS,
    payload,
  };
};

export const getTermsAndCondition = (payload: any) => {
  return {
    type: commonConstant.GET_TERMS_AND_CONDITION,
    payload,
  };
};
export const getTermsAndConditionSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_TERMS_AND_CONDITION_SUCCESS,
    payload,
  };
};

export const fetchGlobalSearchSuggestionsSuccess = (payload: any) => {
  return {
    type: commonConstant.GLOBAL_SEARCH_SUGGESTIONS_SUCCESS,
    payload,
  };
};

export const fetchGlobalSearchResponse = (payload: any) => {
  return {
    type: commonConstant.GLOBAL_SEARCH_RESPONSE,
    payload,
  };
};

export const fetchGlobalSearchResponseSuccess = (payload: any) => {
  return {
    type: commonConstant.GLOBAL_SEARCH_RESPONSE_SUCCESS,
    payload,
  };
};

export const setProductContentData = (payload: any) => {
  return {
    type: commonConstant.PRODUCT_CONTENT_DATA,
    payload,
  };
};

export const fetchProductDetailsData = (payload: any) => {
  return {
    type: commonConstant.PRODUCT_DETAILS_DATA,
    payload,
  };
};

export const fetchProductDetailsDataSuccess = (payload: any) => {
  let response = unzip(payload?.response);
  return {
    type: commonConstant.PRODUCT_DETAILS_DATA_SUCCESS,
    payload:{
      response:response,
      compare:payload?.compare
    },
  };
};

export const setCurrentProductSku = (payload: any) => {
  return {
    type: commonConstant.SET_CURRENT_PRODUCT_SKU,
    payload,
  };
};

export const setCurrentProdCategory = (payload: any) => {
  return {
    type: commonConstant.SET_CURRENT_PROD_CATEGORY,
    payload,
  };
};
export const getQuotationsData = (payload: any) => {
  return {
    type: commonConstant.GET_QUOTATIONS_DATA,
    payload,
  };
};
export const getQuotationsDataSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_QUOTATIONS_DATA_SUCCESS,
    payload,
  };
};

export const getCartProducts = (payload: any) => {
  return {
    type: commonConstant.GET_CART_PRODUCTS,
    payload,
  };
};
export const getCartProductsSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_CART_PRODUCTS_SUCCESS,
    payload,
  };
};

export const getLoginCMSData = (payload: any) => {
  return {
    type: commonConstant.GET_LOGIN_CMS_DATA,
    payload,
  };
};

export const getLoginCMSDataSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_LOGIN_CMS_DATA_SUCCESS,
    payload,
  };
};

export const getGradedStockDetails = (payload: any) => {
  return {
    type: commonConstant.GET_GRADED_STOCK_DATA,
    payload,
  };
};
export const getGradedStockDetailsSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_GRADED_STOCK_DATA_SUCCESS,
    payload,
  }
}

export const setProdCompareData = (payload: any) => {
  return {
    type: commonConstant.SET_PROD_COMPARE_DATA,
    payload,
  };
};

export const clearProdCompareList = (payload: any) => {
  return {
    type: commonConstant.CLEAR_PROD_COMPARE_LIST,
    payload,
  };
};

export const setProductDetailsData = (payload: any) => {
  return {
    type: commonConstant.SET_PRODUCT_DETAILS_DATA,
    payload,
  };
};

export const getOrderChangeReport = (payload: any) => {
  return {
    type: commonConstant.GET_ORDER_CHANGE_REPORT,
    payload,
  };
};
export const getOrderChangeReportSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_ORDER_CHANGE_REPORT_SUCCESS,
    payload,
  };
};

export const getOrderChangeHistoryData = (payload: any) => {
  return {
    type: commonConstant.GET_ORDER_CHANGE_HISTORY,
    payload,
  };
};
export const getOrderChangeHistoryDataSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_ORDER_CHANGE_HISTORY_SUCCESS,
    payload,
  };
};

export const getPrimeSupportArticles = (payload: any) => {
  return {
    type: commonConstant.GET_PRIME_SUPPORT_REGISTRATION,
    payload,
  };
};
export const getPrimeSupportArticlesSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_PRIME_SUPPORT_REGISTRATION_SUCCESS,
    payload,
  };
};

export const setSelectedProductsDownload = (payload: any) => {
  return {
    type: commonConstant.SET_SELECTED_PRODUCTS_DOWNLOAD,
    payload,
  };
};

export const fetchLiveAvailability=(payload:any) => {
  return {
    type: commonConstant.FETCH_LIVE_AVAILABILITY,
    payload,
  }
}

export const fetchLiveAvailabilitySuccess=(payload:any)=>{
  return{
    type: commonConstant.FETCH_LIVE_AVAILABILITY_SUCCESS,
    payload,
  }
}

export const startProductFlow = (payload: any) => {
  return {
    type: commonConstant.START_PRODUCT_FLOW,
    payload,
  };
};

export const startProductFlowSuccess = (payload: any) => {
  return {
    type: commonConstant.START_PRODUCT_FLOW_SUCCESS,
    payload,
  };
};

export const getNewProducts = (payload: any) => {
  return {
    type: commonConstant.GET_NEW_PRODUCTS,
    payload,
  };
};

export const getNewProductsSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_NEW_PRODUCTS_SUCCESS,
    payload,
  };
};

export const getCategoryProductSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_CATEGORY_PRODUCT_SUCCESS,
    payload,
  };
};

export const fetchProductContentData = (payload: any) => {
  return {
    type: commonConstant.FETCH_PRODUCT_CONTENT_DATA,
    payload,
  };
};

export const getContextDetails = (payload: any) => {
  return {
    type: commonConstant.GET_CONTEXT_DETAILS,
    payload,
  };
};
export const getContextDetailsSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_CONTEXT_DETAILS_SUCCESS,
    payload,
  };
};

export const getCalendarData = (payload: any) => {
  return {
    type: commonConstant.GET_CALENDAR_DETAILS,
    payload,
  };
};
export const getCalendarDataSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_CALENDAR_DETAILS_SUCCESS,
    payload,
  };
};

export const myQuotationsSFDCPlaceOrder = (payload: any) => {
  return {
    type: commonConstant.SFDC_PLACE_ORDER_MY_QUOTATIONS,
    payload,
  };
};
export const myQuotationsSFDCPlaceOrderSuccess = (payload: any) => {
  return {
    type: commonConstant.SFDC_PLACE_ORDER_MY_QUOTATIONS_SUCCESS,
    payload,
  };
};

export const getProductsByMaterialName = (payload: any) => {
  return {
    type: commonConstant.GET_PRODUCTS_BY_MATERIAL,
    payload,
  };
};

export const getProductsByMaterialNameSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_PRODUCTS_BY_MATERIAL_SUCCESS,
    payload,
  };
};

export const sendProductDetailsMail= (payload:any)=>{
  return {
    type:commonConstant.SEND_PRODUCT_DETAILS_MAIL,
    payload,
  };
};

export const sendProductDetailsMailSuccess= (payload:any)=>{
  return {
    type:commonConstant.SEND_PRODUCT_DETAILS_MAIL_SUCCESS,
    payload,
  };
};


export const editOrderReport = (payload: any) => {
  return {
    type: commonConstant.EDIT_ORDER_REPORT,
    payload,
  };
};
export const editOrderReportSuccess = (payload: any) => {
  return {
    type: commonConstant.EDIT_ORDER_REPORT_SUCCESS,
    payload,
  };
};

export const getPremiumServiceData =(payload: any)=>{
  return {
    type: commonConstant.GET_PREMIUM_SERVICE_DATA,
    payload,
  };
};
export const getPremiumServiceDataSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_PREMIUM_SERVICE_DATA_SUCCESS,
    payload,
  };
};

export const getCartTotalProductsCount =(payload: any)=>{
  return {
    type: commonConstant.GET_CART_PRODUCTS_TOTAL,
    payload,
  };
};
export const getCartTotalProductsCountSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_CART_PRODUCTS_TOTAL_SUCCESS,
    payload,
  };
};

export const getProductList =(payload: any)=>{
  return {
    type: commonConstant.GET_ALL_PRODUCT_LIST,
    payload,
  };
};
export const getProductListSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_ALL_PRODUCT_LIST_SUCCESS,
    payload,
  };
};

export const getQuote =(payload: any)=>{
  return {
    type: commonConstant.GET_QUOTE,
    payload,
  };
};
export const getQuoteSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_QUOTE_SUCCESS,
    payload,
  };
};

export const getMultiQuote =(payload: any)=>{
  return {
    type: commonConstant.GET_MULTI_QUOTE,
    payload,
  };
};
export const getMultiQuoteSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_MULTI_QUOTE_SUCCESS,
    payload,
  };
};

export const logoutAction =(payload: any)=>{
  return {
    type: commonConstant.CALL_LOGOUT,
    payload,
  };
};

export const getProductGroup=(payload:any)=>{
  return{
    type: commonConstant.GET_PRODUCT_GROUP,
    payload,
  }
}
export const getProductGroupSuccess=(payload:any)=>{
  return{
    type: commonConstant.GET_PRODUCT_GROUP_SUCCESS,
    payload,
  }
}

export const deleteproductGroup=(payload:any)=>{
  return{
    type: commonConstant.DELETE_PRODUCT_GROUP,
    payload,
  }
}

export const deleteproductGroupSuccess=(payload:any)=>{
  return{
    type: commonConstant.DELETE_PRODUCT_GROUP_SUCCESS,
    payload,
  }
}

export const updateproductGroup=(payload:any)=>{
  return{
    type: commonConstant.UPDATE_PRODUCT_GROUP,
    payload,
  }
}

export const saveproductGroup=(payload:any)=>{
  return{
    type: commonConstant.SAVE_PRODUCT_GROUP,
    payload,
  }
}

export const updateproductGroupSuccess=(payload:any)=>{
  return{
    type: commonConstant.UPDATE_PRODUCT_GROUP_SUCCESS,
    payload,
  }
}
export const saveproductGroupSuccess=(payload:any)=>{
  return{
    type: commonConstant.SAVE_PRODUCT_GROUP_SUCCESS,
    payload,
  }
}

export const clearCartOrder=(payload:any)=>{
  return{
    type: commonConstant.CLEAR_CART_ORDER,
    payload,
  }
}
export const clearCartOrderSuccess=(payload:any)=>{
  return{
    type: commonConstant.CLEAR_CART_ORDER_SUCCESS,
    payload,
  }
}

export const addToCart=(payload:any)=>{
  return{
    type: commonConstant.ADD_TO_CART,
    payload,
  }
}
export const addToCartSuccess=(payload:any)=>{
  return{
    type: commonConstant.ADD_TO_CART_SUCCESS,
    payload,
  }
}

export const updateCartAction=(payload:any)=>{
  return{
    type: commonConstant.UPDATE_CART_ORDER,
    payload,
  }
}
export const updateCartActionSuccess=(payload:any)=>{
  return{
    type: commonConstant.UPDATE_CART_ORDER_SUCCESS,
    payload,
  }
}

export const deleteSelectedItemCart=(payload:any)=>{
  return{
    type: commonConstant.DELETE_SELECTED_CART_ORDER,
    payload,
  }
}
export const deleteSelectedItemCartSuccess=(payload:any)=>{
  return{
    type: commonConstant.DELETE_SELECTED_CART_ORDER_SUCCESS,
    payload,
  }
}

export const placeSAPOrder=(payload:any)=>{
  return{
    type: commonConstant.PLACE_SAP_ORDER,
    payload,
  }
}
export const placeSAPOrderSuccess=(payload:any)=>{
  return{
    type: commonConstant.PLACE_SAP_ORDER_SUCCESS,
    payload,
  }
}

export const saveQuoteRefData = (payload: any) => {
  return {
    type: commonConstant.SAVE_QUOTE_REFERENCE_DATA,
    payload,
  }
}
export const saveQuoteSpecialInstructionData = (payload: any) => {
  return {
    type: commonConstant.SAVE_QUOTE_SPECIAL_INSTRUCTION_DATA,
    payload,
  }
}

export const cartFileUpload=(payload:any)=>{
  return{
    type: commonConstant.CART_FILE_UPLOAD,
    payload,
  }
}
export const cartFileUploadSuccess=(payload:any)=>{
  return{
    type: commonConstant.CART_FILE_UPLOAD_SUCCESS,
    payload,
  }
}

export const saveMultiAddresses=(payload:any)=>{
  return{
    type: commonConstant.SAVE_MULTI_ADDRESSES,
    payload,
  }
}

export const setSelectedShipToAccount=(payload:any)=>{
  return{
    type: commonConstant.SET_SELECTED_SHIP_TO_ACC,
    payload,
  }
}


export const getProductImages=(payload:any)=>{
  return{
    type: commonConstant.GET_PRODUCT_IMAGES,
    payload,
  }
}

export const getProductImagesSuccess=(payload:any)=>{
  return{
    type: commonConstant.GET_PRODUCT_IMAGES_SUCCESS,
    payload,
  }
}

export const saveLoginError=(payload:any)=>{
  return{
    type: commonConstant.SAVE_LOGIN_ERROR,
    payload,
  }
}

export const getAccountInfoCMS = (payload: any) => {
  return {
    type: commonConstant.GET_ACCOUNT_INFO_CMS,
    payload,
  };
};

export const getAccountInfoCMSSuccess = (payload: any) => {
  return {
    type: commonConstant.GET_ACCOUNT_INFO_CMS_SUCCESS,
    payload,
  };
};

export const saveAccountDetails = (payload: any) => {
  return {
    type: commonConstant.SAVE_ACCOUNT_DETAILS,
    payload,
  };
}
