import React from 'react';
import {useEffect, useState} from 'react';
import {
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel,
  Box} from '@mui/material';
import { useSelector } from "react-redux";
import { visuallyHidden } from '@mui/utils';
import styles from './paymentTable.module.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getComparator, stableSort } from '../../../helper/util';
import moment from "moment";

const PaymentTable = () => {
    const { t } = useTranslation();
    const [order, setOrder] = React.useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('valueDate');
    const [rows, setRows] = useState<any>([])
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const paymentHistoryData = useSelector(
      (state: any) => state?.gazelleReducer?.paymentHistoryData?.payments
    );

    const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

    const handleRequestSort = (
      event: React.MouseEvent<unknown>,
      property: keyof Data,
    ) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
  interface Column {
    id: 'valueDate' | 'amount' | 'currency';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
  }
  
  const columns: readonly Column[] = [
    {id: 'valueDate', label: t("gzl.payment_date.text")},
    {id: 'amount', label: t("gzl.actual_paid_value.text")},
    {id: 'currency', label: t("gzl.currency.text")}
  ];
  
  interface Data {
    valueDate: any,
    amount: any,
    currency: any,
  }

  function createData(
    valueDate: any,
    amount: any,
    currency: any,
  ): Data {
    return { valueDate, amount, currency};
  }

  const createRowData=()=>{
    let data = paymentHistoryData
    let rowsdata = []
    for(let i=0; i<data?.length; i++){
      rowsdata.push(createData(data[i]["valueDate"], data[i]["amount"], data[i]["currency"]))
    }
    setRows([...rowsdata])
  }

  useEffect(()=>{
    if(paymentHistoryData?.length>0)
    createRowData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[paymentHistoryData])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

    return (
        <div className={styles.tableContainer}>
          <TableContainer component={Paper} className={styles.tableContainerHeader}>
            <Table sx={{ minWidth: 650 }} aria-label="Payments Table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={createSortHandler(column.id)}
                      >
                        {column.label}
                      {orderBy === column.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                paymentHistoryData?.length > 0 && 
                rows?.length > 0 ? stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row:any, key:any) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return column.id === "amount" ? (
                            <TableCell key={column.id} align={column.align}>
                              <Link 
                                id="linkTag"
                                className={styles.colorBlue}
                                to="/Payment_Details" 
                                state={{referenceId: paymentHistoryData?.[key]?.["referenceId"]}}
                              >
                                {value}
                              </Link>
                            </TableCell>
                          ) : column.id === "valueDate" ? (
                            <TableCell key={column.id} align={column.align}>
                              {
                                value && moment(value).format("DD.MM.YYYY") 
                              }
                            </TableCell>
                          ) : (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  }):<TableRow>
                          <TableCell colSpan={columns.length} align={"center"} className={styles.noRecFound}>
                            {t("gzl.no_data_available.text")}
                          </TableCell>
                    </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={styles.tableFooter}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          </div>
        </div>
      );
}

export default PaymentTable;