import { Box, Divider, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PAID_ITEMS } from "../../../constants/labels";
import CustomButton from "../../atoms/customButton";
import CustomDropDown from "../../atoms/customDropDown";
import CustomTextField from "../../atoms/customTextField";
import logger from "../../helper/logger";
import { OpenItems } from "../openItems";
import { PaidItems } from "../paidItems";
import styles from "./your-reference-search.module.scss";
import { YOUR_REFERENCE_SEARCH } from "../../../constants/labels";

export const YourReferenceSearch = () => {
  let monthName = moment(new Date())
    .subtract(0, "month")
    .startOf("month")
    .format("MM YYYY");
  let yearName = monthName.split(" ");
  let current: any = new Date(parseInt(yearName[1]), parseInt(yearName[0]), 0);
  const { t } = useTranslation();
  const [referenceValue, setReferenceValue] = useState<string>("");
  const [refValue, setRefValue] = useState<string>("");
  const [refError, setRefError] = useState("");
  const [menuItems, setMenuItems] = useState<any>([]);
  const [value, setValue] = useState<string | number>(
    moment(current).format("MMMM YYYY")
  );
  const [period, setPeriod] = useState<string | number>("");

  //validates reference value and set error accordingly
  const referenceSetter = (event: any) => {
    setReferenceValue(event.target.value);
    if (event.target.value === "") {
      setRefError(t("gzl.alertBlank.text")?.toString());
    } else {
      setRefError("");
    }
  };

  // triggers search for reference input and selected period
  const referenceHandler = () => {
    if (referenceValue === "") {
      setRefError(t("gzl.alertBlank.text")?.toString());
    } else {
      setRefError("");
      logger.info("Triggered search for reference value ",referenceValue," and for period ",value);
      setRefValue(referenceValue);
      setPeriod(value);
    }
  };

  //to get values for the dropdown period
  const getMonths = () => {
    let startArr = [];
    let endArr = [];
  
    let previousMonthsArr = [];
    for (let i = 0; i <= 11; i++) {
      let monthName = moment(new Date())
        .subtract(i, "month")
        .startOf("month")
        .format("MM YYYY");
      let yearName = monthName.split(" ");
      let current: any = new Date(
        parseInt(yearName[1]),
        parseInt(yearName[0]),
        0
      );
      previousMonthsArr.push(moment(current).format("MMMM YYYY"));
      startArr.push(moment("01 " + previousMonthsArr[i]).format("DD.MM.YYYY"));
      i === 0
        ? endArr.push(moment(new Date()).format("DD.MM.YYYY"))
        : endArr.push(moment(current).format("DD.MM.YYYY"));
    }
    previousMonthsArr = [...previousMonthsArr, ...PAID_ITEMS.MENU_ITEMS];
    return previousMonthsArr;
  };
  useEffect(() => {
    if (menuItems?.length===0) {
      setMenuItems(getMonths());
    }
  });
  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  return (
    <>
      <Typography variant="subtitle1" className={styles.header} pb={3} color="primary">
        {t("dnet.cr_search_by_your_reference.text")}
      </Typography>
      <Box className={styles.boxStyle}>
        <CustomTextField
          id="reference"
          label={t("gzl.your_ref.text")}
          changeHandler={referenceSetter}
          value={referenceValue}
          helperText={refError}
          error={refError?.length > 0}
        />
        <CustomDropDown
          id="periodDropdown"
          value={value}
          handleChange={handleChange}
          menuItems={menuItems}
          label={YOUR_REFERENCE_SEARCH.PAID_ITEM_PERIOD}
        />
        <span>
          <CustomButton
            id="findButton"
            style={{ marginLeft: "0" }}
            additionalSxProperties={{marginLeft: "-1px"}}
            label={t("gzl.find_button.text")}
            onClick={() => referenceHandler()}
          />
        </span>
      </Box>
      <Divider sx={{ marginBottom: "24px" }} />
      <OpenItems refValue={refValue} from="refSearch" />
      <Divider sx={{ marginBottom: "24px" }} />
      <PaidItems period={period} refValue={refValue} from="refSearch" />
    </>
  );
};
