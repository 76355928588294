import { TextField } from "@mui/material";
import * as React from "react";

export default function CustomTextArea({
  label,
  minRows,
  placeholder,
  maxRows,
  changeHandler,
  error,
  helperText,
}: any) {
  return (
    <TextField
      label={label}
      multiline
      minRows={minRows}
      placeholder={placeholder}
      sx={{
        width: "100%",
      }}
      maxRows={maxRows}
      onChange={changeHandler}
      error={error}
      helperText={helperText}
    />
  );
}

CustomTextArea.defaultProps = {
  label: "",
  minRows: 3,
  placeholder: "",
  maxRows: 10,
  changeHandler: () => {},
  error: false,
  helperText: "",
};
