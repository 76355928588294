import { commonConstant } from "../constants/common-constants";
var _ = require("lodash");

export const initialState = {
  statementData: {},
  openItemData: {},
  debitNoteReasons: [],
  userCurrencies: [],
  createDebitNote: false,
  creditItemData: {},
  debitItemData: {},
  paymentHistoryData: {},
  paidItemsData: {},
  paidItemDetailsData: {},
  paymentDetailsData: {},
  enquiryDetailsData: {},
  disputedItems: {},
  makePaymentTableData: {},
  bankDescription: {},
  paymentSubmissionResponse: false,
  replyEnquiryComments: [],
  saveComment: false,
  creditManagementInfo: [],
  enablePayButton: true,
  paymentSelectedMonth: "",
  paidItemSelectedMonth: "",
  getAccountInfoData: [],
};

const gazelleReducer = (state = initialState, action: any) => {
  switch (action?.type) {
    case commonConstant.GET_STATEMENT_DATA_SUCCESS:
      return {
        ...state,
        statementData: action?.payload,
      };
    case commonConstant.GET_OPEN_ITEMS:
      return {
        ...state,
        openItemData: action?.payload,
      };
    case commonConstant.GET_DEBIT_NOTE_REASONS_SUCCESS:
      return {
        ...state,
        debitNoteReasons: action?.payload,
      };
    case commonConstant.GET_USER_CURRENCIES_SUCCESS:
      let currencies = formatUserCurrencies(action?.payload);
      return {
        ...state,
        userCurrencies: currencies,
        enablePayButton: false,
      };
    case commonConstant.CREATE_DEBIT_NOTE_SUCCESS:
      return {
        ...state,
        createDebitNote: action?.payload,
        enablePayButton: true,
      };
    case commonConstant.GET_CREDIT_ITEMS_SUCCESS:
      return {
        ...state,
        creditItemData: action?.payload,
      };
    case commonConstant.GET_DEBIT_ITEMS_SUCCESS:
      return {
        ...state,
        debitItemData: action?.payload,
      };
    case commonConstant.SET_PAYMENT_HISTORY:
      return {
        ...state,
        paymentSelectedMonth: action?.payload?.selectedMonth,
      };
    case commonConstant.SET_PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        paymentHistoryData: action?.payload,
      };
    case commonConstant.SET_PAID_ITEMS:
      return {
        ...state,
        paidItemSelectedMonth: action?.payload?.selectedMonth,
      };
    case commonConstant.SET_PAID_ITEMS_SUCCESS:
      return {
        ...state,
        paidItemsData: action?.payload,
      };
    case commonConstant.SET_PAID_ITEM_DETAILS_SUCCESS:
      return {
        ...state,
        paidItemDetailsData: action?.payload,
      };
    case commonConstant.SET_PAYMENT_ITEM_DETAILS_DATA_SUCCESS:
      return {
        ...state,
        paymentDetailsData: action?.payload,
      };
    case commonConstant.SET_ENQUIRY_TABLE_DATA_SUCCESS:
      return {
        ...state,
        enquiryDetailsData: action?.payload,
      };
    case commonConstant.GET_DISPUTED_ITEMS_SUCCESS:
      return {
        ...state,
        disputedItems: action?.payload,
      };
    case commonConstant.GET_MAKE_PAYMENT_TABLE_DATA_SUCCESS:
      return {
        ...state,
        makePaymentTableData: action?.payload,
      };
    case commonConstant.GET_BANK_DESCRIPTION_DATA_SUCCESS:
      return {
        ...state,
        bankDescription: action?.payload,
      };
    case commonConstant.SUBMIT_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentSubmissionResponse: action?.payload,
        enablePayButton: false,
      };
    case commonConstant.GET_COMMENTS_SUCCESS:
      let comments = formatReplyEnquiryComments(action?.payload);
      return {
        ...state,
        replyEnquiryComments: comments,
        enablePayButton: false,
      };
    case commonConstant.SAVE_COMMENT_SUCCESS:
      return {
        ...state,
        saveComment: action?.payload,
        enablePayButton: true,
      };
    case commonConstant.GET_CREDIT_MANAGEMENT_INFO_SUCCESS:
      let creditManagementInfo = formatCreditMngmtInfo(action?.payload);
      return {
        ...state,
        creditManagementInfo: creditManagementInfo,
      };
    case commonConstant.GET_ACCOUNT_INFO_CMS_SUCCESS:
      return {
        ...state,
        getAccountInfoData: action?.payload,
      };
    default:
      return state;
  }
};

//Creating credit management information response based on required structure
const formatCreditMngmtInfo = (data: any) => {
  let response: any = [];
  data?.EXPTABLE.forEach((ele: any) => {
    response.push({
      creditLimit: ele?.CREDITLIMIT,
      exposure: ele?.EXPOSURE,
      name: ele?.NAME,
      currency: ele?.CURRENCY,
    });
  });
  return response;
};

//Creating currencies list response based on required structure
const formatUserCurrencies = (data: any) => {
  let response: any = [];
  data?.T_WAERS.forEach((ele: any) => {
    response.push(ele?.WAERS);
  });
  return response;
};

//Creating comments list response based on required structure
const formatReplyEnquiryComments = (data: any) => {
  let response: any = [];
  let obj: any = {};
  let content: any = [];
  data?.forEach((ele: any) => {
    //if metadata is B, then its considered start of new comment
    if (ele?.metadata === "B") {
      if (!_.isEmpty(obj)) {
        response.push(obj);
      }
      let arr = ele?.content.split("-----");
      obj = {
        commentedBy: arr[3]?.trim(),
        date: (arr[1] + arr[2])?.trim(),
        comment: [],
      };
      content = [];
    } else {
      content.push(ele?.content);
      obj.comment = content;
    }
  });
  if (Object.keys(obj).length >= 1 && obj?.comment?.length >= 1) {
    response.push(obj);
  }
  return response;
};
export default gazelleReducer;
